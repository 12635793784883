import * as S from "./newDashboard.styles"
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import EmailOutlinedIcon from "@material-ui/icons/EmailOutlined";
import UserImg from "assets/images/new/Tim-Foodiary.jpg"
import Tick from "assets/images/tick.png"
import CopyIcon from "assets/images/copy-icon-black.svg"
import { ToastContainer, toast } from "react-toastify";
import { SidebarSocialIcons } from "../menu";

const defaultAccountManagerDetails = {
    email: 'backoffice@foodiary.app',
    whatsapp_url: `https://wa.me/+491741953597`,
    fullName: 'nicht zugeordnet',
    profile_picture: 'https://foodiaryproduction.s3.eu-central-1.amazonaws.com/Marketingportal/Company%20Logo/Placeholder-Profilbild_1714193073TP5TDPmEgj.jpg'
}

function RightFixed ({
  trainerProfileData,
  isTrainerNewsLoading,
  trainerNews,
  formatNewsDate
}) {

  const promoLink = trainerProfileData.referral_code
  const accountManagerDetails = getAccountManagerDetails(trainerProfileData)

  const copyPromoLink = textToCopy => {
    navigator.clipboard.writeText(textToCopy)
    toast("📃 Erfolgreich kopiert.")
  }

  const sidebarQuickLinks = (
    SidebarSocialIcons.map((item, i) => (
      <a href={item.url} target="_blank" key={i}>
        <img src={item.img} alt="social-icon" />
      </a>
    ))
  )

  return (
    <>
      
        <S.DashboardWrapRight>
          <S.QuickLinks>
            <h3>Quick Links</h3>
            <div className="sidebar-quick-links">
            {sidebarQuickLinks}
            </div>
          </S.QuickLinks>
            <S.CardBox>
                <S.SelfCardHead>
                  <h3>Ansprechpartner</h3>
                  <S.SocialIcons>
                    <a
                      href={accountManagerDetails.whatsapp_url}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <WhatsAppIcon />
                    </a>
                    <a href={`mailto:${accountManagerDetails.email}`}>
                      <EmailOutlinedIcon />
                    </a>
                  </S.SocialIcons>
                </S.SelfCardHead>

                <S.ProfileBox>
                  <img alt="Profile" src={accountManagerDetails.profile_picture} />
                  <S.InnerMenuInfo>
                    <h5>{accountManagerDetails.fullName}</h5>
                    <p>Key Account Manager</p>
                  </S.InnerMenuInfo>
                </S.ProfileBox>
            </S.CardBox>

            <S.CardBox>
              <S.UrlBox>
                <h5>Wichtige URLs</h5>
                <S.UrlBoxWrap>
                  <S.URlBoxInner>
                    <span>WEBSITE URL</span>
                    <div className="url-wrapper">
                      <a href={trainerProfileData?.coach_website_url} target="_blank" rel="noreferrer">
                        {trainerProfileData?.coach_website_url}
                      </a>
                      <span className="promo-copy-icon" onClick={() => copyPromoLink(trainerProfileData?.coach_website_url)}>
                        <img src={CopyIcon} alt="" />
                      </span>
                    </div>
                  </S.URlBoxInner>

                  <S.URlBoxInner>
                    <span>LINKTREE URL</span>
                    <div className="url-wrapper">
                      <a href={`${trainerProfileData?.coach_website_url}/linktree`} target="_blank" rel="noreferrer">
                        {`${trainerProfileData?.coach_website_url}/linktree`}
                      </a>
                      <span className="promo-copy-icon" onClick={() => copyPromoLink(`${trainerProfileData?.coach_website_url}/linktree`)}>
                        <img src={CopyIcon} alt="" />
                      </span>
                    </div>
                  </S.URlBoxInner>
                </S.UrlBoxWrap>
              </S.UrlBox>

              <S.Divider />

              <S.NewsWrap>
                <h5>News</h5>

                {isTrainerNewsLoading && (
                  <>
                    <br />
                    <br />
                    <br />
                    <br />
                    <div className="text-center">
                      <div className="loader mx-auto"></div>
                    </div>
                    <br />
                    <br />
                    <br />
                    <br />
                  </>
                )}

                {!isTrainerNewsLoading && (
                  <S.NewsWrapInner>
                    {trainerNews?.getAllTrainerNews?.data.map((n) => (
                      <S.NewsBox>
                        <a href={n?.url} target="_blank" rel="noreferrer">
                          {n?.title}
                        </a>
                        <p>{n?.text}</p>
                        <span>{formatNewsDate(n?.date)}</span>
                      </S.NewsBox>
                    ))}
                  </S.NewsWrapInner>
                )}
              </S.NewsWrap>
            </S.CardBox>
            <S.CardBox>
              <S.PromoSection>
                <h5>Empfehle Foodiary weiter</h5>
                <p>Bei der Weiterempfehlung von Foodiary investieren wir lieber in euch statt in Google und Facebook. Aus diesem Grund vergüten wir jede Weiterempfehlung mit 200€.</p>
                <S.PromoList>
                  <div>
                    <span><img src={Tick} /></span>
                    <span>Link kopieren</span>
                  </div>
                  <div>
                    <span><img src={Tick} /></span>
                    <span>In unser Webinar einladen</span>
                  </div>
                  <div>
                    <span><img src={Tick} /></span>
                    <span>200€ sichern</span>
                  </div>
                </S.PromoList>
                {promoLink ? (
                  <S.PromoCopyLink>
                  <span className="url-placeholder">{promoLink.length > 20 ? `${promoLink.substring(0, 30)}...` : promoLink}</span>
                  <span className="promo-copy-icon" onClick={() => copyPromoLink(promoLink)} >
                    <img src={CopyIcon} alt="" />
                  </span>
                </S.PromoCopyLink>
                ) : ''}
              </S.PromoSection>
            </S.CardBox>
        </S.DashboardWrapRight>
    </>
  )
}

export default RightFixed

function getAccountManagerDetails (trainerProfileData) {
    if (!trainerProfileData || !trainerProfileData?.key_account_manager?.meta) {
        return defaultAccountManagerDetails
    }

    const email = trainerProfileData?.key_account_manager?.meta?.email
    const whatsapp_url = `https://wa.me/${trainerProfileData?.key_account_manager?.meta?.whatsapp}`
    const prename = trainerProfileData?.key_account_manager?.prename
    const surname = trainerProfileData?.key_account_manager?.surname
    const fullname = `${prename || ''} ${surname || ''}`.trim()

    return {
        email: email || defaultAccountManagerDetails.email,
        profile_picture:
            trainerProfileData?.key_account_manager?.meta?.profile_picture
            ? trainerProfileData?.key_account_manager?.meta?.profile_picture
            : defaultAccountManagerDetails.profile_picture,
        whatsapp_url:
            trainerProfileData?.key_account_manager?.meta?.whatsapp
            ? whatsapp_url
            : defaultAccountManagerDetails.whatsapp_url,
        fullName: fullname || defaultAccountManagerDetails.fullName
    }
}
