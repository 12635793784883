import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

// Components
import Button from "components/formComponents/button/Button";

import Iphone from "assets/images/Onboarding/Screen4/app-functions.png";
import DashboardFunctions from "assets/images/Onboarding/Screen4/dashboard-functions.png";
import ArrowLeft from "assets/images/arrowLeft.svg";

import { AppFunctions, DashbaordFunctions } from "./data";
// Redux
import * as S from "./OnBoardingPartOne.styles";
import OnboardingSidebar from "./OnboardingSidebar";

const FoodiaryBenefits = (props) => {
  const history = useHistory();

  const [ActiveTab, SetActiveTab] = useState(1);

  useEffect(() => {}, []);

  return (
    <>
      <S.OnBoardingPage className="starter">
        <S.OnBoardingContainer className="onboarding-container">
          <OnboardingSidebar step={1} />
          <S.OnBoardingContent className="onboarding-content">
            <S.OnBoardingHeader className="onboarding-header">
              <div className="d-flex align-items-top gap-3">
                <img
                  className="cursor-pointer"
                  src={ArrowLeft}
                  alt="back"
                  onClick={() => history.push("/onboarding/interview-steps")}
                />
                <div className="content">
                  <span className="small-title text-green font-rubik-medium">
                    Vorstellung
                  </span>
                  <h6 className="header-title font-rubik-medium my-0">
                    Die Vorteile von Foodiary
                  </h6>
                </div>
              </div>
              <div className="actions">
                <Button
                  color="rgba(248, 164, 146, 1)"
                  gradient="linear-gradient(60deg, rgba(46, 207, 150, 1), rgba(28, 157, 135, 1)) !important"
                  margin=" 0"
                  absolute={{ width: "fit-content", height: "2.5rem" }}
                  buttonStyle={{ padding: ".5625rem 2rem" }}
                  onClick={() =>
                    history.push("/onboarding/registration/gender")
                  }
                >
                  Weiter
                </Button>
              </div>
            </S.OnBoardingHeader>
            <S.OnBoardingBody className="onboarding-body">
              <div className="foodiary-benefits-content ">
                <br />
                <div className="benefits-tabs">
                  <div
                    className={ActiveTab === 1 ? "tab active" : "tab"}
                    onClick={() => SetActiveTab(1)}
                  >
                    App Funktionen
                  </div>
                  <div
                    className={ActiveTab === 2 ? "tab active" : "tab"}
                    onClick={() => SetActiveTab(2)}
                  >
                    Dashboard Funktion
                  </div>
                </div>
                <br />
                <br />
                {ActiveTab === 1 && (
                  <div className="app-functions">
                    <div className="app-functions-image">
                      <img src={Iphone} alt="App Functions" />
                    </div>
                    <div className="app-functions-content">
                      {AppFunctions.map((item, i) => (
                        <div key={i + item.title}>
                          <p className="p3">
                            {item.title}
                            {item.badge && (
                              <span className="badge">{item.badge}</span>
                            )}
                          </p>
                          <p className="p5 line-height-1-25 text-light-grey-1 font-rubik-regular mt-3">
                            {item.description}
                          </p>
                        </div>
                      ))}
                    </div>
                  </div>
                )}

                {ActiveTab === 2 && (
                  <div className="dashboard-functions-tab">
                    <img className="main-img" src={DashboardFunctions} alt="" />
                    <div className="functions">
                      {DashbaordFunctions.map((item, i) => (
                        <div className="function-item" key={i + item.title}>
                          <p className="p1 text-dark-1 font-rubik-medium">
                            {item.title}
                          </p>
                          <p className="p2 text-light-grey-1 font-rubik-regular mt-3">
                            {item.description}
                          </p>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </S.OnBoardingBody>
          </S.OnBoardingContent>
        </S.OnBoardingContainer>
      </S.OnBoardingPage>
    </>
  );
};

export default FoodiaryBenefits;
