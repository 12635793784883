import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import { ref, object, string } from "yup";

import Input from "components/formComponents/input/Input";
import OutsideAlerter from "components/OutsideAlerter";

import CrossIcon from "assets/images/cross.svg";

import * as AuthActions from "redux/auth/auth.actions";
import * as S from "./passwordResetModal.styles";

const PasswordResetModal = (props) => {
  const [showMsg, setShowMsg] = useState(false);

  const dispatch = useDispatch();
  const loading = useSelector((state) => state.auth.isPasswordReseting);
  const passwordReset = useSelector((state) => state.auth.passwordReset);

  const initialValues = {
    password: "",
    confirmPassword: "",
    email: "",
  };

  const validationSchema = object().shape({
    email: string()
      .email("E-Mail muss eine gültige Adresse sein.")
      .required("Erforderlich"),
    password: string()
      .min(8, "Muss mindestens 8 Zeichen haben.")
      .required("Erforderlich"),
    confirmPassword: string().when("password", {
      is: (val) => (val && val.length > 0 ? true : false),
      then: string()
        .oneOf([ref("password")], "Beide Passwörter müssen übereinstimmen.")
        .required("Erforderlich"),
    }),
  });

  const onFormSubmit = (values, { resetForm }) => {
    dispatch(
      AuthActions.passwordResetRequest({
        formData: {
          email: values.email,
          password: values.password,
          confirm_password: values.confirmPassword,
        },
        callback: () => {
          resetForm();
          setShowMsg(true);
          const timer = setTimeout(() => {
            setShowMsg(false);
            clearTimeout(timer);
          }, 5000);
        },
      })
    );
  };

  return (
    <S.ModalBackdrop show={props.show} key={props.key}>
      <OutsideAlerter closeModal={props.setShow}>
        <S.ModalContainer width={props.width} height={props.height}>
          <S.ModalInnerContainer>
            <S.ModalHeader>
              <h4 className="my-0 text-left">Passwort ändern </h4>
              <img
                className="cursor-pointer"
                src={CrossIcon}
                alt="CrossIcon"
                onClick={() => props.setShow(false)}
              />
            </S.ModalHeader>
            {showMsg && (
              <>
                {passwordReset?.success && (
                  <S.SuccessMsg>{passwordReset?.message}</S.SuccessMsg>
                )}
                {!passwordReset?.success && (
                  <S.ErrorMsg>{passwordReset?.message}</S.ErrorMsg>
                )}
              </>
            )}
            <S.ModalBody>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onFormSubmit}
                enableReinitialize
              >
                {({
                  handleSubmit,
                  handleChange,
                  handleBlur,
                  values,
                  touched,
                  errors,
                  isValid,
                  dirty,
                }) => (
                  <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                    <div style={{ marginBottom: "16px" }}>
                      <Input
                        placeholder="Deine E-Mail Adresse"
                        name="email"
                        onChange={handleChange}
                        value={values.email}
                        style={{ width: "100%" }}
                      />
                      <S.ValidationText>{errors.email}</S.ValidationText>
                    </div>
                    <div style={{ marginBottom: "16px" }}>
                      <Input
                        type="password"
                        placeholder="Neues Passwort"
                        name="password"
                        onChange={handleChange}
                        value={values.password}
                        style={{ width: "100%" }}
                      />
                      <S.ValidationText>{errors.password}</S.ValidationText>
                    </div>
                    <div style={{ marginBottom: "16px" }}>
                      <Input
                        type="password"
                        placeholder="Passwort bestätigen"
                        name="confirmPassword"
                        onChange={handleChange}
                        value={values.confirmPassword}
                        style={{ width: "100%" }}
                      />
                      <S.ValidationText>
                        {errors.confirmPassword}
                      </S.ValidationText>
                    </div>
                    <br />
                    <button
                      className="btn-dark mx-auto"
                      type="submit"
                      disabled={loading}
                    >
                      Passwort ändern
                    </button>
                  </form>
                )}
              </Formik>
            </S.ModalBody>
          </S.ModalInnerContainer>
        </S.ModalContainer>
      </OutsideAlerter>
    </S.ModalBackdrop>
  );
};

export default PasswordResetModal;
