import styled from 'styled-components';
import { RegistrationContainer, RegistrationHeading, RegistrationSubHeading } from 'pages/registration/registration.styles';
import { NutritionFormMacroNutrientsDescription } from 'pages/macroNutritients/macroNutrients.styles';

// Images
import NutritionDone from 'assets/images/nutritionDone.svg';

export const FoodiaryFamilyContainer = styled(RegistrationContainer)``;

export const FoodiaryFamilyHeading = styled(RegistrationHeading)``;

export const FoodiaryFamilySubHeading = styled(RegistrationSubHeading)``;

export const FoodiaryFamilyDescription = styled(NutritionFormMacroNutrientsDescription)``;

export const FoodiaryFamilySVG = styled.div`
    width: 553px;
    height: 422px;
    margin: 56px auto 20px;
    background-image: url(${NutritionDone});
`;