import styled from "styled-components";
import { RegistrationContainer, RegistrationHeading, RegistrationSubHeading, } from 'pages/registration/registration.styles';

export const PremiumCoachingSuccessContainer = styled(RegistrationContainer)``;

export const PremiumCoachingSuccessHeading = styled(RegistrationHeading)``;

export const PremiumCoachingSuccessSubHeading = styled(RegistrationSubHeading)``;

// Premium Coaching Success Card styling
export const PremiumCoachingSuccessCardContainer = styled.div`
    margin-top: 20px;
`;

export const PremiumCoachingSuccessCardInnerContainer = styled.div`
    padding: 33px 26px 33px 28px;
`;

export const PremiumCoachingSuccessCommentUserProfile = styled.div`
    display: flex;
    align-items: center;
`;

export const PremiumCoahingSuccessCommentRating = styled.div`
    margin-left: 20px;
`

export const ProfileImage = styled.img`
    width: 72px;
    border-radius: 50%;
    border: 3px solid #8DCEC3;
`;

export const Rating = styled.img``;

export const Username = styled.div``;

export const Comment = styled.div`
    line-height: 21px;
    font-size: 14px;
    color: #44554C;
    font-weight: 400;
    margin-top: 1.5rem;
`;
