import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PhoneInput from "react-phone-number-input";
import { getUserRegistrationDataFromLocalStorage } from "configs/utils";
import * as Methods from "../../registration/registration.functions";
import * as Data from "../../registration/registration.utils";
// Components
import Button from "components/formComponents/button/Button";
import Input from "components/formComponents/input/Input";
import SquareCheckbox from "components/formComponents/checkbox/SquareCheckbox";

import FoodiaryLogo from "assets/images/Foodiary-Logo.PNG";
import ArrowLeft from "assets/images/arrowLeft.svg";
import { SidebarSteps } from "../data";
// Redux
import * as AuthActions from "redux/auth/auth.actions";
import * as S from "../OnBoardingPartOne.styles";
import OnboardingSidebar from "../OnboardingSidebar";

import Modal from "components/modal/Modal";
import CrossIcon from "assets/images/cross.svg";

const PersonalData = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const trainerProfileData = useSelector((state) => state.auth.trainerData);
  const error = useSelector((state) => state.auth.error);
  const [emailOverrideError, setEmailOverrideError] = useState(null);

  const [selectedNutrients, setSelectedNutrients] = useState({
    carbohydrates: 0,
    protein: 0,
    fat: 0,
  });

  const [nutrients, setNutrients] = useState([]);

  const [isChecked, setIsChecked] = useState({
    firstCheck: false,
    secondCheck: false,
  });
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [mobile, setMobile] = useState("");
  const [IsCreating, setIsCreating] = useState(false);
  const [IsEmailUpdating, SetIsEmailUpdating] = useState(false);

  const [Validations, SetValidations] = useState({
    isEmail: false,
    isEmailFormat: false,
    isFirstName: false,
    isLastName: false,
    isMobile: false,
    isFirstCheck: false,
    isSecondCheck: false,
    isPhoneValid: false,
  });
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  useEffect(() => {
    const savedCategory = JSON.parse(localStorage.getItem("foodCategory"));

    const allNutrients = {
      eatingHabit1: Data.eatingHabits1Data[0],
      eatingHabit2: Data.eatingHabits2Data[0],
      eatingHabit3: Data.eatingHabits3Data[0],
      eatingHabit4: Data.eatingHabits4Data[0],
      eatingHabit5: Data.eatingHabits5Data[0],
      eatingHabit2Vegan: Data.eatingHabits2veganData[0],
      eatingHabit3Vegan: Data.eatingHabits3veganData[0],
      eatingHabit4Vegan: Data.eatingHabits4veganData[0],
      eatingHabit5Vegan: Data.eatingHabits5veganData[0],
      eatingHabit2Vegetarian: Data.eatingHabits2vegetarianData[0],
      eatingHabit3Vegetarian: Data.eatingHabits3vegetarianData[0],
      eatingHabit4Vegetarian: Data.eatingHabits4vegetarianData[0],
      eatingHabit5Vegetarian: Data.eatingHabits5vegetarianData[0],
    };

    const defaultNutrients = Methods.setDefaultValuesOfCategories(
      savedCategory?.id,
      allNutrients
    );

    setNutrients(defaultNutrients);
  }, []);

  const getCalculatedRoutines = (list) => {
    const routines = {};
    list.map((item) => {
      routines[item?.heading] = item?.data?.map((t, i) => ({
        dayroutineID: i + 1,
        time: t,
      }));
    });

    return routines;
  };

  const getEatingHabit = (eatingHabits, id, flag) => {
    if (flag === "exact") {
      return eatingHabits.find((item) => item?.subCategoryId === id);
    }

    if (flag === "startsWith") {
      return eatingHabits.find((item) => item?.subCategoryId.startsWith(id));
    }
  };

  const validateForm = () => {
    const newValidations = {
      isFirstName: firstName === "",
      isLastName: lastName === "",
      isEmail: email === "",
      isEmailFormat: !emailRegex.test(email),
      isPhoneValid: mobile.length > 25 || mobile === "",
      isFirstCheck: !isChecked.firstCheck,
      isSecondCheck: !isChecked.secondCheck,
    };

    SetValidations(newValidations);

    return !Object.values(newValidations).some(Boolean);
  };

  const getValueInMinutes = (value) => {
    if (!value) return 0;

    return value * 60;
  };

  const updateRegistrationData = () => {

    if (!validateForm()) return;

    const userData = getUserRegistrationDataFromLocalStorage();
    console.log("userData : ", userData);

    const routines = {};
    userData?.dailyRoutineData.filter(item => Object.keys(item).length > 0).map((item) => {
      routines[item?.heading] = item.data.map((t, i) => ({
        dayroutineID: i + 1,
        time: t,
      }));
    });

    let formData = {
      sex: userData?.gender,
      aimID: userData?.goal,
      height: userData?.bodyDetails?.height,
      weight: userData?.bodyDetails?.weight,
      nutritiontypeID: 2,
      birthday: userData?.bodyDetails?.date,
      email: email,
      prename: firstName,
      surname: lastName,
      mobile: mobile,
      grant_type: "password",
      isTraining: userData?.isTraining,
      trainingdays: userData?.trainingDays
        ?.filter((item) => item.isActive)
        .map((i) => i?.id),
      totalTrainingDays: +userData?.trainingSessionsPerWeek,
      dailyRoutineData: userData?.dailyRoutineData,
      routines: getCalculatedRoutines(userData?.dailyRoutineData),
      movementtypes: [
        {
          minutes: getValueInMinutes(
            userData?.dailyActivitiesData?.sleepValue?.value
          ),
          movementtypeID: 1,
        },
        {
          minutes: getValueInMinutes(
            userData?.dailyActivitiesData?.lyingDownValue?.value
          ),
          // minutes: userData?.dailyActivitiesData?.lyingDownValue?.value * 60,
          movementtypeID: 2,
        },
        {
          minutes: getValueInMinutes(
            userData?.dailyActivitiesData?.standingValue?.value
          ),
          // minutes: userData?.dailyActivitiesData?.standingValue?.value * 60,
          movementtypeID: 3,
        },
        {
          minutes: getValueInMinutes(
            userData?.dailyActivitiesData?.sittingValue?.value
          ),
          // minutes: userData?.dailyActivitiesData?.sittingValue?.value * 60,
          movementtypeID: 4,
        },
        {
          minutes: getValueInMinutes(
            userData?.dailyActivitiesData?.walkingValue?.value
          ),
          // minutes: userData?.dailyActivitiesData?.walkingValue?.value * 60,
          movementtypeID: 5,
        },
        {
          minutes: getValueInMinutes(
            userData?.dailyActivitiesData?.exercisingValue?.value
          ),
          // minutes: userData?.dailyActivitiesData?.exercisingValue?.value * 60,
          movementtypeID: 6,
        },
      ],
      sleepValue: getValueInMinutes(
        userData?.dailyActivitiesData?.sleepValue?.value
      ),
      lyingDownValue: getValueInMinutes(
        userData?.dailyActivitiesData?.lyingDownValue?.value
      ),
      standingValue: getValueInMinutes(
        userData?.dailyActivitiesData?.standingValue?.value
      ),
      sittingValue: getValueInMinutes(
        userData?.dailyActivitiesData?.sittingValue?.value
      ),
      walkingValue: getValueInMinutes(
        userData?.dailyActivitiesData?.walkingValue?.value
      ),
      exercisingValue: getValueInMinutes(
        userData?.dailyActivitiesData?.exercisingValue?.value
      ),
      eatingHabit1: getEatingHabit(
        userData?.eatingHabits,
        "eatingHabit1",
        "startsWith"
      )?.eatingHabitData?.heading,
      eatingHabit2: getEatingHabit(
        userData?.eatingHabits,
        "eatingHabit2",
        "startsWith"
      )?.eatingHabitData?.heading,
      eatingHabit3: getEatingHabit(
        userData?.eatingHabits,
        "eatingHabit3",
        "startsWith"
      )?.eatingHabitData?.heading,
      eatingHabit4: getEatingHabit(
        userData?.eatingHabits,
        "eatingHabit4",
        "startsWith"
      )?.eatingHabitData?.heading,
      eatingHabit5: getEatingHabit(
        userData?.eatingHabits,
        "eatingHabit5",
        "startsWith"
      )?.eatingHabitData?.heading,
    };

    dispatch(AuthActions.setRegistrationData(formData));
    localStorage.setItem("RegistrationData", JSON.stringify(formData));

    setIsCreating(true);

    formData.nutritiontypeID = getNutiritionTypeId(
      userData?.goal,
      selectedNutrients
    );
    const values = formData;
    values.type = "web";
    values.trainerID = trainerProfileData.trainerID;
    console.log("formData", formData);
    if (userData?.isTraining) {
      values.trainingdays = +userData?.trainingSessionsPerWeek;
    }
    delete values.totalTrainingDays;
    delete values.dailyRoutineData;
    delete values.sleepValue;
    delete values.lyingDownValue;
    delete values.standingValue;
    delete values.sittingValue;
    delete values.walkingValue;
    delete values.exercisingValue;
    delete values.eatingHabit1;
    delete values.eatingHabit2;

    dispatch(
      AuthActions.registration1Request({
        formValues: values,
        func: (response) => {
          setIsCreating(false);
          // registrationSuccessRedirection();
          localStorage.setItem(
            "RegisteredUserDetails",
            JSON.stringify(response)
          );
          console.log("registration1Request====response", response);
          history.push("/onboarding/nutrition/creation-video");
        },
      })
    );
  };

  const getNutiritionTypeId = (aimID, selectedNutrients) => {
    const defaultSelectedNeutrients = {
      carbohydrates: 0,
      protein: 0,
      fat: 0,
    };

    const userData = getUserRegistrationDataFromLocalStorage();

    const subCategories = [
      getEatingHabit(userData?.eatingHabits, "eatingHabit1", "exact") ||
        Data.eatingHabits1Data[0],
      getEatingHabit(userData?.eatingHabits, "eatingHabit2", "exact") ||
        Data.eatingHabits2Data[0],
      getEatingHabit(userData?.eatingHabits, "eatingHabit3", "exact") ||
        Data.eatingHabits3Data[0],
      getEatingHabit(userData?.eatingHabits, "eatingHabit4", "exact") ||
        Data.eatingHabits4Data[0],
      getEatingHabit(userData?.eatingHabits, "eatingHabit5", "exact") ||
        Data.eatingHabits5Data[0],
      getEatingHabit(userData?.eatingHabits, "eatingHabit2-vegan", "exact") ||
        Data.eatingHabits2veganData[0],
      getEatingHabit(userData?.eatingHabits, "eatingHabit3-vegan", "exact") ||
        Data.eatingHabits3veganData[0],
      getEatingHabit(userData?.eatingHabits, "eatingHabit4-vegan", "exact") ||
        Data.eatingHabits4veganData[0],
      getEatingHabit(userData?.eatingHabits, "eatingHabit5-vegan", "exact") ||
        Data.eatingHabits5veganData[0],
      getEatingHabit(
        userData?.eatingHabits,
        "eatingHabit2-vegetarian",
        "exact"
      ) || Data.eatingHabits2vegetarianData[0],
      getEatingHabit(
        userData?.eatingHabits,
        "eatingHabit3-vegetarian",
        "exact"
      ) || Data.eatingHabits3vegetarianData[0],
      getEatingHabit(
        userData?.eatingHabits,
        "eatingHabit4-vegetarian",
        "exact"
      ) || Data.eatingHabits4vegetarianData[0],
      getEatingHabit(
        userData?.eatingHabits,
        "eatingHabit5-vegetarian",
        "exact"
      ) || Data.eatingHabits5vegetarianData[0],
    ];

    let uniqueMenuItems = [];

    subCategories.forEach((cat) => {
      nutrients.forEach((menuItem) => {
        if (cat.subCategoryId === menuItem?.subCategoryId) {
          if (menuItem.eatingHabitData.id === cat.eatingHabitData.id) {
            uniqueMenuItems.push(menuItem);
          }
        }
      });
    });

    const totalNutrients = Methods.addUpNutrients(
      defaultSelectedNeutrients,
      uniqueMenuItems
    );
    setSelectedNutrients(totalNutrients);

    const nutritionTypeID = Methods.calculateNutiritionTypeId(
      aimID,
      totalNutrients
    );

    // formData.nutritiontypeID = nutritionTypeID;
    console.log("nutritionTypeID", nutritionTypeID);
    // console.log("formData", formData);
    localStorage.setItem(
      "NutrtionIds",
      JSON.stringify({ nutritionTypeID, aimID })
    );
    return nutritionTypeID;
  };

  const handleBack = () => {
    const pathPrefix = "/onboarding/registration/eating-habits/";
    const paths = {
      1: pathPrefix + "vegetarisch-1",
      2: pathPrefix + "vegan-1",
      3: pathPrefix + "fleischhaltig-1",
    };

    history.push(
      paths[getUserRegistrationDataFromLocalStorage()?.dietCategory?.id]
    );
  };

  const handleNext = () => {
    try {
      updateRegistrationData();
    } catch (err) {
      console.log("error on handleNext : ", err);
    }
  };

  const removeEmailExistsError = () => {
    dispatch(AuthActions.registration1Failure({}));
    setIsCreating(false);
    setEmailOverrideError(null);
  };

  const handleOverrideEmail = () => {
    SetIsEmailUpdating(true);
    dispatch(
      AuthActions.registrationOverrideEmail({
        email,
        cb: (response) => {
          SetIsEmailUpdating(false);
          if (response instanceof Error) {
            console.log("we got an error : ", error);
            return setEmailOverrideError(error.message);
          }

          updateRegistrationData();
          // dispatch(AuthActions.registration1Failure({}));
          // history.push("/onboarding/nutrition/creation-video");
          // go to the next page after the email is updated without sending registration data
          // handleNext();
        },
      })
    );
  };

  const emailAlreadyExistsError =
    email && error?.message === "Benutzer existiert bereits";

  return (
    <>
      <S.OnBoardingPage className="starter">
        <S.OnBoardingContainer className="onboarding-container">
          <OnboardingSidebar step={2} />
          <S.OnBoardingContent className="onboarding-content">
            <S.OnBoardingHeader className="onboarding-header">
              <div className="d-flex align-items-top gap-3">
                <img
                  className="cursor-pointer"
                  src={ArrowLeft}
                  alt="back"
                  onClick={handleBack}
                />
                <div className="content">
                  <span className="small-title text-green font-rubik-medium">
                    Anamnese
                  </span>
                  <h6 className="header-title font-rubik-medium my-0">
                    Wir erstellen direkt dein Ernährungsplan
                  </h6>
                </div>
              </div>
              <div className="actions">
                <Button
                  color="rgba(248, 164, 146, 1)"
                  gradient="linear-gradient(60deg, rgba(46, 207, 150, 1), rgba(28, 157, 135, 1)) !important"
                  margin="0"
                  absolute={{ width: "fit-content", height: "2.5rem" }}
                  buttonStyle={{ padding: ".5625rem 2.5rem" }}
                  onClick={handleNext}
                  disabled={IsCreating}
                >
                  Weiter
                </Button>
              </div>
            </S.OnBoardingHeader>
            <S.OnBoardingBody className="onboarding-body">
              <div className="personal-data-content">
                <h3 className="text-size-20-28 text-dark-1 font-rubik-medium">
                  Bitte gib deine persönlichen Daten ein
                </h3>
                <p className="text-size-16-19 text-light-grey-1 font-rubik-regular">
                  Deine Daten benötigen wir zum ersten des Ernährungsplanes.
                </p>
                <br />
                {!!error?.error &&
                  Object.values(error?.error || {})
                    ?.flat()
                    ?.map((msg) => (
                      <>
                        <div className="alert danger">{msg}</div>
                      </>
                    ))}

                {error?.success === false && (
                  <div className="alert danger">{error?.message}</div>
                )}

                <div className="personal-data-form">
                  <div className="form-group">
                    <label className="mb-0">Vorname</label>
                    <Input
                      placeholder="Vorname"
                      onChange={(e) => {
                        setFirstName(e.target.value);
                        SetValidations({
                          ...Validations,
                          isFirstName: !e.target.value,
                        });
                      }}
                      value={firstName}
                    />
                    {Validations.isFirstName && (
                      <p className="text-size-14-17 text-red mb-0 mt-1">
                        Erforderlich
                      </p>
                    )}
                  </div>

                  <div className="form-group">
                    <label className="mb-0">Nachname</label>
                    <Input
                      placeholder="Nachname"
                      onChange={(e) => {
                        setLastName(e.target.value);
                        SetValidations({
                          ...Validations,
                          isLastName: !e.target.value,
                        });
                      }}
                      value={lastName}
                    />
                    {Validations.isLastName && (
                      <p className="text-size-14-17 text-red mb-0 mt-1">
                        Erforderlich
                      </p>
                    )}
                  </div>

                  <div className="form-group">
                    <label className="mb-0">E-Mail</label>
                    <Input
                      placeholder="E-Mail"
                      onChange={(e) => {
                        setEmail(e.target.value);
                        SetValidations({
                          ...Validations,
                          isEmail: !e.target.value,
                          isEmailFormat: !emailRegex.test(e.target.value),
                        });
                      }}
                      value={email}
                    />
                    {Validations.isEmail && (
                      <p className="text-size-14-17 text-red mb-0 mt-1">
                        Erforderlich
                      </p>
                    )}
                    {Validations.isEmailFormat && email && (
                      <p className="text-size-14-17 mb-0 mt-1">
                        E-Mail muss eine gültige Adresse sein
                      </p>
                    )}
                  </div>

                  <div className="form-group">
                    <label className="mb-0">Handynummer</label>
                    <PhoneInput
                      className="phone-input"
                      placeholder="+49 000 000 0000"
                      defaultCountry="DE"
                      international
                      countryCallingCodeEditable={false}
                      onChange={(value) => {
                        if (value?.length < 25) {
                          SetValidations({
                            ...Validations,
                            isPhoneValid: false,
                          });
                          setMobile(value);
                        } else {
                          SetValidations({
                            ...Validations,
                            isPhoneValid: true,
                          });
                        }
                      }}
                      value={mobile}
                    />
                    {Validations.isPhoneValid && !!mobile && (
                      <p className="text-size-14-17 mb-0 mt-1">
                        Bitte beachte das internationale Format
                      </p>
                    )}

                    {Validations.isPhoneValid && !mobile && (
                      <p className="text-size-14-17 text-red mb-0 mt-1">
                        Erforderlich
                      </p>
                    )}
                  </div>
                </div>
                <div className="checkboxes-container">
                  <div className="checkbox-group d-flex gap-2">
                    <SquareCheckbox
                      rounded="5px"
                      filled="#FFF"
                      isChecked={isChecked.firstCheck}
                      param="firstCheck"
                      setIsChecked={(v) => {
                        setIsChecked(v);
                        SetValidations({
                          ...Validations,
                          isFirstCheck: !v,
                        });
                      }}
                    />
                    <p className="text-size-16-24 text-dark-1 font-rubik-regular my-0">
                      Der Teilnehmer erklärt sich damit einverstanden, dass
                      seine Daten für die Erstellung des individuellen
                      Ernährungskonzeptes verwendet, verarbeitet und gespeichert
                      werden dürfen.
                    </p>
                  </div>
                  {Validations.isFirstCheck && (
                    <p className="text-size-14-17 text-red mb-0 mt-1">
                      Erforderlich
                    </p>
                  )}
                  <br />
                  <div className="checkbox-group d-flex gap-2">
                    <SquareCheckbox
                      rounded="5px"
                      filled="#FFF"
                      isChecked={isChecked.secondCheck}
                      param="secondCheck"
                      setIsChecked={(v) => {
                        setIsChecked(v);
                        SetValidations({
                          ...Validations,
                          isSecondCheck: !v,
                        });
                      }}
                    />
                    <p className="text-size-16-24 text-dark-1 font-rubik-regular my-0">
                      Der Teilnehmer hat die{" "}
                      <a
                        className="text-dark-1"
                        href="https://foodiary.app/terms/agb-coaching/"
                        target="_blank"
                      >
                        AGB Coaching
                      </a>{" "}
                      und die{" "}
                      <a
                        className="text-dark-1"
                        href="https://foodiary.app/terms/datenschutz/"
                        target="_blank"
                      >
                        Datenschutzbestimmungen
                      </a>{" "}
                      gelesen und verstanden.
                    </p>
                  </div>
                  {Validations.isSecondCheck && (
                    <p className="text-size-14-17 mb-0 text-red mt-1">
                      Erforderlich
                    </p>
                  )}
                </div>
              </div>
            </S.OnBoardingBody>
          </S.OnBoardingContent>
        </S.OnBoardingContainer>
      </S.OnBoardingPage>

      <Modal
        show={emailAlreadyExistsError || emailOverrideError}
        setShow={removeEmailExistsError}
        width="50%"
        height="auto"
        heading={
          <div className="d-flex align-items-center justify-content-between">
            <h4 className="my-0 text-left">E-Mail existiert bereits</h4>
            <img
              className="cursor-pointer"
              src={CrossIcon}
              onClick={removeEmailExistsError}
            />
          </div>
        }
      >
        <S.EmailModalContainer>
          {emailOverrideError ? (
            <div className="alert danger">{emailOverrideError}</div>
          ) : (
            ""
          )}
          <div className="update-email-bg"></div>
          <p>
            Wir haben festgestellt, dass es bereit einen Ernährungsplan mit
            dieser E-Mail gibt. Was möchtest du tun?{" "}
          </p>
          <div>
            <button
              onClick={handleOverrideEmail}
              className="btn-dark btn-pink"
              disabled={IsEmailUpdating}
            >
              Ernährungsplan überschreiben
            </button>
            <button
              onClick={removeEmailExistsError}
              className="btn-dark"
              disabled={IsEmailUpdating}
            >
              Neue E-Mail verwenden
            </button>
          </div>
        </S.EmailModalContainer>
      </Modal>
    </>
  );
};

export default PersonalData;
