import React, { useState, useEffect, useRef } from "react";
import * as S from "./trainerProfileDetails.styles";
// import { useParams, useHistory } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import { number, object, string, boolean } from "yup";
import { useIntl } from "react-intl";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import Slider from "react-slick";

// Components
import Input from "components/formComponents/input/Input";
import Modal from "components/modal/Modal";
import TextArea from "components/formComponents/textArea/TextArea";

import CrossIcon from "assets/images/cross.svg";
import LeftIconGreen from "assets/images/leftIconGreen.svg";
import RightIconGreen from "assets/images/rightIconGreen.svg";

// Redux
import * as AuthActions from "redux/auth/auth.actions";
import ErrorMessage from "components/formComponents/errorMessage/ErrorMessage";
import ImageUpload from "components/formComponents/imageUpload/ImageUpload";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";

const sliderImages = [
  {
    imgSource:
      "https://foodiaryproduction.s3.eu-central-1.amazonaws.com/Website_files/Coach+Website+-+Coaching+PRO+Buchung+%E2%80%93+1.jpg",
  },
  {
    imgSource:
      "https://foodiaryproduction.s3.eu-central-1.amazonaws.com/Website_files/Coach+Website+-+Mein+Angebot+%E2%80%93+1.jpg",
  },
  {
    imgSource:
      "https://foodiaryproduction.s3.eu-central-1.amazonaws.com/Website_files/Erstgespr%C3%A4ch+%E2%80%93+1.jpg",
  },
  {
    imgSource:
      "https://foodiaryproduction.s3.eu-central-1.amazonaws.com/Website_files/Coach+Website+-+Mein+Angebot+%E2%80%93+2.jpg",
  },
  {
    imgSource:
      "https://foodiaryproduction.s3.eu-central-1.amazonaws.com/Website_files/Coach+Website+-+Mein+Coaching+%E2%80%93+2.jpg",
  },
];

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className={className} onClick={onClick} style={{ ...style }}>
      <img src={RightIconGreen} alt="" />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className={className} onClick={onClick} style={{ ...style }}>
      <img src={LeftIconGreen} alt="" />
    </div>
  );
}

const TrainerProfileCoachCard = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const formSubmitRef = useRef(null);

  const loading = useSelector((state) => state.auth.loading);
  const trainerProfileData = useSelector((state) => state.auth.trainerData);
  const [selectedSubtitle, setSelectedSubtitle] = useState(
    trainerProfileData?.subtitle
  );
  const [selectedSpecialization, setSelectedSpecialization] = useState(null);
  const [selectedPracticalExperience, setSelectedPracticalExperience] =
    useState(null);
  const [qualifications, setQualifications] = useState([]);
  const [newQualification, setNewQualification] = useState({
    qualification: "",
    year: "",
    path: "",
  });
  const [updatedQualificationIds, setUpdatedQualificationIds] = useState([]);

  const [IsInformationModalOpen, SetIsInformationModalOpen] = useState(false);
  const [IsSubmitting, SetIsSubmitting] = useState(false);
  const [isTryToUpdating, setIsTryToUpdating] = useState(false);

  useEffect(() => {
    getCoachCardDetails();
  }, []);

  useEffect(() => {
    setSelectedSpecialization(
      specilizationOptions.find((s) => s.value === trainerProfileData?.step1)
    );
    setSelectedPracticalExperience(
      practicalExperienceOptions.find(
        (s) => s.value === trainerProfileData?.step4
      )
    );
    setQualifications(trainerProfileData.qualifications);
  }, [trainerProfileData]);

  const getCoachCardDetails = () => {
    dispatch(
      AuthActions.getCoachCardDetailsRequest({
        trainerID: trainerProfileData.id,
        funcSuccess: (res) => {
          if (!res?.success) {
          }
        },
      })
    );
  };

  const removeCoachCardQualification = (qualificationID) => {
    dispatch(
      AuthActions.removeCoachCardQualificationRequest({
        qualificationID,
        funcSuccess: (res) => {
          if (!res?.success) {
            let allErrors = [];
            try {
              if (res.data?.error) {
                for (let key in res.data?.error) {
                  if (res.data?.error.hasOwnProperty(key)) {
                    allErrors = allErrors.concat(res.data?.error[key]);
                  }
                }
              }
              allErrors.forEach((error) => toast(`❌ ${error}`));
            } catch (error) {
              toast(`❌ Etwas ist schiefgelaufen!`);
            }
          } else {
            toast(`✅ Eine Qualifikation wurde erfolgreich entfernt`);
          }
          getCoachCardDetails();
        },
      })
    );
  };

  const handleImageChange = (id, file) => {
    const index = qualifications.findIndex((s) => s.qualificationId === id);

    if (index !== -1) {
      const updatedQualifications = [...qualifications];
      updatedQualifications[index] = {
        ...updatedQualifications[index],
        path: file,
      };
      setQualifications(updatedQualifications);
    } else setNewQualification((q) => ({ ...q, path: file }));
  };

  const specilizationOptions = [
    { id: "bodyBuilding", value: "Bodybuilding", label: "Bodybuilding" },
    {
      id: "fitnessNutrition",
      value: "Fitnessernährung",
      label: "Fitnessernährung",
    },
    { id: "veganDiet", value: "Vegane Ernährung", label: "Vegane Ernährung" },
    {
      id: "vegetarianDiet",
      value: "Vegetarische Ernährung",
      label: "Vegetarische Ernährung",
    },
    {
      id: "balancedDiet",
      value: "Ausgewogene Ernährung",
      label: "Ausgewogene Ernährung",
    },
    {
      id: "competitiveSports",
      value: "Leistungssport",
      label: "Leistungssport",
    },
    {
      id: 'Vegetarische_Ernährung',
      label: 'Vegetarische Ernährung',
      value: 'Vegetarische Ernährung'
    },
    {
      id: 'Hormonbalance',
      label: 'Hormonbalance',
      value: 'Hormonbalance'
    },
    {
      id: 'Ernährung_für_Senioren',
      label: 'Ernährung für Senioren',
      value: 'Ernährung für Senioren'
    },
    {
      id: 'Kinder-und_Jugendernährung',
      label: 'Kinder- und Jugendernährung',
      value: 'Kinder- und Jugendernährung'
    },
    {
      id: 'Adipositas-Management',
      label: 'Adipositas-Management',
      value: 'Adipositas-Management'
    },
    {
      id: 'Gewichtsmanagement_bei_Frauen',
      label: 'Gewichtsmanagement bei Frauen',
      value: 'Gewichtsmanagement bei Frauen'
    },
    {
      id: 'Gewichtsmanagement_bei_Männern',
      label: 'Gewichtsmanagement bei Männern',
      value: 'Gewichtsmanagement bei Männern'
    },
    {
      id: 'Darmgesundheit',
      label: 'Darmgesundheit',
      value: 'Darmgesundheit'
    },
    {
      id: 'Ernährung_in_der_Schwangerschaft',
      label: 'Ernährung in der Schwangerschaft',
      value: 'Ernährung in der Schwangerschaft'
    },
    {
      id: 'Ernährung_in_der_Menopause',
      label: 'Ernährung in der Menopause',
      value: 'Ernährung in der Menopause'
    },
  ];

  const practicalExperienceOptions = [
    { id: "yes", value: "Ja", label: "Ja" },
    { id: "no", value: "Nein", label: "Nein" },
  ];

  const initialValues = {
    subtitle: selectedSubtitle,
    specilization: trainerProfileData.step1 || "",
    nutiritionistSince: trainerProfileData.step2 || "",
    customers: trainerProfileData.step3 || "",
    practicalExperience: trainerProfileData.step4 || "",
    coachCardIntroText: trainerProfileData.description || "",
    coachProfileIntroText:
      trainerProfileData.pp_Intoduction_text_profilpage || "",
  };

  const validationSchema = object().shape({
    specilization: string(),
    nutiritionistSince: number()
      .required("Erforderlich")
      .typeError("Das Feld muss eine Zahl enthalten."),
    customers: number()
      .required("Erforderlich")
      .typeError("Das Feld muss eine Zahl enthalten."),
    practicalExperience: string(),
    coachCardIntroText: string(),
    coachProfileIntroText: string(),
  });

  const updateFormData = (action, formData, errorMessage) => {
    return new Promise((resolve, reject) => {
      dispatch(
        action({
          formData,
          funcSuccess: (res) => {
            if (!res?.success) {
              let allErrors = [];
              try {
                if (res.data?.error) {
                  for (let key in res.data?.error) {
                    if (res.data?.error.hasOwnProperty(key)) {
                      allErrors = allErrors.concat(res.data?.error[key]);
                    }
                  }
                }
                allErrors.forEach((error) => toast(`❌ ${error}`));
              } catch (error) {
                toast(`❌ ${errorMessage}`);
              }
              resolve(false);
            } else {
              resolve(true);
            }
          },
        })
      );
    });
  };

  const onFormSubmit = async (values, { resetForm }) => {
    let updatePromises = [];
    SetIsSubmitting(true);

    const generalFormData = new FormData();
    generalFormData.append("trainerID", trainerProfileData.trainerID);
    generalFormData.append("FranchiseID", trainerProfileData.FranchiseID);

    generalFormData.append("step1", selectedSpecialization?.value || "");
    generalFormData.append("step2", values.nutiritionistSince || "");
    generalFormData.append("step3", values.customers || "");
    generalFormData.append("step4", selectedPracticalExperience?.value || "");
    generalFormData.append("subtitle", selectedSubtitle || "");

    generalFormData.append("description", values.coachCardIntroText || "");
    generalFormData.append(
      "pp_Intoduction_text_profilpage",
      values.coachProfileIntroText || ""
    );

    updatePromises.push(() =>
      updateFormData(
        AuthActions.updateCoachCardDetailsRequest,
        generalFormData,
        "Bitte überprüfe deine Eingaben"
      )
    );

    const updateQualificationPromises = updateQualifications();

    if (!updateQualificationPromises) {
      SetIsSubmitting(false);
      resetForm();
      return;
    }

    updatePromises.push(...updateQualificationPromises);

    const results = await Promise.all(
      updatePromises.map((promise) => promise())
    );
    if (results.every((result) => result === true)) {
      toast("✅ Deine Änderungen wurden gespeichert");
    }

    getCoachCardDetails();

    SetIsSubmitting(false);
    setNewQualification({
      qualification: "",
      year: "",
      path: "",
    });
    resetForm();
  };

  const onProfileUpdate = () => {
    if (formSubmitRef.current) {
      formSubmitRef.current.handleSubmit();
    }
  };

  const updateQualifications = () => {
    let failedInValidating = true;
    let updateQualificationPromises = [];
    if (
      newQualification.year ||
      newQualification.qualification ||
      newQualification.path
    ) {
      if (
        !(
          newQualification.year &&
          newQualification.qualification &&
          newQualification.path
        )
      ) {
        if (!newQualification.path) {
          toast("❌ Bitte lade eine Qualifikation als PDF hoch.");
        } else
          toast(
            "❌ Um eine neue Qualifikation anzulegen, füllen Sie bitte alle Felder aus."
          );
        failedInValidating = false;
      } else if (
        !newQualification.year &&
        !newQualification.qualification &&
        !newQualification.path
      ) {
      } else {
        const newQualificationData = new FormData();
        newQualificationData.append(
          "connected_trainer",
          trainerProfileData.trainerID
        );
        newQualificationData.append(
          "qualification",
          newQualification.qualification
        );
        newQualificationData.append("year", newQualification.year);
        newQualificationData.append("path", newQualification.path);

        updateQualificationPromises.push(() =>
          updateFormData(
            AuthActions.addCoachCardQualificationRequest,
            newQualificationData,
            "Neue Qualifikation konnte nicht erstellt werden"
          )
        );
      }
    }

    const { updatedQualifications } = findChangedQualifications(
      qualifications,
      trainerProfileData.qualifications
    );

    failedInValidating &&
      updatedQualifications.forEach((updatedQualification) => {
        if (
          !(
            updatedQualification.year &&
            updatedQualification.qualification &&
            updatedQualification.path
          )
        ) {
          toast(
            "❌ Bitte füllen Sie alle Felder für aktualisierte Qualifikationen aus"
          );
          failedInValidating = false;
        }
        const updatedQualificationData = new FormData();

        updatedQualificationData.append(
          "connected_trainer",
          trainerProfileData.trainerID
        );
        updatedQualificationData.append(
          "qualification",
          updatedQualification.qualification
        );

        updatedQualificationData.append("year", updatedQualification.year);
        typeof updatedQualification.path === File &&
          updatedQualificationData.append("path", updatedQualification.path);
        updatedQualificationData.append(
          "qualification_id",
          updatedQualification.qualificationId
        );

        updateQualificationPromises.push(() =>
          updateFormData(
            AuthActions.updateCoachCardQualificationRequest,
            updatedQualificationData,
            "Eine Qualifikation konnte nicht aktualisiert werden"
          )
        );
      });

    // removedQualifications.forEach(removedQualification => {
    //   updateQualificationPromises.push(() =>updateFormData(AuthActions.removeCoachCardQualificationRequest, removedQualification.qualificationId, "Failed to remove a qualification"))
    // })

    if (failedInValidating) {
      return updateQualificationPromises;
    } else return false;
  };

  // const removeQualification = (qualificationId) => {
  //   const index = qualifications.findIndex(s => s.qualificationId === qualificationId);
  //   if (index !== -1) {
  //     const updatedQualifications = [...qualifications];
  //     updatedQualifications.splice(index, 1);
  //     setQualifications(updatedQualifications);
  //   }
  // }

  const findChangedQualifications = (newQualifications, qualifications) => {
    const qualificationsMap = new Map(
      qualifications?.map((q) => [q.qualificationId, q])
    );
    // const newQualificationsMap = new Map(newQualifications?.map(q => [q.qualificationId, q]));

    // Find removed qualifications
    // const removedQualifications = qualifications.filter(q => !newQualificationsMap.has(q.qualificationId));

    // Find updated qualifications
    const updatedQualifications = newQualifications.filter((q) => {
      const original = qualificationsMap.get(q.qualificationId);
      return original && JSON.stringify(original) !== JSON.stringify(q);
    });

    return {
      // removedQualifications,
      updatedQualifications,
    };
  };

  let settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    infinite: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  return (
    <S.TrainerProfileDetailsOverallContainer>
      <S.TrainerProfileDetailsInnerContainer className="p-0">
        <S.Header className="header">
          <div>
            <S.TrainerProfileDetailsSectionHeading>
              Coach Card
            </S.TrainerProfileDetailsSectionHeading>
            <S.TrainerProfileDetailsSectionSubHeading>
              Die Coach Card wird während deinem Erstgespäch angezeigt.
            </S.TrainerProfileDetailsSectionSubHeading>
          </div>
          <div className="header-actions">
            <button
              className="btn-outline sm"
              onClick={() => SetIsInformationModalOpen(true)}
            >
              Platzierungen anzeigen
            </button>
            <button
              className="btn-dark"
              disabled={loading || IsSubmitting}
              onClick={onProfileUpdate}
            >
              {IsSubmitting ? "wird gespeichert…" : "Änderungen speichern"}
            </button>
          </div>
        </S.Header>
        
        <S.Divider />

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onFormSubmit}
          innerRef={formSubmitRef}
          enableReinitialize
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            errors,
            isValid,
            dirty,
            isSubmitting,
            setFieldValue,
          }) => (
            <form noValidate autoComplete="off" onSubmit={handleSubmit}>
              <S.PaddingBox>
                <div>
                  <S.SectionHeading>
                    {intl.formatMessage({
                      id: "trainerProfile.coachFor.capitalize",
                    })}
                  </S.SectionHeading>
                  <br />
                </div>

                <S.TrainerProfileDetailsSubtitlesBox>
                  <S.TrainerProfileDetailsSubtitle
                    active={selectedSubtitle === "Muskelaufbau bulk"}
                    onClick={() => setSelectedSubtitle("Muskelaufbau bulk")}
                  >
                    Muskelaufbau bulk
                  </S.TrainerProfileDetailsSubtitle>
                  <S.TrainerProfileDetailsSubtitle
                    active={selectedSubtitle === "Muskelaufbau lean"}
                    onClick={() => setSelectedSubtitle("Muskelaufbau lean")}
                  >
                    Muskelaufbau lean
                  </S.TrainerProfileDetailsSubtitle>
                  <S.TrainerProfileDetailsSubtitle
                    active={selectedSubtitle === "Abnehmen"}
                    onClick={() => setSelectedSubtitle("Abnehmen")}
                  >
                    Abnehmen
                  </S.TrainerProfileDetailsSubtitle>
                  <S.TrainerProfileDetailsSubtitle
                    active={selectedSubtitle === "Gesunde Ernährung"}
                    onClick={() => setSelectedSubtitle("Gesunde Ernährung")}
                  >
                    Gesunde Ernährung
                  </S.TrainerProfileDetailsSubtitle>
                </S.TrainerProfileDetailsSubtitlesBox>
                <br />
                {isTryToUpdating && (
                  <div className="alert danger">
                    Wir arbeiten mit Hochdruck daran, die Funktion wieder
                    herzustellen. Am 14. Juni sollte alles wie gewohnt
                    funktionieren.
                  </div>
                )}
              </S.PaddingBox>
              <S.Divider />
              <S.PaddingBox>
                <div>
                  <S.SectionHeading>Coach Details</S.SectionHeading>
                  <br />
                </div>

                <S.TrainerProfileDetailsSection1InputContainer>
                  <div>
                    <S.TrainerProfileDetailsSection1InputLabel>
                      {intl.formatMessage({
                        id: "trainerProfile.specilization",
                      })}
                    </S.TrainerProfileDetailsSection1InputLabel>
                    <Select
                      options={specilizationOptions}
                      className="faq-select"
                      classNamePrefix="react-select"
                      name="specilization"
                      onChange={(v) => {
                        handleChange({
                          target: {
                            value: v?.value,
                          },
                        });
                        setSelectedSpecialization(v);
                      }}
                      value={selectedSpecialization}
                      placeholder={intl.formatMessage({
                        id: "trainerProfile.specilization",
                      })}
                      isSearchable={false}
                    />
                  </div>
                  <div>
                    <S.TrainerProfileDetailsSection1InputLabel>
                      {intl.formatMessage({
                        id: "trainerProfile.practicalExperience",
                      })}
                    </S.TrainerProfileDetailsSection1InputLabel>

                    <S.TrainerProfileDetailsSubtitlesBox>
                      <S.TrainerPracticalExperienceSubtitle
                        active={selectedPracticalExperience?.value === "Ja"}
                        onClick={() =>
                          setSelectedPracticalExperience({
                            id: "yes",
                            label: "Ja",
                            value: "Ja",
                          })
                        }
                      >
                        Ja
                      </S.TrainerPracticalExperienceSubtitle>
                      <S.TrainerPracticalExperienceSubtitle
                        active={selectedPracticalExperience?.value === "Nein"}
                        onClick={() =>
                          setSelectedPracticalExperience({
                            id: "no",
                            label: "Nein",
                            value: "Nein",
                          })
                        }
                      >
                        Nein
                      </S.TrainerPracticalExperienceSubtitle>
                    </S.TrainerProfileDetailsSubtitlesBox>
                  </div>
                  <div>
                    <S.TrainerProfileDetailsSection1InputLabel>
                      {intl.formatMessage({
                        id: "trainerProfile.nutiritionistSince",
                      })}
                    </S.TrainerProfileDetailsSection1InputLabel>
                    <Input
                      filled
                      style={{ width: "280px", marginBottom: "10px" }}
                      placeholder={intl.formatMessage({
                        id: "trainerProfile.nutiritionistSince",
                      })}
                      name="nutiritionistSince"
                      onChange={handleChange}
                      value={values.nutiritionistSince}
                    />
                    {touched.nutiritionistSince &&
                      errors.nutiritionistSince && (
                        <ErrorMessage error={errors.nutiritionistSince} />
                      )}
                  </div>
                  <div>
                    <S.TrainerProfileDetailsSection1InputLabel>
                      {intl.formatMessage({
                        id: "trainerProfile.customers",
                      })}
                    </S.TrainerProfileDetailsSection1InputLabel>
                    <Input
                      filled
                      style={{ width: "280px", marginBottom: "10px" }}
                      placeholder={intl.formatMessage({
                        id: "trainerProfile.customers",
                      })}
                      name="customers"
                      onChange={handleChange}
                      value={values.customers}
                    />
                    {touched.customers && errors.customers && (
                      <ErrorMessage error={errors.customers} />
                    )}
                  </div>
                </S.TrainerProfileDetailsSection1InputContainer>

                <S.TrainerProfileCoachCardQualificationContainer>
                  <div>
                    <S.SectionHeading>
                      {intl.formatMessage({
                        id: "trainerProfile.qualifications",
                      })}
                    </S.SectionHeading>
                  </div>
                  <S.TrainerProfileCoachCardQualificationRow>
                    <S.TrainerProfileCoachCardQualificationYear>
                      <S.TrainerProfileDetailsSection1InputLabel>
                        {intl.formatMessage({
                          id: "trainerProfile.year",
                        })}
                      </S.TrainerProfileDetailsSection1InputLabel>
                    </S.TrainerProfileCoachCardQualificationYear>
                    <S.TrainerProfileCoachCardQualificationDegree>
                      <S.TrainerProfileDetailsSection1InputLabel>
                        {intl.formatMessage({
                          id: "trainerProfile.qualificationDetails",
                        })}
                      </S.TrainerProfileDetailsSection1InputLabel>
                    </S.TrainerProfileCoachCardQualificationDegree>
                  </S.TrainerProfileCoachCardQualificationRow>
                  {qualifications &&
                    qualifications?.map((qualificationItem) => (
                      <S.TrainerProfileCoachCardQualificationRow>
                        <S.TrainerProfileCoachCardQualificationYear>
                          <Input
                            filled
                            style={{ width: "80px", margin: "0" }}
                            placeholder="2024"
                            name="qualificationYear"
                            onChange={(e) => {
                              handleChange({
                                target: {
                                  value: e.target.value,
                                  name: e.target.name,
                                },
                              });
                              setQualifications((prevQualifications) =>
                                prevQualifications.map((q) =>
                                  q.qualificationId ===
                                  qualificationItem.qualificationId
                                    ? { ...q, year: e.target.value }
                                    : q
                                )
                              );
                            }}
                            value={qualificationItem.year}
                          />
                          {/* {touched.nutiritionistSince &&
                          errors.nutiritionistSince && (
                            <ErrorMessage error={errors.nutiritionistSince} />
                          )} */}
                        </S.TrainerProfileCoachCardQualificationYear>
                        <S.TrainerProfileCoachCardQualificationDegree selected>
                          <Input
                            filled
                            style={{ width: "100%", margin: "0" }}
                            placeholder="Benenne deine Qualifikation"
                            name="qualificationDegree"
                            onChange={(e) => {
                              handleChange({
                                target: {
                                  value: e.target.value,
                                  name: e.target.name,
                                },
                              });
                              setQualifications((prevQualifications) =>
                                prevQualifications.map((q) =>
                                  q.qualificationId ===
                                  qualificationItem.qualificationId
                                    ? { ...q, qualification: e.target.value }
                                    : q
                                )
                              );
                            }}
                            value={qualificationItem.qualification}
                          />
                        </S.TrainerProfileCoachCardQualificationDegree>
                        <S.TrainerProfileCoachCardQualificationButton>
                          <ImageUpload
                            onImageChange={handleImageChange}
                            id={qualificationItem.qualificationId}
                          />
                        </S.TrainerProfileCoachCardQualificationButton>
                        <S.TrainerProfileCoachCardRemoveButton
                          onClick={() =>
                            removeCoachCardQualification(
                              qualificationItem.qualificationId
                            )
                          }
                        >
                          <DeleteOutlineIcon />
                        </S.TrainerProfileCoachCardRemoveButton>
                      </S.TrainerProfileCoachCardQualificationRow>
                    ))}
                  <S.TrainerProfileCoachCardQualificationRow>
                    <S.TrainerProfileCoachCardQualificationYear>
                      <Input
                        filled
                        style={{ width: "80px", margin: "0" }}
                        placeholder="2024"
                        name="qualificationYear"
                        onChange={(e) => {
                          handleChange({
                            target: {
                              value: e.target.value,
                              name: e.target.name,
                            },
                          });
                          setNewQualification((prev) => ({
                            ...prev,
                            year: e.target.value,
                          }));
                        }}
                        value={newQualification?.year || ""}
                      />
                      {/* {touched.nutiritionistSince &&
                        errors.nutiritionistSince && (
                          <ErrorMessage error={errors.nutiritionistSince} />
                        )} */}
                    </S.TrainerProfileCoachCardQualificationYear>
                    <S.TrainerProfileCoachCardQualificationDegree
                      selected={newQualification.path ? true : false}
                    >
                      <Input
                        filled
                        style={{ width: "100%", margin: "0" }}
                        placeholder="Benenne deine Qualifikation"
                        name="qualificationDegree"
                        onChange={(e) => {
                          handleChange({
                            target: {
                              value: e.target.value,
                              name: e.target.name,
                            },
                          });
                          setNewQualification((prev) => ({
                            ...prev,
                            qualification: e.target.value,
                          }));
                        }}
                        value={newQualification?.qualification || ""}
                      />
                    </S.TrainerProfileCoachCardQualificationDegree>
                    <S.TrainerProfileCoachCardQualificationButton>
                      <ImageUpload
                        onImageChange={handleImageChange}
                        id={"newQualification"}
                      />
                    </S.TrainerProfileCoachCardQualificationButton>
                  </S.TrainerProfileCoachCardQualificationRow>
                </S.TrainerProfileCoachCardQualificationContainer>

                <div className="textarea-box">
                  <S.TrainerProfileDetailsSection1InputLabel className="info-label">
                    {intl.formatMessage({
                      id: "trainerProfile.coachCardIntroText",
                    })}
                  </S.TrainerProfileDetailsSection1InputLabel>
                  <TextArea
                    rows={6}
                    placeholder={intl.formatMessage({
                      id: "trainerProfile.coachCardIntroText",
                    })}
                    name="coachCardIntroText"
                    onChange={handleChange}
                    value={values.coachCardIntroText}
                    style={{
                      margin: "0 0 1rem",
                    }}
                  />
                </div>
                <div className="textarea-box">
                  <S.TrainerProfileDetailsSection1InputLabel className="info-label">
                    {intl.formatMessage({
                      id: "trainerProfile.coachProfileIntroText",
                    })}
                  </S.TrainerProfileDetailsSection1InputLabel>
                  <TextArea
                    rows={6}
                    placeholder={intl.formatMessage({
                      id: "trainerProfile.coachProfileIntroText",
                    })}
                    name="coachProfileIntroText"
                    onChange={handleChange}
                    value={values.coachProfileIntroText}
                    style={{
                      margin: "0",
                    }}
                  />
                </div>
              </S.PaddingBox>
            </form>
          )}
        </Formik>
      </S.TrainerProfileDetailsInnerContainer>
      <br />
      <Modal
        width="550px"
        height="auto"
        show={IsInformationModalOpen}
        setShow={() => null}
        heading={
          <div className="d-flex align-items-center justify-content-between">
            <h4 className="my-0 text-left">Infoscreen </h4>
            <img
              className="cursor-pointer"
              src={CrossIcon}
              alt="CrossIcon"
              onClick={() => SetIsInformationModalOpen(false)}
            />
          </div>
        }
      >
        <div className="modal-body">
          <div className="slider-container info-modal-slider w-100">
            <p className="text-center arrows-text">Platzierung finden</p>
            <br />
            <Slider {...settings}>
              {sliderImages.map((item) => (
                <div className="slide-item">
                  <img src={item.imgSource} alt="" />
                </div>
              ))}
            </Slider>
          </div>
          <div className="slider-content">
            <h6>Die richtige Platzierung für deine Bilder</h6>
            <br />
            <S.Divider />
            <br />
            <p>
              Hier findest du die Platzierung der einzelnen Medien, welche du
              auf der Webseite veröffentlichen kannst.
            </p>
          </div>
        </div>
      </Modal>
    </S.TrainerProfileDetailsOverallContainer>
  );
};

export default TrainerProfileCoachCard;
