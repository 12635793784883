import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector } from "react-redux";
import moment from 'moment';
import {useHistory} from 'react-router-dom';
import * as S from "./overviewBasic.styles";
import {getRandomValueInRange} from "configs/utils";

// Components
import Topbar from 'components/topbar/Topbar';
import Switch from 'components/formComponents/switch/Switch';
import TranslationMessage from 'configs/translations/TranslationMessage';

// Images
import proGrey from "assets/images/proGrey.svg";

// Redux
import * as SupplementsActions from 'redux/supplements/supplements.actions';
import * as AuthActions from "redux/auth/auth.actions";

const OverviewBasic = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const healthScoreData = useSelector(state => state.auth.healthScoreData);
    const registrationData = useSelector(state => state.auth.registrationData);
    const [foodiaryPro, setFoodiaryPro] = useState(false);
    if(foodiaryPro) history.push('/subscriptions')

    useEffect(() => {
        dispatch(AuthActions.setHealthScoreData({foodiaryPro: false}))
    }, [])

    const successRedirection = () => history.push('/foodiary/family')

    const handleFoodiarySubscription = () => {
        const formValues = {
            userID: registrationData.userID, 
            date: moment().format('YYYY-MM-DD'),
            subscribe_status: 'BASIC-USER', 
            trainerID: registrationData.trainerID,
        }
        dispatch(SupplementsActions.updateUserSubscriptionRequest({formValues: formValues, func: successRedirection}))
    }

    const onFoodiaryProChange = () => {
        setFoodiaryPro(!foodiaryPro); 
    }

    return (
        <>
            <Topbar prev="/orderDetails" next="/foodiary/family" nextButtonId="foodiaryBasic" onNext={handleFoodiarySubscription} progress={'92%'} percent={getRandomValueInRange(40, 45)} actualCost={healthScoreData.totalSupplementsCost} healthReport onClose={() => history.push('/login')} close />
            <S.OverviewBasicContainer>
                <S.OverviewBasicHeading>
                    <TranslationMessage id="overviewBasic.heading" />
                </S.OverviewBasicHeading>
                <S.OverviewBasicInnerContainer>
                    <S.OverviewBasicSubHeadingContainer>
                        <S.OverviewBasicSwitchContainer>
                            <S.OverviewBasicSwitchHeading>Foodiary BASIC</S.OverviewBasicSwitchHeading>
                        </S.OverviewBasicSwitchContainer>
                        <Switch switchedOn={foodiaryPro} onChange={onFoodiaryProChange} />
                        <S.OverviewBasicFoodiaryPROContainer>
                            <S.OverviewBasicFoodiaryText>Foodiary</S.OverviewBasicFoodiaryText>
                            <S.OverviewBasicPROImage src={proGrey} alt="pro" />
                        </S.OverviewBasicFoodiaryPROContainer>
                    </S.OverviewBasicSubHeadingContainer>
                </S.OverviewBasicInnerContainer>
                <S.OverBasicDescriptionContainer>
                    <S.OverBasicDescriptionInnerContainer>
                        <TranslationMessage id="overviewBasic.description" />
                    </S.OverBasicDescriptionInnerContainer>
                </S.OverBasicDescriptionContainer>
            </S.OverviewBasicContainer>
        </>
    )
}

export default OverviewBasic