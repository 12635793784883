import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import * as S from "./healthScoreInvestigation.styles";

// Components
import TranslationMessage from 'configs/translations/TranslationMessage';
import Topbar from 'components/topbar/Topbar';
import Card from 'components/card/Card';
import Switch from 'components/formComponents/switch/Switch';

// Redux
import * as AuthActions from "redux/auth/auth.actions";

const HealthScoreInvestigation = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const healthScoreData = useSelector(state => state.auth.healthScoreData);
    
    const [question1, setQuestion1] = useState(healthScoreData ? healthScoreData.question1 : false);
    const [question2, setQuestion2] = useState(healthScoreData ? healthScoreData.question2 : false);
    const [question3, setQuestion3] = useState(healthScoreData ? healthScoreData.question3 : false);
    const [question4, setQuestion4] = useState(healthScoreData ? healthScoreData.question4 : false);
    const [question5, setQuestion5] = useState(healthScoreData ? healthScoreData.question5 : false);

    const QuestionData = [
        { id: "question1", question: "healthScore.investigation.question1", checked: question1, func: setQuestion1 },
        { id: "question2", question: "healthScore.investigation.question2", checked: question2, func: setQuestion2 },
        { id: "question3", question: "healthScore.investigation.question3", checked: question3, func: setQuestion3 },
        { id: "question4", question: "healthScore.investigation.question4", checked: question4, func: setQuestion4 },
        { id: "question5", question: "healthScore.investigation.question5", checked: question5, func: setQuestion5 },
    ]

    const questionsData = { question1, question2, question3, question4, question5 };

    const handleNext = () => {
        dispatch(AuthActions.setHealthScoreData(questionsData))
        history.push('/healthscore/priority')
    }

    return (
        <>
            {/* percent={0} healthReport  */}
            <Topbar next="/healthscore/priority" onNext={handleNext} onClose={() => history.push('/login')} progress={'9%'} close />
            <S.HealthScoreInvestigationFormContainer>
                <S.HealthScoreInvestigationFormHeading><TranslationMessage id="healthScore.investigation.heading" /></S.HealthScoreInvestigationFormHeading>
                <S.HealthScoreInvestigationFormSubHeading><TranslationMessage id="healthScore.investigation.subHeading" /></S.HealthScoreInvestigationFormSubHeading>
                <Card
                    width="554px"
                    height="310px"
                    margin="auto"
                    textAlign="center"
                    styles={{ marginTop: "20px" }}
                >
                    <S.CardInnerContainer>
                        {QuestionData.map((item) => {
                            return (
                                <S.QuestionBoxContainer
                                    key={item.id}
                                >
                                    <TranslationMessage id={item.question} />
                                    <Switch switchedOn={item.checked} onChange={() => item.func(!item.checked)} />
                                </S.QuestionBoxContainer>
                            )
                        })}
                    </S.CardInnerContainer>
                </Card>
            </S.HealthScoreInvestigationFormContainer>
        </>
    )
}

export default HealthScoreInvestigation