import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector } from "react-redux";
import * as S from "./nutritionRecipes.styles";
import TranslationMessage from 'configs/translations/TranslationMessage';
import { getImageUrl, getS3ImageUrl, getNutritionImageUrl } from "configs/utils";

// Components
import Topbar from 'components/topbar/Topbar';
import Loader from "components/loader/Loader";
import Button from 'components/formComponents/button/Button';

// Redux
import * as NutritionActions from 'redux/nutrition/nutrition.actions';
import ToolTip from 'components/ToolTip/ToolTip';

const NutritionRecipes = () => {
    const dispatch = useDispatch();
    const loading = useSelector(state => state.auth.loading);
    const registrationData = useSelector(state => state.auth.registrationData);
    const recipesData = useSelector(state => state.nutrition.nutritionRecipes);

    const [selectedFilter, setSelectedFilter] = useState(1);
    const [newDataIndex, setNewDataIndex] = useState(0);
    const allData = recipesData && [recipesData[newDataIndex], recipesData[newDataIndex + 1]];

    useEffect(() => {
        setNewDataIndex(0);
        dispatch(NutritionActions.getNutritionRecipesRequest({
            nutritiontypeID: registrationData.nutritiontypeID,
            mealId: selectedFilter
        }))
    }, [dispatch, registrationData, selectedFilter])

    const buttonValues = [
        {id: 1, value: "Frühstück"},
        {id: 2, value: "Mittagessen"},
        {id: 3, value: "Abendessen"},
        // {id: 4, value: "Snacks"},
    ];
    
    const EatingHabitsContent = () => {
        return (
            <>
                {allData && allData.map(data => {
                    return (
                        <S.NutritionFormRecipesCard key={data?.id}>
                            <S.NutritionFormRecipesCardImageContainer>
                                <S.NutritionFormRecipesCardImage src={getNutritionImageUrl(data?.imgpath, data?.imgname, data?.imgtype)} alt={data?.id} />
                            </S.NutritionFormRecipesCardImageContainer>
                            <S.NutritionFormRecipesCardInnerContainer>
                                <S.NutritionFormRecipesCardInnerHeading>
                                    <ToolTip value={data?.recipeName || 'Nill'} maxCharacter={25} />
                                    <S.NutritionFormRecipesCardInnerSubHeading>{data?.mealName}</S.NutritionFormRecipesCardInnerSubHeading>
                                </S.NutritionFormRecipesCardInnerHeading>
                                <S.NutritionFormRecipesCardInnerCalories>
                                    {data?.calories} <br/>
                                    <S.NutritionFormRecipesCardInnerCaloriesValue>
                                        kcal
                                    </S.NutritionFormRecipesCardInnerCaloriesValue>
                                </S.NutritionFormRecipesCardInnerCalories>
                            </S.NutritionFormRecipesCardInnerContainer>
                        </S.NutritionFormRecipesCard>
                    )
                })}
            </>
        )
    }

    const filterButtons = () => (
        <>
            {buttonValues.map(button => {
                return (
                    <Button outlined
                        key={button.value}
                        
                        color={selectedFilter === button.id ? "#1C9D87" : 'rgba(165, 181, 175, .6)'} 
                        margin="0 10px 0 0"
                        style={{padding: "7px 20px", marginRight: "16px", border: "1px solid"}}
                        onClick={() => setSelectedFilter(button.id)}
                    >
                        {button.value}
                    </Button>
                )
            })}
        </>
    )

    return (
        <div>
            <Topbar prev='/nutrition/formAdvantages' next='/nutrition/calories' progress='50%'  />
            <S.NutritionFormRecipesContainer>
                <S.NutritionFormRecipesHeading><TranslationMessage id="nutrition.formRecipes.heading" /></S.NutritionFormRecipesHeading>
                <S.NutritionFormRecipesSubHeading><TranslationMessage id="nutrition.formRecipes.subHeading" /></S.NutritionFormRecipesSubHeading>
                <S.NutritionFormRecipesButtonContainer>
                    {filterButtons()}
                    {!loading && <>
                        <S.NutritionFormRecipesButtonLeft active={newDataIndex > 0} onClick={() => {if(newDataIndex >= 2) setNewDataIndex(newDataIndex - 2)}}>
                            <S.ArrowButton left active={newDataIndex > 0}/>
                            <S.StraightLine active={newDataIndex > 0}/>
                        </S.NutritionFormRecipesButtonLeft>
                        <S.NutritionFormRecipesButtonRight active={recipesData && (newDataIndex < recipesData.length)} onClick={() => setNewDataIndex(newDataIndex + 2)}>
                            <S.ArrowButton right active={recipesData && (newDataIndex < recipesData.length)}/>
                            <S.StraightLine active={recipesData && (newDataIndex < recipesData.length)}/>
                        </S.NutritionFormRecipesButtonRight>
                    </>}
                </S.NutritionFormRecipesButtonContainer>
                {!loading && EatingHabitsContent()}
                {<Loader loading={loading} top="100px" />}
            </S.NutritionFormRecipesContainer>
        </div>
    )
}

export default NutritionRecipes;
