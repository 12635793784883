import styled from "styled-components";
import {DashboardContainer} from "pages/dashboard/dashboard.styles"

export const LoginContainer = styled(DashboardContainer)`
    margin-top: 130px;
    display: flex;
    flex-direction: column;

    @media (max-width: 600px) {
        margin-top: 80px;
    }
`;

export const LoginInnerContainer = styled.div`
    padding: 45px 2rem;
`;

export const LoginHeading = styled.div`
    margin: 0 0 10px 0;
    color: #1C9D87;
    font-size: 14px;
`;

export const LoginSubHeading = styled.div`
    color: #142C1F;
    font-size: 22px;
    margin-bottom: 30px;
`;

export const LoginToggleButton = styled.span`
    margin: 130px auto;
    text-align: center;
    font-size: 14px;
    color: #A5B5AF;
    cursor: pointer;
    font-family: RubikRegular;
`;