import styled from "styled-components";

export const LoaderContainer = styled.div`
    position: relative;
    width: ${props => props.width};
    height: ${props => props.height};

    & > img {
        position: absolute;
        left: ${props => props.left ? props.left : "50%"};
        top: ${props => props.top};
        width: 100%;
        height: auto;
    }
`;
