import EmojiOne from "assets/images/Emoji-1.svg";
import EmojiTwo from "assets/images/Emoji-2.svg";
import EmojiThree from "assets/images/Emoji-3.svg";
import EmojiFour from "assets/images/Emoji-4.svg";
import EmojiFive from "assets/images/Emoji-5.svg";

import ShapeOne from "assets/images/Shape-1.svg";
import ShapeTwo from "assets/images/Shape-2.svg";
import ShapeThree from "assets/images/Shape-3.svg";
import ShapeFour from "assets/images/Shape-4.svg";
import ShapeFive from "assets/images/Shape-5.svg";

export const EVALUATION_ITEMS = [
  {
    id: 1,
    title: "Stresslevel",
    descr: "Wie hoch ist dein aktuelles Stresslevel?",
    descr2:
      "Das Stresslevel einer Person variiert je nach den individuellen Umständen und Herausforderungen im Leben. Es wird durch Faktoren wie Arbeit, Beziehungen, Gesundheit und Umwelt beeinflusst",
    value: "",
    isNumber: false,
    isEmoji: true,
    isShape: false,
    images: [
      { state: 1, isActive: false, img: EmojiOne, color: "green-4" },
      { state: 2, isActive: false, img: EmojiTwo, color: "light-green" },
      { state: 3, isActive: false, img: EmojiThree, color: "light-orange" },
      { state: 4, isActive: false, img: EmojiFour, color: "orange" },
      { state: 5, isActive: false, img: EmojiFive, color: "red-4" },
    ],
  },
  {
    id: 2,
    title: "Körperform",
    descr: "Welche Körperform beschreibt dich am besten?",
    descr2:
      "Die Körperform einer Person ist individuell und kann von variieren. Jeder Körpertyp kann nicht verändert werden.",
    value: "",
    isNumber: false,
    isEmoji: false,
    isShape: true,
    images: [
      { state: 1, isActive: false, img: ShapeOne, color: "green-4" },
      { state: 2, isActive: false, img: ShapeTwo, color: "light-green" },
      { state: 3, isActive: false, img: ShapeThree, color: "light-orange" },
      { state: 4, isActive: false, img: ShapeFour, color: "orange" },
      { state: 5, isActive: false, img: ShapeFive, color: "red-4" },
    ],
  },
  {
    id: 3,
    title: "Sportliche Aktivität",
    descr: "Wie häufig treibst du Sport in der Woche?",
    descr2:
      "Es wird empfohlen, mindestens 150 Minuten moderate körperliche Aktivität pro Woche zu erreichen, was etwa 30 Minuten an den meisten Tagen bedeutet.",
    value: "",
    isNumber: true,
    isEmoji: false,
    isShape: false,
    numbers: [
      { state: 1, isActive: false, title: "0", color: "red-4" },
      { state: 2, isActive: false, title: "1", color: "green-1" },
      { state: 3, isActive: false, title: "2-3", color: "green-2" },
      { state: 4, isActive: false, title: "3-5", color: "green-3" },
      { state: 5, isActive: false, title: "> 5", color: "green-4" },
    ],
  },
  {
    id: 4,
    title: "Tägliche Aktivität",
    descr: "Wie viel Schritte machst du am Tag?",
    descr2:
      "Es wird empfohlen, etwa 10.000 Schritte pro Tag zu gehen, um von den gesundheitlichen Vorteilen einer aktiven Lebensweise zu profitieren.",
    value: "",
    isNumber: true,
    isEmoji: false,
    isShape: false,
    numbers: [
      { state: 1, isActive: false, title: "< 5.000", color: "red-4" },
      { state: 2, isActive: false, title: "5.000 - 8.000", color: "orange" },
      { state: 3, isActive: false, title: "8.001 - 10.000", color: "light-orange" },
      { state: 4, isActive: false, title: "10.001 - 12.000", color: "light-green" },
      { state: 5, isActive: false, title: "> 12.000", color: "green-4" },
    ],
  },
  {
    id: 5,
    title: "Rauchen",
    descr: "Wie viele Zigaretten rauchst du am Tag?",
    descr2:
      "Rauchen beeinflusst negativ eine Ernährungsumstellung, da es den Appetit unterdrückt, den Geschmackssinn beeinträchtigt und das Risiko für gesundheitliche Probleme wie Herzkrankheiten und Krebs erhöht.",
    value: "",
    isNumber: true,
    isEmoji: false,
    isShape: false,
    numbers: [
      { state: 1, isActive: false, title: "0", color: "green-4" },
      { state: 2, isActive: false, title: "1-3", color: "red-1" },
      { state: 3, isActive: false, title: "4-10", color: "red-2" },
      { state: 4, isActive: false, title: "11-20", color: "red-3" },
      { state: 5, isActive: false, title: "> 20", color: "red-4" },
    ],
  },
  {
    id: 6,
    title: "Alkohol",
    descr: "Wie häufig trinkst du Alkohol in der Woche?",
    descr2:
      "Alkohol ist negativ bei einer Ernährungsumstellung, da er viele leere Kalorien enthält, die Gewichtszunahme begünstigen kann, den Stoffwechsel beeinflusst und die Nährstoffaufnahme hemmt.",
    value: "",
    isNumber: true,
    isEmoji: false,
    isShape: false,
    numbers: [
      { state: 1, isActive: false, title: "0", color: "green-4" },
      { state: 2, isActive: false, title: "1", color: "red-1" },
      { state: 3, isActive: false, title: "2", color: "red-2" },
      { state: 4, isActive: false, title: "3", color: "red-3" },
      { state: 5, isActive: false, title: "> 3", color: "red-4" },
    ],
  },
  {
    id: 7,
    title: "Obst & Gemüse",
    descr: "Wie häufig isst du Obst und Gemüse am Tag?",
    descr2:
      "Obst und Gemüse sind positiv bei einer Ernährungsumstellung, da sie reich an Vitaminen, Mineralstoffen, Ballaststoffen und Antioxidantien sind und die die Gesundheit fördern.",
    value: "",
    isNumber: true,
    isEmoji: false,
    isShape: false,
    numbers: [
      { state: 1, isActive: false, title: "0", color: "red-4" },
      { state: 2, isActive: false, title: "1", color: "orange" },
      { state: 3, isActive: false, title: "2", color: "light-orange" },
      { state: 4, isActive: false, title: "3-5", color: "light-green" },
      { state: 5, isActive: false, title: "> 5", color: "green-4" },
    ],
  },
  {
    id: 8,
    title: "Mahlzeitenfrequenz",
    descr: "Wie viel Mahlzeiten nimmst du pro Tag zu dir?",
    descr2:
      "Regelmäßige Mahlzeiten halten den Blutzuckerspiegel stabil, regulieren den Stoffwechsel und verhindern Heißhunger. Eine ausgewogene Ernährung und ausreichende Energieversorgung werden unterstützt.",
    value: "",
    isNumber: true,
    isEmoji: false,
    isShape: false,
    numbers: [
      { state: 1, isActive: false, title: "1", color: "red-4" },
      { state: 2, isActive: false, title: "2", color: "red-2" },
      { state: 3, isActive: false, title: "3", color: "green-4" },
      { state: 4, isActive: false, title: "4", color: "green-4" },
      { state: 5, isActive: false, title: "> 4", color: "green-4" },
    ],
  },
  {
    id: 9,
    title: "Trinken",
    descr: "Wie viel Liter trinkst du am Tag?",
    descr2:
      "Es wird empfohlen, 2-3 Liter Flüssigkeit pro Tag zu trinken, um den Körper hydratisiert zu halten, den Stoffwechsel zu unterstützen und Giftstoffe auszuspülen.",
    value: "",
    isNumber: true,
    isEmoji: false,
    isShape: false,
    numbers: [
      { state: 1, isActive: false, title: "< 1", color: "red-4" },
      { state: 2, isActive: false, title: "1", color: "orange" },
      { state: 3, isActive: false, title: "1-2", color: "light-orange" },
      { state: 4, isActive: false, title: "2-3", color: "light-green" },
      { state: 5, isActive: false, title: "> 3", color: "green-4" },
    ],
  },
  {
    id: 10,
    title: "Heißhunger",
    descr: "Wie häufig hast du Heißhungerattacken am Tag?",
    descr2:
      "Heißhunger in der Ernährungsumstellung entsteht oft durch niedrigen Blutzuckerspiegel, emotionales Essen, Nährstoffmangel oder stressbedingte Essgewohnheiten.",
    value: "",
    isNumber: true,
    isEmoji: false,
    isShape: false,
    numbers: [
      { state: 1, isActive: false, title: "0", color: "green-4" },
      { state: 2, isActive: false, title: "1", color: "red-1" },
      { state: 3, isActive: false, title: "2", color: "red-2" },
      { state: 4, isActive: false, title: "3", color: "red-3" },
      { state: 5, isActive: false, title: "> 3", color: "red-4" },
    ],
  },
  {
    id: 11,
    title: "Stimmungsschwankungen",
    descr: "Wie häufig kommt das in der Woche vor?",
    descr2:
      "Stimmungsschwankungen in der Ernährungsumstellung können durch Hormonveränderungen, Nährstoffmangel, Entzugserscheinungen und psychologische Aspekte wie Stress oder Frustration verursacht werden.",
    value: "",
    isNumber: true,
    isEmoji: false,
    isShape: false,
    numbers: [
      { state: 1, isActive: false, title: "0", color: "green-4" },
      { state: 2, isActive: false, title: "1", color: "light-green" },
      { state: 3, isActive: false, title: "2", color: "light-orange" },
      { state: 4, isActive: false, title: "3", color: "orange" },
      { state: 5, isActive: false, title: "> 3", color: "red-4" },
    ],
  },
  {
    id: 12,
    title: "Schwächegefühl",
    descr: "Wie oft fühlst du dich schlapp in der Woche?",
    descr2:
      "Schwächegefühle können durch eine gesunde Ernährung behoben werden, indem man ausreichend Nährstoffe wie Kohlenhydrate, Proteine und gesunde Fette zu sich nimmt.",
    value: "",
    isNumber: true,
    isEmoji: false,
    isShape: false,
    numbers: [
      { state: 1, isActive: false, title: "0", color: "green-4" },
      { state: 2, isActive: false, title: "1", color: "light-green" },
      { state: 3, isActive: false, title: "2", color: "light-orange" },
      { state: 4, isActive: false, title: "3", color: "orange" },
      { state: 5, isActive: false, title: "> 3", color: "red-4" },
    ],
  },
];
