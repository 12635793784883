import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
import { renderValueInGerman } from "configs/utils";
import moment from "moment";

// Components
import Topbar from "components/topbar/Topbar";
import TranslationMessage from "configs/translations/TranslationMessage";
import Card from "components/card/Card";
import Switch from "components/formComponents/switch/Switch";
import SquareCheckbox from "components/formComponents/checkbox/SquareCheckbox";
import Input from "components/formComponents/input/Input";
import Button from "components/formComponents/button/Button";

// Redux
import * as SupplementsActions from "redux/supplements/supplements.actions";
import * as S from "./subscriptionDetails.styles";

const SubscriptionDetails = () => {
  const [isChecked, setIsChecked] = useState({
    firstCheck: false,
    secondCheck: false,
  });
  const [Discount, setDiscount] = useState(
    localStorage.getItem("discount") || 0
  );

  const intl = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();
  const registrationData = useSelector((state) => state.auth.registrationData);
  const authData = useSelector((state) => state.auth);

  const [isPaymentSubmitting, setIsPaymentSubmitting] = useState(false);
  const [oneTimePayment, setOneTimePayment] = useState(false);
  const [code, setCode] = useState("");
  const params = new URLSearchParams(window.location.search);
  const user = params.get("user");
  const TotalDiscountValue = user === "Pro" ? 450.0 : 150.0;

  useEffect(() => {
    setDiscount(localStorage.getItem("discount"));
  }, []);

  const onNext = () => {
    const formData = {
      currency: "eur",
      amount: oneTimePayment ? 194.85 : 64.95,
      userID: registrationData.userID,
      trainerID: authData.trainerData.trainerID,
      date: moment().format("YYYY-MM-DD"),
      subscribe_status: user === "Pro" ? "PRO-USER" : "PLUS-USER",
      payment_type: oneTimePayment ? "One-time payment" : "Monthly payment",
    };
    setIsPaymentSubmitting(true);
    dispatch(
      SupplementsActions.initiateStripePaymentRequest({
        formValues: formData,
        func: () => {
          setIsPaymentSubmitting(false);
          history.push("/foodiary/family");
        },
        affiliateData: {
          userID: registrationData.userID,
          email: registrationData.email,
          conversionAmount: user === "Pro" ? 450 : 150,
          commissionType: user === "Pro" ? "coaching-pro" : "coaching-plus",
        },
      })
    );

    console.log("affiliateData", {
      userID: registrationData.userID,
      email: registrationData.email,
      conversionAmount: user === "Pro" ? 450 : 150,
      commissionType: user === "Pro" ? "coaching-pro" : "coaching-plus",
    });
  };

  return (
    <>
      <Topbar
        prev="/subscriptions"
        next="/foodiary/family"
        onNext={onNext}
        nextButtonValue={intl.formatMessage({ id: "button.orderWithPayment" })}
        nextButtonDisabled={
          ((!isChecked.firstCheck && !isChecked.secondCheck) ||
            isPaymentSubmitting) &&
          "disabled"
        }
      />
      <S.SubscriptionDetailsContainer>
        <S.SubscriptionDetailsHeading>
          <TranslationMessage
            id={
              user === "Pro"
                ? "subscription.pro.page.heading"
                : "subscription.plus.page.heading"
            }
          />
        </S.SubscriptionDetailsHeading>
        <S.SubscriptionDetailsSubHeading>
          <TranslationMessage id="subscription.details.subHeading" />
        </S.SubscriptionDetailsSubHeading>
        <S.SubscriptionDetailsProInnerDetailsOneTimePayment>
          <span style={{ color: oneTimePayment ? "grey" : "#2ECF96" }}>
            <TranslationMessage
              style={{ marginRight: "50px;", color: "white;" }}
              id="subscription.details.card1.pro.oneTimePayment"
            />
          </span>
          <Switch
            switchedOn={oneTimePayment}
            onChange={() => setOneTimePayment(!oneTimePayment)}
          />
          <span style={{ color: oneTimePayment ? "#2ECF96" : "grey" }}>
            <TranslationMessage
              style={{ marginLeft: "50px;" }}
              id="subscription.details.card1.pro.monthly"
            />
          </span>
        </S.SubscriptionDetailsProInnerDetailsOneTimePayment>
        <Card width="724px" height="263px" margin="20px auto" textAlign="left">
          <S.SubscriptionDetailsProInnerContainer>
            <S.SubscriptionDetailsProInnerHeading>
              <TranslationMessage id="subscription.details.card1.heading" />
            </S.SubscriptionDetailsProInnerHeading>
            <S.SubscriptionDetailsProInnerDetailsContainer
              style={{ marginBottom: "40px" }}
            >
              <div style={{ display: "flex", flexDirection: "row" }}>
                <S.SubscriptionDetailsProInnerDetailsFlexColumn width="120px">
                  <S.SubscriptionDetailsProInnerIconContainer>
                    <span>{user}</span>
                  </S.SubscriptionDetailsProInnerIconContainer>
                </S.SubscriptionDetailsProInnerDetailsFlexColumn>
                <S.SubscriptionDetailsProInnerDetailsFlexColumn width="250px">
                  <S.SubscriptionDetailsProInnerDetailsHeading>
                    <TranslationMessage
                      id={
                        user === "Pro"
                          ? "subscription.details.card1.pro.heading"
                          : "subscription.details.card1.Plus.heading"
                      }
                    />
                  </S.SubscriptionDetailsProInnerDetailsHeading>
                  <S.SubscriptionDetailsProInnerDetailsSubHeading>
                    <TranslationMessage
                      id={
                        user === "Pro"
                          ? "subscription.details.card1.pro.subHeading"
                          : "subscription.details.card1.pro.subHeading"
                      }
                    />
                  </S.SubscriptionDetailsProInnerDetailsSubHeading>
                  <S.SubscriptionDetailsProInnerDetailsDescription>
                    <TranslationMessage id="subscription.details.card1.pro.description" />
                  </S.SubscriptionDetailsProInnerDetailsDescription>
                </S.SubscriptionDetailsProInnerDetailsFlexColumn>
              </div>
              <S.SubscriptionDetailsProInnerDetailsFlexColumn width="110px">
                <S.SubscriptionDetailsProInnerDetailsCostWithBg>
                  {oneTimePayment && user === "Pro" && "450,00 €"}
                  {!oneTimePayment && user === "Pro" && "150,00 €"}
                  {oneTimePayment && user === "Plus" && "150,00 €"}
                  {!oneTimePayment && user === "Plus" && "50,00 €"}
                </S.SubscriptionDetailsProInnerDetailsCostWithBg>
                <S.SubscriptionDetailsProInnerDetailsDescription
                  style={{ textAlign: "center" }}
                >
                  {oneTimePayment ? (
                    <TranslationMessage id="subscription.details.card1.pro.perThreeMonth" />
                  ) : (
                    <TranslationMessage id="subscription.details.card1.pro.perMonth" />
                  )}
                </S.SubscriptionDetailsProInnerDetailsDescription>
              </S.SubscriptionDetailsProInnerDetailsFlexColumn>
            </S.SubscriptionDetailsProInnerDetailsContainer>
            <S.SubscriptionDetailsProInnerDetailsContainer>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <S.SubscriptionDetailsProInnerDetailsFlexColumn width="120px">
                  <S.SubscriptionDetailsStaticContainer>
                    <span>KRE</span>
                  </S.SubscriptionDetailsStaticContainer>
                  {/* <Input filled style={{width: '62px', margin: "10px 0 0 0", textAlign: 'center'}} placeholder="CODE" onChange={(e) => setCode(e.target.value)} value={code} /> */}
                </S.SubscriptionDetailsProInnerDetailsFlexColumn>
                <S.SubscriptionDetailsProInnerDetailsFlexColumn width="250px">
                  <S.SubscriptionDetailsProInnerDetailsHeading>
                    <TranslationMessage id="subscription.details.card1.fix.heading" />
                  </S.SubscriptionDetailsProInnerDetailsHeading>
                  <S.SubscriptionDetailsProInnerDetailsSubHeading>
                    <TranslationMessage id="subscription.details.card1.fix.subHeading" />
                  </S.SubscriptionDetailsProInnerDetailsSubHeading>
                  <S.SubscriptionDetailsProInnerDetailsDescription>
                    <TranslationMessage id="subscription.details.card1.fix.description" />
                  </S.SubscriptionDetailsProInnerDetailsDescription>
                </S.SubscriptionDetailsProInnerDetailsFlexColumn>
              </div>
              <S.SubscriptionDetailsProInnerDetailsFlexColumn width="110px">
                <S.SubscriptionDetailsProInnerDetailsCostWithBg>
                  {/* {`${Discount}`.replace(".", ",")},00 € */}
                  {renderValueInGerman(+Discount)}
                  {/* {Discount.toLocaleString("de-DE", { minimumFractionDigits: 2, maximumFractionDigits: 2 })} */}
                </S.SubscriptionDetailsProInnerDetailsCostWithBg>
                <S.SubscriptionDetailsProInnerDetailsCostContainer>
                  {/* {TotalDiscountValue},00 €<span>-</span> */}
                </S.SubscriptionDetailsProInnerDetailsCostContainer>
              </S.SubscriptionDetailsProInnerDetailsFlexColumn>
            </S.SubscriptionDetailsProInnerDetailsContainer>
            <hr />
            <S.SubscriptionDetailsTotalContainer>
              <S.SubscriptionDetailsTotalHeading>
                Gesamt
              </S.SubscriptionDetailsTotalHeading>
              <S.SubscriptionDetailsTotalContainerValueColumn>
                <S.SubscriptionDetailsTotalContainerValue>
                  {/* {oneTimePayment ? "300,00 €" : "0,00 €"} */}
                  {/* {`${(TotalDiscountValue - Discount)
                    .toString()
                    .replace(".", ",")} €`} */}
                  {renderValueInGerman(TotalDiscountValue - Discount)}
                  {/* {!oneTimePayment && `${150 - Discount},00 €`} */}
                </S.SubscriptionDetailsTotalContainerValue>
                <S.SubscriptionDetailsTotalDescription
                  style={{ textAlign: "center" }}
                >
                  inkl. MwSt.
                </S.SubscriptionDetailsTotalDescription>
              </S.SubscriptionDetailsTotalContainerValueColumn>
            </S.SubscriptionDetailsTotalContainer>
          </S.SubscriptionDetailsProInnerContainer>
        </Card>
        <Card width="724px" height="16px" margin="20px auto" textAlign="left">
          <S.SubscriptionDetailsProInnerContainer>
            <S.SubscriptionDetailsPromoContainer>
              <S.SubscriptionDetailsPromoHeadings>
                <h5>Gutschein</h5>
                <h6>Ich habe einen Gutschein</h6>
              </S.SubscriptionDetailsPromoHeadings>
              <S.SubscriptioninputBtnContainer>
                <S.SubscriptionDataInputContainer>
                  <Input
                    filled
                    type="text"
                    placeholder="Gutscheincode eingeben..."
                    name="franchiseId"
                  />
                </S.SubscriptionDataInputContainer>
                <Button
                  color="rgba(46, 207, 150, .2)"
                  gradient="linear-gradient(60deg, rgba(46, 207, 150, 1), rgba(28, 157, 135, 1)) !important"
                  style={{ fontSize: "16px" }}
                >
                  Anwenden
                </Button>
              </S.SubscriptioninputBtnContainer>
            </S.SubscriptionDetailsPromoContainer>
          </S.SubscriptionDetailsProInnerContainer>
        </Card>
        <Card width="724px" height="16px" margin="20px auto" textAlign="left">
          <S.SubscriptionDetailsProInnerContainer>
            <S.SubscriptionDetailsRulesContainer>
              <div style={{ display: "flex" }}>
                <SquareCheckbox
                  isChecked={isChecked.firstCheck}
                  param="firstCheck"
                  setIsChecked={setIsChecked}
                  rounded="5px"
                  filled="#F5F5F5"
                />
                <S.SubscriptionDetailsRulesLabel>
                  <TranslationMessage id="subscription.details.card2.checkBox1" />
                </S.SubscriptionDetailsRulesLabel>
              </div>
              <div style={{ display: "flex" }}>
                <SquareCheckbox
                  isChecked={isChecked.secondCheck}
                  param="secondCheck"
                  setIsChecked={setIsChecked}
                  rounded="5px"
                  filled="#F5F5F5"
                />
                <S.SubscriptionDetailsRulesLabel>
                  Der Teilnehmer hat die AGB <a className='text-dark-1' href='https://foodiary.app/agb/' target='_blank'>Foodiary Coaching</a> und die Datenschutzbestimmungen gelesen, verstanden und akzeptiere diese.
                </S.SubscriptionDetailsRulesLabel>
              </div>
            </S.SubscriptionDetailsRulesContainer>
          </S.SubscriptionDetailsProInnerContainer>
        </Card>
      </S.SubscriptionDetailsContainer>
    </>
  );
};

export default SubscriptionDetails;
