import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";

// Components
import Modal from "components/modal/Modal";
import Table from "components/table/Table";
import InnerSideNav from "pages/NewDasboard/components/InnerSideNav/InnerSide";
import Pagination from "components/Pagination/Pagination";

// Images
import ErrorMessageIcon from "assets/images/error-message.png";
import CrossIcon from "assets/images/cross.svg";

// Redux
import * as AuthActions from "redux/auth/auth.actions";
import * as DashboardActions from "redux/dashboard/dashboard.actions";
import * as S from "./mainCustomers.styles";
import * as GlobalStyles from "components/global/Sections.styles"

const MENUS = [
  {
    id: 1,
    title: "Klientenübersicht",
    path: "/dashboard/customers/list",
  },
  {
    id: 2,
    title: "Testumgebung",
    path: "",
    url: "http://3.70.7.80/",
  },
  {
    id: 3,
    title: "Erstgespräch starten",
    path: "/start-consultation",
  },
  {
    id: 4,
    title: "Ernährungsplan öffnen",
    path: "/dashboard/customers/token-request",
  },
];

const MainCustomers = ({ props }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const intl = useIntl();
  let location = useLocation();

  const [showClientDetails, setShowClientDetails] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);

  const [
    IsStripeConnectedAccountStatusModal,
    SetIsStripeConnectedAccountStatusModal,
  ] = useState(false);

  const checkStripeConnectedAccountStatus = useSelector(
    (state) => state.dashboard.checkStripeConnectedAccountStatus
  );
  const isCheckStripeConnectedAccountStatusLoading = useSelector(
    (state) => state.dashboard.isCheckStripeConnectedAccountStatusLoading
  );

  const trainerData = useSelector((state) => state.auth.trainerData);
  const registrationData = useSelector((state) => state.auth.registrationData);
  const usersRelatedToTrainer = useSelector(
    (state) => state.auth.usersRelatedToTrainer
  );

  console.log("usersRelatedToTrainer", usersRelatedToTrainer);

  useEffect(() => {
    dispatch(
      AuthActions.getUsersByTrainerRequest({
        formData: {
          trainerID: trainerData.trainerID,
        },
        page: 1,
      })
    );
  }, []);

  const tableHeading = [
    { value: "mainCustomer.table.heading1" },
    { value: "mainCustomer.table.heading2" },
    { value: "mainCustomer.table.heading3" },
    { value: "mainCustomer.table.heading6" },
    { value: "mainCustomer.table.heading4" },
    { value: "mainCustomer.table.heading5" },
  ];

  const handlePresentation = (userData) => {
    const formData = {
      name: userData.prename,
      email: userData.email,
    };

    const formDataLoginWithToken = {
      formData: {
        grant_type: "password",
        client_id: 2,
        client_secret: process.env.REACT_APP_CLIENT_SECRET,
        trainerID: trainerData.trainerID,
        FranchiseID: trainerData.FranchiseID,
      },
      func: () =>
        history.push({
          pathname: "/nutrition/nutritionForm",
          state: {
            from: location.pathname,
          },
        }),
    };

    dispatch(
      AuthActions.tokenLoginRequest({ formData, formDataLoginWithToken })
    );
    dispatch(
      AuthActions.setRegistrationData({ ...registrationData, ...userData })
    );
  };

  const createTableData = () => {
    // console.log("usersRelatedToTrainer?.data", usersRelatedToTrainer?.data);
    const tableData = usersRelatedToTrainer?.data?.map((data) => {
      return {
        id: data.userID,
        values: [
          { id: data.userID + "-1", value: data.userID, isPlan: false },
          { id: data.userID + "-2", value: data.prename, isPlan: false },
          { id: data.userID + "-3", value: data.surname, isPlan: false },
          {
            id: data.userID + "-6",
            value: data.subscribe_status,
            isPlan: true,
          },
          {
            id: data.userID + "-4",
            value: formatDate(data.startdate),
            isPlan: false,
          },
          { id: data.userID + "-5", value: renderAction(data), isPlan: false },
        ],
      };
    });
    return tableData;
  };

  function formatDate(dateString) {
    if (!dateString) return;
    const [year, month, day] = dateString.split("-");
    return `${day}.${month}.${year}`;
  }

  const renderAction = (data) => {
    return (
      <>
        <button
          className="btn-outline"
          onClick={() => {
            history.push("/dashboard/clients/overview/" + data?.userID);
          }}
        >
          Details
        </button>
      </>
    );
  };

  const getPaginationDetails = () => {
    return {
      currentPage: usersRelatedToTrainer.current_page,
      lastPage: usersRelatedToTrainer.last_page,
      all: usersRelatedToTrainer,
    };
  };

  const [TotalPages, setTotalPages] = useState(
    Math.ceil(usersRelatedToTrainer?.total / usersRelatedToTrainer?.per_page)
  );
  const [CurrentPage, setCurrentPage] = useState(1);
  const [AllPages, setAllPages] = useState([]);
  const [SliceOne, setSliceOne] = useState([]);
  const [SliceTwo, setSliceTwo] = useState([]);
  const [SlicedPagination, setSlicedPagination] = useState([]);

  useEffect(() => {
    onPaginationClick(1);
  }, []);

  const onPaginationClick = (pageIdx) => {
    let page = pageIdx;
    setCurrentPage(page);

    const total = Math.ceil(
      usersRelatedToTrainer?.total / usersRelatedToTrainer?.per_page
    );
    let allPages = [];

    for (let index = 1; index <= total; index++) {
      allPages.push(index);
    }

    setAllPages(allPages);

    let slicedPagination = [];
    let sliceOne = [...SliceOne];
    let sliceTwo = [...SliceTwo];

    sliceOne = allPages.slice(page - 1, page + 5);
    setSliceOne(sliceOne);
    sliceTwo = allPages.slice(-1);
    setSliceTwo(sliceTwo);

    // if (sliceOne.includes(total) && sliceOne.length < 6) {
    //   slicedPagination = [...sliceOne];
    //   setSlicedPagination(slicedPagination);
    //   return;
    // }

    if (sliceOne.includes(total)) {
      if (page === total) {
        sliceOne = allPages.slice(0, 5);
        setSliceOne(sliceOne);
        slicedPagination = [...sliceOne, "...", total];
      }

      if (page < total) {
        sliceOne = allPages.slice(page - 1, page + 5);
        setSliceOne(sliceOne);
        slicedPagination = [1, ...sliceOne];
      }
    } else {
      if (sliceOne.length < 6) {
        slicedPagination = [...sliceOne, ...sliceTwo];
      } else {
        slicedPagination = [...sliceOne, "...", ...sliceTwo];
      }
    }
    // console.log("sliceOne, ", sliceOne);
    // console.log("sliceTwo, ", sliceTwo);
    // console.log("page", page);
    // console.log("slicedPagination", slicedPagination);
    setSlicedPagination([...new Set(slicedPagination)]);
  };

  const checkStripeConnection = () => {
    dispatch(
      DashboardActions.checkStripeConnectedAccountStatusRequest({
        payload: {
          trainerID: trainerData?.trainerID,
        },
        callback: (res) => {
          console.log("checkStripeConnectedAccountStatusRequest", res);
          if (
            res?.connected_account_status === "completed" &&
            res?.connected_user_account_status === "completed"
          ) {
            // history.push("/start-consultation");
            history.push("/onboarding/legal-informations");
          } else {
            SetIsStripeConnectedAccountStatusModal(true);
          }
        },
      })
    );
  };

  return (
    <S.Container>
      <div className="second-sidebar-wrapper">
        <InnerSideNav
          menus={MENUS}
          onCheckStripeConnection={checkStripeConnection}
          isCheckStripeConnectedAccountStatusLoading={
            isCheckStripeConnectedAccountStatusLoading
          }
          hideUserInfo
        ></InnerSideNav>
      </div>
      <S.MainCustomerContainer className="second-sidebar-content">
        <S.PaddingBox>
          <S.ContainerBox>
            <S.Header>
              <div>
                <GlobalStyles.SectionHeadline>
                  Klientenübersicht
                </GlobalStyles.SectionHeadline>
                <GlobalStyles.SubHeadline>
                  Hier sind alle Klienten, die deiner Betreuung sind oder waren.
                </GlobalStyles.SubHeadline>
                {/* <S.Heading>Klientenübersicht</S.Heading>
                <S.SubHeading>
                  Hier sind alle Klienten, die deiner Betreuung sind oder waren.
                </S.SubHeading> */}
              </div>
            </S.Header>

            <S.Divider />

            <S.PaddingBox>
              {!showClientDetails && (
                <S.CustomersListContainer className="customer-table-wrap">
                  {/* <S.MainCustomerHeadingContainer>
              <div>
                <S.MainCustomerHeading>
                  <TranslationMessage id="mainCustomer.heading" />
                </S.MainCustomerHeading>
                <S.MainCustomerSubHeading>
                  <TranslationMessage id="mainCustomer.subHeading" />
                </S.MainCustomerSubHeading>
              </div>
              <Button
                color="rgba(248, 164, 146, 1)"
                margin="20px 0 0 0"
                absolute={{ width: "auto", height: "45px" }}
                style={{
                  position: "relative",
                  paddingLeft: "36px",
                  fontSize: "16px",
                }}
                onClick={() =>
                  history.push({
                    pathname: "/",
                    state: {
                      from: location.pathname,
                    },
                  })
                }
              >
                <img
                  src={plusIcon}
                  alt="plus-icon"
                  style={{ marginRight: "8px" }}
                />{" "}
                <TranslationMessage id="button.newConsultation" />
              </Button>
            </S.MainCustomerHeadingContainer> */}
                  <S.abc>
                    {usersRelatedToTrainer && (
                      <Table
                        tableHeading={tableHeading}
                        tableData={createTableData()}
                        pagination={getPaginationDetails()}
                        SlicedPagination={SlicedPagination}
                        CurrentPage={CurrentPage}
                        onRow={(rowData) => {
                          // console.log("onRow", rowData);
                          // setUserID(rowData?.id);
                          // setClientDetails(rowData);
                          // setShowClientDetails(true);
                          // history.push(
                          //   "/dashboard/customers/detail/" + rowData?.id
                          // );
                        }}
                        onPageChange={(pageNo) => {
                          setPageNumber(pageNo);
                          onPaginationClick(pageNo);
                          dispatch(
                            AuthActions.getUsersByTrainerRequest({
                              formData: {
                                trainerID: trainerData.trainerID,
                              },
                              page: pageNo,
                            })
                          );
                        }}
                      />
                    )}
                  </S.abc>
                  <br />
                  <Pagination
                    currentPage={CurrentPage}
                    recordsPerPage={10}
                    totalRecords={usersRelatedToTrainer?.total}
                    onPageChange={(pageNo) => {
                      setPageNumber(pageNo);
                      onPaginationClick(pageNo);
                      dispatch(
                        AuthActions.getUsersByTrainerRequest({
                          formData: {
                            trainerID: trainerData.trainerID,
                          },
                          page: pageNo,
                        })
                      );
                    }}
                  ></Pagination>
                </S.CustomersListContainer>
              )}
            </S.PaddingBox>
          </S.ContainerBox>
        </S.PaddingBox>
      </S.MainCustomerContainer>
      {/* {showClientDetails && UserID > 0 && (
        <ClientDetails
          userID={UserID}
          details={clientDetails}
          setDetails={setClientDetails}
          showDetails={setShowClientDetails}
          pageNumber={pageNumber}
          trainerID={trainerData.trainerID}
        />
      )} */}

      <Modal
        width="450px"
        height="auto"
        show={IsStripeConnectedAccountStatusModal}
        setShow={() => null}
        heading={
          <div className="d-flex align-items-center justify-content-between">
            <h4 className="my-0 text-left"> </h4>

            <img
              className="cursor-pointer"
              src={CrossIcon}
              alt="CrossIcon"
              onClick={() => SetIsStripeConnectedAccountStatusModal(false)}
            />
          </div>
        }
      >
        <div className="congratulations-modal-body-inner">
          <img src={ErrorMessageIcon} alt="" />

          <h6>Achtung!</h6>
          <p>
            Dein Zahlungsanbieter wurde noch nicht aktiviert. <br />
            Bitte aktiviere deinen Anbieter direkt.
          </p>
          <button
            className="btn-dark"
            onClick={() => history.push("/dashboard/settings/payment-provider")}
          >
            Zahlungsanbieter aktivieren
          </button>
        </div>
      </Modal>
    </S.Container>
  );
};

export default MainCustomers;
