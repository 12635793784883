import React from "react";
import * as S from "./switch.styles";

const Switch = ({ color, switchedOn, hideLabels = false, keepBackgroundSame = false, small, ...props }) => {
  return (
    <S.SwitchWrapper>
      <S.SwitchContainer>
        <label>
          <S.CustomSwitch small={small} checked={switchedOn} keepBackgroundSame={keepBackgroundSame} {...props} />
          <div>
            <div></div>
          </div>
          {!hideLabels && (
            <>
              {switchedOn && <span className="no">Nein</span>}

              {!switchedOn && <span className="yes text-dark-2">Ja</span>}
            </>
          )}
        </label>
      </S.SwitchContainer>
    </S.SwitchWrapper>
  );
};

export default Switch;
