import styled from "styled-components";

export const ProfileDropIconContainer = styled.div`
  display: flex;
`;

export const ProfileDropIconImageContainer = styled.div`
  width: 46px;
  height: 46px;
  background: ${props => props.profilePic ? 'url("' + props.profilePic + '")' : 'none'};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 50%;
  background-color: white;
  cursor: pointer;
`;

export const ProfileDropIconNameContainer = styled.span`
    line-height: 46px;
    font-size: 16px;
    font-family: RubikRegular;
    margin-left: 14px;
    color: white;
`;

export const ProfileDropIconArrowBottomButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 22px;
`;

export const ProfileDropdownContainer = styled.div`
    width: 200px;
    height: auto;
    border-radius: 8px;
    box-shadow: 0px 13px 80px #00000033;
    background: white;
    position: absolute;
    top: 4rem;
    overflow: hidden;
    z-index: 999;
    right: 1.5rem;
`;

export const ProfileDropdownOptionContainer = styled.div`
    font-size: 16px;
    font-family: RubikRegular;
    height: 45px;
    display: flex;
    cursor: pointer;

    &:hover {
        background-color: #d3d3d3;
    }

    &.disabled {
        background: #e8edf1;
        color: #afafaf;
        cursor: not-allowed;
    }
`;

export const ProfileDropdownOptionImage = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 55px;
`;

export const ProfileDropdownOption = styled.div`
    line-height: 45px;
`;