import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";

import * as AuthActions from "redux/auth/auth.actions";
import Select from 'react-select'

import * as S from "./socialMedia.styles";
import InstaOne from "assets/images/insta1.png";

import InnerSideNav from "pages/NewDasboard/components/InnerSideNav/InnerSide";
import ButtonHover from "./ButtonHover";
import Pagination from "components/Pagination/Pagination";
import { MENUS } from "./data";
import * as GlobalStyles from "components/global/Sections.styles"

const SocialMediaInstagram = () => {
  const dispatch = useDispatch();

  const [CurrentPage, setCurrentPage] = useState(1);

  const [SelectedCategory, setSelectedCategory] = useState(4);
  const [SelectedSubCategory, setSelectedSubCategory] = useState(0);

  const instagramPostsByCategory = useSelector(
    (state) => state.auth.instagramPostsByCategory
  );
  const instagramSubCategoriesByCategory = useSelector(
    (state) => state.auth.instagramSubCategoriesByCategory
  );

  const allInstagramCategories = useSelector(
    (state) => state.auth.allInstagramCategories
  );
  const allInstagramPosts = useSelector(
    (state) => state.auth.allInstagramPosts
  );

  const isAllInstagramPostsLoading = useSelector(
    (state) => state.auth.isAllInstagramPostsLoading
  );

  const isInstagramPostsByCategoryLoading = useSelector(
    (state) => state.auth.isInstagramPostsByCategoryLoading
  );

  const instagramPosts = useSelector((state) => state.auth.instagramPosts);

  const arePostsLoading =
    isAllInstagramPostsLoading || isInstagramPostsByCategoryLoading;

  // console.log("instagramPostsByCategory", instagramPostsByCategory);
  // console.log(
  //   "instagramSubCategoriesByCategory",
  //   instagramSubCategoriesByCategory
  // );

  useEffect(() => {
    dispatch(
        AuthActions.getAllInstagramPostsByCategoriesRequest({
          payload: {
            category: SelectedCategory,
            sub_category: SelectedSubCategory,
            pageNo: 1,
          },
          callback: () => null,
        })
      );

    dispatch(
      AuthActions.getAllInstagramCategoriesRequest({
        callback: (res) => {
          dispatch(
            AuthActions.getInstagramSubCategoriesByCategoryRequest({
              payload: {
                category: res?.getAllCategories?.data[SelectedCategory]?.id,
              },
              callback: () => null,
            })
          );
        },
      })
    );
  }, []);

  const getSubCategories = (categoryId) => {
    dispatch(
      AuthActions.getInstagramSubCategoriesByCategoryRequest({
        payload: {
          category: categoryId,
        },
        callback: () => null,
      })
    );
  };

  const handleCategoryChange = (categoryId) => {
    setSelectedCategory(categoryId);
    getSubCategories(categoryId);
  };

  const handleSubCategoryChange = (subCategoryId) => {
    setCurrentPage(1);
    setSelectedSubCategory(subCategoryId);
    dispatch(
      AuthActions.getAllInstagramPostsByCategoriesRequest({
        payload: {
          category: SelectedCategory,
          sub_category: subCategoryId,
          pageNo: 1,
        },
        callback: () => null,
      })
    );
  };

  
  const categories =
    allInstagramCategories?.data && allInstagramCategories?.data?.length
    ? allInstagramCategories?.data?.map((category) => (
      { value: category.id, label: category.title }
    ))
    : []

  const subCategories =
    instagramSubCategoriesByCategory?.data && instagramSubCategoriesByCategory?.data?.length
    ? instagramSubCategoriesByCategory?.data?.map((subCategory) => (
      { value: subCategory.id, label: subCategory.title }
    ))
    : []

  return (
    <>
      <S.Container>
        <div className="second-sidebar-wrapper">
          <InnerSideNav menus={MENUS} hideUserInfo></InnerSideNav>
        </div>
        <S.Wrapper className="second-sidebar-content">
          <S.MCard>
            <GlobalStyles.SectionHeadline>
              Instagram Datenbank
            </GlobalStyles.SectionHeadline>
            <GlobalStyles.SubHeadline>
              Finde die richtigen Assets für deine Social Media Strategie
            </GlobalStyles.SubHeadline>

            <br />
            <S.Divider />
            <br />

            <S.DropDown>
            <Select
                options={categories}
                className="select-width"
                classNamePrefix="react-select"
                placeholder="Auswählen"
                onChange={(item) => {
                  console.log(item)
                  handleCategoryChange(item.value)
                }}
                isSearchable={false}
              />

              <span>&gt;&gt;</span>

              <Select
                options={subCategories}
                className="select-width"
                classNamePrefix="react-select"
                placeholder="Auswählen"
                isSearchable={false}
                onChange={(item) => handleSubCategoryChange(item.value)}
              />
            </S.DropDown>
            {arePostsLoading && (
              <>
                <br />
                <br />
                <br />
                <br />
                <div className="text-center">
                  <div className="loader mx-auto"></div>
                </div>
                <br />
                <br />
                <br />
                <br />
              </>
            )}
            {!arePostsLoading && instagramPosts?.data?.length === 0 && (
              <h3 className="text-center">No record found</h3>
            )}
            <ResponsiveMasonry
              columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 4 }}
            >
              <Masonry gutter="1.25rem">
                {!arePostsLoading &&
                  instagramPosts?.data?.map(
                    ({
                      id,
                      title,
                      cover_image,
                      category_name,
                      category,
                      direct_download_link,
                      google_link,
                      canva_link,
                    }) => (
                      <>
                        {category === 1 && (
                          <S.FullImage key={id}>
                            <S.Layer></S.Layer>
                            <img src={cover_image || InstaOne} alt="" />

                            <S.BottomSection className="full-image">
                              <h5>{category_name}</h5>
                              <p>{title}</p>

                              <ButtonHover
                                links={{
                                  direct_download_link,
                                  google_link,
                                  canva_link,
                                }}
                              />
                            </S.BottomSection>
                          </S.FullImage>
                        )}

                        {category !== 1 && (
                          <S.HalfImage key={id}>
                            <S.UpperSection>
                              {/* <img
                              src={
                                "https://foodiaryproduction.s3.eu-central-1.amazonaws.com/Social_Media_db/Instagram/Beitr%C3%A4ge/Tipps/Beitrag+-+Tipps+-+Quote+Fortschritt.jpg"
                              }
                              alt=""
                            /> */}
                              <img src={cover_image || InstaOne} alt="" />
                            </S.UpperSection>
                            <S.BottomSection>
                              <h5>{category_name}</h5>
                              <p>{title}</p>

                              <ButtonHover
                                links={{
                                  direct_download_link,
                                  google_link,
                                  canva_link,
                                }}
                              />
                            </S.BottomSection>
                          </S.HalfImage>
                        )}
                      </>
                    )
                  )}
              </Masonry>
            </ResponsiveMasonry>
            {/* {!arePostsLoading && (
              <S.TablePaginationDetailsContainer>
                <S.TablePaginationNumbersContainer>
                  {renderPageNumbers(instagramPosts)}
                </S.TablePaginationNumbersContainer>
              </S.TablePaginationDetailsContainer>
            )} */}

            <br />
            {!arePostsLoading && (
              <Pagination
                currentPage={CurrentPage}
                recordsPerPage={10}
                totalRecords={instagramPosts?.total}
                onPageChange={(pageNo) => {
                  setCurrentPage(pageNo);
                  if (instagramPosts?.path.includes("getAllPostsByCategory")) {
                    dispatch(
                      AuthActions.getAllInstagramPostsByCategoriesRequest({
                        payload: {
                          category: SelectedCategory,
                          sub_category: SelectedSubCategory,
                          pageNo: pageNo,
                        },
                        callback: () => null,
                      })
                    );
                  } else {
                    dispatch(
                      AuthActions.getAllInstagramPostsRequest({
                        payload: {
                          pageNo: pageNo,
                        },
                        callback: () => null,
                      })
                    );
                  }
                }}
              ></Pagination>
            )}
          </S.MCard>
        </S.Wrapper>
      </S.Container>
    </>
  );
};

export default SocialMediaInstagram;
