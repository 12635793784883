import styled from "styled-components"
import { DashboardContainer } from "pages/dashboard/dashboard.styles"

export const Container = styled(DashboardContainer)`
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  width: 100%;
  max-width: 1280px;
`

export const Title = styled.div`
  color: #1c9d87;
  font-size: 14px;
`

export const SubTitle = styled.div`
  color: #142c1f;
  font-size: 24px;
  margin: 10px 0 30px 0;
`

export const CardsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 21px;
`

export const Card = styled.div`
  background: #ffffff;
  box-shadow: 0px 0px 30px #d6d6d629;
  border-radius: 8px;
  padding: 20px;
`

export const CardImage = styled.img`
  width: 142px;
  height: 142px;
  border: 5px solid #f5f5f5;
  border-radius: 50%;
  display: block;
  margin: 0 auto;
`

export const CardTitle = styled.h3`
  text-align: center;
  font-size: 20px;
  line-height: 34px;
  font-family: RubikMedium, Georgia;
  font-weight: 500;
  color: #142c1f;
  margin: 30px 0 20px;
`

export const CardDeisnationBox = styled.div`
  background: #f8f8f8;
  border-radius: 4px;
  padding: 10px;
  text-align: center;
  margin-bottom: 30px;
`

export const CardDeisnationHeading = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #a5b5af;
  margin: 0 0 10px;
`

export const CardDeisnationValue = styled.p`
  font-size: 16px;
  line-height: 19px;
  font-weight: 500;
  color: #1c9d87;
  margin: 0;
`

export const CardContactBox = styled.div`
  text-align: center;
`

export const CardContactItem = styled.div`
  font-size: 16px;
  line-height: 19px;
  font-weight: 500;
  color: #1c9d87;
  margin: 0;
  padding: 15px;
  &:not(:last-child) {
    border-bottom: 1px solid #eeeeee;
  }
`

export const CardContactItemIcon = styled.img`
  width: 24px;
  height: 24px;
  display: block;
  margin: 0 auto 10px;
`

export const CardContactItemText = styled.p`
  font-size: 14px;
  line-height: 17px;
  font-weight: 500;
  color: #44554c;
  margin: 0;
`
