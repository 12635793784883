import styled from "styled-components";

export const LeftWrap = styled.div`
  display: flex;
  justify-content: space-between;
  height: 100%;
  flex-direction: column;
  width: 100%;
`;

export const LogoBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
  padding-left: 2.5rem;

  img {
    width: 5rem;
  }
`;

export const UpperBox = styled.div`
  width: 100%;
`;

export const LowerBox = styled.div`
  width: 100%;
`;

export const LastNavItem = styled.div`
  border-top: 2px solid rgba(275, 275, 275, 0.15);

  ul {
    margin-bottom: 0;
    li {
      cursor: pointer;
      padding: 0.5rem !important;

      a {
        display: flex;
        align-items: center;
        text-decoration: none;
        color: white;
        flex-direction: column;
        padding: 1rem 0.7rem;
        border-radius: 10px;
        gap: 0.5rem;
        width: 100%;

        &:hover {
          /* color: #2ecf96; */
          background: #f5f5f54a;
        }

        &.active-link {
          background: #f5f5f54a;
        }
      }
    }
  }
`;

export const SocialWrap = styled.div`
  width: 100%;
  padding: .5rem;
  display: flex;
  flex-direction: column;
  gap: .3rem;
  position: relative;

  h6 {
    font-size: .875rem;
    line-height: 1rem;
    text-align: center;
  }
`;

export const OutlineButton = styled.button`
  width: 100%;
  color: #ffffff;
  background-color: transparent;
  font-size: 1rem;
  line-height: 1.25rem;
  font-weight: 400;
  border: 1px solid #ffffff;
  border-radius: 8px;
  padding: 0.75rem 1rem;
  box-shadow: none;
  outline: transparent;
  transition: all 0.2s ease;

  &:hover {
    outline: 1px solid white;
    cursor: pointer;
  }
`;

export const SocialBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  text-align: center;
  cursor: pointer;

  a {
    img {
      width: 2rem;
      height: 2rem;
    }
  }
`;

export const MenuLinks = styled.div`
  padding: 1rem 0 0 0;
`;

export const ContentInner = styled.div`
  display: flex;
  height: 100%;
`;
