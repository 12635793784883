export const TRAINER_SIGNUP = "/trainerSignup";
export const LOGIN = "/login";
export const NEW_LOGIN = "/login-new";
export const ROOT = "/start-consultation";
export const INFORMATION = "/information";
export const MAIN_DASHBOARD = "/main/dashboard/:id";
export const DASHBOARD = "/new-consultation";
export const WHAT_AWAITS_YOU = "/whatAwaitsYou";
export const REGISTRATION = "/registration/:id";
export const CHANGE_PASSWORD = "/changePassword";
export const RESET_PASSWORD = "/reset-password-erztu134uizufd";
export const SET_PASSWORD = "/foodiary-aktivieren";
export const COURSE_CHANGE_PASSWORD = "/course-changepassword";
export const SEND_EMAIL = "/reset-password";

export const NUTRITION_CREATION = "/nutrition/creation";
export const NUTRITION_FORM = "/nutrition/nutritionForm";
export const NUTRITION_FORM_ADVANTAGES = "/nutrition/formAdvantages";
export const NUTRITION_RECIPES = "/nutrition/recipes";
export const NUTRITION_CALORIES = "/nutrition/calories";
export const NUTRITION_MACRO_NUTRIENTS = "/nutrition/macroNutrients";
export const NUTRITION_DONE = "/nutrition/done";
export const HEALTH_SCORE_INVESTIGATION = "/healthscore/investigation";
export const HEALTH_SCORE_IMPROVEMENT = "/healthscore/improvement";
export const OVERVIEW = "/overview";
export const OVERVIEW_BASIC = "/overview/basic";
export const ORDER_DETAILS = "/orderDetails";
export const FOODIARY_FAMILY = "/foodiary/family";
export const PREMIUM_COACHING = "/premiumCoaching";
export const PREMIUM_COACHING_SUCCESS = "/premiumCoaching/success";
export const HEALTH_SCORE_TIPS = "/healthscore/tips";
export const HEALTH_SCORE_PRIORITY = "/healthscore/priority";
export const HEALTH_SCORE_EVALUATION = "/healthscore/evaluation";
export const HEALTH_SCORE_ANAMNESE_EVALUATION =
  "/healthscore/anamnese_evaluations";
export const HEALTH_SCORE_DAILY_BUDGET = "/healthscore/dailyBudget";
export const SUPPLEMENTS = "/Supplements";
export const SUBSCRIPTIONS = "/subscriptions";
export const SUBSCRIPTIONS_DETAILS = "/subscription/details";
export const SUBSCRIPTIONS_PRO = "/subscription/pro";
export const SUBSCRIPTIONS_BASIC = "/subscription/basic";
export const CONTACT_PERSONS = "/contact-persons";
export const ADMINISTRATION = "/administration";
export const INFLUENCERS = "/influencers";
export const INFLUENCERS_DETAILS = "/influencers/:id";
export const OVERALL_EVALUATION = "/overall-evaluation";
export const OVERALL_EVALUATION_FORM = "/overall-evaluation-form";
export const ACADEMY = "/academy";
export const ACADEMY_DETAILS = "/academy/:id";
export const NEW_DASHBOARD = "/dashboard";
export const NEW_DASHBOARD_LAYOUT = "/dashboard";

// export const WEEKLY_CALLS_FOR_SUGGESTIONS = "/main/dashboard/weeklyCalls";
export const LEGAL_INFORMATION = '/onboarding/legal-informations'
export const ONBOARDING_STARTER = "/onboarding/starter";
export const CALL_RECORDING = '/onboarding/call-recording'
export const ONBOARDING_INTERVIEW_STEPS = "/onboarding/interview-steps";
export const ONBOARDING_TRAINER_INTRODUCTION =
  "/onboarding/trainer-introduction";
export const ONBOARDING_FOODIARY_BENEFITS = "/onboarding/foodiary-benefits";
export const ONBOARDING_REGISTRATION_GENDER = "/onboarding/registration/gender";
export const ONBOARDING_REGISTRATION_GOAL = "/onboarding/registration/goal";
export const ONBOARDING_REGISTRATION_ANAMNESE_EVALUATION =
  "/onboarding/registration/anamnese_evaluation";

export const ONBOARDING_REGISTRATION_BODY_DETAILS =
  "/onboarding/registration/body-details";
export const ONBOARDING_REGISTRATION_DAILY_ROUTINE =
  "/onboarding/registration/daily-routine";
export const ONBOARDING_REGISTRATION_ACTIVITY_LEVEL =
  "/onboarding/registration/activity-level";
export const ONBOARDING_REGISTRATION_TRAINING_DAYS =
  "/onboarding/registration/training-days";
export const ONBOARDING_REGISTRATION_DIET_CATEGORIES =
  "/onboarding/registration/diet-categories";
export const ONBOARDING_REGISTRATION_EATING_HABBITS =
  "/onboarding/registration/eating-habits/:id";
export const ONBOARDING_REGISTRATION_PERSONAL_DATA =
  "/onboarding/registration/personal-data";

export const ONBOARDING_NUTRITION_CREATION =
  "/onboarding/nutrition/creation-video";
export const ONBOARDING_NUTRITION_TYPE_UPDATE =
  "/onboarding/nutrition/type-update";
export const ONBOARDING_NUTRITION_RECIPES = "/onboarding/nutrition/recipies";
export const ONBOARDING_NUTRITION_CALORIES = "/onboarding/nutrition/calories";
export const ONBOARDING_NUTRITION_MACRO_NUTRIENTS =
  "/onboarding/nutrition/macro-nutrients";
export const ONBOARDING_NUTRITION_DONE = "/onboarding/nutrition/done";

export const ONBOARDING_HEALTHSOCRE_INVESTIGATION =
  "/onboarding/healthscore/investigation";

export const ONBOARDING_HEALTHSOCRE_PRIORITIES =
  "/onboarding/healthscore/priorities";

export const ONBOARDING_HEALTHSOCRE_ANAMNESE_EVALUATION =
  "/onboarding/healthscore/anamnese-evaluation";

export const ONBOARDING_HEALTHSOCRE_TIPS_AND_TRICKS =
  "/onboarding/healthscore/tips-and-tricks";
export const ONBOARDING_HEALTHSOCRE_IMPROVEMENTS =
  "/onboarding/healthscore/improvements";

export const ONBOARDING_HEALTHSOCRE_PREMIUM_COACHING_FEATURES =
  "/onboarding/healthscore/premium-coaching-features";

export const ONBOARDING_SUBSCRIPTIONS = "/onboarding/subscriptions";
export const ONBOARDING_SUBSCRIPTIONS_DETAILS =
  "/onboarding/subscriptions/details";

export const ONBOARDING_FOODIARY_FAMILY = "/onboarding/foodiary/family";
