import React from 'react';
import { useHistory } from "react-router-dom";
import * as S from './premiumCoaching.styles';
import { getRandomValueInRange } from "configs/utils";

// Components
import Topbar from 'components/topbar/Topbar';
import TranslationMessage from 'configs/translations/TranslationMessage';
import Card from 'components/card/Card';
import MultiCarousel from 'components/multiCarousel/MultiCarousel';

// Images
import premiumCoaching1 from "assets/images/premiumCoaching1.svg";
import premiumCoaching2 from "assets/images/premiumCoaching2.svg";
import premiumCoaching3 from "assets/images/premiumCoaching3.svg";
import premiumCoaching4 from "assets/images/premiumCoaching4.svg";
import premiumCoaching5 from "assets/images/premiumCoaching5.svg";
import premiumCoaching6 from "assets/images/premiumCoaching6.svg";
import premiumCoaching7 from "assets/images/premiumCoaching7.svg";
import premiumCoaching8 from "assets/images/premiumCoaching8.svg";

function PremiumCoaching() {
    const history = useHistory();

    const CardData = [
        { id: "card1", heading: "premium.coaching.benefit1", icon: premiumCoaching1 },
        { id: "card2", heading: "premium.coaching.benefit2", icon: premiumCoaching2 },
        { id: "card3", heading: "premium.coaching.benefit3", icon: premiumCoaching3 },
        { id: "card4", heading: "premium.coaching.benefit4", icon: premiumCoaching4 },
        { id: "card5", heading: "premium.coaching.benefit5", icon: premiumCoaching5 },
        // { id: "card6", heading: "premium.coaching.benefit6", icon: premiumCoaching6 },
        { id: "card7", heading: "premium.coaching.benefit7", icon: premiumCoaching7 },
        { id: "card8", heading: "premium.coaching.benefit8", icon: premiumCoaching8 },
    ]

    return (
        <>
            {/* healthReport */}
            <Topbar prev="/healthscore/improvement" next="/premiumCoaching/success" progress={'64%'} percent={getRandomValueInRange(40, 45)} onClose={() => history.push('/login')} close />
            <S.PremiumCoachingContainer>
                <S.PremiumCoachingHeading><TranslationMessage id="premium.coaching.heading" /></S.PremiumCoachingHeading>
                <S.PremiumCoachingSubHeading><TranslationMessage id="premium.coaching.subHeading" /></S.PremiumCoachingSubHeading>
                <MultiCarousel itemClass="carousel-item-padding-30-px" totalItems={4}>
                    {CardData.map((card) => {
                        return (
                            <Card
                                width="250px"
                                height="360px"
                                margin="auto 15px"
                                textAlign="center"
                                key={card.id}
                            >
                                <S.PremiumCoachingCardInnerContainer>
                                    <img src={card.icon} alt="cardIcon" />
                                    <S.Benefits><TranslationMessage id={card.heading} /></S.Benefits>
                                </S.PremiumCoachingCardInnerContainer>
                            </Card>
                        )
                    })}
                </MultiCarousel>
                {/* <S.PremiumCoachingCardDescription >
                    <TranslationMessage id="premium.coaching.description.p1.1" />
                    <S.PremiumCoachingCardDescriptionHeading><TranslationMessage id="premium.coaching.description.p1.2" /></S.PremiumCoachingCardDescriptionHeading>
                    <TranslationMessage id="premium.coaching.description.p1.3" />  <br />
                    <TranslationMessage id="premium.coaching.description.p2.1" />
                    <S.PremiumCoachingCardDescriptionHeading><TranslationMessage id="premium.coaching.description.p2.2" /></S.PremiumCoachingCardDescriptionHeading>
                </S.PremiumCoachingCardDescription> */}
            </S.PremiumCoachingContainer>
        </>
    )
}

export default PremiumCoaching
