import React, { useState, useEffect, useRef } from "react";
import * as S from "./trainerProfileDetails.styles";
// import { useParams, useHistory } from "react-router-dom"
import ReactPlayer from "react-player";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import { number, object, string, boolean } from "yup";
import { useIntl } from "react-intl";
import axios from "axios";
import { useAlert } from "react-alert";
import { ToastContainer, toast } from "react-toastify";
import Slider from "react-slick";

// Components
import Input from "components/formComponents/input/Input";
import Modal from "components/modal/Modal";
import TextArea from "components/formComponents/textArea/TextArea";
// Images
import UploadIcon from "assets/images/upload.svg";
import CrossIcon from "assets/images/cross.svg";
import LeftIconGreen from "assets/images/leftIconGreen.svg";
import RightIconGreen from "assets/images/rightIconGreen.svg";

// Redux
import * as AuthActions from "redux/auth/auth.actions";
import { getS3ImageUrl } from "configs/utils";
import ErrorMessage from "components/formComponents/errorMessage/ErrorMessage";

const sliderImages = [
  {
    imgSource:
      "https://foodiaryproduction.s3.eu-central-1.amazonaws.com/Website_files/Coach+Website+-+Coaching+PRO+Buchung+%E2%80%93+1.jpg",
  },
  {
    imgSource:
      "https://foodiaryproduction.s3.eu-central-1.amazonaws.com/Website_files/Coach+Website+-+Mein+Angebot+%E2%80%93+1.jpg",
  },
  {
    imgSource:
      "https://foodiaryproduction.s3.eu-central-1.amazonaws.com/Website_files/Erstgespr%C3%A4ch+%E2%80%93+1.jpg",
  },
  {
    imgSource:
      "https://foodiaryproduction.s3.eu-central-1.amazonaws.com/Website_files/Coach+Website+-+Mein+Angebot+%E2%80%93+2.jpg",
  },
  {
    imgSource:
      "https://foodiaryproduction.s3.eu-central-1.amazonaws.com/Website_files/Coach+Website+-+Mein+Coaching+%E2%80%93+2.jpg",
  },
];

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className={className} onClick={onClick} style={{ ...style }}>
      <img src={RightIconGreen} alt="" />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className={className} onClick={onClick} style={{ ...style }}>
      <img src={LeftIconGreen} alt="" />
    </div>
  );
}

const TrainerProfileWebsiteSetup = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const alert = useAlert();
  const formSubmitRef = useRef();

  const coachProfileIntroVideoRef = useRef(null);
  const websiteIdeaPicRef = useRef(null);
  const websiteWhyImYourCoachPicRef = useRef(null);
  const websiteYourWayPicRef = useRef(null);
  const websitePersonalityPicRef = useRef(null);
  const websiteCoachingPerBookingPicRef = useRef(null);
  const feedbackPicRef = useRef(null);

  const loading = useSelector((state) => state.auth.loading);
  const isWebsiteSaving = useSelector((state) => state.auth.isWebsiteCreating);
  // const saveCreatedSiteInDBRes = useSelector((state) => state.auth.createWebsite)
  const trainerProfileData = useSelector((state) => state.auth.trainerData);
  const trainerProfileDataRes = useSelector(
    (state) => state.auth.trainerDataRes
  );
  // const registrationData = useSelector((state) => state.auth.registrationData)

  const [section2Edit, setSection2Edit] = useState(true);
  const [profileImage, setProfileImage] = useState(null);

  const [coachProfileIntroVideo, setCoachProfileIntroVideoImage] =
    useState(null);
  const [websiteIdeaImage, setWebsiteIdeaImage] = useState(null);
  const [websiteWhyImYourCoachImage, setWebsiteWhyImYourCoachImage] =
    useState(null);
  const [websiteYourWayImage, setWebsiteYourWayImage] = useState(null);
  const [websitePersonality, setWebsitePersonality] = useState(null);
  const [websiteCoachingPerBookingImage, setWebsiteCoachingPerBookingImage] =
    useState(null);
  const [mediaValidations, setMediaValidations] = useState({
    introVideo: false,
    idea: false,
    whyImYourCoach: false,
    yourWay: false,
    personality: false,
    coachingPerBooking: false,
  });
  const [mediaTypeValidations, setMediaTypeValidations] = useState({
    introVideo: false,
    idea: false,
    whyImYourCoach: false,
    yourWay: false,
    personality: false,
    coachingPerBooking: false,
  });

  const [selectedSubtitle, setSelectedSubtitle] = useState(
    trainerProfileData?.subtitle
  );

  const [saveWebsiteMsg, setSaveWebsiteMsg] = useState(false);

  const [isWebsiteCreating, setIsWebsiteCreating] = useState(false);
  const [showCreateWebsiteMsg, setShowCreateWebsiteMsg] = useState(false);
  const [createWebsiteRes, setCreateWebsiteRes] = useState(null);

  const [IsInformationModalOpen, SetIsInformationModalOpen] = useState(false);
  const [IsSubmitting, SetIsSubmitting] = useState(false);
  const [isTryToUpdating, setIsTryToUpdating] = useState(false);

  useEffect(() => {
    getTrainerProfileDetails();
  }, []);

  const getTrainerProfileDetails = () => {
    dispatch(
      AuthActions.getCoachCardWebsiteDetailsRequest({
        trainerID: trainerProfileData.id,
      })
    );
  };

  const initialValues = {
    websiteIntroText: trainerProfileData?.cw_lp_textfield_1 || "",
    websiteWhyImYourCoach: trainerProfileData?.cw_lp_textfield_2 || "",
    whatsappURLForCoaching: trainerProfileData?.whatsapp_url || "",
    instagramURLForCoaching: trainerProfileData?.instagram_url || "",
  };

  const validationSchema = object().shape({
    websiteWhyImYourCoach: string(),
    websiteIntroText: string(),
    whatsappURLForCoaching: string(),
    instagramURLForCoaching: string(),
  });

  const onFormSubmit = (values, { resetForm }) => {
    if (values.websiteIntroText.length > 400) {
      toast("❌ Dein Text ist zu lange. Bitte kürze diesen.");
      return;
    }
    SetIsSubmitting(true);

    const formData = new FormData();

    formData.append("trainerID", trainerProfileData.trainerID);
    formData.append("FranchiseID", trainerProfileData.FranchiseID);
    formData.append("instagram_url", values.instagramURLForCoaching || "");
    formData.append("whatsapp_url", values.whatsappURLForCoaching || "");

    formData.append("cw_lp_textfield_1", values.websiteIntroText || "");
    formData.append("cw_lp_textfield_2", values.websiteWhyImYourCoach || "");

    profileImage && formData.append("profilePic", profileImage);
    coachProfileIntroVideo &&
      formData.append(
        "pp_Intoduction_video_profilpage",
        coachProfileIntroVideo
      );

    websiteIdeaImage && formData.append("cw_lp_picture_1", websiteIdeaImage);
    websiteWhyImYourCoachImage &&
      formData.append("cw_lp_picture_2", websiteWhyImYourCoachImage);
    websiteYourWayImage &&
      formData.append("cw_fm_picture_2", websiteYourWayImage);
    websitePersonality &&
      formData.append("cw_cp_picture_2", websitePersonality);
    websiteCoachingPerBookingImage &&
      formData.append("cw_sb_picture_1", websiteCoachingPerBookingImage);

    dispatch(
      AuthActions.updateTrainerDetailsRequest({
        formData,
        funcSuccess: (res) => {
          if (res?.success) {
            toast("✅ Deine Änderungen wurden gespeichert");

            resetForm();
            setProfileImage(null);
            setCoachProfileIntroVideoImage(null);
            setWebsiteIdeaImage(null);
            setWebsiteWhyImYourCoachImage(null);
            setWebsiteYourWayImage(null);
            setWebsitePersonality(null);
            setWebsiteCoachingPerBookingImage(null);
          }

          if (!res?.success) {
            toast("❌ Bitte überprüfe deine Eingaben");
          }

          SetIsSubmitting(false);
          getTrainerProfileDetails();
          resetForm();
        },
      })
    );
  };

  const handleImageChange = (key) => {
    switch (key) {
      case "Video":
        coachProfileIntroVideoRef.current.click();
        break;
      case "IdeaPic":
        websiteIdeaPicRef.current.click();
        break;
      case "WhyImYourCoachPic":
        websiteWhyImYourCoachPicRef.current.click();
        break;
      case "YourWayPic":
        websiteYourWayPicRef.current.click();
        break;
      case "PersonalityPic":
        websitePersonalityPicRef.current.click();
        break;
      case "CoachingPerBookingPic":
        websiteCoachingPerBookingPicRef.current.click();
        break;
      case "FeedbackPic":
        feedbackPicRef.current.click();
        break;
      default:
        break;
    }
  };

  const handleCreateWebsite = async () => {
    const formData = new FormData();
    const subDomain =
      trainerProfileData.prename + "-" + trainerProfileData.FranchiseID;
    const coachWebsiteUrl = `https://${subDomain}.foodiary.app`;
    const firstMeetingUrl = `https://${subDomain}.foodiary.app/erstgespraech`;
    formData.append("domain", subDomain);
    setIsWebsiteCreating(true);
    try {
      const { data } = await axios({
        method: "post",
        url: "https://coach.foodiary.app/coach-subdomain.php",
        // url: "https://coach.foodiary.app/dashboard/coach-subdomain.php",
        data: formData,
      });

      setCreateWebsiteRes(data);
      setShowCreateWebsiteMsg(true);
      setIsWebsiteCreating(false);
      const timer = setTimeout(() => {
        setShowCreateWebsiteMsg(false);
        clearTimeout(timer);
      }, 4000);

      // if (!data?.success) return;
      dispatch(
        AuthActions.createWebsiteRequest({
          formData: {
            trainerID: trainerProfileData.id,
            coach_website_url: coachWebsiteUrl,
            first_meeting_url: firstMeetingUrl,
            // booking_page_pro: 'success url'
          },
          callback: (res) => {
            setSaveWebsiteMsg(true);
            const timer = setTimeout(() => {
              setSaveWebsiteMsg(false);
              clearTimeout(timer);
            }, 5000);
          },
        })
      );
    } catch (err) {
      console.log(err.message);
    }
  };

  const isValidFile = (file, control) => {
    // setCoachProfileIntroVideoImage(null);
    // setWebsiteIdeaImage(null);
    // setWebsiteWhyImYourCoachImage(null);
    // setWebsiteYourWayImage(null);
    // setWebsitePersonality(null);
    // setWebsiteCoachingPerBookingImage(null);
    console.log("file", file);

    const validExtensions = ["jpg", "jpeg", "png"];
    let fileExtension = file.type.split("/")[1];
    switch (control) {
      case "introVideo":
        fileExtension = file.type.split("/")[0];
        const result = fileExtension.toLowerCase() === "video";

        setMediaTypeValidations({
          ...mediaTypeValidations,
          introVideo: !result,
        });
        return result;
      case "idea":
        setMediaTypeValidations({
          ...mediaTypeValidations,
          idea: !validExtensions.includes(fileExtension),
        });
        return validExtensions.includes(fileExtension);
      case "whyImYourCoach":
        setMediaTypeValidations({
          ...mediaTypeValidations,
          whyImYourCoach: !validExtensions.includes(fileExtension),
        });
        return validExtensions.includes(fileExtension);
      case "yourWay":
        setMediaTypeValidations({
          ...mediaTypeValidations,
          yourWay: !validExtensions.includes(fileExtension),
        });
        return validExtensions.includes(fileExtension);
      case "personality":
        setMediaTypeValidations({
          ...mediaTypeValidations,
          personality: !validExtensions.includes(fileExtension),
        });
        return validExtensions.includes(fileExtension);
      case "coachPerBooking":
        setMediaTypeValidations({
          ...mediaTypeValidations,
          coachingPerBooking: !validExtensions.includes(fileExtension),
        });
        return validExtensions.includes(fileExtension);
      default:
        setMediaTypeValidations({
          ...mediaTypeValidations,
        });
        break;
    }
  };

  const isFileBig = (file, control) => {
    setMediaValidations({
      introVideo: false,
      idea: false,
      whyImYourCoach: false,
      yourWay: false,
      personality: false,
      coachingPerBooking: false,
    });

    const size = +(file.size / 1024 / 1024).toFixed(2);
    if (size <= 3) {
      return false;
    }

    switch (control) {
      case "introVideo":
        setMediaValidations({
          ...mediaValidations,
          introVideo: true,
        });
        return true;
      case "idea":
        setMediaValidations({
          ...mediaValidations,
          idea: true,
        });
        return true;
      case "whyImYourCoach":
        setMediaValidations({
          ...mediaValidations,
          whyImYourCoach: true,
        });
        return true;
      case "yourWay":
        setMediaValidations({
          ...mediaValidations,
          yourWay: true,
        });
        return true;
      case "personality":
        setMediaValidations({
          ...mediaValidations,
          personality: true,
        });
        return true;
      case "coachPerBooking":
        setMediaValidations({
          ...mediaValidations,
          coachingPerBooking: true,
        });
        return true;
      default:
        setMediaValidations({
          ...mediaValidations,
        });
        break;
    }
  };

  const onProfileUpdate = () => {
    // setIsTryToUpdating(true);
    // const t = setTimeout(() => {
    //   setIsTryToUpdating(false);
    //   clearTimeout(t);
    // }, 12000);

    if (formSubmitRef.current) {
      formSubmitRef.current.handleSubmit();
    }
  };

  let settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    infinite: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  return (
    <S.TrainerProfileDetailsOverallContainer>
      
      <S.TrainerProfileDetailsInnerContainer className="p-0">
        <S.Header className="">
          <div>
            <S.TrainerProfileDetailsSectionHeading>
              Webseite einrichten
            </S.TrainerProfileDetailsSectionHeading>
            <S.TrainerProfileDetailsSectionSubHeading>
              Veröffentliche deine eigene Webseite als Foodiary Coach
            </S.TrainerProfileDetailsSectionSubHeading>
          </div>
          <div>
            <div className="header-actions">
              <button
                className="btn-outline sm"
                onClick={() => SetIsInformationModalOpen(true)}
              >
                Platzierungen anzeigen
              </button>
              <button
                className="btn-dark"
                disabled={loading || IsSubmitting}
                onClick={onProfileUpdate}
              >
                {IsSubmitting ? "wird gespeichert…" : "Änderungen speichern"}
              </button>
            </div>
          </div>
        </S.Header>
        <S.Divider />

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onFormSubmit}
          innerRef={formSubmitRef}
          enableReinitialize
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            errors,
            isValid,
            dirty,
          }) => (
            <form noValidate autoComplete="off" onSubmit={handleSubmit}>
              {/* {showCreateWebsiteMsg && createWebsiteRes?.success && (
                  <S.TrainerProfileDetailsSectionSubHeading
                    style={{
                      color: "#1C9D87",
                      fontWeight: 400,
                      padding: 0,
                      marginTop: 16,
                    }}
                  >
                    {createWebsiteRes?.message}
                  </S.TrainerProfileDetailsSectionSubHeading>
                )}
                {showCreateWebsiteMsg && !createWebsiteRes?.success && (
                  <S.TrainerProfileDetailsSectionSubHeading
                    style={{
                      color: "red",
                      fontWeight: 400,
                      padding: 0,
                      marginTop: 16,
                    }}
                  >
                    {createWebsiteRes?.message}
                  </S.TrainerProfileDetailsSectionSubHeading>
                )} */}

              {/* <S.ToggleSwitchWrapper>
                  <S.ToggleSwitchLabelOne active={false}>
                    Status
                  </S.ToggleSwitchLabelOne>
                  <S.ToggleSwitch>
                    <label
                      className={
                        isWebsiteCreating || isWebsiteSaving
                          ? "switch disabled"
                          : "switch"
                      }
                    >
                      <input
                        type="checkbox"
                        name="customerCare"
                        onChange={(e) => {
                          if (e.target.checked) {
                            handleCreateWebsite();
                          }
                        }}
                        checked={values.coachWebsite !== ""}
                      />
                      <span className="slider round"></span>
                    </label>
                    <S.ToggleSwitchLabelTwo active>
                      Website veröffentlichen
                    </S.ToggleSwitchLabelTwo>
                  </S.ToggleSwitch>
                </S.ToggleSwitchWrapper>
                <S.Divider /> */}
              <S.PaddingBox>
                <div>
                  <S.SectionHeading>Webseiten-Details:</S.SectionHeading>
                  <br />
                </div>
                {isTryToUpdating && (
                  <div className="alert danger">
                    Wir arbeiten mit Hochdruck daran, die Funktion wieder
                    herzustellen. Am 14. Juni sollte alles wie gewohnt
                    funktionieren.
                  </div>
                )}
                <S.InlineControls>
                  <S.FormControl>
                    <S.TrainerProfileDetailsSection1InputLabel>
                      {intl.formatMessage({
                        id: "trainerProfile.whatsappURLForCoaching",
                      })}
                    </S.TrainerProfileDetailsSection1InputLabel>
                    <Input
                      className="input-custom"
                      style={{ width: "100%", marginBottom: "1.25rem" }}
                      placeholder={intl.formatMessage({
                        id: "trainerProfile.whatsappURLForCoaching",
                      })}
                      name="whatsappURLForCoaching"
                      onChange={handleChange}
                      value={values.whatsappURLForCoaching}
                    />
                  </S.FormControl>
                  <S.FormControl>
                    <S.TrainerProfileDetailsSection1InputLabel>
                      {intl.formatMessage({
                        id: "trainerProfile.instagramURLForCoaching",
                      })}
                    </S.TrainerProfileDetailsSection1InputLabel>
                    <Input
                      className="input-custom"
                      style={{ width: "100%", marginBottom: "1.25rem" }}
                      placeholder={intl.formatMessage({
                        id: "trainerProfile.instagramURLForCoaching",
                      })}
                      name="instagramURLForCoaching"
                      onChange={handleChange}
                      value={values.instagramURLForCoaching}
                    />
                  </S.FormControl>
                </S.InlineControls>
                <div className="textarea-box mb">
                  <S.TrainerProfileDetailsSection1InputLabel className="info-label">
                    {intl.formatMessage({
                      id: "trainerProfile.websiteIntroText",
                    })}
                  </S.TrainerProfileDetailsSection1InputLabel>
                  <TextArea
                    rows={6}
                    placeholder={intl.formatMessage({
                      id: "trainerProfile.websiteIntroText",
                    })}
                    name="websiteIntroText"
                    onChange={handleChange}
                    value={values.websiteIntroText}
                    style={{
                      margin: "0",
                      border: "#F8F8F8",
                      background: "#F8F8F8",
                    }}
                  />
                  <div className="input-info">
                    {errors.websiteIntroText && (
                      <ErrorMessage error={errors.websiteIntroText} />
                    )}
                    {!errors.websiteIntroText && <span></span>}
                    <span>
                      <span
                        className={
                          values.websiteIntroText.length > 400
                            ? "text-red font-rubik-medium"
                            : ""
                        }
                      >
                        {values.websiteIntroText.length}
                      </span>{" "}
                      / 400
                    </span>
                  </div>
                </div>
                <div className="textarea-box mb">
                  <S.TrainerProfileDetailsSection1InputLabel className="info-label">
                    {intl.formatMessage({
                      id: "trainerProfile.websiteWhyImYourCoach",
                    })}
                  </S.TrainerProfileDetailsSection1InputLabel>
                  <TextArea
                    rows={6}
                    placeholder={intl.formatMessage({
                      id: "trainerProfile.websiteWhyImYourCoach",
                    })}
                    name="websiteWhyImYourCoach"
                    onChange={handleChange}
                    value={values.websiteWhyImYourCoach}
                    style={{
                      margin: "0",
                      border: "#F8F8F8",
                      background: "#F8F8F8",
                    }}
                  />
                </div>
              </S.PaddingBox>
              <S.Divider />
              <S.PaddingBox>
                <div>
                  <S.SectionHeading>Webseiten-Bilder:</S.SectionHeading>
                  <br />
                </div>
                <S.MediaUploadBlock>
                  <S.MediaUploadItem>
                    <S.MediaUploadLabel className="info-label">
                      {intl.formatMessage({
                        id: "trainerProfile.introVideo",
                      })}
                    </S.MediaUploadLabel>
                    {mediaValidations.introVideo && (
                      <S.ValidationText>
                        {intl.formatMessage({
                          id: "trainerProfile.fileSizeValidationText",
                        })}
                      </S.ValidationText>
                    )}
                    {mediaTypeValidations.introVideo && (
                      <S.ValidationText>
                        {intl.formatMessage({
                          id: "trainerProfile.videoTypeValidation",
                        })}
                      </S.ValidationText>
                    )}
                    <S.VideoPlaceholder
                      className={
                        !trainerProfileData?.pp_Intoduction_video_profilpage
                          ? "bordered"
                          : ""
                      }
                      onClick={() =>
                        !trainerProfileData?.pp_Intoduction_video_profilpage
                          ? handleImageChange("Video")
                          : null
                      }
                    >
                      {coachProfileIntroVideo && (
                        <S.FileName>{coachProfileIntroVideo?.name}</S.FileName>
                      )}

                      {!trainerProfileData?.pp_Intoduction_video_profilpage && (
                        <div className="placeholer-content">
                          <div className="upload-sq-icon mx-auto">
                            <img src={UploadIcon} alt="" />
                          </div>
                          <p>
                            <strong>Klicke</strong> auf das Icon zum hochladen.
                          </p>
                          <span>MP4 bis 3 MB erlaubt</span>
                        </div>
                      )}

                      {trainerProfileData?.pp_Intoduction_video_profilpage && (
                        <>
                          <ReactPlayer
                            className="player"
                            url={getS3ImageUrl(
                              trainerProfileData?.pp_Intoduction_video_profilpage
                            )}
                            controls
                          />
                          <div
                            className="upload"
                            onClick={() => handleImageChange("Video")}
                          >
                            <img src={UploadIcon} alt="" />
                          </div>
                        </>
                      )}
                    </S.VideoPlaceholder>
                    <input
                      type="file"
                      style={{ display: "none" }}
                      ref={coachProfileIntroVideoRef}
                      onChange={(e) => {
                        if (
                          !isFileBig(e.target.files[0], "introVideo") &&
                          isValidFile(e.target.files[0], "introVideo")
                        ) {
                          setCoachProfileIntroVideoImage(e.target.files[0]);
                        }
                      }}
                    />
                  </S.MediaUploadItem>
                  <S.MediaUploadItem>
                    <S.MediaUploadLabel className="info-label">
                      {intl.formatMessage({
                        id: "trainerProfile.ideaImage",
                      })}
                    </S.MediaUploadLabel>
                    {mediaValidations.idea && (
                      <S.ValidationText>
                        {intl.formatMessage({
                          id: "trainerProfile.fileSizeValidationTextForImages",
                        })}
                      </S.ValidationText>
                    )}
                    {mediaTypeValidations.idea && (
                      <S.ValidationText>
                        {intl.formatMessage({
                          id: "trainerProfile.imageTypeValidation",
                        })}
                      </S.ValidationText>
                    )}
                    <S.MediaPlaceholder
                      className={
                        !trainerProfileData?.cw_lp_picture_1 ? "bordered" : ""
                      }
                      onClick={() =>
                        !trainerProfileData?.cw_lp_picture_1
                          ? handleImageChange("IdeaPic")
                          : null
                      }
                    >
                      {websiteIdeaImage && (
                        <S.FileName>{websiteIdeaImage?.name}</S.FileName>
                      )}
                      {trainerProfileData?.cw_lp_picture_1 && (
                        <img
                          src={getS3ImageUrl(
                            trainerProfileData?.cw_lp_picture_1
                          )}
                          alt="placeholder"
                        />
                      )}

                      {!trainerProfileData?.cw_lp_picture_1 && (
                        <div className="placeholer-content">
                          <div className="upload-sq-icon mx-auto">
                            <img src={UploadIcon} alt="" />
                          </div>
                          <p>
                            <strong>Klicke</strong> auf das Icon zum hochladen.
                          </p>
                          <span>PNG, JPG bis 3 MB erlaubt</span>
                        </div>
                      )}
                      {trainerProfileData?.cw_lp_picture_1 && (
                        <div
                          className="upload-icon"
                          onClick={() => handleImageChange("IdeaPic")}
                        >
                          <img src={UploadIcon} alt="" />
                        </div>
                      )}
                    </S.MediaPlaceholder>
                    <input
                      type="file"
                      style={{ display: "none" }}
                      ref={websiteIdeaPicRef}
                      onChange={(e) => {
                        if (
                          !isFileBig(e.target.files[0], "idea") &&
                          isValidFile(e.target.files[0], "idea")
                        ) {
                          setWebsiteIdeaImage(e.target.files[0]);
                        }
                      }}
                    />
                  </S.MediaUploadItem>
                  <S.MediaUploadItem>
                    <S.MediaUploadLabel className="info-label">
                      {intl.formatMessage({
                        id: "trainerProfile.whyImYourCoachImage",
                      })}
                    </S.MediaUploadLabel>
                    {mediaValidations.whyImYourCoach && (
                      <S.ValidationText>
                        {intl.formatMessage({
                          id: "trainerProfile.fileSizeValidationTextForImages",
                        })}
                      </S.ValidationText>
                    )}
                    {mediaTypeValidations.whyImYourCoach && (
                      <S.ValidationText>
                        {intl.formatMessage({
                          id: "trainerProfile.imageTypeValidation",
                        })}
                      </S.ValidationText>
                    )}
                    <S.MediaPlaceholder
                      className={
                        !trainerProfileData?.cw_lp_picture_2 ? "bordered" : ""
                      }
                      onClick={() =>
                        !trainerProfileData?.cw_lp_picture_2
                          ? handleImageChange("WhyImYourCoachPic")
                          : null
                      }
                    >
                      {websiteWhyImYourCoachImage && (
                        <S.FileName>
                          {websiteWhyImYourCoachImage?.name}
                        </S.FileName>
                      )}
                      {trainerProfileData?.cw_lp_picture_2 && (
                        <img
                          src={getS3ImageUrl(
                            trainerProfileData?.cw_lp_picture_2
                          )}
                          alt="cw_lp_picture_2"
                        />
                      )}
                      {!trainerProfileData?.cw_lp_picture_2 && (
                        <div className="placeholer-content">
                          <div className="upload-sq-icon mx-auto">
                            <img src={UploadIcon} alt="" />
                          </div>
                          <p>
                            <strong>Klicke</strong> auf das Icon zum hochladen.
                          </p>
                          <span>PNG, JPG bis 3 MB erlaubt</span>
                        </div>
                      )}
                      {trainerProfileData?.cw_lp_picture_2 && (
                        <div
                          className="upload-icon"
                          onClick={() => handleImageChange("WhyImYourCoachPic")}
                        >
                          <img src={UploadIcon} alt="" />
                        </div>
                      )}
                    </S.MediaPlaceholder>
                    <input
                      type="file"
                      style={{ display: "none" }}
                      ref={websiteWhyImYourCoachPicRef}
                      onChange={(e) => {
                        if (
                          !isFileBig(e.target.files[0], "whyImYourCoach") &&
                          isValidFile(e.target.files[0], "whyImYourCoach")
                        ) {
                          setWebsiteWhyImYourCoachImage(e.target.files[0]);
                        }
                      }}
                    />
                  </S.MediaUploadItem>
                  <S.MediaUploadItem>
                    <S.MediaUploadLabel className="info-label">
                      {intl.formatMessage({
                        id: "trainerProfile.yourWayImage",
                      })}
                    </S.MediaUploadLabel>
                    {mediaValidations.yourWay && (
                      <S.ValidationText>
                        {intl.formatMessage({
                          id: "trainerProfile.fileSizeValidationTextForImages",
                        })}
                      </S.ValidationText>
                    )}
                    {mediaTypeValidations.yourWay && (
                      <S.ValidationText>
                        {intl.formatMessage({
                          id: "trainerProfile.imageTypeValidation",
                        })}
                      </S.ValidationText>
                    )}
                    <S.MediaPlaceholder
                      className={
                        !trainerProfileData?.cw_fm_picture_2 ? "bordered" : ""
                      }
                      onClick={() =>
                        !trainerProfileData?.cw_fm_picture_2
                          ? handleImageChange("YourWayPic")
                          : null
                      }
                    >
                      {websiteYourWayImage && (
                        <S.FileName>{websiteYourWayImage?.name}</S.FileName>
                      )}
                      {trainerProfileData?.cw_fm_picture_2 && (
                        <img
                          src={getS3ImageUrl(
                            trainerProfileData?.cw_fm_picture_2
                          )}
                          alt="cw_fm_picture_2"
                        />
                      )}
                      {!trainerProfileData?.cw_fm_picture_2 && (
                        <div className="placeholer-content">
                          <div className="upload-sq-icon mx-auto">
                            <img src={UploadIcon} alt="" />
                          </div>
                          <p>
                            <strong>Klicke</strong> auf das Icon zum hochladen.
                          </p>
                          <span>PNG, JPG bis 3 MB erlaubt</span>
                        </div>
                      )}
                      {trainerProfileData?.cw_fm_picture_2 && (
                        <div
                          className="upload-icon"
                          onClick={() => handleImageChange("YourWayPic")}
                        >
                          <img src={UploadIcon} alt="" />
                        </div>
                      )}
                    </S.MediaPlaceholder>
                    <input
                      type="file"
                      style={{ display: "none" }}
                      ref={websiteYourWayPicRef}
                      onChange={(e) => {
                        if (
                          !isFileBig(e.target.files[0], "yourWay") &&
                          isValidFile(e.target.files[0], "yourWay")
                        ) {
                          setWebsiteYourWayImage(e.target.files[0]);
                        }
                      }}
                    />
                  </S.MediaUploadItem>
                  <S.MediaUploadItem>
                    <S.MediaUploadLabel className="info-label">
                      {intl.formatMessage({
                        id: "trainerProfile.personalityImage",
                      })}
                    </S.MediaUploadLabel>
                    {mediaValidations.personality && (
                      <S.ValidationText>
                        {intl.formatMessage({
                          id: "trainerProfile.fileSizeValidationTextForImages",
                        })}
                      </S.ValidationText>
                    )}
                    {mediaTypeValidations.personality && (
                      <S.ValidationText>
                        {intl.formatMessage({
                          id: "trainerProfile.imageTypeValidation",
                        })}
                      </S.ValidationText>
                    )}
                    <S.MediaPlaceholder
                      className={
                        !trainerProfileData?.cw_cp_picture_2 ? "bordered" : ""
                      }
                      onClick={() =>
                        !trainerProfileData?.cw_cp_picture_2
                          ? handleImageChange("PersonalityPic")
                          : null
                      }
                    >
                      {websitePersonality && (
                        <S.FileName>{websitePersonality?.name}</S.FileName>
                      )}
                      {trainerProfileData?.cw_cp_picture_2 && (
                        <img
                          className="cw_cp_picture_2-image"
                          src={getS3ImageUrl(
                            trainerProfileData?.cw_cp_picture_2
                          )}
                          alt="cw_cp_picture_2"
                        />
                      )}

                      {!trainerProfileData?.cw_cp_picture_2 && (
                        <div className="placeholer-content">
                          <div className="upload-sq-icon mx-auto">
                            <img src={UploadIcon} alt="" />
                          </div>
                          <p>
                            <strong>Klicke</strong> auf das Icon zum hochladen.
                          </p>
                          <span>PNG, JPG bis 3 MB erlaubt</span>
                        </div>
                      )}
                      {trainerProfileData?.cw_cp_picture_2 && (
                        <div
                          className="upload-icon"
                          onClick={() => handleImageChange("PersonalityPic")}
                        >
                          <img src={UploadIcon} alt="" />
                        </div>
                      )}
                    </S.MediaPlaceholder>
                    {/* <S.MediaUpload
        onClick={() => handleImageChange("PersonalityPic")}
      >
        <img src={UploadIcon} alt="" />
        <span>Datei auswählen</span>
      </S.MediaUpload> */}
                    <input
                      type="file"
                      style={{ display: "none" }}
                      ref={websitePersonalityPicRef}
                      onChange={(e) => {
                        if (
                          !isFileBig(e.target.files[0], "personality") &&
                          isValidFile(e.target.files[0], "personality")
                        ) {
                          setWebsitePersonality(e.target.files[0]);
                        }
                      }}
                    />
                  </S.MediaUploadItem>
                  <S.MediaUploadItem>
                    <S.MediaUploadLabel>
                      {intl.formatMessage({
                        id: "trainerProfile.bookingImage",
                      })}
                    </S.MediaUploadLabel>
                    {mediaValidations.coachingPerBooking && (
                      <S.ValidationText>
                        {intl.formatMessage({
                          id: "trainerProfile.fileSizeValidationTextForImages",
                        })}
                      </S.ValidationText>
                    )}
                    {mediaTypeValidations.coachingPerBooking && (
                      <S.ValidationText>
                        {intl.formatMessage({
                          id: "trainerProfile.imageTypeValidation",
                        })}
                      </S.ValidationText>
                    )}
                    <S.MediaPlaceholder
                      className={
                        !trainerProfileData?.cw_sb_picture_1 ? "bordered" : ""
                      }
                      onClick={() =>
                        !trainerProfileData?.cw_sb_picture_1
                          ? handleImageChange("CoachingPerBookingPic")
                          : null
                      }
                    >
                      {websiteCoachingPerBookingImage && (
                        <S.FileName>
                          {websiteCoachingPerBookingImage?.name}
                        </S.FileName>
                      )}
                      {trainerProfileData?.cw_sb_picture_1 && (
                        <img
                          src={getS3ImageUrl(
                            trainerProfileData?.cw_sb_picture_1
                          )}
                          alt="cw_sb_picture_1"
                        />
                      )}
                      {!trainerProfileData?.cw_sb_picture_1 && (
                        <div className="placeholer-content">
                          <div className="upload-sq-icon mx-auto">
                            <img src={UploadIcon} alt="" />
                          </div>
                          <p>
                            <strong>Klicke</strong> auf das Icon zum hochladen.
                          </p>
                          <span>PNG, JPG bis 3 MB erlaubt</span>
                        </div>
                      )}

                      {trainerProfileData?.cw_sb_picture_1 && (
                        <div
                          className="upload-icon"
                          onClick={() =>
                            handleImageChange("CoachingPerBookingPic")
                          }
                        >
                          <img src={UploadIcon} alt="" />
                        </div>
                      )}
                    </S.MediaPlaceholder>
                    <input
                      type="file"
                      style={{ display: "none" }}
                      ref={websiteCoachingPerBookingPicRef}
                      onChange={(e) => {
                        if (
                          !isFileBig(e.target.files[0], "coachPerBooking") &&
                          isValidFile(e.target.files[0], "coachPerBooking")
                        ) {
                          setWebsiteCoachingPerBookingImage(e.target.files[0]);
                        }
                      }}
                    />
                  </S.MediaUploadItem>
                </S.MediaUploadBlock>
              </S.PaddingBox>
            </form>
          )}
        </Formik>
      </S.TrainerProfileDetailsInnerContainer>
      <br />
      <Modal
        width="550px"
        height="auto"
        show={IsInformationModalOpen}
        setShow={() => null}
        heading={
          <div className="d-flex align-items-center justify-content-between">
            <h4 className="my-0 text-left">Infoscreen </h4>
            <img
              className="cursor-pointer"
              src={CrossIcon}
              alt="CrossIcon"
              onClick={() => SetIsInformationModalOpen(false)}
            />
          </div>
        }
      >
        <div className="modal-body">
          <div className="slider-container info-modal-slider w-100">
            <p className="text-center arrows-text">Platzierung finden</p>
            <br />
            <Slider {...settings}>
              {sliderImages.map((item) => (
                <div className="slide-item">
                  <img src={item.imgSource} alt="" />
                </div>
              ))}
            </Slider>
          </div>
          <div className="slider-content">
            <h6>Die richtige Platzierung für deine Bilder</h6>
            <br />
            <S.Divider />
            <br />
            <p>
              Hier findest du die Platzierung der einzelnen Medien, welche du
              auf der Webseite veröffentlichen kannst.
            </p>
          </div>
        </div>
      </Modal>
    </S.TrainerProfileDetailsOverallContainer>
  );
};

export default TrainerProfileWebsiteSetup;
