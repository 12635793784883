import EmojiOne from "assets/images/Emoji-1.svg";
import EmojiOneActive from "assets/images/Emoji-1-Active.svg";
import EmojiTwo from "assets/images/Emoji-2.svg";
import EmojiTwoActive from "assets/images/Emoji-2-Active.svg";
import EmojiThree from "assets/images/Emoji-3.svg";
import EmojiThreeActive from "assets/images/Emoji-3-Active.svg";
import EmojiFour from "assets/images/Emoji-4.svg";
import EmojiFourActive from "assets/images/Emoji-4-Active.svg";
import EmojiFive from "assets/images/Emoji-5.svg";
import EmojiFiveActive from "assets/images/Emoji-5-Active.svg";

import ShapeOne from "assets/images/Shape-1.svg";
import ShapeOneActive from "assets/images/Shape-1-Active.svg";
import ShapeTwo from "assets/images/Shape-2.svg";
import ShapeTwoActive from "assets/images/Shape-2-Active.svg";
import ShapeThree from "assets/images/Shape-3.svg";
import ShapeThreeActive from "assets/images/Shape-3-Active.svg";
import ShapeFour from "assets/images/Shape-4.svg";
import ShapeFourActive from "assets/images/Shape-4-Active.svg";
import ShapeFive from "assets/images/Shape-5.svg";
import ShapeFiveActive from "assets/images/Shape-5-Active.svg";

export const EVALUATION_ITEMS = [
  {
    id: 1,
    title: "Stresslevel",
    descr: "Wie hoch ist dein aktuelles Stresslevel?",
    descr2:
      "Zwei flinke Boxer jagen die quirlige Eva und ihren Mops durch Sylt. Franz jagt im komplett verwahrlosten Taxi quer durch Bayern.",
    value: "",
    isNumber: false,
    isEmoji: true,
    isShape: false,
    images: [
      {
        state: 1,
        value: 1,
        isActive: false,
        img: EmojiOne,
        activeImg: EmojiOneActive,
        color: "green-4",
      },
      {
        state: 2,
        value: 2,
        isActive: false,
        img: EmojiTwo,
        activeImg: EmojiTwoActive,
        color: "light-green",
      },
      {
        state: 3,
        value: 3,
        isActive: false,
        img: EmojiThree,
        activeImg: EmojiThreeActive,
        color: "light-orange",
      },
      {
        state: 4,
        value: 4,
        isActive: false,
        img: EmojiFour,
        activeImg: EmojiFourActive,
        color: "orange",
      },
      {
        state: 5,
        value: 5,
        isActive: false,
        img: EmojiFive,
        activeImg: EmojiFiveActive,
        color: "red-4",
      },
    ],
  },
  {
    id: 2,
    title: "Körperform",
    descr: "Welche Körperform beschreibt dich am besten?",
    descr2:
      "Zwei flinke Boxer jagen die quirlige Eva und ihren Mops durch Sylt. Franz jagt im komplett verwahrlosten Taxi quer durch Bayern.",
    value: "",
    isNumber: false,
    isEmoji: false,
    isShape: true,
    images: [
      {
        state: 1,
        value: 1,
        isActive: false,
        img: ShapeOne,
        activeImg: ShapeOneActive,
        color: "green-4",
      },
      {
        state: 2,
        value: 2,
        isActive: false,
        img: ShapeTwo,
        activeImg: ShapeTwoActive,
        color: "light-green",
      },
      {
        state: 3,
        value: 3,
        isActive: false,
        img: ShapeThree,
        activeImg: ShapeThreeActive,
        color: "light-orange",
      },
      {
        state: 4,
        value: 4,
        isActive: false,
        img: ShapeFour,
        activeImg: ShapeFourActive,
        color: "orange",
      },
      {
        state: 5,
        value: 5,
        isActive: false,
        img: ShapeFive,
        activeImg: ShapeFiveActive,
        color: "red-4",
      },
    ],
  },
  {
    id: 3,
    title: "Sportliche Aktivität",
    descr: "Wie häufig treibst du Sport in der Woche?",
    descr2:
      "Zwei flinke Boxer jagen die quirlige Eva und ihren Mops durch Sylt. Franz jagt im komplett verwahrlosten Taxi quer durch Bayern.",
    value: "",
    isNumber: true,
    isEmoji: false,
    isShape: false,
    numbers: [
      { state: 1, value: 1, isActive: false, title: "0", color: "red-4" },
      { state: 2, value: 2, isActive: false, title: "1", color: "green-1" },
      { state: 3, value: 3, isActive: false, title: "2-3", color: "green-2" },
      { state: 4, value: 4, isActive: false, title: "3-5", color: "green-3" },
      { state: 5, value: 5, isActive: false, title: "> 5", color: "green-4" },
    ],
  },
  {
    id: 4,
    title: "Tägliche Aktivität",
    descr: "Wie viel Schritte machst du am Tag?",
    descr2:
      "Zwei flinke Boxer jagen die quirlige Eva und ihren Mops durch Sylt. Franz jagt im komplett verwahrlosten Taxi quer durch Bayern.",
    value: "",
    isNumber: true,
    isEmoji: false,
    isShape: false,
    numbers: [
      { state: 1, value: 1, isActive: false, title: "< 5.000", color: "red-4" },
      {
        state: 2,
        value: 2,
        isActive: false,
        title: "5.000 - 8.000",
        color: "orange",
      },
      {
        state: 3,
        value: 3,
        isActive: false,
        title: "8.001 - 10.000",
        color: "light-orange",
      },
      {
        state: 4,
        value: 4,
        isActive: false,
        title: "10.001 - 12.000",
        color: "light-green",
      },
      {
        state: 5,
        value: 5,
        isActive: false,
        title: "> 12.000",
        color: "green-4",
      },
    ],
  },
  {
    id: 5,
    title: "Rauchen",
    descr: "Wie viele Zigaretten rauchst du am Tag?",
    descr2:
      "Zwei flinke Boxer jagen die quirlige Eva und ihren Mops durch Sylt. Franz jagt im komplett verwahrlosten Taxi quer durch Bayern.",
    value: "",
    isNumber: true,
    isEmoji: false,
    isShape: false,
    numbers: [
      { state: 1, value: 1, isActive: false, title: "0", color: "green-4" },
      { state: 2, value: 2, isActive: false, title: "1-3", color: "red-1" },
      { state: 3, value: 3, isActive: false, title: "4-10", color: "red-2" },
      { state: 4, value: 4, isActive: false, title: "11-20", color: "red-3" },
      { state: 5, value: 5, isActive: false, title: "> 20", color: "red-4" },
    ],
  },
  {
    id: 6,
    title: "Alkohol",
    descr: "Wie häufig trinkst du Alkohol in der Woche?",
    descr2:
      "Zwei flinke Boxer jagen die quirlige Eva und ihren Mops durch Sylt. Franz jagt im komplett verwahrlosten Taxi quer durch Bayern.",
    value: "",
    isNumber: true,
    isEmoji: false,
    isShape: false,
    numbers: [
      { state: 1, value: 1, isActive: false, title: "0", color: "green-4" },
      { state: 2, value: 2, isActive: false, title: "1", color: "red-1" },
      { state: 3, value: 3, isActive: false, title: "2", color: "red-2" },
      { state: 4, value: 4, isActive: false, title: "3", color: "red-3" },
      { state: 5, value: 5, isActive: false, title: "> 3", color: "red-4" },
    ],
  },
  {
    id: 7,
    title: "Obst & Gemüse",
    descr: "Wie häufig isst du Obst und Gemüse am Tag?",
    descr2:
      "Zwei flinke Boxer jagen die quirlige Eva und ihren Mops durch Sylt. Franz jagt im komplett verwahrlosten Taxi quer durch Bayern.",
    value: "",
    isNumber: true,
    isEmoji: false,
    isShape: false,
    numbers: [
      { state: 1, value: 1, isActive: false, title: "0", color: "red-4" },
      { state: 2, value: 2, isActive: false, title: "1", color: "orange" },
      {
        state: 3,
        value: 3,
        isActive: false,
        title: "2",
        color: "light-orange",
      },
      {
        state: 4,
        value: 4,
        isActive: false,
        title: "3-5",
        color: "light-green",
      },
      { state: 5, value: 5, isActive: false, title: "> 5", color: "green-4" },
    ],
  },
  {
    id: 8,
    title: "Mahlzeitenfrequenz",
    descr: "Wie viel Mahlzeiten nimmst du pro Tag zu dir?",
    descr2:
      "Zwei flinke Boxer jagen die quirlige Eva und ihren Mops durch Sylt. Franz jagt im komplett verwahrlosten Taxi quer durch Bayern.",
    value: "",
    isNumber: true,
    isEmoji: false,
    isShape: false,
    numbers: [
      { state: 1, value: 1, isActive: false, title: "1", color: "red-4" },
      { state: 2, value: 2, isActive: false, title: "2", color: "red-2" },
      { state: 3, value: 3, isActive: false, title: "3", color: "green-4" },
      { state: 4, value: 4, isActive: false, title: "4", color: "green-4" },
      { state: 5, value: 5, isActive: false, title: "> 4", color: "green-4" },
    ],
  },
  {
    id: 9,
    title: "Trinken",
    descr: "Wie viel Liter trinkst du am Tag?",
    descr2:
      "Zwei flinke Boxer jagen die quirlige Eva und ihren Mops durch Sylt. Franz jagt im komplett verwahrlosten Taxi quer durch Bayern.",
    value: "",
    isNumber: true,
    isEmoji: false,
    isShape: false,
    numbers: [
      { state: 1, value: 1, isActive: false, title: "< 1", color: "red-4" },
      { state: 2, value: 2, isActive: false, title: "1", color: "orange" },
      {
        state: 3,
        value: 3,
        isActive: false,
        title: "1-2",
        color: "light-orange",
      },
      {
        state: 4,
        value: 4,
        isActive: false,
        title: "2-3",
        color: "light-green",
      },
      { state: 5, value: 5, isActive: false, title: "> 3", color: "green-4" },
    ],
  },
  {
    id: 10,
    title: "Heißhunger",
    descr: "Wie häufig hast du Heißhungerattacken am Tag?",
    descr2:
      "Zwei flinke Boxer jagen die quirlige Eva und ihren Mops durch Sylt. Franz jagt im komplett verwahrlosten Taxi quer durch Bayern.",
    value: "",
    isNumber: true,
    isEmoji: false,
    isShape: false,
    numbers: [
      { state: 1, value: 1, isActive: false, title: "0", color: "green-4" },
      { state: 2, value: 2, isActive: false, title: "1", color: "red-1" },
      { state: 3, value: 3, isActive: false, title: "2", color: "red-2" },
      { state: 4, value: 4, isActive: false, title: "3", color: "red-3" },
      { state: 5, value: 5, isActive: false, title: "> 3", color: "red-4" },
    ],
  },
  {
    id: 11,
    title: "Stimmungsschwankungen",
    descr: "Wie häufig kommt das in der Woche vor?",
    descr2:
      "Zwei flinke Boxer jagen die quirlige Eva und ihren Mops durch Sylt. Franz jagt im komplett verwahrlosten Taxi quer durch Bayern.",
    value: "",
    isNumber: true,
    isEmoji: false,
    isShape: false,
    numbers: [
      { state: 1, value: 1, isActive: false, title: "0", color: "green-4" },
      { state: 2, value: 2, isActive: false, title: "1", color: "light-green" },
      {
        state: 3,
        value: 3,
        isActive: false,
        title: "2",
        color: "light-orange",
      },
      { state: 4, value: 4, isActive: false, title: "3", color: "orange" },
      { state: 5, value: 5, isActive: false, title: "> 3", color: "red-4" },
    ],
  },
  {
    id: 12,
    title: "Schwächegefühl",
    descr: "Wie oft fühlst du dich schlapp in der Woche?",
    descr2:
      "Zwei flinke Boxer jagen die quirlige Eva und ihren Mops durch Sylt. Franz jagt im komplett verwahrlosten Taxi quer durch Bayern.",
    value: "",
    isNumber: true,
    isEmoji: false,
    isShape: false,
    numbers: [
      { state: 1, value: 1, isActive: false, title: "0", color: "green-4" },
      { state: 2, value: 2, isActive: false, title: "1", color: "light-green" },
      {
        state: 3,
        value: 3,
        isActive: false,
        title: "2",
        color: "light-orange",
      },
      { state: 4, value: 4, isActive: false, title: "3", color: "orange" },
      { state: 5, value: 5, isActive: false, title: "> 3", color: "red-4" },
    ],
  },
];
