import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  setUserRegistrationDataInLocalStorage,
  getUserRegistrationDataFromLocalStorage,
  areFewObjectsValuesNull,
} from "configs/utils";

import Button from "components/formComponents/button/Button";
import EvaluationCard from "./AnamneseEvaluationForm/EvaluationCard/EvaluationCard";

import FoodiaryLogo from "assets/images/Foodiary-Logo.PNG";
import ArrowLeft from "assets/images/arrowLeft.svg";
import AnamneseEvaluationForm from "./AnamneseEvaluationForm/AnamneseEvaluationForm";
import { SidebarSteps } from "../data";
// Redux
import OnboardingSidebar from "../OnboardingSidebar";
import { EVALUATION_ITEMS } from "./AnamneseEvaluationForm/EvaluationData";
import * as S from "../OnBoardingPartOne.styles";
import * as SF from "./AnamneseEvaluationForm/AnamneseEvaluationForm.styles";
import * as SC from "./AnamneseEvaluationForm/EvaluationCard/EvaluationCard.styles";

const AnamneseEvaluation = (props) => {
  const history = useHistory();

  const [evaluationItems, setEvaluationItem] = useState(EVALUATION_ITEMS);

  const [StressLevel, setStressLevel] = useState(null);
  const [BodyShape, setBodyShape] = useState(null);
  const [SportActivity, setSportActivity] = useState(null);
  const [DailyActivity, setDailyActivity] = useState(null);
  const [Smoking, setSmoking] = useState(null);
  const [Alcohol, setAlcohol] = useState(null);
  const [FruitVegetable, setFruitVegetable] = useState(null);
  const [MealFrequency, setMealFrequency] = useState(null);
  const [Drink, setDrink] = useState(null);
  const [Cravings, setCravings] = useState(null);
  const [MoodSwings, setMoodSwings] = useState(null);
  const [FeelingWeak, setFeelingWeak] = useState(null);

  const [IsValidation, SetIsValidation] = useState(false);

  useEffect(() => {
    const anamnese = JSON.parse(localStorage.getItem("Anamnese"));

    if (anamnese) {
      setStressLevel(anamnese?.StressLevel);
      setBodyShape(anamnese?.BodyShape);
      setSportActivity(anamnese?.SportActivity);
      setDailyActivity(anamnese?.DailyActivity);
      setSmoking(anamnese?.Smoking);
      setAlcohol(anamnese?.Alcohol);
      setFruitVegetable(anamnese?.FruitVegetable);
      setMealFrequency(anamnese?.MealFrequency);
      setDrink(anamnese?.Drink);
      setCravings(anamnese?.Cravings);
      setMoodSwings(anamnese?.MoodSwings);
      setFeelingWeak(anamnese?.FeelingWeak);
    }

    setUserRegistrationDataInLocalStorage({
      ...getUserRegistrationDataFromLocalStorage(),
      anamnese: JSON.parse(localStorage.getItem("Anamnese")),
    });
  }, []);

  const handleOverAllEvaluation = (obj) => {
    console.log("All Anamnese", obj);
    SetIsValidation(false);
    localStorage.setItem("Anamnese", JSON.stringify(obj));
    setUserRegistrationDataInLocalStorage({
      ...getUserRegistrationDataFromLocalStorage(),
      anamnese: obj,
    });
  };

  const handleNext = () => {
    const anamnese = JSON.parse(localStorage.getItem("Anamnese"));
    console.log("anamnese", anamnese);

    if (
      !anamnese ||
      areFewObjectsValuesNull(anamnese) ||
      Object.keys(anamnese).length < 12
    ) {
      SetIsValidation(true);
      return;
    } else {
      const anamnesePayload = {
        stress_level: anamnese?.StressLevel.images.find((item) => item.isActive)
          ?.value,
        body_fat: anamnese?.BodyShape.images.find((item) => item.isActive)
          ?.value,
        sport_activity: anamnese?.SportActivity.numbers.find(
          (item) => item.isActive
        )?.value,
        daily_activity: anamnese?.DailyActivity.numbers.find(
          (item) => item.isActive
        )?.value,
        smoking: anamnese?.Smoking.numbers.find((item) => item.isActive)?.value,
        alcohol: anamnese?.Alcohol.numbers.find((item) => item.isActive)?.value,
        fruits_vegitables: anamnese?.FruitVegetable.numbers.find(
          (item) => item.isActive
        )?.value,
        meal_frequency: anamnese?.MealFrequency.numbers.find(
          (item) => item.isActive
        )?.value,
        drinking: anamnese?.Drink.numbers.find((item) => item.isActive)?.value,
        cravings: anamnese?.Cravings.numbers.find((item) => item.isActive)
          ?.value,
        mood_swings: anamnese?.MoodSwings.numbers.find((item) => item.isActive)
          ?.value,
        weekness_feeling: anamnese?.FeelingWeak.numbers.find(
          (item) => item.isActive
        )?.value,
      };

      localStorage.setItem(
        "AnamneseWithValues",
        JSON.stringify(anamnesePayload)
      );

      history.push("/onboarding/registration/body-details");
    }
  };

  const handleBack = () => {
    history.push("/onboarding/registration/goal");
  };

  return (
    <>
      <S.OnBoardingPage className="starter">
        <S.OnBoardingContainer className="onboarding-container">
          <OnboardingSidebar step={2} />
          <S.OnBoardingContent className="onboarding-content">
            <S.OnBoardingHeader className="onboarding-header">
              <div className="d-flex align-items-top gap-3">
                <img
                  className="cursor-pointer"
                  src={ArrowLeft}
                  alt="back"
                  onClick={handleBack}
                />
                <div className="content">
                  <span className="small-title text-green font-rubik-medium">
                    Anamnese
                  </span>
                  <h6 className="header-title font-rubik-medium my-0">
                    Wir erstellen deinen Plan.
                  </h6>
                </div>
              </div>
              <div className="actions">
                <Button
                  color="rgba(248, 164, 146, 1)"
                  gradient="linear-gradient(60deg, rgba(46, 207, 150, 1), rgba(28, 157, 135, 1)) !important"
                  margin="0"
                  absolute={{ width: "fit-content", height: "2.5rem" }}
                  buttonStyle={{ padding: ".5625rem 2.5rem" }}
                  onClick={handleNext}
                >
                  Weiter
                </Button>
              </div>
            </S.OnBoardingHeader>
            <S.OnBoardingBody className="onboarding-body">
              <div className="anamnese-evaluation-content ">
                <h3 className="text-size-20-28 text-dark-1 font-rubik-medium mt-0">
                  Lernen wir deinen Alltag kennen.
                </h3>
                <p className="text-size-16-19 text-light-grey-1 font-rubik-regular">
                  Die folgenden Fragen helfen uns ein besseres Verständnis zu{" "}
                  <br /> bekommen.
                </p>
                {IsValidation && (
                  <p className="text-size-14-18 text-right text-red font-rubik-regular my-0">
                    Alle Felder müssen ausgewählt werden.
                  </p>
                )}
                <AnamneseEvaluationForm
                  onSelect={(obj) => handleOverAllEvaluation(obj)}
                />
              </div>
            </S.OnBoardingBody>
          </S.OnBoardingContent>
        </S.OnBoardingContainer>
        <br />
        <br />
      </S.OnBoardingPage>
    </>
  );
};

export default AnamneseEvaluation;
