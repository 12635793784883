import { useSelector } from "react-redux";
import moment from "moment";
import "moment/locale/de";
import {
  FILE_BASE_URL,
  FILE_BASE_URL_S3,
} from "configs/services/apiCollections";

export const setUserRegistrationDataInLocalStorage = (data) => {
  localStorage.setItem("UserRegistration", JSON.stringify(data));
};

export const getUserRegistrationDataFromLocalStorage = () => {
  return JSON.parse(localStorage.getItem("UserRegistration"));
};

export const setUserHealthScoreDataInLocalStorage = (data) => {
  localStorage.setItem("HealthScoreData", JSON.stringify(data));
};

export const getUserHealthScoreDataFromLocalStorage = () => {
  return JSON.parse(localStorage.getItem("HealthScoreData"));
};

export const GetTrainerToken = () => {
  const Authorization = useSelector((state) => state.auth.trainer_token);
  if (Authorization.length > 0) return Authorization;
  else return false;
};

export const GetUserToken = () => {
  const Authorization = useSelector((state) => state.auth.token);
  if (Authorization.length > 0) return Authorization;
  else return false;
};

export const getImageUrl = (imagePath, image, imageType = null) => {
  if (!imageType) return FILE_BASE_URL() + imagePath + "/" + image;
  return FILE_BASE_URL() + imagePath + "/" + image + "." + imageType;
};

export const getS3ImageUrl = (image, type = null) => {
  if (!image || image === "null") return null; // some images come from api as "null" which is truthy
  if (!type) return FILE_BASE_URL_S3() + image;
  return FILE_BASE_URL_S3() + image + "." + type;
};

export const getNutritionImageUrl = (path, image, type = null) => {
  const BASE_URL = "https://app.foodiary.app/";
  if (!type) return BASE_URL + path + image;
  return BASE_URL + path + "/" + image + "." + type;
};

export const findAge = (birthday) => {
  const a = moment();
  const b = moment(birthday);
  if (!birthday) return 0;
  return moment.duration(a.diff(b)).years();
};

export const getPercent = (position) => {
  if (position === 0) return 60;
  else if (position === 1) return 75;
  else if (position === 2) return 100;
  else return 60;
};

export const getTargetValue = (position) => {
  if (position === 0) return "1,50 €";
  else if (position === 1) return "4,50 €";
  else if (position === 2) return "8,00 €";
  else return "0,00 €";
};

export const getRandomValue = (endValue) =>
  Math.floor(Math.random() * endValue + 1);

export const getRandomValueInRange = (startValue, endValue) =>
  Math.floor(Math.random() * (startValue - endValue + 1) + endValue);

export const isFirefox = typeof InstallTrigger !== "undefined";

export const formatToGermanCurrency = (number) =>
  new Intl.NumberFormat("de-DE", { style: "currency", currency: "EUR" }).format(
    number
  );

export const sliceIntoChunks = (arr, chunkSize) => {
  const res = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    const chunk = arr.slice(i, i + chunkSize);
    res.push(chunk);
  }
  return res;
};

export const calculateLastDaysChartData = (days) => {
  if (!days) return;

  const listOfChunks = sliceIntoChunks(days, 7);

  const chartData = listOfChunks.map((list) => ({
    date: `${list.at(0).date.split("-")[2]}.${
      list.at(0).date.split("-")[1]
    } - ${list.at(-1).date.split("-")[2]}.${list.at(-1).date.split("-")[1]}`,
    value: list
      .map((item) => item?.numberOfActiveClients)
      .reduce((a, b) => a + b, 0),
  }));

  return {
    dates: chartData?.map((item) => item?.date || ""),
    values: chartData?.map((item) => item?.value || 0),
  };
};

export const calculateChartPercentage = (
  ChartDataPreviousMonth,
  ChartDataCurrentMonth
) => {
  const previousMonthCount = ChartDataPreviousMonth?.reduce((a, b) => a + b, 0);
  const currentMonthCount = ChartDataCurrentMonth?.reduce((a, b) => a + b, 0);

  const p = (
    100 *
    Math.abs(
      (previousMonthCount - currentMonthCount) /
        ((previousMonthCount + currentMonthCount) / 2)
    )
  ).toFixed();

  if (currentMonthCount - previousMonthCount >= 0) {
    return `+${p}`;
  } else {
    return `-${p}`;
  }
};

export const dateNumbersFormat = (date) => {
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = String(date.getFullYear()).slice(-2);
  return `${day}.${month}.${year}`;
};

export const formatDate = (date) => {
  const formatedDate = date?.split("-")?.reverse()?.join(".");
  const dayIndex = new Date(date).getDay();
  const days = [
    "Sonntag",
    "Montag",
    "Dienstag",
    "Mittwoch",
    "Donnerstag",
    "Freitag",
    "Samstag",
  ];
  return { day: days[dayIndex], date: formatedDate };
};

export const formatNewsDate = (dateString) => {
  const date = moment(dateString, "YYYY-MM-DD").locale("de");
  return date.format("DD. MMMM YYYY");
};

export const removeDuplicates = (array) => {
  const uniqueArray = array.filter(
    (item, index, self) =>
      index === self.findIndex((i) => i.email === item.email)
  );
  return uniqueArray;
};

export const renderValueInGerman = (value) =>
  value.toLocaleString("de-DE", {
    style: "currency",
    currency: "EUR",
  });

export const areAllObjectsValuesNull = (obj) => {
  return Object.values(obj).every((value) => value === null);
};

export const areFewObjectsValuesNull = (obj) => {
  return Object.values(obj)?.some((value) => value === null);
};

export const removeSpaceAndToLowerCase = (value) => {
  return value?.split(" ")?.join("")?.toLowerCase();
};

export const removeDuplicatesUsingId = (array) => {
  const uniqueIds = new Set();
  return array.filter((item) => {
    if (uniqueIds.has(item.id)) {
      return false;
    } else {
      uniqueIds.add(item.id);
      return true;
    }
  });
};

export function compareValuesWithMissingBodyMeasurements(data) {
  const defaultNames = ["Brust", "Hals", "Oberarm", "Oberschenkel", "Taille"];

  // Handle empty or null data
  if (!data || Object.keys(data).length === 0) {
    return defaultNames.map((name) => ({
      name,
      value: 0,
      date: null,
      valueDifference: 0,
      valueStatus: "none",
    }));
  }

  const keys = Object.keys(data);
  const lastKey = keys[keys.length - 1];
  const results = [];

  // Handle case with only one array
  if (keys.length === 1) {
    const lastArray = data[lastKey];
    return defaultNames.map((name) => {
      const lastItem = lastArray.find((el) => el.name === name) || {
        name,
        value: 0,
        date: null,
      };
      return {
        name: lastItem.name,
        value: lastItem.value,
        date: lastItem.date,
        valueDifference: 0,
        valueStatus: lastItem.value === 0 ? "none" : "none",
      };
    });
  }

  // Compare values across arrays
  for (const name of defaultNames) {
    let lastItem = data[lastKey].find((el) => el.name === name);
    let comparisonValue = null;
    let comparisonDate = null;

    // If not found in the last array, search previous arrays
    if (!lastItem) {
      for (let i = keys.length - 2; i >= 0; i--) {
        const previousArray = data[keys[i]];
        const match = previousArray.find((el) => el.name === name);
        if (match) {
          comparisonValue = match.value;
          comparisonDate = match.date; // Use the date from the most recent match
          break;
        }
      }
    } else {
      comparisonValue = lastItem.value;
      comparisonDate = lastItem.date;
    }

    // If no comparison value was found, set it to 0
    const lastValue = lastItem ? lastItem.value : 0;
    const valueToCompare =
      comparisonValue !== null ? comparisonValue : lastValue;

    // Calculate valueDifference
    const valueDifference =
      lastValue === 0 && comparisonValue === null
        ? 0
        : lastValue - (comparisonValue || 0);

    // Determine valueStatus
    let valueStatus = "none"; // Default to none
    if (comparisonValue !== null) {
      if (lastValue > comparisonValue) {
        valueStatus = "decreasing";
      } else if (lastValue < comparisonValue) {
        valueStatus = "increasing";
      }
    }

    results.push({
      name,
      value: valueToCompare,
      date: comparisonDate || (lastItem ? lastItem.date : null),
      valueDifference: valueDifference,
      valueStatus,
    });
  }

  return results;
}

// Define all possible items with an empty date
const allItems = [
  { name: "Brust", value: 0, date: "" },
  { name: "Hals", value: 0, date: "" },
  { name: "Oberarm", value: 0, date: "" },
  { name: "Oberschenkel", value: 0, date: "" },
  { name: "Taille", value: 0, date: "" },
];

// Find missing items and add them with default properties
function addMissingItems(array) {
  const arrayNames = array.map((item) => item.name);

  return allItems.map((item) => {
    if (arrayNames.includes(item.name)) {
      return array.find((a) => a.name === item.name);
    }
    return { ...item }; // Missing item with default values
  });
}

// Compare two arrays and calculate valueDifference and valueStatus
function calculateDifferences(array1, array2) {
  return array1.map((item1) => {
    const item2 = array2.find((i) => i.name === item1.name) || { value: 0 };
    const valueDifference = item1.value - item2.value;
    let valueStatus = "none";

    if (valueDifference > 0) valueStatus = "increasing";
    else if (valueDifference < 0) valueStatus = "decreasing";

    return {
      ...item1,
      valueDifference,
      valueStatus,
    };
  });
}

// Main function
export function processBodymeasurements(obj) {
  const keys = Object.keys(obj);

  // Handle empty or null object
  if (!obj || keys.length === 0) {
    return allItems.map((item) => ({
      ...item,
      valueDifference: 0,
      valueStatus: "none",
    }));
  }

  const lastArray = addMissingItems(obj[keys[keys.length - 1]]);

  // If there is only one array
  if (keys.length === 1) {
    return lastArray.map((item) => ({
      ...item,
      valueDifference: 0,
      valueStatus: "none",
    }));
  }

  // If there are two arrays
  const secondLastArray = addMissingItems(obj[keys[keys.length - 2]]);
  return calculateDifferences(lastArray, secondLastArray);
}
//---------------
export const getTransformedCaloriesDataForChart = (data) => {
  const erholungstagObj = { daytypeID: "Erholungstag" };
  const trainingstagObj = { daytypeID: "Trainingstag" };

  // Loop through the weeks and extract the calories for each daytype
  Object.keys(data).forEach((week, index) => {
    const weekData = data[week];

    // Extract calories for each daytype
    const erholungstagCalories =
      weekData.find((day) => day.daytypeID === "Erholungstag")?.calories || 0;
    const trainingstagCalories =
      weekData.find((day) => day.daytypeID === "Trainingstag")?.calories || 0;

    // Assign calories to the respective objects
    erholungstagObj[index] = erholungstagCalories;
    trainingstagObj[index] = trainingstagCalories;
  });

  return { erholungstagObj, trainingstagObj };
};

export const removeOnboardingKeys = () => {
  localStorage.removeItem("UserToStartOnBoardingFromMiddle");
  localStorage.removeItem("RegisteredUserDetails");
  localStorage.removeItem("UserRegistration");
  localStorage.removeItem("RegistrationData");
  localStorage.removeItem("SelectedDietItems");
  localStorage.removeItem("HealthScoreData");
  localStorage.removeItem("AnamneseUserId");
  localStorage.removeItem("AnamneseWithValues");
  localStorage.removeItem("Anamnese");
  localStorage.removeItem("NutrtionIds");
  localStorage.removeItem("discount");
};

export const removeOnboardingKeysForMiddleFlow = () => {
  localStorage.removeItem("UserToStartOnBoardingFromMiddle");
  localStorage.removeItem("HealthScoreData");
  localStorage.removeItem("AnamneseUserId");
  localStorage.removeItem("NutrtionIds");
  localStorage.removeItem("discount");
};

export const dashboardNavTabs = [
  {
    id: "dashboard",
    value: "Dashboard",
    route: "/main/dashboard/home",
  },
  {
    id: "mainCustomer",
    value: "Meine Kunden",
    route: "/main/dashboard/customers",
  },
  //   {
  //     id: "calendar",
  //     value: "Kalender",
  //     redirect: "https://www.calendly.com",
  //   },
  {
    id: "weeklyCalls",
    value: "Weeklys",
    route: "/main/dashboard/weeklyCalls",
  },
  {
    id: "academy",
    value: "Academy",
    route: "/academy",
  },
];

export const initializeHotjar = (hjid, hjsv) => {
  if (typeof window !== "undefined" && window.hj === undefined) {
    (function (h, o, t, j, a, r) {
      h.hj =
        h.hj ||
        function () {
          (h.hj.q = h.hj.q || []).push(arguments);
        };
      h._hjSettings = { hjid: hjid, hjsv: hjsv };
      a = o.getElementsByTagName("head")[0];
      r = o.createElement("script");
      r.async = 1;
      r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
      a.appendChild(r);
    })(window, document, "https://static.hotjar.com/c/hotjar-", ".js?sv=");
  }
};

export const disableHotjar = () => {
  if (window.hj) {
    window.hj = undefined; // This will reset the Hotjar tracking
    delete window.hj;
  }
};

export function startRecording() {
  console.log("startRecording----Just-----Called");
  if (typeof window?.hj !== "undefined") {
    window.hj("trigger", "startRecording");

    console.log(
      "%cRecording...",
      "color: white; background: purple; font-size: 16px; font-weight: bold"
    );
  }
}

export function stopRecording() {
  console.log("stopRecording----Just-----Called");
  if (typeof window?.hj !== "undefined") {
    window.hj("trigger", "stopRecording");
    console.log(
      "%cStop Recording...",
      "color: white; background: black; font-size: 16px; font-weight: bold"
    );
  }
}
