import { takeLatest, put, all, call } from "redux-saga/effects";
import Tap from "@tapfiliate/tapfiliate-js";
import Swal from "sweetalert2";

import ApiCollections from "configs/services/apiCollections";

import SupplementsActionTypes from "redux/supplements/supplements.types";
import * as AuthActions from "redux/auth/auth.actions";
import * as SupplementsActions from "redux/supplements/supplements.actions";

/*************************************************/

export function* getSupplements() {
  yield put(AuthActions.loadingStart());
  try {
    const response = yield call(ApiCollections.getSupplements);
    yield put(SupplementsActions.getSupplementsSuccess(response.data));
    yield put(AuthActions.loadingStop());
  } catch (error) {
    yield put(SupplementsActions.getSupplementsFailure(error));
    yield put(AuthActions.loadingStop());
  }
}

/*************************************************/

export function* deleteMailChimpUserDetails({ payload }) {
  yield put(AuthActions.loadingStart());
  try {
    const response = yield call(
      ApiCollections.deleteMailChimpUserDetails,
      payload.formValues
    );
    yield put(SupplementsActions.deleteMailChimpUserSuccess(response.data));
    yield put(AuthActions.loadingStop());
    // yield put(AuthActions.signOutRequest());
    payload?.func(response);
  } catch (error) {
    yield put(SupplementsActions.deleteMailChimpUserFailure(error));
    yield put(AuthActions.loadingStop());
    // yield put(AuthActions.signOutRequest());
    payload?.func(error);
  }
}

/*************************************************/

export function* addBasicUserMainChimpData({ payload }) {
  yield put(AuthActions.loadingStart());
  try {
    const response = yield call(
      ApiCollections.addBasicUserDataMailChimp,
      payload
    );
    yield put(
      SupplementsActions.addBasicMailChimpUserDataSuccess(response.data)
    );
    yield put(AuthActions.loadingStop());
    payload?.func();
  } catch (error) {
    yield put(SupplementsActions.addBasicMailChimpUserDataFailure(error));
    yield put(AuthActions.loadingStop());
    payload?.func();
  }
}

/*************************************************/

export function* addProUserMainChimpData({ payload }) {
  yield put(AuthActions.loadingStart());
  try {
    const response = yield call(
      ApiCollections.addProUserDataMailChimp,
      payload
    );
    yield put(SupplementsActions.addProMailChimpUserDataSuccess(response.data));
    yield put(AuthActions.loadingStop());
    payload?.func();
  } catch (error) {
    yield put(SupplementsActions.addProMailChimpUserDataFailure(error));
    yield put(AuthActions.loadingStop());
    payload?.func();
  }
}

/*************************************************/

export function* updateUserSubscription({ payload }) {
  yield put(AuthActions.loadingStart());
  try {
    const response = yield call(
      ApiCollections.updateUserSubscription,
      payload.formValues
    );
    yield put(SupplementsActions.updateUserSubscriptionSuccess(response.data));
    if (payload.affiliateData) {
      Tap.init(process.env.REACT_APP_TAPFILIATE_ID);
      Tap.conversion(
        payload.affiliateData.userID,
        payload.affiliateData.conversionAmount,
        { customer_id: payload.affiliateData.email }
      );
    }
    yield put(AuthActions.loadingStop());
    payload?.func();
  } catch (error) {
    yield put(SupplementsActions.updateUserSubscriptionFailure(error));
    yield put(AuthActions.loadingStop());
  }
}

/*************************************************/

export function* initiateStripePayment({ payload }) {
  const { history } = payload;
  console.log("payload", payload)
  console.log("history", history)
  yield put(AuthActions.loadingStart());
  try {
    const response = yield call(
      ApiCollections.updateUserSubscription,
      payload?.formValues
    );
    yield put(SupplementsActions.initiateStripePaymentSuccess(response.data));
    yield put(AuthActions.loadingStop());
    payload?.func(response);

    if (response instanceof Error) {
      Swal.fire({
        icon: "error",
        title: "Hinweis",
        text: "Die Rechnungsstellung konnte nicht ausgeführt werden.",
        confirmButtonText: "Zum Dashboard",
        showCancelButton: false,
        cancelButtonText: "Cancel",
        showConfirmButton: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
      }).then((result) => {
        if (result.isConfirmed) {
          history.push("/dashboard/main");
        }
      });
    } else {
      Swal.fire({
        icon: "success",
        title: "Erledigt",
        text:
          `Die Rechnung wurde an ${payload?.affiliateData?.email} gesendet.` ||
          response?.message,
        timer: 5000,
      });

      // if (payload?.affiliateData) {
      //   Tap.init(process.env.REACT_APP_TAPFILIATE_ID);
      //   Tap.conversion(
      //     payload?.affiliateData?.userID,
      //     payload?.affiliateData?.conversionAmount,
      //     { customer_id: payload?.affiliateData?.email },
      //     payload?.affiliateData?.commissionType
      //   );
      // }
    }
  } catch (error) {
    yield put(AuthActions.loadingStop());
    yield put(SupplementsActions.initiateStripePaymentFailure(error));
    Swal.fire({
      icon: "error",
      title: "Hinweis",
      text: "Die Rechnungsstellung konnte nicht ausgeführt werden.",
      confirmButtonText: "Zum Dashboard",
      showCancelButton: false,
      cancelButtonText: "Cancel",
      showConfirmButton: true,
      allowOutsideClick: false,
      allowEscapeKey: false,
    }).then((result) => {
      if (result.isConfirmed) {
        history.push("/dashboard/main");
      }
    });
  }
}

/*************************************************/

export function* getTrainerLeads({ payload }) {
  yield put(SupplementsActions.getTrainerLeadsLoadingStart());
  try {
    const response = yield call(
      ApiCollections.getTrainerLeads,
      payload?.payload
    );
    yield put(SupplementsActions.getTrainerLeadsSuccess(response));
    yield put(SupplementsActions.getTrainerLeadsLoadingStop());
    payload?.callback(response);
  } catch (error) {
    yield put(SupplementsActions.getTrainerLeadsLoadingStop());
  }
}

/*************************************************/

export function* getNumberofLeads({ payload }) {
  yield put(SupplementsActions.getNumberofLeadsLoadingStart());
  try {
    const response = yield call(
      ApiCollections.getNumberOfLeads,
      payload?.payload
    );
    yield put(SupplementsActions.getNumberofLeadsSuccess(response));
    yield put(SupplementsActions.getNumberofLeadsLoadingStop());
    yield put(payload?.callback(response));
  } catch (error) {
    yield put(SupplementsActions.getNumberofLeadsLoadingStop());
  }
}
/*************************************************/

export function* triggerTrainerEmail({ payload }) {
  try {
    const response = yield call(
      ApiCollections.triggerTrainerEmail,
      payload?.payload
    );
    if (response instanceof Error) throw new Error("Error sending email");

    payload?.cb && payload?.cb();
  } catch (err) {
    payload?.cb && payload?.cb(err);
  }
}

/*************************************************/

export function* getTrainerLeadDetails({ payload }) {
  yield put(SupplementsActions.getTrainerLeadDetailsLoadingStart());
  try {
    const response = yield call(
      ApiCollections.getTrainerLeadDetails,
      payload?.payload
    );
    yield put(SupplementsActions.getTrainerLeadDetailsSuccess(response));
    yield put(SupplementsActions.getTrainerLeadDetailsLoadingStop());
    payload?.callback(response);
  } catch (error) {
    yield put(SupplementsActions.getTrainerLeadDetailsLoadingStop());
  }
}


/*************************************************/

export function* updateTrainerLead({ payload }) {
  yield put(SupplementsActions.updateTrainerLeadLoadingStart());
  try {
    const response = yield call(
      ApiCollections.updateTrainerLead,
      payload?.payload
    );
    yield put(SupplementsActions.updateTrainerLeadSuccess(response));
    yield put(SupplementsActions.updateTrainerLeadLoadingStop());
    payload?.callback(response);
  } catch (error) {
    yield put(SupplementsActions.updateTrainerLeadLoadingStop());
  }
}

/*************************************************/

export function* getFoodiaryLeads({ payload }) {
  yield put(SupplementsActions.getFoodiaryLeadsLoadingStart());
  try {
    const response = yield call(ApiCollections.getFoodiaryLeads);
    yield put(SupplementsActions.getFoodiaryLeadsSuccess(response));
    yield put(SupplementsActions.getFoodiaryLeadsLoadingStop());
    payload?.callback(response);
  } catch (error) {
    yield put(SupplementsActions.getFoodiaryLeadsLoadingStop());
  }
}

/*************************************************/

export function* getFoodiaryLeadDetails({ payload }) {
  yield put(SupplementsActions.getFoodiaryLeadsLoadingStart());
  try {
    const response = yield call(
      ApiCollections.getFoodiaryLeadDetails,
      payload?.payload
    );
    yield put(SupplementsActions.getFoodiaryLeadsSuccess(response));
    yield put(SupplementsActions.getFoodiaryLeadsLoadingStop());
    payload?.callback(response);
  } catch (error) {
    yield put(SupplementsActions.getFoodiaryLeadsLoadingStop());
  }
}

/*************************************************/

export function* claimFoodiaryLead({ payload }) {
  yield put(SupplementsActions.claimFoodiaryLeadLoadingStart());
  try {
    let response = yield call(
      ApiCollections.claimFoodiaryLead,
      payload?.payload
    );
    yield put(SupplementsActions.claimFoodiaryLeadSuccess(response));
    yield put(SupplementsActions.claimFoodiaryLeadLoadingStop());
    payload?.callback(response);
  } catch (error) {
    yield put(SupplementsActions.claimFoodiaryLeadLoadingStop());
  }
}

/*************************************************/

export function* leadUtlization({ payload }) {
  yield put(SupplementsActions.leadUtilizationLoadingStart());
  try {
    const response = yield call(
      ApiCollections.leadUtlization,
      payload?.payload
    );
    yield put(SupplementsActions.leadUtilizationSuccess(response));
    yield put(SupplementsActions.leadUtilizationLoadingStop());
    payload?.callback(response);
  } catch (error) {
    yield put(SupplementsActions.leadUtilizationLoadingStop());
  }
}

/*************************************************/

export function* getLeadCenterStatus({ payload }) {
  yield put(SupplementsActions.getLeadCenterStatusLoadingStart());
  try {
    const response = yield call(
      ApiCollections.getLeadCenterStatus,
      payload?.payload
    );
    yield put(SupplementsActions.getLeadCenterStatusSuccess(response));
    yield put(SupplementsActions.getLeadCenterStatusLoadingStop());
    payload?.callback(response);
  } catch (error) {
    yield put(SupplementsActions.getLeadCenterStatusLoadingStop());
  }
}

/*************************************************/

export function* getLeadAuthorization({ payload }) {
  yield put(SupplementsActions.getLeadAuthorizationLoadingStart());
  try {
    const response = yield call(
      ApiCollections.getLeadAuthorization,
      payload?.payload
    );
    yield put(SupplementsActions.getLeadAuthorizationSuccess(response));
    yield put(SupplementsActions.getLeadAuthorizationLoadingStop());
    payload?.callback(response);
  } catch (error) {
    yield put(SupplementsActions.getLeadAuthorizationLoadingStop());
  }
}

/*************************************************/

export function* getLeadHistory({ payload }) {
  yield put(SupplementsActions.getLeadHistoryLoadingStart());
  try {
    const response = yield call(
      ApiCollections.getLeadHistory,
      payload?.payload
    );
    yield put(SupplementsActions.getLeadHistorySuccess(response));
    yield put(SupplementsActions.getLeadHistoryLoadingStop());
    payload?.callback(response);
  } catch (error) {
    yield put(SupplementsActions.getLeadHistoryLoadingStop());
  }
}


/*************************************************/


export function* createLeadCenter({ payload }) {

  yield put(SupplementsActions.createLeadCenterRequestStart());
  try {
    const response = yield call(
      ApiCollections.createLeadCenter,
      payload?.payload
    );
    yield put(SupplementsActions.createLeadCenterSuccess(response));
    yield put(payload?.callback(response));
  } catch (error) {
    yield put(SupplementsActions.createLeadCenterFailure(error));
  }
}

/*************************************************/


export function* updateLeadCenter({ payload }) {
  yield put(SupplementsActions.updateLeadCenterUpdateStart());
  try {
    const response = yield call(
      ApiCollections.updateLeadCenter,
       payload?.payload
    );
    yield put(SupplementsActions.updateLeadCenterUpdateSuccess(response));
    yield put(payload?.callback(response))
  } catch (error) {
    yield put(SupplementsActions.updateLeadCenterUpdateFailure(error));

  }

}


/*************************************************/

export function* supplementsSagas() {
  yield all([
    yield takeLatest(
      SupplementsActionTypes.GET_SUPPLEMENTS_REQUEST,
      getSupplements
    ),
    yield takeLatest(
      SupplementsActionTypes.DELETE_MAIL_CHIMP_USER_REQUEST,
      deleteMailChimpUserDetails
    ),
    yield takeLatest(
      SupplementsActionTypes.ADD_BASIC_USER_MAIL_CHIMP_DATA_REQUEST,
      addBasicUserMainChimpData
    ),
    yield takeLatest(
      SupplementsActionTypes.ADD_PRO_USER_MAIL_CHIMP_DATA_REQUEST,
      addProUserMainChimpData
    ),
    // yield takeLatest(
    //   SupplementsActionTypes.DELETE_MAIL_CHIMP_USER_REQUEST,
    //   deleteMailChimpUserDetails
    // ),
    yield takeLatest(
      SupplementsActionTypes.UPDATE_USER_SUBSCRIPTION_REQUEST,
      updateUserSubscription
    ),
    yield takeLatest(
      SupplementsActionTypes.INITIATE_STRIPE_PAYMENT_REQUEST,
      initiateStripePayment
    ),
    yield takeLatest(
      SupplementsActionTypes.GET_TRAINER_LEADS_REQUEST,
      getTrainerLeads
    ),
    yield takeLatest(
      SupplementsActionTypes.TRIGGER_TRAINER_EMAIL,
      triggerTrainerEmail
    ),
    yield takeLatest(
      SupplementsActionTypes.GET_TRAINER_LEAD_DETAILS_REQUEST,
      getTrainerLeadDetails
    ),
    yield takeLatest(
      SupplementsActionTypes.GET_NUMBER_OF_LEADS_REQUEST,
      getNumberofLeads
    ),

    yield takeLatest(
      SupplementsActionTypes.UPDATE_TRAINER_LEAD_REQUEST,
      updateTrainerLead
    ),
    yield takeLatest(
      SupplementsActionTypes.GET_FOODIARY_LEADS_REQUEST,
      getFoodiaryLeads
    ),
    yield takeLatest(
      SupplementsActionTypes.GET_FOODIARY_LEAD_DETAILS_REQUEST,
      getFoodiaryLeadDetails
    ),

    yield takeLatest(
      SupplementsActionTypes.CLAIM_FOODIARY_LEAD_REQUEST,
      claimFoodiaryLead
    ),

    yield takeLatest(
      SupplementsActionTypes.LEAD_UTILIZATION_REQUEST,
      leadUtlization
    ),

    yield takeLatest(
      SupplementsActionTypes.GET_LEAD_CENTER_STATUS_REQUEST,
      getLeadCenterStatus
    ),

    yield takeLatest(
      SupplementsActionTypes.GET_LEAD_HISTORY_REQUEST,
      getLeadHistory
    ),

    yield takeLatest(
      SupplementsActionTypes.GET_LEAD_AUTHORIZATION_REQUEST,
      getLeadAuthorization
    ),
    yield takeLatest(
      SupplementsActionTypes.CREATE_LEAD_CENTER_REQUEST,
      createLeadCenter
    ),
    yield takeLatest(
      SupplementsActionTypes.UPDATE_LEAD_CENTER_REQUEST,
      updateLeadCenter
    )

  ]);
}

/*************************************************/
