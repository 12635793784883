import styled from "styled-components";

export const MainDashboardContainer = styled.div`
  margin: 20px auto;
  max-width: calc(100% - 50px);
`;

export const MainDashboardTitle = styled.div`
  color: #1c9d87;
  font-size: 14px;
`;

export const MainDashboardSubTitle = styled.div`
  color: #142c1f;
  font-size: 24px;
  margin: 10px 0 30px 0;
`;

export const HomeHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
`;

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 5px;
`

export const VideoWithDescription = styled.div`
  h4 {
      font-size: 19px;
      line-height: 23px;
      margin: 15px 0 19px;
      color: #142C1F;
    }

    p {
      font-size: 16px;
      line-height: 23px;
      color: #808D87;
      max-width: 90%;
      font-family: RubikLight;
    }

  .player {
    width: 100% !important;
    height: auto !important;

    video {
      border-radius: 20px;
    }

    .react-player__preview {
      height: 25rem !important;
    }
  }
`
export const InfoResources = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 20px;

  > * {
    margin-bottom: 20px;
  }
`

export const ResourcesCard = styled.div`
  border: 1px solid #D0D5DD;
  font-size: 14px;
  border-radius: 15px;

  .card-header {
    padding: 12px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #D0D5DD;
  }

  .card-header span:nth-child(1) {
    font-weight: 500;
    padding: 7px 10px;
  }

  .card-header span:nth-child(2) {
    background: #F3F4F6;
    padding: 7px 10px;
    border-radius: 7px;
  }
  
  .card-items {
    padding: 12px 10px;
    display: flex;
    flex-direction: column;
    
    a {
      text-decoration: none;
      color: black;
    }
  }

  .resources-card-item {
    margin-bottom: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: all 200ms ease-in-out;

    &:hover {
      color: #1D9D87;
    }
    
    img {
      width: 80%;
    }
  }
  
  .resources-card-item span:nth-child(1) {
    margin-right: 10px;
  }

  .modules-card-item {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .category-tag {
      background: #1D9D87;
      color: white;
      padding: 7px 10px;
      border-radius: 7px;
    }
    
    .duration-tag {
      display: flex;
      align-items: center;

      span:nth-child(1) {
        margin-right: 10px;
      }
    }
  }
`

export const Divider = styled.div`
  height: 1px;
  background-color: #e2e4e9;
`;
