import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useAlert } from "react-alert";
import { ToastContainer, toast } from "react-toastify";

import ClientDetailsSidebar from "./ClientDetailsSidebar";

import * as DashboardActions from "redux/dashboard/dashboard.actions";
import * as AuthActions from "redux/auth/auth.actions";
import * as S from "./ClientDetails.styles";
import "./clientDetails.scss";

const EMAIL_TEMPLATES = {
  BASIC: "coaching-basic-welcome-e-mail",
  PLUS_ONE: "coaching-plus-welcome-e-mail",
  PLUS_TWO: "coaching-plus-krankenkasse-welcome",
  PRO_ONE: "coaching-pro-welcome-e-mail",
  PRO_TWO: "coaching-pro-krankenkasse-welcome",
  PASSWORD_RESET: "passwort-reset-coaching",
};

const EmailManagement = () => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const { userId } = useParams();

  const isEmailSending = useSelector((state) => state.dashboard.isEmailSending);

  const sendEmail = useSelector((state) => state.dashboard.sendEmail);
  const trainerData = useSelector((state) => state.auth.trainerData);

  const clientDetails = useSelector(
    (state) => state.auth.getClientDetails?.data
  );
  const isClientDetailsLoading = useSelector(
    (state) => state.auth.isClientDetailsLoading
  );

  const trainerProfileTempDetails = useSelector(
    (state) => state.dashboard.trainerProfileTempDetails
  );
  const [SelectedTemplate, SetSelectedTemplate] = useState("");

  useEffect(() => {
    dispatch(
      AuthActions.getClientDetailsRequest({
        payload: {
          trainerID: trainerData?.trainerID,
          userID: +userId,
        },
        callback: (res) => {
          console.log(res);
        },
      })
    );

    dispatch(
      DashboardActions.getTrainerProfileTempDetailsRequest({
        payload: { trainerID: trainerData?.trainerID },
        callback: () => {},
      })
    );
  }, []);

  const sendEmailToUsers = (templateName) => {
    SetSelectedTemplate(templateName);

    if (EMAIL_TEMPLATES.PLUS_ONE === templateName) {
      sendMultipleEmails([EMAIL_TEMPLATES.PLUS_ONE, EMAIL_TEMPLATES.PLUS_TWO]);
      return;
    }

    if (EMAIL_TEMPLATES.PRO_ONE === templateName) {
      sendMultipleEmails([EMAIL_TEMPLATES.PRO_ONE, EMAIL_TEMPLATES.PRO_TWO]);
      return;
    }

    dispatch(
      DashboardActions.sendEmailRequest({
        payload: {
          templateName,
          fromEmail: trainerProfileTempDetails?.data?.foodiaryEmail,
          fromName: trainerData?.prename + " " + trainerData?.surname,
          toEmail: clientDetails?.email,
          bccAddress: trainerProfileTempDetails?.data?.foodiaryEmail,
        },
        callback: (res) => {
          dispatch(DashboardActions.sendEmailNull());
          toast("📃 Die Email wurde verschickt");
        },
      })
    );
  };

  const sendMultipleEmails = (list) => {
    list.forEach((t, i) => {
      dispatch(
        DashboardActions.sendEmailRequest({
          payload: {
            templateName: t,
            fromEmail: trainerProfileTempDetails?.data?.foodiaryEmail,
            fromName: trainerData?.prename + " " + trainerData?.surname,
            toEmail: clientDetails?.email,
            bccAddress: trainerProfileTempDetails?.data?.foodiaryEmail,
          },
          callback: (res) => {
            if (i === 1) {
              dispatch(DashboardActions.sendEmailNull());

              toast("📃 Die Email wurde verschickt");
            }
          },
        })
      );
    });
  };

  return (
    <>
      
      <S.ClientDetailsOverallContainer>
        <S.ClientDetailsContainer>
          <div className="second-sidebar-wrapper">
            <ClientDetailsSidebar />
          </div>
          <div className="second-sidebar-content" style={{ padding: "1.5rem" }}>
            <S.ContainerBox>
              <S.ContainerBoxWithPadding>
                <S.Heading>E-Mails versenden</S.Heading>
                <S.SubHeading>
                  Unsere E-Mails werden immer automatisch verschickt. Sollte
                  dein Klient eine E-Mail nicht erhalten, <br /> so kannst du
                  hier die wichtigsten E-Mails manuell versenden.
                </S.SubHeading>
              </S.ContainerBoxWithPadding>
              <S.Divider></S.Divider>

              {isClientDetailsLoading && (
                <>
                  <br />
                  <br />
                  <br />
                  <br />
                  <div className="text-center">
                    <div className="loader mx-auto"></div>
                  </div>
                  <br />
                  <br />
                  <br />
                  <br />
                </>
              )}

              {!isClientDetailsLoading && (
                <>
                  <S.EmailBox>
                    <S.EmailBoxContent>
                      <h4>Willkommens-E-Mail für Coaching PRO</h4>
                      <p>
                        Schick eine E-Mail mit allen Startinfos und
                        Passwort-Setup für den Kunden.
                      </p>
                    </S.EmailBoxContent>
                    <S.EmailBoxAction>
                      <button
                        className="btn-email"
                        onClick={() =>
                          sendEmailToUsers(EMAIL_TEMPLATES.PRO_ONE)
                        }
                        disabled={
                          isEmailSending &&
                          (SelectedTemplate === EMAIL_TEMPLATES.PRO_ONE ||
                            SelectedTemplate === EMAIL_TEMPLATES.PRO_TWO)
                        }
                      >
                        {isEmailSending &&
                        (SelectedTemplate === EMAIL_TEMPLATES.PRO_ONE ||
                          SelectedTemplate === EMAIL_TEMPLATES.PRO_TWO)
                          ? "Sending..."
                          : "E-Mail versenden"}
                      </button>
                    </S.EmailBoxAction>
                  </S.EmailBox>
                  <S.Divider></S.Divider>
                  <S.EmailBox>
                    <S.EmailBoxContent>
                      <h4>Willkommens-E-Mail für Coaching PLUS</h4>
                      <p>
                        Schick eine E-Mail mit allen Startinfos und
                        Passwort-Setup für den Kunden.
                      </p>
                    </S.EmailBoxContent>
                    <S.EmailBoxAction>
                      <button
                        className="btn-email"
                        onClick={() =>
                          sendEmailToUsers(EMAIL_TEMPLATES.PLUS_ONE)
                        }
                        disabled={
                          isEmailSending &&
                          (SelectedTemplate === EMAIL_TEMPLATES.PLUS_ONE ||
                            SelectedTemplate === EMAIL_TEMPLATES.PLUS_TWO)
                        }
                      >
                        {isEmailSending &&
                        (SelectedTemplate === EMAIL_TEMPLATES.PLUS_ONE ||
                          SelectedTemplate === EMAIL_TEMPLATES.PLUS_TWO)
                          ? "Sending..."
                          : "E-Mail versenden"}
                      </button>
                    </S.EmailBoxAction>
                  </S.EmailBox>
                  <S.Divider></S.Divider>
                  <S.EmailBox>
                    <S.EmailBoxContent>
                      <h4>Willkommens-E-Mail für Coaching BASIC</h4>
                      <p>
                        Schick eine E-Mail mit allen Startinfos und
                        Passwort-Setup für den Kunden.
                      </p>
                    </S.EmailBoxContent>
                    <S.EmailBoxAction>
                      <button
                        className="btn-email"
                        onClick={() => sendEmailToUsers(EMAIL_TEMPLATES.BASIC)}
                        disabled={
                          isEmailSending &&
                          SelectedTemplate === EMAIL_TEMPLATES.BASIC
                        }
                      >
                        {isEmailSending &&
                        SelectedTemplate === EMAIL_TEMPLATES.BASIC
                          ? "Sending..."
                          : "E-Mail versenden"}
                      </button>
                    </S.EmailBoxAction>
                  </S.EmailBox>
                  <S.Divider></S.Divider>
                  <S.EmailBox>
                    <S.EmailBoxContent>
                      <h4>Passwort zurücksetzen</h4>
                      <p>E-Mail zum Passwort-Reset an den Kunden senden.</p>
                    </S.EmailBoxContent>
                    <S.EmailBoxAction>
                      <button
                        className="btn-email"
                        onClick={() =>
                          sendEmailToUsers(EMAIL_TEMPLATES.PASSWORD_RESET)
                        }
                        disabled={
                          isEmailSending &&
                          SelectedTemplate === EMAIL_TEMPLATES.PASSWORD_RESET
                        }
                      >
                        {isEmailSending &&
                        SelectedTemplate === EMAIL_TEMPLATES.PASSWORD_RESET
                          ? "Sending..."
                          : "E-Mail versenden"}
                      </button>
                    </S.EmailBoxAction>
                  </S.EmailBox>
                  <S.Divider></S.Divider>
                </>
              )}
            </S.ContainerBox>
          </div>
        </S.ClientDetailsContainer>
      </S.ClientDetailsOverallContainer>
    </>
  );
};

export default EmailManagement;
