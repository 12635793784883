import styled from "styled-components";

export const TokenRequestWrapper = styled.div`
  padding: 1.5rem;
`;

export const TokenRequestInnerWrapper = styled.div`
  padding: 1.5rem;
  border-radius: 10px;
  height: 100%;
  background-color: #fff;
`;

export const TokenRequestHeading = styled.h6`
  font-size: 20px;
  color: #142c1f;
  margin: 0;
  margin-bottom: 5px;
  line-height: 24px;
`;

export const TokenRequestSubHeading = styled.div`
  font-size: 16px;
  line-height: 19px;
  color: #818d88;
  font-weight: 300;
  margin: 1rem 0 1.2rem;
`;

export const Divider = styled.div`
  height: 1px;
  background-color: #e2e4e9;
`;

export const FormContainer = styled.div`
  .hidden {
    display: none;
  }

`

export const SubmitButton = styled.div`
  margin-top: 1rem;
  display: flex;
  justify-content: flex-end;
`

export const FormInputs = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
`
