import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAlert } from "react-alert";
import { ToastContainer, toast } from "react-toastify";

import CopyIcon from "assets/images/copy-icon-black.svg";
import InnerSideNav from "pages/NewDasboard/components/InnerSideNav/InnerSide";
import { MENUS } from "../data";

import * as NutritionActions from "redux/nutrition/nutrition.actions";
import * as S from "./HealthInsuranceReinbursement.styles";

const HealthInsuranceReinbursement = () => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const allRefunds = useSelector((state) => state.nutrition.getAllRefunds);

  const isAllRefundsLoading = useSelector(
    (state) => state.nutrition.isAllRefundsLoading
  );

  useEffect(() => {
    getAllRefunds();
  }, []);

  const getAllRefunds = () => {
    dispatch(
      NutritionActions.getAllRefundsRequest({
        pyload: null,
        callback: (res) => {},
      })
    );
  };

  const formatDate = (dateString) => {
    // Convert the date string to a JavaScript Date object
    const date = new Date(dateString);
    // Format the date using toLocaleDateString
    return date.toLocaleDateString("de-DE"); // "de-DE" represents the German locale, you can change it to your desired locale
  };

  const mailSubject = 'Änderung Krankenkasse'

  return (
    <>
      <S.Container className="whit-paper-page">
        <div className="second-sidebar-wrapper">
          <InnerSideNav menus={MENUS} hideUserInfo></InnerSideNav>
        </div>
        <S.Wrapper className="second-sidebar-content">
          <S.MCard>
            {/* <S.ClientDetailsContentHeading>
              Whitepaper
            </S.ClientDetailsContentHeading>
            <S.ClientDetailsContentSubHeading>
              Erweitere dein Wissen über Schlüsselaspekte der Selbstständigkeit.
            </S.ClientDetailsContentSubHeading> */}

            <S.Header className="p-0">
              <div>
                <S.TrainerProfileDetailsSectionHeading>
                  Rückerstattung der Krankenkasse
                </S.TrainerProfileDetailsSectionHeading>
                <S.TrainerProfileDetailsSectionSubHeading>
                  Wir prüfen jede Angabe, können jedoch keine Haftung für Änderungen übernehmen.
                </S.TrainerProfileDetailsSectionSubHeading>
              </div>
              <div>
                <a href={`mailto:backoffice@foodiary.app?subject=${mailSubject}`} className="btn-dark">Änderung mitteilen</a>
              </div>
            </S.Header>
            
            <S.TrainerProfileDetailsSectionSubHeading>
              <div className="links">
                <div
                  className="url-copy"
                  onClick={() => {
                    navigator.clipboard.writeText("CUWQJWEP");
                    toast("📃 Erfolgreich kopiert.");
                  }}
                >
                  <img src={CopyIcon} alt="CopyIcon" />
                  <p>
                    <strong>Zerfitizierungscode: </strong>CUWQJWEP
                  </p>
                </div>
                <div
                  className="url-copy"
                  onClick={() => {
                    navigator.clipboard.writeText(
                      "https://foodiaryproduction.s3.eu-central-1.amazonaws.com/course_zertifikate/Muster+Krankenkasse.pdf"
                    );

                    toast("📃 Erfolgreich kopiert.");
                  }}
                >
                  <img src={CopyIcon} alt="CopyIcon" />
                  <p>
                    <strong>Muster-Teilnahmebestätigung</strong>
                  </p>
                </div>
              </div>
            </S.TrainerProfileDetailsSectionSubHeading>
            <br />
            <S.Divider />
            <br />
            <S.WhitePaperWrap>
              {isAllRefundsLoading && (
                <>
                  <br />
                  <br />
                  <br />
                  <br />
                  <div className="text-center">
                    <div className="loader mx-auto"></div>
                  </div>
                  <br />
                  <br />
                  <br />
                  <br />
                </>
              )}
              {!isAllRefundsLoading && (
                <S.TableWrapper>
                  <table>
                    <thead>
                      <tr>
                        <th>Krankenkasse</th>
                        <th>Letztes Update</th>
                        <th>Rückerstattung</th>
                        <th>Rückerstattungsbetrag</th>
                      </tr>
                    </thead>
                    <tbody>
                      {allRefunds?.getAllRefunds.map((item) => (
                        <tr>
                          <td>
                            <p>{item?.krankenkasse}</p>
                          </td>
                          <td>
                            <div className="contact-person">
                              {item?.updated_at && (
                                <p>{formatDate(item?.updated_at)}</p>
                              )}
                            </div>
                          </td>
                          <td>
                            {item?.refund_amount > 50 &&
                              item?.refund_amount < 76 && (
                                <div className="status-badge fixed text-center orange-yellow">
                                  {item?.refund_amount}%
                                </div>
                              )}

                            {item?.refund_amount > 75 && (
                              <div className="status-badge fixed text-center green">
                                {item?.refund_amount}%
                              </div>
                            )}

                            {item?.refund_amount <= 50 && (
                              <div className="status-badge fixed text-center red">
                                {item?.refund_amount}%
                              </div>
                            )}
                          </td>
                          <td>
                            <p>{150 * (item?.refund_amount / 100)}€</p>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </S.TableWrapper>
              )}
            </S.WhitePaperWrap>
          </S.MCard>
        </S.Wrapper>
      </S.Container>
    </>
  );
};

export default HealthInsuranceReinbursement;
