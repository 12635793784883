import React, { useState, useEffect } from "react";
import { Route, useHistory } from "react-router-dom";
import InnerSideNav from "pages/NewDasboard/components/InnerSideNav/InnerSide";
import * as S from "./WebsiteWrapper.style";
import TrainerProfileWebsiteOverview from "./TrainerProfileWebsiteOverview";
import TrainerProfileFeedback from "../TrainerProfileFeedback";
import TrainerProfileWebsiteSetup from "../TrainerProfileWebsiteSetup";
import TrainerProfileCoachCard from "../TrainerProfileCoachCard";

const MENUS = [
  {
    id: 1,
    title: "Übersicht",
    path: "/dashboard/website/overview",
  },
  {
    id: 2,
    title: "Webseite einrichten",
    path: "/dashboard/website/setup",
  },
  {
    id: 3,
    title: "Coach Card",
    path: "/dashboard/website/coach-card",
  },
  {
    id: 4,
    title: "Kundenfeedback",
    path: "/dashboard/website/customer-feedback",
  },
];

const WebsiteWrapper = () => {
  const history = useHistory();

  useEffect(() => {
    // history.push("/dashboard/website/overview");
  }, []);

  return (
    <>
      <S.Container>
        <div className="second-sidebar-wrapper">
          <InnerSideNav menus={MENUS} hideUserInfo></InnerSideNav>
        </div>
        <div className="second-sidebar-content">
          <Route
            path="/dashboard/website/overview"
            component={TrainerProfileWebsiteOverview}
            exact
          />
          <Route
            path="/dashboard/website/setup"
            component={TrainerProfileWebsiteSetup}
            exact
          />
          <Route
            path="/dashboard/website/coach-card"
            component={TrainerProfileCoachCard}
            exact
          />
          <Route
            path="/dashboard/website/customer-feedback"
            component={TrainerProfileFeedback}
            exact
          />
        </div>
      </S.Container>
    </>
  );
};

export default WebsiteWrapper;
