import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useAlert } from "react-alert";

import * as DashboardActions from "redux/dashboard/dashboard.actions";

import * as S from "./clientDetails.styles";
import "./clientDetails.scss";

const EMAIL_TEMPLATES = {
  BASIC: "coaching-basic-welcome-e-mail",
  PLUS_ONE: "coaching-plus-welcome-e-mail",
  PLUS_TWO: "coaching-plus-krankenkasse-welcome",
  PRO_ONE: "coaching-pro-welcome-e-mail",
  PRO_TWO: "coaching-pro-krankenkasse-welcome",
  PASSWORD_RESET: "passwort-reset-coaching",
};

const EmailManagement = ({ clientDetails }) => {
  const dispatch = useDispatch();
  const alert = useAlert();

  const [SelectedTemplate, SetSelectedTemplate] = useState("");

  const isEmailSending = useSelector((state) => state.dashboard.isEmailSending);

  const sendEmail = useSelector((state) => state.dashboard.sendEmail);
  const trainerData = useSelector((state) => state.auth.trainerData);
  const trainerProfileTempDetails = useSelector(
    (state) => state.dashboard.trainerProfileTempDetails
  );

  console.log("sendEmail", sendEmail);

  const sendEmailToUsers = (templateName) => {
    SetSelectedTemplate(templateName);

    if (EMAIL_TEMPLATES.PLUS_ONE === templateName) {
      sendMultipleEmails([EMAIL_TEMPLATES.PLUS_ONE, EMAIL_TEMPLATES.PLUS_TWO]);
      return;
    }

    if (EMAIL_TEMPLATES.PRO_ONE === templateName) {
      sendMultipleEmails([EMAIL_TEMPLATES.PRO_ONE, EMAIL_TEMPLATES.PRO_TWO]);
      return;
    }

    dispatch(
      DashboardActions.sendEmailRequest({
        payload: {
          templateName,
          fromEmail: trainerProfileTempDetails?.data?.foodiaryEmail,
          fromName: trainerData?.prename + " " + trainerData?.surname,
          toEmail: clientDetails?.email,
          bccAddress: trainerProfileTempDetails?.data?.foodiaryEmail,
        },
        callback: (res) => {
          dispatch(DashboardActions.sendEmailNull());
          alert.success("Die Email wurde verschickt", {
            timeout: 5000,
          });
        },
      })
    );
  };

  const sendMultipleEmails = (list) => {
    list.forEach((t, i) => {
      dispatch(
        DashboardActions.sendEmailRequest({
          payload: {
            templateName: t,
            fromEmail: trainerProfileTempDetails?.data?.foodiaryEmail,
            fromName: trainerData?.prename + " " + trainerData?.surname,
            toEmail: clientDetails?.email,
            bccAddress: trainerProfileTempDetails?.data?.foodiaryEmail,
          },
          callback: (res) => {
            if (i === 1) {
              dispatch(DashboardActions.sendEmailNull());

              alert.success("Die Email wurde verschickt", {
                timeout: 5000,
              });
            }
          },
        })
      );
    });
  };

  return (
    <>
      <S.ContainerBox>
        <S.ContainerBoxWithPadding>
          <S.Heading>E-Mails versenden</S.Heading>
          <S.SubHeading>
            Unsere E-Mails werden immer automatisch verschickt. Sollte dein
            Klient eine E-Mail nicht erhalten, <br /> so kannst du hier die
            wichtigsten E-Mails manuell versenden.
          </S.SubHeading>
        </S.ContainerBoxWithPadding>

        <S.Divider></S.Divider>
        <S.EmailBox>
          <S.EmailBoxContent>
            <h4>Willkommens-E-Mail für Coaching PRO</h4>
            <p>
              Schick eine E-Mail mit allen Startinfos und Passwort-Setup für den
              Kunden.
            </p>
          </S.EmailBoxContent>
          <S.EmailBoxAction>
            <button
              className="btn-email"
              onClick={() => sendEmailToUsers(EMAIL_TEMPLATES.PRO_ONE)}
              disabled={
                isEmailSending &&
                (SelectedTemplate === EMAIL_TEMPLATES.PRO_ONE ||
                  SelectedTemplate === EMAIL_TEMPLATES.PRO_TWO)
              }
            >
              {isEmailSending &&
              (SelectedTemplate === EMAIL_TEMPLATES.PRO_ONE ||
                SelectedTemplate === EMAIL_TEMPLATES.PRO_TWO)
                ? "Sending..."
                : "E-Mail versenden"}
            </button>
          </S.EmailBoxAction>
        </S.EmailBox>
        <S.Divider></S.Divider>
        <S.EmailBox>
          <S.EmailBoxContent>
            <h4>Willkommens-E-Mail für Coaching PLUS</h4>
            <p>
              Schick eine E-Mail mit allen Startinfos und Passwort-Setup für den
              Kunden.
            </p>
          </S.EmailBoxContent>
          <S.EmailBoxAction>
            <button
              className="btn-email"
              onClick={() => sendEmailToUsers(EMAIL_TEMPLATES.PLUS_ONE)}
              disabled={
                isEmailSending &&
                (SelectedTemplate === EMAIL_TEMPLATES.PLUS_ONE ||
                  SelectedTemplate === EMAIL_TEMPLATES.PLUS_TWO)
              }
            >
              {isEmailSending &&
              (SelectedTemplate === EMAIL_TEMPLATES.PLUS_ONE ||
                SelectedTemplate === EMAIL_TEMPLATES.PLUS_TWO)
                ? "Sending..."
                : "E-Mail versenden"}
            </button>
          </S.EmailBoxAction>
        </S.EmailBox>
        <S.Divider></S.Divider>
        <S.EmailBox>
          <S.EmailBoxContent>
            <h4>Willkommens-E-Mail für Coaching BASIC</h4>
            <p>
              Schick eine E-Mail mit allen Startinfos und Passwort-Setup für den
              Kunden.
            </p>
          </S.EmailBoxContent>
          <S.EmailBoxAction>
            <button
              className="btn-email"
              onClick={() => sendEmailToUsers(EMAIL_TEMPLATES.BASIC)}
              disabled={
                isEmailSending && SelectedTemplate === EMAIL_TEMPLATES.BASIC
              }
            >
              {isEmailSending && SelectedTemplate === EMAIL_TEMPLATES.BASIC
                ? "Sending..."
                : "E-Mail versenden"}
            </button>
          </S.EmailBoxAction>
        </S.EmailBox>
        <S.Divider></S.Divider>
        <S.EmailBox>
          <S.EmailBoxContent>
            <h4>Passwort zurücksetzen</h4>
            <p>E-Mail zum Passwort-Reset an den Kunden senden.</p>
          </S.EmailBoxContent>
          <S.EmailBoxAction>
            <button
              className="btn-email"
              onClick={() => sendEmailToUsers(EMAIL_TEMPLATES.PASSWORD_RESET)}
              disabled={
                isEmailSending &&
                SelectedTemplate === EMAIL_TEMPLATES.PASSWORD_RESET
              }
            >
              {isEmailSending &&
              SelectedTemplate === EMAIL_TEMPLATES.PASSWORD_RESET
                ? "Sending..."
                : "E-Mail versenden"}
            </button>
          </S.EmailBoxAction>
        </S.EmailBox>
        <S.Divider></S.Divider>
      </S.ContainerBox>
    </>
  );
};

export default EmailManagement;
