import YoghurtWithFruits from "assets/images/eatingHabits/YoghurtWithFruits.jpg";
import ToastWithEggAndAvocado from "assets/images/eatingHabits/ToastWithEggAndAvocado.jpg";
import Smoothiebowl from "assets/images/eatingHabits/Smoothiebowl.jpg";
import Spiegeleier from "assets/images/eatingHabits/Spiegeleier.jpg";
import Granola from "assets/images/eatingHabits/Granola.jpg";
import Apple from "assets/images/eatingHabits/Apple.jpg";
import LowFatCurd from "assets/images/eatingHabits/LowFatCurd.jpg";
import Nuts from "assets/images/eatingHabits/Nuts.jpg";
import Avocado from "assets/images/eatingHabits/Avocado.jpg";
import Peach from "assets/images/eatingHabits/Peach.jpg";
import RiceCakes from "assets/images/eatingHabits/RiceCakes.jpg";
import Oatmeal from "assets/images/eatingHabits/Oatmeal.jpg";
import ScrambledEggs from "assets/images/eatingHabits/ScrambledEggs.jpg";
import Smoothiebowl1 from "assets/images/eatingHabits/Smoothiebowl1.jpg";
import ChilliConCarne from "assets/images/eatingHabits/ChilliConCarne.jpg";
import SalmonWithAsparagus from "assets/images/eatingHabits/SalmonWithAsparagus.jpg";
import GreekSaladWithFeta from "assets/images/eatingHabits/GreekSaladWithFeta.jpg";

import SalatmitZiegenkaseundBirnen from "assets/images/dietCategories/Vegan/Step2/SalatmitZiegenkaseundBirnen-fat.jpg";
import ReismitgebratenemTofu from "assets/images/dietCategories/Vegan/Step2/ReismitgebratenemTofu-fat.jpg";
import BrokkolieCouscousPfanne from "assets/images/dietCategories/Vegan/Step2/BrokkolieCouscousPfanne-carbs.jpg";

import BrotmitAvocadofats from "assets/images/dietCategories/Vegan/Step3/BrotmitAvocadofats.jpg";
import FalafelmitCouscoussalatcarbs from "assets/images/dietCategories/Vegan/Step3/FalafelmitCouscoussalatcarbs.jpg";
import HummusmitFalafelcarbs from "assets/images/dietCategories/Vegan/Step3/HummusmitFalafelcarbs.jpg";

import SalatmitNussmischungfat from "assets/images/dietCategories/Vegan/Step4/SalatmitNussmischungfat.jpg";
import Belugalinsensalatcarbs from "assets/images/dietCategories/Vegan/Step4/Belugalinsensalatcarbs.jpg";
import ReismitMangocarbs from "assets/images/dietCategories/Vegan/Step4/ReismitMangocarbs.jpg";

import BandnudelninKaseSahneSaucefats from "assets/images/dietCategories/Vegan/Step5/BandnudelninKaseSahneSaucefats.jpg";
import GnocchiGemusePfannefats from "assets/images/dietCategories/Vegan/Step5/GnocchiGemusePfannefats.jpg";
import Salatmitguacamolefats from "assets/images/dietCategories/Vegan/Step5/Salatmitguacamolefats.jpg";

import SalatmitAvocadoUndEierFatVegetarian from "assets/images/dietCategories/vegetarian/Step2/SalatmitAvocadoUndEierFat.jpg";
import NudelpfannemitGemuseFatVegetarian from "assets/images/dietCategories/vegetarian/Step2/NudelpfannemitGemuseFat.jpg";
import GebackeneAvocadomitEifatVegetarian from "assets/images/dietCategories/vegetarian/Step2/GebackeneAvocadomitEifat.jpg";

import EierragoutfatVegetarian from "assets/images/dietCategories/vegetarian/Step3/Eierragoutfat.jpg";
import BratkartoffelncarbsVegetarian from "assets/images/dietCategories/vegetarian/Step3/Bratkartoffelncarbs.jpg";
import HummusmitFalafelcarbsVegetarian from "assets/images/dietCategories/vegetarian/Step3/HummusmitFalafelcarbs.jpg";

import NussefatVegetarian from "assets/images/dietCategories/vegetarian/Step4/Nussefat.jpeg";
import BananecarbsVegetarian from "assets/images/dietCategories/vegetarian/Step4/Bananecarbs.jpg";
import ReiswaffelncarbsVegetarian from "assets/images/dietCategories/vegetarian/Step4/Reiswaffelncarbs.jpg";

import KlassischesRuhreifatVegetarian from "assets/images/dietCategories/vegetarian/Step5/KlassischesRuhreifat.jpg";
import ChiapuddingmitHimbeerenfatVegetarian from "assets/images/dietCategories/vegetarian/Step5/ChiapuddingmitHimbeerenfat.jpg";
import OatmealmitPfirsichundHimbeerencarbsVegetarian from "assets/images/dietCategories/vegetarian/Step5/OatmealmitPfirsichundHimbeerencarbs.jpg";

export const RegistrationLinks = [
  "/whatAwaitsYou",
  "/registration/gender",
  "/registration/goal",
  "/registration/anamnese_evaluation",
  "/registration/bodyDetails",
  "/registration/dailyRoutine",
  "/registration/activityLevel",
  "/registration/trainingFixed",
  "/registration/dietCategories",
  "/registration/eatingHabit1",
  "/registration/eatingHabit2",
  "/registration/eatingHabit3",
  "/registration/eatingHabit4",
  "/registration/eatingHabit5",
  "/registration/personalData",
  "/nutrition/creation",
];

export const hourValues = [
  { id: "0", value: "0 h" },
  { id: "1", value: "1 h" },
  { id: "2", value: "2 h" },
  { id: "3", value: "3 h" },
  { id: "4", value: "4 h" },
  { id: "5", value: "5 h" },
  { id: "6", value: "6 h" },
  { id: "7", value: "7 h" },
  { id: "8", value: "8 h" },
  { id: "9", value: "9 h" },
  { id: "10", value: "10 h" },
  { id: "11", value: "11 h" },
  { id: "12", value: "12 h" },
  { id: "13", value: "13 h" },
  { id: "14", value: "14 h" },
  { id: "15", value: "15 h" },
  { id: "16", value: "16 h" },
  { id: "17", value: "17 h" },
  { id: "18", value: "18 h" },
  { id: "19", value: "19 h" },
  { id: "20", value: "20 h" },
  { id: "21", value: "21 h" },
  { id: "22", value: "22 h" },
  { id: "23", value: "23 h" },
  { id: "24", value: "24 h" },
];

export const AddDailyRoutineValues = [
  { id: "input.label.getup", hrs: "07" },
  { id: "input.label.lunchTime", hrs: "12" },
  { id: "input.label.workoutTime", hrs: "17" },
  { id: "input.label.bedTime", hrs: "22" },
];

export const activityLevelValues = [
  { id: "registration.activityLevel.sleep", options: hourValues },
  { id: "registration.activityLevel.lyingDown", options: hourValues },
  { id: "registration.activityLevel.standing", options: hourValues },
  { id: "registration.activityLevel.siting", options: hourValues },
  { id: "registration.activityLevel.walking", options: hourValues },
  { id: "registration.activityLevel.exercising", options: hourValues },
];

export const trainingFixedValues = [
  { id: "registration.trainingFixed.monday", value: 1 },
  { id: "registration.trainingFixed.tuesday", value: 2 },
  { id: "registration.trainingFixed.wednesday", value: 3 },
  { id: "registration.trainingFixed.thursday", value: 4 },
  { id: "registration.trainingFixed.friday", value: 5 },
  { id: "registration.trainingFixed.saturday", value: 6 },
  { id: "registration.trainingFixed.sunday", value: 7 },
];

export const AccordionContentData = [
  { id: "registration.dailyRoutine.getup" },
  { id: "registration.dailyRoutine.lunch" },
  { id: "registration.dailyRoutine.workoutTime" },
  { id: "registration.dailyRoutine.bedTime" },
];

export const getProgress = (id) => {
  let progress = "0%";

  const eatingHabit2 = [
    "eatingHabit2",
    "eatingHabit2-vegan",
    "eatingHabit2-vegetarian",
  ];
  const eatingHabit3 = [
    "eatingHabit3",
    "eatingHabit3-vegan",
    "eatingHabit3-vegetarian",
  ];
  const eatingHabit4 = [
    "eatingHabit4",
    "eatingHabit4-vegan",
    "eatingHabit4-vegetarian",
  ];
  const eatingHabit5 = [
    "eatingHabit5",
    "eatingHabit5-vegan",
    "eatingHabit5-vegetarian",
  ];

  if (id === "gender") progress = "10%";
  if (id === "goal") progress = "20%";
  if (id === "anamnese_evaluation") progress = "15%";
  if (id === "bodyDetails") progress = "30%";
  if (id === "dailyRoutine") progress = "40%";
  if (id === "activityLevel") progress = "50%";
  if (id === "trainingFixed") progress = "60%";
  if (id === "dietCategories") progress = "60%";
  if (id === "eatingHabit1") progress = "70%";
  if (eatingHabit2.includes(id)) progress = "73%";
  if (eatingHabit3.includes(id)) progress = "76%";
  if (eatingHabit4.includes(id)) progress = "78%";
  if (eatingHabit5.includes(id)) progress = "80%";
  if (id === "personalData") progress = "90%";
  return progress;
};

export const eatingHabits1Data = [
  {
    subCategoryId: "eatingHabit1",
    eatingHabitData: {
      id: "YoghurtWithFruits",
      image: YoghurtWithFruits,
      heading: "registration.eatingHabit.yoghurtWithFruit",
      nutrients: {
        carbohydrates: 0,
        protein: 0,
        fat: 0,
      },
    },
  },
  {
    subCategoryId: "eatingHabit1",
    eatingHabitData: {
      id: "ToastWithEggAndAvocado",
      image: ToastWithEggAndAvocado,
      heading: "registration.eatingHabit.toastWithEggAvocada",
      nutrients: {
        carbohydrates: 0,
        protein: 0,
        fat: 0,
      },
    },
  },
  {
    subCategoryId: "eatingHabit1",
    eatingHabitData: {
      id: "Smoothiebowl",
      image: Smoothiebowl,
      heading: "registration.eatingHabit.Smoothiebowl",
      nutrients: {
        carbohydrates: 0,
        protein: 0,
        fat: 0,
      },
    },
  },
  {
    subCategoryId: "eatingHabit1",
    eatingHabitData: {
      id: "Spiegeleier",
      image: Spiegeleier,
      heading: "registration.eatingHabit.Spiegeleier",
      nutrients: {
        carbohydrates: 0,
        protein: 0,
        fat: 0,
      },
    },
  },
  {
    subCategoryId: "eatingHabit1",
    eatingHabitData: {
      id: "Granola",
      image: Granola,
      heading: "registration.eatingHabit.granola",
      nutrients: {
        carbohydrates: 0,
        protein: 0,
        fat: 0,
      },
    },
  },
];

export let eatingHabits2Data = [
  {
    id: "Apple",
    image: Apple,
    heading: "registration.eatingHabit.apple",
    nutrients: {
      carbohydrates: 1,
      protein: 0,
      fat: 0,
    },
  },
  {
    id: "LowFatCurd",
    image: LowFatCurd,
    heading: "registration.eatingHabit.LowFatCurd",
    nutrients: {
      carbohydrates: 0,
      protein: 0,
      fat: 1,
    },
  },
  {
    id: "Nuts",
    image: Nuts,
    heading: "registration.eatingHabit.Nuts",
    nutrients: {
      carbohydrates: 0,
      protein: 0,
      fat: 1,
    },
  },
].map((eatingHabitData) => ({
  subCategoryId: "eatingHabit2",
  eatingHabitData,
}));

export const eatingHabits3Data = [
  {
    id: "Avocado",
    image: Avocado,
    heading: "registration.eatingHabit.Avocado",
    nutrients: {
      carbohydrates: 0,
      protein: 0,
      fat: 1,
    },
  },
  {
    id: "Peach",
    image: Peach,
    heading: "registration.eatingHabit.Peach",
    nutrients: {
      carbohydrates: 1,
      protein: 0,
      fat: 0,
    },
  },
  {
    id: "RiceCakes",
    image: RiceCakes,
    heading: "registration.eatingHabit.RiceCakes",
    nutrients: {
      carbohydrates: 1,
      protein: 0,
      fat: 0,
    },
  },
].map((eatingHabitData) => ({
  subCategoryId: "eatingHabit3",
  eatingHabitData,
}));

export const eatingHabits4Data = [
  {
    id: "Oatmeal",
    image: Oatmeal,
    heading: "registration.eatingHabit.Oatmeal",
    nutrients: {
      carbohydrates: 1,
      protein: 0,
      fat: 0,
    },
  },
  {
    id: "ScrambledEggs",
    image: ScrambledEggs,
    heading: "registration.eatingHabit.ScrambledEggs",
    nutrients: {
      carbohydrates: 0,
      protein: 0,
      fat: 1,
    },
  },
  {
    id: "Smoothiebowl1",
    image: Smoothiebowl1,
    heading: "registration.eatingHabit.Smoothiebowl1",
    nutrients: {
      carbohydrates: 1,
      protein: 0,
      fat: 0,
    },
  },
].map((eatingHabitData) => ({
  subCategoryId: "eatingHabit4",
  eatingHabitData,
}));

export const eatingHabits5Data = [
  {
    id: "ChilliConCarne",
    image: ChilliConCarne,
    heading: "registration.eatingHabit.chilliConCarne",
    nutrients: {
      carbohydrates: 1,
      protein: 0,
      fat: 0,
    },
  },
  {
    id: "SalmonWithAsparagus",
    image: GreekSaladWithFeta,
    heading: "registration.eatingHabit.salmonWithAsparagus",
    nutrients: {
      carbohydrates: 0,
      protein: 0,
      fat: 1,
    },
  },
  {
    id: "GreekSaladWithFeta",
    image: SalmonWithAsparagus,
    heading: "registration.eatingHabit.greekSaladWithFeta",
    nutrients: {
      carbohydrates: 0,
      protein: 0,
      fat: 1,
    },
  },
].map((eatingHabitData) => ({
  subCategoryId: "eatingHabit5",
  eatingHabitData,
}));

export const eatingHabits2veganData = [
  {
    id: "BrokkolieCouscousPfanneCarbs",
    image: BrokkolieCouscousPfanne,
    heading: "registration.eatingHabit.vegan.BrokkolieCouscousPfanneCarbs",
    nutrients: {
      carbohydrates: 1,
      protein: 0,
      fat: 0,
    },
  },
  {
    id: "ReisMitgebratenemTofuFat",
    image: ReismitgebratenemTofu,
    heading: "registration.eatingHabit.vegan.ReisMitgebratenemTofuFat",
    nutrients: {
      carbohydrates: 0,
      protein: 0,
      fat: 1,
    },
  },
  {
    id: "SalatmitZiegenkaseundBirnenFat",
    image: SalatmitZiegenkaseundBirnen,
    heading: "registration.eatingHabit.vegan.SalatmitZiegenkaseundBirnenFat",
    nutrients: {
      carbohydrates: 0,
      protein: 0,
      fat: 1,
    },
  },
].map((eatingHabitData) => ({
  subCategoryId: "eatingHabit2-vegan",
  eatingHabitData,
}));

export const eatingHabits3veganData = [
  {
    id: "BrotMitAvocado",
    image: BrotmitAvocadofats,
    heading: "registration.eatingHabit.vegan.BrotMitAvocado",
    nutrients: {
      carbohydrates: 0,
      protein: 0,
      fat: 1,
    },
  },
  {
    id: "FalafelMitCouscousSalat",
    image: FalafelmitCouscoussalatcarbs,
    heading: "registration.eatingHabit.vegan.FalafelMitCouscousSalat",
    nutrients: {
      carbohydrates: 1,
      protein: 0,
      fat: 0,
    },
  },
  {
    id: "HummusMitFalafel",
    image: HummusmitFalafelcarbs,
    heading: "registration.eatingHabit.vegan.HummusMitFalafel",
    nutrients: {
      carbohydrates: 1,
      protein: 0,
      fat: 0,
    },
  },
].map((eatingHabitData) => ({
  subCategoryId: "eatingHabit3-vegan",
  eatingHabitData,
}));

export const eatingHabits4veganData = [
  {
    id: "SalatMitNussmischung",
    image: SalatmitNussmischungfat,
    heading: "registration.eatingHabit.vegan.SalatMitNussmischung",
    nutrients: {
      carbohydrates: 0,
      protein: 0,
      fat: 1,
    },
  },
  {
    id: "Belugalinsensalat",
    image: Belugalinsensalatcarbs,
    heading: "registration.eatingHabit.vegan.Belugalinsensalat",
    nutrients: {
      carbohydrates: 1,
      protein: 0,
      fat: 0,
    },
  },
  {
    id: "ReisMitMango",
    image: ReismitMangocarbs,
    heading: "registration.eatingHabit.vegan.ReisMitMango",
    nutrients: {
      carbohydrates: 1,
      protein: 0,
      fat: 0,
    },
  },
].map((eatingHabitData) => ({
  subCategoryId: "eatingHabit4-vegan",
  eatingHabitData,
}));

export const eatingHabits5veganData = [
  {
    id: "BandnudelnInKäseSahneSauce",
    image: BandnudelninKaseSahneSaucefats,
    heading: "registration.eatingHabit.vegan.BandnudelnInKäseSahneSauce",
    nutrients: {
      carbohydrates: 0,
      protein: 0,
      fat: 1,
    },
  },
  {
    id: "GnocchiGemüsePfanne",
    image: GnocchiGemusePfannefats,
    heading: "registration.eatingHabit.vegan.GnocchiGemüsePfanne",
    nutrients: {
      carbohydrates: 1,
      protein: 0,
      fat: 0,
    },
  },
  {
    id: "SalatMitGuacamole",
    image: Salatmitguacamolefats,
    heading: "registration.eatingHabit.vegan.SalatMitGuacamole",
    nutrients: {
      carbohydrates: 0,
      protein: 0,
      fat: 1,
    },
  },
].map((eatingHabitData) => ({
  subCategoryId: "eatingHabit5-vegan",
  eatingHabitData,
}));

export const eatingHabits2vegetarianData = [
  {
    id: "SalatMitAvocadoUndEier",
    image: SalatmitAvocadoUndEierFatVegetarian,
    heading: "registration.eatingHabit.vegetarian.SalatMitAvocadoUndEier",
    nutrients: {
      carbohydrates: 0,
      protein: 0,
      fat: 1,
    },
  },
  {
    id: "NudelpfanneMitGemuse",
    image: NudelpfannemitGemuseFatVegetarian,
    heading: "registration.eatingHabit.vegetarian.NudelpfanneMitGemuse",
    nutrients: {
      carbohydrates: 1,
      protein: 0,
      fat: 0,
    },
  },
  {
    id: "GebackeneAvocadoMitEi",
    image: GebackeneAvocadomitEifatVegetarian,
    heading: "registration.eatingHabit.vegetarian.GebackeneAvocadoMitEi",
    nutrients: {
      carbohydrates: 0,
      protein: 0,
      fat: 1,
    },
  },
].map((eatingHabitData) => ({
  subCategoryId: "eatingHabit2-vegetarian",
  eatingHabitData,
}));

export const eatingHabits3vegetarianData = [
  {
    id: "Eierragout",
    image: EierragoutfatVegetarian,
    heading: "registration.eatingHabit.vegetarian.Eierragout",
    nutrients: {
      carbohydrates: 0,
      protein: 0,
      fat: 1,
    },
  },
  {
    id: "Bratkartoffeln",
    image: BratkartoffelncarbsVegetarian,
    heading: "registration.eatingHabit.vegetarian.Bratkartoffeln",
    nutrients: {
      carbohydrates: 1,
      protein: 0,
      fat: 0,
    },
  },
  {
    id: "HummusMitFalafel",
    image: HummusmitFalafelcarbsVegetarian,
    heading: "registration.eatingHabit.vegetarian.HummusMitFalafel",
    nutrients: {
      carbohydrates: 1,
      protein: 0,
      fat: 0,
    },
  },
].map((eatingHabitData) => ({
  subCategoryId: "eatingHabit3-vegetarian",
  eatingHabitData,
}));

export const eatingHabits4vegetarianData = [
  {
    id: "Nusse",
    image: NussefatVegetarian,
    heading: "registration.eatingHabit.vegetarian.Nusse",
    nutrients: {
      carbohydrates: 0,
      protein: 0,
      fat: 1,
    },
  },
  {
    id: "Banane",
    image: BananecarbsVegetarian,
    heading: "registration.eatingHabit.vegetarian.Banane",
    nutrients: {
      carbohydrates: 1,
      protein: 0,
      fat: 0,
    },
  },
  {
    id: "Maiswaffeln",
    image: ReiswaffelncarbsVegetarian,
    heading: "registration.eatingHabit.vegetarian.Maiswaffeln",
    nutrients: {
      carbohydrates: 1,
      protein: 0,
      fat: 0,
    },
  },
].map((eatingHabitData) => ({
  subCategoryId: "eatingHabit4-vegetarian",
  eatingHabitData,
}));

export const eatingHabits5vegetarianData = [
  {
    id: "KlassischesRuhrei",
    image: KlassischesRuhreifatVegetarian,
    heading: "registration.eatingHabit.vegetarian.KlassischesRuhrei",
    nutrients: {
      carbohydrates: 0,
      protein: 0,
      fat: 1,
    },
  },
  {
    id: "OatmealMitPfirsichUndHimbeeren",
    image: OatmealmitPfirsichundHimbeerencarbsVegetarian,
    heading:
      "registration.eatingHabit.vegetarian.OatmealMitPfirsichUndHimbeeren",
    nutrients: {
      carbohydrates: 1,
      protein: 0,
      fat: 0,
    },
  },
  {
    id: "ChiapuddingMitHimbeeren",
    image: ChiapuddingmitHimbeerenfatVegetarian,
    heading: "registration.eatingHabit.vegetarian.ChiapuddingMitHimbeeren",
    nutrients: {
      carbohydrates: 0,
      protein: 0,
      fat: 1,
    },
  },
].map((eatingHabitData) => ({
  subCategoryId: "eatingHabit5-vegetarian",
  eatingHabitData,
}));
