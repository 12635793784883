import styled from "styled-components"
import { ClientDetailsOverviewInputLabel } from "components/clientDetails/clientDetails.styles"

export const ModalBackdrop = styled.div`
  width: 100%;
  height: 100vh;
  background-color: rgb(0, 0, 0, 0.2);
  position: fixed;
  top: 0;
  left: 0;
  overflow: auto;
  z-index: 998;
  display: ${(props) => (props.show ? "block" : "none")};
`

export const ModalContainer = styled.div`
  width: ${(props) => (props.width ? props.width : "400px")};
  background-color: white;
  height: ${(props) => (props.height ? props.height : "auto")};
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
  border-radius: 10px;
`

export const ModalInnerContainer = styled.div`
  margin: 10px;
`

export const ModalHeader = styled.div`
  font-family: RubikMedium;
  font-size: 20px;
  color: #142c1f;
  text-align: center;
  margin-bottom: 30px;
`

export const ModalBody = styled.div`
  position: relative;
  .icon-close {
    position: absolute;
    top: -24px;
    right: -24px;
    z-index: 9;
    background: white;
    border-radius: 50%;
    cursor: pointer;
  }
`

export const ValidationText = styled.p.attrs({
  className: `${(props) => props.className}`,
  id: `${(props) => props.id}`,
})`
  color: red;
  font-size: 14px;
  font-weight: bold;
  font-weight: 400;
  word-break: break-word;
  user-select: text;
  margin: 6px 0 0 0;
`

export const SuccessMsg = styled.p.attrs({
  className: `${(props) => props.className}`,
  id: `${(props) => props.id}`,
})`
  font-size: 16px;
  font-weight: bold;
  font-weight: 400;
  word-break: break-word;
  user-select: text;
  text-align: center;
  margin: 6px 0 20px;
  color: #2ecf96;
`

export const ErrorMsg = styled.p.attrs({
  className: `${(props) => props.className}`,
  id: `${(props) => props.id}`,
})`
  color: red;
  font-size: 16px;
  font-weight: bold;
  font-weight: 400;
  word-break: break-word;
  user-select: text;
  text-align: center;
  margin: 6px 0 20px;
`

export const TrainerProfileDetailsSection1InputLabel = styled(
  ClientDetailsOverviewInputLabel
)`
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const OrangeButton = styled.button`
  background: #f8a492;
  border-radius: 8px;
  cursor: pointer;
  width: 100%;
  border: none;
  outline: none;
  box-shadow: none;
  width: 200px;
  height: 38px;
  color: white;
  font-size: 15px;
  margin: 0 auto;
  display: block;

  &:disabled {
    background: lightgray;
    color: black;
    cursor: not-allowed;
  }
`

export const VisualArea = styled.div`
  position: relative;
  .image-bg {
    width: 100%;
  }
  .image-hand {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 9;
  }
`

export const ImageOne = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  transform: rotate(5deg);
  border: 5px solid white;
  border-radius: 10px;
  box-shadow: 2px 0px 6px 0px #e3e7ea;
  z-index: 1;
  .image-one {
    width: 190px;
    height: 250px;
  }
`

export const ImageTwo = styled.div`
  position: absolute;
  top: 85px;
  left: 20px;
  border-radius: 10px;
  transform: rotate(-5deg);
  .image-two {
    width: 170px;
    height: 230px;
  }
`

export const StartDay = styled.span`
  background: #142c1f;
  border-radius: 3px;
  padding: 5px 10px;
  color: white;
  position: absolute;
  top: 15px;
  left: 15px;
`

export const Week = styled.span`
  background: #2ecf96;
  border-radius: 3px;
  padding: 5px 10px;
  color: white;
  position: absolute;
  top: 15px;
  left: 15px;
`

export const Title = styled.p`
  color: #142c1f;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  margin: 40px 0 25px;
`

export const ShareItems = styled.div`
  display: grid;
  gap: 5px;
  grid-template-columns: 1fr 1fr 1fr;
  margin-bottom: 15px;
`

export const ShareItem = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: #a3b5af24;
  border: 2px solid #a3b5af24;
  border-radius: 8px;
  /* backdrop-filter: blur(30px);
  -webkit-backdrop-filter: blur(30px); */
  height: 90px;

  img {
    width: 20px;
  }

  span {
    color: #a5b5af;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
  }

  ${(props) =>
    props.isActive &&
    `
      border-color: #2ecf96;
      background: #F3FFFD;
      
      span {
        color: #142C1F;
      }
    `}
`
