import React from 'react';
import { useHistory } from "react-router-dom";
import * as S from "./healthScoreImprovement.styles";
import { getRandomValueInRange } from "configs/utils";

// Components
import TranslationMessage from 'configs/translations/TranslationMessage';
import Topbar from 'components/topbar/Topbar';
import Card from 'components/card/Card';

// Images
import GreenCheck from "assets/images/GreenCheck.svg";

const HealthScoreImprovement = () => {
    const history = useHistory();

    const CardData = [
        {
            id: "card1",
            heading: "healthScore.improvement.card.heading.1",
            benefits: [
                "healthScore.improvement.card.minerals.benefits1",
                "healthScore.improvement.card.minerals.benefits2",
                "healthScore.improvement.card.minerals.benefits3",
                "healthScore.improvement.card.minerals.benefits4"
            ]
        },
        {
            id: "card2",
            heading: "healthScore.improvement.card.heading.2",
            benefits: [
                "healthScore.improvement.card.vitamins.benefits1",
                "healthScore.improvement.card.vitamins.benefits2",
                "healthScore.improvement.card.vitamins.benefits3",
                "healthScore.improvement.card.vitamins.benefits4"
            ]
        },
        {
            id: "card3",
            heading: "healthScore.improvement.card.heading.3",
            benefits: [
                "healthScore.improvement.card.fattyAcids.benefits1",
                "healthScore.improvement.card.fattyAcids.benefits2",
                "healthScore.improvement.card.fattyAcids.benefits3",
                "healthScore.improvement.card.fattyAcids.benefits4"
            ]
        },
    ]


    return (
        <>
            {/* healthReport */}
            <Topbar prev="/healthscore/tips" next="/premiumCoaching" progress={'55%'} percent={getRandomValueInRange(40, 45)} onClose={() => history.push('/login')} close />
            <S.HealthScoreImprovementContainer>
                <S.HealthScoreImprovementHeading><TranslationMessage id="healthScore.improvement.heading" /></S.HealthScoreImprovementHeading>
                <S.HealthScoreImprovementSubHeading><TranslationMessage id="healthScore.improvement.subHeading" /></S.HealthScoreImprovementSubHeading>
                <S.HealthScoreImprovementCardContainer>
                    {CardData.map((card) => {
                        return (
                            <Card
                                width="295px"
                                height="360px"
                                margin="auto"
                                textAlign="left"
                                key={card.id}
                            >
                                <S.HealthScoreImprovementCardInnerContainer>
                                    <S.HealthScoreCardHeading><TranslationMessage id={card.heading} /></S.HealthScoreCardHeading>
                                    {card.benefits.map(benefit => {
                                        return (
                                            <S.BenefitsContainer>
                                                <S.ChecklistElementGreenTickContainer>
                                                    <img src={GreenCheck} alt="GreenCheck" />
                                                </S.ChecklistElementGreenTickContainer>
                                                <S.Benefits >
                                                    <TranslationMessage id={benefit} />
                                                </S.Benefits>
                                            </S.BenefitsContainer>
                                        )
                                    })}
                                </S.HealthScoreImprovementCardInnerContainer>
                            </Card>
                        )
                    })}
                </S.HealthScoreImprovementCardContainer>
            </S.HealthScoreImprovementContainer>
        </>
    )
}

export default HealthScoreImprovement
