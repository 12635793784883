import styled from "styled-components";
import RightArrow from "assets/images/active-arrow.png";
export const InnerMenuWrap = styled.div`
  width: 100%;
  max-width: 300px;
  height: 100%;
  background-color: #f5f5f5;
  margin-right: 30px;
  overflow-y: auto;
`;

export const InnerMenuTop = styled.div`
  padding: 1rem 2rem;
  border-bottom: 1px solid #eeeeee;

  a {
    text-decoration: none !important;
    color: #44554c;
    font-size: 1rem;
    line-height: 19px;
    display: inline-flex;
    font-weight: 400;
    align-items: center;
    font-weight: 400 !important;
  }
`;

export const InnerMenuMid = styled.div`
  /* border-top: 1px solid #e3e3e3; */
  border-bottom: 1px solid #e3e3e3;
  padding: 24px;
  display: flex;
  align-items: center;
`;

export const InnerMenuInfo = styled.div`
  h5 {
    font-weight: 500;
    font-size: 17px;
    line-height: 20px;
    color: #142c1f;
    margin-bottom: 8px;
    margin-left: 15px;
  }
  p {
    font-size: 15px;
    line-height: 18px;
    color: #44554c;
    margin-bottom: 0px;
    font-weight: 400;
    span {
      color: #a5b5af;
    }
    margin-left: 15px;

    &.change-password {
      margin-left: 0;
      display: flex;
      gap: 0.5rem;
      color: #44554c;
      font-weight: 400;
      cursor: pointer;
    }
  }

  .id-item {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 0.5rem;

    img {
      background: #ffffff;
      box-shadow: 0px 1px 2px #5258660f;
      border: 1px solid #e2e4e9;
    }

    p,
    p span,
    img {
      color: #44554c;
      font-weight: 300;
      font-size: 0.875rem;
      padding: 0.5rem 0.8rem;
    }

    p {
      margin-left: 0;
      border-radius: 1rem;
      margin-top: 0;
      width: 100%;
      padding-left: 0;

      div {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-left: 0;
        max-width: 10.625rem;
      }

      strong {
        font-weight: 500;
      }
    }
    img {
      border-radius: 0.625rem;
      cursor: pointer;
      width: 2rem;
      padding: 0.5rem;
    }
  }
`;

export const InnerMenuLast = styled.div`
  padding: 24px;
  display: flex;
  flex-direction: column;
  h6 {
    letter-spacing: 0.8px;
    color: #142c1f;
    text-transform: uppercase;
    font-size: 16px;
    line-height: 19px;
    font-weight: 500;
    margin-bottom: 20px;
  }
  a {
    color: ${(props) => (props.active ? "#1C9D87" : "#44554C")};
    cursor: pointer;
    font-size: 15px;
    line-height: 18px;
    font-weight: 400;
    margin-bottom: 8px;
    position: relative;
    padding: 8px 15px;
    border: 1px solid transparent;
    text-decoration: none;

    &:hover {
      color: #1c9d87;
      box-shadow: 0px 1px 2px #1018280d;
      border-color: #d0d5dd;
      border-radius: 10px;
      background: white;
    }

    &:after {
      content: "";
      position: absolute;
      right: 6px;
      top: 10px;
      background-image: ${(props) =>
        props.active ? `url("${RightArrow}")` : "none"};
      width: 16px;
      height: 16px;
    }

    &.active-link {
      color: #1c9d87;
      box-shadow: 0px 1px 2px #1018280d;
      border-color: #d0d5dd;
      border-radius: 10px;
      background: white;
      &:after {
        background-image: url("${RightArrow}");
      }
    }

    .new-tab-icon {
      width: 12px;
      margin-left: 4px;
    }
  }
  .MuiPaper-root {
    box-shadow: none !important;
    background-color: transparent;
    margin-bottom: 20px;
  }
  .MuiAccordionSummary-root {
    padding: 0px;
    height: 19px;
    min-height: 19px;
    &:hover {
      .MuiAccordionSummary-content p,
      .MuiIconButton-root {
        color: #2ecf96;
      }
    }
  }
  .MuiAccordionSummary-content {
    margin: 0px;
  }
  .MuiIconButton-root {
    padding: 0px;
    margin-right: 0px;
    height: 19px;
  }

  .MuiAccordionDetails-root {
    padding: 8px 16px 16px 20px;
    display: flex;
    flex-direction: column;
    a {
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0px;
      }
    }
  }
  .MuiAccordion-root:before {
    display: none;
  }
  .MuiAccordionSummary-content {
    p {
      color: #44554c;
      font-size: 16px;
      line-height: 19px;
      font-weight: 400;
    }
  }
  .MuiAccordion-root.Mui-expanded {
    margin: 0px;
  }
  .MuiAccordionSummary-expandIcon {
    transform: rotate(270deg) !important;
  }
  .MuiAccordionSummary-expandIcon.Mui-expanded {
    transform: rotate(360deg) !important;
  }
`;
