export const MENUS = [
  {
    id: 1,
    title: "Mein Profil",
    path: "/dashboard/profile",
  },
  {
    id: 5,
    title: "Rechnungsdaten",
    path: "/dashboard/billing",
  },
  {
    id: 2,
    title: "Kündigung",
    path: "/dashboard/termination",
  },
  {
    id: 3,
    title: "Gutschriften",
    // path: "/dashboard/trainer-invoices",
    path: "/dashboard/trainer-credits",
  },
  {
    id: 4,
    title: "Vertrag verwalten",
    path: "",
    url: "https://billing.stripe.com/p/login/00g7uY57p1mf8eI4gg",
  },
  {
    id: 5,
    title: "Passwort ändern",
    path: "",
    url: "",
    isModal: true,
  },
  // {
  //   id: 3,
  //   title: "Gutschriften - V2",
  //   path: "/dashboard/trainer-credits",
  // },
];
