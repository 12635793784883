import ApiService from ".";

export const BASE_URL = () => {
  if (process.env.REACT_APP_ENV === "development")
    return process.env.REACT_APP_BASE_URL_DEVELOPMENT;
  else if (process.env.REACT_APP_ENV === "staging")
    return process.env.REACT_APP_BASE_URL_STAGING;
  else if (process.env.REACT_APP_ENV === "production") {
    return process.env.REACT_APP_BASE_URL_PRODUCTION;
  }
};

const FILE_BASE_URL = () => {
  if (process.env.REACT_APP_ENV === "development")
    return process.env.REACT_APP_FILES_DEVELOPMENT_BASE_URL;
  else if (process.env.REACT_APP_ENV === "staging")
    return process.env.REACT_APP_FILES_STAGING_BASE_URL;
  else if (process.env.REACT_APP_ENV === "production")
    return process.env.REACT_APP_FILES_PRODUCTION_BASE_URL;
};

const FILE_BASE_URL_S3 = () => {
  if (process.env.REACT_APP_ENV === "development")
    return process.env.REACT_APP_FILES_DEVELOPMENT_BASE_URL_S3;
  else if (process.env.REACT_APP_ENV === "staging")
    return process.env.REACT_APP_FILES_STAGING_BASE_URL_S3;
  else if (process.env.REACT_APP_ENV === "production")
    return process.env.REACT_APP_FILES_PRODUCTION_BASE_URL_S3;
};

const trainer = new ApiService({ baseURL: BASE_URL(), loginType: "trainer" });
const client = new ApiService({ baseURL: BASE_URL(), loginType: "user" });
const ApiCollections = {
  // *********** Auth Module ********
  trainerSignUp: (params) => client.imgPost("trainerSignup", params),
  // signIn: (params) => client.imgPost("trainerLogin", params),
  signIn: (params) => client.imgPost("coach/login", params),

  passwordReset: (params) => client.imgPost("coach/reset-password", params),
  existingPlanSignIn: (params) => client.imgPost("trainerUserLogin", params),
  getWeekWiseImages: (params) =>
    client.postWithoutAuthorization(
      BASE_URL(),
      "coachdashboard/clients/userImagesWeekWise/list",
      params
    ),
  getBodyMeasurements: (params) =>
    client.postWithoutAuthorization(
      BASE_URL(),
      "coachdashboard/clients/measurementList",
      params
    ),
  getNutritionTypePercentageChartData: (params) =>
    client.postWithoutAuthorization(
      BASE_URL(),
      "coachdashboard/clients/nutritiontype/nutritionTypePercentageData",
      params
    ),
  // getBodyMeasurements: () => client.get("app/user/measurements"),
  // getBodyMeasurements: () => client.get("measurementData"),
  // getWeekWiseWeight: (params) => client.post("app/getWeekWiseWeight/user", params),
  getWeekWiseWeight: (params) =>
    client.postWithoutAuthorization(
      BASE_URL(),
      "coachdashboard/clients/getUserWeight/weekWise",
      params
    ),
  getUserWeightGoals: (params) =>
    client.postWithoutAuthorization(
      BASE_URL(),
      "coachdashboard/clients/getUserWeight/data",
      params
    ),
  updateGoalWeight: (params) =>
    client.post("app/trainers/userGoalWeight/create", params),
  updateSelectedGoal: (params) =>
    client.postWithoutAuthorization(
      BASE_URL(),
      "coachdashboard/clients/aim-goal/change",
      params
    ),

  checkStripeConnectedAccountStatus: (params) =>
    client.post("trainer/connectedAccountStatus", params),

  changePassword: (params) => client.post("reset-password", params),
  resetPassword: (params) => client.post("coach/changePassword", params),
  setPassword: (params) => client.post("user/changePassword", params),
  courseChangePassword: (params) =>
    client.post("user_course/changePassword", params),
  registrationPart1: (params) => client.post("register", params),
  registrationOverrideEmail: (params) => client.post("updateEmail", params),
  getFaqQuestions: (params) => client.get("trainerFaq/getAllQuestionsAnswers"),
  getFaqQuestionsById: (params) =>
    client.post("trainerFaq/getAllQuestionsAnswersByTopicID", params),

  getNutritionCourseDetailssById: (params) =>
    client.post("app/coachingNutritionCourse/getCourseDetailsByUserID", params),
  updateNutritionCourseDetails: (params) =>
    client.post(
      "app/coachingNutritionCourse/updateNutritionCousePoints",
      params
    ),
  updateOrDeleteNutritionCourseDetails: (params) =>
    client.post("updateOrDeleteTagData", params),

  checkUserIdEmail: (params) => client.post("checkUserIdEmail", params),
  getMarketingPortalAssets: (params) =>
    client.get("marketingportal/assets/overview"),
  getMarketingPortalPrints: (params) =>
    client.get("marketingportal/prints/overview"),
  getMarketingPortalPrintsDetails: (params) =>
    client.post("marketingportal/prints/details", params),

  getMarketingPortalPartnerDetails: (params) =>
    client.post("marketingportal/partner/overview", params),
  getMarketingPortalPartnerAndPrintDetails: (params) =>
    client.post("marketingportal/partnerAndPrint/details", params),

  marketingPortalCreateAffiliate: (params) =>
    client.post("create-affiliate", params),
  marketingPortalAddAffiliateProgram: (params) =>
    client.post("add-affiliate-program", params),

  createQrCode: (params) =>
    client.originalPost(
      "https://admin.foodiary.app/api/qr-code/create.php",
      params,
      "7b9a1f3c0e5d8a2b6f0c9e2a1d3e9b4c8d1a6f3c5e0d8a2b6f0c9e2a1d3e9b4c8"
    ),
  marketingPortalCreatePartner: (params) =>
    client.post("marketingportal/partner/create", params),

  getMarketingPortalPartners: (params) =>
    client.post("marketingportal/partner/list", params),
  marketingPortalCoachDetails: (params) =>
    client.post("marketingportal/coach/details", params),

  marketingPortalPrintPic: (params) => client.post("trainer/printPic", params),
  marketingPortalRawUrlByPrintId: (params) =>
    client.post("marketingportal/prints/rawURL", params),
  marketingPortalPrintPlacementDetails: (params) =>
    client.post("marketingportal/prints/placementDetails", params),
  marketingPortalCreatePrint: (params) =>
    client.originalPost(
      "https://admin.foodiary.app/api/marketingportal/create_print.php",
      params,
      "7b9a1f3c0e5d8a2b6f0c9e2a1d3e9b4c8d1a6f3c5e0d8a2b6f0c9e2a1d3e9b4c8"
    ),
  marketingPortalCreatePartnerPrint: (params) =>
    client.post("marketingportal/partnerPrint/create", params),

  getAllRefunds: () => client.get("app/coachingNutritionCourse/getAllRefunds"),

  createUserAnamnese: (params) =>
    client.post("app/user_Anamnese/create", params),
  getUserAnamnese: (params) =>
    client.postWithoutAuthorization(
      BASE_URL(),
      "coachdashboard/clients/anamneseList",
      params
    ),

  getAddressDetails: (params) => trainer.post("trainer/address", params),
  updateAddressDetails: (params) =>
    trainer.post("trainer/address/update", params),
  getTrainerProfile: (params) => trainer.post("app/trainers/showData", params),
  trainerDetailsEdit: (params) =>
    trainer.imgPost("app/user/trainerUpdate", params),
  getNutritionToken: (params) =>
    trainer.post("app/trainers/getNutritionConceptToken", params),
  getUsersByTrainer: (params) =>
    trainer.post(
      "app/trainers/getUsersListByTrainer?page=" + params.page,
      params.formData
    ),
  getInvoicesByTrainer: (params) =>
    trainer.post(
      `app/trainers/getInvoiceListByTrainer${
        params.page ? "?page=" + params.page : ""
      }`,
      params.formData
    ),
  getInvoicesByUser: (params) =>
    trainer.post("app/trainers/getUserInvoiceListByTrainer", params.formData),
  getUsersDetailsByTrainer: (params) =>
    trainer.post("app/trainers/getUsersDetails", params),
  addDayRoutineForUserByTrainer: (params) =>
    trainer.postWithoutAuthorization(
      BASE_URL(),
      "coachdashboard/clients/nutritionconcept/insertDayRoutines",
      params
    ),
  getClientDetails: (params) =>
    trainer.postWithoutAuthorization(
      BASE_URL(),
      "coachdashboard/clients/getUserDetails",
      params
    ),
  updateUserDetailsByTrainer: (params) =>
    trainer.postWithoutAuthorization(
      BASE_URL(),
      "coachdashboard/clients/updateUserDetails",
      params
    ),

  getTrainerDetails: (params) => trainer.post("app/trainers/showData", params),
  // app/trainers/trainerDetails

  updateTrainerDetails: (params) =>
    trainer.imgPost("app/trainers/updateTrainerDetails", params),

  getCoachCardWebsiteDetails: (params) =>
    trainer.getWithParam("coachdashboard/website/setup", params),
  updateCoachCardWebsiteDetails: (params) =>
    trainer.postWithoutAuthorization(
      BASE_URL(),
      "coachdashboard/website/setup",
      params
    ),

  getCoachCardDetails: (params) =>
    trainer.getWithParam("coachdashboard/website/coachcard", params),
  updateCoachCardDetails: (params) =>
    trainer.postWithoutAuthorization(
      BASE_URL(),
      "coachdashboard/website/coachcard",
      params
    ),

  addCoachCardQualification: (params) =>
    trainer.imgPost("trainerqualifications/create", params),
  updateCoachCardQualification: (params) =>
    trainer.imgPost("trainerqualifications/update", params),
  removeCoachCardQualification: (params) =>
    trainer.imgPost("trainerqualifications/delete", params),
  getCoachCardQualification: (params) =>
    trainer.imgPost("app.foodiary.app/api/trainerqualifications", params),

  createWebsite: (params) =>
    trainer.imgPost("coach_website_url/update", params),
  trainerFeedback: (params) => trainer.imgPost("app/trainers/feedback", params),
  getDaysRoutineForUserByTrainer: (params) =>
    trainer.postWithoutAuthorization(
      BASE_URL(),
      "coachdashboard/clients/nutritionconcept/getDayRoutines",
      params
    ),
  updateDaysRoutineForUserByTrainer: (params) =>
    trainer.postWithoutAuthorization(
      BASE_URL(),
      "coachdashboard/clients/nutritionconcept/updateDayRoutines",
      params
    ),
  insertDayRoutineForUserByTrainer: (params) =>
    trainer.postWithoutAuthorization(
      BASE_URL(),
      "coachdashboard/clients/nutritionconcept/insertDayRoutines",
      params
    ),
  deleteDayRoutineForUserByTrainer: (params) =>
    trainer.postWithoutAuthorization(
      BASE_URL(),
      "coachdashboard/clients/nutritionconcept/deleteDayRoutines",
      params
    ),
  getWeightGraphDetails: (params) =>
    trainer.post("app/trainers/showTrainer", params),
  getInfluencers: (params) => trainer.post("influencersList", params),
  getInfluencerDetails: (params) => trainer.post("influencerData", params),
  getFirstMeetingURLByTrainerID: (params) =>
    trainer.post("getFirstMeetingURL", params),
  getWeeklyCalls: () => client.get("trainer/weeklys/list"),
  updateConnectedAccount: (id) => client.post("trainerConnectedAccount", id),
  updateUserConnectedAccount: (id) =>
    client.post("trainerConnectedAccountforUser", id),

  // *********** Academy ********
  getLessonsCategories: () => client.get("trainerAcademy/getAllCategories"),
  getLessonsByCategoryId: (params) =>
    trainer.post("trainerAcademy/getAllLessonsByCategoryID", params),
  getLessonsDetails: (params) =>
    trainer.post("trainerAcademy/getLessonVideoByLessonID", params),
  getDownloadableLessons: (params) =>
    trainer.post("trainerAcademy/getDownloadedFileByLessonID", params),

  // *********** Social Media ********
  getAllInstagramCategories: () =>
    client.get("trainerInstagram/getAllCategories"),
  getAllInstagramPosts: (params) =>
    client.get(`trainerInstagram/getAllPosts?page=${params?.payload?.pageNo}`),
  getAllInstagramPostsByCategories: (params) =>
    trainer.post(
      `trainerInstagram/getAllPostsByCategory?page=${params?.payload?.pageNo}`,
      params?.payload
    ),
  getInstagramSubCategoriesByCategory: (params) =>
    trainer.post("trainerInstagram/getSubCategoriesByParent", params),

  getAllLinkedinCategories: () =>
    client.get("trainerLinkedin/getAllCategories"),
  getAllLinkedinPosts: (params) =>
    client.get(`trainerLinkedin/getAllPosts?page=${params?.payload?.pageNo}`),
  getAllLinkedinPostsByCategories: (params) =>
    trainer.post(
      `trainerLinkedin/getAllPostsByCategory?page=${params?.payload?.pageNo}`,
      params?.payload
    ),
  getLinkedinSubCategoriesByCategory: (params) =>
    trainer.post("trainerLinkedin/getSubCategoriesByParent", params),

  // *********** Dashboard Module ********
  getAllWhitePaperData: () =>
    trainer.get("trainerAcademy/getAllWhitePaperThumbData"),
  getTrainerProfileTempDetails: (params) =>
    trainer.post("app/trainers/trainerProfileTempDetails", params),
  getTrainerOnBoardingChecks: (params) =>
    trainer.post("trainerOnboardingCheck/getTrainerOnboardingChecks", params),
  updateTrainerOnBoardingCheck: (params) =>
    trainer.post("trainerOnboardingCheck/updateTrainerOnboardingCheck", params),
  getActiveClientsLastDays: (params) =>
    trainer.post("trainerStatistics/getActiveClientsLastDays", params),
  getInitialDiscussionChartData: (params) =>
    trainer.post("trainerStatistics/getfirstMeetingsMonth", params),
  getActiveClientsChartData: (params) =>
    trainer.postWithoutAuthorization(
      BASE_URL(),
      "coachdashboard/dashboard/getNumberOfClients",
      params
    ),
  getSalesChartData: (params) =>
    trainer.post("trainerStatistics/getSalesMonth", params),
  // getUserTransaction: (params) =>
  //   trainer.post("app/getTransactionDataByUser", params),
  getUserTransaction: (params) =>
    trainer.post("trainer/getUserOrderTransactionData", params),

  // createHabbit: (params) => trainer.post("app/user/habit_tracker/create", params),
  // createJournal: (params) => trainer.post("app/user/journal/create", params),
  getHabbit: (params) =>
    trainer.postWithoutAuthorization(
      BASE_URL(),
      "coachdashboard/clients/userHabitTrackerData",
      params
    ),
  getJournal: (params) =>
    trainer.postWithoutAuthorization(
      BASE_URL(),
      "coachdashboard/clients/userJournalData",
      params
    ),
  getExperts: () => trainer.get("trainer/weeklys/list"),
  getUserWeightData: (params) =>
    trainer.postWithoutAuthorization(
      BASE_URL(),
      "coachdashboard/clients/getUserWeight/data",
      params
    ),
  getTrainerInvoices: (params) =>
    trainer.originalGet(
      "https://admin.foodiary.app/trainer-credits/get-credits.php?key=7b9a1f3c0e5d8a2b6f0c9e2a1d3e9b4c8d1a6f3c5e0d8a2b6f0c9e2a1d3e9b4c8&id=" +
        params?.trainerId
    ),
  getTrainerCredits: (params) =>
    trainer.originalPostJson(
      "https://admin.foodiary.app/api/trainer_credits/get_monthly_transactions_pdfs.php",
      params,
      "oHI0CLQm3M8BAoU37EmRqlHRqRcz7MBStBPy2zYqUoV7A1EdFEts3p8lkESxSM0l"
    ),
  sendEmail: (params) => trainer.post("sendTemplateData", params),
  getTrainerNews: () => trainer.get("trainerNews/list"),

  // *********** Nutrition Module ********

  getUserData: () => client.get("app/nutritionconcept"),
  getNutritionType: (params) =>
    client.postWithoutAuthorization(
      BASE_URL(),
      "coachdashboard/clients/nutritiontype/show",
      params
    ),
  getAllNutritionTypes: (params) =>
    client.postWithoutAuthorization(
      BASE_URL(),
      "coachdashboard/clients/nutritiontype/nutritionTypeList",
      params
    ),
  updateNutritionType: (params) =>
    client.postWithoutAuthorization(
      BASE_URL(),
      "coachdashboard/clients/nutritiontype/nutritionTypeUpdate",
      params
    ),
  updateNutritionCalories: (params) =>
    client.postWithoutAuthorization(
      BASE_URL(),
      "coachdashboard/clients/nutritiontype/caloriesUpdate",
      params
    ),
  getNutritionAdvantages: (params) =>
    client.postWithoutAuthorization(
      BASE_URL(),
      "coachdashboard/clients/nutritiontype/advantagesData",
      params
    ),
  getNutritionRecipes: (params) =>
    client.postWithoutAuthorization(
      BASE_URL(),
      "coachdashboard/clients/nutritiontype/receipeData",
      params
    ),
  getWeekWiseCaloriesData: (params) =>
    client.postWithoutAuthorization(
      BASE_URL(),
      "coachdashboard/clients/nutritiontype/weekWiseData",
      params
    ),
  getAllCaloriesData: (params) =>
    client.postWithoutAuthorization(
      BASE_URL(),
      "coachdashboard/clients/nutritiontype/caloriesData",
      params
    ),
  getMacroNutrientsDonutData: (params) =>
    client.postWithoutAuthorization(
      BASE_URL(),
      "coachdashboard/clients/nutritiontype/getPieChartData",
      params
    ),
  getMacroNutrientsPieChartData: (params) =>
    client.postWithoutAuthorization(
      BASE_URL(),
      "coachdashboard/clients/nutritiontype/getPieChartData",
      params
    ),
  // *********** Health Score Module ********

  getSupplements: () => client.get("app/coachSupplement"),
  addProUserDataMailChimp: (params) =>
    client.post("app/mailchimpdata/addProUserDataMailchimp", params),
  addBasicUserDataMailChimp: (params) =>
    client.post("app/mailchimpdata/addBasicUserDataMailchimp", params),
  deleteMailChimpUserDetails: (params) =>
    client.post("app/mailchimpdata/deleteUserDetails", params),
  updateUserSubscription: (params) => client.post("app/coach/stripe", params),

  getTrainerLeads: (params) => client.post("trainerLeads/list", params),
  getNumberOfLeads: (params) =>
    client.postWithoutAuthorization(
      BASE_URL(),
      "coachdashboard/dashboard/getNumberOfLeads",
      params
    ),

  getTrainerLeadDetails: (params) =>
    client.post("trainerLeads/details", params),
  triggerTrainerEmail: (params) =>
    client.originalPost(
      "https://admin.foodiary.app/api/mandrill/send_transactional_mail.php",
      params,
      "7b9a1f3c0e5d8a2b6f0c9e2a1d3e9b4c8d1a6f3c5e0d8ahwrthetw2b6f0c9e2a1d3e9b4c8"
    ),
  updateTrainerLead: (params) => client.post("trainerLeads/update", params),

  getFoodiaryLeads: () => client.get("foodiaryleads"),
  getFoodiaryLeadDetails: (params) =>
    client.post("foodiaryleadsdetails", params),
  claimFoodiaryLead: (params) =>
    client.post("update/foodiaryleadsclaim", params),
  leadUtlization: (params) =>
    client.post(`leads/${params.lead_id}/claim`, {
      trainer_id: params.trainer_id,
    }),
  getLeadCenterStatus: (params) => client.post("getleadcenterstatus", params),
  getLeadAuthorization: (params) =>
    client.get(`leads/details?trainer_id=${params.trainer_id}`),
  getLeadHistory: (params) =>
    client.get(
      `leads/history?trainer_id=${params.trainer_id}&year=${params.year}`
    ),
  createLeadCenter: (params) =>
    client.postWithoutAuthorization(
      BASE_URL(),
      "coachdashboard/leadscenter/create",
      params
    ),
  updateLeadCenter: (params) =>
    client.postWithoutAuthorization(
      BASE_URL(),
      "coachdashboard/leadscenter/update",
      params
    ),
  updatePartnerClaim: (params) =>
    client.postWithoutAuthorization(
      BASE_URL(),
      "coachdashboard/marketingportal/claimpartner",
      params
    ),
  updateUserInformation: (params) => client.put("user-info/update", params),
};

export { FILE_BASE_URL, FILE_BASE_URL_S3 };
export default ApiCollections;
