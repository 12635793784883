import styled from 'styled-components';
import {RegistrationContainer ,RegistrationHeading, RegistrationSubHeading} from 'pages/registration/registration.styles';

export const NutritionFormContainer = styled(RegistrationContainer)``;

export const NutritionFormHeading = styled(RegistrationHeading)``;

export const NutritionFormSubHeading = styled(RegistrationSubHeading)``;

export const NutritionFormDescription = styled.div`
    font-size: 16px;
    font-family: RubikRegular;
    color: rgba(68, 68, 68, .6);
    line-height: 23px;
    margin: 40px 25px 30px 25px;
    text-align: left;
`;

export const NutritionFormCardContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: ${props => props.justify ? props.justify : "space-between"};
`;

export const NutritionFormCard = styled.div`
    width: 172px;
    height: 151px;
    cursor: pointer;
    background: ${props => {
        if(props.backgroundColor) return props.backgroundColor 
        else if(props.selected) return "#F3FFFD" 
        else return "#F5F5F5";}};
    text-align: center;
    margin-top: 10px;
    border-radius: 10px;
    color: #142C1F;
    border: ${props => {
        if(props.selected) return "2px solid #2ECF96" 
        else if(props.backgroundColor) return "2px solid white"
        else return "2px solid #F5F5F5";}};
`;

export const NutritionCardImage = styled.img`
    margin: 33px 67px 19px;
`;