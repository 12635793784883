import React from 'react';
import { useSelector } from "react-redux";
import * as S from "./trainerProfile.styles";
import { findAge } from 'configs/utils';

// Components
import Card from 'components/card/Card';

// Images
import GreenCheck from "assets/images/GreenCheck.svg";


const TrainerProfile = ({ editProfile, setEditProfile, profileImage }) => {
    const trainerProfileData = useSelector(state => state.auth.trainerData);

    return (
        <Card
            width="703px"
            height="495px"
            margin="auto"
            textAlign="left"
        >
            {trainerProfileData && <S.TrainerDetailsContainer>
                <S.TrainerHeading>Das bin ich</S.TrainerHeading>
                <S.TrainerName>{trainerProfileData.prename} {trainerProfileData.surname}, {findAge(trainerProfileData.birthday)} Jahre</S.TrainerName>
                <S.TrainerDescription>{trainerProfileData.description}</S.TrainerDescription>
                <S.ChecklistElementContainer>
                    <S.ChecklistElementGreenTick src={GreenCheck} />
                    <strong style={{ marginRight: 10 }}>Coach für: </strong>{trainerProfileData.subtitle}
                </S.ChecklistElementContainer>
                <S.ChecklistElementContainer>
                    <S.ChecklistElementGreenTick src={GreenCheck} />
                    <strong style={{ marginRight: 10 }}>Spezialgebiet: </strong>{trainerProfileData.step1}
                </S.ChecklistElementContainer>
                <S.ChecklistElementContainer>
                    <S.ChecklistElementGreenTick src={GreenCheck} />
                    <strong style={{ marginRight: 10 }}>Ernährungsberater seit: </strong>{trainerProfileData.step2}
                </S.ChecklistElementContainer>
                <S.ChecklistElementContainer>
                    <S.ChecklistElementGreenTick src={GreenCheck} />
                    <strong style={{ marginRight: 10 }}>Ausbildungen: </strong>{trainerProfileData.step3}
                </S.ChecklistElementContainer>
                <S.ChecklistElementContainer>
                    <S.ChecklistElementGreenTick src={GreenCheck} />
                    <strong style={{ marginRight: 10 }}>Praxiserfahrung: </strong>{trainerProfileData.step4}
                </S.ChecklistElementContainer>
            </S.TrainerDetailsContainer>}
        </Card>
    )
}

export default TrainerProfile
