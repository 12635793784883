export const MENUS = [
    {
      id: 1,
      title: "Ticket eröffnen",
      path: "/dashboard/support/open-ticket",
    },
    {
      id: 5,
      title: "Häufige Fragen",
      path: "/dashboard/support/faq",
    },
    {
      id: 4,
      title: "Kostenrückerstattung",
      path: "/dashboard/support/health-insurance-reimbursement",
    },
    {
      id: 2,
      title: "Ansprechpartner",
      path: "/dashboard/support/contact-person",
      url: "https://wa.me/1741953597",
    },
    {
      id: 3,
      title: "Changelog",
      path: "/dashboard/support/submit-feedback",
      url: "https://foodiary.sleekplan.app",
    },
    // {
    //   id: 4,
    //   title: "Roadmap",
    //   path: "/dashboard/support/roadmap",
    //   url: 'https://wa.me/1741953597'
    // },
  ];