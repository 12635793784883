import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useAlert } from "react-alert";
import { useParams, useHistory } from "react-router-dom";

import LinkIcon from "assets/images/link.svg";

import * as AuthActions from "redux/auth/auth.actions";
import * as DashboardActions from "redux/dashboard/dashboard.actions";
import * as S from "./clientDetails.styles";
import "./clientDetails.scss";
import Loader from "react-spinners/CircleLoader";
import { FadeLoader } from "react-spinners";

const Invoices = ({ clientDetails, onDetails }) => {
  const [showLoader, setShowLoader] = useState(false);
  const dispatch = useDispatch();
  const alert = useAlert();
  const { userId } = useParams();
  const history = useHistory();

  const trainerData = useSelector((state) => state.auth.trainerData);
  const usersRelatedToTrainer = useSelector(
    (state) => state.auth.usersRelatedToTrainer
  );

  const loading = useSelector((state) => state.auth.loading);
  const userInvoice = useSelector((state) => state.auth.userInvoice);
  console.log("userInvoice", userInvoice);

  const details = usersRelatedToTrainer?.data?.find(
    (data) => data.userID === +userId
  );

  const [selectedInvoice, setSelectedInvoice] = useState(null);

  useEffect(() => {
    dispatch(
      AuthActions.getInvoicesOfUserByTrainerRequest({
        formData: {
          trainerID: trainerData.trainerID,
          userID: userId,
        },
      })
    );
  }, []);

  const renderStatusBadge = (status) => {
    switch (status) {
      case "Completed":
        return (
          <div className="status-badge text-center green">Erfolgreich</div>
        );

      case "Incomplete":
        return (
          <div className="status-badge text-center orange-yellow">
            Unvollständig
          </div>
        );

      case "Canceled":
        return <div className="status-badge text-center red">Storniert</div>;

      case "Pending":
        return <div className="status-badge text-center grey">Ausstehend</div>;

      case "Refunded":
        return (
          <div className="status-badge text-center red">Zurückerstattet</div>
        );
      case "Processing":
        return (
          <div className="status-badge text-center orange-yellow">
            Wird bearbeitet
          </div>
        );
      default:
        return null;
    }
  };

  const handleDetailsClick = (invoice) => {
    // window.location.href = `/dashboard/transfer?userId=${userId}&invoiceId=${invoice.id}`;
    // history.push(
    //   `/dashboard/transfer?userId=${userId}&invoiceId=${invoice?.id}`
    // );

    history.push(
      `${window.location.pathname}?userId=${userId}&invoiceId=${invoice?.id}`
    );
  };

  return (
    <>
      <S.ContainerBox>
        <S.ContainerBoxWithPadding>
          <S.Heading>Abrechnung</S.Heading>
          <S.SubHeading>
            Detaillierte Übersicht des Zahlungsverlaufes mit deinem Klienten.
          </S.SubHeading>
        </S.ContainerBoxWithPadding>

        <S.Divider></S.Divider>
        <S.PaddingBox>
          <S.TableWrapper>
            {loading && (
              <>
                <br />
                <br />
                <br />
                <br />
                <div className="text-center">
                  <div className="loader mx-auto"></div>
                </div>
                <br />
                <br />
                <br />
                <br />
              </>
            )}

            {!loading && (
              <table>
                <thead>
                  <tr>
                    <th>Produktname</th>
                    <th>Erstellt</th>
                    <th>Geldeingang</th>
                    <th>Status</th>
                    <th>Gesamtbetrag</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {userInvoice &&
                    Object.entries(userInvoice).map(([key, value]) => (
                      <tr key={value.product_name}>
                        <td>
                          <p>{value.product_name}</p>
                        </td>
                        <td>
                          <div className="contact-person">
                            <p>
                              {value.invoice_created_at
                                .split("-")
                                .reverse()
                                .join("/")}
                            </p>
                          </div>
                        </td>
                        <td>
                          <p>
                            {value.invoice_money_arrived_at
                              ? value.invoice_money_arrived_at
                                  .split("-")
                                  .reverse()
                                  .join("/")
                              : "-"}
                          </p>
                        </td>
                        <td>{renderStatusBadge(value.payment_status)}</td>
                        <td>
                          <p>{value.invoice_amount} €</p>
                        </td>
                        <td>
                          <button
                            className="btn-outline sm cursor-pointer"
                            onClick={() => {
                              // history.push(
                              //   window.location.pathname +
                              //     `?userId=${userId}&invoiceId=${value.id}`
                              // );
                              handleDetailsClick(value);
                              onDetails(value);
                            }}
                          >
                            Details
                          </button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            )}
          </S.TableWrapper>
        </S.PaddingBox>
      </S.ContainerBox>
      <br />

      {selectedInvoice && (
        <S.ContainerBox>
          {showLoader ? (
            <div className="d-flex justify-content-center align-items-center">
              <FadeLoader />
            </div>
          ) : (
            <S.InvoiceDetailsContainer>
              <br />

              <S.InvoiceDetailsHeader>
                <h6>Abrechnungsdetails</h6>

                <div className="d-flex align-items-center gap-3">
                  <span>Transaktionsstatus</span>

                  {renderStatusBadge(selectedInvoice.payment_status)}
                </div>
              </S.InvoiceDetailsHeader>

              <br />

              <S.Divider></S.Divider>

              <S.DataSection>
                <h6>Timestamps</h6>

                <div className="data-rows">
                  <div className="data-row">
                    <p>Rechnung erstellt am:</p>

                    <p>
                      {selectedInvoice.invoice_created_at
                        .split("-")
                        .reverse()
                        .join("/")}
                    </p>
                  </div>

                  <div className="data-row">
                    <p>Bezahlt am:</p>

                    <p>
                      {selectedInvoice.invoice_money_arrived_at
                        ? selectedInvoice.invoice_money_arrived_at
                            .split("-")
                            .reverse()
                            .join("/")
                        : "noch nicht verfügbar"}
                    </p>
                  </div>

                  <div className="data-row">
                    <p>Geldeingang am:</p>

                    <p>
                      {selectedInvoice.invoice_money_arrived_at
                        ? selectedInvoice.invoice_money_arrived_at
                            .split("-")
                            .reverse()
                            .join("/")
                        : "noch nicht verfügbar"}
                    </p>
                  </div>
                </div>
              </S.DataSection>

              <S.Divider></S.Divider>

              <S.DataSection>
                <h6>Bezahldetails</h6>

                <div className="data-rows">
                  <div className="data-row">
                    <p>Gesamtbetrag</p>

                    <p>{selectedInvoice.invoice_amount} €</p>
                  </div>

                  <div className="data-row">
                    <p>Transaktionsgebühr</p>

                    <p>{selectedInvoice.transaction_fee}</p>
                  </div>

                  <div className="data-row">
                    <p>Höhe der Betreuungsgutschrift</p>

                    <p>{selectedInvoice.amount_transferred}</p>
                  </div>
                </div>

                <span className="note">
                  *Werbepartnerschaften werden gesondert abgerechnet
                </span>
              </S.DataSection>

              <S.Divider></S.Divider>

              <S.DataSection>
                <h6>Bezahldetails</h6>

                <div className="data-rows">
                  <div className="data-row">
                    <p>ID der Rechnung</p>

                    <p>{selectedInvoice.invoice_id}</p>
                  </div>

                  <div className="data-row">
                    <p>Quellentransaktion</p>

                    <p>
                      {selectedInvoice.source_transaction_id
                        ? selectedInvoice.source_transaction_id
                        : "noch nicht verfügbar"}
                    </p>
                  </div>

                  <div className="data-row">
                    <p>ID des Transfers</p>

                    <p>
                      {selectedInvoice.transfers_id
                        ? selectedInvoice.transfers_id
                        : "noch nicht verfügbar"}
                    </p>
                  </div>

                  <div className="data-row">
                    <p>ID der Auszahlung</p>

                    <p>
                      {selectedInvoice.payout_id
                        ? selectedInvoice.payout_id
                        : "noch nicht verfügbar"}
                    </p>
                  </div>
                </div>
              </S.DataSection>

              <S.Divider></S.Divider>

              <S.DataSection>
                <div className="d-flex align-items-center justify-content-between">
                  <h6>Bezahldetails</h6>

                  <div className="link-box">
                    <img src={LinkIcon} alt="" />

                    <a
                      href={selectedInvoice.payment_link_url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {selectedInvoice.payment_link_url}
                    </a>
                  </div>
                </div>

                <div className="d-flex align-items-center justify-content-between">
                  <h6>Transaktionsbeleg</h6>

                  <div className="link-box">
                    <img src={LinkIcon} alt="" />

                    <a
                      href={selectedInvoice.payment_link_url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {selectedInvoice.payment_link_url}
                    </a>
                  </div>
                </div>
              </S.DataSection>

              <br />
            </S.InvoiceDetailsContainer>
          )}
        </S.ContainerBox>
      )}
    </>
  );
};

export default Invoices;
