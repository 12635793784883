import React, { useState } from 'react';
import * as S from "./collapsibleAccordion.styles";

// Images
import Expand from "assets/images/expand.svg";
import Contract from "assets/images/contract.svg";
import TranslationMessage from 'configs/translations/TranslationMessage';
import Button from 'components/formComponents/button/Button';

const CollapsibleAccordion = ({ data, onUpdate }) => {

    const [activeAccordion, setActiveAccordion] = useState(data.length > 0 ? data[0].id : '0');

    return (
        <S.AccordionContainer>
            {data.map(accordion => {
                return (
                    <div style={{display: 'flex', gap: '1rem', marginInline: 'auto'}}>
                        <S.Accordion style={{marginInline: '0px'}} active={activeAccordion === accordion.id} onClick={() => setActiveAccordion(accordion.id)} key={accordion.id}>
                            <S.AccordionHeading>{accordion.heading}</S.AccordionHeading>
                            <S.AccordionIcon
                                src={activeAccordion === accordion.id ? Contract : Expand}
                                lt={activeAccordion === accordion.id ? "Contract" + activeAccordion : "Expand" + activeAccordion}
                            />

                            <S.AccordionContent active={activeAccordion === accordion.id}>
                                {accordion.content()}
                            </S.AccordionContent>
                        </S.Accordion>
                        <div>
                        <Button
                            color="#1C9D87"
                            absolute={{ width: 'auto' }}
                            buttonStyle={{ height: '53px' }}
                            onClick={() => onUpdate(accordion)}
                        >
                            <TranslationMessage id="button.updateAnotherDailyRoutine" />
                        </Button>
                        </div>
                    </div>
                )
            })
            }
            {data.length === 0 && <S.NoDataFound active><TranslationMessage id="message.noDataFound" /></S.NoDataFound>}
        </S.AccordionContainer>
    )
}

export default CollapsibleAccordion
