import React from 'react';
import * as S from './budgetGraph.styles';

const BudgetGraph = (props) => {
    const budgetValues =  [-560, -285, 0];
    const currentStatus = "translate(" + budgetValues[props.id] + ')';
    return (
        <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="600" height="256.718" viewBox="-15 0 600 256.718">
                <g id="Group_6434" data-name="Group 6434" transform="translate(-349.983 -369)">
                    <rect id="Rectangle_570" data-name="Rectangle 570" width="570.855" height="7.418" rx="2" transform="translate(352.837 610.883)" fill="#ededed"/>
                    <rect id="Rectangle_1623" data-name="Rectangle 1623" width="5.563" height="22.254" rx="2" transform="translate(624.6 603.465)" fill="#ededed"/>
                    <rect id="Rectangle_1625" data-name="Rectangle 1625" width="5.563" height="22.254" rx="2" transform="translate(918.129 603.465)" fill="#ededed"/>
                    <rect id="Rectangle_1817" data-name="Rectangle 1817" width="5.563" height="22.254" rx="2" transform="translate(353 603.465)" fill="#ededed"/>
                    <line id="Line_19" data-name="Line 19" x2="571" transform="translate(353 463)" fill="none" stroke="#ededed" stroke-width="1" stroke-dasharray="5"/>
                    <line id="Line_21" data-name="Line 21" x2="571" transform="translate(353 532)" fill="none" stroke="#ededed" stroke-width="1" stroke-dasharray="5"/>
                    <line id="Line_20" data-name="Line 20" x2="571" transform="translate(353 394)" fill="none" stroke="#ededed" stroke-width="1" stroke-dasharray="5"/>
                    <g id="Group_6433" data-name="Group 6433" transform={currentStatus}>
                        <animateTransform attributeType="XML" attributeName="transform" type="translate" from="-600" to={budgetValues[props.id]} dur="1s"/>
                        <path id="Path_28025" data-name="Path 28025" d="M0,0V249" transform="translate(918 369)" fill="none" stroke="#2ecf96" stroke-width="2" stroke-dasharray="5"/>
                        <g id="Ellipse_164" data-name="Ellipse 164" transform="translate(903.164 595)" fill="#fff" stroke="#2ecf96" stroke-width="5">
                            <circle cx="14.836" cy="14.836" r="14.836" stroke="none"/>
                            <circle cx="14.836" cy="14.836" r="12.336" fill="none"/>
                        </g>
                    </g>
                    <line id="Line_23" data-name="Line 23" y1="138" x2="571" transform="translate(353 394)" fill="none" stroke="#1c9d87" stroke-linecap="round" stroke-width="5"/>
                </g>
            </svg>
            <S.BudgetGraphPricingLabelContainer>
                <S.BudgetGraphLabel1 active={props.id === 0}>1,50 €</S.BudgetGraphLabel1>
                <S.BudgetGraphLabel2 active={props.id === 1}>4,50 €</S.BudgetGraphLabel2>
                <S.BudgetGraphLabel3 active={props.id === 2}>8,00 €</S.BudgetGraphLabel3>
            </S.BudgetGraphPricingLabelContainer>
        </div>
    )
}

export default BudgetGraph
