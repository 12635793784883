import styled from "styled-components";

export const EvaluationCard = styled.div`
  background: #ffffff;
  box-shadow: 0px 1px 2px #1018280d;
  border: 1px solid #d0d5dd;
  border-radius: 10px;
  padding: 30px;

  .evaluation-item {
    position: relative;

    .checked {
      position: absolute;
      top: 0.1rem;
      right: 0;
      width: 1.3rem;
    }

    .unchecked {
      position: absolute;
      right: 3px;
      top: 5px;
      width: 1.1rem;
    }
  }

  .title {
    color: #142c1f;
    font-size: 18px;
    line-height: 22px;
    margin-block: 0;
  }

  .desc {
    color: #44554c;
    font-size: 16px;
    line-height: 24px;
    margin: 10px 0 20px;
    font-weight: 400;
  }

  .eval-desc {
    margin-top: 25px;
    color: #a5b5af;
    font-size: 14px;
    font-weight: 300;
    line-height: 18px;
    font-weight: 400;
  }

  .evaluation-items {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    gap: 5px;

    .evaluation-item {
      width: 70px;
      height: 70px;
      padding: 5px;
      border: 2px solid transparent;
      border-radius: 15px;
      cursor: pointer;
      background: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      box-shadow: 0px 2px 5px #00000014;

      p {
        margin: 0;
        font-size: 14px;
        color: #142c1f;
        font-weight: 400;
      }

      &.active {
        border-color: #2ecf96;
        background: #f3fffd;

        p {
          color: #818d88;
        }
      }

      img.icon {
        margin-top: 8px;
        width: 26px;
        height: 26px;
      }
    }
  }
`;
