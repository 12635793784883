import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useAlert } from "react-alert";
import { useParams, useHistory } from "react-router-dom";
import Select from "react-select";
import {
  getS3ImageUrl,
  compareValuesWithMissingBodyMeasurements,
  processBodymeasurements,
} from "configs/utils";
import ReactApexChart from "react-apexcharts";
import moment from "moment";

import LineChart from "components/lineChart/LineChart";
import ShareSocialMediaModal from "components/ShareSocialMediaModal/ShareSocialMediaModal";

import ShareIcon from "assets/images/new/Icon-feather-share.svg";
import UserPlaceholder from "assets/images/default-picture.jpg";
import ArrowUpIcon from "assets/images/new/arrow-circle-up.svg";
import ArrowDownIcon from "assets/images/new/arrow-circle-down.svg";
import ArrowEqualIcon from "assets/images/new/arrow-circle-equal.svg";
import LinkIcon from "assets/images/link.svg";
import ClientDetailsSidebar from "./ClientDetailsSidebar";

import * as AuthActions from "redux/auth/auth.actions";
import * as DashboardActions from "redux/dashboard/dashboard.actions";
import * as S from "./ClientDetails.styles";
import "./clientDetails.scss";
import Loader from "react-spinners/CircleLoader";
import { FadeLoader } from "react-spinners";

const ProgressAndMeasurements = () => {
  const [showLoader, setShowLoader] = useState(false);
  const dispatch = useDispatch();
  const alert = useAlert();
  const { userId } = useParams();
  const history = useHistory();

  const trainerData = useSelector((state) => state.auth.trainerData);
  const clientDetails = useSelector(
    (state) => state.auth.getClientDetails?.data
  );
  const isClientDetailsLoading = useSelector(
    (state) => state.auth.isClientDetailsLoading
  );

  const weekWiseImages = useSelector((state) => state.auth.weekWiseImages);
  const bodyMeasurements = useSelector((state) => state.auth.bodyMeasurements);
  console.log("bodyMeasurements", bodyMeasurements);

  const weekWiseWeight = useSelector((state) => state.auth.weekWiseWeight);

  const caloriesData = useSelector(
    (state) => state.nutrition.caloriesLineChartData
  );
  const [shareOnSocialMediaModal, setShareOnSocialMediaModal] = useState(false);
  const [weekOptions, setWeekOptions] = useState([]);
  const [weekZeroImage, setWeekZeroImage] = useState(null);
  const [selectedWeeklyImage, setSelectedWeeklyImage] = useState(null);
  const [isChartDataLoading, setIsChartDataLoading] = useState(false);

  const [calculatedBodyMeasurements, setCalculatedBodyMeasurements] = useState(
    []
  );

  const [options, setOptions] = useState({
    series: [
      {
        name: "Gewicht",
        data: [], // Will be filled with API data
      },
    ],
    chart: {
      height: 350,
      type: "area",
    },
    colors: ["#1b9c86"],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      categories: [], // Will be filled with the time categories
    },
    tooltip: {},
  });

  const weekWiseWeightList = [
    { label: "1", x: 1, y: weekWiseWeight?.data?.["1"] || 0 },
    { label: "2", x: 2, y: weekWiseWeight?.data?.["2"] || 0 },
    { label: "3", x: 3, y: weekWiseWeight?.data?.["3"] || 0 },
    { label: "4", x: 4, y: weekWiseWeight?.data?.["4"] || 0 },
    { label: "5", x: 5, y: weekWiseWeight?.data?.["5"] || 0 },
    { label: "6", x: 6, y: weekWiseWeight?.data?.["6"] || 0 },
    { label: "7", x: 7, y: weekWiseWeight?.data?.["7"] || 0 },
    { label: "8", x: 8, y: weekWiseWeight?.data?.["8"] || 0 },
    { label: "9", x: 9, y: weekWiseWeight?.data?.["9"] || 0 },
    { label: "10", x: 10, y: weekWiseWeight?.data?.["10"] || 0 },
    { label: "11", x: 11, y: weekWiseWeight?.data?.["11"] || 0 },
    { label: "12", x: 12, y: weekWiseWeight?.data?.["12"] || 0 },
  ];

  // console.log("weekWiseWeight", weekWiseWeight);

  const lineChartStatus = caloriesData
    ? (caloriesData["12"] - caloriesData["1"]).toFixed(2) + " kcal"
    : "0 kcal";

  useEffect(() => {
    const images = weekWiseImages?.data
      ?.filter((item) => item.weekID !== 0)
      .map((item) => ({
        id: item.weekID,
        label: `Woche ${item.weekID}`,
        value: `Week ${item.weekID}`,
        ...item,
      }));
    setWeekZeroImage(weekWiseImages?.data?.find((item) => item.weekID === 0));
    setWeekOptions(images);
  }, [weekWiseImages]);

  useEffect(() => {
    setIsChartDataLoading(true);
    dispatch(
      AuthActions.getClientDetailsRequest({
        payload: {
          trainerID: trainerData?.trainerID,
          userID: +userId,
        },
        callback: (res) => {
          console.log(res);
        },
      })
    );

    dispatch(
      AuthActions.getBodyMeasurementsRequest({
        formData: {
          userID: userId,
        },
        callback: (res) => {
          const result = processBodymeasurements(res?.data || {});
          setCalculatedBodyMeasurements(result);
        },
      })
    );

    dispatch(
      AuthActions.getWeekWiseImagesRequest({
        formData: {
          userID: userId,
        },
        callback: () => null,
      })
    );

    dispatch(
      AuthActions.getUserWeightWeekWiseRequest({
        formData: {
          userID: userId,
        },
        callback: (response) => {
          console.log("response", response);
          setIsChartDataLoading(false);

          const seriesData = Object.values(response?.data);
          const categoriesData = Object.keys(response?.data);
          const maxValue = Math.max(...seriesData);

          const roundedMaxValue = Math.ceil(maxValue + 20);

          // Calculate the tick amount, spacing the ticks by 20
          const tickAmount = Math.ceil(roundedMaxValue / 20);

          setOptions((prevOptions) => ({
            ...prevOptions,
            series: [
              {
                ...prevOptions.series[0],
                data: seriesData,
              },
            ],
            xaxis: {
              ...prevOptions.xaxis,
              categories: categoriesData,
            },
            yaxis: {
              min: 0,
              max: roundedMaxValue,
              tickAmount: tickAmount,
              labels: {
                formatter: (value) => Math.round(value),
              },
            },
          }));
        },
      })
    );
  }, []);

  const months = {
    1: "Jan",
    2: "Feb",
    3: "Mar",
    4: "April",
    5: "May",
    6: "Jun",
    7: "Jul",
    8: "Aug",
    9: "Sep",
    10: "Oct",
    11: "Nov",
    12: "Dec",
  };

  return (
    <>
      <S.ClientDetailsOverallContainer>
        <S.ClientDetailsContainer>
          <div className="second-sidebar-wrapper">
            <ClientDetailsSidebar />
          </div>
          <div className="second-sidebar-content" style={{ padding: "1.5rem" }}>
            <S.ContainerBox>
              <S.Header>
                <div>
                  <S.Heading>Verlauf & Fortschritt</S.Heading>
                  <S.SubHeading>
                    Verfolge den Fortschritt deines Klienten.
                  </S.SubHeading>
                </div>
              </S.Header>

              <S.Divider></S.Divider>

              {isChartDataLoading && (
                <>
                  <br />
                  <br />
                  <br />
                  <br />
                  <div className="text-center">
                    <div className="loader mx-auto"></div>
                  </div>
                  <br />
                  <br />
                  <br />
                  <br />
                </>
              )}
              {!isChartDataLoading && (
                <>
                  <S.PaddingBox>
                    <div className="container-70rem">
                      <S.TargetDateRange>
                        {months[+clientDetails?.startdate?.substring(5, 7)]}{" "}
                        {clientDetails?.startdate?.substring(0, 4)} -{" "}
                        {months[+clientDetails?.locked?.substring(5, 7)]}{" "}
                        {clientDetails?.locked?.substring(0, 4)}
                      </S.TargetDateRange>
                      <div className="client-line-chart-box">
                        <div
                          className="apex-clients-calories-chart"
                          id="apex-clients-calories-chart"
                        >
                          <S.LineChartContainer>
                            <S.LineChartHeading>
                              Gewichtsverlauf
                            </S.LineChartHeading>
                            <span style={{ flexGrow: 1 }}> </span>
                            {/* <S.LineChartStatus>
                              {lineChartStatus} <br />
                              <S.LineChartDescription>
                                in 12 Wochen
                              </S.LineChartDescription>
                            </S.LineChartStatus> */}
                          </S.LineChartContainer>
                          <br />
                          <ReactApexChart
                            options={options}
                            series={options.series}
                            type="area"
                            height={350}
                          />
                        </div>
                        {/* <LineChart
                      heading="Grundumsatz"
                      status={lineChartStatus}
                      description="in 12 Wochen"
                      fontSize={13}
                      precision={0}
                      padding={50}
                      width={863}
                      height={200}
                      data={weekWiseWeightList}
                      horizontalGuideLines={3}
                      yAxisBetweenRange
                      doNotShowReport
                    /> */}
                      </div>
                      {/* <S.TargetInfo>
                <S.OrangeLine></S.OrangeLine>
                <S.TargetInfoTitle>Ziel:</S.TargetInfoTitle>
                <S.TargetInfoValue>{`${userWeightGoalData?.data?.goal_weight} Kg`}</S.TargetInfoValue>
              </S.TargetInfo> */}
                    </div>
                  </S.PaddingBox>
                  <S.Divider></S.Divider>
                  <S.PaddingBox>
                    <div className="container-70rem">
                      <S.ProgressHeadingWrap>
                        <S.ProgressHeading>Körpermaße</S.ProgressHeading>
                        {/* <S.ChipBox>
                    <S.Chip isActive>Aktuell</S.Chip>
                    <S.Chip>Entwicklung</S.Chip>
                  </S.ChipBox> */}
                      </S.ProgressHeadingWrap>
                      <br />
                      <br />
                      <S.ProgressBody>
                        <S.StatsRow style={{ marginBottom: 0 }}>
                          <div></div>
                          <S.StatDate>
                            {moment(
                              new Date(calculatedBodyMeasurements?.[0]?.date)
                            ).format("DD.MM.YYYY")}
                          </S.StatDate>
                          <S.StatProgress>Fortschritt</S.StatProgress>
                        </S.StatsRow>
                        <S.StatsRow style={{ marginBottom: 0 }}>
                          <div></div>
                          <S.StatDate>
                            {moment(
                              new Date(calculatedBodyMeasurements?.[0]?.date)
                            ).format("DD.MM.YYYY")}
                          </S.StatDate>
                          <S.StatProgress>Fortschritt</S.StatProgress>
                        </S.StatsRow>
                      </S.ProgressBody>
                      <S.ProgressBody>
                        {/* <S.StatsCol> */}

                        {calculatedBodyMeasurements?.map((stat, i) => (
                          <S.StatsRow key={i}>
                            <S.StatTitleBox>{stat?.name}</S.StatTitleBox>
                            {!stat?.isEmpty && (
                              <S.StatValueBox>{`${stat?.value} cm`}</S.StatValueBox>
                            )}
                            <S.StatCalculationBox>
                              <>
                                {stat?.valueStatus === "none" && (
                                  <img src={ArrowEqualIcon} alt="arrow" />
                                )}
                                {stat?.valueStatus === "increasing" && (
                                  <img src={ArrowUpIcon} alt="arrow" />
                                )}
                                {stat?.valueStatus === "decreasing" && (
                                  <img src={ArrowDownIcon} alt="arrow" />
                                )}
                                <span>
                                  {stat?.valueDifference < 0 &&
                                    `${Math.abs(stat?.valueDifference)}`}
                                  {stat?.valueDifference >= 0 &&
                                    `${stat?.valueDifference}`}
                                </span>
                              </>
                            </S.StatCalculationBox>
                          </S.StatsRow>
                        ))}

                        {/* <S.StatsRow>
                    <S.StatTitleBox>Oberarm</S.StatTitleBox>
                    <S.StatValueBox>96 cm</S.StatValueBox>
                    <S.StatCalculationBox>
                      <img src={ArrowDownIcon} alt="arrow" />
                      <span>-2</span>
                    </S.StatCalculationBox>
                  </S.StatsRow> */}
                        {/* </S.StatsCol>
                  <S.StatsCol> */}
                        {/* <S.StatsRow style={{ marginBottom: 0 }}>
                      <div></div>
                      <S.StatDate>19.08.2022</S.StatDate>
                      <S.StatProgress>Fortschritt</S.StatProgress>
                    </S.StatsRow> */}

                        {/* </S.StatsCol> */}
                      </S.ProgressBody>
                    </div>
                  </S.PaddingBox>
                  <S.Divider></S.Divider>
                  <S.PaddingBox>
                    <div className="container-70rem">
                      <S.VisualProgressHeadingWrap>
                        <S.VisualProgressHeading>
                          Vorher / Nachher{" "}
                        </S.VisualProgressHeading>
                        <div className="upload-image-and-image-select">
                          <Select
                            classNamePrefix="react-select"
                            options={weekOptions}
                            value={selectedWeeklyImage}
                            isSearchable={false}
                            onChange={(e) => {
                              setSelectedWeeklyImage(e);
                            }}
                          />
                          <button
                            className="btn-share btn-upload-image"
                            onClick={() => setShareOnSocialMediaModal(true)}
                          >
                            <img src={ShareIcon} alt="share" /> Teilen
                          </button>
                        </div>
                      </S.VisualProgressHeadingWrap>
                      <S.ProgressImages>
                        {weekZeroImage && (
                          <S.ProgressImageItem>
                            <S.StartDay>
                              {weekZeroImage?.weekID} Woche
                            </S.StartDay>
                            <img
                              src={getS3ImageUrl(weekZeroImage?.image)}
                              alt="Woche-zero"
                            />
                          </S.ProgressImageItem>
                        )}

                        {!weekZeroImage && (
                          <S.ProgressImageItem>
                            <S.StartDay>Start</S.StartDay>
                            <img src={UserPlaceholder} alt="Woche-zero" />
                          </S.ProgressImageItem>
                        )}

                        {selectedWeeklyImage && (
                          <S.ProgressImageItem>
                            <S.Week>{selectedWeeklyImage?.weekID} Woche</S.Week>
                            <img
                              src={getS3ImageUrl(selectedWeeklyImage?.image)}
                              alt="selected-Woche"
                            />
                          </S.ProgressImageItem>
                        )}
                      </S.ProgressImages>
                    </div>
                  </S.PaddingBox>
                </>
              )}
            </S.ContainerBox>
          </div>
        </S.ClientDetailsContainer>
      </S.ClientDetailsOverallContainer>
      <ShareSocialMediaModal
        show={shareOnSocialMediaModal}
        onClose={() => setShareOnSocialMediaModal(false)}
      />
    </>
  );
};

export default ProgressAndMeasurements;
