import { createGlobalStyle } from "styled-components";
import RubikRegular from "assets/fonts/Rubik-Regular.ttf";
import RubikMedium from "assets/fonts/Rubik-Medium.ttf";
import RubikLight from "assets/fonts/Rubik-Light.ttf";
import ExoRegular from "assets/fonts/Exo-Regular.ttf";
import ExoSemiBold from "assets/fonts/Exo-SemiBold.ttf";

const GlobalStyles = createGlobalStyle`
    @font-face {
        font-weight: 400;
        src: url(${RubikRegular}) format('truetype')
    }
    @font-face {
        font-family: 'RubikMedium';
        src: url(${RubikMedium}) format('truetype')
    }
    @font-face {
        font-family: 'RubikLight';
        src: url(${RubikLight}) format('truetype')
    }
    @font-face {
        font-family: 'ExoRegular';
        src: url(${ExoRegular}) format('truetype')
    }
    @font-face {
        font-family: 'ExoSemiBold';
        src: url(${ExoSemiBold}) format('truetype')
    }
    body {
        margin: 0;
        padding: 0;
        
        margin: auto;
        background: #fafafa;
        font-weight: 400;
        overflow-x: hidden;
        /* -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none; */
        @media (min-width: 1024px) {
            min-width: 1024px;
        }

        * {
            /* outline: 1px solid red; */
            box-sizing: border-box;
        }
    }
    
    @keyframes progress {
        0% {
            stroke-dasharray: 0 100;
        }
    }

    @keyframes toast-in-right {
        from {
            transform: translateX(100%);
        }
        to {
            transform: translateX(0);
        }
    }

    @keyframes toast-in-left {
        from {
            transform: translateX(-100%);
        }
        to {
            transform: translateX(0);
        }
    }

    .input {
        border: rgba(165,181,175,.15) 2px solid;
    }

    .TimePicker-header-4, .Clock-innerDot-17, .Clock-pointer-14 {
        background-color: #1C9D87 !important;
    }

    .Clock-outerDot-18 {
        border: 16px solid #1C9D87 !important;
    }

    .MuiButton-textPrimary {
        color: #1C9D87 !important;
    }

    .react-multi-carousel-list {
        position: inherit;
    }

    .carousel-item-padding-30-px {
        margin-left: 30px;
    }

    .health-score-tips-carousel {
        margin-left: -10px;
    }
    
    .premium-coaching-success-carousel {
        margin-left: 100px;
    }

    .table-styled{
        width:100%;
        margin:0px !important;
        padding-right: 0;
    }
`;

export default GlobalStyles;
