import React from "react";
import * as S from "./AcademyWrapper.style";
import Academy from "./Academy/Academy";
import InnerSideNav from "pages/NewDasboard/components/InnerSideNav/InnerSide";
import { MENUS } from "./data";

const AcademyWrapper = ({ children }) => {
  return (
    <>
      <S.Container>
        <div className="second-sidebar-wrapper">
          <InnerSideNav menus={MENUS} hideUserInfo></InnerSideNav>
        </div>
        <div className="second-sidebar-content">
          { children ? children : <Academy /> }
        </div>
      </S.Container>
    </>
  );
};

export default AcademyWrapper;
