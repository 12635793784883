import React, {useEffect} from 'react';
import { useDispatch, useSelector } from "react-redux";
import {useHistory} from 'react-router-dom';
import { Formik } from "formik";
import { string, object } from "yup";
import * as S from "./orderDetails.styles";
import moment from 'moment';

// Components
import TranslationMessage from 'configs/translations/TranslationMessage';
import Topbar from 'components/topbar/Topbar';
import SquareCheckbox from 'components/formComponents/checkbox/SquareCheckbox';
import Input from 'components/formComponents/input/Input';
import ErrorMessage from 'components/formComponents/errorMessage/ErrorMessage';
import Button from 'components/formComponents/button/Button';

// Redux
import * as SupplementsActions from 'redux/supplements/supplements.actions';

function OrderDetails() {
    const dispatch = useDispatch();
    const history = useHistory();
    const supplementsData = useSelector(state => state.supplements.supplements);
    const registrationData = useSelector(state => state.auth.registrationData);
    const healthScoreData = useSelector(state => state.auth.healthScoreData);

    if(!healthScoreData.foodiaryPro) history.push('/subscriptions')

    useEffect(() => {
        if(supplementsData.length === 0) dispatch(SupplementsActions.getSupplementsRequest())
    }, [supplementsData])
    
    const initialValues = {
        address: "",
        account_holder: "",
        IBAN: "",
        BIC: "",
    };

    const validationSchema = object().shape({
        address: string().required("Required"),
        account_holder: string().required("Required"),
        IBAN: string().required("Required"),
        BIC: string().required("Required"),
    });
    const orderSuccessRedirection = () => history.push('/foodiary/family')

    const handleFoodiarySubscription = (values, { resetForm }) => {
        const supplementPaymentArray = [];
        let calculatedConversionAmount = 0;
        supplementsData.map((supplement, index) => {
            if(healthScoreData.supplementsStatus[index]) {
                calculatedConversionAmount = calculatedConversionAmount + (supplement.monthly_price * 4 * .2);
                supplementPaymentArray.push({ 
                    userID: registrationData.userID,
                    trainerID: registrationData.trainerID,
                    supplementID: supplement.id,
                    date: moment().format('YYYY-MM-DD'),
                    subscribe_status: 'PRO-USER', 
                    payment_type: healthScoreData.payment_type, 
                    ...values
                })
            }
            return true;
        })
        const formValues = {supplements_data: supplementPaymentArray}
        dispatch(SupplementsActions.updateUserSubscriptionRequest({
            formValues: formValues, 
            func: orderSuccessRedirection, 
            affiliateData: {userID: registrationData.userID, email: registrationData.email, conversionAmount: calculatedConversionAmount}
        }))
        resetForm()
    }

    const handleSubscription = () => {
        document.getElementById("ibanSubmit").click();
    }

    const handlePrev = () => {
        // handlePrev
    }

    return (
        <>
            <Topbar prev="/overview" next="/subscription/details" nextButtonId="foodiaryPro" onPrev={handlePrev} redirect onNext={handleSubscription} progress={'90%'} nextButtonValue={<TranslationMessage id="button.launch" />} />
            <S.OrderDetailsFormContainer>
                <S.OrderDetailsDataContainer>
                    <S.OrderDetailsDataInnerContainer>
                        <S.OrderDetailsFormHeading>
                            <TranslationMessage id="orderDetails.heading" />
                        </S.OrderDetailsFormHeading>
                        <S.OrderDetailsFormSubHeading>
                            <TranslationMessage id="orderDetails.subHeading" />
                        </S.OrderDetailsFormSubHeading>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={handleFoodiarySubscription}
                        >
                            {({handleSubmit, handleChange, handleBlur, values, touched, errors, isValid, dirty,}) => ( 
                            <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                                <>
                                    <S.OrderDetailsInputContainer>
                                        <Input filled style={{ width: '288px', marginBottom: "1em" }} placeholder="Adresse" name="address" onChange={handleChange} value={values.address} />
                                        <ErrorMessage error={errors.address} />
                                        <Input filled style={{ width: '288px', marginBottom: "1em" }} placeholder="Kontoinhaber" name="account_holder" onChange={handleChange} value={values.account_holder} />
                                        <ErrorMessage error={errors.account_holder} />
                                        <Input filled style={{ width: '288px', marginBottom: "1em" }} placeholder="IBAN" name="IBAN" onChange={handleChange} value={values.IBAN} />
                                        <ErrorMessage error={errors.IBAN} />
                                        <Input filled style={{ width: '288px', marginBottom: "1em" }} placeholder="BIC" name="BIC" onChange={handleChange} value={values.BIC}/>
                                        <ErrorMessage error={errors.BIC} />
                                    </S.OrderDetailsInputContainer>
                                    <S.OrderDetailsRulesContainer>
                                        <SquareCheckbox rounded='7px' filled="#F5F5F5" />
                                        <S.OrderDetailsRulesLabel>
                                            <TranslationMessage id="orderDetails.termsAndConditions" />
                                        </S.OrderDetailsRulesLabel>
                                    </S.OrderDetailsRulesContainer>
                                    <Button type='submit' style={{display: 'none'}} id="ibanSubmit"> Save </Button>
                                </>
                            </form>
                        )}
                    </Formik>
                    </S.OrderDetailsDataInnerContainer>
                </S.OrderDetailsDataContainer>
            </S.OrderDetailsFormContainer>
        </>
    )
}

export default OrderDetails
