import React, {useState} from 'react';

import Topbar from 'components/topbar/Topbar';
import Card from 'components/card/Card';
import * as S from "./whatAwaitsYou.styles";

// Images
import One from "assets/images/one.svg";
import Two from "assets/images/two.svg";
import Three from "assets/images/three.svg";
import Iphone from "assets/images/iphone.png";
import TranslationMessage from 'configs/translations/TranslationMessage';

const WhatAwaitsYou = () => {
    const [active, setActive] = useState(0);
    
    const CardData = [
        {id: "card1", image: One, heading: "whatAwaitsYou.card.heading.1", subHeading: "whatAwaitsYou.card.subHeading.1"},
        {id: "card2", image: Two, heading: "whatAwaitsYou.card.heading.2", subHeading: "whatAwaitsYou.card.subHeading.2"},
        {id: "card3", image: Three, heading: "whatAwaitsYou.card.heading.3", subHeading: "whatAwaitsYou.card.subHeading.3"},
    ]

    const AppFunctionCards = [
        {id: "appFunction1", heading: "whatAwaitsYou.appFunctions.heading.1", subHeading: "whatAwaitsYou.appFunctions.subHeading.1"},
        {id: "appFunction2", heading: "whatAwaitsYou.appFunctions.heading.2", subHeading: "whatAwaitsYou.appFunctions.subHeading.2"},
        {id: "appFunction3", heading: "whatAwaitsYou.appFunctions.heading.3", subHeading: "whatAwaitsYou.appFunctions.subHeading.3"},
        {id: "appFunction4", heading: "whatAwaitsYou.appFunctions.heading.4", subHeading: "whatAwaitsYou.appFunctions.subHeading.4"},
        {id: "appFunction5", heading: "whatAwaitsYou.appFunctions.heading.5", subHeading: "whatAwaitsYou.appFunctions.subHeading.5"},
        {id: "appFunction6", heading: "whatAwaitsYou.appFunctions.heading.6", subHeading: "whatAwaitsYou.appFunctions.subHeading.6"},
    ]
    return (
        <>
            <Topbar prev="/new-consultation" next="/registration/gender" />
            <S.WhatAwaitsYouContainer>
                <S.WhatAwaitsYouNavTabContainer>
                    <S.WhatAwaitsYouNavTab active={active === 0 ? true : false} onClick={() => setActive(0)}>
                        <TranslationMessage id="button.whatAwaitsYou" />
                    </S.WhatAwaitsYouNavTab>
                    <S.WhatAwaitsYouNavTab active={active === 1 ? true : false} onClick={() => setActive(1)}>
                        <TranslationMessage id="button.appFunctions" />
                    </S.WhatAwaitsYouNavTab>
                </S.WhatAwaitsYouNavTabContainer>
                {active === 0 && <S.WhatAwaitsYouCardContainer>
                    {CardData.map((card, index) => {
                        return (
                            <Card 
                                width="295px"
                                height="auto"
                                margin="auto"
                                textAlign="center"
                                key={card.id}
                            >
                                <S.WhatAwaitsYouCardInnerContainer>
                                    <S.WhatAwaitsYouTopImage src={card.image} alt="One" />
                                    <S.WhatAwaitsYouCardHeading><TranslationMessage id={card.heading} /></S.WhatAwaitsYouCardHeading>
                                    <S.WhatAwaitsYouCardSubHeading><TranslationMessage id={card.subHeading} /></S.WhatAwaitsYouCardSubHeading>
                                </S.WhatAwaitsYouCardInnerContainer>
                            </Card>
                        )
                    })}
                </S.WhatAwaitsYouCardContainer>}
                {active === 1 && <S.AppFunctionsContainer>
                        <S.AppFunctionsImageContainer>
                            <S.IPhoneImage src={Iphone} alt="Iphone" />
                        </S.AppFunctionsImageContainer>
                        <S.AppFunctionsDetailsContainer>
                            {AppFunctionCards.map((card) => {
                                return (
                                    <S.AppFunctionsDetailsCardContainer key={card.id}>
                                <S.AppFunctionsDetailsCardHeading><TranslationMessage id={card.heading} /></S.AppFunctionsDetailsCardHeading>
                                <S.AppFunctionsDetailsCardSubHeading><TranslationMessage id={card.subHeading} /></S.AppFunctionsDetailsCardSubHeading>
                            </S.AppFunctionsDetailsCardContainer>
                                )
                            })}
                        </S.AppFunctionsDetailsContainer>
                    </S.AppFunctionsContainer>}
            </S.WhatAwaitsYouContainer>
        </>
    )
}

export default WhatAwaitsYou
