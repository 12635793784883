import styled from "styled-components";
import Tick from "assets/images/GreenCheck.svg";
export const DatabaseContainer = styled.div`
  width: 1200px;
  margin: 50px auto auto auto;
  box-sizing: border-box;

  @media (max-width: 1250px) {
    width: 100%;
    padding: 0px 15px;
  }
`;

export const FirstRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: end;
  @media (max-width: 767px) {
    flex-direction: column;
    align-items: start;
  }
`;

export const LeftSide = styled.div`
  display: flex;
`;
export const InfoBox = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 15px;
`;

export const DetailBoxRight = styled.div`
  width: 50%;
  @media (max-width: 767px) {
    width: 100%;
    margin-top: 50px;
  }
`;
export const RightSide = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 767px) {
    margin-top: 30px;
  }
  .custumStyle {
    width: 312px;
    height: 39px;
    .react-select__control {
      border: none;
      &:hover {
        cursor: pointer;
      }
    }

    .react-select__dropdown-indicator {
      color: #a5b5af;
    }
    @media (max-width: 991.99px) {
      width: 300px;
    }
    @media (max-width: 757px) {
      width: 280px;
    }
  }
`;

export const SmallText = styled.span`
  color: #1c9d87;
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
`;

export const Heading = styled.div`
  font-size: 24px;
  line-height: 34px;
  font-weight: 500;
  color: #142c1f;
  margin: 0px;
`;

export const FollowText = styled.span`
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  color: #44554c;
  margin-right: 15px;
`;

export const TableBox = styled.div`
  display: flex;
  margin: 30px 0 100px 0px;

  .outerBorder {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 13px 20px #d6d6d629;
    border-radius: 8px;
  }
  .rdt_TableCol {
    letter-spacing: 0.28px;
    color: #a5b5af;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 24px;
    font-weight: 500;
    padding: 20px 20px 5px;
  }
  .rdt_TableHeadRow {
    min-height: 1px;
    border: none;
  }

  .rdt_TableCell {
    padding: 0px 20px 0px;
    min-width: 1px;
  }

  .rdt_Table {
    padding: 0px 10px 10px;
    font-weight: 400;
    @media (max-width: 1100px) {
      width: 1100px;
    }
  }
  .name {
    line-height: 24px;
    font-size: 16px;
    color: #44554c;
  }
  .chip {
    background: #dddddd;
    color: #899591;
    border-radius: 14px;
    line-height: 15px;
    font-size: 13px;
    padding: 6px 12px;
    display: flex;
  }
  .priceText {
    color: #44554c;
    font-size: 16px;
    line-height: 24px;
  }
  button {
    border: 2px solid #1c9d87;
    padding: 10px 20px;
    background: transparent;
    cursor: pointer;
    color: #1c9d87;
    font-size: 14px;
    line-height: 17px;
    border-radius: 8px;
    font-weight: 500;
  }
  button:hover {
    background: #1c9d87;
    color: #fff;
  }
  .rdt_TableRow {
    border-top: 1px solid #eeeeee;
    padding: 11px 0;
    cursor: pointer;
  }
  .rdt_TableRow:hover {
    background: #eeeeee;
  }
  .rdt_TableRow:first-child {
    border-top: none;
  }
  .circle {
    width: 35px;
    height: 35px;
  }
  .circle img {
    max-width: 100%;
    object-fit: cover;
    border-radius: 50%;
  }
`;

// detail box
export const DetailBox = styled.div`
  background: #ffffff;
  box-shadow: 0px 13px 20px #d6d6d629;
  border-radius: 8px;
  padding: 30px;
  margin: 30px 0 100px;
`;

export const DetailBoxInner = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 767px) {
    flex-direction: column;
  }
`;
export const DetailBoxLeft = styled.div`
  width: 50%;
  @media (max-width: 767px) {
    width: 100%;
  }
`;

export const DetailBoxTop = styled.div`
  border-left: 2px solid #2ecf96;
  padding-left: 15px;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
  span {
    letter-spacing: 0.24px;
    color: #44554c;
    text-transform: uppercase;
    font-size: 12px;
    line-height: 24px;
  }
`;
export const ProfilBox = styled.div`
  background: #eeeeee;
  border-radius: 23px;
  padding: 4px 15px 4px 4px;
  display: flex;
  align-items: center;
  margin-top: 10px;

  img {
    width: 29px;
    height: 29px;
    border-radius: 50%;
  }
  p {
    color: #44554c;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    margin: 0px;
    padding-left: 10px;
  }
`;

export const Insights = styled.div`
  margin: 40px 0 30px;

  h3 {
    letter-spacing: 0.24px;
    color: #44554c;
    text-transform: uppercase;
    font-size: 12px;
    line-height: 24px;
    margin: 0 0 15px;
    font-weight: 500 !important;
  }
  ul {
    margin: 0px;
    padding: 0px 0 0 0px;
    list-style: none;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    @media (max-width: 600px) {
      grid-template-columns: repeat(1, 1fr);
    }
    li {
      letter-spacing: 0px;
      color: #44554c;
      font-size: 14px;
      line-height: 21px;
      font-weight: 400;
      background-image: url(${Tick});
      background-repeat: no-repeat;

      padding: 0 10px 0 25px;
      background-position: 0px 4px;
      margin-bottom: 10px;
    }
  }
`;
export const DetailPrice = styled.div`
  h3 {
    letter-spacing: 0.24px;
    color: #44554c;
    text-transform: uppercase;
    font-size: 12px;
    line-height: 24px;
    margin: 0 0 10px;
    font-weight: 500 !important;
  }
  p {
    margin: 0;
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    color: #1c9d87;
  }
`;
export const ButtonBox = styled.div`
  margin-top: 50px;
  a {
    background: #f8a492 0% 0% no-repeat padding-box;
    border-radius: 8px;
    letter-spacing: 0px;
    font-size: 16px;
    line-height: 19px;
    color: #ffffff;
    padding: 12px 20px;
    border: none;
    font-weight: 500;
    cursor: pointer;
    text-decoration: none;
  }
`;

export const BackButton = styled.button`
  width: 30px;
  background: #eaefed;
  border-radius: 4px;
  height: 53px;
  border: none;
  cursor: pointer;
  img {
    transform: rotate(90deg);
  }
`;
export const InputWrap = styled.div`
  display: flex;
  flex-direction: column;

  label {
    letter-spacing: 0.24px;
    color: #44554c;
    font-size: 12px;
    line-height: 24px;
    font-weight: 500;
    text-transform: uppercase;
    margin-bottom: 10px;
  }
  input {
    border: 1px solid #dce1e0;
    letter-spacing: 0px;
    color: #a5b5af;
    font-size: 14px;
    line-height: 17px;
    font-style: italic;
    border-radius: 4px;
    padding: 11px 15px;
    margin-bottom: 20px;
    &::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: #a5b5af;
    }
    &::-moz-placeholder {
      /* Firefox 19+ */
      color: #a5b5af;
    }
    &:-ms-input-placeholder {
      /* IE 10+ */
      color: #a5b5af;
    }
    &:-moz-placeholder {
      /* Firefox 18- */
      color: #a5b5af;
    }
  }
  textarea {
    border: 1px solid #dce1e0;
    letter-spacing: 0px;
    color: #a5b5af;
    font-size: 14px;
    line-height: 17px;
    font-style: italic;
    border-radius: 4px;
    padding: 11px 15px;
    height: 194px;
    resize: none;

    &::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: #a5b5af;
    }
    &::-moz-placeholder {
      /* Firefox 19+ */
      color: #a5b5af;
    }
    &:-ms-input-placeholder {
      /* IE 10+ */
      color: #a5b5af;
    }
    &:-moz-placeholder {
      /* Firefox 18- */
      color: #a5b5af;
    }
  }
`;
