import styled from "styled-components";
import {DashboardContainer} from "pages/dashboard/dashboard.styles"

export const WhatAwaitsYouContainer = styled(DashboardContainer)`
    display: flex;
    flex-direction: column;
    margin-top: 50px;
`;

// Nav Tabs Styling
export const WhatAwaitsYouNavTabContainer = styled(DashboardContainer)`
    padding: 20px;
    margin-top: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
`;

export const WhatAwaitsYouNavTab = styled.div`
    padding: 10px 15px;
    border: ${props => props.active ? "#1C9D87 2px solid" : "none"};
    border-radius: 8px;
    /* width: 176px; */
    text-align: center;
    color: ${props => props.active ? "#1C9D87" : "#A5B5AF"};
    cursor: pointer;
`;

// What Awaits You Card styling
export const WhatAwaitsYouCardContainer = styled(DashboardContainer)`
    display: flex;
    flex-direction: row;
    margin-top: 50px;
`;

export const WhatAwaitsYouCardInnerContainer = styled.div`
    padding: 20px;
`;

export const WhatAwaitsYouTopImage = styled.img`
    margin-top: 35px;
    margin: 35px auto 30px;
`;

export const WhatAwaitsYouCardHeading = styled.div`
    font-family: RubikMedium;
    font-size: 18px;
    margin-bottom: 20px;
    color: #142C1F;
`;

export const WhatAwaitsYouCardSubHeading = styled.div`
    font-family: RubikRegular;
    font-size: 14px;
    margin-bottom: 35px;
    opacity: .55;
    color: #44554C;
`;

// App Functions Styling
export const AppFunctionsContainer = styled(DashboardContainer)`
    display: flex;
    flex-direction: row;
    margin-top: 30px;
`;

export const AppFunctionsImageContainer = styled.div`
    width: 270px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const IPhoneImage = styled.img``;

export const AppFunctionsDetailsContainer = styled.div`
    width: 650px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 25px;
`;

export const AppFunctionsDetailsCardContainer = styled.div`
    width: 295px;
`;

export const AppFunctionsDetailsCardHeading = styled.div`
    font-size: 16px;
    font-family: RubikMedium;
    color: #142C1F;
    margin-bottom: 10px;
`;

export const AppFunctionsDetailsCardSubHeading = styled.div`
    font-size: 13px;
    font-family: RubikRegular;
    color: #44554C;
    opacity: .5;
`;