import styled from 'styled-components';
import {RegistrationContainer ,RegistrationHeading, RegistrationSubHeading,
     EatingHabitsCardContainer, EatingHabitsImageContainer, EatingHabitsCardInnerContainer
} from 'pages/registration/registration.styles';

export const NutritionFormRecipesContainer = styled(RegistrationContainer)``;

export const NutritionFormRecipesHeading = styled(RegistrationHeading)``;

export const NutritionFormRecipesSubHeading = styled(RegistrationSubHeading)``;

export const NutritionFormRecipesButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin: 20px auto;
    position: relative;
`;

export const ArrowButton = styled.div`
    position: absolute;
    border: ${props => props.active ? "solid #2ECF96" : "solid rgba(165, 181, 175, .6)"};
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 6px;
    border-radius: 2px;
    top: 13px;
    right: ${props => props.left ? '15px' : "13px"};;
    transform: ${props => props.left ? 'rotate(135deg)' : "rotate(-45deg)"};
    -webkit-transform: ${props => props.left ? 'rotate(135deg)' : "rotate(-45deg)"};
`;

export const StraightLine = styled.div`
    position: absolute;
    border-bottom: ${props => props.active ? "2px solid #2ECF96" : "2px solid rgba(165, 181, 175, .6)"};
    width: 18px;
    bottom: 17px;
    left: 8px;
`;

export const NutritionFormRecipesButtonLeft = styled.div`
    position: absolute;
    width: 42px;
    height: 42px;
    border-radius: 50%;
    border: ${props => props.active ? "2px solid #2ECF96" : "2px solid rgba(165, 181, 175, .6)"};
    left: -90px;
    top: 190px;
    cursor: ${props => props.active ? "pointer" : "not-allowed"};
`;

export const NutritionFormRecipesButtonRight = styled(NutritionFormRecipesButtonLeft)`
    left: auto;
    right: -90px;
    top: 190px;
`;

export const NutritionFormRecipesCardContainer = styled(NutritionFormRecipesButtonContainer)`
    flex-direction: column;
`;

export const NutritionFormRecipesCard = styled(EatingHabitsCardContainer)`
    margin: 20px auto 0px;
`;

export const NutritionFormRecipesCardImageContainer = styled.div`
    width: 122px;
    overflow: hidden;
    border-radius: 8px;
`;

export const NutritionFormRecipesCardImage = styled(EatingHabitsImageContainer)`
    margin-left: -50px;
    width: auto;
`;

export const NutritionFormRecipesCardInnerContainer = styled(EatingHabitsCardInnerContainer)``;

export const NutritionFormRecipesCardInnerHeading = styled.div`
    width: 130px;
    font-size: 14px;
    font-family: RubikMedium;
    color: #142C1F;
    margin: 30px 20px;
`;

export const Tooltip = styled.div`
    &:hover > div {
        visibility: visible;
    }
`;

export const TooltipText = styled.div`
    visibility: hidden;
    width: 250px;
    background-color: #2ECF96;
    color: #fff;
    text-align: center;
    border-radius: 4px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
`;

export const NutritionFormRecipesCardInnerSubHeading = styled.div`
    font-size: 12px;
    color: #A5B5AF;
    margin-top: 6px;
    font-family: RubikRegular;
`;

export const NutritionFormRecipesCardInnerCalories = styled.div`
    width: 80px;
    border-left: 2px solid rgba(128, 128, 128, .1);
    margin: 27px 0;
    color: #25A08B;
    font-size: 16px;
    font-family: RubikMedium;
    text-align: center;
    padding-top: 11px;
`;

export const NutritionFormRecipesCardInnerCaloriesValue = styled.div`
    font-size: 13px;
    color: #44554C;
    font-family: RubikRegular;
    margin-top: 2px;
`;