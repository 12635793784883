import styled from "styled-components";

export const CardContainer = styled.div`
  width: ${(props) => props.width};
  max-width: ${(props) => props.maxWidth};
  min-height: ${(props) => props.height};
  margin: ${(props) => props.margin};
  background: #ffffff;
  box-shadow: 0px 2px 5px #0000001a;
  border-radius: 1.25rem;
  text-align: ${(props) => props.textAlign};
  position: relative;
  overflow: hidden;
`;
