import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useAlert } from "react-alert";
import {
  renderDescription,
  isDate7DaysOld,
  formatDate,
  calculatePercentage,
  sumCoursePoints,
  getFormatedDates,
} from "./PureFunctions";

import * as DashboardActions from "redux/dashboard/dashboard.actions";
import * as NutritionActions from "redux/nutrition/nutrition.actions";
import LockSVG from "assets/images/lock.svg";
import LockWhite from "assets/images/lock-white.svg";
import * as C from "./clientDetails.styles";
import * as S from "./NutritionCourse.styles";
import "./clientDetails.scss";

const texts = [
  {
    title: "Ernährungsstart einfach gemacht",
    description: "Einführung in den Kurs",
  },
  {
    title: "Kalorien in der täglichen Ernährung",
    description: "Grundlagen der Ernährung",
  },
  {
    title: "Makronährstoffe in der Ernährung",
    description: "Makronährstoffe verstehen",
  },
  {
    title: "Mikronährstoffe in der Ernährung",
    description: "Mikronährstoffe verstehen",
  },
  { title: "Gesunde Ernährung im Alltag", description: "Ernährung im Alltag" },
  {
    title: "Gesundheit und Wohlbefinden",
    description: "Gesundheit/Wohlbefinden",
  },
  {
    title: "Ernährung in besonderen Situationen",
    description: "Besondere Situationen",
  },
  {
    title: "Nachhaltigkeit & Zusammenfassung",
    description: "Eine nachhaltige Ernährung",
  },
];

const NutritionCourse = ({ clientDetails }) => {
  const dispatch = useDispatch();
  const alert = useAlert();

  const [SelectedCourse, SetSelectedCourse] = useState(null);

  const nutritionCourseDetails = useSelector(
    (state) => state.nutrition.nutritionCourseDetails
  );
  const isNutritionCourseDetailsLoading = useSelector(
    (state) => state.nutrition.isNutritionCourseDetailsLoading
  );

  const updateNutritionCourseDetails = useSelector(
    (state) => state.nutrition.updateNutritionCourseDetails
  );
  const isNutritionCourseDetailsUpdating = useSelector(
    (state) => state.nutrition.isNutritionCourseDetailsUpdating
  );

  const checkUserIdEmail = useSelector(
    (state) => state.nutrition.checkUserIdEmail
  );
  const isCheckUserIdEmailLoading = useSelector(
    (state) => state.nutrition.isCheckUserIdEmailLoading
  );

  const isEmailSending = useSelector((state) => state.dashboard.isEmailSending);

  const sendEmail = useSelector((state) => state.dashboard.sendEmail);
  const trainerData = useSelector((state) => state.auth.trainerData);
  const trainerProfileTempDetails = useSelector(
    (state) => state.dashboard.trainerProfileTempDetails
  );

  useEffect(() => {
    dispatch(DashboardActions.sendEmailNull());
    getCourseDetails();

    dispatch(
      NutritionActions.checkUserIdEmailRequest({
        payload: {
          email: clientDetails?.email,
          userID: clientDetails?.userID,
        },
        callback: () => {},
      })
    );
  }, [dispatch]);

  const handleUpdateCourseInformation = (course) => {
    SetSelectedCourse(course);
    const payload = {
      1: [{ name: "Woche 1", status: "active" }],
      2: [
        { name: "Woche 1", status: "inactive" },
        { name: "Woche 2", status: "active" },
      ],
      3: [
        { name: "Woche 2", status: "inactive" },
        { name: "Woche 3", status: "active" },
      ],
      4: [
        { name: "Woche 3", status: "inactive" },
        { name: "Woche 4", status: "active" },
      ],
      5: [
        { name: "Woche 4", status: "inactive" },
        { name: "Woche 5", status: "active" },
      ],
      6: [
        { name: "Woche 5", status: "inactive" },
        { name: "Woche 6", status: "active" },
      ],
      7: [
        { name: "Woche 6", status: "inactive" },
        { name: "Woche 7", status: "active" },
      ],
      8: [
        { name: "Woche 7", status: "inactive" },
        { name: "Woche 8", status: "active" },
      ],
    };

    dispatch(
      NutritionActions.updateOrDeleteNutritionCourseDetailsRequest({
        payload: {
          email: clientDetails?.email,
          tagData: payload[course?.courseID],
        },
        callback: (res) => {
          console.log("res", res);
        },
      })
    );

    dispatch(
      NutritionActions.updateNutritionCourseDetailsRequest({
        payload: {
          userID: clientDetails?.userID,
          courseID: course?.courseID,
          type:
            course?.type || clientDetails?.subscribe_status
              ? clientDetails?.subscribe_status?.split("-")[0]
              : "",
        },
        callback: () => {
          getCourseDetails();
        },
      })
    );

    dispatch(
      DashboardActions.sendEmailRequest({
        payload: {
          templateName: "course-kursleitung-lesson",
          fromEmail: trainerProfileTempDetails?.data?.foodiaryEmail,
          fromName: trainerData?.prename + " " + trainerData?.surname,
          toEmail: clientDetails?.email,
          bccAddress: trainerProfileTempDetails?.data?.foodiaryEmail,
        },
        callback: (res) => {
          dispatch(DashboardActions.sendEmailNull());
          alert.success("Die Email wurde verschickt", {
            timeout: 5000,
          });
        },
      })
    );
  };

  const getCourseDetails = () => {
    dispatch(
      NutritionActions.getNutritionCourseDetailsByIdRequest({
        payload: {
          userID: clientDetails?.userID,
        },
        callback: () => {},
      })
    );
  };

  const getRemainingCourses = () => {
    const courseDetailsLength =
      nutritionCourseDetails?.getCourseDetailsByUserID?.length;

    let titles = [...texts];

    Array(courseDetailsLength)
      .fill(0)
      .forEach((t) => titles.shift());

    titles = titles.map((t, i) => {
      return {
        id: i + 1,
        title: t.title,
        description: t.description,
        badge: "grey-bg",
        courseID: i === 0 ? courseDetailsLength + 1 : null,
      };
    });

    const lastCourse =
      nutritionCourseDetails?.getCourseDetailsByUserID[courseDetailsLength - 1];

    if (titles.length > 0 && lastCourse?.course_points === 5) {
      titles[0].badge = "orange-bg";
    }

    if (
      titles.length > 0 &&
      lastCourse?.course_points === 5 &&
      isDate7DaysOld(
        lastCourse?.course_attempts === 1
          ? lastCourse?.created_at
          : lastCourse?.updated_at
      )
    ) {
      titles[0].badge = "light-green-bg";
    }

    return titles;
  };

  return (
    <>
      <C.ContainerBox>
        <C.ContainerBoxWithPadding>
          <S.Header>
            <div>
              <C.Heading>Ernährungskurs</C.Heading>
              <C.SubHeading>
                Behalte den Überblick über den Fortschritt deines Klienten und
                informiere die Kursleitung, <br />
                sollte dein Klient eine Lektion nicht bestanden haben.
              </C.SubHeading>
            </div>
            <div>
              <button className="btn-dark">
                <img src={LockWhite} alt="" /> Zertifikat anfragen
              </button>
            </div>
          </S.Header>
        </C.ContainerBoxWithPadding>

        <C.Divider></C.Divider>

        {isNutritionCourseDetailsLoading && (
          <>
            <br />
            <br />
            <br />
            <br />
            <div className="text-center">
              <div className="loader mx-auto"></div>
            </div>
            <br />
            <br />
            <br />
            <br />
          </>
        )}
        {!isNutritionCourseDetailsLoading && nutritionCourseDetails && (
          <S.NutritionCourseDetailsBox>
            <S.BoxTitle>Fortschritt</S.BoxTitle>
            <S.GridFourColumn>
              <S.ProgressCard>
                <S.ProgressCardTitle>
                  {calculatePercentage(
                    nutritionCourseDetails?.getCourseDetailsByUserID
                  )}
                  % Abgeschlossen
                </S.ProgressCardTitle>
                <S.ProgressCardSubTitle>
                  Der Kursfortschritt
                </S.ProgressCardSubTitle>
                {nutritionCourseDetails && (
                  <S.ProgressBars>
                    {nutritionCourseDetails?.getCourseDetailsByUserID.map(
                      (d) => (
                        <div className="active"></div>
                      )
                    )}

                    {Array(
                      8 -
                        nutritionCourseDetails?.getCourseDetailsByUserID.length
                    )
                      .fill(0)
                      .map((d) => (
                        <div></div>
                      ))}
                  </S.ProgressBars>
                )}
              </S.ProgressCard>

              <S.ProgressCard>
                <S.ProgressCardTitle>Richtige Fragen</S.ProgressCardTitle>
                <S.ProgressCardSubTitle>
                  Fragen, die richtig beantwortet wurden
                </S.ProgressCardSubTitle>
                <S.ProgressCardDate>
                  {sumCoursePoints(
                    nutritionCourseDetails?.getCourseDetailsByUserID
                  )}{" "}
                  out of 40
                </S.ProgressCardDate>
              </S.ProgressCard>

              <S.ProgressCard>
                <S.ProgressCardTitle>Startdatum</S.ProgressCardTitle>
                <S.ProgressCardSubTitle>
                  Klient wurde freigeschaltet
                </S.ProgressCardSubTitle>
                {checkUserIdEmail && (
                  <S.ProgressCardDate>
                    {
                      getFormatedDates(checkUserIdEmail?.data || "")
                        .formattedOriginalDate
                    }
                  </S.ProgressCardDate>
                )}
              </S.ProgressCard>

              <S.ProgressCard>
                <S.ProgressCardTitle>Enddatum</S.ProgressCardTitle>
                <S.ProgressCardSubTitle>
                  Zeitraum für erfolgreichen Abschluss
                </S.ProgressCardSubTitle>
                {checkUserIdEmail && (
                  <S.ProgressCardDate>
                    {
                      getFormatedDates(checkUserIdEmail?.data || "")
                        .formattedNewDate
                    }
                  </S.ProgressCardDate>
                )}
              </S.ProgressCard>
            </S.GridFourColumn>
            <br />
            <br />
            <C.Divider></C.Divider>
            <br />
            <br />
            <S.BoxTitle>Kursdetails</S.BoxTitle>
            <S.GridTwoColumns>
              {nutritionCourseDetails &&
                nutritionCourseDetails?.getCourseDetailsByUserID
                  .sort((a, b) => a?.courseID - b?.courseID)
                  .map((c, i) => (
                    <S.CourseDetailsCard>
                      <S.CourseDetailsCardHeader>
                        <div>
                          <h5>{c?.lesson_title}</h5>
                          <p>{renderDescription(texts, i)}</p>
                        </div>
                        <div>
                          {c?.course_points < 5 && (
                            <div className="badge light-green-bg">Aktiv</div>
                          )}
                          {c?.course_points === 5 && (
                            <div className="badge green-bg">Erfolgreich</div>
                          )}
                        </div>
                      </S.CourseDetailsCardHeader>
                      <S.CourseDetailsCardBody>
                        <div className="c1">
                          <p>
                            Richtige Fragen:{" "}
                            <strong>{c?.course_points} von 5</strong>
                          </p>
                          <p>Datum des ersten Versuches:</p>
                          <p>Datum des zweiten Versuches:</p>
                        </div>
                        <div className="c2">
                          <p>
                            Versuch Nummer:{" "}
                            <strong>{c?.course_attempts}</strong>
                          </p>
                          <p>
                            <strong>
                              {formatDate(new Date(c?.created_at))}
                            </strong>
                          </p>
                          <p>
                            {c?.course_attempts > 1 && (
                              <strong>
                                {formatDate(new Date(c?.updated_at))}
                              </strong>
                            )}
                          </p>
                        </div>
                      </S.CourseDetailsCardBody>
                      <S.CourseDetailsCardFooter>
                        <button
                          className="w-full"
                          onClick={() =>
                            c?.course_points < 5
                              ? handleUpdateCourseInformation(c)
                              : null
                          }
                          disabled={c?.course_points === 5}
                        >
                          {c?.course_points === 5 && (
                            <img src={LockSVG} alt="" />
                          )}
                          {isNutritionCourseDetailsUpdating &&
                          SelectedCourse?.courseID === c?.courseID
                            ? "Updating..."
                            : "Kursleitung informieren"}
                        </button>
                      </S.CourseDetailsCardFooter>
                    </S.CourseDetailsCard>
                  ))}

              {nutritionCourseDetails &&
                getRemainingCourses()?.map((t, i) => (
                  <S.CourseDetailsCard>
                    <S.CourseDetailsCardHeader>
                      <div>
                        <h5>{t?.title}</h5>
                        <p>{t?.description}</p>
                      </div>
                      <div>
                        {t?.badge === "orange-bg" && (
                          <div className="badge orange-bg">Freigeschaltet</div>
                        )}
                        {t?.badge === "light-green-bg" && (
                          <div className="badge light-green-bg">Aktiv</div>
                        )}
                        {t?.badge === "grey-bg" && (
                          <div className="badge grey-bg">Gesperrt</div>
                        )}
                      </div>
                    </S.CourseDetailsCardHeader>
                    <S.CourseDetailsCardBody>
                      <div className="c1">
                        <p>Richtige Fragen:</p>
                        <p>Datum des ersten Versuches:</p>
                        <p>Datum des zweiten Versuches:</p>
                      </div>
                      <div className="c2">
                        <p>Versuch Nummer:</p>
                        <p></p>
                        <p></p>
                      </div>
                    </S.CourseDetailsCardBody>
                    <S.CourseDetailsCardFooter>
                      <button
                        className="w-full"
                        onClick={() =>
                          ["light-green-bg"].includes(t?.badge) && i === 0
                            ? handleUpdateCourseInformation(t)
                            : null
                        }
                        disabled={["orange-bg", "grey-bg"].includes(t?.badge)}
                      >
                        {["orange-bg", "grey-bg"].includes(t?.badge) && (
                          <img src={LockSVG} alt="" />
                        )}{" "}
                        {isNutritionCourseDetailsUpdating &&
                        SelectedCourse?.courseID === t?.courseID
                          ? "Updating..."
                          : "Kursleitung informieren"}
                      </button>
                    </S.CourseDetailsCardFooter>
                  </S.CourseDetailsCard>
                ))}
            </S.GridTwoColumns>
          </S.NutritionCourseDetailsBox>
        )}
      </C.ContainerBox>
    </>
  );
};

export default NutritionCourse;
