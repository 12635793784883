import React, { useEffect, useState } from "react";
import { Route, useLocation, useHistory, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import Overview from "pages/ClientDetails/Overview";
import Calories from "pages/ClientDetails/Calories";
import DietFormAndNutritionDistribution from "pages/ClientDetails/DietFormAndNutritionDistribution";
import Objective from "pages/ClientDetails/Objective";
import Anamnese from "pages/ClientDetails/Anamnese";
import DiaryAndHabbitTracker from "pages/ClientDetails/DiaryAndHabbitTracker";
import EmailManagement from "pages/ClientDetails/EmailManagement";
import NutritionCourse from "pages/ClientDetails/NutritionCourse/NutritionCourse";
import Invoices from "pages/ClientDetails/Invoices";
import ProgressAndMeasurements from "pages/ClientDetails/ProgressAndMeasurements";
import DailyRoutine from "pages/ClientDetails/DailyRoutine";

import * as AuthActions from "redux/auth/auth.actions";
import * as SupplementsActions from "redux/supplements/supplements.actions";
import * as DashboardActions from "redux/dashboard/dashboard.actions";
import Transfer from "./Transfer";

const useScrollToTop = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [window.location.pathname]);
};

export default function ClientDetailsLayout() {
  useScrollToTop();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { userId } = useParams();

  const trainerData = useSelector((state) => state.auth.trainerData);

  useEffect(() => {}, []);

  return (
    <>
      <div className="client-details-layout">
        <Route
          path="/dashboard/clients/overview/:userId"
          component={Overview}
          exact
        />

        <Route
          path="/dashboard/clients/calories/:userId"
          component={Calories}
          exact
        />

        <Route
          path="/dashboard/clients/diet-and-nutrition/:userId"
          component={DietFormAndNutritionDistribution}
          exact
        />

        <Route
          path="/dashboard/clients/objective/:userId"
          component={Objective}
          exact
        />

        <Route
          path="/dashboard/clients/anamnese/:userId"
          component={Anamnese}
          exact
        />

        <Route
          path="/dashboard/clients/journal/:userId"
          component={DiaryAndHabbitTracker}
          exact
        />

        <Route
          path="/dashboard/clients/email-management/:userId"
          component={EmailManagement}
          exact
        />

        <Route
          path="/dashboard/clients/nutrition-course/:userId"
          component={NutritionCourse}
          exact
        />
        <Route
          path="/dashboard/clients/invoices/:userId"
          component={Invoices}
          exact
        />

        <Route
          path="/dashboard/clients/invoices/:userId/detail/:invoiceId"
          component={Transfer}
          exact
        />

        <Route
          path="/dashboard/clients/progress-and-measurements/:userId"
          component={ProgressAndMeasurements}
          exact
        />

        <Route
          path="/dashboard/clients/daily-routine/:userId"
          component={DailyRoutine}
          exact
        />
      </div>
    </>
  );
}
