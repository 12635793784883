const LeadGoalOptions = [
    //Abnehmen, Gewichtsabnahme, Gesunder Lebensstil
    {
        label: 'Abnehmen',
        value: 'Abnehmen'

    },
    {
        label: 'Gewichtsabnahme',
        value: 'Gewichtsabnahme'

    },
    {
        label: 'Gesunder Lebensstil',
        value: 'Gesunder Lebensstil'
    },

];
const LeadGenderOptions = [
    //Männlich, Weiblich, Divers
    {
        label: 'Männlich',
        value: 'Männlich'

    },
    {
        label: 'Weiblich',
        value: 'Weiblich'

    },
    {
        label: 'Keine Angabe',
        value: 'Keine Angabe',
    },

];
const LeadAgeOptions = [
    {
        label: '18-25 Jahre',
        value: '18-25'
    },
    {
        label: '26-35 Jahre',
        value: '26-35',

    }, {
        label: '36-45 Jahre',
        value: '36-45',
    },
    {
        label: '+45 Jahre',
        value: '+45'
    }

];

const stateFieldError = {
    leadAge: {
        type: false,
        message: ''
    },
    leadDate: {
        type: false,
        message: ''
    },
    leadGoal: {
        type: false,
        message: ''
    },
    leadGender: {
        type: false,
        message: ''
    },
    prename: {
        type: false,
        message: ''
    },
    surename: {
        type: false,
        message: ''
    },
    mobilenumber: {
        type: false,
        message: ''
    },
    email: {
        type: false,
        message: ''
    },
    leadInformations: {
        type: false,
        message: ''
    }
}

const FieldErrorMessages = {
    'leadGoal': 'Wähle das Ziel aus',
    'leadGender': 'Wähle das Geschlecht aus',
    'leadAge': 'Wähle das Alter aus',
    'leadDate': 'Wähle dein Datum aus',
    'prename': 'Trage den Vornamen ein',
    'surename': 'Trage den Nachnamen ein',
    'mobilenumber': 'Trage die Telefonnummer ein',
    'email': 'Trage die E-Mail ein',
    'leadInformations': 'Hinterlasse eine kurze Notiz'
}


export { LeadAgeOptions, LeadGenderOptions, LeadGoalOptions, stateFieldError, FieldErrorMessages };

