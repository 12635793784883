import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getS3ImageUrl } from "configs/utils";
import { findAge } from "configs/utils";

// Components
import Button from "components/formComponents/button/Button";
import TranslationMessage from "configs/translations/TranslationMessage";
import GreenCheck from "assets/images/GreenCheck.svg";
import FoodiaryLogo from "assets/images/Foodiary-Logo.PNG";
import userProfile1 from "assets/images/reviews/Marie-Sophie-Silmar-01.png";
import userProfile2 from "assets/images/reviews/Anna-Seiner.png";
import userProfile3 from "assets/images/reviews/Lukas-Tatzer-01.png";
import ratingStars from "assets/images/rating.svg";

import ArrowLeft from "assets/images/arrowLeft.svg";
import { SidebarSteps, OnboardingInterviewSteps } from "./data";
import OnboardingSidebar from "./OnboardingSidebar";
// Redux
import * as AuthActions from "redux/auth/auth.actions";
import * as S from "./OnBoardingPartOne.styles";

const TrainerIntroduction = (props) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const trainerProfileData = useSelector((state) => state.auth.trainerData);

  const [activeTab, setActiveTab] = useState(1);

  useEffect(() => {}, []);

  const Testimonials = [
    {
      id: "card1",
      profileImage: userProfile1,
      rating: ratingStars,
      username: "premium.coaching.success.username1",
      comment: "premium.coaching.success.comment1",
    },
    {
      id: "card2",
      profileImage: userProfile2,
      rating: ratingStars,
      username: "premium.coaching.success.username2",
      comment: "premium.coaching.success.comment2",
    },
    {
      id: "card3",
      profileImage: userProfile3,
      rating: ratingStars,
      username: "premium.coaching.success.username3",
      comment: "premium.coaching.success.comment3",
    },
  ];

  return (
    <>
      <S.OnBoardingPage className="starter">
        <S.OnBoardingContainer className="onboarding-container">
          <OnboardingSidebar step={1}/>
          <S.OnBoardingContent className="onboarding-content">
            <S.OnBoardingHeader className="onboarding-header">
              <div className="d-flex align-items-top gap-3">
                <img
                  className="cursor-pointer"
                  src={ArrowLeft}
                  alt="back"
                  onClick={() => history.push("/onboarding/call-recording")}
                />
                <div className="content">
                  <span className="small-title text-green font-rubik-medium">
                    Vorstellung
                  </span>
                  <h6 className="header-title font-rubik-medium my-0">
                    Lerne mich kennen
                  </h6>
                </div>
              </div>
              <div className="actions">
                <Button
                  color="rgba(248, 164, 146, 1)"
                  gradient="linear-gradient(60deg, rgba(46, 207, 150, 1), rgba(28, 157, 135, 1)) !important"
                  margin=" 0"
                  absolute={{ width: "fit-content", height: "2.5rem" }}
                  buttonStyle={{ padding: ".5625rem 2rem" }}
                  onClick={() => history.push("/onboarding/interview-steps")}
                >
                  Weiter
                </Button>
              </div>
            </S.OnBoardingHeader>
            <S.OnBoardingBody className="onboarding-body p-0">
              <div className="trainer-intro-content padding-body">
                <div className="profile-img">
                  <img
                    src={getS3ImageUrl(trainerProfileData.profilePic)}
                    alt=""
                  />
                </div>
                <div className="profile-content">
                  <span className="small-title text-green font-rubik-medium mb-1 d-block">
                    Das bin ich
                  </span>
                  <h6 className="h2 font-rubik-medium">
                    {trainerProfileData.prename} {trainerProfileData.surname},{" "}
                    {findAge(trainerProfileData.birthday)} Jahre
                  </h6>

                  <p className="p3 text-dark-2 my-1-5-rem line-height-1-5 font-rubik-regular">
                    {trainerProfileData.description}
                  </p>

                  <S.ChecklistElementContainer>
                    <S.ChecklistElementGreenTick src={GreenCheck} />
                    <strong style={{ marginRight: 10 }}>Coach für:</strong>
                    <span className="font-rubik-light">
                      {trainerProfileData.subtitle}
                    </span>
                  </S.ChecklistElementContainer>
                  <S.ChecklistElementContainer>
                    <S.ChecklistElementGreenTick src={GreenCheck} />
                    <strong style={{ marginRight: 10 }}>Spezialgebiet:</strong>
                    <span className="font-rubik-light">
                      {trainerProfileData.step1}
                    </span>
                  </S.ChecklistElementContainer>
                  <S.ChecklistElementContainer>
                    <S.ChecklistElementGreenTick src={GreenCheck} />
                    <strong style={{ marginRight: 10 }}>
                      Ernährungsberater seit:
                    </strong>
                    <span className="font-rubik-light">
                      {trainerProfileData.step2}
                    </span>
                  </S.ChecklistElementContainer>
                  <S.ChecklistElementContainer>
                    <S.ChecklistElementGreenTick src={GreenCheck} />
                    <strong style={{ marginRight: 10 }}>Ausbildungen:</strong>
                    <span className="font-rubik-light">
                      {trainerProfileData.step3}
                    </span>
                  </S.ChecklistElementContainer>
                  <S.ChecklistElementContainer>
                    <S.ChecklistElementGreenTick src={GreenCheck} />
                    <strong style={{ marginRight: 10 }}>
                      Praxiserfahrung:
                    </strong>
                    <span className="font-rubik-light">
                      {trainerProfileData.step4}
                    </span>
                  </S.ChecklistElementContainer>
                </div>
              </div>
              <div className="divider"></div>
              <div className="trainers-reviews padding-body">
                <p className="p1 font-rubik-medium text-dark-1">
                  Das sagen unsere Klienten:
                </p>
                <br />
                <div className="testimonials">
                  {Testimonials.map((item) => (
                    <div className="testimonial" key={item.id}>
                      <div className="d-flex gap-2 align-items-center">
                        <div className="testimonial-img">
                          <img src={item.profileImage} alt="" />
                        </div>
                        <div className="testimonial-content">
                          <div className="stars">
                            <img src={item.rating} alt="" />
                          </div>
                          <p className="p3 text-dark-1">
                            {" "}
                            <TranslationMessage id={item.username} />
                          </p>
                          <span className="font-rubik-light text-light-grey p6">
                            26 Jahre
                          </span>
                        </div>
                      </div>
                      <br />
                      <p className="p2 font-rubik-regular text-dark-2 line-height-1-25">
                        <TranslationMessage id={item.comment} />
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            </S.OnBoardingBody>
          </S.OnBoardingContent>
        </S.OnBoardingContainer>
      </S.OnBoardingPage>
    </>
  );
};

export default TrainerIntroduction;
