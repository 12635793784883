import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  setUserRegistrationDataInLocalStorage,
  getUserRegistrationDataFromLocalStorage,
} from "configs/utils";
import Button from "components/formComponents/button/Button";

import FoodiaryLogo from "assets/images/Foodiary-Logo.PNG";
import ArrowLeft from "assets/images/arrowLeft.svg";
import { SidebarSteps, DietCategoriesList } from "../data";
// Redux
import * as S from "../OnBoardingPartOne.styles";
import OnboardingSidebar from "../OnboardingSidebar";

const DietCategories = (props) => {
  const history = useHistory();

  const [SelectedCategory, SetSelectedCategory] = useState(
    // DietCategoriesList[0]
    null
  );
  const [IsValidation, SetIsValidation] = useState(false);

  useEffect(() => {
    const cat = getUserRegistrationDataFromLocalStorage()?.dietCategory;
    if (cat) SetSelectedCategory(cat);
  }, []);

  const onSelectCategory = (item) => {
    SetSelectedCategory(item);
    SetIsValidation(false);
  };

  const handleNext = () => {
    if (!SelectedCategory) {
      SetIsValidation(true);
      return;
    }

    const pathPrefix = "/onboarding/registration/eating-habits/";
    const paths = {
      1: pathPrefix + "vegetarisch-1",
      2: pathPrefix + "vegan-1",
      3: pathPrefix + "fleischhaltig-1",
    };

    setUserRegistrationDataInLocalStorage({
      ...getUserRegistrationDataFromLocalStorage(),
      dietCategory: SelectedCategory,
    });

    history.push(paths[SelectedCategory?.id]);
  };

  const handleBack = () => {
    history.push("/onboarding/registration/training-days");
  };

  return (
    <>
      <S.OnBoardingPage className="starter">
        <S.OnBoardingContainer className="onboarding-container">
          <OnboardingSidebar step={2} />
          <S.OnBoardingContent className="onboarding-content">
            <S.OnBoardingHeader className="onboarding-header">
              <div className="d-flex align-items-top gap-3">
                <img
                  className="cursor-pointer"
                  src={ArrowLeft}
                  alt="back"
                  onClick={handleBack}
                />
                <div className="content">
                  <span className="small-title text-green font-rubik-medium">
                    Anamnese
                  </span>
                  <h6 className="header-title font-rubik-medium my-0">
                    Vegan, Vegetarisch oder Fleischhaltig?
                  </h6>
                </div>
              </div>
              <div className="actions">
                <Button
                  color="rgba(248, 164, 146, 1)"
                  gradient="linear-gradient(60deg, rgba(46, 207, 150, 1), rgba(28, 157, 135, 1)) !important"
                  margin="0"
                  absolute={{ width: "fit-content", height: "2.5rem" }}
                  buttonStyle={{ padding: ".5625rem 2.5rem" }}
                  onClick={handleNext}
                >
                  Weiter
                </Button>
              </div>
            </S.OnBoardingHeader>
            <S.OnBoardingBody className="onboarding-body">
              <div className="diet-categories-content ">
                <h3 className="text-size-20-28 text-dark-1 font-rubik-medium">
                  Gibt es Präferenzen?
                </h3>
                <p className="text-size-16-19 text-light-grey-1 font-rubik-regular">
                  Nenne uns, ob du im Alltag eher auf tierische Produkte
                  verzichtest oder eine fleischhaltige Ernährungsweise
                  bevorzugst?
                </p>
                <p className="text-size-14-28 text-dark-1 font-rubik-medium">
                  Welche Art der Ernährung bevorzugst du?
                </p>
                {IsValidation && (
                  <p className="text-size-14-18 text-right text-red font-rubik-regular mt-0">
                    Wähle eine Kategorie
                  </p>
                )}
                <div className="gender-items">
                  {DietCategoriesList.map((item, i) => (
                    <div
                      key={i}
                      className="gender-item cursor-pointer"
                      onClick={() => onSelectCategory(item)}
                    >
                      <img className="gender-img" src={item.img} alt="" />
                      <div className="headings">
                        <p className="text-size-20-24 text-dark-1 font-rubik-medium my-0">
                          {item.title}
                        </p>
                      </div>
                      <div className="form-group-c medium">
                        <input
                          type="checkbox"
                          id={item?.id}
                          checked={SelectedCategory?.id === item?.id}
                          readOnly
                        />
                        <label htmlFor={item?.id}></label>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </S.OnBoardingBody>
          </S.OnBoardingContent>
        </S.OnBoardingContainer>
      </S.OnBoardingPage>
    </>
  );
};

export default DietCategories;
