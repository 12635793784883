import AuthActionTypes from "redux/auth/auth.types";

// Daily Routine Data
export const setDailyRoutineData = (data) => ({
  type: AuthActionTypes.SET_DAILY_ROUTINE_DATA,
  payload: data,
});

// Registration Data
export const setRegistrationData = (data) => ({
  type: AuthActionTypes.SET_REGISTRATION_DATA,
  payload: data,
});

export const resetRegistrationData = () => ({
  type: AuthActionTypes.RESET_REGISTRATION_DATA,
});

// Health Score Data
export const setHealthScoreData = (data) => ({
  type: AuthActionTypes.SET_HEALTH_SCORE_DATA,
  payload: data,
});

// Creating Site
export const createWebsiteLoading = () => ({
  type: AuthActionTypes.CREATE_WEBSITE_LOADING_START,
});

export const createWebsiteStop = () => ({
  type: AuthActionTypes.CREATE_WEBSITE_LOADING_STOP,
});

// CreateWebsite
export const createWebsiteRequest = (payload) => ({
  type: AuthActionTypes.CREATE_WEBSITE_REQUEST,
  payload: payload,
});

export const createWebsiteSuccess = (user) => ({
  type: AuthActionTypes.CREATE_WEBSITE_SUCCESS,
  payload: user,
});

export const createWebsiteFailure = (error) => ({
  type: AuthActionTypes.CREATE_WEBSITE_FAILURE,
  payload: error,
});

// Loading
export const loadingStart = () => ({
  type: AuthActionTypes.LOADING_START,
});

export const loadingStop = () => ({
  type: AuthActionTypes.LOADING_STOP,
});

export const getTrainerDetailsRequest = (payload) => ({
  type: AuthActionTypes.GET_TRAINER_DETAILS_REQUEST,
  payload: payload,
});

export const getTrainerDetailsSuccess = (user) => ({
  type: AuthActionTypes.GET_TRAINER_DETAILS_SUCCESS,
  payload: user,
});

export const getTrainerDetailsFailure = (error) => ({
  type: AuthActionTypes.GET_TRAINER_DETAILS_FAILURE,
  payload: error,
});

// Update Trainer Details
export const updateTrainerDetailsRequest = (payload) => ({
  type: AuthActionTypes.UPDATE_TRAINER_DETAILS_REQUEST,
  payload: payload,
});

export const updateTrainerDetailsSuccess = (user) => ({
  type: AuthActionTypes.UPDATE_TRAINER_DETAILS_SUCCESS,
  payload: user,
});

export const updateTrainerDetailsFailure = (error) => ({
  type: AuthActionTypes.UPDATE_TRAINER_DETAILS_FAILURE,
  payload: error,
});

//-------------GetCoachCardWebsiteDetails-----------

export const getCoachCardWebsiteDetailsRequest = (payload) => ({
  type: AuthActionTypes.GET_COACH_CARD_WEBSITE_DETAILS_REQUEST,
  payload: payload,
});

export const getCoachCardWebsiteDetailsSuccess = (user) => ({
  type: AuthActionTypes.GET_COACH_CARD_WEBSITE_DETAILS_SUCCESS,
  payload: user,
});

export const getCoachCardWebsiteDetailsFailure = (error) => ({
  type: AuthActionTypes.GET_COACH_CARD_WEBSITE_DETAILS_FAILURE,
  payload: error,
});

export const getCoachCardWebsiteDetailsLoadingStart = () => ({
  type: AuthActionTypes.GET_COACH_CARD_WEBSITE_DETAILS_LOADING_START,
});

export const getCoachCardWebsiteDetailsLoadingStop = () => ({
  type: AuthActionTypes.GET_COACH_CARD_WEBSITE_DETAILS_LOADING_STOP,
});

//-----------------UpdateCoachCardWebsiteDetails---------------

export const updateCoachCardWebsiteDetailsRequest = (payload) => ({
  type: AuthActionTypes.UPDATE_COACH_CARD_WEBSITE_DETAILS_REQUEST,
  payload: payload,
});

export const updateCoachCardWebsiteDetailsSuccess = (user) => ({
  type: AuthActionTypes.UPDATE_COACH_CARD_WEBSITE_DETAILS_SUCCESS,
  payload: user,
});

export const updateCoachCardWebsiteDetailsFailure = (error) => ({
  type: AuthActionTypes.UPDATE_COACH_CARD_WEBSITE_DETAILS_FAILURE,
  payload: error,
});

export const updateCoachCardWebsiteDetailsLoadingStart = () => ({
  type: AuthActionTypes.UPDATE_COACH_CARD_WEBSITE_DETAILS_LODING_START,
});

export const updateCoachCardWebsiteDetailsLoadingStop =() => ({
  type: AuthActionTypes.UPDATE_COACH_CARD_WEBSITE_DETAILS_LOADING_STOP,
})

//-------------------GetCoachCardDetails--------------------

export const getCoachCardDetailsRequest = (payload) => ({
  type: AuthActionTypes.GET_COACH_CARD_DETAILS_REQUEST,
  payload: payload
})

export const getCoachCardDetailsSuccess = (user) => ({
  type: AuthActionTypes.GET_COACH_CARD_DETAILS_SUCCESS,
  payload: user,
})

export const getCoachCardDetailsFailure = (error) => ({
  type: AuthActionTypes.GET_COACH_CARD_DETAILS_FAILURE,
  payload: error,
});

export const getCoachCardDetailsLoadingStart = () => ({
  type:AuthActionTypes.GET_COACH_CARD_DETAILS_LOADING_START,
});

export const getCoachCardDetailsLoadingStop = () => ({
  type: AuthActionTypes.GET_COACH_CARD_DETAILS_LOADING_STOP,
})

//------------updateCoachCardDetails-----------

export const updateCoachCardDetailsRequest = (payload) =>({
  type: AuthActionTypes.UPDATE_COACH_CARD_DETAILS_REQUEST,
  payload
});

export const updateCoachCardDetailsSuccess = (user) => ({
  type: AuthActionTypes.UPDATE_COACH_CARD_DETAILS_SUCCESS,
  user
});

export const updateCoachCardDetailsFailure = (error) => ({
  type: AuthActionTypes.UPDATE_COACH_CARD_DETAILS_FAILURE, 
  error
})

export const updateCoachCardDetailsLoadingStart = () => ({
  type: AuthActionTypes.UPDATE_COACH_CARD_DETAILS_LOADING_START,
});

export const updateCoachCardDetailsLoadingStop = () => ({
  type: AuthActionTypes.UPDATE_COACH_CARD_DETAILS_LOADING_STOP,
});

// Add update, get, remove CoachCardQualification

export const addCoachCardQualificationRequest = (payload) => ({
  type: AuthActionTypes.ADD_COACH_CARD_QUALIFICATION_REQUEST,
  payload
});

export const addCoachCardQualificationSucess = (user) => ({
  type: AuthActionTypes.ADD_COACH_CARD_QUALIFICATION_SUCCESS,
  user
});

export const addCoachCardQualificationFailure = (error) => ({
  type: AuthActionTypes.ADD_COACH_CARD_QUALIFICATION_FAILURE,
  error
});

export const addCoachCardQualificationLoadingStart = () => ({
  type: AuthActionTypes.ADD_COACH_CARD_QUALIFICATION_LOADING_START,
});

export const addCoachCardQualificationLoadingStop = () => ({
  type: AuthActionTypes.ADD_COACH_CARD_QUALIFICATION_LOADING_STOP,
});

export const updateCoachCardQualificationRequest = (payload) => ({
  type: AuthActionTypes.UPDATE_COACH_CARD_QUALIFICATION_REQUEST,
  payload
});

export const updateCoachCardQualificationSucess = (user) => ({
  type: AuthActionTypes.UPDATE_COACH_CARD_QUALIFICATION_SUCCESS,
  user
});

export const updateCoachCardQualificationFailure = (error) => ({
  type: AuthActionTypes.UPDATE_COACH_CARD_QUALIFICATION_FAILURE,
  error
});

export const updateCoachCardQualificationLoadingStart = () => ({
  type: AuthActionTypes.UPDATE_COACH_CARD_QUALIFICATION_LOADING_START,
});

export const updateCoachCardQualificationLoadingStop = () => ({
  type: AuthActionTypes.UPDATE_COACH_CARD_QUALIFICATION_LOADING_STOP,
});

export const getCoachCardQualificationRequest = (payload) => ({
  type: AuthActionTypes.GET_COACH_CARD_QUALIFICATION_REQUEST,
  payload
});

export const getCoachCardQualificationSucess = (user) => ({
  type: AuthActionTypes.GET_COACH_CARD_QUALIFICATION_SUCCESS,
  user
});

export const getCoachCardQualificationFailure = (error) => ({
  type: AuthActionTypes.GET_COACH_CARD_QUALIFICATION_FAILURE,
  error
});

export const getCoachCardQualificationLoadingStart = () => ({
  type: AuthActionTypes.GET_COACH_CARD_QUALIFICATION_LOADING_START,
});

export const getCoachCardQualificationLoadingStop = () => ({
  type: AuthActionTypes.GET_COACH_CARD_QUALIFICATION_LOADING_STOP,
});

export const removeCoachCardQualificationRequest = (payload) => ({
  type: AuthActionTypes.REMOVE_COACH_CARD_QUALIFICATION_REQUEST,
  payload
});

export const removeCoachCardQualificationSucess = (payload) => ({
  type: AuthActionTypes.REMOVE_COACH_CARD_QUALIFICATION_SUCCESS,
  payload
});

export const removeCoachCardQualificationFailure = (error) => ({
  type: AuthActionTypes.REMOVE_COACH_CARD_QUALIFICATION_FAILURE,
  error
});

export const removeCoachCardQualificationLoadingStart = () => ({
  type: AuthActionTypes.REMOVE_COACH_CARD_QUALIFICATION_LOADING_START,
});

export const removeCoachCardQualificationLoadingStop = () => ({
  type: AuthActionTypes.REMOVE_COACH_CARD_QUALIFICATION_LOADING_STOP,
});

// Password Reset Loading
export const passwordResetLoadingStart = () => ({
  type: AuthActionTypes.PASSWORD_RESET_LOADING_START,
});

export const passwordResetLoadingStop = () => ({
  type: AuthActionTypes.PASSWORD_RESET_LOADING_STOP,
});

// Password Reset
export const passwordResetRequest = (payload) => ({
  type: AuthActionTypes.PASSWORD_RESET_REQUEST,
  payload,
});

export const passwordResetSuccess = (payload) => ({
  type: AuthActionTypes.PASSWORD_RESET_SUCCESS,
  payload,
});

export const passwordResetFailure = (error) => ({
  type: AuthActionTypes.PASSWORD_RESET_FAILURE,
  payload: error,
});

// Sign In
export const signInRequest = (payload) => ({
  type: AuthActionTypes.SIGN_IN_REQUEST,
  payload: payload,
});

export const signInSuccess = (user) => ({
  type: AuthActionTypes.SIGN_IN_SUCCESS,
  payload: user,
});

export const signInFailure = (error) => ({
  type: AuthActionTypes.SIGN_IN_FAILURE,
  payload: error,
});

export const signInNull = (error) => ({
  type: AuthActionTypes.SIGN_IN_NULL,
  payload: error,
});

// Get Address Details
export const getAddressDetailsRequest = (payload) => ({
  type: AuthActionTypes.GET_ADDRESS_DETAILS_REQUEST,
  payload: payload,
});

export const getAddressDetailsSuccess = (user) => ({
  type: AuthActionTypes.GET_ADDRESS_DETAILS_SUCCESS,
  payload: user,
});

export const getAddressDetailsFailure = (error) => ({
  type: AuthActionTypes.GET_ADDRESS_DETAILS_FAILURE,
  payload: error,
});

export const getAddressDetailsLoadingStart = () => ({
  type: AuthActionTypes.GET_ADDRESS_DETAILS_LOADING_START,
});

export const getAddressDetailsLoadingStop = () => ({
  type: AuthActionTypes.GET_ADDRESS_DETAILS_LOADING_STOP,
});

// Update Address Details
export const updateAddressDetailsRequest = (payload) => ({
  type: AuthActionTypes.UPDATE_ADDRESS_DETAILS_REQUEST,
  payload: payload,
});

export const updateAddressDetailsSuccess = (user) => ({
  type: AuthActionTypes.UPDATE_ADDRESS_DETAILS_SUCCESS,
  payload: user,
});

export const updateAddressDetailsFailure = (error) => ({
  type: AuthActionTypes.UPDATE_ADDRESS_DETAILS_FAILURE,
  payload: error,
});

export const updateAddressDetailsLoadingStart = () => ({
  type: AuthActionTypes.UPDATE_ADDRESS_DETAILS_LOADING_START,
});

export const updateAddressDetailsLoadingStop = () => ({
  type: AuthActionTypes.UPDATE_ADDRESS_DETAILS_LOADING_STOP,
});

// Sign In
export const tokenLoginRequest = (payload) => ({
  type: AuthActionTypes.TOKEN_LOGIN_REQUEST,
  payload: payload,
});

export const tokenLoginSuccess = (user) => ({
  type: AuthActionTypes.TOKEN_LOGIN_SUCCESS,
  payload: user,
});

export const tokenLoginFailure = (error) => ({
  type: AuthActionTypes.TOKEN_LOGIN_FAILURE,
  payload: error,
});

// Sign Existing Plan
export const signInExistingPlanRequest = (payload) => ({
  type: AuthActionTypes.SIGN_IN_EXISTING_PLAN_REQUEST,
  payload: payload,
});

export const signInExistingPlanSuccess = (response) => ({
  type: AuthActionTypes.SIGN_IN_EXISTING_PLAN_SUCCESS,
  payload: response,
});

export const signInExistingPlanFailure = (error) => ({
  type: AuthActionTypes.SIGN_IN_EXISTING_PLAN_FAILURE,
  payload: error,
});

// Change Password
export const changePasswordRequest = (payload) => ({
  type: AuthActionTypes.CHANGE_PASSWORD_REQUEST,
  payload: payload,
});

export const changePasswordSuccess = (user) => ({
  type: AuthActionTypes.CHANGE_PASSWORD_SUCCESS,
  payload: user,
});

export const changePasswordFailure = (error) => ({
  type: AuthActionTypes.CHANGE_PASSWORD_FAILURE,
  payload: error,
});

// Trainer Sign Up
export const trainerSignUpRequest = (payload) => ({
  type: AuthActionTypes.TRAINER_SIGN_UP_REQUEST,
  payload: payload,
});

export const trainerSignUpSuccess = (token) => ({
  type: AuthActionTypes.TRAINER_SIGN_UP_SUCCESS,
  payload: token,
});

export const trainerSignUpFailure = (error) => ({
  type: AuthActionTypes.TRAINER_SIGN_UP_FAILURE,
  payload: error,
});

// Trainer Profile Edit
export const trainerProfileEditRequest = (payload) => ({
  type: AuthActionTypes.TRAINER_DETAILS_EDIT_REQUEST,
  payload: payload,
});

export const trainerProfileEditSuccess = (user) => ({
  type: AuthActionTypes.TRAINER_DETAILS_EDIT_SUCCESS,
  payload: user,
});

export const trainerProfileEditFailure = (error) => ({
  type: AuthActionTypes.TRAINER_DETAILS_EDIT_FAILURE,
  payload: error,
});

// Get User Details By Trainer
export const getUsersByTrainerRequest = (payload) => ({
  type: AuthActionTypes.GET_USERS_BY_TRAINER_REQUEST,
  payload: payload,
});

export const getUsersByTrainerSuccess = (user) => ({
  type: AuthActionTypes.GET_USERS_BY_TRAINER_SUCCESS,
  payload: user,
});

export const getUsersByTrainerFailure = (error) => ({
  type: AuthActionTypes.GET_USERS_BY_TRAINER_FAILURE,
  payload: error,
});

// Get User Details By Trainer
export const getUserDetailsByTrainerRequest = (payload) => ({
  type: AuthActionTypes.GET_USER_DETAILS_BY_TRAINER_REQUEST,
  payload: payload,
});

export const getUserDetailsByTrainerSuccess = (user) => ({
  type: AuthActionTypes.GET_USER_DETAILS_BY_TRAINER_SUCCESS,
  payload: user,
});

export const getUserDetailsByTrainerFailure = (error) => ({
  type: AuthActionTypes.GET_USER_DETAILS_BY_TRAINER_FAILURE,
  payload: error,
});

// Get Client Details
export const getClientDetailsRequest = (payload) => ({
  type: AuthActionTypes.GET_CLIENT_DETAILS_REQUEST,
  payload: payload,
});

export const getClientDetailsSuccess = (user) => ({
  type: AuthActionTypes.GET_CLIENT_DETAILS_SUCCESS,
  payload: user,
});

export const getClientDetailsFailure = (error) => ({
  type: AuthActionTypes.GET_CLIENT_DETAILS_FAILURE,
  payload: error,
});

export const getClientDetailsLoadingStart = () => ({
  type: AuthActionTypes.GET_CLIENT_DETAILS_LOADING_START,
});

export const getClientDetailsLoadingStop = () => ({
  type: AuthActionTypes.GET_CLIENT_DETAILS_LOADING_STOP,
});

// Update User Details by Trainer
export const updateUserDetailsByTrainerRequest = (payload) => ({
  type: AuthActionTypes.UPDATE_USER_DETAILS_BY_TRAINER_REQUEST,
  payload: payload,
});

export const updateUserDetailsByTrainerSuccess = (user) => ({
  type: AuthActionTypes.UPDATE_USER_DETAILS_BY_TRAINER_SUCCESS,
  payload: user,
});

export const updateUserDetailsByTrainerFailure = (error) => ({
  type: AuthActionTypes.UPDATE_USER_DETAILS_BY_TRAINER_FAILURE,
  payload: error,
});

// Get invoice Details
export const getInvoicesByTrainerRequest = (payload) => ({
  type: AuthActionTypes.GET_INVOICES_BY_TRAINER_REQUEST,
  payload: payload,
});

export const getPaidInvoicesByTrainerRequest = (payload) => ({
  type: AuthActionTypes.GET_PAID_INVOICES_BY_TRAINER_REQUEST,
  payload: payload,
});

export const getInvoicesByTrainerSuccess = (user) => ({
  type: AuthActionTypes.GET_INVOICES_BY_TRAINER_SUCCESS,
  payload: user,
});

export const clearInvoicesByTrainer = () => ({
  type: AuthActionTypes.CLEAR_INVOICES_BY_TRAINER,
});

export const getInvoicesByTrainerFailure = (error) => ({
  type: AuthActionTypes.GET_INVOICES_BY_TRAINER_FAILURE,
  payload: error,
});

// Get user invoice Details
export const getInvoicesOfUserByTrainerRequest = (payload) => ({
  type: AuthActionTypes.GET_INVOICES_OF_USER_BY_TRAINER_REQUEST,
  payload: payload,
});

export const getInvoicesOfUserByTrainerSuccess = (user) => ({
  type: AuthActionTypes.GET_INVOICES_OF_USER_BY_TRAINER_SUCCESS,
  payload: user,
});

export const getInvoicesOfUserByTrainerFailure = (error) => ({
  type: AuthActionTypes.GET_INVOICES_OF_USER_BY_TRAINER_FALIURE,
  payload: error,
});

// Trainer Feedback
export const trainerFeedbackRequest = (payload) => ({
  type: AuthActionTypes.TRAINER_FEEDBACK_REQUEST,
  payload: payload,
});

export const trainerFeedbackSuccess = (user) => ({
  type: AuthActionTypes.TRAINER_FEEDBACK_SUCCESS,
  payload: user,
});

export const trainerFeedbackFailure = (error) => ({
  type: AuthActionTypes.TRAINER_FEEDBACK_FAILURE,
  payload: error,
});

// Get Days Routine for User By Trainer
export const getDaysRoutineForUserByTrainerRequest = (payload) => ({
  type: AuthActionTypes.GET_DAYS_ROUTINE_FOR_USER_BY_TRAINER_REQUEST,
  payload: payload,
});

export const getDaysRoutineForUserByTrainerSuccess = (user) => ({
  type: AuthActionTypes.GET_DAYS_ROUTINE_FOR_USER_BY_TRAINER_SUCCESS,
  payload: user,
});

export const getDaysRoutineForUserByTrainerFailure = (error) => ({
  type: AuthActionTypes.GET_DAYS_ROUTINE_FOR_USER_BY_TRAINER_FAILURE,
  payload: error,
});

export const getDaysRoutineForUserByTrainerLoadingStart = () => ({
  type: AuthActionTypes.GET_DAYS_ROUTINE_FOR_USER_BY_TRAINER_LOADING_START,
});

export const getDaysRoutineForUserByTrainerLoadingStop = () => ({
  type: AuthActionTypes.GET_DAYS_ROUTINE_FOR_USER_BY_TRAINER_LOADING_STOP,
});

// Update Days Routine for User By Trainer
export const updateDaysRoutineForUserByTrainerRequest = (payload) => ({
  type: AuthActionTypes.UPDATE_DAYS_ROUTINE_FOR_USER_BY_TRAINER_REQUEST,
  payload: payload,
});

export const updateDaysRoutineForUserByTrainerSuccess = (user) => ({
  type: AuthActionTypes.UPDATE_DAYS_ROUTINE_FOR_USER_BY_TRAINER_SUCCESS,
  payload: user,
});

export const updateDaysRoutineForUserByTrainerFailure = (error) => ({
  type: AuthActionTypes.UPDATE_DAYS_ROUTINE_FOR_USER_BY_TRAINER_FAILURE,
  payload: error,
});

export const updateDaysRoutineForUserByTrainerLoadingStart = () => ({
  type: AuthActionTypes.UPDATE_DAYS_ROUTINE_FOR_USER_BY_TRAINER_LOADING_START,
});

export const updateDaysRoutineForUserByTrainerLoadingStop = () => ({
  type: AuthActionTypes.UPDATE_DAYS_ROUTINE_FOR_USER_BY_TRAINER_LOADING_STOP,
});

// ADD Day Routine for User By Trainer
export const addDayRoutineForUserByTrainerRequest = (payload) => ({
  type: AuthActionTypes.ADD_DAY_ROUTINE_FOR_USER_BY_TRAINER_REQUEST,
  payload: payload,
});

export const addDayRoutineForUserByTrainerSuccess = (user) => ({
  type: AuthActionTypes.ADD_DAY_ROUTINE_FOR_USER_BY_TRAINER_SUCCESS,
  payload: user,
});

export const addDayRoutineForUserByTrainerFailure = (error) => ({
  type: AuthActionTypes.ADD_DAY_ROUTINE_FOR_USER_BY_TRAINER_FAILURE,
  payload: error,
});

export const addDaysRoutineForUserByTrainerLoadingStart = () => ({
  type: AuthActionTypes.ADD_DAYS_ROUTINE_FOR_USER_BY_TRAINER_LOADING_START,
});

export const addDaysRoutineForUserByTrainerLoadingStop = () => ({
  type: AuthActionTypes.ADD_DAYS_ROUTINE_FOR_USER_BY_TRAINER_LOADING_STOP,
});

// Delete Day Routine for User By Trainer
export const deleteDayRoutineForUserByTrainerRequest = (payload) => ({
  type: AuthActionTypes.DELETE_DAY_ROUTINE_FOR_USER_BY_TRAINER_REQUEST,
  payload: payload,
});

export const deleteDayRoutineForUserByTrainerSuccess = (user) => ({
  type: AuthActionTypes.DELETE_DAY_ROUTINE_FOR_USER_BY_TRAINER_SUCCESS,
  payload: user,
});

export const deleteDayRoutineForUserByTrainerFailure = (error) => ({
  type: AuthActionTypes.DELETE_DAY_ROUTINE_FOR_USER_BY_TRAINER_FAILURE,
  payload: error,
});

// Get Weight Graph Details
export const getWeightGraphDetailsRequest = (payload) => ({
  type: AuthActionTypes.GET_WEIGHT_GRAPH_DETAILS_REQUEST,
  payload: payload,
});

export const getWeightGraphDetailsSuccess = (user) => ({
  type: AuthActionTypes.GET_WEIGHT_GRAPH_DETAILS_SUCCESS,
  payload: user,
});

export const getWeightGraphDetailsFailure = (error) => ({
  type: AuthActionTypes.GET_WEIGHT_GRAPH_DETAILS_FAILURE,
  payload: error,
});

// Sign Out
export const signOutRequest = () => ({
  type: AuthActionTypes.SIGN_OUT_REQUEST,
});

export const signOutSuccess = () => {
  return {
    type: AuthActionTypes.SIGN_OUT_SUCCESS,
  };
};

export const signOutFailure = (error) => ({
  type: AuthActionTypes.SIGN_OUT_FAILURE,
  payload: error,
});

// Get Trainers Details
export const getTrainerProfileRequest = (payload) => ({
  type: AuthActionTypes.GET_TRAINER_PROFILE_REQUEST,
  payload: payload,
});

export const getTrainerProfileSuccess = (payload) => ({
  type: AuthActionTypes.GET_TRAINER_PROFILE_SUCCESS,
  payload: payload,
});

export const getTrainerProfileFailure = (error) => ({
  type: AuthActionTypes.GET_TRAINER_PROFILE_FAILURE,
  payload: error,
});

// Registration Part 1
export const registration1Request = (payload) => ({
  type: AuthActionTypes.REGISTRATION_PART1_REQUEST,
  payload: payload,
});

export const registration1Success = (token) => {
  return {
    type: AuthActionTypes.REGISTRATION_PART1_SUCCESS,
    payload: token,
  };
};

export const registration1Failure = (error) => ({
  type: AuthActionTypes.REGISTRATION_PART1_FAILURE,
  payload: error,
});

export const registrationOverrideEmail = (payload) => ({
  type: AuthActionTypes.REGISTRATION_OVERRIDE_EMAIL,
  payload,
});

// Create User Anamnese After Registration Part 1
export const createUserAnamneseRequest = (payload) => ({
  type: AuthActionTypes.CREATE_USER_ANAMNESE_REQUEST,
  payload: payload,
});

export const createUserAnamneseSuccess = (payload) => {
  return {
    type: AuthActionTypes.CREATE_USER_ANAMNESE_SUCCESS,
    payload: payload,
  };
};

export const createUserAnamneseFailure = (error) => ({
  type: AuthActionTypes.CREATE_USER_ANAMNESE_FAILURE,
  payload: error,
});

// Get User Anamnese After Registration Part 1
export const getUserAnamneseRequest = (payload) => ({
  type: AuthActionTypes.GET_USER_ANAMNESE_REQUEST,
  payload: payload,
});

export const getUserAnamneseSuccess = (payload) => {
  return {
    type: AuthActionTypes.GET_USER_ANAMNESE_SUCCESS,
    payload: payload,
  };
};

export const getUserAnamneseFailure = (error) => ({
  type: AuthActionTypes.GET_USER_ANAMNESE_FAILURE,
  payload: error,
});

export const getWeekWiseImagesLoadingStart = () => ({
  type: AuthActionTypes.GET_WEEK_WISE_IMAGES_LOADING_START,
});

export const getWeekWiseImagesLoadingStop = () => ({
  type: AuthActionTypes.GET_WEEK_WISE_IMAGES_LOADING_STOP,
});

// Week Wise Images
export const getWeekWiseImagesRequest = (payload) => ({
  type: AuthActionTypes.GET_WEEK_WISE_IMAGES_REQUEST,
  payload: payload,
});

export const getWeekWiseImagesSuccess = (user) => ({
  type: AuthActionTypes.GET_WEEK_WISE_IMAGES_SUCCESS,
  payload: user,
});

export const getWeekWiseImagesFailure = (error) => ({
  type: AuthActionTypes.GET_WEEK_WISE_IMAGES_FAILURE,
  payload: error,
});

// ------------------------------------------

export const getBodyMeasurementsLoadingStart = () => ({
  type: AuthActionTypes.GET_BODY_MEASUREMENTS_LOADING_START,
});

export const getBodyMeasurementsLoadingStop = () => ({
  type: AuthActionTypes.GET_BODY_MEASUREMENTS_LOADING_STOP,
});

// Body Measurements
export const getBodyMeasurementsRequest = (payload) => ({
  type: AuthActionTypes.GET_BODY_MEASUREMENTS_REQUEST,
  payload: payload,
});

export const getBodyMeasurementsSuccess = (user) => ({
  type: AuthActionTypes.GET_BODY_MEASUREMENTS_SUCCESS,
  payload: user,
});

export const getBodyMeasurementsFailure = (error) => ({
  type: AuthActionTypes.GET_BODY_MEASUREMENTS_FAILURE,
  payload: error,
});

// ------------------------------------------

export const getUserWeightWeekWiseLoadingStart = () => ({
  type: AuthActionTypes.GET_USER_WEIGHT_WEEK_WISE_LOADING_START,
});

export const getUserWeightWeekWiseLoadingStop = () => ({
  type: AuthActionTypes.GET_USER_WEIGHT_WEEK_WISE_LOADING_STOP,
});

// User Week WIse Weight
export const getUserWeightWeekWiseRequest = (payload) => ({
  type: AuthActionTypes.GET_USER_WEIGHT_WEEK_WISE_REQUEST,
  payload: payload,
});

export const getUserWeightWeekWiseSuccess = (user) => ({
  type: AuthActionTypes.GET_USER_WEIGHT_WEEK_WISE_SUCCESS,
  payload: user,
});

export const getUserWeightWeekWiseFailure = (error) => ({
  type: AuthActionTypes.GET_USER_WEIGHT_WEEK_WISE_FAILURE,
  payload: error,
});

// ------------------------------------------

export const getUserWeightGoalDataLoadingStart = () => ({
  type: AuthActionTypes.GET_USER_WEIGHT_GOAL_DATA_LOADING_START,
});

export const getUserWeightGoalDataLoadingStop = () => ({
  type: AuthActionTypes.GET_USER_WEIGHT_GOAL_DATA_LOADING_STOP,
});

// User Weight Goal Data
export const getUserWeightGoalDataRequest = (payload) => ({
  type: AuthActionTypes.GET_USER_WEIGHT_GOAL_DATA_REQUEST,
  payload: payload,
});

export const getUserWeightGoalDataSuccess = (user) => ({
  type: AuthActionTypes.GET_USER_WEIGHT_GOAL_DATA_SUCCESS,
  payload: user,
});

export const getUserWeightGoalDataFailure = (error) => ({
  type: AuthActionTypes.GET_USER_WEIGHT_GOAL_DATA_FAILURE,
  payload: error,
});

// Get Influencers
export const getInfluencersRequest = (payload) => ({
  type: AuthActionTypes.GET_INFLUENCERS_REQUEST,
  payload: payload,
});

export const getInfluencersSuccess = (res) => ({
  type: AuthActionTypes.GET_INFLUENCERS_SUCCESS,
  payload: res,
});

export const getInfluencersFailure = (error) => ({
  type: AuthActionTypes.GET_INFLUENCERS_FAILURE,
  payload: error,
});

export const getInfluencersLoadingStart = () => ({
  type: AuthActionTypes.GET_INFLUENCERS_LOADING_START,
});

export const getInfluencersLoadingStop = () => ({
  type: AuthActionTypes.GET_INFLUENCERS_LOADING_STOP,
});

// Get Influencer Details
export const getInfluencerDetailsRequest = (payload) => ({
  type: AuthActionTypes.GET_INFLUENCER_DETAILS_REQUEST,
  payload: payload,
});

export const getInfluencerDetailsSuccess = (res) => ({
  type: AuthActionTypes.GET_INFLUENCER_DETAILS_SUCCESS,
  payload: res,
});

export const getInfluencerDetailsFailure = (error) => ({
  type: AuthActionTypes.GET_INFLUENCER_DETAILS_FAILURE,
  payload: error,
});

export const getInfluencerDetailsLoadingStart = () => ({
  type: AuthActionTypes.GET_INFLUENCER_DETAILS_LOADING_START,
});

export const getInfluencerDetailsLoadingStop = () => ({
  type: AuthActionTypes.GET_INFLUENCER_DETAILS_LOADING_STOP,
});

// Get First Meeting URL byTrainer ID
export const getFirstMeetingURLByTrainerIDRequest = (payload) => ({
  type: AuthActionTypes.GET_FIRST_MEETING_URL_BY_TRAINER_ID_REQUEST,
  payload: payload,
});

export const getFirstMeetingURLByTrainerIDSuccess = (res) => ({
  type: AuthActionTypes.GET_FIRST_MEETING_URL_BY_TRAINER_ID_SUCCESS,
  payload: res,
});

export const getFirstMeetingURLByTrainerIDFailure = (error) => ({
  type: AuthActionTypes.GET_FIRST_MEETING_URL_BY_TRAINER_ID_FAILURE,
  payload: error,
});

export const getFirstMeetingURLByTrainerIDLoadingStart = () => ({
  type: AuthActionTypes.GET_FIRST_MEETING_URL_BY_TRAINER_ID_LOADING_START,
});

export const getFirstMeetingURLByTrainerIDLoadingStop = () => ({
  type: AuthActionTypes.GET_FIRST_MEETING_URL_BY_TRAINER_ID_LOADING_STOP,
});

// Get Weekly Calls
export const getWeeklyCallsRequest = (payload) => ({
  type: AuthActionTypes.GET_WEEKLY_CALLS_FOR_SUGGESTIONS_REQUEST,
  payload: payload,
});

export const getWeeklyCallsSuccess = (res) => ({
  type: AuthActionTypes.GET_WEEKLY_CALLS_FOR_SUGGESTIONS_SUCCESS,
  payload: res,
});

export const getWeeklyCallsFailure = (error) => ({
  type: AuthActionTypes.GET_WEEKLY_CALLS_FOR_SUGGESTIONS_FAILURE,
  payload: error,
});

export const getWeeklyCallsLoadingStart = () => ({
  type: AuthActionTypes.GET_WEEKLY_CALLS_FOR_SUGGESTIONS_LOADING_START,
});

export const getWeeklyCallsLoadingStop = () => ({
  type: AuthActionTypes.GET_WEEKLY_CALLS_FOR_SUGGESTIONS_LOADING_STOP,
});

// Academy

// Categories
export const getLessonsCategoriesLoadingStart = () => ({
  type: AuthActionTypes.GET_LESSONS_CATEGORIES_LOADING_START,
});

export const getLessonsCategoriesLoadingStop = () => ({
  type: AuthActionTypes.GET_LESSONS_CATEGORIES_LOADING_STOP,
});

export const getLessonsCategoriesRequest = (payload) => ({
  type: AuthActionTypes.GET_LESSONS_CATEGORIES_REQUEST,
  payload,
});

export const getLessonsCategoriesSuccess = (response) => ({
  type: AuthActionTypes.GET_LESSONS_CATEGORIES_SUCCESS,
  payload: response,
});

export const getLessonsCategoriesFailure = (error) => ({
  type: AuthActionTypes.GET_LESSONS_CATEGORIES_FAILURE,
  payload: error,
});

// Lessons

export const getLessonsByCategoryIdLoadingStart = () => ({
  type: AuthActionTypes.GET_LESSONS_BY_CATEGORY_ID_LOADING_START,
});

export const getLessonsByCategoryIdLoadingStop = () => ({
  type: AuthActionTypes.GET_LESSONS_BY_CATEGORY_ID_LOADING_STOP,
});

export const getLessonsByCategoryIdRequest = (payload) => ({
  type: AuthActionTypes.GET_LESSONS_BY_CATEGORY_ID_REQUEST,
  payload,
});

export const getLessonsByCategoryIdSuccess = (response) => ({
  type: AuthActionTypes.GET_LESSONS_BY_CATEGORY_ID_SUCCESS,
  payload: response,
});

export const getLessonsByCategoryIdFailure = (error) => ({
  type: AuthActionTypes.GET_LESSONS_BY_CATEGORY_ID_FAILURE,
  payload: error,
});

// Lesson Details

export const getLessonDetailsLoadingStart = () => ({
  type: AuthActionTypes.GET_LESSON_DETAILS_LOADING_START,
});

export const getLessonDetailsLoadingStop = () => ({
  type: AuthActionTypes.GET_LESSON_DETAILS_LOADING_STOP,
});

export const getLessonDetailsRequest = (payload) => ({
  type: AuthActionTypes.GET_LESSON_DETAILS_REQUEST,
  payload,
});

export const getLessonDetailsSuccess = (response) => ({
  type: AuthActionTypes.GET_LESSON_DETAILS_SUCCESS,
  payload: response,
});

export const getLessonDetailsFailure = (error) => ({
  type: AuthActionTypes.GET_LESSON_DETAILS_FAILURE,
  payload: error,
});

// Lesson Downloads

export const getDownloadableLessonsLoadingStart = () => ({
  type: AuthActionTypes.GET_DOWNLOADABLE_LESSON_LOADING_START,
});

export const getDownloadableLessonsLoadingStop = () => ({
  type: AuthActionTypes.GET_DOWNLOADABLE_LESSON_LOADING_STOP,
});

export const getDownloadableLessonsRequest = (payload) => ({
  type: AuthActionTypes.GET_DOWNLOADABLE_LESSON_REQUEST,
  payload,
});

export const getDownloadableLessonsSuccess = (response) => ({
  type: AuthActionTypes.GET_DOWNLOADABLE_LESSON_SUCCESS,
  payload: response,
});

export const getDownloadableLessonsFailure = (error) => ({
  type: AuthActionTypes.GET_DOWNLOADABLE_LESSON_FAILURE,
  payload: error,
});

// Social Media

// --------------

export const getAllInstagramPostsRequest = (payload) => ({
  type: AuthActionTypes.GET_ALL_INSTAGRAM_POSTS_REQUEST,
  payload,
});

export const getAllInstagramPostsSuccess = (payload) => ({
  type: AuthActionTypes.GET_ALL_INSTAGRAM_POSTS_SUCCESS,
  payload,
});

export const getAllInstagramPostsFailure = (error) => ({
  type: AuthActionTypes.GET_ALL_INSTAGRAM_POSTS_FAILURE,
  payload: error,
});

export const getAllInstagramPostsLoadingStart = () => ({
  type: AuthActionTypes.GET_ALL_INSTAGRAM_POSTS_LOADING_START,
});

export const getAllInstagramPostsLoadingStop = () => ({
  type: AuthActionTypes.GET_ALL_INSTAGRAM_POSTS_LOADING_STOP,
});

// --------------

export const getAllInstagramCategoriesRequest = (payload) => ({
  type: AuthActionTypes.GET_ALL_INSTAGRAM_CATEGORIES_REQUEST,
  payload,
});

export const getAllInstagramCategoriesSuccess = (payload) => ({
  type: AuthActionTypes.GET_ALL_INSTAGRAM_CATEGORIES_SUCCESS,
  payload,
});

export const getAllInstagramCategoriesFailure = (error) => ({
  type: AuthActionTypes.GET_ALL_INSTAGRAM_CATEGORIES_FAILURE,
  payload: error,
});

export const getAllInstagramCategoriesLoadingStart = () => ({
  type: AuthActionTypes.GET_ALL_INSTAGRAM_CATEGORIES_LOADING_START,
});

export const getAllInstagramCategoriesLoadingStop = () => ({
  type: AuthActionTypes.GET_ALL_INSTAGRAM_CATEGORIES_LOADING_STOP,
});

// --------------

export const getAllInstagramPostsByCategoriesRequest = (payload) => ({
  type: AuthActionTypes.GET_ALL_INSTAGRAM_POSTS_BY_CATEGORIES_REQUEST,
  payload,
});

export const getAllInstagramPostsByCategoriesSuccess = (response) => ({
  type: AuthActionTypes.GET_ALL_INSTAGRAM_POSTS_BY_CATEGORIES_SUCCESS,
  payload: response,
});

export const getAllInstagramPostsByCategoriesFailure = (error) => ({
  type: AuthActionTypes.GET_ALL_INSTAGRAM_POSTS_BY_CATEGORIES_FAILURE,
  payload: error,
});

export const getAllInstagramPostsByCategoriesLoadingStart = () => ({
  type: AuthActionTypes.GET_ALL_INSTAGRAM_POSTS_BY_CATEGORIES_LOADING_START,
});

export const getAllInstagramPostsByCategoriesLoadingStop = () => ({
  type: AuthActionTypes.GET_ALL_INSTAGRAM_POSTS_BY_CATEGORIES_LOADING_STOP,
});

// --------------

export const getInstagramSubCategoriesByCategoryRequest = (payload) => ({
  type: AuthActionTypes.GET_SUB_CATEGORIES_BY_PARENT_CATEGORY_REQUEST,
  payload,
});

export const getInstagramSubCategoriesByCategorySuccess = (response) => ({
  type: AuthActionTypes.GET_SUB_CATEGORIES_BY_PARENT_CATEGORY_SUCCESS,
  payload: response,
});

export const getInstagramSubCategoriesByCategoryFailure = (error) => ({
  type: AuthActionTypes.GET_SUB_CATEGORIES_BY_PARENT_CATEGORY_FAILURE,
  payload: error,
});

export const getInstagramSubCategoriesByCategoryLoadingStart = () => ({
  type: AuthActionTypes.GET_SUB_CATEGORIES_BY_PARENT_CATEGORY_LOADING_START,
});

export const getInstagramSubCategoriesByCategoryLoadingStop = () => ({
  type: AuthActionTypes.GET_SUB_CATEGORIES_BY_PARENT_CATEGORY_LOADING_STOP,
});

export const trainerConnectedAccount = (id) => ({
  type: AuthActionTypes.UPDATE_CONNECTED_ACCOUNT,
  payload: { trainerID: id },
});

export const updateConnectedAccountSuccess = (res) => ({
  type: AuthActionTypes.UPDATE_CONNECTED_ACCOUNT_SUCCESS,
  payload: res,
});
export const trainerConnectedAccountClear = () => ({
  type: AuthActionTypes.UPDATE_CONNECTED_ACCOUNT_CLEAR,
});

export const userConnectedAccount = (id) => ({
  type: AuthActionTypes.UPDATE_USER_CONNECTED_ACCOUNT,
  payload: { trainerID: id },
});

export const updateUserConnectedAccountSuccess = (res) => ({
  type: AuthActionTypes.UPDATE_USER_CONNECTED_ACCOUNT_SUCCESS,
  payload: res,
});
export const userConnectedAccountClear = () => ({
  type: AuthActionTypes.UPDATE_USER_CONNECTED_ACCOUNT_CLEAR,
});
