import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  setUserRegistrationDataInLocalStorage,
  getUserRegistrationDataFromLocalStorage,
} from "configs/utils";
import Select from "react-select";
// Components
import Button from "components/formComponents/button/Button";
import TranslationMessage from "configs/translations/TranslationMessage";

import FoodiaryLogo from "assets/images/Foodiary-Logo.PNG";
import ArrowLeft from "assets/images/arrowLeft.svg";
import { SidebarSteps, DailyActivitiesTime } from "../data";
// Redux
import * as S from "../OnBoardingPartOne.styles";
import OnboardingSidebar from "../OnboardingSidebar";

const ActivityLevel = (props) => {
  const history = useHistory();
  const registrationData = useSelector((state) => state.auth.registrationData);

  const [sleepValue, setSleepValue] = useState(null);
  const [lyingDownValue, setLyingDownValue] = useState(null);
  const [standingValue, setStandingValue] = useState(null);
  const [sittingValue, setSittingValue] = useState(null);
  const [walkingValue, setWalkingValue] = useState(null);
  const [exercisingValue, setExercisingValue] = useState(null);

  const [isError, setIsError] = useState(false);

  const [ActivityTimeOptions, SetActivityTimeOptions] = useState([]);

  const totalHours =
    (sleepValue?.value || 0) +
      (lyingDownValue?.value || 0) +
      (standingValue?.value || 0) +
      (sittingValue?.value || 0) +
      (walkingValue?.value || 0) +
      (exercisingValue?.value || 0) || "00";

  useEffect(() => {
    SetActivityTimeOptions(
      Array.from({ length: 25 }, (_, i) => ({
        value: i,
        label: `${i} h`,
      }))
    );

    const dailyActivities =
      getUserRegistrationDataFromLocalStorage()?.dailyActivitiesData;

    if (!!dailyActivities) {
      setSleepValue(dailyActivities?.sleepValue);
      setLyingDownValue(dailyActivities?.lyingDownValue);
      setStandingValue(dailyActivities?.standingValue);
      setSittingValue(dailyActivities?.sittingValue);
      setWalkingValue(dailyActivities?.walkingValue);
      setExercisingValue(dailyActivities?.exercisingValue);
    }
  }, []);

  const handleActivityLevelValueChange = (v, activityLevelId) => {
    setIsError(false);
    switch (activityLevelId) {
      case "registration.activityLevel.sleep":
        setSleepValue(v);
        break;
      case "registration.activityLevel.lyingDown":
        setLyingDownValue(v);
        break;
      case "registration.activityLevel.standing":
        setStandingValue(v);
        break;
      case "registration.activityLevel.siting":
        setSittingValue(v);
        break;
      case "registration.activityLevel.walking":
        setWalkingValue(v);
        break;
      case "registration.activityLevel.exercising":
        setExercisingValue(v);
        break;
      default:
        return null;
    }
  };

  const getSelectedValue = (id) => {
    const activityLevels = {
      "registration.activityLevel.sleep": sleepValue,
      "registration.activityLevel.lyingDown": lyingDownValue,
      "registration.activityLevel.standing": standingValue,
      "registration.activityLevel.siting": sittingValue,
      "registration.activityLevel.walking": walkingValue,
      "registration.activityLevel.exercising": exercisingValue,
    };

    return activityLevels[id];
  };

  const handleNext = () => {
    if (+totalHours !== 24) {
      setIsError(true);
      return;
    }

    // const isValid = [
    //   sleepValue,
    //   lyingDownValue,
    //   standingValue,
    //   sittingValue,
    //   walkingValue,
    //   exercisingValue,
    // ].every((v) => !!v);

    // if (!isValid) {
    //   return;
    // }

    setUserRegistrationDataInLocalStorage({
      ...getUserRegistrationDataFromLocalStorage(),
      dailyActivitiesData: {
        sleepValue,
        lyingDownValue,
        standingValue,
        sittingValue,
        walkingValue,
        exercisingValue,
      },
    });

    history.push("/onboarding/registration/training-days");
  };

  const handleBack = () => {
    history.push("/onboarding/registration/daily-routine");
  };

  return (
    <>
      <S.OnBoardingPage className="starter">
        <S.OnBoardingContainer className="onboarding-container">
          <OnboardingSidebar step={2} />
          <S.OnBoardingContent className="onboarding-content">
            <S.OnBoardingHeader className="onboarding-header">
              <div className="d-flex align-items-top gap-3">
                <img
                  className="cursor-pointer"
                  src={ArrowLeft}
                  alt="back"
                  onClick={handleBack}
                />
                <div className="content">
                  <span className="small-title text-green font-rubik-medium">
                    Anamnese
                  </span>
                  <h6 className="header-title font-rubik-medium my-0">
                    Wie aktiv bist du im Alltag?
                  </h6>
                </div>
              </div>
              <div className="actions">
                <Button
                  color="rgba(248, 164, 146, 1)"
                  gradient="linear-gradient(60deg, rgba(46, 207, 150, 1), rgba(28, 157, 135, 1)) !important"
                  margin="0"
                  absolute={{ width: "fit-content", height: "2.5rem" }}
                  buttonStyle={{ padding: ".5625rem 2.5rem" }}
                  onClick={handleNext}
                >
                  Weiter
                </Button>
              </div>
            </S.OnBoardingHeader>
            <S.OnBoardingBody className="onboarding-body">
              <div className="activity-level-content">
                <h3 className="text-size-20-28 text-dark-1 font-rubik-medium">
                  Wie setzt sich ein Tagesablauf zusammen
                </h3>
                <p className="text-size-16-19 text-light-grey-1 font-rubik-regular">
                  Wie gestaltest du einen durchschnittlichen Wochentag?
                </p>
                <br />
                {isError && (
                  <p className="text-size-14-18 text-right text-red font-rubik-regular">
                    Der Tag muss genau 24 Stunden haben.
                  </p>
                )}
                <div className="activity-level-items">
                  {DailyActivitiesTime.map((item, i) => (
                    <div className="activity-level-item" key={i}>
                      <h6 className="text-size-18-22 font-rubik-medium text-dark-2 my-0">
                        <TranslationMessage id={item.id} />
                      </h6>
                      <Select
                        options={ActivityTimeOptions}
                        className="faq-select"
                        classNamePrefix="react-select"
                        onChange={(v) =>
                          handleActivityLevelValueChange(v, item.id)
                        }
                        value={getSelectedValue(item.id)}
                        placeholder="0"
                        isSearchable={false}
                      />
                    </div>
                  ))}
                </div>
                <br />
                <div className="divider"></div>
                <br />
                <div className="total-activities-time d-flex align-items-center justify-content-between">
                  <div>
                    <p className="text-size-20-24 font-rubik-medium text-dark-2 my-0">
                      Gesamt
                    </p>
                    <p className="text-size-14-17 font-rubik-regular text-light-grey-1 mb-0 mt-1">
                      noch {24 - totalHours} Stunden
                    </p>
                  </div>
                  <div className="total-time text-size-16-19 font-rubik-medium text-dark-2">
                    {totalHours} Stunden
                  </div>
                </div>
              </div>
            </S.OnBoardingBody>
          </S.OnBoardingContent>
        </S.OnBoardingContainer>
      </S.OnBoardingPage>
    </>
  );
};

export default ActivityLevel;
