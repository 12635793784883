import styled from "styled-components";
import { RegistrationContainer, RegistrationHeading, RegistrationSubHeading, } from 'pages/registration/registration.styles';

export const SubscriptionContainer = styled(RegistrationContainer)``;

export const SubscriptionHeading = styled(RegistrationHeading)``;

export const SubscriptionSubHeading = styled(RegistrationSubHeading)``;

export const SubscriptionCardContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 20px;
    gap: 1.2rem;
`;

export const SubscriptionCardInnerHeadingContainer = styled.div`
    width: 100%;
    height: 103px;
    color: white;
    background-color: ${props => props.background ? props.background : 'none'};
`;

export const SubscriptionCardInnerHeading = styled.div`
    padding-top: 25px;
    text-align: center;
    font-family: RubikMedium;
    font-size: 20px;
`;

export const SubscriptionPlanTitle = styled.div`
    text-align: center;
    font-family: RubikMedium;
    font-size: 20px;
    color: white;
`;

export const SubscriptionPlanDuration = styled.div`
    text-align: center;
    font-family: RubikMedium;
    font-size: 16px;
    color: #2ECF96;
    margin-top: 8px;
`;

export const SubscriptionCardInnerSubHeading = styled.div`
    padding-top: 10px;
    text-align: center;
    font-family: RubikMedium;
    font-size: 16px;
`;

export const SubscriptionCardInnerDescription = styled.div`
    /* margin: 30px; */
    text-align: center;
    font-family: RubikRegular;
    color: #A5B5AF;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 16px;
`;

export const SubscriptionCardInnerBenefitsList = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
`;

export const SubscriptionCardBenefitsHeading = styled.div`
    font-family: RubikRegular;
    font-size: 20px;
    font-weight: 700;
    color: #142C1F;
    margin-bottom: 25px;
`;

export const SubscriptionCardInnerBenefitsListItem = styled.div`
    /* display: flex;
    flex-direction: row; */
    width: 100%;
    font-family: RubikRegular;
    font-size: 16px;
    color: #44554C;
    &:not(:last-child) {
        margin-bottom: 15px;
    }
`;

export const SubscriptionCardInnerBenefitsListItemCheck = styled.img`
    padding-right: 10px;
    width: 16px;
`;

export const SubscriptionCardInnerButtonContainer = styled.div`
    margin: 30px;
`;

export const SubscriptionCardInnerButtonIcon = styled.img`
    font-size: 20px;
    margin-right: 10px;
`;

export const SubscriptionSimpleCard = styled.div.attrs({
    className: `${(props) => props.className}`,
})`
    background: #FFFFFF;
    box-shadow: 0px 13px 20px #D6D6D629;
    border-radius: 8px;
    padding: 3.5rem 2rem;
    max-width: 300px;
    cursor: pointer;
    
    * {
        font-family: RubikRegular;
    }

    h6 {
        color: #142C1F;
        font-size: 1.1rem;
        margin-block: 1rem;
    }

    p {
        color: #44554C;
        font-size: 0.9rem;
        opacity: 0.7;
        
    }
`;

export const SVGIllustration = styled.div`
    display: flex;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 300px;
    height: 200px;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
`;

export const SubscriptionPrice = styled.div`
    text-align: center;
    margin-top: 1rem;   
    strong {
        color: #1C9D87;
        font-size: 24px;
    }

    p {
        color: #44554C;
        font-size: .9rem;
        margin: 3px 0 9px 0;
    }

    div {
        color: #A5B5AF;
        font-size: .8rem;
        font-weight: 300;
    }
`;