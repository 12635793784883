import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import * as S from "./subscriptionBasic.styles";
import { useIntl } from "react-intl";
import { getRandomValueInRange } from "configs/utils";

// Components
import Topbar from "components/topbar/Topbar";
import TranslationMessage from "configs/translations/TranslationMessage";
import Card from "components/card/Card";
import Button from "components/formComponents/button/Button";
import * as SupplementsActions from "redux/supplements/supplements.actions";
import * as AuthActions from "redux/auth/auth.actions";
// Images
import GreenCheck from "assets/images/GreenCheck.svg";
import CrossCheck from "assets/images/Foodiary-Cross.svg";
import NutritionDone from "assets/images/nutritionDone.svg";

const SubscriptionBasic = () => {
  const history = useHistory();
  const intl = useIntl();
  const dispatch = useDispatch();

  const [selectedCard, setSelectedCard] = useState("subscriptionPro");
  const [isLoading, setIsLoading] = useState(false);
  const trainerProfileData = useSelector((state) => state.auth.trainerData);
  const registrationData = useSelector((state) => state.auth.registrationData);
  const authData = useSelector((state) => state.auth);

  const onSubscriptionSelection = (subscription) => {
    // const SUBSCRIPTION_STATUS = {
    //     subscriptionBasic: 'BASIC-USER',
    //     subscriptionPro: 'PRO-USER',
    // }

    const formValues = {
      userID: registrationData?.userID,
      date: moment().format("YYYY-MM-DD"),
      subscribe_status: "BASIC-USER",
      // subscribe_status: SUBSCRIPTION_STATUS[subscription],
      trainerID:
        registrationData?.trainerID || authData?.trainerData?.trainerID,
    };

    // console.log("payload", formValues)
    setIsLoading(true);
    dispatch(
      SupplementsActions.updateUserSubscriptionRequest({
        formValues: formValues,
        func: () => {
          setIsLoading(false);
          history.push("/foodiary/family");
          // if (SUBSCRIPTION_STATUS[subscription] === "BASIC-USER") {
          //     history.push("/subscription/basic")
          // }
          // if (SUBSCRIPTION_STATUS[subscription] === "PRO-USER") {
          //     history.push("/subscription/pro")
          // }
        },
      })
    );

    // history.push('/subscription/pro')
    // history.push('/overview/basic')
  };

  const Benefits = [
    { value: "subscription.basic.benefits.1", isInclude: true },
    { value: "subscription.basic.benefits.2", isInclude: true },
    { value: "subscription.basic.benefits.3", isInclude: false },
    { value: "subscription.basic.benefits.4", isInclude: false },
    { value: "subscription.basic.benefits.5", isInclude: false },
    { value: "subscription.basic.benefits.6", isInclude: false },
    { value: "subscription.basic.benefits.7", isInclude: false },
    { value: "subscription.basic.benefits.8", isInclude: false },
  ];

  return (
    <>
      {/* selectedCard === 'subscriptionPro' ? "/subscription/details" : "/overview/basic" */}
      {/*  percent={getRandomValueInRange(40, 45)} healthReport  */}
      {/* onClose={() => history.push('/login')} close */}
      <Topbar
        prev="/subscriptions"
        onNext={() => onSubscriptionSelection(selectedCard)}
        next={"/foodiary/family"}
        progress={"73%"}
      />
      <S.SubscriptionContainer>
        <S.SubscriptionHeading>
          <TranslationMessage id="subscription.basic.page.heading" />
        </S.SubscriptionHeading>
        <S.SubscriptionSubHeading>
          <TranslationMessage id="subscription.basic.page.subHeading" />
        </S.SubscriptionSubHeading>
        {isLoading && (
          <>
            <br />
            <br />
            <br />
            <br />
            <div className="text-center">
              <div className="loader mx-auto"></div>
            </div>
            <br />
            <br />
            <br />
            <br />
          </>
        )}
        {!isLoading && (
          <>
            <S.SubscriptionCardContainer>
              <Card
                width="606px"
                margin="0"
                textAlign="left"
                style={{ padding: "24px", position: "relative" }}
              >
                <S.SubscriptionCardBenefitsHeading>
                  <TranslationMessage id={"subscription.basic.left.heading"} />
                </S.SubscriptionCardBenefitsHeading>
                <S.SubscriptionCardInnerBenefitsList>
                  {Benefits.map((benefit) => {
                    return (
                      <S.SubscriptionCardInnerBenefitsListItem
                        key={benefit.value}
                      >
                        <span>
                          <S.SubscriptionCardInnerBenefitsListItemCheck
                            src={benefit.isInclude ? GreenCheck : CrossCheck}
                          />
                        </span>
                        <TranslationMessage id={benefit.value} />
                      </S.SubscriptionCardInnerBenefitsListItem>
                    );
                  })}
                </S.SubscriptionCardInnerBenefitsList>
                <S.SVGIllustration>
                  <img src={NutritionDone} alt="" />
                </S.SVGIllustration>
              </Card>
              <Card width={"288px"} margin="0 10px" textAlign="left">
                <S.SubscriptionCardInnerHeadingContainer background={"#A5B5AF"}>
                  <S.SubscriptionCardInnerHeading>
                    <S.SubscriptionPlanTitle>
                      <TranslationMessage id={"subscription.basic.planTitle"} />
                    </S.SubscriptionPlanTitle>
                    <S.SubscriptionPlanDuration style={{ color: "#142C1F" }}>
                      <TranslationMessage
                        id={"subscription.basic.planDuration"}
                      />
                    </S.SubscriptionPlanDuration>
                  </S.SubscriptionCardInnerHeading>
                </S.SubscriptionCardInnerHeadingContainer>
                <div style={{ padding: "24px" }}>
                  <S.SubscriptionCardInnerDescription
                    style={{ textAlign: "center" }}
                  >
                    <TranslationMessage id="subscription.basic.planIncludes" />
                  </S.SubscriptionCardInnerDescription>
                  <S.SubscriptionCardInnerBenefitsList>
                    <S.SubscriptionCardInnerBenefitsListItem
                      style={{ marginLeft: "26px" }}
                    >
                      <span style={{ marginLeft: "-26px" }}>
                        <S.SubscriptionCardInnerBenefitsListItemCheck
                          src={GreenCheck}
                        />
                      </span>
                      <TranslationMessage
                        id={"subscription.basic.checkList.1"}
                      />
                    </S.SubscriptionCardInnerBenefitsListItem>
                    <S.SubscriptionCardInnerBenefitsListItem
                      style={{ marginLeft: "26px" }}
                    >
                      <span style={{ marginLeft: "-26px" }}>
                        <S.SubscriptionCardInnerBenefitsListItemCheck
                          src={GreenCheck}
                        />
                      </span>
                      <TranslationMessage
                        id={"subscription.basic.checkList.2"}
                      />
                    </S.SubscriptionCardInnerBenefitsListItem>
                    <S.SubscriptionCardInnerBenefitsListItem
                      style={{ marginLeft: "26px" }}
                    >
                      <span style={{ marginLeft: "-26px" }}>
                        <S.SubscriptionCardInnerBenefitsListItemCheck
                          src={GreenCheck}
                        />
                      </span>
                      <TranslationMessage
                        id={"subscription.basic.checkList.3"}
                      />
                    </S.SubscriptionCardInnerBenefitsListItem>
                  </S.SubscriptionCardInnerBenefitsList>
                  <Button
                    color={"#1C9D87"}
                    outlined
                    margin="20px 0 0 0"
                    type="submit"
                  >
                    <span style={{ display: "flex" }}>
                      <div style={{ lineHeight: "20px" }}>
                        <TranslationMessage
                          id={"subscription.card2.buttonValue"}
                        />
                      </div>
                    </span>
                  </Button>
                </div>
              </Card>
            </S.SubscriptionCardContainer>
          </>
        )}
      </S.SubscriptionContainer>
    </>
  );
};

export default SubscriptionBasic;
