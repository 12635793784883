import React, {useEffect} from 'react';
import { useDispatch, useSelector } from "react-redux";
import * as S from "./macroNutrients.styles";
import TranslationMessage from 'configs/translations/TranslationMessage';

// Components
import Topbar from 'components/topbar/Topbar';
import DonutChart from 'components/donutChart/DonutChart';
import Loader from "components/loader/Loader";

// Redux
import * as NutritionActions from 'redux/nutrition/nutrition.actions';

const MacroNutrients = () => {
    const dispatch = useDispatch();
    const loading = useSelector(state => state.auth.loading);
    const registrationData = useSelector(state => state.auth.registrationData);
    const donutChartData = useSelector(state => state.nutrition.macroNutrientsDonutChartData);

    useEffect(() => {
        dispatch(NutritionActions.getMacroNutrientsDonutRequest({
            nutritiontypeID: registrationData.nutritiontypeID,
        }))
    }, [dispatch, registrationData])

    const values = donutChartData && [
        {
          label: "Kohlenhydrate",
          value: donutChartData.carbohydratesData
        },
        {
          label: "Fett",
          value: donutChartData.fatData
        },
        {
          label: "Eiweiß",
          value: donutChartData.proteinData
        }
    ];

    const getCurrentNutritionType = () => {
        if(registrationData.nutritiontypeID === 1) return 'Anabole Diät';
        else if(registrationData.nutritiontypeID === 2) return 'Metabole Diät';
        else if(registrationData.nutritiontypeID === 3) return 'High Carb';
        else if(registrationData.nutritiontypeID === 4) return 'Low Carb';
    }

    return (
        <div>
            <Topbar prev='/nutrition/calories' next='/nutrition/done' progress='83%'  />
            <S.NutritionFormMacroNutrientsContainer>
                <S.NutritionFormMacroNutrientsHeading><TranslationMessage id="nutrition.formMacroNutrients.heading" /></S.NutritionFormMacroNutrientsHeading>
                <S.NutritionFormMacroNutrientsSubHeading><TranslationMessage id="nutrition.formMacroNutrients.subHeading" /></S.NutritionFormMacroNutrientsSubHeading>
                <S.NutritionFormMacroNutrientsDescription>
                    <TranslationMessage id="nutrition.formMacroNutrients.description.p1" /> <br/>
                    <TranslationMessage id="nutrition.formMacroNutrients.description.p2.1" />{getCurrentNutritionType()}
                    <TranslationMessage id="nutrition.formMacroNutrients.description.p2.2" />
                </S.NutritionFormMacroNutrientsDescription>
                <S.DonutContainer>
                    {!loading && <DonutChart series={values} />}
                    {<Loader loading={loading} top="100px" />}
                </S.DonutContainer>
            </S.NutritionFormMacroNutrientsContainer>
        </div>
    )
}

export default MacroNutrients
