import UploadIcon from "../../../assets/images/upload-gray.svg";
import * as S from "./imageUpload.styles";

const ImageUpload = ({ onImageChange, id }) => {
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      onImageChange(id, file);
    }
  };

  return (
    <S.QualificationImageUploadDiv>
      <input
        type="file"
        accept="application/pdf"
        onChange={handleImageChange}
        style={{ display: "none" }}
        id={`qualification-image-${id}`}
      />
      <S.QualificationImageUploadLabel
        htmlFor={`qualification-image-${id}`}
      >
        <img src={UploadIcon} alt="Upload Icon" />
      </S.QualificationImageUploadLabel>
    </S.QualificationImageUploadDiv>
  );
};
export default ImageUpload;
