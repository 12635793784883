import React, { useState } from 'react';

import Topbar from 'components/topbar/Topbar';
import * as S from "./contactPersons.styles";

// Images
import Felix from "assets/images/new/CEO-FelixMergenthaler.jpg";
import Tim from "assets/images/new/Tim-Foodiary.jpg";
import Jessica from "assets/images/new/Jessica-Foodiary.jpg";
import NinaFassl from "assets/images/new/NinaFassl.png";
import Email from "assets/images/new/Icon-feather-mail.svg";
import Phone from "assets/images/new/Icon-feather-phone.svg";

import { getS3ImageUrl } from "configs/utils";
import { useSelector } from "react-redux";
import { dashboardNavTabs } from "configs/utils";
import TranslationMessage from 'configs/translations/TranslationMessage';

const ContactPersons = () => {
    const [active, setActive] = useState(0);
    const trainerProfileData = useSelector((state) => state.auth.trainerData);

    return (
        <>
            {/* <Topbar prev="/dashboard" next="/registration/gender" /> */}
            <Topbar
                navTabs={dashboardNavTabs}
                redirectionLink
                profileDropdown={{
                    name: trainerProfileData.prename + " " + trainerProfileData.surname,
                    profilePic: getS3ImageUrl(trainerProfileData.profilePic),
                }}
            />
            <S.Container>
                <S.Title>
                    <TranslationMessage id="contactPersons.heading" />
                </S.Title>
                <S.SubTitle>
                    <TranslationMessage id="contactPersons.subHeading" />
                </S.SubTitle>
                <S.CardsContainer>
                    <S.Card>
                        <S.CardImage src={Felix} alt="contact person" />
                        <S.CardTitle>Felix Mergenthaler</S.CardTitle>
                        <S.CardDeisnationBox>
                            <S.CardDeisnationHeading>Ansprechpartner für</S.CardDeisnationHeading>
                            <S.CardDeisnationValue>Geschäftsführung</S.CardDeisnationValue>
                        </S.CardDeisnationBox>
                        <S.CardContactBox>
                            <S.CardContactItem>
                                <S.CardContactItemIcon src={Phone} alt="phone icon" />
                                <S.CardContactItemText>+49 (0) 174 1953597</S.CardContactItemText>
                            </S.CardContactItem>
                            <S.CardContactItem>
                                <S.CardContactItemIcon src={Email} alt="email icon" />
                                <S.CardContactItemText>felix@foodiary.app</S.CardContactItemText>
                            </S.CardContactItem>
                        </S.CardContactBox>
                    </S.Card>
                    <S.Card>
                        <S.CardImage src={Tim} alt="contact person" />
                        <S.CardTitle>Tim Vicariesmann</S.CardTitle>
                        <S.CardDeisnationBox>
                            <S.CardDeisnationHeading>Ansprechpartner für</S.CardDeisnationHeading>
                            <S.CardDeisnationValue>Partnermanagement</S.CardDeisnationValue>
                        </S.CardDeisnationBox>
                        <S.CardContactBox>
                            <S.CardContactItem>
                                <S.CardContactItemIcon src={Phone} alt="phone icon" />
                                <S.CardContactItemText>+49 (0) 151 10675715</S.CardContactItemText>
                            </S.CardContactItem>
                            <S.CardContactItem>
                                <S.CardContactItemIcon src={Email} alt="email icon" />
                                <S.CardContactItemText>tim@foodiary.coach</S.CardContactItemText>
                            </S.CardContactItem>
                        </S.CardContactBox>
                    </S.Card>
                    <S.Card>
                        <S.CardImage src={NinaFassl} alt="contact person" />
                        <S.CardTitle>Nina Fassl</S.CardTitle>
                        <S.CardDeisnationBox>
                            <S.CardDeisnationHeading>Ansprechpartner für</S.CardDeisnationHeading>
                            <S.CardDeisnationValue>B2B - Sales Managerin</S.CardDeisnationValue>
                        </S.CardDeisnationBox>
                        <S.CardContactBox>
                            <S.CardContactItem>
                                <S.CardContactItemIcon src={Phone} alt="phone icon" />
                                <S.CardContactItemText>+49 (0) 1515 0226776 </S.CardContactItemText>
                            </S.CardContactItem>
                            <S.CardContactItem>
                                <S.CardContactItemIcon src={Email} alt="email icon" />
                                <S.CardContactItemText>nina@foodiary.app</S.CardContactItemText>
                            </S.CardContactItem>
                        </S.CardContactBox>
                    </S.Card>
                    {/* <S.Card>
                        <S.CardImage src={Jessica} alt="contact person" />
                        <S.CardTitle>Jessica Rüter</S.CardTitle>
                        <S.CardDeisnationBox>
                            <S.CardDeisnationHeading>Ansprechpartner für</S.CardDeisnationHeading>
                            <S.CardDeisnationValue>B2B - Sales Managerin</S.CardDeisnationValue>
                        </S.CardDeisnationBox>
                        <S.CardContactBox>
                            <S.CardContactItem>
                                <S.CardContactItemIcon src={Phone} alt="phone icon" />
                                <S.CardContactItemText>+49 (0) 152 29507498</S.CardContactItemText>
                            </S.CardContactItem>
                            <S.CardContactItem>
                                <S.CardContactItemIcon src={Email} alt="email icon" />
                                <S.CardContactItemText>jessica@foodiary.app</S.CardContactItemText>
                            </S.CardContactItem>
                        </S.CardContactBox>
                    </S.Card> */}
                </S.CardsContainer>
                <br />
                <br />
            </S.Container>
        </>
    )
}

export default ContactPersons
