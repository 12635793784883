import styled from "styled-components";

export const TableOverallContainer = styled.div`
  width: 100%;
  background: white;
  border-radius: 8px;
  padding: 0;
  /* height: 500px; */
  overflow: auto;
  /* box-shadow: 0px 2px 5px #0000001a; */
  box-sizing: border-box;

  th,
  td {
    padding: 1rem;
  }

  thead {
    tr {
      background: #f6f8fa;
      border-radius: 10px;

      th {
        color: #535865;
      }
    }
  }

  tbody {
    td {
      border-top: 1px solid #ececec;
    }
    tr {
      /* &:hover {
        background: #c0c0c01f;
        cursor: pointer;
      } */
    }
  }
`;

export const TableContainer = styled.table`
  width: 100%;
  border-spacing: 0;
`;

export const TableHeadingContainer = styled.thead`
  /* height: 54px; */
  color: #a5b5af;
  font-size: 0.875rem;
  text-align: left;
  position: sticky;
  top: 0;
  background: white;
  z-index: 1;
`;

export const TableRowContainer = styled.tr`
  font-size: 1rem;
  color: #44554c;
  border-bottom: 1px #f5f5f5;
`;

export const TablePaginationDetailsContainer = styled.div`
  background-color: white;
  margin-top: 15px;
  padding: 15px;
  display: flex;
  justify-content: center;
  box-shadow: 0px 2px 5px #0000001a;
  border-radius: 8px;
`;

export const TablePaginationNumbersContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 0.5rem;
`;

export const TablePaginationNumber = styled.div`
  width: 1.875rem;
  height: 1.875rem;
  border-radius: 5px;
  border: ${(props) => (props.active ? "none" : "1px solid #DDDDDD")};
  background: ${(props) => (props.active ? "#44554C" : "white")};
  color: ${(props) => (props.active ? "white" : "#44554C")};
  cursor: pointer;
  line-height: 25px;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ChipLightGreen = styled.div`
  width: 5.625rem;
  padding: 6px 0;
  border-radius: 5px;
  background: #e7faf3;
  color: #2ecf96;
  text-align: center;
  font-size: 0.875rem;
  font-weight: 400;
`;

export const ChipLight = styled.div`
  width: fit-content;
  border-radius: 30px;
  background: #dddddd;
  color: #a3aba9;
  text-align: center;
  font-size: 13px;
  padding: 6px 12px;
  font-weight: 400;
`;

export const ChipDark = styled.div`
  width: fit-content;
  padding: 6px 12px;
  font-weight: 400;
  border-radius: 30px;
  background: #44554c;
  color: #d4d7d5;
  text-align: center;
  font-size: 13px;
`;
