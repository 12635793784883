import React from "react";
import * as S from "./Pagination.styles";


const Pagination = ({ currentPage, totalRecords, recordsPerPage, onPageChange }) => {
  const totalPages = Math.ceil(totalRecords / recordsPerPage);

  const getPages = () => {
    const range = 2; // Number of pages to display before and after the current page
    let start = Math.max(1, currentPage - range);
    let end = Math.min(totalPages, start + 2 * range);

    // Adjust the start index if needed to ensure that the current page is within the displayed range
    if (currentPage <= range + 1) {
      start = 1;
      end = Math.min(totalPages, start + 2 * range);
    } else if (currentPage >= totalPages - range) {
      start = Math.max(1, totalPages - 2 * range);
      end = totalPages;
    }

    const displayedPages = Array.from({ length: end - start + 1 }, (_, index) => start + index);

    // Check if the first page is not included in the displayed range
    if (start > 1) {
      displayedPages.unshift(1);
    }

    // Check if the second last page is not included in the displayed range
    if (start + 1 < totalPages - 1 && !displayedPages.includes(totalPages - 1)) {
      displayedPages.push("...");
    }

    // Check if the last page is within the displayed range
    if (totalPages > end) {
      displayedPages.push(totalPages);
    }

    return displayedPages;
  };

  return (
    <nav aria-label="Page navigation example">
      <ul className="pagination m-0">
        <li
          className={`page-item ${currentPage <= 1 ? "disabled" : ""}`}
          onClick={() => onPageChange(currentPage - 1)}
        >
          <a
            className="page-link"
            href="javascript:void(0)"
            aria-label="Previous"
          >
            <span aria-hidden="true">&laquo;</span>
            {/* <span className="sr-only">Previous</span> */}
          </a>
        </li>
        {getPages().map((page, index) => (
          <li
            key={index}
            className={`page-item ${currentPage === page ? "active" : ""}`}
            onClick={() => onPageChange(page)}
          >
            <a className="page-link" href="javascript:void(0)">
              {page}
            </a>
          </li>
        ))}
        <li
          className={`page-item ${currentPage >= totalPages ? "disabled" : ""}`}
          onClick={() => onPageChange(currentPage + 1)}
        >
          <a className="page-link" href="javascript:void(0)" aria-label="Next">
            <span aria-hidden="true">&raquo;</span>
            {/* <span className="sr-only">Next</span> */}
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default Pagination;
