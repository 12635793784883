import React, { useState } from 'react';
import { useHistory } from "react-router-dom";

import Topbar from 'components/topbar/Topbar';
import BackIconButton from "components/backIconButton/BackIconButton";
import * as S from "./administration.styles";

// Images
import Invoice from "assets/images/new/invoice-icon.jpg";
import Folder from "assets/images/new/icon-folder.jpg";

import { getS3ImageUrl } from "configs/utils";
import { useSelector } from "react-redux";
import { dashboardNavTabs } from "configs/utils";
import TranslationMessage from 'configs/translations/TranslationMessage';

const Administration = () => {
    const [active, setActive] = useState(0);
    const trainerProfileData = useSelector((state) => state.auth.trainerData);
    const history = useHistory();

    return (
        <>
            <Topbar
                navTabs={dashboardNavTabs}
                redirectionLink
                profileDropdown={{
                    name: trainerProfileData.prename + " " + trainerProfileData.surname,
                    profilePic: getS3ImageUrl(trainerProfileData.profilePic),
                }}
            />
            <S.Container>
                <div style={{ display: "flex" }}>
                    <BackIconButton onClick={() => { 
                        history.push('/main/dashboard/home')
                    }} />
                    <div style={{ marginLeft: "15px" }}>
                        <S.Title>
                            <TranslationMessage id="administration.heading" />
                        </S.Title>
                        <S.SubTitle>
                            <TranslationMessage id="administration.subHeading" />
                        </S.SubTitle>
                    </div>
                </div>
                <S.CardsContainer>
                    <S.Card onClick={() => {
                        window.open("https://billing.stripe.com/p/login/00g7uY57p1mf8eI4gg", "_blank")
                    }}>
                        <S.CardImage src={Invoice} alt="contact person" />
                        <S.CardTitle>Rechnungen</S.CardTitle>
                    </S.Card>
                    <S.Card onClick={() => {
                        window.open("http://gofile.me/6GQ02/K7FUsZSLS", "_blank")
                    }}>
                        <S.CardImage src={Folder} alt="contact person" />
                        <S.CardTitle>Dateien</S.CardTitle>
                    </S.Card>
                </S.CardsContainer>
            </S.Container>
        </>
    )
}

export default Administration
