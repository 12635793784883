import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { debounce } from "lodash";
import Select from "react-select";
import Input from "components/formComponents/input/Input";
import Dropdown from "components/formComponents/dropdown/Dropdown";
import TextArea from "components/formComponents/textArea/TextArea";
import TranslationMessage from "configs/translations/TranslationMessage";
import InnerSideNav from "pages/NewDasboard/components/InnerSideNav/InnerSide";
import Button from "components/formComponents/button/Button";

import PlusIcon from "assets/images/plus-icon-cricle-faq.svg";
import MinusIcon from "assets/images/minus-icon-cricle-faq.svg";
import CrossIcon from "assets/images/cross-icon-faq.svg";
import SearchIcon from "assets/images/search.svg";

import { MENUS } from "../data";
import * as NutritionActions from "redux/nutrition/nutrition.actions";
import * as S from "./FAQs.styles";

const faqOptions = [
  { value: 1, label: "Ernährungskurs" },
  { value: 3, label: "Abrechnung Coach" },
  { value: 4, label: "Abrechnung Klienten" },
  { value: 5, label: "Allgemeines" },
  { value: 6, label: "Werbepartnerschaften" },
  { value: 7, label: "Kundenservice" },
  { value: 8, label: "Lernacademy" },
];

const FAQs = () => {
  const dispatch = useDispatch();
  const [TopicSelect, SetTopicSelect] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [AllQuestions, SetAllQuestions] = useState(null);
  const [AllQuestionsClone, SetAllQuestionsClone] = useState(null);
  const [SelectedQuestion, SetSelectedQuestion] = useState(null);

  const faqQuestions = useSelector((state) => state.nutrition.faqQuestions);
  const faqQuestionsById = useSelector(
    (state) => state.nutrition.faqQuestionsById
  );
  const isFaqQuestionsLoading = useSelector(
    (state) => state.nutrition.isFaqQuestionsLoading
  );

  // console.log("faqQuestions", faqQuestions?.getAllQuestionsAnswers);
  // console.log(
  //   "faqQuestionsById",
  //   faqQuestionsById?.getAllQuestionsAnswersByTopicID
  // );
  // console.log("isFaqQuestionsLoading", isFaqQuestionsLoading);

  useEffect(() => {
    dispatch(
      NutritionActions.getFaqQuestionsRequest({
        formValues: {},
        func: (res) => {
          SetAllQuestions(res?.getAllQuestionsAnswers);
          SetAllQuestionsClone(res?.getAllQuestionsAnswers);
        },
      })
    );
  }, [dispatch]);

  const debouncedFilter = debounce((term) => filterQuestions(term), 300);

  const onSearchFaqs = (e) => {
    setSearchTerm(e.target.value);
    debouncedFilter(e.target.value);
  };

  const filterQuestions = (term) => {
    if (!term) {
      SetAllQuestions(AllQuestionsClone);
      return;
    }

    const filteredQuestions = AllQuestions.filter(
      (q) =>
        q?.question.toLowerCase().includes(term.toLowerCase()) ||
        q?.answer.toLowerCase().includes(term.toLowerCase())
    );
    console.log("filteredQuestions", filteredQuestions);
    SetAllQuestions(filteredQuestions);
  };

  const onSelectFaqFilter = (v) => {
    console.log(v);
    SetTopicSelect(v);
    setSearchTerm("");
    dispatch(
      NutritionActions.getFaqQuestionsByTopicIdRequest({
        formValues: {
          topic_id: v?.value,
        },
        func: (res) => {
          SetAllQuestions(res?.getAllQuestionsAnswersByTopicID);
          SetAllQuestionsClone(res?.getAllQuestionsAnswersByTopicID);
        },
      })
    );
  };

  const handleSelectQuestion = (question) => {
    SetSelectedQuestion(question);
  };

  const onResetSelectFilter = () => {
    SetTopicSelect(null);
    setSearchTerm("");
    dispatch(
      NutritionActions.getFaqQuestionsRequest({
        formValues: {},
        func: (res) => {
          SetAllQuestions(res?.getAllQuestionsAnswers);
          SetAllQuestionsClone(res?.getAllQuestionsAnswers);
        },
      })
    );
  };

  return (
    <>
      <S.Container>
        <div className="second-sidebar-wrapper">
          <InnerSideNav menus={MENUS} hideUserInfo></InnerSideNav>
        </div>
        <S.Wrapper className="second-sidebar-content">
          <S.TrainerProfileDetailsInnerContainer>
            <S.TrainerProfileDetailsSectionHeading>
              Häufige Fragen
            </S.TrainerProfileDetailsSectionHeading>
            <S.TrainerProfileDetailsSectionSubHeading>
              Die häufigsten Fragen rund um Foodiary und die Software
            </S.TrainerProfileDetailsSectionSubHeading>
            <S.Divider />
            <S.FiltersBox>
              <S.SelectedFiltersAndDropdown>
                <Select
                  options={faqOptions}
                  className="faq-select"
                  classNamePrefix="react-select"
                  onChange={onSelectFaqFilter}
                  value={TopicSelect}
                  placeholder="Alle Kategorien"
                  isSearchable={false}
                />

                {/* <Dropdown
                  options={faqOptions}
                  onChange={onSelectFaqFilter}
                  value={faqOptions[0].value}
                  placeholder="Alle Kategorien"
                  style={{
                    margin: "0",
                    fontSize: "1rem",
                    border: "1px solid #D0D5DD",
                    background: "#fff",
                  }}
                /> */}

                {TopicSelect && (
                  <S.SelectedFilter>
                    {TopicSelect?.label}{" "}
                    <img
                      src={CrossIcon}
                      alt="CrossIcon"
                      onClick={() => onResetSelectFilter()}
                    />
                  </S.SelectedFilter>
                )}
              </S.SelectedFiltersAndDropdown>
              <div>
                <S.FormControl>
                  <Input
                    className="input-custom"
                    style={{ width: "100%", marginBottom: "0" }}
                    placeholder=""
                    onChange={onSearchFaqs}
                    value={searchTerm}
                  />
                  <img src={SearchIcon} alt="SearchIcon" />
                </S.FormControl>
              </div>
            </S.FiltersBox>
            <S.FilterSearchTitle>
              {AllQuestions?.length} Fragen verfügbar
            </S.FilterSearchTitle>

            <S.FAQList>
              {isFaqQuestionsLoading && (
                <>
                  <br />
                  <br />
                  <br />
                  <br />
                  <div className="text-center">
                    <div className="loader mx-auto"></div>
                  </div>
                  <br />
                  <br />
                  <br />
                  <br />
                </>
              )}
              {!isFaqQuestionsLoading &&
                AllQuestions?.map((q) => (
                  <S.FAQItem key={q?.id}>
                    <S.FAQItemHeader>
                      <p>{q?.question}</p>
                      {SelectedQuestion?.id !== q?.id && (
                        <img
                          src={PlusIcon}
                          alt="PlusIcon"
                          onClick={() => handleSelectQuestion(q)}
                        />
                      )}
                      {SelectedQuestion?.id === q?.id && (
                        <img
                          src={MinusIcon}
                          alt="MinusIcon"
                          onClick={() => handleSelectQuestion(null)}
                        />
                      )}
                    </S.FAQItemHeader>
                    {SelectedQuestion?.id === q?.id && (
                      <S.FAQItemBody>
                        <p>{q?.answer}</p>
                      </S.FAQItemBody>
                    )}
                  </S.FAQItem>
                ))}
            </S.FAQList>
          </S.TrainerProfileDetailsInnerContainer>
        </S.Wrapper>
      </S.Container>
    </>
  );
};

export default FAQs;
