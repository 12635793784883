import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getTransformedCaloriesDataForChart } from "configs/utils";
import ReactApexChart from "react-apexcharts";

import TranslationMessage from "configs/translations/TranslationMessage";
import Button from "components/formComponents/button/Button";
import Modal from "components/modal/Modal";
import CrossIcon from "assets/images/cross.svg";
// Images
import LeftIcon from "assets/images/leftIcon.svg";
import RightIcon from "assets/images/rightIcon.svg";
// Redux
import * as NutritionActions from "redux/nutrition/nutrition.actions";

import ArrowLeft from "assets/images/arrowLeft.svg";

import * as S from "../OnBoardingPartTwo.styles";
import IncrementInputModern from "components/formComponents/IncrementInputModern/IncrementInputModern";
import OnboardingSidebar from "pages/OnBoardingPartOne/OnboardingSidebar";

const NutritionCalories = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const caloriesData = useSelector(
    (state) => state.nutrition.caloriesLineChartData
  );

  const registrationData = useSelector((state) => state.auth.registrationData)

  console.log("caloriesData", caloriesData);

  const [editCaloriesModal, setEditCaloriesModal] = useState(false);
  const [restDayCalories, setRestDayCalories] = useState(0);
  const [trainingDayCalories, setTrainingDayCalories] = useState(0);
  const [weekNumber, setWeekNumber] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [lineChartStatus, setLineChartStatus] = useState(null);
  const [options, setOptions] = useState({
    series: [
      {
        name: "Trainingsfreier Tag",
        data: [],
      },
      {
        name: "Trainingstag",
        data: [],
      },
    ],
    chart: {
      height: 350,
      type: "area",
    },
    colors: ["#2ECF96", "#44554C"],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      categories: [],
    },
    tooltip: {},
  });

  useEffect(() => {
    hitCaloriesDataAPI();
  }, [dispatch]);

  const hitCaloriesDataAPI = () => {
    setIsLoading(true);

    const nutritiontypeID = (
      getRegistrationData()?.nutritiontypeID ||
      registrationData?.nutritiontypeID
    )

    const aimID = (
      getRegistrationData()?.aimID ||
      registrationData?.aimID
    )

    const sex = (
      getRegistrationData()?.sex
      || registrationData?.sex
    )

    const user_id = (
      getRegistrationData()?.userID
      || registrationData?.userID
    )

    dispatch(
      NutritionActions.getWeekWiseCaloriesRequest({
        data: { nutritiontypeID, aimID, sex, user_id },
        funcSuccess: (response) => {
          setIsLoading(false);

          console.log("response", response);
          setSelectedWeekData(response?.data, weekNumber);

          const { erholungstagObj, trainingstagObj } =
            getTransformedCaloriesDataForChart(response?.data || {});

          setLineChartStatus(
            (erholungstagObj["11"] - erholungstagObj["0"]).toFixed(2) + " kcal"
          );

          const erholungstagData = Object.values(erholungstagObj).filter(
            (val) => !isNaN(val)
          ); // filtering only numeric values
          const trainingstagData = Object.values(trainingstagObj).filter(
            (val) => !isNaN(val)
          );

          // Categories will be the same for both, you can adjust this if needed
          const categoriesData = Object.keys(erholungstagObj)
            .filter((key) => !isNaN(key))
            .map((i) => +i + 1);

          console.log(
            "erholungstagData, trainingstagData",
            erholungstagData,
            trainingstagData
          );
          // Find the maximum values for both datasets
          const maxErholungstag = Math.max(...erholungstagData);
          const maxTrainingstag = Math.max(...trainingstagData);

          // Get the overall max value and round it
          const maxValue = Math.max(maxErholungstag, maxTrainingstag);
          const roundedMaxValue = Math.ceil((maxValue + 100) / 500) * 500;
          const tickAmount = Math.ceil(roundedMaxValue / 500);

          setOptions((prevOptions) => ({
            ...prevOptions,
            series: [
              {
                ...prevOptions.series[0],
                data: erholungstagData,
              },
              {
                ...prevOptions.series[1],
                data: trainingstagData,
              },
            ],
            xaxis: {
              ...prevOptions.xaxis,
              categories: categoriesData,
            },
            yaxis: {
              min: 0,
              max: roundedMaxValue,
              tickAmount: tickAmount,
              labels: {
                formatter: (value) => Math.round(value),
              },
            },
          }));
        },
      })
    );
  };

  const handleCaloriesUpdate = () => {
    setEditCaloriesModal(false);
    dispatch(
      NutritionActions.updateNutritionCaloriesRequest({
        payload: {
          nutritiontypeID: getRegistrationData()?.nutritiontypeID,
          nutritionconceptID: getRegistrationData()?.nutritionconceptID,
          aimID: getRegistrationData()?.aimID,
          sex: getRegistrationData()?.sex,
          week: weekNumber,
          caloriesData: [
            { daytypeID: 1, calories: restDayCalories },
            { daytypeID: 2, calories: trainingDayCalories },
          ],
        },
        callback: () => {
          hitCaloriesDataAPI();
        },
      })
    );
  };

  const setSelectedWeekData = (data, weekNumber) => {
    const selectedWeekData = data
      ? data["week" + weekNumber]
      : caloriesData["week" + weekNumber];

    const erholungstagCalories =
      selectedWeekData.find((day) => day?.daytypeID === "Erholungstag")
        ?.calories || 0;
    const trainingstagCalories =
      selectedWeekData.find((day) => day?.daytypeID === "Trainingstag")
        ?.calories || 0;

    setTrainingDayCalories(trainingstagCalories);
    setRestDayCalories(erholungstagCalories);
  };

  const handleRightNavigation = () => {
    if (weekNumber !== 12) {
      setWeekNumber(weekNumber + 1);
      setSelectedWeekData(null, weekNumber + 1);
    }
  };
  const handleLeftNavigation = () => {
    if (weekNumber !== 1) {
      setWeekNumber(weekNumber - 1);
      setSelectedWeekData(null, weekNumber - 1);
    }
  };

  const getRegistrationData = () => {
    const RD = JSON.parse(localStorage.getItem("RegistrationData"));
    return RD;
  };

  const handleBack = () => {
    history.push("/onboarding/nutrition/recipies");
  };

  const handleNext = () => {
    history.push("/onboarding/nutrition/macro-nutrients");
  };

  return (
    <>
      <S.OnBoardingPage className="starter">
        <S.OnBoardingContainer className="onboarding-container">
          <OnboardingSidebar step={3} />
          <S.OnBoardingContent className="onboarding-content">
            <S.OnBoardingHeader className="onboarding-header">
              <div className="d-flex align-items-top gap-3">
                <img
                  className="cursor-pointer"
                  src={ArrowLeft}
                  alt="back"
                  onClick={handleBack}
                />
                <div className="content">
                  <span className="small-title text-green font-rubik-medium">
                    Auswertung
                  </span>
                  <h6 className="header-title font-rubik-medium my-0">
                    Unsere Empfehlung für dich
                  </h6>
                </div>
              </div>
              <div className="actions">
                <Button
                  color="rgba(248, 164, 146, 1)"
                  gradient="linear-gradient(60deg, rgba(46, 207, 150, 1), rgba(28, 157, 135, 1)) !important"
                  margin="0"
                  absolute={{ width: "fit-content", height: "2.5rem" }}
                  buttonStyle={{ padding: ".5625rem 2.5rem" }}
                  onClick={handleNext}
                >
                  Weiter
                </Button>
              </div>
            </S.OnBoardingHeader>
            <S.OnBoardingBody className="onboarding-body">
              <div className="nutrition-calories-content">
                {isLoading && (
                  <>
                    <br />
                    <br />
                    <br />
                    <br />
                    <div className="text-center">
                      <div className="loader mx-auto"></div>
                    </div>
                    <br />
                    <br />
                    <br />
                    <br />
                  </>
                )}

                {!isLoading && (
                  <>
                    <h3 className="text-size-20-28 text-dark-1 font-rubik-medium mt-0">
                      Dein persönlicher Tagesbedarf
                    </h3>

                    <div className="nutrition-calories-chart">
                      <S.NutritionFormCaloriesLineGraphContainer>
                        <S.LineChartContainer>
                          <S.LineChartHeading>
                            Kalorienverlauf
                          </S.LineChartHeading>
                          <span style={{ flexGrow: 1 }}> </span>
                          <S.LineChartStatus>
                            {lineChartStatus} <br />
                            <S.LineChartDescription>
                              in 12 Wochen
                            </S.LineChartDescription>
                          </S.LineChartStatus>
                        </S.LineChartContainer>

                        <ReactApexChart
                          options={options}
                          series={options.series}
                          type="area"
                          height={350}
                        />
                        {/* <LineChart
                          heading="Gesamtbedarf"
                          status={lineChartStatus}
                          description="in 12 Wochen"
                          fontSize={12}
                          precision={0}
                          padding={35}
                          width={447}
                          height={150}
                          data={data}
                          horizontalGuideLines={3}
                          yAxisBetweenRange
                        /> */}
                      </S.NutritionFormCaloriesLineGraphContainer>
                    </div>
                    <div className="divider my-1-5-rem"></div>
                    <p className="text-size-16-24 text-dark-2 font-rubik-light">
                      <TranslationMessage id="nutrition.formCalories.description.p1" />
                      <br />
                      <br />
                      {caloriesData?.description}
                    </p>

                    <button
                      className="btn-outline mx-auto"
                      onClick={() => setEditCaloriesModal(true)}
                    >
                      Kalorien ändern
                    </button>
                  </>
                )}
              </div>
            </S.OnBoardingBody>
          </S.OnBoardingContent>
        </S.OnBoardingContainer>
      </S.OnBoardingPage>
      <Modal
        show={editCaloriesModal}
        setShow={setEditCaloriesModal}
        heading={
          <div className="d-flex align-items-center justify-content-between">
            <h5 className="text-size-20-24 text-dark-1 text-left my-0">
              <TranslationMessage id="nutrition.formCalories.editCalories.heading" />
            </h5>
            <img
              className="cursor-pointer"
              src={CrossIcon}
              alt="CrossIcon"
              onClick={() => setEditCaloriesModal(false)}
            />
          </div>
        }
        width="500px"
        height="auto"
      >
        <S.EditCaloriesWeekHeadingContainer>
          <S.EditCaloriesNavigationIconContainer onClick={handleLeftNavigation}>
            <img src={LeftIcon} alt="left Icon" />
          </S.EditCaloriesNavigationIconContainer>
          <S.EditCaloriesWeekHeading>
            <TranslationMessage id="nutrition.formCalories.editCalories.week" />{" "}
            {weekNumber}
          </S.EditCaloriesWeekHeading>
          <S.EditCaloriesNavigationIconContainer
            onClick={handleRightNavigation}
          >
            <img src={RightIcon} alt="Right Icon" />
          </S.EditCaloriesNavigationIconContainer>
        </S.EditCaloriesWeekHeadingContainer>
        <S.EditCaloriesInputCard className="d-flex align-items-center justify-content-between">
          <h6 className="text-size-18-22 text-dark-1 my-0">
            <TranslationMessage id="input.label.restDay" />
          </h6>
          <IncrementInputModern
            width="200"
            placeholder="0 cm"
            id="cm"
            inputMeasure=""
            value={restDayCalories}
            setData={setRestDayCalories}
          />
        </S.EditCaloriesInputCard>
        <br />
        <S.EditCaloriesInputCard className="d-flex align-items-center justify-content-between">
          <h6 className="text-size-18-22 text-dark-1 my-0">
            <TranslationMessage id="input.label.trainingDay" />
          </h6>
          <IncrementInputModern
            width="200"
            placeholder="0 kg"
            id="kg"
            inputMeasure=""
            value={trainingDayCalories}
            setData={setTrainingDayCalories}
          />
        </S.EditCaloriesInputCard>
        <br />
        <Button
          color="rgba(46, 207, 150, .2)"
          onClick={handleCaloriesUpdate}
          gradient="linear-gradient(60deg, rgba(46, 207, 150, 1), rgba(28, 157, 135, 1)) !important"
          disabled={
            parseInt(restDayCalories) === 0 ||
            parseInt(trainingDayCalories) === 0
          }
          margin="0 auto"
        >
          <TranslationMessage id="button.changeCalories" />
        </Button>
      </Modal>
    </>
  );
};

export default NutritionCalories;
