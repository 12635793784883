import styled from "styled-components";
import { RegistrationContainer, RegistrationHeading, RegistrationSubHeading } from 'pages/registration/registration.styles';


export const HealthScoreInvestigationFormContainer = styled(RegistrationContainer)``;

export const HealthScoreInvestigationFormHeading = styled(RegistrationHeading)``;

export const HealthScoreInvestigationFormSubHeading = styled(RegistrationSubHeading)``;

export const CardInnerContainer = styled.div`
    margin: 45px 50px;
`;

export const QuestionBoxContainer = styled.div`
    line-height: 24px;
    margin-bottom: 2rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 16px;
    font-family: RubikRegular;
    align-items: center;
    color: #44554C;
`;