import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Formik } from "formik"
import { ref, object, string } from "yup"

import OutsideAlerter from 'components/OutsideAlerter';
import Button from "components/formComponents/button/Button";

import Instagram from "assets/images/new/Icon-menu-social-media.svg";
import Facebook from "assets/images/new/Icon-facebook.svg";
import BackgroundColorShape from "assets/images/new/share-background.svg";
import Progress from "assets/images/nutrition1.png";
import CloseModal from "assets/images/Icon-Close.svg";

import * as AuthActions from "redux/auth/auth.actions"
import * as S from './shareSocialMediaModal.styles';

const ShareSocialMediaModal = (props) => {
    const [showMsg, setShowMsg] = useState(false)

    const dispatch = useDispatch()
    const loading = useSelector((state) => state.auth.isPasswordReseting)
    const passwordReset = useSelector((state) => state.auth.passwordReset)


    return (
        <S.ModalBackdrop show={props.show} key={props.key}>
            <OutsideAlerter closeModal={props.setShow}>
                <S.ModalContainer width={props.width} height={props.height}>
                    <S.ModalInnerContainer>
                        {/* <S.ModalHeader>Passwort ändern</S.ModalHeader> */}
                        <S.ModalBody>
                            <img className="icon-close" src={CloseModal} onClick={() => props.onClose()} alt="close" />
                            <S.VisualArea>
                                <img className="image-bg" src={BackgroundColorShape} alt="bg wall" />
                                <S.ImageOne>
                                    <S.StartDay>Start</S.StartDay>
                                    <img className="image-one" src={Progress} alt="bg wall" />
                                </S.ImageOne>
                                <S.ImageTwo>
                                    <S.Week>Start</S.Week>
                                    <img className="image-two" src={Progress} alt="bg wall" />
                                </S.ImageTwo>
                                <img className="image-hand" src={Progress} alt="hand" />
                            </S.VisualArea>
                            <S.Title>Jetzt auf Social Media teilen!</S.Title>
                            <S.ShareItems>
                                <S.ShareItem isActive>
                                    <img src={Instagram} alt="insta" />
                                    <span>Instagram <br /> Story</span>
                                </S.ShareItem>
                                <S.ShareItem>
                                    <img src={Instagram} alt="insta" />
                                    <span>Instagram <br /> Post</span>
                                </S.ShareItem>
                                <S.ShareItem>
                                    <img src={Facebook} alt="insta" />
                                    <span>Facebook <br /> Post</span>
                                </S.ShareItem>
                            </S.ShareItems>
                            <Button
                                color="rgba(46, 207, 150, .2)"
                                gradient="linear-gradient(60deg, rgba(46, 207, 150, 1), rgba(28, 157, 135, 1)) !important"
                                margin="40px 0 0 0"
                                absolute={{ width: "100%", height: "auto" }}
                                style={{ width: "100%", }}
                            >
                                Jetzt teilen
                            </Button>
                        </S.ModalBody>
                    </S.ModalInnerContainer>
                </S.ModalContainer>
            </OutsideAlerter>
        </S.ModalBackdrop>
    )
}

export default ShareSocialMediaModal
