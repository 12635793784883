import React from "react";
import * as S from "./textArea.styles";

const TextArea = ({ ...props }) => {
  return (
    <S.TextAreaContainer>
      <S.CustomTextArea {...props} />
    </S.TextAreaContainer>
  );
};

export default TextArea;
