import styled from "styled-components";
import UpdateEmailBg from "assets/images/update-email-bg.png";

export const OnBoardingPage = styled.div`
  background: transparent linear-gradient(125deg, #e5f5f2 0%, #edfbf6 100%);
  width: 100%;
  min-height: 100vh;
`;

export const OnBoardingContainer = styled.div`
  width: 100%;
  max-width: calc(100% - 5rem);
  height: 100%;
  margin-inline: auto;
  padding-block: 3.75rem;
  display: flex;
  align-items: stretch;
  gap: 1rem;
  min-height: calc(100vh - 3.75rem);
`;

export const OnBoardingSidebar = styled.div`
  background: #ffffff;
  box-shadow: 0px 2px 5px #0000001a;
  border-radius: 1.25rem;
  width: 18.75rem;
  padding: 2rem;
  max-height: calc(100vh - 3.75rem - 3.75rem);
  overflow-y: auto;
  position: fixed;
  z-index: 1;
  height: 100%;

  .sidebar-title {
    letter-spacing: 0.4px;

    font-size: 1.25rem;
    margin: 1.875rem 0;
  }

  .steps-title {
    font-size: 0.875rem;
  }

  .sidebar-title,
  .steps-title {
    font-weight: 500;
    color: #142c1f;
  }

  .steps {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.1875rem;

    .step {
      display: flex;
      gap: 0.9375rem;

      .step-bar {
        display: flex;
        gap: 3px;
        flex-direction: column;
        align-items: center;

        .round {
          width: 1.625rem;
          height: 1.625rem;
          border: 2px solid #eaeaea;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;

          .small-round {
            border-radius: 50%;
            width: 0.75rem;
            height: 0.75rem;
            background: #e4e4e4;
          }

          &.active {
            background: #2ecf96;

            .small-round {
              background: #fff;
            }
          }
        }

        .wall {
          width: 0.1875rem;
          height: 4rem;
          background: #eaeaea;

          &.active {
            background: #2ecf96;
          }
        }
      }

      .step-content {
        .step-title,
        .step-description {
          margin: 0;
        }

        .step-title {
          letter-spacing: 0.32px;
          color: #142c1f;
          font-size: 1rem;
          font-weight: 500;
        }

        .step-description {
          letter-spacing: 0.28px;
          color: #818d88;
          font-size: 0.875rem;
          font-weight: 400;
          margin-top: 0.3125rem;
        }
      }
    }
  }
`;

export const OnBoardingContent = styled.div`
  background: #ffffff;
  box-shadow: 0px 2px 5px #0000001a;
  border-radius: 1.25rem;
  width: calc(100% - 18.75rem);
  margin-left: 19.75rem;

  .starter-content {
    .h1 {
      margin: 1rem 0;
    }

    .companies {
      margin-top: 0.8rem;
    }
  }
`;

export const OnBoardingHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  padding: 1.25rem 2.1875rem;
  border-bottom: 1px solid #e2e4e9;

  .content {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
  }

  .header-title {
    color: #142c1f;
    font-size: 1.375rem;
  }
`;

export const FakeModalEffect = styled.div`
  border-radius: 1.25rem;
  background-color: rgba(0, 0, 0, 0.2);
`;

export const FakeModalOnboardingBody = styled.div`
  width: 100%;
  height: calc(100vh - 3.75rem - 3.75rem - 5.6rem);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FakeModalOnboardingContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 600px;
  padding: 20px;
  background: white;
  border-radius: 1.25rem;
  > * {
    margin-bottom: 20px;
    text-align: center;
  }

  > div p {
    margin-bottom: 25px;
    color: #818d88;
  }

  .ctas {
    display: flex;
    align-items: center;
    > * {
      margin-right: 10px;
    }
  }

  .close {
    align-self: flex-end;
    img {
      cursor: pointer;
    }
  }
`;

export const OnBoardingBody = styled.div`
  min-height: calc(100vh - 3.75rem - 3.75rem - 5.6rem);
  padding: 1.75rem 2.1875rem;
`;

export const ChecklistElementContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 0 0.9375rem 0;
`;

export const ChecklistElementGreenTick = styled.img`
  padding-right: 0.625rem;
`;

export const TwoColsGridView = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
`;

export const Button = styled.button`
  padding: 12px 9px;
  font-size: 16px;
  line-height: 19px;
  border: none;
  background: #f8a492;
  border-radius: 8px;
  letter-spacing: 0px;
  color: #ffffff;
  font-family: RubikMedium;
  transition: all 0.3s;
  cursor: pointer;
  transition-duration: 0.3s;
  transition-property: transform;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;

  &:not(:disabled):hover {
    transform: scale(0.9);
  }
`;

export const Tab = styled.div`
  height: 18px;
  margin-bottom: 10px;
  border-radius: 4px;
  background-color: ${(props) => (props.active ? "#1C9D87" : "#142C1F15")};
  color: ${(props) => (props.active ? "white" : "#44554C")};
  padding: 11px 15px;
  font-size: 14px;
`;

// Add Daily Routine Popup
export const DailyRoutineInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  justify-content: space-between;
`;

export const DailyRoutineInputLabel = styled.div`
  line-height: 35px;
`;

export const DailyRoutineAddButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const EmailModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 70%;
  margin: auto;

  > * {
    margin-bottom: 20px;
  }

  .update-email-bg {
    height: 300px;
    width: 300px;
    background: url("${UpdateEmailBg}") no-repeat;
    background-size: cover;
    background-position: center;
  }

  div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;

    > * {
      margin-right: 10px;
    }

    .btn-pink {
      background: #e08073;
    }
  }
`;
