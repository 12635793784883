const SupplementsTypes = {
  GET_SUPPLEMENTS_REQUEST: "GET_SUPPLEMENTS_REQUEST",
  GET_SUPPLEMENTS_SUCCESS: "GET_SUPPLEMENTS_SUCCESS",
  GET_SUPPLEMENTS_FAILURE: "GET_SUPPLEMENTS_FAILURE",

  DELETE_MAIL_CHIMP_USER_REQUEST: "DELETE_MAIL_CHIMP_USER_REQUEST",
  DELETE_MAIL_CHIMP_USER_SUCCESS: "DELETE_MAIL_CHIMP_USER_SUCCESS",
  DELETE_MAIL_CHIMP_USER_FAILURE: "DELETE_MAIL_CHIMP_USER_FAILURE",

  ADD_PRO_USER_MAIL_CHIMP_DATA_REQUEST: "ADD_PRO_USER_MAIL_CHIMP_DATA_REQUEST",
  ADD_PRO_USER_MAIL_CHIMP_DATA_SUCCESS: "ADD_PRO_USER_MAIL_CHIMP_DATA_SUCCESS",
  ADD_PRO_USER_MAIL_CHIMP_DATA_FAILURE: "ADD_PRO_USER_MAIL_CHIMP_DATA_FAILURE",

  ADD_BASIC_USER_MAIL_CHIMP_DATA_REQUEST:
    "ADD_BASIC_USER_MAIL_CHIMP_DATA_REQUEST",
  ADD_BASIC_USER_MAIL_CHIMP_DATA_SUCCESS:
    "ADD_BASIC_USER_MAIL_CHIMP_DATA_SUCCESS",
  ADD_BASIC_USER_MAIL_CHIMP_DATA_FAILURE:
    "ADD_BASIC_USER_MAIL_CHIMP_DATA_FAILURE",

  UPDATE_USER_SUBSCRIPTION_REQUEST: "UPDATE_USER_SUBSCRIPTION_REQUEST",
  UPDATE_USER_SUBSCRIPTION_SUCCESS: "UPDATE_USER_SUBSCRIPTION_SUCCESS",
  UPDATE_USER_SUBSCRIPTION_FAILURE: "UPDATE_USER_SUBSCRIPTION_FAILURE",

  INITIATE_STRIPE_PAYMENT_REQUEST: "INITIATE_STRIPE_PAYMENT_REQUEST",
  INITIATE_STRIPE_PAYMENT_SUCCESS: "INITIATE_STRIPE_PAYMENT_SUCCESS",
  INITIATE_STRIPE_PAYMENT_FAILURE: "INITIATE_STRIPE_PAYMENT_FAILURE",

  GET_TRAINER_LEADS_REQUEST: "GET_TRAINER_LEADS_REQUEST",
  GET_TRAINER_LEADS_SUCCESS: "GET_TRAINER_LEADS_SUCCESS",
  GET_TRAINER_LEADS_FAILURE: "GET_TRAINER_LEADS_FAILURE",

  GET_TRAINER_LEADS_LOADING_START: "GET_TRAINER_LEADS_LOADING_START",
  GET_TRAINER_LEADS_LOADING_STOP: "GET_TRAINER_LEADS_LOADING_STOP",

  TRIGGER_TRAINER_EMAIL: 'TRIGGER_TRAINER_EMAIL',

  GET_TRAINER_LEAD_DETAILS_REQUEST: "GET_TRAINER_LEAD_DETAILS_REQUEST",
  GET_TRAINER_LEAD_DETAILS_SUCCESS: "GET_TRAINER_LEAD_DETAILS_SUCCESS",
  GET_TRAINER_LEAD_DETAILS_FAILURE: "GET_TRAINER_LEAD_DETAILS_FAILURE",

  GET_TRAINER_LEAD_DETAILS_LOADING_START:
    "GET_TRAINER_LEAD_DETAILS_LOADING_START",
  GET_TRAINER_LEAD_DETAILS_LOADING_STOP:
    "GET_TRAINER_LEAD_DETAILS_LOADING_STOP",

  GET_NUMBER_OF_LEADS_REQUEST: "GET_NUMBER_OF_LEADS_REQUEST",
  GET_NUMBER_OF_LEADS_SUCCESS: "GET_NUMBER_OF_LEADS_SUCCESS",
  GET_NUMBER_OF_LEADS_FAILURE: "GET_NUMBER_OF_LEADS_FAILURE",

  GET_NUMBER_OF_LEADS_LOADING_START:
    "GET_NUMBER_OF_LEADS_LOADING_START",
  GET_NUMBER_OF_LEADS_LOADING_STOP:
    "GET_NUMBER_OF_LEADS_LOADING_STOP",


  UPDATE_TRAINER_LEAD_REQUEST: "UPDATE_TRAINER_LEAD_REQUEST",
  UPDATE_TRAINER_LEAD_SUCCESS: "UPDATE_TRAINER_LEAD_SUCCESS",
  UPDATE_TRAINER_LEAD_FAILURE: "UPDATE_TRAINER_LEAD_FAILURE",

  UPDATE_TRAINER_LEAD_LOADING_START: "UPDATE_TRAINER_LEAD_LOADING_START",
  UPDATE_TRAINER_LEAD_LOADING_STOP: "UPDATE_TRAINER_LEAD_LOADING_STOP",

  GET_FOODIARY_LEADS_REQUEST: "GET_FOODIARY_LEADS_REQUEST",
  GET_FOODIARY_LEADS_SUCCESS: "GET_FOODIARY_LEADS_SUCCESS",
  GET_FOODIARY_LEADS_FAILURE: "GET_FOODIARY_LEADS_FAILURE",

  GET_FOODIARY_LEADS_LOADING_START: "GET_FOODIARY_LEADS_LOADING_START",
  GET_FOODIARY_LEADS_LOADING_STOP: "GET_FOODIARY_LEADS_LOADING_STOP",

  GET_FOODIARY_LEAD_DETAILS_REQUEST: "GET_FOODIARY_LEAD_DETAILS_REQUEST",
  GET_FOODIARY_LEAD_DETAILS_SUCCESS: "GET_FOODIARY_LEAD_DETAILS_SUCCESS",
  GET_FOODIARY_LEAD_DETAILS_FAILURE: "GET_FOODIARY_LEAD_DETAILS_FAILURE",

  GET_FOODIARY_LEAD_DETAILS_LOADING_START:
    "GET_FOODIARY_LEAD_DETAILS_LOADING_START",
  GET_FOODIARY_LEAD_DETAILS_LOADING_STOP:
    "GET_FOODIARY_LEAD_DETAILS_LOADING_STOP",

  CLAIM_FOODIARY_LEAD_REQUEST: "CLAIM_FOODIARY_LEAD_REQUEST",
  CLAIM_FOODIARY_LEAD_SUCCESS: "CLAIM_FOODIARY_LEAD_SUCCESS",
  CLAIM_FOODIARY_LEAD_FAILURE: "CLAIM_FOODIARY_LEAD_FAILURE",

  CLAIM_FOODIARY_LEAD_LOADING_START: "CLAIM_FOODIARY_LEAD_LOADING_START",
  CLAIM_FOODIARY_LEAD_LOADING_STOP: "CLAIM_FOODIARY_LEAD_LOADING_STOP",

  LEAD_UTILIZATION_REQUEST: "LEAD_UTILIZATION_REQUEST",
  LEAD_UTILIZATION_SUCCESS: "LEAD_UTILIZATION_SUCCESS",
  LEAD_UTILIZATION_FAILURE: "LEAD_UTILIZATION_FAILURE",

  LEAD_UTILIZATION_LOADING_START: "LEAD_UTILIZATION_LOADING_START",
  LEAD_UTILIZATION_LOADING_STOP: "LEAD_UTILIZATION_LOADING_STOP",

  GET_LEAD_CENTER_STATUS_REQUEST: "GET_LEAD_CENTER_STATUS_REQUEST",
  GET_LEAD_CENTER_STATUS_SUCCESS: "GET_LEAD_CENTER_STATUS_SUCCESS",
  GET_LEAD_CENTER_STATUS_FAILURE: "GET_LEAD_CENTER_STATUS_FAILURE",

  GET_LEAD_CENTER_STATUS_LOADING_START: "GET_LEAD_CENTER_STATUS_LOADING_START",
  GET_LEAD_CENTER_STATUS_LOADING_STOP: "GET_LEAD_CENTER_STATUS_LOADING_STOP",

  GET_LEAD_HISTORY_REQUEST: "GET_LEAD_HISTORY_REQUEST",
  GET_LEAD_HISTORY_SUCCESS: "GET_LEAD_HISTORY_SUCCESS",
  GET_LEAD_HISTORY_FAILURE: "GET_LEAD_HISTORY_FAILURE",

  GET_LEAD_HISTORY_LOADING_START: "GET_LEAD_HISTORY_LOADING_START",
  GET_LEAD_HISTORY_LOADING_STOP: "GET_LEAD_HISTORY_LOADING_STOP",

  GET_LEAD_AUTHORIZATION_REQUEST: "GET_LEAD_AUTHORIZATION_REQUEST",
  GET_LEAD_AUTHORIZATION_SUCCESS: "GET_LEAD_AUTHORIZATION_SUCCESS",
  GET_LEAD_AUTHORIZATION_FAILURE: "GET_LEAD_AUTHORIZATION_FAILURE",

  GET_LEAD_AUTHORIZATION_LOADING_START: "GET_LEAD_AUTHORIZATION_LOADING_START",
  GET_LEAD_AUTHORIZATION_LOADING_STOP: "GET_LEAD_AUTHORIZATION_LOADING_STOP",
  CREATE_LEAD_CENTER_REQUEST_START: "CREATE_LEAD_CENTER_REQUEST_START",
  CREATE_LEAD_CENTER_REQUEST: "CREATE_LEAD_CENTER_REQUEST",
  CREATE_LEAD_CENTER_SUCCESS: "CREATE_LEAD_CENTER_SUCCESS",
  CREATE_LEAD_CENTER_FAILURE: "CREATE_LEAD_CENTER_FAILURE",
  UPDATE_LEAD_CENTER_REQUEST_START: "UPDATE_LEAD_CENTER_REQUEST_START",
  UPDATE_LEAD_CENTER_REQUEST: "UPDATE_LEAD_CENTER_REQUEST",
  UPDATE_LEAD_CENTER_SUCCESS: "UPDATE_LEAD_CENTER_SUCCESS",
  UPDATE_LEAD_CENTER_FAILURE: "UPDATE_LEAD_CENTER_FAILURE",

};

export default SupplementsTypes;
