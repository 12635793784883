export const LAST_CLIENTS_CHART = {
  options: {
    colors: ['#6BBEB1', '#44554C'],
    chart: {
      type: "area",
      foreColor: "#ccc",
      zoom: {
        enabled: false,
      },
      toolbar: {
        autoSelected: "pan",
        show: false,
      },
    },
    dataLabels: { enabled: false, },
    stroke: {
      curve: "smooth",
      color: "#fff",
    },
    xaxis: { type: "numeric", },
    yaxis: {
      opposite: false,
      min: 0,
      // tickAmount: 2
      labels: {
        formatter: function (value) {
          return value.toFixed(0);
        },
      },
    },
    legend: {
      horizontalAlign: "left",
      show: false
    },
    grid: {
      borderColor: "transparent",
      xaxis: {
        lines: {
          show: false,
        },
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0, // Remove bottom padding
        left: 16,
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        stops: [0, 90, 100],
      },
    },
  },
};
