import styled from "styled-components";
import { LoginHeading, LoginSubHeading } from "pages/login/login.styles";
import { InformationDescription } from "pages/information/information.styles";

export const TrainerDetailsContainer = styled.div`
    padding: 45px 50px;
`;

export const TrainerHeading = styled(LoginHeading)`
    
`;

export const TrainerName = styled(LoginSubHeading)``;

export const TrainerDescription = styled(InformationDescription)`
    margin: 0 0 30px 0;
`;

export const ChecklistElementContainer = styled(TrainerDescription)`
    display: flex;
    flex-direction: row;
    margin: 0 0 15px 0;
`;

export const ChecklistElementGreenTick = styled.img`
    padding-right: 10px;
`;