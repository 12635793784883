export const NavbarTitles = [
  { id: 1, title: "Meine Klienten 🎓", path: "/dashboard/customers/list" },
  {
    id: 1,
    title: "Ernährungsplan",
    path: "/dashboard/customers/detail/",
  },
  {
    id: 2,
    title: "Partnerschaften 🙌",
    path: "/dashboard/partnerships/experts",
  },
  {
    id: 29,
    title: "Partnerschaften 🙌",
    path: "/dashboard/partnerships/education",
  },
  {
    id: 30,
    title: "Partnerschaften 🙌",
    path: "/dashboard/partnerships/marketing",
  },
  {
    id: 3,
    title: `Dashboard`,
    path: "/dashboard/main",
  },
  { id: 4, title: "Mein Profil ✌️", path: "/dashboard/profile" },
  { id: 16, title: "Mein Profil ✌️", path: "/dashboard/trainer-invoices" },
  { id: 5, title: "Academy ✔️", path: "/dashboard/academy/video" },
  { id: 6, title: "Academy ✔️", path: "/dashboard/academy/white-paper" },

  { id: 7, title: "Webseite 🖥️", path: "/dashboard/website" },
  {
    id: 8,
    title: "Social Media ⭐️",
    path: "/dashboard/social-media/instagram",
  },
  {
    id: 9,
    title: "Social Media ⭐️",
    path: "/dashboard/social-media/linkedin",
  },
  { id: 10, title: "Support 🤝", path: "/dashboard/support/open-ticket" },
  {
    id: 11,
    title: "Einstellungen 🔗",
    path: "/dashboard/settings/email-account",
  },
  {
    id: 12,
    title: "Einstellungen 🔗",
    path: "/dashboard/settings/access-data",
  },
  {
    id: 13,
    title: "Einstellungen 🔗",
    path: "/dashboard/settings/payment-provider",
  },
  {
    id: 14,
    title: "Token abfragen",
    path: "/dashboard/customers/token-request",
  },
  {
    id: 15,
    title: "Academy ✔️",
    path: "/dashboard/academy/experts-calls",
  },
  { id: 16, title: "Support 🤝", path: "/dashboard/support/faq" },
  {
    id: 17,
    title: "Marketingportal 🎙️",
    path: "/dashboard/marketing-portal/werbeassets",
  },
  {
    id: 18,
    title: "Marketingportal 🎙️",
    path: "/dashboard/marketing-portal/print-data",
  },
  {
    id: 19,
    title: "Marketingportal 🎙️",
    path: "/dashboard/marketing-portal/partners",
  },
  {
    id: 20,
    title: "Marketingportal 🎙️",
    path: "/dashboard/marketing-portal/partner/create",
  },
  { id: 21, title: "Mein Profil ✌️", path: "/dashboard/termination" },
  {
    id: 22,
    title: "Support 🤝",
    path: "/dashboard/support/health-insurance-reimbursement",
  },
  {
    id: 23,
    title: "Mein Profil ✌️",
    path: "/dashboard/billing",
  },
  { id: 24, title: "Mein Profil ✌️", path: "/dashboard/trainer-credits" },
  { id: 25, title: "Transfer", path: "/dashboard/transfer" },
  {
    id: 26,
    title: "Transfer",
    path: "/dashboard/trainer-credits/details",
  },
  {
    id: 27,
    title: "Leads Center 📧",
    path: "/dashboard/leads/all",
  },
  {
    id: 28,
    title: "Leads Center 📧",
    path: "/dashboard/leads/foodiary",
  },
  {
    id: 29,
    title: "Ernährungsplan",
    path: "dashboard/clients/overview/",
  },
  {
    id: 30,
    title: "Ernährungsplan",
    path: "dashboard/clients/calories/",
  },
  {
    id: 31,
    title: "Ernährungsplan",
    path: "/dashboard/clients/diet-and-nutrition/",
  },
  {
    id: 32,
    title: "Ernährungsplan",
    path: "/dashboard/clients/objective/",
  },
  {
    id: 32,
    title: "Ernährungsplan",
    path: "/dashboard/clients/anamnese",
  },
  {
    id: 33,
    title: "Ernährungsplan",
    path: "/dashboard/clients/journal",
  },
  {
    id: 34,
    title: "Ernährungsplan",
    path: "/dashboard/clients/email-management",
  },
  {
    id: 34,
    title: "Ernährungsplan",
    path: "/dashboard/clients/nutrition-course",
  },
  {
    id: 35,
    title: "Ernährungsplan",
    path: "/dashboard/clients/invoices",
  },
  {
    id: 36,
    title: "Ernährungsplan",
    path: "/dashboard/clients/progress-and-measurements",
  },
  {
    id: 37,
    title: "Ernährungsplan",
    path: "/dashboard/clients/daily-routine",
  },
];
