import styled from "styled-components";

export const LeadCardContainer = styled.div`
padding: 0px 0.5rem;
.lead-card-header{
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: space-between;
  margin-top:-20px;
  overflow-y: auto;
  .close {
    img {
      cursor: pointer;
    }
}
`;
export const LeadCardBody = styled.div`
marginTop: 0.5;
.lead-card-parent{
  display: flex;
  justify-content: space-between;
  width:'100%';
  gap: 10px;
  margin-top:20px;
  .lead-card-child{
  gap:10px;
  display:flex;
  width:100%;
  flex-direction:column;
  label{
  font-size: 14px;
  }
 
  }
}  
}
`;

export const LeadCardBtn = styled.div`
display: flex;
justify-content: center;
align-items: center;
margin: 20px 0px 0px 0px;

`;
export const LeadCardSpanCount = styled.span`
float:right;
`
export const MainLeadErrorText = styled.div`
color: red;
font-size: 14px;

`;
