export const MENUS = [
  {
    id: 1,
    title: "Meine Leads",
    path: "/dashboard/leads/all",
  },
  {
    id: 2,
    title: "Leads von Foodiary",
    path: "/dashboard/leads/foodiary",
  },
];
