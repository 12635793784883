import styled from "styled-components";

export const Container = styled.div`
  /* display: grid;
  grid-template-columns: 300px 1fr; */
  display: flex;
`;
export const Wrapper = styled.div`
  padding: 1.5rem;
`;
export const WhitePaperWrap = styled.div``;

export const MCard = styled.div`
  box-shadow: 0px 2px 5px #0000001a;
  border-radius: 10px;
  padding: 1.875rem 1.56rem;
  background: #fff;
`;

export const Header = styled.div`
  padding: 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .header-actions {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
`;

export const WhitePaperTitle = styled.div`
  color: #44554c;
  font-size: 16px;
  line-height: 19px;
  font-weight: 400;
  margin: 2.5rem 0 1.5rem;
`;

export const WhitePaperInner = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
`;

export const Divider = styled.div`
  height: 1px;
  background-color: #e2e4e9;
`;

export const TrainerProfileDetailsSectionHeading = styled.h6`
  font-size: 1.25rem;
  color: #142c1f;
  margin: 0;
  margin-bottom: 5px;
  line-height: 1.5rem;
`;

export const TrainerProfileDetailsSectionSubHeading = styled.div`
  font-size: 16px;
  line-height: 19px;
  color: #818d88;
  font-weight: 300;
  margin: 1rem 0 0;
  max-width: 700px;

  .links {
    display: flex;
    align-items: center;
    gap: 1rem;

    strong {
      color: #142c1f;
      font-size: large.9rem;
      font-weight: 400;
    }

    .url-copy {
      display: flex;
      align-items: center;
      gap: 0.7rem;

      img {
        box-shadow: 0px 1px 2px #5258660f;
        border: 1px solid #e2e4e9;
        border-radius: 0.25rem;
        padding: 0.1875rem;
        background-color: #fff;
        cursor: pointer;
      }

      p {
        color: #818d88;
        font-size: 0.85rem;
        font-weight: 300;
        margin: 0;
      }
    }
  }
`;

export const TableWrapper = styled.div`
  table {
    width: 100%;
    border-collapse: collapse;
  }
  thead {
    background: #f6f8fa;

    th {
      color: #535865;
      font-size: 1rem;
      font-weight: 400;
      padding: 0.875rem 2rem;
      text-align: left;

      &:first-child {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
      }

      &:last-child {
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
      }
    }
  }

  tbody {
    tr {
      td {
        padding: 1rem 2rem;
        border-bottom: 1px solid #e2e4e9;

        p {
          margin: 0;
          font-size: 1rem;
          font-weight: 300;
        }
      }
    }
  }

  .partner {
    display: flex;
    align-items: center;
    gap: 0.625rem;

    img {
      background: #ffffff;
      box-shadow: 0px 1px 2px #e4e5e73d;
      border: 2px solid #e2e4e9;
      border-radius: 50%;
      width: 3.2rem;
      min-width: 3.2rem;
      height: 3.2rem;
    }

    h6 {
      margin: 0;
      color: #44554c;
      font-size: 0.9rem;
      line-height: 1.3rem;
      font-weight: 400;
    }

    p {
      margin: 0;
      color: #a5b5af;
      font-size: 0.9rem;
      font-weight: 300;
    }
  }

  .contact-person {
    h6 {
      margin: 0;
      color: #44554c;
      font-size: 0.9rem;
      line-height: 1.3rem;
      font-weight: 400;
    }
  }
`;

export const WhitePaperInnerCard = styled.div`
  border: 1px solid #eeeeee;
  border-radius: 10px;
  display: grid;
  grid-template-columns: 13.625rem 1fr;
  gap: 1.5rem;
  background-color: white;
`;

export const WhitePaperCardImage = styled.div`
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
  }
`;

export const WhitePaperCardText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 0.5rem;
  margin-block: 1rem;

  h3 {
    font-size: 1.125rem;
    line-height: 1.375rem;
    font-weight: 500;
    color: #142c1f;
    margin: 0;
  }
  p {
    color: #44554c;
    font-size: 0.75rem;
    line-height: 1.25rem;
    font-weight: 400;
    margin: 1rem 0;
  }

  a {
    background: #f5f5f5;
    border-radius: 0.3125rem;
    padding: 0.5rem;
    color: #44554c;
    text-transform: uppercase;
    font-size: 0.75rem;
    line-height: 1.0625rem;
    font-weight: 500;
    display: flex;
    align-items: center;
    border: none;
    cursor: pointer;
    gap: 0.5rem;
    width: fit-content;
    text-align: left;
    text-decoration: none;

    &:hover {
      background: #eeeeee;
    }
  }
`;

export const ClientDetailsContentHeading = styled.div`
  font-size: 1.25rem;
  color: #142c1f;
  margin-bottom: 0.3125rem;
`;

export const ClientDetailsContentSubHeading = styled.div`
  font-size: 0.9375rem;
  color: #818d88;
  margin-top: 1.25rem;
  font-weight: 400;
`;
