import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import * as S from "./subscription.styles";
import { useIntl } from "react-intl";
import { getRandomValueInRange } from "configs/utils";
// Components
import Topbar from "components/topbar/Topbar";
import TranslationMessage from "configs/translations/TranslationMessage";
import Card from "components/card/Card";
import Button from "components/formComponents/button/Button";
import * as SupplementsActions from "redux/supplements/supplements.actions";
import * as AuthActions from "redux/auth/auth.actions";
// Images
import GreenCheck from "assets/images/GreenCheck.svg";
import PROGreenCheck from "assets/images/PROGreenCheck.svg";
import ProIcon from "assets/images/Icon-foodiary-coaching-pro.svg";
import BasicIcon from "assets/images/Icon-foodiary-coaching-basic.svg";
import Check from "assets/images/check.svg";
import CheckCircle from "assets/images/checkcircle.svg";

const Subscription = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCard, setSelectedCard] = useState("subscriptionPro");
  const [isClose, setIsClose] = useState(false);

  const [selectCardsData, setSelectCardsData] = useState([
    {
      h1: "Foodiary Coaching PRO",
      id: "subscriptionPro",
      h2: "Ab 99,00 € pro Monat",
      p: "3 Monate Laufzeit",
      type: "pro",
    },
    {
      h1: "Foodiary Coaching PLUS",
      id: "subscriptionPlus",
      h2: "49,66 € pro Monat",
      p: "3 Monate Laufzeit",
      type: "plus",
    },
  ]);

  const [CardsData, setCardsData] = useState([
    {
      id: "subscriptionBasic",
      img: BasicIcon,
      title: "Foodiary App BASIC",
      description:
        "Fühlst du dich bereit, sodass du deine Ernährungsumstellung selbstständig starten kannst und dein Ziel erreichen?",
    },
  ]);
  const [proData, setproData] = useState([
    {
      img: Check,
      subheading: "12 Monate",
      description: "Smarte Ernährungsplaner App",
    },
    {
      img: Check,
      subheading: "bis zu 150,00 €",
      description: "Kostenrückerstattung",
    },
    {
      img: Check,
      subheading: "3 Monate",
      description: "persönliche Ernährungsberatung",
    },
    {
      img: Check,
      description: "Wöchentliche Gruppencalls und persönliche Betreuung",
    },
    {
      img: Check,
      subheading: "3 Monate",
      description: "Digitale Academy mit Online Kurs",
    },
    {
      img: Check,
      description: "Ebooks, Lernvideos, Bibliothek uvm.",
    },
  ]);
  const [plusData, setplusData] = useState([
    {
      img: Check,
      subheading: "3 Monate",
      description: "Smarte Ernährungsplaner App",
    },
    {
      img: Check,
      subheading: "3 Monate",
      description: "Digitale Academy mit Online Kurs",
    },
    {
      img: Check,
      subheading: "bis zu 100%",
      description: "Kostenrückerstattung",
    },
  ]);

  const subscriptionCards = ({ card, index }) => {
    return card.type === "pro" ? (
      <>
        <S.SubscriptionCoachingProHeading>
          <h1>{card.h1}</h1>
          <h2>{card.h2}</h2>
          <p>{card.p}</p>
        </S.SubscriptionCoachingProHeading>
        <S.SubscriptionCoachingContent>
          <ul>
            {(card.type === "pro" ? proData : plusData).map((itm, index2) => (
              <li className="items_data" key={index2}>
                <img src={itm.img} alt="" />
                <p>
                  <span>{itm.subheading} </span>
                  {itm.description}
                </p>
              </li>
            ))}
          </ul>
          <S.SubscriptionCoachingbuttons>
            <a href="#" className="button_pro">
              <img src={CheckCircle} alt="" />
              <span>Ausgewählt</span>
            </a>
          </S.SubscriptionCoachingbuttons>
        </S.SubscriptionCoachingContent>
      </>
    ) : (
      <>
        <S.SubscriptionCoachingPlusHeading>
          <h1>{card.h1}</h1>
          <h2>{card.h2}</h2>
          <p>{card.p}</p>
        </S.SubscriptionCoachingPlusHeading>
        <S.SubscriptionCoachingContent>
          <ul>
            {(card.type === "pro" ? proData : plusData).map((itm, index2) => (
              <li className="items_data" key={index2}>
                <img src={itm.img} alt="" />
                <p>
                  <span>{itm.subheading} </span>
                  {itm.description}
                </p>
              </li>
            ))}
          </ul>
          <S.SubscriptionCoachingbuttons>
            <a href="#" className="button_pro">
              <img src={CheckCircle} alt="" />
              <span>Auswählen</span>
            </a>
          </S.SubscriptionCoachingbuttons>
        </S.SubscriptionCoachingContent>
      </>
    );
  };

  return (
    <>
      {/*  percent={getRandomValueInRange(40, 45)} healthReport onClose={() => history.push('/login')} close  */}
      {/*  */}
      <Topbar
        prev="/premiumCoaching/success"
        next={
          selectedCard === "subscriptionPro"
            ? "/subscription/pro?user=Pro"
            : selectedCard === "subscriptionPlus"
            ? "/subscription/pro?user=Plus"
            : "/overview/basic"
        }
        name="subscription"
        isClose={isClose}
        close="true"
        setIsClose={setIsClose}
        progress={"73%"}
      />
      <S.SubscriptionContainer>
        <S.SubscriptionHeading>
          <TranslationMessage id="subscription.heading" />
        </S.SubscriptionHeading>
        <S.SubscriptionSubHeading>
          <TranslationMessage id="subscription.subHeading" />
        </S.SubscriptionSubHeading>
        {isClose && (
          <S.SubscriptionCardContainer>
            {isLoading && (
              <>
                <br />
                <br />
                <br />
                <br />
                <div className="text-center">
                  <div className="loader mx-auto"></div>
                </div>
                <br />
                <br />
                <br />
                <br />
              </>
            )}
            {!isLoading && (
              <>
                {CardsData.map((card) => (
                  <S.SubscriptionSimpleCard
                    key={card.id}
                    onClick={() => setSelectedCard(card.id)}
                    style={{
                      border:
                        card.id === selectedCard
                          ? "3px solid #2ECF96"
                          : "3px solid white",
                      background:
                        card.id === selectedCard ? "#F3FFFD" : "white",
                    }}
                  >
                    <img src={card.img} alt="" />
                    <h6>{card.title}</h6>
                    <p>{card.description}</p>
                  </S.SubscriptionSimpleCard>
                ))}
              </>
            )}
          </S.SubscriptionCardContainer>
        )}
        {!isClose && (
          <S.SubscriptionCardContainer>
            <S.SubscriptionCoachingContainer>
              {selectCardsData.map((card, index) => (
                <>
                  {card.type === "pro" ? (
                    <S.SubscriptionCoachingProContainer
                      key={index}
                      onClick={() => setSelectedCard(card.id)}
                      style={{
                        border:
                          card.id === selectedCard
                            ? "3px solid #2ECF96"
                            : "3px solid white",
                        background:
                          card.id === selectedCard ? "#F3FFFD" : "white",
                      }}
                    >
                      {subscriptionCards({ card, index })}
                    </S.SubscriptionCoachingProContainer>
                  ) : (
                    <S.SubscriptionCoachingPlusContainer
                      key={index}
                      onClick={() => setSelectedCard(card.id)}
                      style={{
                        border:
                          card.id === selectedCard
                            ? "3px solid #2ECF96"
                            : "3px solid white",
                        background:
                          card.id === selectedCard ? "#F3FFFD" : "white",
                      }}
                    >
                      {subscriptionCards({ card, index })}
                    </S.SubscriptionCoachingPlusContainer>
                  )}
                </>
              ))}
            </S.SubscriptionCoachingContainer>
          </S.SubscriptionCardContainer>
        )}
      </S.SubscriptionContainer>
    </>
  );
};

export default Subscription;

{
  /* {CardData.map((card) => {
                        return (
                            <Card
                                width={card.cardWidth}
                                height="430px"
                                margin="auto 10px"
                                textAlign="left"
                                key={card.id}
                                styles={{ border: card.id === selectedCard ? "3px solid #2ECF96" : 'none' }}
                                onClick={() => setSelectedCard(card.id)}
                            >
                                <S.SubscriptionCardInnerHeadingContainer background={card.cardHeadingBackground}>
                                    <S.SubscriptionCardInnerHeading><TranslationMessage id={card.cardHeading} /></S.SubscriptionCardInnerHeading>
                                    <S.SubscriptionCardInnerSubHeading dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: card.cardSubHeading }) }}></S.SubscriptionCardInnerSubHeading>
                                </S.SubscriptionCardInnerHeadingContainer>
                                <S.SubscriptionCardInnerDescription dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: card.cardDescription }) }}></S.SubscriptionCardInnerDescription>
                                <S.SubscriptionCardInnerBenefitsList>
                                    {card.benefits.map(benefit => {
                                        return (
                                            <S.SubscriptionCardInnerBenefitsListItem key={benefit}>
                                                <span><S.SubscriptionCardInnerBenefitsListItemCheck src={GreenCheck} /></span>
                                                <TranslationMessage id={benefit} />
                                            </S.SubscriptionCardInnerBenefitsListItem>
                                        )
                                    })}
                                </S.SubscriptionCardInnerBenefitsList>
                                <S.SubscriptionCardInnerButtonContainer>
                                    <Button color={card.buttonStyle.color} outlined={card.buttonStyle.outlined} margin="20px 0 0 0" type="submit">
                                        <span style={{ display: 'flex' }}>
                                            {card.buttonIcon && <S.SubscriptionCardInnerButtonIcon src={card.buttonIcon} />}
                                            <div style={{ lineHeight: '20px' }}>
                                                <TranslationMessage id={card.cardButtonValue} />
                                            </div>
                                        </span>
                                    </Button>
                                </S.SubscriptionCardInnerButtonContainer>
                            </Card>
                        )
                    })} */
}
