export const MENUS = [
  {
    id: 1,
    title: "Video Academy",
    path: "/dashboard/academy/video",
  },
  {
    id: 2,
    title: "Whitepaper",
    path: "/dashboard/academy/white-paper",
  },
  {
    id: 3,
    title: "Expertencalls",
    path: "/dashboard/academy/experts-calls",
  },
];
