import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import { Link, NavLink } from "react-router-dom";
import { useAlert } from "react-alert";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import Typography from "@material-ui/core/Typography";
import { ToastContainer, toast } from "react-toastify";

import CopyIcon from "assets/images/copy-icon-black.svg";
import LockSVG from "assets/images/lock.svg";
import NewTabIcon from "assets/images/newtabicon.svg";

import * as S from "./innerside.styles";

const useStyles = makeStyles((theme) => ({
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

const InnerSideNav = ({
  menus = [],
  hideUserInfo = false,
  backURL = "",
  idsSection = false,
  userProfileInfo = null,
  onPasswordLink,
  onCheckStripeConnection,
  isCheckStripeConnectedAccountStatusLoading = false,
}) => {
  const classes = useStyles();
  const alert = useAlert();

  return (
    <>
      
      <S.InnerMenuWrap className="inner-menu-wrap">
        {backURL && (
          <S.InnerMenuTop>
            <Link to={backURL}>
              <NavigateBeforeIcon />
              <span>Zurück zur Kundenübersicht</span>
            </Link>
          </S.InnerMenuTop>
        )}
        {!hideUserInfo && (
          <S.InnerMenuMid>
            <Avatar
              alt="Remy Sharp"
              src={userProfileInfo?.profilePic || "/static/images/avatar/1.jpg"}
              className={classes.large}
            />
            <S.InnerMenuInfo>
              <Typography variant="h5" className="">
                {userProfileInfo?.prename} {userProfileInfo?.surname}
              </Typography>
              <Typography paragraph>
                <span>FRANCHISE-ID: </span>
                {userProfileInfo?.franchiseId}
              </Typography>
            </S.InnerMenuInfo>
          </S.InnerMenuMid>
        )}

        {idsSection && (
          <S.InnerMenuMid>
            <S.InnerMenuInfo>
              <div className="id-item">
                <p>
                  <strong>Stripe-App-Id:</strong>
                  <br />
                  <div>{userProfileInfo?.connected_user_account_id}</div>
                </p>
                <div>
                  <img
                    src={CopyIcon}
                    alt="CopyIcon"
                    onClick={() => {
                      navigator.clipboard.writeText(
                        userProfileInfo?.connected_user_account_id
                      );
                      toast("📃 Erfolgreich kopiert.");
                    }}
                  />
                </div>
              </div>
              <div className="id-item">
                <p>
                  <strong>Stripe-Coach-Id:</strong> <br />
                  <div>{userProfileInfo?.stripeId}</div>
                </p>
                <div>
                  <img
                    src={CopyIcon}
                    alt="CopyIcon"
                    onClick={() => {
                      navigator.clipboard.writeText(userProfileInfo?.stripeId);
                      toast("📃 Erfolgreich kopiert.");
                    }}
                  />
                </div>
              </div>

              {/* <p
                className="change-password"
                onClick={() => {
                  onPasswordLink();
                }}
              >
                <img src={LockSVG} alt="lock" />
                Passwort ändern
              </p> */}
            </S.InnerMenuInfo>
          </S.InnerMenuMid>
        )}

        <S.InnerMenuLast>
          <Typography variant="h6">KATEGORIEN</Typography>

          {/* <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>Tagesablauf</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Link href="#">link 1</Link>
            <Link href="#">link 2</Link>
          </AccordionDetails>
        </Accordion> */}

          {menus.map((item) => {
            if (item?.isModal) {
              return (
                <a
                  href="javascript:void(0)"
                  key={item?.id}
                  onClick={() => {
                    onPasswordLink();
                  }}
                >
                  {item?.title}
                </a>
              );
            }

            if (item?.url) {
              return (
                <a
                  href={item?.url}
                  key={item?.id}
                  target="_blank"
                  rel="noreferrer"
                >
                  {item?.title}{" "}
                  <img className="new-tab-icon" src={NewTabIcon} alt="" />
                </a>
              );
            } else if (item?.isEmail) {
              return (
                <a href={`mailto:${item?.email}`} key={item?.id}>
                  {item?.title}
                </a>
              );
            } else {
              if (item.title === "Erstgespräch starten") {
                return (
                  <a
                    className={
                      isCheckStripeConnectedAccountStatusLoading
                        ? "d-flex gap-3 active-link"
                        : "d-flex gap-3"
                    }
                    href="javascript:void(0)"
                    key={item?.id}
                    onClick={onCheckStripeConnection}
                  >
                    {item?.title}{" "}
                    {isCheckStripeConnectedAccountStatusLoading && (
                      <div className="loader-small"></div>
                    )}
                  </a>
                );
              } else {
                return (
                  <NavLink
                    to={item?.path}
                    key={item?.id}
                    activeClassName="active-link"
                  >
                    {item?.title}
                  </NavLink>
                );
              }
            }
          })}
          <br />
          <br />
          <br />
        </S.InnerMenuLast>
      </S.InnerMenuWrap>
    </>
  );
};

export default InnerSideNav;
