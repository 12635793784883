import styled from 'styled-components';

export const Alert = styled.div`
    background: ${props => props.state.isSuccess && '#199c86'};
    background: ${props => props.state.isError && 'red'};
	pointer-events: auto;
	padding: 14px 16px;
	border-radius: 3px 3px 3px 3px;
	box-shadow: 0 0 10px #999;
    margin: ${props => props.state.containerMargin || '0'};
`;

export const Message = styled.p`
	color: white;
    font-weight: 700;
	font-size: 16px;
	text-align: left;
	margin: 0;
`;