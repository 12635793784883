import styled from "styled-components";

export const QualificationImageUploadDiv = styled.div`
    height: 40px;
`

export const QualificationImageUploadLabel = styled.label`
    height: 100%;
    padding: 10px 12px 4px;
    border-radius: 10px;
    display: inline-block;
    margin-left: 8px;
    cursor: pointer;
    border: solid 1px #E2E4E9;
    & img {
        width: 14px;
    }
`;
