import styled from "styled-components";

export const Container = styled.div`
  /* display: grid;
  grid-template-columns: 300px 1fr; */
  display: flex;
`;

export const Wrapper = styled.div`
  padding: 1.5rem;
`;

export const InlineThreeControls = styled.div`
  display: grid;
  grid-template-columns: 7.5rem 1fr 1fr;
  gap: 1rem;
`;

export const FormControl = styled.div`
  position: relative;

  input {
    color: #44554c;
  }

  img {
    cursor: pointer;
    position: absolute;
    top: 12px;
    left: 10px;
    width: 18px;
  }

  input {
    padding: 11px 15px 11px 32px;
  }
`;

export const TrainerProfileDetailsSectionHeading = styled.h6`
  font-size: 1.25rem;
  color: #142c1f;
  margin: 0;
  margin-bottom: 5px;
  line-height: 1.5rem;
`;

export const TrainerProfileDetailsSectionSubHeading = styled.div`
  font-size: 16px;
  line-height: 19px;
  color: #818d88;
  font-weight: 400;
  margin: 1.2rem 0 20px;
`;

export const Divider = styled.div`
  height: 1px;
  background-color: #e2e4e9;
`;

export const FiltersBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  padding: 1.5rem 0 1rem 0;
`;

export const SelectedFiltersAndDropdown = styled.div`
  display: flex;
  gap: 0.5rem;

  .faq-select {
    min-width: 200px;
  }
`;

export const SelectedFilter = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 9px 1rem;
  background: #ffffff;
  box-shadow: 0px 1px 2px #1018280d;
  border: 1px solid #d0d5dd;
  border-radius: 5px;
  color: #44554c;
  font-weight: 400;

  img {
    cursor: pointer;
  }
`;

export const FilterSearchTitle = styled.p`
  font-size: 14px;
  line-height: 17px;
  color: #44554c;
  font-weight: 400;
  margin: 0;
`;

export const FAQList = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1.5rem;
  border-top: 1px solid #eeeeee;
`;

export const FAQItem = styled.div``;

export const FAQItemHeader = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  padding: 1rem 0;
  p {
    margin: 0;
    color: #142c1f;
    font-size: 1.125rem;
    line-height: 1.5rem;
    font-weight: 400;
  }

  img {
    cursor: pointer;
  }
`;

export const FAQItemBody = styled.div`
  padding: 0.5rem 0 1rem 0;
  p {
    margin: 0;
    color: #44554c;
    font-size: 1rem;
    line-height: 1.25rem;
    font-weight: 300;
  }
`;

export const SuccessAlert = styled.div`
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
  position: relative;
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  font-weight: 400;
`;

export const ErrorAlert = styled.div`
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
  position: relative;
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  font-weight: 400;
`;

export const TrainerProfileDetailsInnerContainer = styled.div`
  padding: 1.5rem;
  border-radius: 10px;
  height: 100%;
  background-color: #fff;
`;

export const TrainerProfileDetailsSection1InputLabel = styled.div`
  font-size: 0.875rem;
  color: #44554c;
  line-height: 1.5rem;
  margin: 0 0 10px;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;

  textarea {
    border: 1px solid #dddddd;
    border-radius: 4px;
    width: 100% !important;
    background-color: transparent;
  }

  img {
    position: relative;
  }
`;
