import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, Link, Redirect } from "react-router-dom";
import Slider from "react-slick";
import * as S from "./NewLogin.styles";
// Components
import Card from "components/card/Card";
import Input from "components/formComponents/input/Input";
import Button from "components/formComponents/button/Button";
import Loader from "components/loader/Loader";
import ErrorMessage from "components/formComponents/errorMessage/ErrorMessage";
import FoodiaryLogo from "assets/images/Foodiary-Logo.PNG";
import Hand from "assets/images/YoHand.svg";
import FoodiaryBackground from "assets/images/Foodiary-Background.jpg";
import DashboardOne from "assets/images/Foodiary-dashboard-screen.jpg";
import DashboardTwo from "assets/images/Foodiary-Klienten-Screen.jpg";
import DashboardThree from "assets/images/Foodiary-Academy-Screen.jpg";
// Redux
import * as AuthActions from "redux/auth/auth.actions";

const NewLogin = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [IsError, setIsError] = useState(false);
  const loading = useSelector((state) => state.auth.loading);
  const signInResponse = useSelector((state) => state.auth.signInResponse);

  const authenticated =
    localStorage.getItem("trainer_token") &&
    localStorage.getItem("token") &&
    signInResponse?.data?.token;

  useEffect(() => {
    if (signInResponse?.isError || signInResponse?.FranchiseID) {
      setIsError(true);

      const t = setTimeout(() => {
        setIsError(false);
        dispatch(AuthActions.signInNull(null));
        clearTimeout(t);
      }, 2000);
    }
  }, [signInResponse]);

  if (authenticated) return <Redirect to="/dashboard/main" />;

  const initialValues = {
    franchiseId: "",
    password: "",
  };

  const validationSchema = Yup.object().shape({
    franchiseId: Yup.string().required("Erforderlich"),
    password: Yup.string().required("Erforderlich"),
  });

  const onFormSubmit = (values, { resetForm }) => {
    const formData = {
      FranchiseID: values.franchiseId,
      password: values.password,
    };

    dispatch(
      AuthActions.signInRequest({
        formData,
        callback: (res) => {
          localStorage.setItem("trainerID", res?.data?.trainerID);
        },
      })
    );
    resetForm();
  };

  let settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };

  return (
    <>
      <S.LoginContainer>
        <Card
          width="100%"
          maxWidth="530px"
          height="auto"
          margin="auto"
          textAlign="center"
        >
          <S.ChangePasswordInnerContainer className="relative">
            <img width={100} src={FoodiaryLogo} alt="" />
            <S.ChangePasswordHeading>
              Login zum Coach Dashboard
            </S.ChangePasswordHeading>
            <S.ChangePasswordSubHeading>
              Gebe deine Zugangsdaten ein.
            </S.ChangePasswordSubHeading>
            {IsError && (
              <S.AlertDanger>Dein Login war nicht erfolgreich</S.AlertDanger>
            )}
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onFormSubmit}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                errors,
                isValid,
                dirty,
              }) => (
                <form
                  className="relative z-1"
                  noValidate
                  autoComplete="off"
                  onSubmit={handleSubmit}
                >
                  <div className="form-group">
                    <label>CoachID</label>
                    <Input
                      className="input-v2"
                      type="text"
                      placeholder="Deine CoachID"
                      name="franchiseId"
                      onChange={handleChange}
                      value={values.franchiseId}
                    />
                    <ErrorMessage error={errors.franchiseId} />
                  </div>
                  <div className="form-group">
                    <label>Passwort</label>
                    <Input
                      className="input-v2"
                      type="password"
                      placeholder="Passwort"
                      name="password"
                      onChange={handleChange}
                      value={values.password}
                    />
                    <ErrorMessage error={errors.password} />
                  </div>
                  <div className="check-and-link">
                    {/* <S.SelfMadeCheckBoxRow>
                      <label className="form-control">
                        <input type="checkbox" name="checkbox" />
                      </label>
                      <span>Login speichern</span>
                    </S.SelfMadeCheckBoxRow> */}

                    <Link to="/reset-password" className="password-reset-link">
                      Passwort vergessen?
                    </Link>
                  </div>

                  <Button
                    color="rgba(248, 164, 146, 1)"
                    gradient="linear-gradient(60deg, rgba(46, 207, 150, 1), rgba(28, 157, 135, 1)) !important"
                    style={{ fontSize: "16px" }}
                    margin="20px 0 0 0"
                    absolute={{ width: "100%", height: "45px" }}
                    buttonStyle={{ width: "100%" }}
                    type="submit"
                  >
                    Anmelden
                  </Button>
                  <Loader loading={loading} top="-135px" />
                </form>
              )}
            </Formik>
            <img className="hand" src={Hand} alt="" />
          </S.ChangePasswordInnerContainer>
        </Card>

        {/* <S.SliderContainer imgUrl={FoodiaryBackground}>
 
          <Slider {...settings}>
            <div className="slide-item">
              <img src={DashboardOne} alt="" />
              <h4>Verwalte deine Ernährungsberatung</h4>
              <p>
                Mit dem Coach Dashboard verwaltest du deine Klienten, die Coach
                Webseite und hast Zugang zu wichtigen Funktionen.
              </p>
            </div>
            <div className="slide-item">
              <img src={DashboardTwo} alt="" />
              <h4>Betreue deine Klienten und Teilnehmer</h4>
              <p>
                Über die Klientenverwaltung hast du alle Daten deiner Klienten
                im Überblick und kannst hier wichtige Einstellungen vornehmen.
              </p>
            </div>
            <div className="slide-item">
              <img src={DashboardThree} alt="" />
              <h4>Lerne wichtige Grundlagen</h4>
              <p>
                Mit unserer Academy und den Whitepaper lernst du wichtige
                Grundlagen rund um das Thema “selbstständige Ernährungsberatung”
              </p>
            </div>
          </Slider>
        </S.SliderContainer> */}
      </S.LoginContainer>
    </>
  );
};

export default NewLogin;
