import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useAlert } from "react-alert";
import { useHistory, useParams } from "react-router-dom";
import { FadeLoader } from "react-spinners";

import LinkIcon from "assets/images/link.svg";
import PDFIcon from "assets/images/pdf.svg";
import ArrowLeft from "assets/images/arrowLeft.svg";

import * as AuthActions from "redux/auth/auth.actions";
import * as DashboardActions from "redux/dashboard/dashboard.actions";
import * as S from "./ClientDetails.styles";
import "./clientDetails.scss";
import ClientDetailsSidebar from "./ClientDetailsSidebar";

const Transfer = () => {
  const [showLoader, setShowLoader] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const { userId, invoiceId } = useParams();

  const clientDetails = useSelector(
    (state) => state.auth.getClientDetails?.data
  );
  const isClientDetailsLoading = useSelector(
    (state) => state.auth.isClientDetailsLoading
  );
  const trainerData = useSelector((state) => state.auth.trainerData);

  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    dispatch(
      AuthActions.getClientDetailsRequest({
        payload: {
          trainerID: trainerData?.trainerID,
          userID: +userId,
        },
        callback: (res) => {
          console.log(res);
        },
      })
    );
  }, []);

  useEffect(() => {
    if (invoiceId) {
      setIsLoading(true);
      dispatch(
        AuthActions.getInvoicesByTrainerRequest({
          formData: {
            trainerID: trainerData?.trainerID,
          },
          callback: (res) => {
            setIsLoading(false);
            Object.entries(res.data).map(([key, value]) => {
              if (value.id == invoiceId) {
                setSelectedInvoice(value);
              }
            });
          },
        })
      );
    }
  }, [invoiceId]);

  const renderStatusBadge = (status) => {
    switch (status) {
      case "Completed":
        return (
          <div className="status-badge text-center green">Erfolgreich</div>
        );

      case "Incomplete":
        return (
          <div className="status-badge text-center orange-yellow">
            Unvollständig
          </div>
        );

      case "Processing":
        return (
          <div className="status-badge text-center orange-yellow">
            Wird bearbeitet
          </div>
        );

      case "Canceled":
        return <div className="status-badge text-center red">Storniert</div>;

      case "Pending":
        return <div className="status-badge text-center grey">Ausstehend</div>;

      case "Refunded":
        return (
          <div className="status-badge text-center red">Zurückerstattet</div>
        );

      default:
        return null;
    }
  };

  const handleBack = () => {
    const url = window.location.pathname.split("/detail")[0];
    history.push(url);
  };

  return (
    <>
      <S.ClientDetailsOverallContainer>
        <S.ClientDetailsContainer>
          <div className="second-sidebar-wrapper">
            <ClientDetailsSidebar />
          </div>
          <div className="second-sidebar-content" style={{ padding: "1.5rem" }}>
            {isLoading && (
              <>
                <br />
                <br />
                <br />
                <br />
                <div className="text-center">
                  <div className="loader mx-auto"></div>
                </div>
                <br />
                <br />
                <br />
                <br />
              </>
            )}
            {selectedInvoice && !isLoading && (
              <>
                <S.ContainerBox>
                  <S.Header>
                    <div className="with-back-arrow">
                      <img
                        className="cursor-pointer"
                        src={ArrowLeft}
                        alt="back"
                        onClick={handleBack}
                      />
                      <div>
                        <S.Heading>Abrechnung</S.Heading>
                        <S.SubHeading>
                          Hier findest du die Übersicht der Transaktion
                        </S.SubHeading>
                      </div>
                    </div>
                  </S.Header>

                  <S.Divider></S.Divider>
                  {showLoader ? (
                    <div className="d-flex justify-content-center align-items-center">
                      <FadeLoader />
                    </div>
                  ) : (
                    <S.InvoiceDetailsContainer>
                      <br />

                      <S.InvoiceDetailsHeader>
                        <h6>
                          {`${clientDetails?.prename} ${clientDetails?.surname}`}
                        </h6>

                        <div className="d-flex align-items-center gap-3">
                          <span>Transaktionsstatus</span>

                          {renderStatusBadge(selectedInvoice?.payment_status)}
                        </div>
                      </S.InvoiceDetailsHeader>

                      <br />

                      <S.Divider></S.Divider>

                      <S.DataSection>
                        <h6>Timestamps</h6>

                        <div className="data-rows">
                          <div className="data-row">
                            <p>Rechnung erstellt am:</p>

                            <p>
                              {selectedInvoice.invoice_created_at
                                .split("-")
                                .reverse()
                                .join(".")}
                            </p>
                          </div>

                          <div className="data-row">
                            <p>Bezahlt am:</p>

                            <p>
                              {selectedInvoice.invoice_paid_at
                                ? selectedInvoice.invoice_paid_at
                                    .split("-")
                                    .reverse()
                                    .join(".")
                                : "noch nicht verfügbar"}
                            </p>
                          </div>

                          <div className="data-row">
                            <p>Geldeingang am:</p>

                            <p>
                              {selectedInvoice.invoice_money_arrived_at
                                ? selectedInvoice.invoice_money_arrived_at
                                    .split("-")
                                    .reverse()
                                    .join(".")
                                : "noch nicht verfügbar"}
                            </p>
                          </div>
                          <div className="data-row">
                            <p>Storniert am:</p>

                            <p>
                              {selectedInvoice.invoice_canceled_at
                                ? selectedInvoice.invoice_canceled_at
                                    .split("-")
                                    .reverse()
                                    .join("/")
                                : "-"}
                            </p>
                          </div>
                          <div className="data-row">
                            <p>Zurückerstattet am:</p>

                            <p>
                              {selectedInvoice.invoice_refunded_at
                                ? selectedInvoice.invoice_refunded_at
                                    .split("-")
                                    .reverse()
                                    .join("/")
                                : "-"}
                            </p>
                          </div>
                        </div>
                      </S.DataSection>

                      <S.Divider></S.Divider>

                      <S.DataSection>
                        <h6>Bezahldetails</h6>

                        <div className="data-rows">
                          <div className="data-row">
                            <p>Gesamtbetrag</p>

                            <p>{selectedInvoice.invoice_amount} €</p>
                          </div>

                          <div className="data-row">
                            <p>Transaktionsgebühr</p>

                            <p>{selectedInvoice.transaction_fee} €</p>
                          </div>

                          <div className="data-row">
                            <p>Höhe der Betreuungsgutschrift</p>

                            <p>{selectedInvoice.amount_transferred} €</p>
                          </div>
                        </div>

                        <span className="note">
                          *Werbepartnerschaften werden gesondert abgerechnet
                        </span>
                      </S.DataSection>

                      <S.Divider></S.Divider>

                      <S.DataSection>
                        <h6>Bezahlmethode</h6>

                        <div className="data-rows">
                          <div className="data-row">
                            <p>ID der Rechnung</p>

                            <p>{selectedInvoice.invoice_id}</p>
                          </div>

                          <div className="data-row">
                            <p>Quellentransaktion</p>

                            <p>
                              {selectedInvoice.source_transaction_id
                                ? selectedInvoice.source_transaction_id
                                : "noch nicht verfügbar"}
                            </p>
                          </div>

                          <div className="data-row">
                            <p>ID des Transfers</p>

                            <p>
                              {selectedInvoice.transfers_id
                                ? selectedInvoice.transfers_id
                                : "noch nicht verfügbar"}
                            </p>
                          </div>

                          <div className="data-row">
                            <p>ID der Auszahlung</p>

                            <p>
                              {selectedInvoice.payout_id
                                ? selectedInvoice.payout_id
                                : "noch nicht verfügbar"}
                            </p>
                          </div>
                          <div className="data-row">
                            <p>ID der Rückerstattung</p>
                            <p>
                              {selectedInvoice.credit_id
                                ? selectedInvoice.credit_id
                                : "-"}
                            </p>
                          </div>

                          <div className="data-row">
                            <p>Rückerstattungsnummer</p>
                            <p>
                              {selectedInvoice.credit_number
                                ? selectedInvoice.credit_number
                                : "-"}
                            </p>
                          </div>
                        </div>
                      </S.DataSection>

                      <S.Divider></S.Divider>

                      <S.DataSection>
                        <div className="d-flex align-items-center justify-content-between">
                          <h6>Bezahllink</h6>

                          <div className="link-box">
                            <img src={LinkIcon} alt="" />

                            <a
                              href={selectedInvoice.payment_link_url}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {selectedInvoice.payment_link_url}
                            </a>
                          </div>
                        </div>

                        <div className="d-flex align-items-center justify-content-between">
                          <h6>Transaktionsbeleg</h6>

                          {selectedInvoice &&
                          selectedInvoice.transaction_pdf_url ? (
                            <div className="file-item d-flex align-items-center">
                              <img width={30} src={PDFIcon} alt="" />
                              <a
                                href={selectedInvoice.transaction_pdf_url}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {selectedInvoice.transfers_id.substring(0, 8)}
                              </a>
                            </div>
                          ) : (
                            <div className="file-item">
                              <span>noch nicht verfügbar</span>
                            </div>
                          )}
                        </div>

                        <div className="d-flex align-items-center justify-content-between">
                        <h6>Stornobeleg</h6>

                        {selectedInvoice &&
                        selectedInvoice.storno_transaction_pdf_url ? (
                          <div className="file-item d-flex align-items-center">
                            <img width={30} src={PDFIcon} alt="" />
                            <a
                              href={selectedInvoice.storno_transaction_pdf_url}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {selectedInvoice.storno_transaction_pdf_url.substring(0, 10)}
                            </a>
                          </div>
                        ) : (
                          <div className="file-item">
                            <span>-</span>
                          </div>
                        )}
                      </div>
                      </S.DataSection>

                      <br />
                    </S.InvoiceDetailsContainer>
                  )}
                </S.ContainerBox>
              </>
            )}
          </div>
        </S.ClientDetailsContainer>
      </S.ClientDetailsOverallContainer>
    </>
  );
};

export default Transfer;
