import styled from "styled-components";

export const EvaluationContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1100px;
  margin-top: 2rem;

  @media (max-width: 600px) {
  }
`;

export const EvaluationInnerContainer = styled.div``;

export const EvaluationHeading = styled.div`
  margin: 0 0 10px 0;
  color: #1c9d87;
  font-size: 14px;
  text-align: center;
`;

export const EvaluationSubHeading = styled.div`
  color: #142c1f;
  font-size: 22px;
  margin-bottom: 50px;
  text-align: center;
`;

export const GridView = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;

  @media screen and (max-width: 1350px) {
    grid-template-columns: 1fr;
  }
`;

export const EvaluationCard = styled.div`
  background: #ffffff;
  box-shadow: 0px 13px 20px #d6d6d629;
  border-radius: 8px;
  padding: 30px;

  .title {
    color: #142c1f;
    font-size: 18px;
    line-height: 22px;
    margin-block: 0;
  }

  .desc {
    color: #44554c;
    font-size: 16px;
    line-height: 24px;
    margin: 10px 0 20px;
    font-weight: 400;
  }

  .eval-desc {
    margin-top: 25px;
    color: #a5b5af;
    font-size: 14px;
    font-weight: 300;
    line-height: 18px;
    font-weight: 400;
  }

  .evaluation-items {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    gap: 5px;
    .evaluation-item {
      padding: 5px;
      border: 2px solid transparent;
      border-radius: 15px;
      text-align: center;
      cursor: pointer;
      &.active {
        border-color: #142c1f;
      }

      .status-bar {
        width: 100%;
        height: 20px;
        border-radius: 10px;

        &.green-1 {
          background: rgba(46, 207, 150, 0.25);
        }
        &.green-2 {
          background: rgba(46, 207, 150, 0.5);
        }
        &.green-3 {
          background: rgba(46, 207, 150, 0.75);
        }
        &.green-4 {
          background: rgba(46, 207, 150, 1);
        }

        &.light-green {
          background: #a7e140;
        }

        &.light-orange {
          background: #fed400;
        }

        &.orange {
          background: #f39b29;
        }

        &.red-1 {
          background: rgba(226, 104, 104, 0.25);
        }
        &.red-2 {
          background: rgba(226, 104, 104, 0.5);
        }
        &.red-3 {
          background: rgba(226, 104, 104, 0.75);
        }
        &.red-4 {
          background: rgba(226, 104, 104, 1);
        }
      }

      img {
        margin-top: 8px;
        width: 26px;
        height: 26px;
      }
    }
  }
`;
