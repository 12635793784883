import styled, { css } from "styled-components";

export const HL = styled.h3`
  padding: 4rem 0;
  text-align: center;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;

  .btn-dark {
    background: linear-gradient(180deg, #47584f 0%, #596860 100%);
    border-radius: 10px;
    padding: 10px 27px;
    color: white;
    border: none;
    display: flex;
    align-items: center;
    gap: 8px;
    justify-content: center;
    cursor: pointer;

    &:hover {
      box-shadow: 0px 1px 2px #5258664f;
    }
  }
`;

export const NutritionCourseDetailsBox = styled.div`
  padding: 1.5rem;
`;

export const BoxTitle = styled.h4`
  color: #142c1f;
  font-size: 18px;
  line-height: 22px;
  font-weight: 500;
  margin: 0 0 1rem 0;
`;

export const GridFourColumn = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 1rem;
`;

export const ProgressCard = styled.div`
  padding: 15px;
  background: #ffffff;
  box-shadow: 0px 1px 2px #1018280d;
  border: 1px solid #d0d5dd;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const ProgressCardTitle = styled.div`
  color: #142c1f;
  font-size: 14px;
  line-height: 17px;
  font-weight: 400;
`;

export const ProgressCardSubTitle = styled.div`
  color: #44554c;
  font-size: 12px;
  line-height: 17px;
  font-weight: 300;
`;

export const ProgressCardDate = styled.div`
  color: #142c1f;
  font-size: 16px;
  line-height: 19px;
  font-weight: 500;
`;

export const ProgressBars = styled.div`
  display: flex;
  gap: 4px;

  div {
    background: #e2e4e9;
    border-radius: 2px;
    width: 6px;
    height: 19px;

    &.active {
      background: #3dd29d;
    }
  }
`;

export const GridTwoColumns = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
`;

export const CourseDetailsCard = styled.div`
  background: #ffffff;
  box-shadow: 0px 1px 2px #1018280d;
  border: 1px solid #d0d5dd;
  border-radius: 16px;
  padding: 16px;
`;

export const CourseDetailsCardHeader = styled.div`
  display: flex;
  gap: 1rem;

  h5 {
    color: #142c1f;
    font-size: 16px;
    line-height: 19px;
    font-weight: 500;
    margin: 0;
  }

  p {
    color: #44554c;
    font-size: 14px;
    line-height: 17px;
    font-weight: 300;
    margin: 8px 0 0 0;
  }

  .badge {
    border-radius: 5px;
    padding: 4px 8px;
    color: white;
    font-size: 13px;
    line-height: 15px;
    font-weight: 300;

    &.light-green-bg {
      background: #3dd29d;
    }

    &.green-bg {
      background: #1c9d87;
    }

    &.grey-bg {
      background: #868c98;
    }

    &.orange-bg {
      background: #f17b2c;
    }
  }
`;

export const CourseDetailsCardBody = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.3rem;
  margin: 1rem 0;

  p {
    font-weight: 300;
    strong {
      font-weight: 500;
    }
  }
`;

export const CourseDetailsCardFooter = styled.div`
  button {
    background: #ffffff;
    box-shadow: 0px 1px 2px #5258660f;
    border: 1px solid #e2e4e9;
    border-radius: 10px;
    padding: 12px 20px;
    text-align: center;
    color: #141414;
    font-size: 14px;
    line-height: 17px;
    font-weight: 400;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    justify-content: center;

    &.w-full {
      width: 100%;
    }

    &:not(:disabled):hover {
      box-shadow: 0px 1px 2px #5258664f;
    }

    &:disabled {
      cursor: not-allowed;
    }
  }
`;
