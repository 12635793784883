import DashboardActionTypes from "./dashboard.types";

/*************************************************/

const INITIAL_STATE = {
  linkedinPostsByCategory: null,
  isLinkedinPostsByCategoryLoading: false,

  linkedinSubCategoriesByCategory: null,
  isLinkedinSubCategoriesByCategoryLoading: false,

  allLinkedinCategories: null,
  isAllLinkedinCategoriesLoading: false,

  allLinkedinPosts: null,
  isAllLinkedinPostsLoading: false,

  linkedinPosts: [],

  allWhitePaperData: [],
  isAllWhitePaperDataLoading: false,

  trainerProfileTempDetails: null,
  isTrainerProfileTempDetailsLoading: false,

  onboardingChecks: null,
  isOnBoardingChecksLoading: false,
  updateOnBoardingCheck: null,
  isUpdateOnBoardingCheckLoading: false,

  activeClientsLastDays: null,
  isActiveClientsLastDaysLoading: false,

  initialDiscussionChartDataCurrentMonth: null,
  initialDiscussionChartDataPreviousMonth: null,
  isInitialDiscussionChartDataLoading: false,

  activeClientsCurrentMonthChartData: null,
  activeClientsPreviousMonthChartData: null,
  isActiveClientsChartDataLoading: false,

  salesPreviousMonthChartData: null,
  salesCurrentMonthChartData: null,
  isSalesChartDataLoading: false,

  userTransaction: null,
  isUserTransactionLoading: false,

  // createHabbit: null,
  // isHabbitCreating: false,

  // createJournal: null,
  // isJournalCreating: false,

  habbit: null,
  isHabbitLoading: false,

  journal: null,
  isJournalLoading: false,

  expertsCalls: null,
  nextExpertCall: null,
  isExpertsLoading: false,

  userWeightData: null,
  isUserWeightDataLoading: false,

  trainerInvoices: null,
  isTrainerInvoicesLoading: false,

  trainerCredits: null,
  isTrainerCreditsLoading: false,

  sendEmail: null,
  isEmailSending: false,

  trainerNews: null,
  isTrainerNewsLoading: false,

  updateGoalWeight: null,
  isGoalWeightUpdating: false,

  checkStripeConnectedAccountStatus: null,
  isCheckStripeConnectedAccountStatusLoading: false,
};

/*************************************************/

const dashboardReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case DashboardActionTypes.GET_ALL_LINKEDIN_POSTS_SUCCESS:
      return {
        ...state,
        allLinkedinPosts: action.payload?.getAllPosts,
        linkedinPosts: action.payload?.getAllPosts,
      };
    case DashboardActionTypes.GET_ALL_LINKEDIN_POSTS_LOADING_START:
      return {
        ...state,
        isAllLinkedinPostsLoading: true,
      };
    case DashboardActionTypes.GET_ALL_LINKEDIN_POSTS_LOADING_STOP:
      return {
        ...state,
        isAllLinkedinPostsLoading: false,
      };

    case DashboardActionTypes.GET_ALL_LINKEDIN_CATEGORIES_SUCCESS:
      return {
        ...state,
        allLinkedinCategories: action.payload?.getAllCategories,
      };

    case DashboardActionTypes.GET_ALL_LINKEDIN_CATEGORIES_LOADING_START:
      return {
        ...state,
        isAllLinkedinCategoriesLoading: true,
      };
    case DashboardActionTypes.GET_ALL_LINKEDIN_CATEGORIES_LOADING_STOP:
      return {
        ...state,
        isAllLinkedinCategoriesLoading: false,
      };

    case DashboardActionTypes.GET_ALL_LINKEDIN_POSTS_BY_CATEGORIES_SUCCESS:
      return {
        ...state,
        linkedinPostsByCategory: action.payload?.getAllPostsByCategory,
        linkedinPosts: action.payload?.getAllPostsByCategory,
      };
    case DashboardActionTypes.GET_ALL_LINKEDIN_POSTS_BY_CATEGORIES_LOADING_START:
      return {
        ...state,
        isLinkedinPostsByCategoryLoading: true,
      };
    case DashboardActionTypes.GET_ALL_LINKEDIN_POSTS_BY_CATEGORIES_LOADING_STOP:
      return {
        ...state,
        isLinkedinPostsByCategoryLoading: false,
      };

    case DashboardActionTypes.GET_LINKEDIN_SUB_CATEGORIES_BY_PARENT_CATEGORY_SUCCESS:
      return {
        ...state,
        linkedinSubCategoriesByCategory:
          action.payload?.getSubCategoriesByParent,
      };
    case DashboardActionTypes.GET_LINKEDIN_SUB_CATEGORIES_BY_PARENT_CATEGORY_LOADING_START:
      return {
        ...state,
        isLinkedinSubCategoriesByCategoryLoading: true,
      };
    case DashboardActionTypes.GET_LINKEDIN_SUB_CATEGORIES_BY_PARENT_CATEGORY_LOADING_STOP:
      return {
        ...state,
        isLinkedinSubCategoriesByCategoryLoading: false,
      };
    // White Paper
    case DashboardActionTypes.GET_ALL_WHITE_PAPER_DATA_SUCCESS:
      return {
        ...state,
        allWhitePaperData: action.payload?.getAllWhitePaperThumbData,
      };
    case DashboardActionTypes.GET_ALL_WHITE_PAPER_DATA_LOADING_START:
      return {
        ...state,
        isAllWhitePaperDataLoading: true,
      };
    case DashboardActionTypes.GET_ALL_WHITE_PAPER_DATA_LOADING_STOP:
      return {
        ...state,
        isAllWhitePaperDataLoading: false,
      };

    // Settings
    case DashboardActionTypes.TRAINER_PROFILE_TEMP_DETAILS_SUCCESS:
      return {
        ...state,
        trainerProfileTempDetails: action.payload,
      };
    case DashboardActionTypes.TRAINER_PROFILE_TEMP_DETAILS_LOADING_START:
      return {
        ...state,
        isTrainerProfileTempDetailsLoading: true,
      };
    case DashboardActionTypes.TRAINER_PROFILE_TEMP_DETAILS_LOADING_STOP:
      return {
        ...state,
        isTrainerProfileTempDetailsLoading: false,
      };
    // On boarding
    case DashboardActionTypes.GET_TRAINER_ONBOARDING_CHECKS_SUCCESS:
      return {
        ...state,
        onboardingChecks: action.payload,
      };
    case DashboardActionTypes.GET_TRAINER_ONBOARDING_CHECKS_LOADING_START:
      return {
        ...state,
        isOnBoardingChecksLoading: true,
      };
    case DashboardActionTypes.GET_TRAINER_ONBOARDING_CHECKS_LOADING_STOP:
      return {
        ...state,
        isOnBoardingChecksLoading: false,
      };
    case DashboardActionTypes.UPDATE_TRAINER_ONBOARDING_CHECK_SUCCESS:
      return {
        ...state,
        updateOnBoardingCheck: action.payload,
      };
    case DashboardActionTypes.UPDATE_TRAINER_ONBOARDING_CHECK_LOADING_START:
      return {
        ...state,
        isUpdateOnBoardingCheckLoading: true,
      };
    case DashboardActionTypes.UPDATE_TRAINER_ONBOARDING_CHECK_LOADING_STOP:
      return {
        ...state,
        isUpdateOnBoardingCheckLoading: false,
      };

    case DashboardActionTypes.GET_ACTIVE_CLIENTS_LAST_DAYS_SUCCESS:
      return {
        ...state,
        activeClientsLastDays: action.payload,
      };
    case DashboardActionTypes.GET_ACTIVE_CLIENTS_LAST_DAYS_LOADING_START:
      return {
        ...state,
        isActiveClientsLastDaysLoading: true,
      };
    case DashboardActionTypes.GET_ACTIVE_CLIENTS_LAST_DAYS_LOADING_STOP:
      return {
        ...state,
        isActiveClientsLastDaysLoading: false,
      };

    case DashboardActionTypes.GET_INITIAL_DISCUSSION_CHART_DATA_SUCCESS:
      return {
        ...state,
        initialDiscussionChartDataCurrentMonth: action.payload,
      };
    case DashboardActionTypes.GET_INITIAL_DISCUSSION_CHART_DATA_PREVIOUS_MONTH_SUCCESS:
      return {
        ...state,
        initialDiscussionChartDataPreviousMonth: action.payload,
      };
    case DashboardActionTypes.GET_INITIAL_DISCUSSION_CHART_DATA_LOADING_START:
      return {
        ...state,
        isInitialDiscussionChartDataLoading: true,
      };
    case DashboardActionTypes.GET_INITIAL_DISCUSSION_CHART_DATA_LOADING_STOP:
      return {
        ...state,
        isInitialDiscussionChartDataLoading: false,
      };
    // --------------------
    case DashboardActionTypes.GET_ACTIVE_CLIENTS_CURRENT_MONTH_CHART_DATA_SUCCESS:
      return {
        ...state,
        activeClientsCurrentMonthChartData: action.payload,
      };
    case DashboardActionTypes.GET_ACTIVE_CLIENTS_PREVIOUS_MONTH_CHART_DATA_SUCCESS:
      return {
        ...state,
        activeClientsPreviousMonthChartData: action.payload,
      };
    case DashboardActionTypes.GET_ACTIVE_CLIENTS_MONTH_CHART_DATA_LOADING_START:
      return {
        ...state,
        isActiveClientsChartDataLoading: true,
      };
    case DashboardActionTypes.GET_ACTIVE_CLIENTS_MONTH_CHART_DATA_LOADING_STOP:
      return {
        ...state,
        isActiveClientsChartDataLoading: false,
      };

    // ------------------------------------------------

    case DashboardActionTypes.GET_SALES_CURRENT_MONTH_CHART_DATA_SUCCESS:
      return {
        ...state,
        salesCurrentMonthChartData: action.payload,
      };
    case DashboardActionTypes.GET_SALES_PREVIOUS_MONTH_CHART_DATA_SUCCESS:
      return {
        ...state,
        salesPreviousMonthChartData: action.payload,
      };
    case DashboardActionTypes.GET_SALES_MONTH_CHART_DATA_LOADING_START:
      return {
        ...state,
        isSalesChartDataLoading: true,
      };
    case DashboardActionTypes.GET_SALES_MONTH_CHART_DATA_LOADING_STOP:
      return {
        ...state,
        isSalesChartDataLoading: false,
      };

    case DashboardActionTypes.GET_USER_TRANSACTION_SUCCESS:
      // console.log(
      //   "action?.payload?.getUserOrderTransactionData",
      //   action?.payload?.getUserOrderTransactionData
      // );

      // const firstInstallment =
      //   action?.payload?.getUserOrderTransactionData.find(
      //     (item) => item?.installment_num === 1 && item?.status === "paid"
      //   );

      // console.log("firstInstallment", firstInstallment);

      // const secondInstallment =
      //   action?.payload?.getUserOrderTransactionData.find(
      //     (item) => item?.installment_num === 2 && item?.status === "paid"
      //   );

      // console.log("secondInstallment", secondInstallment);

      return {
        ...state,
        userTransaction: action.payload,
      };
    case DashboardActionTypes.GET_USER_TRANSACTION_LOADING_START:
      return {
        ...state,
        isUserTransactionLoading: true,
      };
    case DashboardActionTypes.GET_USER_TRANSACTION_LOADING_STOP:
      return {
        ...state,
        isUserTransactionLoading: false,
      };

    case DashboardActionTypes.GET_HABBIT_SUCCESS:
      return {
        ...state,
        habbit: action.payload,
      };
    case DashboardActionTypes.GET_HABBIT_LOADING_START:
      return {
        ...state,
        isHabbitLoading: true,
      };
    case DashboardActionTypes.GET_HABBIT_LOADING_STOP:
      return {
        ...state,
        isHabbitLoading: false,
      };

    case DashboardActionTypes.GET_JOURNAL_SUCCESS:
      return {
        ...state,
        journal: action.payload,
      };
    case DashboardActionTypes.GET_JOURNAL_LOADING_START:
      return {
        ...state,
        isJournalLoading: true,
      };
    case DashboardActionTypes.GET_JOURNAL_LOADING_STOP:
      return {
        ...state,
        isJournalLoading: false,
      };

    case DashboardActionTypes.GET_EXPERTS_CALLS_SUCCESS:
      const currentDate = new Date().toISOString().split("T")[0];
      return {
        ...state,
        expertsCalls: action.payload,
        nextExpertCall: action.payload?.data?.find(
          (event) => event?.Datum > currentDate
        ),
      };

    case DashboardActionTypes.GET_EXPERTS_CALLS_LOADING_START:
      return {
        ...state,
        isExpertsLoading: true,
      };
    case DashboardActionTypes.GET_EXPERTS_CALLS_LOADING_STOP:
      return {
        ...state,
        isExpertsLoading: false,
      };

    case DashboardActionTypes.GET_USER_WEIGHT_DATA_SUCCESS:
      return {
        ...state,
        userWeightData: action.payload,
      };

    case DashboardActionTypes.GET_USER_WEIGHT_DATA_LOADING_START:
      return {
        ...state,
        isUserWeightDataLoading: true,
      };
    case DashboardActionTypes.GET_USER_WEIGHT_DATA_LOADING_STOP:
      return {
        ...state,
        isUserWeightDataLoading: false,
      };

    case DashboardActionTypes.GET_TRAINER_INVOICES_SUCCESS:
      return {
        ...state,
        trainerInvoices: action.payload,
      };
    case DashboardActionTypes.GET_TRAINER_INVOICES_LOADING_START:
      return {
        ...state,
        isTrainerInvoicesLoading: true,
      };
    case DashboardActionTypes.GET_TRAINER_INVOICES_LOADING_STOP:
      return {
        ...state,
        isTrainerInvoicesLoading: false,
      };

    case DashboardActionTypes.GET_TRAINER_CREDITS_SUCCESS:
      return {
        ...state,
        trainerCredits: action.payload,
      };
    case DashboardActionTypes.GET_TRAINER_CREDITS_FAILURE:
      console.log(
        "DashboardActionTypes.GET_TRAINER_CREDITS_FAILURE-----------",
        action.payload
      );
      return {
        ...state,
        trainerCredits: {
          status: "error",
          data: [],
        },
      };
    case DashboardActionTypes.GET_TRAINER_CREDITS_LOADING_START:
      return {
        ...state,
        isTrainerCreditsLoading: true,
      };
    case DashboardActionTypes.GET_TRAINER_CREDITS_LOADING_STOP:
      return {
        ...state,
        isTrainerCreditsLoading: false,
      };

    case DashboardActionTypes.SEND_EMAIL_SUCCESS:
      return {
        ...state,
        sendEmail: action.payload,
      };
    case DashboardActionTypes.SEND_EMAIL_FAILURE:
      return {
        ...state,
        sendEmail: action.payload,
      };
    case DashboardActionTypes.SEND_EMAIL_NULL:
      return {
        ...state,
        sendEmail: null,
      };
    case DashboardActionTypes.SEND_EMAIL_LOADING_START:
      return {
        ...state,
        isEmailSending: true,
      };
    case DashboardActionTypes.SEND_EMAIL_LOADING_STOP:
      return {
        ...state,
        isEmailSending: false,
      };
    case DashboardActionTypes.GET_TRAINER_NEWS_SUCCESS:
      return {
        ...state,
        trainerNews: action.payload,
      };
    case DashboardActionTypes.GET_TRAINER_NEWS_LOADING_START:
      return {
        ...state,
        isTrainerNewsLoading: true,
      };
    case DashboardActionTypes.GET_TRAINER_NEWS_LOADING_STOP:
      return {
        ...state,
        isTrainerNewsLoading: false,
      };

    case DashboardActionTypes.UPDATE_GOAL_WEIGHT_SUCCESS:
      return {
        ...state,
        updateGoalWeight: action.payload,
      };
    case DashboardActionTypes.UPDATE_GOAL_WEIGHT_LOADING_START:
      return {
        ...state,
        isGoalWeightUpdating: true,
      };
    case DashboardActionTypes.UPDATE_GOAL_WEIGHT_LOADING_STOP:
      return {
        ...state,
        isGoalWeightUpdating: false,
      };

    case DashboardActionTypes.CHECK_STRIPE_CONNECTED_ACCOUNT_STATUS_SUCCESS:
      return {
        ...state,
        checkStripeConnectedAccountStatus: action.payload,
      };
    case DashboardActionTypes.CHECK_STRIPE_CONNECTED_ACCOUNT_STATUS_LOADING_START:
      return {
        ...state,
        isCheckStripeConnectedAccountStatusLoading: true,
      };
    case DashboardActionTypes.CHECK_STRIPE_CONNECTED_ACCOUNT_STATUS_LOADING_STOP:
      return {
        ...state,
        isCheckStripeConnectedAccountStatusLoading: false,
      };
    default:
      return state;
  }
};

/*************************************************/

export default dashboardReducer;

/*************************************************/
