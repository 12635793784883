import React, { useState, useEffect } from "react";
import moment from "moment";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PhoneInput from "react-phone-number-input";
import { TimePicker } from "@material-ui/pickers";
import { useIntl } from "react-intl";
import TranslationMessage from "configs/translations/TranslationMessage";
import Topbar from "components/topbar/Topbar";
import * as S from "./registration.styles";
import * as Data from "./registration.utils";
import * as Methods from "./registration.functions";

// Redux
import * as AuthActions from "redux/auth/auth.actions";

// Images
import Male from "assets/images/male.svg";
import Female from "assets/images/female.svg";
import Slimming from "assets/images/slimming.svg";
import HealthDiet from "assets/images/healthDiet.svg";
import MuscleBuilding from "assets/images/muscleBuilding.svg";
import MuscleBuildingBulk from "assets/images/muscleBuildingBulk.svg";

// Components
import Input from "components/formComponents/input/Input";
import IncrementInput from "components/formComponents/incrementInput/IncrementInput";
import CollapsibleAccordion from "components/collapsibleAccordion/CollapsibleAccordion";
import Button from "components/formComponents/button/Button";
import Modal from "components/modal/Modal";
import Dropdown from "components/formComponents/dropdown/Dropdown";
import Switch from "components/formComponents/switch/Switch";
import Checkbox from "components/formComponents/checkbox/Checkbox";
import RadioButton from "components/formComponents/radioButton/RadioButton";
import SquareCheckbox from "components/formComponents/checkbox/SquareCheckbox";
import TimePickerCustom from "components/formComponents/timePicker/TimePicker";
import ErrorMessage from "components/formComponents/errorMessage/ErrorMessage";
import DietCategories from "./DietCategories";
import EvaluationOfOverallConditionForm from "pages/EvaluationOfOverallConditionForm/EvaluationOfOverallConditionForm";

const Registration = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const intl = useIntl();
  const registrationData = useSelector((state) => state.auth.registrationData);
  const trainerProfileData = useSelector((state) => state.auth.trainerData);
  const error = useSelector((state) => state.auth.error);

  const { id } = useParams();
  const [isFirstTime, setIsFirstTime] = useState(true);

  const [addDailyRoutineModal, setAddDailyRoutineModal] = useState(false);
  const [updateDailyRoutineModal, setUpdateDailyRoutineModal] = useState(false);
  const [selectedRoutine, setSelectedRoutine] = useState(null);

  const [training, setTraining] = useState(registrationData.isTraining);
  const [routineName, setRoutineName] = useState("");
  const [routineGetup, setRoutineGetup] = useState({
    dayroutineID: 1,
    time: "07:00",
  });
  const [routineLunchTime, setRoutineLunchTime] = useState({
    dayroutineID: 2,
    time: "12:00",
  });
  const [routineWorkoutTime, setRoutineWorkoutTime] = useState({
    dayroutineID: 3,
    time: "17:00",
  });
  const [routineBedTime, setRoutineBedTime] = useState({
    dayroutineID: 4,
    time: "22:00",
  });

  const [updateRoutineGetup, setUpdateRoutineGetup] = useState({
    dayroutineID: 1,
    id: "input.label.getup",
    time: "",
  });
  const [updateRoutineLunchTime, setUpdateRoutineLunchTime] = useState({
    dayroutineID: 2,
    id: "input.label.lunchTime",
    time: "",
  });
  const [updateRoutineWorkoutTime, setUpdateRoutineWorkoutTime] = useState({
    dayroutineID: 3,
    id: "input.label.workoutTime",
    time: "",
  });
  const [updateRoutineBedTime, setUpdateRoutineBedTime] = useState({
    dayroutineID: 4,
    id: "input.label.bedTime",
    time: "",
  });
  const [selectedDate, handleDateChange] = useState(new Date());

  const [gender, setGender] = useState(registrationData.sex);
  const [goal, setGoal] = useState(registrationData.aimID);
  const [birthDate, setBirthDate] = useState(""); //registrationData.birthday
  const [height, setHeight] = useState(0); //registrationData.height
  const [weight, setWeight] = useState(0); //registrationData.weight
  const [BodyDetailsError, SetBodyDetailsError] = useState(false);

  const [email, setEmail] = useState(registrationData.email);
  const [firstName, setFirstName] = useState(registrationData.prename);
  const [lastName, setLastName] = useState(registrationData.surname);
  const [mobile, setMobile] = useState(registrationData.mobile);
  const [trainingDays, setTrainingDays] = useState(
    registrationData.trainingdays
  );
  const [totalTrainingDays, setTotalTrainingDays] = useState(
    registrationData.totalTrainingDays
  );
  const [dailyRoutineData, setDailyRoutineData] = useState(
    registrationData.dailyRoutineData
  );
  const [routines, setRoutines] = useState(registrationData.routines);

  const [sleepValue, setSleepValue] = useState(registrationData.sleepValue);
  const [lyingDownValue, setLyingDownValue] = useState(
    registrationData.lyingDownValue
  );
  const [standingValue, setStandingValue] = useState(
    registrationData.standingValue
  );
  const [sittingValue, setSittingValue] = useState(
    registrationData.sittingValue
  );
  const [walkingValue, setWalkingValue] = useState(
    registrationData.walkingValue
  );
  const [exercisingValue, setExercisingValue] = useState(
    registrationData.exercisingValue
  );

  const [eatingHabit1, setEatingHabit1] = useState(
    // registrationData.eatingHabit1
    Data.eatingHabits1Data[0]
  );
  const [eatingHabit2, setEatingHabit2] = useState(
    // registrationData.eatingHabit2
    Data.eatingHabits2Data[0]
  );
  const [eatingHabit3, setEatingHabit3] = useState(
    // registrationData.eatingHabit3
    Data.eatingHabits3Data[0]
  );
  const [eatingHabit4, setEatingHabit4] = useState(
    // registrationData.eatingHabit4
    Data.eatingHabits4Data[0]
  );
  const [eatingHabit5, setEatingHabit5] = useState(
    // registrationData.eatingHabit5
    Data.eatingHabits5Data[0]
  );

  const [eatingHabit2Vegan, setEatingHabit2Vegan] = useState(
    Data.eatingHabits2veganData[0]
  );
  const [eatingHabit3Vegan, setEatingHabit3Vegan] = useState(
    Data.eatingHabits3veganData[0]
  );
  const [eatingHabit4Vegan, setEatingHabit4Vegan] = useState(
    Data.eatingHabits4veganData[0]
  );
  const [eatingHabit5Vegan, setEatingHabit5Vegan] = useState(
    Data.eatingHabits5veganData[0]
  );

  const [eatingHabit2Vegetarian, setEatingHabit2Vegetarian] = useState(
    Data.eatingHabits2vegetarianData[0]
  );
  const [eatingHabit3Vegetarian, setEatingHabit3Vegetarian] = useState(
    Data.eatingHabits3vegetarianData[0]
  );
  const [eatingHabit4Vegetarian, setEatingHabit4Vegetarian] = useState(
    Data.eatingHabits4vegetarianData[0]
  );
  const [eatingHabit5Vegetarian, setEatingHabit5Vegetarian] = useState(
    Data.eatingHabits5vegetarianData[0]
  );

  const [isChecked, setIsChecked] = useState({
    firstCheck: false,
    secondCheck: false,
  });

  const totalMomentHrs =
    (sleepValue +
      lyingDownValue +
      standingValue +
      sittingValue +
      walkingValue +
      exercisingValue) /
    60;

  const [prevLink, setPrevLink] = useState("/whatAwaitsYou");
  const [nextLink, setNextLink] = useState("/registration/goal");
  const [isWeightFloat, setIsWeightFloat] = useState(false);
  const [isHeightFloat, setIsHeightFloat] = useState(false);

  const [isPhoneValid, setIsPhoneValid] = useState(false);

  const [registrationLinks, setRegistrationLinks] = useState([
    ...Data.RegistrationLinks,
  ]);
  const [selectedFoodCategory, setSelectedFoodCategory] = useState({
    id: 1,
    title: "Fleischhaltige Ernährung",
  });

  const [selectedNutrients, setSelectedNutrients] = useState({
    carbohydrates: 0,
    protein: 0,
    fat: 0,
  });

  const [nutrients, setNutrients] = useState([]);
  const [IsBrowserReloadModal, SetIsBrowserReloadModal] = useState(false);

  useEffect(() => {
    const savedCategory = JSON.parse(localStorage.getItem("foodCategory"));

    const allNutrients = {
      eatingHabit1,
      eatingHabit2,
      eatingHabit3,
      eatingHabit4,
      eatingHabit5,
      eatingHabit2Vegan,
      eatingHabit3Vegan,
      eatingHabit4Vegan,
      eatingHabit5Vegan,
      eatingHabit2Vegetarian,
      eatingHabit3Vegetarian,
      eatingHabit4Vegetarian,
      eatingHabit5Vegetarian,
    };

    const defaultNutrients = Methods.setDefaultValuesOfCategories(
      savedCategory?.id,
      allNutrients
    );

    setNutrients(defaultNutrients);
  }, [selectedFoodCategory]);

  useEffect(() => {
    const savedCategory = JSON.parse(localStorage.getItem("foodCategory"));
    if (!!savedCategory) {
      manageRoutesBasedOnSelectedCategory(savedCategory, true);
      setSelectedFoodCategory(savedCategory);
    }
  }, []);

  useEffect(() => {
    // const allNutrients = {
    //   eatingHabit1,
    //   eatingHabit2,
    //   eatingHabit3,
    //   eatingHabit4,
    //   eatingHabit5,
    //   eatingHabit2Vegan,
    //   eatingHabit3Vegan,
    //   eatingHabit4Vegan,
    //   eatingHabit5Vegan,
    //   eatingHabit2Vegetarian,
    //   eatingHabit3Vegetarian,
    //   eatingHabit4Vegetarian,
    //   eatingHabit5Vegetarian,
    // };
    //  const addUps =  Methods.addUpNutrientsWithDefaultValues(
    //     id,
    //     selectedNutrients,
    //     allNutrients
    //   );
    //   console.log("addUps", addUps)
    //   setSelectedNutrients(addUps);

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [history.location.pathname]);

  const handleBeforeUnload = (event) => {
    event.preventDefault();
    event.returnValue = "Custom message";
    // SetIsBrowserReloadModal(true);
    console.log("handleBeforeUnload");
  };

  useEffect(() => {
    setDailyRoutineData(registrationData.dailyRoutineData);
  }, [registrationData.dailyRoutineData]);

  const handleLinksPosition = (registrationLinks) => {
    let newCurrentLinkPosition = registrationLinks.indexOf(
      `/registration/${id}`
    );

    let newPrevLink = registrationLinks[newCurrentLinkPosition - 1];
    let newNextLink = registrationLinks[newCurrentLinkPosition + 1];
    setPrevLink(newPrevLink);
    setNextLink(newNextLink);
  };

  const activityLevelValues = [
    {
      id: "registration.activityLevel.sleep",
      options: Data.hourValues,
      value: sleepValue / 60 + " h",
    },
    {
      id: "registration.activityLevel.lyingDown",
      options: Data.hourValues,
      value: lyingDownValue / 60 + " h",
    },
    {
      id: "registration.activityLevel.standing",
      options: Data.hourValues,
      value: standingValue / 60 + " h",
    },
    {
      id: "registration.activityLevel.siting",
      options: Data.hourValues,
      value: sittingValue / 60 + " h",
    },
    {
      id: "registration.activityLevel.walking",
      options: Data.hourValues,
      value: walkingValue / 60 + " h",
    },
    {
      id: "registration.activityLevel.exercising",
      options: Data.hourValues,
      value: exercisingValue / 60 + " h",
    },
  ];

  let formData = {
    sex: gender,
    aimID: goal,
    height: height,
    weight: weight,
    nutritiontypeID: 2,
    birthday: birthDate,
    email: email,
    prename: firstName,
    surname: lastName,
    mobile: mobile,
    grant_type: "password",
    isTraining: training,
    trainingdays: trainingDays,
    totalTrainingDays: totalTrainingDays,
    dailyRoutineData: dailyRoutineData,
    routines: routines,
    movementtypes: [
      { minutes: sleepValue, movementtypeID: 1 },
      { minutes: lyingDownValue, movementtypeID: 2 },
      { minutes: standingValue, movementtypeID: 3 },
      { minutes: sittingValue, movementtypeID: 4 },
      { minutes: walkingValue, movementtypeID: 5 },
      { minutes: exercisingValue, movementtypeID: 6 },
    ],
    sleepValue: sleepValue,
    lyingDownValue: lyingDownValue,
    standingValue: standingValue,
    sittingValue: sittingValue,
    walkingValue: walkingValue,
    exercisingValue: exercisingValue,
    eatingHabit1: eatingHabit1?.eatingHabitData?.heading,
    eatingHabit2: eatingHabit2?.eatingHabitData?.heading,
    eatingHabit3: eatingHabit3?.eatingHabitData?.heading,
    eatingHabit4: eatingHabit4?.eatingHabitData?.heading,
    eatingHabit5: eatingHabit5?.eatingHabitData?.heading,
  };

  const registrationSuccessRedirection = () =>
    history.push("/nutrition/creation");

  const [IsCreating, setIsCreating] = useState(false);

  const updateRegistrationData = () => {
    console.log("updateRegistrationData");
    if (id === "anamnese_evaluation") {
      const anamnesePayload = JSON.parse(localStorage.getItem("Anamnese"));

      const isValid = Object?.entries(anamnesePayload)
        ?.map(([key, value]) => value)
        ?.every((item) => item !== null);

      if (isValid) {
        setPrevLink("/registration/anamnese_evaluation");
        history.push("/registration/bodyDetails");
      }
      return;
    }

    if (!isPhoneValid) return;
    if (id === "bodyDetails") {
      setIsFirstTime(false);
      console.log("393 --> ", "setIsFirstTime");

      if (!birthDate || !weight || +weight < 40 || !height || +height < 120) {
        SetBodyDetailsError(true);
        return;
      }
    }
    dispatch(AuthActions.setRegistrationData(formData));
    if (id === "personalData") {
      if (!isChecked.firstCheck || !isChecked.secondCheck) {
        return;
      }

      setIsCreating(true);
      getNutiritionTypeId(goal, selectedNutrients);

      const values = formData;
      values.type = "web";
      values.trainerID = trainerProfileData.trainerID;
      if (!training) values.trainingdays = totalTrainingDays;
      delete values.totalTrainingDays;
      delete values.dailyRoutineData;
      delete values.sleepValue;
      delete values.lyingDownValue;
      delete values.standingValue;
      delete values.sittingValue;
      delete values.walkingValue;
      delete values.exercisingValue;
      delete values.eatingHabit1;
      delete values.eatingHabit2;
      dispatch(
        AuthActions.registration1Request({
          formValues: values,
          func: (response) => {
            setIsCreating(false);
            registrationSuccessRedirection();
          },
        })
      );
    }
  };

  const handleNextRedirect = () => {
    if (id === "bodyDetails") {
      console.log(
        "handleNextRedirect",
        parseInt(weight),
        parseInt(height),
        birthDate
      );
      if (parseInt(weight) < 40 || parseInt(height) < 120 || birthDate === "") {
        console.log("434 --> ", "handleNextRedirect");
        // handleLinksPosition(Data.RegistrationLinks);
        return false;
      }

      if (
        parseInt(weight) >= 40 ||
        parseInt(height) >= 120 ||
        birthDate !== ""
      ) {
        return true;
      }
    }
    if (id === "dailyRoutine") if (dailyRoutineData.length === 0) return false;
    if (id === "activityLevel") if (totalMomentHrs !== 24) return false;
    if (id === "trainingFixed") {
      if (training) if (trainingDays.length === 0) return false;
      if (!training) if (parseInt(totalTrainingDays) === 0) return false;
    }
    if (id === "personalData") return false;
    if (id === "anamnese_evaluation") return false;

    return true;
  };

  const handleAddRoutine = () => {
    const uniqueId = "id" + new Date().getTime();
    const newRoutine = {
      id: uniqueId,
      heading: routineName,
      data: [
        routineGetup["time"],
        routineLunchTime["time"],
        routineWorkoutTime["time"],
        routineBedTime["time"],
      ],
    };
    setDailyRoutineData([...dailyRoutineData, newRoutine]);
    setRoutines({
      ...routines,
      [newRoutine.heading]: [
        { dayroutineID: 1, time: newRoutine.data[0] },
        { dayroutineID: 2, time: newRoutine.data[1] },
        { dayroutineID: 3, time: newRoutine.data[2] },
        { dayroutineID: 4, time: newRoutine.data[3] },
      ],
    });
    setRoutineName("");
    setRoutineGetup({ dayroutineID: 1, time: "07:00" });
    setRoutineLunchTime({ dayroutineID: 2, time: "12:00" });
    setRoutineWorkoutTime({ dayroutineID: 3, time: "17:00" });
    setRoutineBedTime({ dayroutineID: 4, time: "22:00" });
    setAddDailyRoutineModal(false);
  };

  const handleTimeChange = (time, TimeId) => {
    const timeValue = moment(time).format("HH:mm");
    console.log("time", time)
    console.log("timeValue", timeValue)
    switch (TimeId) {
      case "input.label.getup":
        setRoutineGetup({ dayroutineID: 1, time: timeValue });
        break;
      case "input.label.lunchTime":
        setRoutineLunchTime({ dayroutineID: 2, time: timeValue });
        break;
      case "input.label.workoutTime":
        setRoutineWorkoutTime({ dayroutineID: 3, time: timeValue });
        break;
      case "input.label.bedTime":
        setRoutineBedTime({ dayroutineID: 4, time: timeValue });
        break;
      default:
        return dailyRoutineData;
    }
  };

  const updateRoutine = (accordion) => {
    const currentRoutine = dailyRoutineData.find(
      (item) => item?.id === accordion?.id
    );

    setUpdateRoutineGetup({
      dayroutineID: 1,
      id: "input.label.getup",
      timeString: currentRoutine.data[0],
      time: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate(),
        currentRoutine?.data[0]?.split(":")[0],
        currentRoutine?.data[0]?.split(":")[1],
        0
      ),
    });
    setUpdateRoutineLunchTime({
      dayroutineID: 2,
      id: "input.label.lunchTime",
      timeString: currentRoutine.data[1],
      time: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate(),
        currentRoutine?.data[1]?.split(":")[0],
        currentRoutine?.data[1]?.split(":")[1],
        0
      ),
    });
    setUpdateRoutineWorkoutTime({
      dayroutineID: 3,
      id: "input.label.workoutTime",
      timeString: currentRoutine.data[2],
      time: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate(),
        currentRoutine?.data[2]?.split(":")[0],
        currentRoutine?.data[2]?.split(":")[1],
        0
      ),
    });
    setUpdateRoutineBedTime({
      dayroutineID: 4,
      id: "input.label.bedTime",
      timeString: currentRoutine.data[3],
      time: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate(),
        currentRoutine?.data[3]?.split(":")[0],
        currentRoutine?.data[3]?.split(":")[1],
        0
      ),
    });

    const newData = {
      id: currentRoutine.id,
      heading: currentRoutine.heading,
      data: [
        {
          id: "input.label.getup",
          time: currentRoutine.data[0],
        },
        { id: "input.label.lunchTime", time: currentRoutine.data[1] },
        { id: "input.label.workoutTime", time: currentRoutine.data[2] },
        { id: "input.label.bedTime", time: currentRoutine.data[3] },
      ],
    };

    setSelectedRoutine({ ...newData });
    setUpdateDailyRoutineModal(true);
  };

  const handleUpdateTimeChange = (time, TimeId) => {
    const timeValue = moment(time).format("HH:mm");
    let routine = { ...selectedRoutine };
    setUpdateDailyRoutineModal(true);
    const obj = {
      dayroutineID: 1,
      id: "input.label.getup",
      timeString: timeValue,
      time: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate(),
        timeValue?.split(":")[0],
        timeValue?.split(":")[1],
        0
      ),
    };
    switch (TimeId) {
      case "input.label.getup":
        setUpdateRoutineGetup(obj);
        break;
      case "input.label.lunchTime":
        obj.id = "input.label.lunchTime";
        setUpdateRoutineLunchTime(obj);
        break;
      case "input.label.workoutTime":
        obj.id = "input.label.workoutTime";
        setUpdateRoutineWorkoutTime(obj);
        break;
      case "input.label.bedTime":
        obj.id = "input.label.bedTime";
        setUpdateRoutineBedTime(obj);
        break;
      default:
        return routine;
    }
  };

  const updateObjectsValueInArray = (array, valueObj) => {
    if (!valueObj?.timeString) return;

    return array
      ?.map((item) => {
        if (item?.id === valueObj?.id) {
          item.time = valueObj?.timeString;
        }
        return item;
      })
      .map((item) => item?.time);
  };

  const handleUpdateRoutine = (routine) => {
    const excludedRoutines = dailyRoutineData.filter(
      (item) => item?.id !== routine?.id
    );

    const updatedRoutines = [...excludedRoutines];

    let updateSingleRoutineAndGetListOfRoutines = updateObjectsValueInArray(
      routine?.data,
      updateRoutineGetup
    );
    updateSingleRoutineAndGetListOfRoutines = updateObjectsValueInArray(
      routine?.data,
      updateRoutineLunchTime
    );
    updateSingleRoutineAndGetListOfRoutines = updateObjectsValueInArray(
      routine?.data,
      updateRoutineWorkoutTime
    );
    updateSingleRoutineAndGetListOfRoutines = updateObjectsValueInArray(
      routine?.data,
      updateRoutineBedTime
    );

    const updatedRoutine = {
      id: routine?.id,
      heading: routine?.heading,
      data: updateSingleRoutineAndGetListOfRoutines,
    };

    updatedRoutines.push({ ...updatedRoutine });

    dispatch(AuthActions.setDailyRoutineData({ updatedRoutines }));
    setUpdateDailyRoutineModal(false);
  };

  const handleActivityLevelValueChange = (e, activityLevelId) => {
    const totalMinutes = e.target.value.split(" ")[0] * 60;
    switch (activityLevelId) {
      case "registration.activityLevel.sleep":
        setSleepValue(totalMinutes);
        break;
      case "registration.activityLevel.lyingDown":
        setLyingDownValue(totalMinutes);
        break;
      case "registration.activityLevel.standing":
        setStandingValue(totalMinutes);
        break;
      case "registration.activityLevel.siting":
        setSittingValue(totalMinutes);
        break;
      case "registration.activityLevel.walking":
        setWalkingValue(totalMinutes);
        break;
      case "registration.activityLevel.exercising":
        setExercisingValue(totalMinutes);
        break;
      default:
        return totalMinutes;
    }
  };

  const handleTrainingFixedCheckbox = (checked, value) => {
    let arrayValues = trainingDays.slice();
    if (checked) {
      arrayValues.push(value);
      setTrainingDays(() => arrayValues);
    } else {
      const newArrayValue = arrayValues.filter(
        (dayValue) => dayValue !== value
      );
      setTrainingDays(newArrayValue);
    }
  };

  const handleRadioChange = (e, radioName, eatingHabitData) => {
    // addUpNutrients(selectedNutrients, eatingHabitData.nutrients);
    setNutrients([...nutrients, eatingHabitData]);

    if (radioName === "eatingHabit1") setEatingHabit1(eatingHabitData);

    if (radioName === "eatingHabit2") setEatingHabit2(eatingHabitData);
    if (radioName === "eatingHabit3") setEatingHabit3(eatingHabitData);
    if (radioName === "eatingHabit4") setEatingHabit4(eatingHabitData);
    if (radioName === "eatingHabit5") setEatingHabit5(eatingHabitData);

    if (radioName === "eatingHabit2-vegan")
      setEatingHabit2Vegan(eatingHabitData);
    if (radioName === "eatingHabit3-vegan")
      setEatingHabit3Vegan(eatingHabitData);
    if (radioName === "eatingHabit4-vegan")
      setEatingHabit4Vegan(eatingHabitData);
    if (radioName === "eatingHabit5-vegan")
      setEatingHabit5Vegan(eatingHabitData);

    if (radioName === "eatingHabit2-vegetarian")
      setEatingHabit2Vegetarian(eatingHabitData);
    if (radioName === "eatingHabit3-vegetarian")
      setEatingHabit3Vegetarian(eatingHabitData);
    if (radioName === "eatingHabit4-vegetarian")
      setEatingHabit4Vegetarian(eatingHabitData);
    if (radioName === "eatingHabit5-vegetarian")
      setEatingHabit5Vegetarian(eatingHabitData);
  };

  const eatingHabits = [
    {
      id: "eatingHabit1",
      data: Data.eatingHabits1Data,
      state: eatingHabit1?.eatingHabitData?.heading,
    },

    {
      id: "eatingHabit2",
      data: Data.eatingHabits2Data,
      state: eatingHabit2?.eatingHabitData?.heading,
    },
    {
      id: "eatingHabit3",
      data: Data.eatingHabits3Data,
      state: eatingHabit3?.eatingHabitData?.heading,
    },
    {
      id: "eatingHabit4",
      data: Data.eatingHabits4Data,
      state: eatingHabit4?.eatingHabitData?.heading,
    },
    {
      id: "eatingHabit5",
      data: Data.eatingHabits5Data,
      state: eatingHabit5?.eatingHabitData?.heading,
    },

    {
      id: "eatingHabit2-vegan",
      data: Data.eatingHabits2veganData,
      state: eatingHabit2Vegan?.eatingHabitData?.heading,
    },
    {
      id: "eatingHabit3-vegan",
      data: Data.eatingHabits3veganData,
      state: eatingHabit3Vegan?.eatingHabitData?.heading,
    },
    {
      id: "eatingHabit4-vegan",
      data: Data.eatingHabits4veganData,
      state: eatingHabit4Vegan?.eatingHabitData?.heading,
    },
    {
      id: "eatingHabit5-vegan",
      data: Data.eatingHabits5veganData,
      state: eatingHabit5Vegan?.eatingHabitData?.heading,
    },

    {
      id: "eatingHabit2-vegetarian",
      data: Data.eatingHabits2vegetarianData,
      state: eatingHabit2Vegetarian?.eatingHabitData?.heading,
    },
    {
      id: "eatingHabit3-vegetarian",
      data: Data.eatingHabits3vegetarianData,
      state: eatingHabit3Vegetarian?.eatingHabitData?.heading,
    },
    {
      id: "eatingHabit4-vegetarian",
      data: Data.eatingHabits4vegetarianData,
      state: eatingHabit4Vegetarian?.eatingHabitData?.heading,
    },
    {
      id: "eatingHabit5-vegetarian",
      data: Data.eatingHabits5vegetarianData,
      state: eatingHabit5Vegetarian?.eatingHabitData?.heading,
    },
  ];

  const EatingHabitsContent = (allData, radioName, state) => {
    return (
      <>
        {allData.map((data) => {
          return (
            <S.EatingHabitsCardContainer key={data.eatingHabitData.id}>
              <S.EatingHabitsImageContainer
                src={data.eatingHabitData.image}
                alt={data.eatingHabitData.id}
              />
              <S.EatingHabitsCardInnerContainer>
                <S.EatingHabitsInnerCardHeading>
                  <TranslationMessage id={data.eatingHabitData.heading} />
                </S.EatingHabitsInnerCardHeading>
                <S.EatingHabitsRadioContainer>
                  <RadioButton
                    checked={data.eatingHabitData.heading === state}
                    name={radioName}
                    id={data.eatingHabitData.heading}
                    onClick={(e) => handleRadioChange(e, radioName, data)}
                  />
                </S.EatingHabitsRadioContainer>
              </S.EatingHabitsCardInnerContainer>
            </S.EatingHabitsCardContainer>
          );
        })}
      </>
    );
  };

  const AccordionContent = () => {
    let AccordionData = [];
    let spanStyle = { display: "flex", float: "right", color: "#A5B5AF" };
    dailyRoutineData.map((data) => {
      const contentContainer = () => {
        return (
          <div key={data.id}>
            {Data.AccordionContentData.map((label, index) => {
              return (
                <div style={{ marginBottom: "10px" }} key={label.id}>
                  <TranslationMessage id={label.id} />
                  <span style={spanStyle}>{data.data[index]} Uhr</span>
                </div>
              );
            })}
          </div>
        );
      };
      AccordionData.push({
        id: data.id,
        heading: data.heading,
        content: contentContainer,
      });
    });
    return AccordionData;
  };

  const handleDateBlur = (e) => {
    if (e.target.value === "") e.target.type = "text";
  };

  const renderTraining = () => {
    if (training) {
      return (
        <S.TrainingFixedContainer>
          {Data.trainingFixedValues.map((value) => {
            return (
              <S.TrainingFixedCardContainer key={value.id}>
                <Checkbox
                  isChecked={trainingDays.indexOf(value.value) !== -1}
                  onChange={(checked) =>
                    handleTrainingFixedCheckbox(checked, value.value)
                  }
                  id={value.id}
                  name={value.id}
                  value={value.value}
                  margin="0 8px 0 0"
                />
                <TranslationMessage id={value.id} />
              </S.TrainingFixedCardContainer>
            );
          })}
        </S.TrainingFixedContainer>
      );
    } else {
      return (
        <S.TrainingFlexibleCardContainer>
          <S.TrainingFlexibleCard>
            <S.TrainingFlexibleCardHeading>
              <TranslationMessage id="registration.trainingFlexible.trainingUnitPerWeek" />
            </S.TrainingFlexibleCardHeading>
            <IncrementInput
              disabled={false}
              width="130"
              placeholder="0"
              value={totalTrainingDays}
              setData={setTotalTrainingDays}
              id="weekDays"
              inputMeasure=""
              maxLimit={7}
            />
          </S.TrainingFlexibleCard>
        </S.TrainingFlexibleCardContainer>
      );
    }
  };

  const nextButtonValue = () => {
    if (id === "personalData") return "part1Register";
    else return "next";
  };

  const manageRoutesBasedOnSelectedCategory = (
    category,
    isCallingOnComponentLoad = false
  ) => {
    let categoryToAppend = "";

    const resetRegistrationLinks = registrationLinks.map((link) => {
      if (link.includes("-")) return link.split("-")[0];
      return link;
    });
    // console.log("registrationLinks", registrationLinks)
    setRegistrationLinks([...resetRegistrationLinks]);
    // console.log("category", category)
    // console.log("resetRegistrationLinks", resetRegistrationLinks)
    if (category.id === 1) categoryToAppend = "";
    if (category.id === 2) categoryToAppend = "-vegan";
    if (category.id === 3) categoryToAppend = "-vegetarian";

    let indexOfStartLink = resetRegistrationLinks.indexOf(
      "/registration/eatingHabit2"
    );
    let indexOfEndLink = resetRegistrationLinks.indexOf(
      "/registration/eatingHabit5"
    );
    let requiredSlice = resetRegistrationLinks.slice(
      indexOfStartLink,
      indexOfEndLink + 1
    );

    const newRequiredSlice = requiredSlice.map((link) => {
      if (link.includes("-")) return `${link.split[0]}${categoryToAppend}`;
      return `${link}${categoryToAppend}`;
    });

    let cloneRegistrationLinks = [...resetRegistrationLinks];
    cloneRegistrationLinks.splice(10, 4, ...newRequiredSlice);
    // console.log("cloneRegistrationLinks-----before", cloneRegistrationLinks);
    setRegistrationLinks([...cloneRegistrationLinks]);

    if (isCallingOnComponentLoad) handleLinksPosition(cloneRegistrationLinks);
  };

  const getNutiritionTypeId = (aimID, selectedNutrients) => {
    const defaultSelectedNeutrients = {
      carbohydrates: 0,
      protein: 0,
      fat: 0,
    };

    const subCategories = [
      eatingHabit1,
      eatingHabit2,
      eatingHabit3,
      eatingHabit4,
      eatingHabit5,
      eatingHabit2Vegan,
      eatingHabit3Vegan,
      eatingHabit4Vegan,
      eatingHabit5Vegan,
      eatingHabit2Vegetarian,
      eatingHabit3Vegetarian,
      eatingHabit4Vegetarian,
      eatingHabit5Vegetarian,
    ];

    let uniqueMenuItems = [];

    subCategories.forEach((cat) => {
      nutrients.forEach((menuItem) => {
        if (cat.subCategoryId === menuItem?.subCategoryId) {
          if (menuItem.eatingHabitData.id === cat.eatingHabitData.id) {
            uniqueMenuItems.push(menuItem);
          }
        }
      });
    });

    const totalNutrients = Methods.addUpNutrients(
      defaultSelectedNeutrients,
      uniqueMenuItems
    );
    setSelectedNutrients(totalNutrients);

    const nutritionTypeID = Methods.calculateNutiritionTypeId(
      aimID,
      totalNutrients
    );

    formData.nutritiontypeID = nutritionTypeID;
    console.log("nutritionTypeID", nutritionTypeID);
    console.log("formData", formData);
    localStorage.setItem(
      "NutrtionIds",
      JSON.stringify({ nutritionTypeID, aimID })
    );
  };

  const handleOverAllEvaluation = (obj) => {
    localStorage.setItem("Anamnese", JSON.stringify(obj));
  };

  return (
    <div>
      <Topbar
        prev={prevLink}
        next={nextLink}
        nextButtonDisabled={IsCreating}
        nextButtonId={nextButtonValue()}
        onNext={updateRegistrationData}
        redirect={handleNextRedirect()}
        arrayData={registrationLinks}
        setPrevLink={setPrevLink}
        setNextLink={(link) => {
          console.log("setNextLink", link);
          setNextLink(link);
        }}
        progress={Data.getProgress(id)}
        urlId={id}
        onNutritionTypeIdCalculation={() => {
          // console.log("onNutritionTypeIdCalculation")
        }}
      />
      {id === "gender" && (
        <S.RegistrationContainer>
          <S.RegistrationHeading>
            <TranslationMessage id="registration.gender.heading" />
          </S.RegistrationHeading>
          <S.RegistrationSubHeading>
            <TranslationMessage id="registration.gender.subHeading" />
          </S.RegistrationSubHeading>
          <S.GenderCardContainer>
            <S.GenderCard
              selected={gender === "male"}
              onClick={() => setGender("male")}
            >
              <S.GenderCardImage src={Male} alt="Male" />
              <S.GenderCardHeading>
                <TranslationMessage id="registration.gender.male" />
              </S.GenderCardHeading>
            </S.GenderCard>
            <S.GenderCard
              marginLeft
              selected={gender === "female"}
              onClick={() => setGender("female")}
            >
              <S.GenderCardImage src={Female} alt="Female" />
              <S.GenderCardHeading>
                <TranslationMessage id="registration.gender.female" />
              </S.GenderCardHeading>
            </S.GenderCard>
          </S.GenderCardContainer>
        </S.RegistrationContainer>
      )}

      {id === "goal" && (
        <S.RegistrationContainer>
          <S.RegistrationHeading>
            <TranslationMessage id="registration.goal.heading" />
          </S.RegistrationHeading>
          <S.RegistrationSubHeading>
            <TranslationMessage id="registration.goal.subHeading" />
          </S.RegistrationSubHeading>
          <S.GoalCardContainer>
            <S.GoalCard selected={goal === 1} onClick={() => setGoal(1)}>
              <S.GoalCardImage src={Slimming} alt="Slimming" />
              <S.GoalCardHeading>
                <TranslationMessage id="registration.goal.slimming" />
              </S.GoalCardHeading>
            </S.GoalCard>
            <S.GoalCard selected={goal === 4} onClick={() => setGoal(4)}>
              <S.GoalCardImage src={HealthDiet} alt="HealthDiet" />
              <S.GoalCardHeading>
                <TranslationMessage id="registration.goal.healthDiet" />
              </S.GoalCardHeading>
            </S.GoalCard>
            <S.GoalCard selected={goal === 2} onClick={() => setGoal(2)}>
              <S.GoalCardImage src={MuscleBuilding} alt="MuscleBuilding" />
              <S.GoalCardHeading>
                <TranslationMessage id="registration.goal.muscleBuilding" />
              </S.GoalCardHeading>
            </S.GoalCard>
            <S.GoalCard selected={goal === 3} onClick={() => setGoal(3)}>
              <S.GoalCardImage
                src={MuscleBuildingBulk}
                alt="MuscleBuildingBulk"
              />
              <S.GoalCardHeading withSubtext>
                <TranslationMessage id="registration.goal.muscleBuildingBulk" />
              </S.GoalCardHeading>
              <S.GoalCardSubHeading>
                <TranslationMessage id="registration.goal.muscleBuildingBulk.subheading" />
              </S.GoalCardSubHeading>
            </S.GoalCard>
          </S.GoalCardContainer>
        </S.RegistrationContainer>
      )}

      {id === "anamnese_evaluation" && (
        <EvaluationOfOverallConditionForm
          onSelect={(obj) => handleOverAllEvaluation(obj)}
        />
      )}

      {id === "bodyDetails" && (
        <S.RegistrationContainer>
          <S.RegistrationHeading>
            <TranslationMessage id="registration.bodyDetails.heading" />
          </S.RegistrationHeading>
          <S.RegistrationSubHeading>
            <TranslationMessage id="registration.bodyDetails.subHeading" />
          </S.RegistrationSubHeading>
          <S.BodyDetailsCardContainer>
            <S.BodyDetailsCard>
              <S.BodyDetailsCardHeading>
                <TranslationMessage id="registration.bodyDetails.dateOfBirth" />
              </S.BodyDetailsCardHeading>
              <Input
                type="text"
                placeholder="01.01.2000"
                max={moment().add(-1, "days").format("YYYY-MM-DD")}
                onFocus={(e) => (e.target.type = "date")}
                onBlur={handleDateBlur}
                value={birthDate}
                onChange={(e) => {
                  setBirthDate(e.target.value);
                  SetBodyDetailsError(false);
                }}
                style={{
                  width: "200px",
                  margin: "0 auto",
                  padding: "10px 0px",
                  textAlign: "center",
                }}
              />
              {BodyDetailsError || isFirstTime || birthDate ? (
                ""
              ) : (
                <ErrorMessage error={"Required."} />
              )}
            </S.BodyDetailsCard>
            <S.BodyDetailsCard>
              <S.BodyDetailsCardHeading>
                <TranslationMessage id="registration.bodyDetails.height" />
              </S.BodyDetailsCardHeading>
              <IncrementInput
                width="200"
                placeholder="0"
                type="number"
                id="cm"
                value={height}
                setData={(value) => {
                  if (!Number.isInteger(+value)) {
                    setIsHeightFloat(true);
                    return;
                  }
                  setIsHeightFloat(false);
                  SetBodyDetailsError(false);
                  setHeight(value);
                  console.log("CM", +value, Number.isInteger(+value));
                }}
              />
              {BodyDetailsError ||
                isFirstTime ||
                (parseInt(height) <= 120 && (
                  <ErrorMessage
                    error={"Die Mindesthöhe sollte 120 cm betragen."}
                  />
                ))}
              {isHeightFloat && (
                <ErrorMessage
                  error={"Das Gewicht muss eine gerade Nummer sein."}
                />
              )}
            </S.BodyDetailsCard>
            <S.BodyDetailsCard>
              <S.BodyDetailsCardHeading>
                <TranslationMessage id="registration.bodyDetails.weight" />
              </S.BodyDetailsCardHeading>
              <IncrementInput
                width="200"
                placeholder="0"
                type="number"
                id="kg"
                value={weight}
                setData={(value) => {
                  if (!Number.isInteger(+value)) {
                    setIsWeightFloat(true);
                    return;
                  }
                  setIsWeightFloat(false);
                  SetBodyDetailsError(false);
                  setWeight(value);
                  // console.log('KG',+value, Number.isInteger(+value))
                }}
              />
              {BodyDetailsError ||
                isFirstTime ||
                (parseInt(weight) <= 40 && (
                  <ErrorMessage
                    error={"Das Mindestgewicht sollte 40 kg betragen."}
                  />
                ))}
              {isWeightFloat && (
                <ErrorMessage
                  error={"Das Gewicht muss eine gerade Nummer sein."}
                />
              )}{" "}
            </S.BodyDetailsCard>
          </S.BodyDetailsCardContainer>
        </S.RegistrationContainer>
      )}

      {id === "dailyRoutine" && (
        <S.RegistrationContainer>
          <S.RegistrationHeading>
            <TranslationMessage id="registration.dailyRoutine.heading" />
          </S.RegistrationHeading>
          <S.RegistrationSubHeading>
            <TranslationMessage id="registration.dailyRoutine.subHeading" />
          </S.RegistrationSubHeading>
          <S.DailyRoutineCardContainer>
            <CollapsibleAccordion
              data={AccordionContent()}
              onUpdate={(accordion) => updateRoutine(accordion)}
            />
            <S.AddNewDailyRoutineButtonContainer>
              <Button
                color="#1C9D87"
                outlined
                absolute={{ width: "auto" }}
                onClick={() => setAddDailyRoutineModal(true)}
              >
                <TranslationMessage id="button.addAnotherDailyRoutine" />
              </Button>
            </S.AddNewDailyRoutineButtonContainer>
          </S.DailyRoutineCardContainer>
        </S.RegistrationContainer>
      )}
      {id === "activityLevel" && (
        <S.RegistrationContainer>
          <S.RegistrationHeading>
            <TranslationMessage id="registration.activityLevel.heading" />
          </S.RegistrationHeading>
          <S.RegistrationSubHeading>
            <TranslationMessage id="registration.activityLevel.subHeading" />
          </S.RegistrationSubHeading>
          <S.ActivityLevelContainer>
            {activityLevelValues.map((value) => {
              return (
                <S.ActivityLevelCardContainer key={value.id}>
                  <TranslationMessage id={value.id} />
                  <Dropdown
                    width="80px"
                    options={value.options}
                    margin="none"
                    value={value.value}
                    onChange={(e) =>
                      handleActivityLevelValueChange(e, value.id)
                    }
                    placeholder="0:00"
                  />
                </S.ActivityLevelCardContainer>
              );
            })}
            <S.ActivityLevelTotalContainer>
              <S.ActivityLevelTotalHeading>
                <TranslationMessage id="registration.activityLevel.total" />
              </S.ActivityLevelTotalHeading>
              <S.ActivityLevelTotal>
                <Input
                  disabled
                  warning
                  value={totalMomentHrs}
                  type="text"
                  placeholder="0 h"
                  style={{ width: "86px" }}
                />
              </S.ActivityLevelTotal>
              <S.ActivityLevelTotalDescription>
                <TranslationMessage id="registration.activityLevel.24hLeft.p1" />
                {24 - totalMomentHrs}
                <TranslationMessage id="registration.activityLevel.24hLeft.p2" />
              </S.ActivityLevelTotalDescription>
            </S.ActivityLevelTotalContainer>
          </S.ActivityLevelContainer>
        </S.RegistrationContainer>
      )}
      {id === "trainingFixed" && (
        <S.RegistrationContainer>
          <S.RegistrationHeading>
            <TranslationMessage id="registration.trainingFixed.heading" />
          </S.RegistrationHeading>
          <S.RegistrationSubHeading>
            <TranslationMessage id="registration.trainingFixed.subHeading" />
          </S.RegistrationSubHeading>
          <S.TrainingFixedSwitchContainer>
            <S.TrainingFixedSwitchHeading>
              <TranslationMessage id="registration.trainingFixed.fixedTrainingDays" />
            </S.TrainingFixedSwitchHeading>
            <Switch
              switchedOn={training}
              onChange={() => setTraining(!training)}
            />
          </S.TrainingFixedSwitchContainer>
          {renderTraining()}
        </S.RegistrationContainer>
      )}

      {id === "dietCategories" && (
        <DietCategories
          selectedFoodCategory={selectedFoodCategory}
          onCategorySelect={(category) => {
            localStorage.setItem("foodCategory", JSON.stringify(category));
            setSelectedFoodCategory(category);
            manageRoutesBasedOnSelectedCategory(category);
          }}
        />
      )}

      {eatingHabits.map((eatingHabit) => {
        return (
          <>
            {id === eatingHabit.id && (
              <S.RegistrationContainer key={eatingHabit.id}>
                <S.RegistrationHeading>
                  <TranslationMessage id="registration.eatingHabit.heading" />
                </S.RegistrationHeading>
                <S.RegistrationSubHeading>
                  <TranslationMessage id="registration.eatingHabit.subHeading" />
                </S.RegistrationSubHeading>
                <S.EatingHabitsContainer>
                  {EatingHabitsContent(
                    eatingHabit.data,
                    eatingHabit.id,
                    eatingHabit.state
                  )}
                </S.EatingHabitsContainer>
              </S.RegistrationContainer>
            )}
          </>
        );
      })}
      {id === "personalData" && (
        <S.RegistrationContainer>
          <S.PersonalDataContainer>
            <S.PersonalDataInnerContainer>
              <S.RegistrationHeading>
                <TranslationMessage id="registration.personalData.heading" />
              </S.RegistrationHeading>
              <S.RegistrationSubHeading>
                <TranslationMessage id="registration.personalData.subHeading" />
              </S.RegistrationSubHeading>
              <S.PersonalDataInputContainer>
                <Input
                  placeholder="Vorname"
                  onChange={(e) => setFirstName(e.target.value)}
                  value={firstName}
                />
                <Input
                  placeholder="Nachname"
                  onChange={(e) => setLastName(e.target.value)}
                  value={lastName}
                />
                {/* containerStyles={{ height: "48px" }} */}
                <Input
                  placeholder="E-Mail"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                />
                {/* <Input
                    filled
                    style={{ width: "256px", marginBottom: "10px" }}
                    placeholder="Handynummer"
                    onChange={(e) => setMobile(e.target.value)}
                    value={mobile}
                  /> */}
                <div style={{ textAlign: "left" }}>
                  <PhoneInput
                    className="phone-input"
                    placeholder="+49 000 000 0000"
                    defaultCountry="DE"
                    international
                    countryCallingCodeEditable={false}
                    onChange={(value) => {
                      console.log("PhoneInput", value);
                      if (value?.length < 25) {
                        setIsPhoneValid(true);
                        setMobile(value);
                      } else {
                        setIsPhoneValid(false);
                      }
                    }}
                    value={mobile}
                  />
                  {!isPhoneValid && !!mobile && (
                    <ErrorMessage
                      error={"Bitte beachte das internationale Format"}
                    />
                  )}
                </div>
              </S.PersonalDataInputContainer>
              <S.PersonalDataRulesContainer>
                <SquareCheckbox
                  rounded="7px"
                  filled="#F5F5F5"
                  isChecked={isChecked.firstCheck}
                  param="firstCheck"
                  setIsChecked={setIsChecked}
                />
                <S.PersonalDataRulesLabel
                  dangerouslySetInnerHTML={{
                    __html: intl.formatMessage({
                      id: "registration.personalData.termsAndConditions",
                    }),
                  }}
                />
                <SquareCheckbox
                  rounded="7px"
                  filled="#F5F5F5"
                  isChecked={isChecked.secondCheck}
                  param="secondCheck"
                  setIsChecked={setIsChecked}
                />
                <S.PersonalDataRulesLabel
                  dangerouslySetInnerHTML={{
                    __html: intl.formatMessage({
                      id: "registration.personalData.DataConsent",
                    }),
                  }}
                />
              </S.PersonalDataRulesContainer>
              {!!error?.error &&
                Object.values(error?.error || {})
                  ?.flat()
                  ?.map((msg) => (
                    <>
                      <ErrorMessage error={msg} /> <br />
                    </>
                  ))}

              {error?.success === false && (
                <ErrorMessage error={error?.message} />
              )}
            </S.PersonalDataInnerContainer>
          </S.PersonalDataContainer>
        </S.RegistrationContainer>
      )}

      <Modal
        width="450px"
        show={IsBrowserReloadModal}
        setShow={() => null}
        heading={"Leave Page Confirmation"}
      >
        <p>Are you sure you want to leave this page?</p>
        <div className="modal-actions">
          <Button
            color="rgba(46, 207, 150, .2)"
            gradient="linear-gradient(60deg, rgba(46, 207, 150, 1), rgba(28, 157, 135, 1)) !important"
            margin="30px auto 0px"
            style={{ fontSize: "16px" }}
            onClick={() => SetIsBrowserReloadModal(false)}
          >
            Cancel
          </Button>
          <Button
            color="rgba(46, 207, 150, .2)"
            gradient="linear-gradient(60deg, rgba(46, 207, 150, 1), rgba(28, 157, 135, 1)) !important"
            margin="30px auto 0px"
            style={{ fontSize: "16px" }}
            onClick={() => {
              SetIsBrowserReloadModal(false);
              window.removeEventListener("beforeunload", handleBeforeUnload);
              window.location.reload();
            }}
          >
            Reload
          </Button>
        </div>
      </Modal>
      <Modal
        show={addDailyRoutineModal}
        setShow={setAddDailyRoutineModal}
        heading={
          <TranslationMessage id="registration.dailyRoutine.popupHeading" />
        }
      >
        <Input
          type="text"
          id="routineName"
          placeholder="Name des Tagesablaufs"
          value={routineName}
          onChange={(e) => setRoutineName(e.target.value)}
        />
        {Data.AddDailyRoutineValues.map((value) => {
          return (
            <S.DailyRoutineInputContainer key={value.id}>
              <S.DailyRoutineInputLabel>
                <TranslationMessage id={value.id} />
              </S.DailyRoutineInputLabel>
              <TimePickerCustom
                value={new Date(2007, 11, 5, value.hrs, 0, 17)}
                onChange={(time) => {
                  handleTimeChange(time, value.id);
                  setAddDailyRoutineModal(true);
                }}
              />
            </S.DailyRoutineInputContainer>
          );
        })}
        <Button
          color="rgba(46, 207, 150, .2)"
          gradient="linear-gradient(60deg, rgba(46, 207, 150, 1), rgba(28, 157, 135, 1)) !important"
          margin="30px auto 0px"
          style={{ fontSize: "16px" }}
          onClick={handleAddRoutine}
          disabled={routineName.length === 0}
        >
          <TranslationMessage id="button.addDailyRoutine" />
        </Button>
      </Modal>
      <Modal
        width="450px"
        show={updateDailyRoutineModal}
        setShow={setUpdateDailyRoutineModal}
        heading={
          <TranslationMessage id="registration.dailyRoutine.popupHeading" />
        }
      >
        <h4>{selectedRoutine?.heading}</h4>
        {/* {selectedRoutine?.data?.map((value) => {
          return (
            <S.DailyRoutineInputContainer key={value?.id}>
              {console.log(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), value.time.split(':')[0], value.time.split(':')[1], 0))}
              <S.DailyRoutineInputLabel>
                <TranslationMessage id={value?.id} />
              </S.DailyRoutineInputLabel>
              <TimePicker
                defaultValue={new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), value.time.split(':')[0], value.time.split(':')[1], 0)}
                onChange={(time) => {
                  console.log(time)
                  handleUpdateTimeChange(time, value?.id)
                  // setAddDailyRoutineModal(true)
                }}
              />
            </S.DailyRoutineInputContainer>
          )
        })} */}
        <S.DailyRoutineInputContainer>
          <S.DailyRoutineInputLabel>
            <TranslationMessage id={"input.label.getup"} />
          </S.DailyRoutineInputLabel>
          <TimePicker
            clearable
            ampm={false}
            value={updateRoutineGetup?.time}
            onChange={(time) => {
              handleUpdateTimeChange(time, "input.label.getup");
            }}
          />
        </S.DailyRoutineInputContainer>
        <S.DailyRoutineInputContainer>
          <S.DailyRoutineInputLabel>
            <TranslationMessage id={"input.label.lunchTime"} />
          </S.DailyRoutineInputLabel>
          <TimePicker
            ampm={false}
            value={updateRoutineLunchTime?.time}
            onChange={(time) => {
              handleUpdateTimeChange(time, "input.label.lunchTime");
            }}
          />
        </S.DailyRoutineInputContainer>
        <S.DailyRoutineInputContainer>
          <S.DailyRoutineInputLabel>
            <TranslationMessage id={"input.label.workoutTime"} />
          </S.DailyRoutineInputLabel>
          <TimePicker
            ampm={false}
            value={updateRoutineWorkoutTime?.time}
            onChange={(time) => {
              handleUpdateTimeChange(time, "input.label.workoutTime");
            }}
          />
        </S.DailyRoutineInputContainer>
        <S.DailyRoutineInputContainer>
          <S.DailyRoutineInputLabel>
            <TranslationMessage id={"input.label.bedTime"} />
          </S.DailyRoutineInputLabel>
          <TimePicker
            ampm={false}
            value={updateRoutineBedTime?.time}
            onChange={(time) => {
              handleUpdateTimeChange(time, "input.label.bedTime");
            }}
          />
        </S.DailyRoutineInputContainer>
        <Button
          color="rgba(46, 207, 150, .2)"
          gradient="linear-gradient(60deg, rgba(46, 207, 150, 1), rgba(28, 157, 135, 1)) !important"
          margin="30px auto 0px"
          style={{ fontSize: "16px" }}
          onClick={() => handleUpdateRoutine(selectedRoutine)}
        >
          <TranslationMessage id="button.updateDailyRoutine" />
        </Button>
      </Modal>
    </div>
  );
};

export default Registration;
