import React from "react";
import { useParams } from "react-router-dom";

// Components
import TranslationMessage from "configs/translations/TranslationMessage";
import EvaluationOfOverallConditionForCustomers from "pages/ClientDetails/EvaluationOfOverallConditionForCustomers/EvaluationOfOverallConditionForCustomers";

import ClientDetailsSidebar from "./ClientDetailsSidebar";

import * as S from "./ClientDetails.styles";
import "./clientDetails.scss";

const Anamnese = () => {
  const { userId } = useParams();

  return (
    <>
      <S.ClientDetailsOverallContainer>
        <S.ClientDetailsContainer>
          <div className="second-sidebar-wrapper">
            <ClientDetailsSidebar />
          </div>
          <div className="second-sidebar-content" style={{ padding: "1.5rem" }}>
            <S.ContainerBox>
              <S.Header>
                <div>
                  <S.Heading className="mb-0">
                    <TranslationMessage id="clientDetails.tab.anamnese" />
                  </S.Heading>
                </div>
                <div></div>
              </S.Header>

              <S.Divider></S.Divider>
              <S.PaddingBox>
                <EvaluationOfOverallConditionForCustomers userID={userId} />
              </S.PaddingBox>
            </S.ContainerBox>
          </div>
        </S.ClientDetailsContainer>
      </S.ClientDetailsOverallContainer>
    </>
  );
};

export default Anamnese;
