import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import * as S from "./influencer.styles";
import Select from "react-select";
import DataTable from "react-data-table-component";

// Components
import Topbar from "components/topbar/Topbar";

// Images
import ArrowLeft from "assets/images/downArrow.svg";

// Redux
import * as AuthActions from "redux/auth/auth.actions";
import { FILE_BASE_URL_S3 } from "configs/services/apiCollections";
import { dashboardNavTabs } from "configs/utils";
import { getS3ImageUrl } from "configs/utils";

const followersOptions = [
  { value: [0, 10000], label: "0 - 10.000" },
  { value: [10001, 20000], label: "10.001 - 20.000" },
  { value: [20001], label: "20.001 and Above" },
];

const columns = [
  {
    name: "Bild",
    selector: (row) => row.image,
  },
  {
    name: "Name",
    selector: (row) => row.fullName,
  },
  {
    name: "Follower",
    selector: (row) => row.followers,
  },
  {
    name: "Story Views",
    selector: (row) => row.storyViews,
  },
  {
    name: "PREIS",
    selector: (row) => row.price,
  },
  {
    name: "",
    selector: (row) => row.action,
  },
];

const Influencers = () => {
  const [tableData, setTableData] = useState([]);
  const [selectedFolloers, setSelectedFolloers] = useState(null);

  const dispatch = useDispatch();
  const history = useHistory();

  const isInfluencersLoading = useSelector(
    (state) => state.auth.isInfluencersLoading
  );
  const influencers = useSelector((state) => state.auth.influencers);
  const trainerProfileData = useSelector((state) => state.auth.trainerData);
  
  useEffect(() => {
    setSelectedFolloers(followersOptions[0]);
    getInfluencers(followersOptions[0]?.value);
  }, []);

  const onDetails = (item) => {
    history.push(`/influencers/${item?.id}`);
  };

  const onFolloersChange = (option) => {
    setSelectedFolloers(option);
    getInfluencers(option?.value);
  };

  const getInfluencers = (follower) => {
    dispatch(
      AuthActions.getInfluencersRequest({
        formData: {
          follower,
        },
        callback: (response) => {
          setTableData([]);
          const list = response?.influencersList?.map((item) => {
            return {
              id: item?.id,
              image: (
                <div className="circle">
                  <img src={FILE_BASE_URL_S3() + item?.profile_picture} />{" "}
                </div>
              ),
              fullName: (
                <span className="name">{`${item?.Vorname} ${item?.Nachname}`}</span>
              ),
              followers: <span className="chip">{item?.follower}</span>,
              storyViews: <span className="chip">{item?.story_view}</span>,
              price: <span className="priceText">{`${item?.price} €`}</span>,
              action: (
                <button onClick={() => onDetails(item)}>Jetzt buchen</button>
              ),
            };
          });

          setTableData(list);
        },
      })
    );
  };

  return (
    <>
      <Topbar
        navTabs={dashboardNavTabs}
        redirectionLink
        profileDropdown={{
          name: trainerProfileData.prename + " " + trainerProfileData.surname,
          profilePic: getS3ImageUrl(trainerProfileData.profilePic),
        }}
      />
      <S.DatabaseContainer>
        <S.FirstRow>
          <S.LeftSide>
            <S.InfoBox style={{ paddingLeft: 0 }}>
              <S.SmallText>Influencer</S.SmallText>
              <S.Heading>Datenbank</S.Heading>
            </S.InfoBox>
          </S.LeftSide>
          <S.RightSide>
            <S.FollowText>Followers</S.FollowText>
            <Select
              options={followersOptions}
              className="custumStyle"
              classNamePrefix="react-select"
              onChange={onFolloersChange}
              value={selectedFolloers}
              isSearchable={false}
            />
          </S.RightSide>
        </S.FirstRow>

        <S.TableBox>
          <DataTable
            className="outerBorder"
            progressPending={isInfluencersLoading}
            columns={columns}
            data={tableData}
          />
        </S.TableBox>
      </S.DatabaseContainer>
    </>
  );
};

export default Influencers;
