import React, {useEffect} from 'react';
import { useDispatch, useSelector } from "react-redux";
import * as S from "./dashboard.styles";
import { getImageUrl, getS3ImageUrl } from "configs/utils";

// Components
import Topbar from 'components/topbar/Topbar';
import TrainerProfile from 'components/trainerProfile/TrainerProfile';
import Loader from "components/loader/Loader";

// Images
import GymLogo from "assets/images/gymLogo.svg";

// Redux
import * as AuthActions from "redux/auth/auth.actions";

const Dashboard = () => {
    const dispatch = useDispatch();
    const loading = useSelector(state => state.auth.loading);
    const trainerProfileData = useSelector(state => state.auth.trainerData);

    useEffect(() => {
        dispatch(AuthActions.getTrainerProfileRequest({FranchiseID: trainerProfileData.FranchiseID}))
    }, [])

    
    
    return (
        <>
            <Topbar next="/whatAwaitsYou" />
            {!loading && <S.DashboardContainer>
                <S.TrainerProfileSidebarContainer>
                    <S.TrainerProfileImage bgImage={getS3ImageUrl(trainerProfileData.profilePic)} />
                    <S.FromGymContainer>
                        <S.FromGymHeading>Bekannt aus:</S.FromGymHeading>
                        <S.FromGymLogoContainer>
                            <S.FromGymLogo src={GymLogo} alt="Gym Logo" />
                        </S.FromGymLogoContainer>
                    </S.FromGymContainer>
                </S.TrainerProfileSidebarContainer>
                <TrainerProfile />
            </S.DashboardContainer>}
            {<Loader loading={loading} top="320px" />}
        </>
    )
}

export default Dashboard
