import styled from 'styled-components';

export const NutritionBackdropImage = styled.img`
    position: absolute;
    width: 102%;
    margin-left: -2%;

    @media only screen and (min-width: 1100px) {
        margin-top: -10%;
    }
    @media only screen and (min-width: 1500px) {
        margin-top: -17%;
    }
`;

export const NutritionCreationTextContainer = styled.div`
    position: absolute;
    top: 420px;
    left: 19%;
    z-index: 200;

    @media only screen and (min-width: 1100px) {
        left: 20%;
    }
    @media only screen and (min-width: 1500px) {
        left: 23%;
    }
`;


export const NutritionCreationVideo = styled.video`
    position: absolute;
    border-radius: 15px;
    top: calc((100vh - 568px)/2);;
    right: 10%;
    width: 318px;
    height: 568px;
    -webkit-box-shadow: 0 8px 15px 8px #E0E0E0;
    -moz-box-shadow: 0 8px 15px 8px #E0E0E0;
    box-shadow: 0 8px 15px 8px #E0E0E0;
`;