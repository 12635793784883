import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  setUserRegistrationDataInLocalStorage,
  getUserRegistrationDataFromLocalStorage,
} from "configs/utils";

// Components
import Button from "components/formComponents/button/Button";

import MaleIcon from "assets/images/Onboarding/Screen5/Icon-male.svg";
import FemaleIcon from "assets/images/Onboarding/Screen5/Icon-woman.svg";
import ArrowLeft from "assets/images/arrowLeft.svg";

// Redux
import * as S from "../OnBoardingPartOne.styles";
import OnboardingSidebar from "../OnboardingSidebar";

const Gender = (props) => {
  const history = useHistory();
  const registrationData = useSelector((state) => state.auth.registrationData);

  const [gender, setGender] = useState("");
  const [IsValidation, SetIsValidation] = useState(false);

  useEffect(() => {
    // onSelectGender(registrationData.sex);

    const gender = getUserRegistrationDataFromLocalStorage()?.gender;

    if (gender) {
      setGender(gender);
    } else {
      setGender("");
    }
  }, []);

  const onSelectGender = (gender) => {
    SetIsValidation(false);
    setGender(gender);
    setUserRegistrationDataInLocalStorage({
      gender,
      goal: 0,
      bodyDetails: null,
      anamnese: null,
      dailyRoutineData: null,
      trainingDays: null,
      trainingSessionsPerWeek: null,
      eatinghabits: null,
    });
  };

  const handleNext = () => {
    if (!gender) {
      SetIsValidation(true);
      return;
    }

    history.push("/onboarding/registration/goal");
  };

  const handleBack = () => {
    history.push("/onboarding/foodiary-benefits");
  };

  return (
    <>
      <S.OnBoardingPage className="starter">
        <S.OnBoardingContainer className="onboarding-container">
          <OnboardingSidebar step={2} />
          <S.OnBoardingContent className="onboarding-content">
            <S.OnBoardingHeader className="onboarding-header">
              <div className="d-flex align-items-top gap-3">
                <img
                  className="cursor-pointer"
                  src={ArrowLeft}
                  alt="back"
                  onClick={handleBack}
                />
                <div className="content">
                  <span className="small-title text-green font-rubik-medium">
                    Anamnese
                  </span>
                  <h6 className="header-title font-rubik-medium my-0">
                    Wir erstellen deinen Plan.
                  </h6>
                </div>
              </div>
              <div className="actions">
                <Button
                  color="rgba(248, 164, 146, 1)"
                  gradient="linear-gradient(60deg, rgba(46, 207, 150, 1), rgba(28, 157, 135, 1)) !important"
                  margin="0"
                  absolute={{ width: "fit-content", height: "2.5rem" }}
                  buttonStyle={{ padding: ".5625rem 2.5rem" }}
                  onClick={handleNext}
                >
                  Weiter
                </Button>
              </div>
            </S.OnBoardingHeader>
            <S.OnBoardingBody className="onboarding-body">
              <div className="gender-content ">
                <h3 className="text-size-20-28 text-dark-1 font-rubik-medium">
                  Dein Geschlecht ist die Basis für den <br /> Kalorienbedarf.
                </h3>
                <p className="text-size-16-19 text-light-grey-1 font-rubik-regular">
                  Der Grundumsatz unterscheidet sich bei Mann und Frau.
                </p>
                <p className="text-size-14-28 text-dark-1 font-rubik-medium">
                  Wähle dein Geschlecht
                </p>
                {IsValidation && (
                  <p className="text-size-14-18 text-right text-red font-rubik-regular mt-0">
                    Bitte wähle das Geschlecht
                  </p>
                )}
                <div className="gender-items">
                  <div
                    className="gender-item cursor-pointer"
                    onClick={() => onSelectGender("male")}
                  >
                    <img className="gender-img" src={MaleIcon} alt="" />
                    <p className="text-size-20-24 text-dark-1 font-rubik-medium">
                      Mann
                    </p>
                    <div className="form-group-c">
                      <input
                        type="checkbox"
                        id="male"
                        checked={gender === "male"}
                        onChange={() => null}
                      />
                      <label htmlFor="male"></label>
                    </div>
                  </div>
                  <div
                    className="gender-item cursor-pointer"
                    onClick={() => onSelectGender("female")}
                  >
                    <img className="gender-img" src={FemaleIcon} alt="" />
                    <p className="text-size-20-24 text-dark-1 font-rubik-medium">
                      Frau
                    </p>
                    <div className="form-group-c">
                      <input
                        type="checkbox"
                        id="female"
                        checked={gender === "female"}
                        onChange={() => null}
                      />
                      <label htmlFor="female"></label>
                    </div>
                  </div>
                </div>
              </div>
            </S.OnBoardingBody>
          </S.OnBoardingContent>
        </S.OnBoardingContainer>
      </S.OnBoardingPage>
    </>
  );
};

export default Gender;
