import { useHistory } from "react-router-dom";
import * as S from "./OnBoardingPartOne.styles";
import OnboardingSidebar from "./OnboardingSidebar";
import CrossIcon from "assets/images/cross.svg";
import WarningIcon from "assets/images/warning-modal-icon.svg";

function LegalInformation() {
  const history = useHistory();

  return (
    <>
      <S.OnBoardingPage className="starter">
        <S.OnBoardingContainer className="onboarding-container">
          <OnboardingSidebar step={1} />
          <S.OnBoardingContent className="onboarding-content">
            <S.FakeModalEffect>
              <S.OnBoardingHeader className="onboarding-header">
                <div className="d-flex align-items-top gap-3">
                  <div className="content">
                    <span className="small-title text-green font-rubik-medium">
                      Intern Hinweis
                    </span>
                    <h6 className="header-title font-rubik-medium my-0">
                      Bitte achte auf die Rechtssicherheit.
                    </h6>
                  </div>
                </div>
              </S.OnBoardingHeader>
              <S.OnBoardingBody className="onboarding-body p-0">
                <S.FakeModalOnboardingBody>
                  <S.FakeModalOnboardingContent>
                    <div className="close">
                      <img
                        onClick={() => history.push("/onboarding/starter")}
                        src={CrossIcon}
                        alt=""
                      />
                    </div>
                    <div>
                      <img src={WarningIcon} alt="" />
                    </div>
                    <div className="header-title font-rubik-medium">
                      INTERN : Bitte beachte bei deinem Erstgespräch die
                      Rechtssicherheit.
                    </div>
                    <div>
                      <p>
                        Wir empfehlen, das Erstgespräch aufzunehmen, um den
                        mündlichen Vertragsschluss jederzeit nachweisen zu
                        können. Du kannst das Gespräch direkt über Zoom oder
                        Google Meet aufzeichnen.
                      </p>
                      <p>
                        Stelle bitte sicher, dass dein Interessent der Aufnahme
                        vorab ausdrücklich zugestimmt hat.
                      </p>
                      <p>
                        Falls dein Interessent der Aufnahme nicht zustimmt, gilt
                        der Vertrag als rechtsgültig geschlossen, sobald nach
                        dem Erstgespräch das Passwort gesetzt wird.
                      </p>
                    </div>
                  </S.FakeModalOnboardingContent>
                </S.FakeModalOnboardingBody>
              </S.OnBoardingBody>
            </S.FakeModalEffect>
          </S.OnBoardingContent>
        </S.OnBoardingContainer>
      </S.OnBoardingPage>
    </>
  );
}

export default LegalInformation;
