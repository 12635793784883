import styled from "styled-components";
import { ClientDetailsOverviewInputLabel } from "components/clientDetails/clientDetails.styles";

export const ModalBackdrop = styled.div`
  width: 100%;
  height: 100vh;
  background-color: rgb(0, 0, 0, 0.2);
  position: fixed;
  top: 0;
  left: 0;
  overflow: auto;
  z-index: 998;
  display: ${(props) => (props.show ? "block" : "none")};
`;

export const ModalContainer = styled.div`
  width: ${(props) => (props.width ? props.width : "400px")};
  background-color: white;
  height: ${(props) => (props.height ? props.height : "auto")};
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
  border-radius: 10px;
`;

export const ModalInnerContainer = styled.div`
  /* margin: 40px; */
`;

export const ModalHeader = styled.div`
  font-family: 'RubikMedium';
  font-size: 20px;
  color: #142c1f;
  text-align: center;
  border-bottom: 1px solid #e2e4e9;
  padding: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  h4 {

  }
`;

export const ModalBody = styled.div`
  padding: 1.5rem;
`;

export const ValidationText = styled.p`
  color: red;
  font-size: 14px;
  word-break: break-word;
  user-select: text;
  margin: 6px 0 0 0;
`;

export const SuccessMsg = styled.p.attrs({
  className: `${(props) => props.className}`,
  id: `${(props) => props.id}`,
})`
  font-size: 16px;
  font-weight: bold;
  font-weight: 400;
  word-break: break-word;
  user-select: text;
  text-align: center;
  margin: 6px 0 20px;
  color: #2ecf96;
`;

export const ErrorMsg = styled.p.attrs({
  className: `${(props) => props.className}`,
  id: `${(props) => props.id}`,
})`
  color: red;
  font-size: 16px;
  font-weight: bold;
  font-weight: 400;
  word-break: break-word;
  user-select: text;
  text-align: center;
  margin: 6px 0 20px;
`;

export const TrainerProfileDetailsSection1InputLabel = styled(
  ClientDetailsOverviewInputLabel
)`
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const OrangeButton = styled.button`
  background: #f8a492;
  border-radius: 8px;
  cursor: pointer;
  width: 100%;
  border: none;
  outline: none;
  box-shadow: none;
  width: 200px;
  height: 38px;
  color: white;
  font-size: 15px;
  margin: 0 auto;
  display: block;

  &:disabled {
    background: lightgray;
    color: black;
    cursor: not-allowed;
  }
`;
