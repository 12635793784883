import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { debounce } from "lodash";
import Select from "react-select";
import { getS3ImageUrl } from "configs/utils";

import Input from "components/formComponents/input/Input";
import InnerSideNav from "pages/NewDasboard/components/InnerSideNav/InnerSide";

import CrossIcon from "assets/images/cross-icon-faq.svg";
import SearchIcon from "assets/images/search.svg";
import { MENUS } from "./SideMenu";

import * as S from "./MarketingPortal.styles";
import * as NutritionActions from "redux/nutrition/nutrition.actions";

const TagOptions = [
  { value: 1, label: "Partnerschaft aktiv" },
  { value: 2, label: "Partnerschaft beendet" },
];

const Partnerships = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [SelectedTag, SetSelectedTag] = useState(null);
  const [SearchTerm, SetSearchTerm] = useState("");
  const [AllPartners, SetAllPartners] = useState([]);
  const [AllPartnersClone, SetAllPartnersClone] = useState([]);

  const isMarketingPortalPartnerDetailsLoading = useSelector(
    (state) => state.nutrition.isMarketingPortalPartnerDetailsLoading
  );

  const trainerData = useSelector((state) => state.auth.trainerData);

  useEffect(() => {
    getMarketingPortalPartners();
  }, [dispatch]);

  const getMarketingPortalPartners = () => {
    dispatch(
      NutritionActions.getMarketingPortalPartnerDetailsRequest({
        payload: {
          connectedCoachID: trainerData?.trainerID,
        },
        callback: (res) => {
          SetAllPartners(res?.getPartnerDetailsByConnectedCoachId);
          SetAllPartnersClone(res?.getPartnerDetailsByConnectedCoachId);
        },
      })
    );
  };

  const onResetSelectFilter = () => {
    SetSelectedTag(null);
    SetSearchTerm("");
    SetAllPartners(AllPartnersClone);
  };

  const onSelectTagFilter = (b) => {
    SetSelectedTag(b);
    SetSearchTerm("");

    const partners = AllPartnersClone.filter(
      (item) => item?.status?.toLowerCase() === b?.label?.toLowerCase()
    );

    SetAllPartners(partners);
  };

  const debouncedFilter = debounce(
    (term) => filterMarketingPartners(term),
    300
  );

  const onSearchInput = (e) => {
    SetSearchTerm(e.target.value);
    debouncedFilter(e.target.value);
  };

  const filterMarketingPartners = (term) => {
    if (!term) {
      SetAllPartners(AllPartnersClone);
      return;
    }

    const filteredPartnerships = AllPartners.filter((b) =>
      b?.companyName.toLowerCase().includes(term.toLowerCase())
    );

    SetAllPartners(filteredPartnerships);
  };

  return (
    <>
      <S.Container>
        <div className="second-sidebar-wrapper">
          <InnerSideNav menus={MENUS} hideUserInfo></InnerSideNav>
        </div>
        <S.Wrapper className="second-sidebar-content">
          <S.TrainerProfileDetailsInnerContainer>
            <S.Header className="p-0">
              <div>
                <S.TrainerProfileDetailsSectionHeading>
                  Deine Partnerschaften
                </S.TrainerProfileDetailsSectionHeading>
                <S.TrainerProfileDetailsSectionSubHeading>
                  Verwalte deine Partnerschaften. Lege neue Werbepartner an,
                  erhalte die Partner-URL und QR-Code.
                </S.TrainerProfileDetailsSectionSubHeading>
              </div>
              <div className="header-actions">
                <button
                  className="btn-dark"
                  onClick={() =>
                    history.push("/dashboard/marketing-portal/partner/create")
                  }
                >
                  Werbepartner anlegen
                </button>
              </div>
            </S.Header>
            <S.Divider />
            <S.FiltersBox>
              <S.SelectedFiltersAndDropdown>
                <Select
                  options={TagOptions}
                  className="faq-select"
                  classNamePrefix="react-select"
                  onChange={onSelectTagFilter}
                  value={SelectedTag}
                  placeholder="Alle Kategorien"
                  isSearchable={false}
                />

                {SelectedTag && (
                  <S.SelectedFilter>
                    {SelectedTag?.label}
                    <img
                      src={CrossIcon}
                      alt="CrossIcon"
                      onClick={onResetSelectFilter}
                    />
                  </S.SelectedFilter>
                )}
              </S.SelectedFiltersAndDropdown>
              <div>
                <S.FormControlSearch>
                  <Input
                    className="input-custom"
                    style={{ width: "100%", marginBottom: "0" }}
                    placeholder="Suche in der Bibliothek"
                    onChange={onSearchInput}
                    value={SearchTerm}
                  />
                  <img src={SearchIcon} alt="SearchIcon" />
                </S.FormControlSearch>
              </div>
            </S.FiltersBox>
            {isMarketingPortalPartnerDetailsLoading && (
              <>
                <br />
                <br />
                <br />
                <br />
                <div className="text-center">
                  <div className="loader mx-auto"></div>
                </div>
                <br />
                <br />
                <br />
                <br />
              </>
            )}
            {!isMarketingPortalPartnerDetailsLoading && (
              <S.TableWrapper>
                <table>
                  <thead>
                    <tr>
                      <th>Werbepartner</th>
                      <th>Ansprechpartner</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {AllPartners.map((p) => (
                      <tr>
                        <td>
                          <div className="partner">
                            <img
                              src={
                                getS3ImageUrl(p?.companylogo) ||
                                "https://foodiaryproduction.s3.eu-central-1.amazonaws.com/Marketingportal/Company Logo/Placeholder-Profilbild_1714193073TP5TDPmEgj.jpg"
                              }
                              alt=""
                            />
                            <div className="">
                              <h6>{p?.companyName}</h6>
                              <p>{`${p?.street} ${p?.houseNumber}, ${p?.zipcode} ${p?.city}`}</p>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="contact-person">
                            <h6>{p?.contact}</h6>
                            <p>{p?.phone}</p>
                          </div>
                        </td>
                        <td>
                          {p?.status === "Partnerschaft beendet" && (
                            <div className="status-badge orange">
                              {p?.status}
                            </div>
                          )}
                          {p?.status === "Partnerschaft aktiv" && (
                            <div className="status-badge green">
                              {p?.status}
                            </div>
                          )}
                        </td>
                        <td>
                          <button
                            className="btn-outline sm cursor-pointer"
                            onClick={() =>
                              history.push(
                                "/dashboard/marketing-portal/partners/" + p?.id
                              )
                            }
                          >
                            Details
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </S.TableWrapper>
            )}
          </S.TrainerProfileDetailsInnerContainer>
        </S.Wrapper>
      </S.Container>
    </>
  );
};

export default Partnerships;
