import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getNutritionImageUrl } from "configs/utils";

import Loader from "components/loader/Loader";
import Button from "components/formComponents/button/Button";

// Redux
import * as NutritionActions from "redux/nutrition/nutrition.actions";

import FoodiaryLogo from "assets/images/Foodiary-Logo.PNG";
import ArrowLeft from "assets/images/arrowLeft.svg";
import { SidebarSteps } from "../data";

import * as S from "../OnBoardingPartTwo.styles";
import OnboardingSidebar from "pages/OnBoardingPartOne/OnboardingSidebar";

const NutritionRecipes = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.auth.loading);
  const registrationData = useSelector((state) => state.auth.registrationData);
  const recipesData = useSelector((state) => state.nutrition.nutritionRecipes);

  const [selectedFilter, setSelectedFilter] = useState(1);
  const [newDataIndex, setNewDataIndex] = useState(0);
  const allData = recipesData && [
    recipesData[newDataIndex],
    recipesData[newDataIndex + 1],
  ];

  const NutritionCategories = [
    { id: 1, value: "Frühstück" },
    { id: 2, value: "Mittagessen" },
    { id: 3, value: "Abendessen" },
    // {id: 4, value: "Snacks"},
  ];

  useEffect(() => {
    setNewDataIndex(0);
    dispatch(
      NutritionActions.getNutritionRecipesRequest({
        nutritiontypeID:
          registrationData.nutritiontypeID ||
          getRegistrationData()?.nutritiontypeID,
        mealId: selectedFilter,
      })
    );
  }, [dispatch, registrationData, selectedFilter]);

  const getRegistrationData = () => {
    const RD = JSON.parse(localStorage.getItem("RegistrationData"));
    return RD;
  };

  const EatingHabitsContent = () => {
    return (
      <>
        {allData &&
          allData.map((data) => {
            return (
              <S.NutritionFormRecipesCard
                className="recipe-card d-flex align-items-center"
                key={data?.id}
              >
                <S.NutritionFormRecipesCardImageContainer>
                  <img
                    src={getNutritionImageUrl(
                      data?.imgpath,
                      data?.imgname,
                      data?.imgtype
                    )}
                    alt={data?.id}
                  />
                </S.NutritionFormRecipesCardImageContainer>
                <S.NutritionFormRecipesCardInnerContainer className="d-flex justify-content-between gap-3 w-100">
                  <div>
                    <h6 className="text-size-18-22 text-dark-1 mt-0 mb-1">
                      {data?.recipeName}
                    </h6>
                    <p className="text-size-16-19 text-dark-2 my-0">
                      {data?.mealName}
                    </p>
                  </div>
                  <S.NutritionFormRecipesCardInnerCalories className="">
                    <h6 className="text-size-20-24 text-green mt-0 mb-1">
                      {data?.calories}
                    </h6>
                    <span className="text-size-16-19 text-dark-2">kcal</span>
                  </S.NutritionFormRecipesCardInnerCalories>
                </S.NutritionFormRecipesCardInnerContainer>
              </S.NutritionFormRecipesCard>
            );
          })}
      </>
    );
  };

  const handleBack = () => {
    history.push("/onboarding/nutrition/type-update");
  };

  const handleNext = () => {
    history.push("/onboarding/nutrition/calories");
  };

  return (
    <>
      <S.OnBoardingPage className="starter">
        <S.OnBoardingContainer className="onboarding-container">
          <OnboardingSidebar step={3} />
          <S.OnBoardingContent className="onboarding-content">
            <S.OnBoardingHeader className="onboarding-header">
              <div className="d-flex align-items-top gap-3">
                <img
                  className="cursor-pointer"
                  src={ArrowLeft}
                  alt="back"
                  onClick={handleBack}
                />
                <div className="content">
                  <span className="small-title text-green font-rubik-medium">
                    Auswertung
                  </span>
                  <h6 className="header-title font-rubik-medium my-0">
                    Unsere Empfehlung für dich
                  </h6>
                </div>
              </div>
              <div className="actions">
                <Button
                  color="rgba(248, 164, 146, 1)"
                  gradient="linear-gradient(60deg, rgba(46, 207, 150, 1), rgba(28, 157, 135, 1)) !important"
                  margin="0"
                  absolute={{ width: "fit-content", height: "2.5rem" }}
                  buttonStyle={{ padding: ".5625rem 2.5rem" }}
                  onClick={handleNext}
                >
                  Weiter
                </Button>
              </div>
            </S.OnBoardingHeader>
            <S.OnBoardingBody className="onboarding-body">
              <div className="nutrition-recipies-content">
                <h3 className="text-size-20-28 text-dark-1 font-rubik-medium">
                  Rezeptbeispiele aus über 1700 Rezepten
                </h3>
                <p className="text-size-16-19 text-light-grey-1 font-rubik-regular">
                  Keine Einschränkungen im Alltag und völlige Flexibilität
                </p>
                <div className="nutrition-categories d-flex gap-3">
                  {NutritionCategories.map((c) => (
                    <div
                      className={
                        selectedFilter === c.id
                          ? "nutrition-category active"
                          : "nutrition-category"
                      }
                      key={c.id}
                      onClick={() => setSelectedFilter(c.id)}
                    >
                      <span className="text-size-14-17">{c.value}</span>
                    </div>
                  ))}
                </div>
                <p className="text-size-14-28 text-dark-1 font-rubik-medium">
                  Eine kleine Auswahl unserer Rezepte
                </p>

                <div className="nutrition-recipies-slider relative">
                  {!loading && (
                    <>
                      <S.NutritionFormRecipesButtonLeft
                        className={newDataIndex > 0 ? "active" : ""}
                        active={newDataIndex > 0}
                        onClick={() => {
                          if (newDataIndex >= 2)
                            setNewDataIndex(newDataIndex - 2);
                        }}
                      >
                        {/* <S.ArrowButton left active={newDataIndex > 0} />
                        <S.StraightLine active={newDataIndex > 0} /> */}

                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="feather feather-arrow-left-circle"
                        >
                          <circle cx="12" cy="12" r="10"></circle>
                          <polyline points="12 8 8 12 12 16"></polyline>
                          <line x1="16" y1="12" x2="8" y2="12"></line>
                        </svg>
                      </S.NutritionFormRecipesButtonLeft>
                      <S.NutritionFormRecipesButtonRight
                        className={
                          recipesData && newDataIndex < recipesData.length - 2
                            ? "active"
                            : ""
                        }
                        active={
                          recipesData && newDataIndex < recipesData.length - 2
                        }
                        onClick={() => {
                          if (newDataIndex + 2 < recipesData.length) {
                            setNewDataIndex(newDataIndex + 2);
                          }
                        }}
                      >
                        {/* <S.ArrowButton
                          right
                          active={
                            recipesData && newDataIndex < recipesData.length
                          }
                        />
                        <S.StraightLine
                          active={
                            recipesData && newDataIndex < recipesData.length
                          }
                        /> */}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="feather feather-arrow-right-circle"
                        >
                          <circle cx="12" cy="12" r="10"></circle>
                          <polyline points="12 16 16 12 12 8"></polyline>
                          <line x1="8" y1="12" x2="16" y2="12"></line>
                        </svg>
                      </S.NutritionFormRecipesButtonRight>
                    </>
                  )}

                  {!loading && EatingHabitsContent()}
                  {loading && (
                    <>
                      <br />
                      <br />
                      <br />
                      <br />
                      <div className="text-center">
                        <div className="loader mx-auto"></div>
                      </div>
                      <br />
                      <br />
                      <br />
                      <br />
                    </>
                  )}
                </div>
              </div>
            </S.OnBoardingBody>
          </S.OnBoardingContent>
        </S.OnBoardingContainer>
      </S.OnBoardingPage>
    </>
  );
};

export default NutritionRecipes;
