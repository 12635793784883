import React, {useEffect} from 'react';
import { useDispatch, useSelector } from "react-redux";
import * as S from "./formAdvantages.styles";
import TranslationMessage from 'configs/translations/TranslationMessage';

// Components
import Topbar from 'components/topbar/Topbar';
import Loader from "components/loader/Loader";

// Redux
import * as NutritionActions from 'redux/nutrition/nutrition.actions';

const FormAdvantages = () => {
    const dispatch = useDispatch();
    const loading = useSelector(state => state.auth.loading);
    const registrationData = useSelector(state => state.auth.registrationData);
    const advantagesData = useSelector(state => state.nutrition.nutritionAdvantages);

    useEffect(() => {
        dispatch(NutritionActions.getNutritionAdvantagesRequest({
            nutritiontypeID: registrationData.nutritiontypeID,
            aimID: registrationData.aimID,
            sex: registrationData.sex,
        })) 
    }, [dispatch, registrationData])

    const advantages = [
        {key:'advantages1', id:"1", advantage: advantagesData && advantagesData['01_advantage']},
        {key:'advantages2', id:"2", advantage: advantagesData && advantagesData['02_advantage']},
        {key:'advantages3', id:"3", advantage: advantagesData && advantagesData['03_advantage']},
    ]
    
    return (
        <div>
            <Topbar prev='/nutrition/nutritionForm' next='/nutrition/recipes' progress='33%'  />
            <S.NutritionFormAdvantagesContainer>
                <S.NutritionFormAdvantagesHeading><TranslationMessage id="nutrition.formAdvantages.heading" /></S.NutritionFormAdvantagesHeading>
                <S.NutritionFormAdvantagesSubHeading><TranslationMessage id="nutrition.formAdvantages.subHeading" /></S.NutritionFormAdvantagesSubHeading>
                <S.NutritionFormAdvantagesCardContainer>
                    {!loading && advantagesData && advantages.map(value => {
                        return (
                            <S.NutritionFormAdvantagesCard key={value.key}>
                                <S.NutritionFormAdvantagesCardInnerContainer>
                                    <S.NutritionFormAdvantagesPointNumber>{value.id}</S.NutritionFormAdvantagesPointNumber>
                                    <S.NutritionFormAdvantageData>{value.advantage}</S.NutritionFormAdvantageData>
                                </S.NutritionFormAdvantagesCardInnerContainer>
                            </S.NutritionFormAdvantagesCard>
                        )
                    })}
                    {<Loader loading={loading} top="150px" />}
                </S.NutritionFormAdvantagesCardContainer>
            </S.NutritionFormAdvantagesContainer>
        </div>
    )
}

export default FormAdvantages
