import styled from "styled-components";

export const SwitchWrapper = styled.span`
  position: relative;
  margin-left: 50px;
  margin-right: 10px;
`;

export const SwitchContainer = styled.span`
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0);

  .yes,
  .no {
    position: absolute;
    top: .3125rem;
    right: .4375rem;
    font-size: 12px;
  }

  .no {
    right: 1.5375rem;
    color: white;
  }
`;

export const CustomSwitch = styled.input.attrs({ type: "checkbox" })`
  width: ${(props) => (props.small ? "40px" : "40px")};
  height: ${(props) => (props.small ? "24px" : "24px")};
  left: 0px;
  position: absolute;
  opacity: 0;
  cursor: pointer;
  margin-left: ${(props) => (props.small ? "-40px" : "-40px")};

 

  & + div {
    vertical-align: middle;
    width: ${(props) => (props.small ? "40px" : "40px")};
    height: ${(props) => (props.small ? "24px" : "24px")};
    border-radius: 999px;
    background-color: ${(props) => (props.keepBackgroundSame ? "#2ecf96" : "#fff")};
    
    border: 1px solid #a5b5af;
    -webkit-transition-duration: 0.4s;
    transition-duration: 0.4s;
    -webkit-transition-property: background-color, box-shadow;
    transition-property: background-color, box-shadow;
    cursor: pointer;
    margin-left: -40px;
  }

  & + div span {
    position: absolute;
    font-size: 1.6rem;
    color: white;
    margin-top: 12px;
  }

  & + div span:nth-child(1) {
    margin-left: 15px;
  }

  & + div span:nth-child(2) {
    margin-left: 55px;
  }

  &:checked + div {
    width: ${(props) => (props.small ? "55px" : "55px")};
    background-position: 0 0;
    background-color: #3b89ec;
  }

  & + div {
    width: ${(props) => (props.small ? "55px" : "55px")};
    height: ${(props) => (props.small ? "24px" : "24px")};
  }

  &:checked + div {
    background-color: #2ecf96;
  }

  & + div > div {
    float: left;
    position: relative;
    top: 2px;
    left: 2px;
    width: ${(props) => (props.small ? "18px" : "18px")};
    height: ${(props) => (props.small ? "18px" : "18px")};
    border-radius: 1.25rem;
    background: #f5faf9;
    border: 1px solid #a5b5af;
    -webkit-transition-timing-function: cubic-bezier(1, 0, 0, 1);
    transition-timing-function: cubic-bezier(1, 0, 0, 1);
    -webkit-transition-duration: 0.4s;
    transition-duration: 0.4s;
    -webkit-transition-property: transform, background-color;
    transition-property: transform, background-color;
    pointer-events: none;
  }

  &:checked + div > div {
    -webkit-transform: translate3d(40px, 0, 0);
    transform: translate3d(40px, 0, 0);
    background-color: #ffffff;
    border-color: #ffffff;
    left: auto;
    right: -1rem;
  }

  &:checked + div > div {
    -webkit-transform: translate3d(17px, 0, 0);
    transform: translate3d(17px, 0, 0);
  }
`;
