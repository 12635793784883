import styled from "styled-components";

const color1 = "#2ECF96";
const color2 = "#FFC400";
const color3 = "#C74343";
const color4 = "blue";
const color5 = "orange";

export const DonutChartContainer = styled.div`
  width: max-content;
`;

export const DonutChartTitle = styled.div`
  color: #142c1f;
  font-size: 0.875rem;
  line-height: 1.5rem;
  font-weight: 500;
  margin-bottom: 0.625rem;
  text-align: center;
`;

export const ChartBox = styled.div`
  width: max-content;
`;

export const DonutChartInnerContainer = styled.div`
  /* border: 1px solid #dddddd; */
  border-radius: 8px;
  /* padding: 1rem 1.5rem; */
  position: relative;
  width: fit-content;

  .label {
    background-color: red;
    width: fit-content;
  }

  svg {
    width: 230px;
    /* background-color: #f8f8f8; */
    border-radius: 50%;
    padding: 1rem;
  }
`;

export const DonutChartSegment = styled.path`
  &:nth-child(5n + 1) {
    fill: ${color1};
  }
  &:nth-child(5n + 2) {
    fill: ${color2};
  }
  &:nth-child(5n + 3) {
    fill: ${color3};
  }
  &:nth-child(5n + 4) {
    fill: ${color4};
  }
  &:nth-child(5n + 5) {
    fill: ${color5};
  }
`;

export const DonutChartLegend = styled.div`
  list-style: none;
  /* padding: 1rem 1.5rem; */
  margin: 30px 0 0 0;
  display: flex;
  max-width: 230px;

  & > div {
    margin-bottom: 0.5em;
    // margin-right: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;

    & > span {
      margin-right: 2rem;
      font-size: 16px;
      line-height: 19px;
      font-weight: 400;
    }

    &:before {
      content: "";
      display: inline-block;
      width: 1em;
      height: 1em;
      margin-right: 0.5em;
      vertical-align: middle;
      border-radius: 50%;
    }
    &:nth-child(5n + 1)::before {
      background: ${color1};
    }
    &:nth-child(5n + 2)::before {
      background: ${color2};
    }
    &:nth-child(5n + 3)::before {
      background: ${color3};
    }
    &:nth-child(5n + 4)::before {
      background: ${color4};
    }
    &:nth-child(5n + 5)::before {
      background: ${color5};
    }
  }
`;

export const DonutChartLegendVertical = styled.div`
  list-style: none;
  /* padding: 1rem 1.5rem; */
  margin: 30px 0 0 0;
  display: flex;
  flex-direction: column;

  & > div {
    margin-bottom: 0.5em;
    // margin-right: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;

    & > span {
      margin-right: 2rem;
      font-size: 16px;
      line-height: 19px;
      font-weight: 400;
    }

    &:before {
      content: "";
      display: inline-block;
      width: 1em;
      height: 1em;
      margin-right: 0.5em;
      vertical-align: middle;
      border-radius: 50%;
    }
    &:nth-child(5n + 1)::before {
      background: ${color1};
    }
    &:nth-child(5n + 2)::before {
      background: ${color2};
    }
    &:nth-child(5n + 3)::before {
      background: ${color3};
    }
    &:nth-child(5n + 4)::before {
      background: ${color4};
    }
    &:nth-child(5n + 5)::before {
      background: ${color5};
    }
  }
`;
