export const MENUS = [
  {
    id: 1,
    title: "Werbeassets",
    path: "/dashboard/marketing-portal/werbeassets",
  },
  {
    id: 2,
    title: "Druckdaten erstellen",
    path: "/dashboard/marketing-portal/print-data",
  },
  {
    id: 3,
    title: "Partnerschaften",
    path: "/dashboard/marketing-portal/partners",
  },
  // {
  //   id: 4,
  //   title: "Roadmap",
  //   path: "/dashboard/support/roadmap",
  //   url: 'https://wa.me/1741953597'
  // },
];
