import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  /* grid-template-columns: 300px 1fr; */
`;

export const Wrapper = styled.div`
  padding: 1.5rem;
`;

export const Button = styled.div`
  font-family: RubikMedium;
  transition: all 0.3s;
  cursor: pointer;
  color: #111614;
`;

export const InlineControls = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
`;

export const InlineThreeControls = styled.div`
  display: grid;
  grid-template-columns: 7.5rem 1fr 1fr;
  gap: 1rem;
`;

export const FormControl = styled.div`
  position: relative;

  img {
    cursor: pointer;
    position: absolute;
    bottom: 0;
    right: 12px;
    transform: translateY(-23px);
  }
`;

export const TrainerProfileDetailsSectionHeading = styled.div`
  font-size: 20px;
  color: #142c1f;
  margin: 0;
  margin-bottom: 5px;
  line-height: 24px;
`;

export const TrainerProfileDetailsSectionSubHeading = styled.div`
  font-size: 16px;
  color: #818d88;
  font-weight: 300;
  margin: 1.2rem 0 2.5rem;
  line-height: 19px;
`;

export const Divider = styled.div`
  height: 1px;
  background-color: #e2e4e9;
`;

export const SectionTitle = styled.div`
  color: #142c1f;
  font-weight: 500;
  line-height: 24px;
  font-size: 16px;
  margin-bottom: 10px;
`;

export const TrainerProfileDetailsInnerContainer = styled.div`
  padding: 1.5rem;
  border-radius: 10px;
  height: 100%;
  background-color: #fff;
`;

export const TrainerProfileDetailsSection1InputLabel = styled.div`
  font-size: 0.875rem;
  color: #44554c;
  line-height: 1.5rem;
  margin: 0 0 10px;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;

  textarea {
    border: 1px solid #dddddd;
    border-radius: 4px;
    width: 100% !important;
    background-color: transparent;
  }

  img {
    position: relative;
  }
`;

export const ToggleSwitchWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  border-radius: 4px;
  height: 83px;
  justify-content: space-between;
  position: relative;
  &.app-sales {
  }
  &.nonClickable {
    /* opacity: 0.5; 
    pointer-events: none; */
  }
  &.disabled {
    /* opacity: 0.5; 
    pointer-events: none;  */
  }
  .loader_main {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .loader-settings {
    width: 48px;
    height: 48px;
    border: 5px solid green;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const ToggleSwitch = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  flex-direction: row-reverse;
  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #2ecf96;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: #2ecf96;
  }

  &.yellow {
    input:checked + .slider {
      background-color: #eb8b4e;
    }
  }
  &.red {
    input:checked + .slider {
      background-color: #ff0000;
    }
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #2ecf96;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  .disabled {
    .slider:before {
      background-color: #a3a3a3 !important;
    }

    input:checked + .slider {
      background-color: #e2e2e2 !important;
    }
  }

  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
`;

export const ToggleSwitchLabelOne = styled.div`
  color: #111614;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-family: RubikMedium;

  > * {
    margin-bottom: 10px;
  }

  span {
    color: #818D88;
    font-size: .9rem;
  }

  ${({ active }) =>
    active &&
    `
      color: #2ecf96;
      font-weight: 500;
    `}
`;

export const ToggleSwitchLabelTwo = styled.div`
  color: #a5b5af;
  font-size: 16px;
  display: flex;
  flex-direction: row-reverse;
  font-size: 16px;

  ${({ active }) =>
    active &&
    `
      color: #44554C;
      font-weight: 500;
      margin-right:.7rem;
    `}
`;
