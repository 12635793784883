import NutritionActionTypes from "./nutrition.types";

// Get Nutrition Type
export const getUserDataRequest = (payload) => ({
  type: NutritionActionTypes.GET_USER_DATA_REQUEST,
  payload,
});

export const getUserDataSuccess = (payload) => ({
  type: NutritionActionTypes.GET_USER_DATA_SUCCESS,
  payload,
});

export const getUserDataFailure = (error) => ({
  type: NutritionActionTypes.GET_USER_DATA_FAILURE,
  payload: error,
});

// Get Nutrition Type
export const getNutritionTypeRequest = (payload) => ({
  type: NutritionActionTypes.GET_NUTRITION_TYPE_REQUEST,
  payload: payload,
});

export const getNutritionTypeSuccess = (response) => {
  return {
    type: NutritionActionTypes.GET_NUTRITION_TYPE_SUCCESS,
    payload: response,
  };
};

export const getNutritionTypeFailure = (error) => ({
  type: NutritionActionTypes.GET_NUTRITION_TYPE_FAILURE,
  payload: error,
});

// Get All Nutrition Types
export const getAllNutritionTypesRequest = (payload) => ({
  type: NutritionActionTypes.GET_ALL_NUTRITION_TYPES_REQUEST,
  payload: payload,
});

export const getAllNutritionTypesSuccess = (response) => {
  return {
    type: NutritionActionTypes.GET_ALL_NUTRITION_TYPES_SUCCESS,
    payload: response,
  };
};

export const getAllNutritionTypesFailure = (error) => ({
  type: NutritionActionTypes.GET_ALL_NUTRITION_TYPES_FAILURE,
  payload: error,
});

// Update Nutrition Type
export const updateNutritionTypeRequest = (payload) => ({
  type: NutritionActionTypes.UPDATE_NUTRITION_TYPE_REQUEST,
  payload: payload,
});

export const updateNutritionTypeSuccess = (response) => {
  return {
    type: NutritionActionTypes.UPDATE_NUTRITION_TYPE_SUCCESS,
    payload: response,
  };
};

export const updateNutritionTypeFailure = (error) => ({
  type: NutritionActionTypes.UPDATE_NUTRITION_TYPE_FAILURE,
  payload: error,
});

// Update Nutrition Type
export const updateNutritionCaloriesRequest = (payload) => ({
  type: NutritionActionTypes.UPDATE_NUTRITION_CALORIES_REQUEST,
  payload: payload,
});

export const updateNutritionCaloriesSuccess = (response) => {
  return {
    type: NutritionActionTypes.UPDATE_NUTRITION_CALORIES_SUCCESS,
    payload: response,
  };
};

export const updateNutritionCaloriesFailure = (error) => ({
  type: NutritionActionTypes.UPDATE_NUTRITION_CALORIES_FAILURE,
  payload: error,
});

// Get Nutrition Advantages
export const getNutritionAdvantagesRequest = (payload) => ({
  type: NutritionActionTypes.GET_NUTRITION_ADVANTAGES_REQUEST,
  payload: payload,
});

export const getNutritionAdvantagesSuccess = (response) => {
  return {
    type: NutritionActionTypes.GET_NUTRITION_ADVANTAGES_SUCCESS,
    payload: response,
  };
};

export const getNutritionAdvantagesFailure = (error) => ({
  type: NutritionActionTypes.GET_NUTRITION_ADVANTAGES_FAILURE,
  payload: error,
});

// Get Nutrition Recipes
export const getNutritionRecipesRequest = (payload) => ({
  type: NutritionActionTypes.GET_NUTRITION_RECIPES_REQUEST,
  payload: payload,
});

export const getNutritionRecipesSuccess = (response) => {
  return {
    type: NutritionActionTypes.GET_NUTRITION_RECIPES_SUCCESS,
    payload: response,
  };
};

export const getNutritionRecipesFailure = (error) => ({
  type: NutritionActionTypes.GET_NUTRITION_RECIPES_FAILURE,
  payload: error,
});

// Get Week Wise Calories Data
export const getWeekWiseCaloriesRequest = (payload) => ({
  type: NutritionActionTypes.GET_WEEK_WISE_CALORIES_REQUEST,
  payload: payload,
});

export const getWeekWiseCaloriesSuccess = (response) => {
  return {
    type: NutritionActionTypes.GET_WEEK_WISE_CALORIES_SUCCESS,
    payload: response,
  };
};

export const getWeekWiseCaloriesFailure = (error) => ({
  type: NutritionActionTypes.GET_WEEK_WISE_CALORIES_FAILURE,
  payload: error,
});

// Get All Calories Data
export const getAllCaloriesRequest = (payload) => ({
  type: NutritionActionTypes.GET_ALL_CALORIES_REQUEST,
  payload: payload,
});

export const getAllCaloriesSuccess = (response) => {
  return {
    type: NutritionActionTypes.GET_ALL_CALORIES_SUCCESS,
    payload: response,
  };
};

export const getAllCaloriesFailure = (error) => ({
  type: NutritionActionTypes.GET_ALL_CALORIES_FAILURE,
  payload: error,
});

// Get Macro Nutrients Donut Data
export const getMacroNutrientsDonutRequest = (payload) => ({
  type: NutritionActionTypes.GET_MACRO_NUTRIENTS_DONUT_REQUEST,
  payload: payload,
});

export const getMacroNutrientsDonutSuccess = (response) => {
  return {
    type: NutritionActionTypes.GET_MACRO_NUTRIENTS_DONUT_SUCCESS,
    payload: response,
  };
};

export const getMacroNutrientsDonutFailure = (error) => ({
  type: NutritionActionTypes.GET_MACRO_NUTRIENTS_DONUT_FAILURE,
  payload: error,
});

// ------------------------

export const getMacroNutrientsPieChartDataRequest = (payload) => ({
  type: NutritionActionTypes.GET_MACRO_NUTRIENTS_PIE_CHART_DATA_REQUEST,
  payload: payload,
});

export const getMacroNutrientsPieChartDataSuccess = (response) => {
  return {
    type: NutritionActionTypes.GET_MACRO_NUTRIENTS_PIE_CHART_DATA_SUCCESS,
    payload: response,
  };
};

export const getMacroNutrientsPieChartDataFailure = (error) => ({
  type: NutritionActionTypes.GET_MACRO_NUTRIENTS_PIE_CHART_DATA_FAILURE,
  payload: error,
});

export const getMacroNutrientsPieChartDataLoadingStart = () => ({
  type: NutritionActionTypes.GET_MACRO_NUTRIENTS_PIE_CHART_DATA_LOADING_START,
});

export const getMacroNutrientsPieChartDataLoadingStop = () => ({
  type: NutritionActionTypes.GET_MACRO_NUTRIENTS_PIE_CHART_DATA_LOADING_STOP,
});

// Pie Charts Data
export const getNutritionTypePercentageChartDataRequest = (payload) => ({
  type: NutritionActionTypes.GET_NUTRITION_TYPE_PERCENTAGE_CHART_DATA_REQUEST,
  payload,
});

export const getNutritionTypePercentageChartDataSuccess = (payload) => ({
  type: NutritionActionTypes.GET_NUTRITION_TYPE_PERCENTAGE_CHART_DATA_SUCCESS,
  payload,
});

export const getNutritionTypePercentageChartDataFailure = (error) => ({
  type: NutritionActionTypes.GET_NUTRITION_TYPE_PERCENTAGE_CHART_DATA_FAILURE,
  payload: error,
});

// Reset Password
export const resetPasswordRequest = (payload) => ({
  type: NutritionActionTypes.RESET_PASSWORD_REQUEST,
  payload,
});

export const resetPasswordSuccess = (payload) => ({
  type: NutritionActionTypes.RESET_PASSWORD_SUCCESS,
  payload,
});

export const resetPasswordFailure = (error) => ({
  type: NutritionActionTypes.RESET_PASSWORD_FAILURE,
  payload: error,
});

export const resetPasswordNull = (error) => ({
  type: NutritionActionTypes.RESET_PASSWORD_NULL,
  payload: error,
});

export const resetPasswordLoadingStart = () => ({
  type: NutritionActionTypes.RESET_PASSWORD_LOADING_START,
});

export const resetPasswordLoadingStop = () => ({
  type: NutritionActionTypes.RESET_PASSWORD_LOADING_STOP,
});

// Set Password
export const setPasswordRequest = (payload) => ({
  type: NutritionActionTypes.SET_PASSWORD_REQUEST,
  payload,
});

export const setPasswordSuccess = (payload) => ({
  type: NutritionActionTypes.SET_PASSWORD_SUCCESS,
  payload,
});

export const setPasswordFailure = (error) => ({
  type: NutritionActionTypes.SET_PASSWORD_FAILURE,
  payload: error,
});

export const setPasswordNull = (error) => ({
  type: NutritionActionTypes.SET_PASSWORD_NULL,
  payload: error,
});

export const setPasswordLoadingStart = () => ({
  type: NutritionActionTypes.SET_PASSWORD_LOADING_START,
});

export const setPasswordLoadingStop = () => ({
  type: NutritionActionTypes.SET_PASSWORD_LOADING_STOP,
});

// Course Change Password
export const courseChangePasswordRequest = (payload) => ({
  type: NutritionActionTypes.COURSE_CHANGE_PASSWORD_REQUEST,
  payload,
});

export const courseChangePasswordSuccess = (payload) => ({
  type: NutritionActionTypes.COURSE_CHANGE_PASSWORD_SUCCESS,
  payload,
});

export const courseChangePasswordFailure = (error) => ({
  type: NutritionActionTypes.COURSE_CHANGE_PASSWORD_FAILURE,
  payload: error,
});

export const courseChangePasswordNull = (error) => ({
  type: NutritionActionTypes.COURSE_CHANGE_PASSWORD_NULL,
  payload: error,
});

export const courseChangePasswordLoadingStart = () => ({
  type: NutritionActionTypes.COURSE_CHANGE_PASSWORD_LOADING_START,
});

export const courseChangePasswordLoadingStop = () => ({
  type: NutritionActionTypes.COURSE_CHANGE_PASSWORD_LOADING_STOP,
});

// FAQ Questions
export const getFaqQuestionsRequest = (payload) => ({
  type: NutritionActionTypes.GET_FAQ_QUESTIONS_REQUEST,
  payload,
});

export const getFaqQuestionsSuccess = (payload) => ({
  type: NutritionActionTypes.GET_FAQ_QUESTIONS_SUCCESS,
  payload,
});

export const getFaqQuestionsFailure = (error) => ({
  type: NutritionActionTypes.GET_FAQ_QUESTIONS_FAILURE,
  payload: error,
});

export const getFaqQuestionsLoadingStart = () => ({
  type: NutritionActionTypes.GET_FAQ_QUESTIONS_LOADING_START,
});

export const getFaqQuestionsLoadingStop = () => ({
  type: NutritionActionTypes.GET_FAQ_QUESTIONS_LOADING_STOP,
});

// FAQ By ID Questions
export const getFaqQuestionsByTopicIdRequest = (payload) => ({
  type: NutritionActionTypes.GET_FAQ_QUESTIONS_BY_ID_REQUEST,
  payload,
});

export const getFaqQuestionsByTopicIdSuccess = (payload) => ({
  type: NutritionActionTypes.GET_FAQ_QUESTIONS_BY_ID_SUCCESS,
  payload,
});

export const getFaqQuestionsByTopicIdFailure = (error) => ({
  type: NutritionActionTypes.GET_FAQ_QUESTIONS_BY_ID_FAILURE,
  payload: error,
});

export const getFaqQuestionsByTopicIdLoadingStart = () => ({
  type: NutritionActionTypes.GET_FAQ_QUESTIONS_BY_ID_LOADING_START,
});

export const getFaqQuestionsByTopicIdLoadingStop = () => ({
  type: NutritionActionTypes.GET_FAQ_QUESTIONS_BY_ID_LOADING_STOP,
});

// Nutrition COurse details by id
export const getNutritionCourseDetailsByIdRequest = (payload) => ({
  type: NutritionActionTypes.GET_NUTRITION_COURSE_DETAILS_BY_ID_REQUEST,
  payload,
});

export const getNutritionCourseDetailsByIdSuccess = (payload) => ({
  type: NutritionActionTypes.GET_NUTRITION_COURSE_DETAILS_BY_ID_SUCCESS,
  payload,
});

export const getNutritionCourseDetailsByIdFailure = (error) => ({
  type: NutritionActionTypes.GET_NUTRITION_COURSE_DETAILS_BY_ID_FAILURE,
  payload: error,
});

export const getNutritionCourseDetailsByIdLoadingStart = () => ({
  type: NutritionActionTypes.GET_NUTRITION_COURSE_DETAILS_BY_ID_LOADING_START,
});

export const getNutritionCourseDetailsByIdLoadingStop = () => ({
  type: NutritionActionTypes.GET_NUTRITION_COURSE_DETAILS_BY_ID_LOADING_STOP,
});

// Update Nutrition COurse details
export const updateNutritionCourseDetailsRequest = (payload) => ({
  type: NutritionActionTypes.UPDATE_NUTRITION_COURSE_DETAILS_REQUEST,
  payload,
});

export const updateNutritionCourseDetailsSuccess = (payload) => ({
  type: NutritionActionTypes.UPDATE_NUTRITION_COURSE_DETAILS_SUCCESS,
  payload,
});

export const updateNutritionCourseDetailsFailure = (error) => ({
  type: NutritionActionTypes.UPDATE_NUTRITION_COURSE_DETAILS_FAILURE,
  payload: error,
});

export const updateNutritionCourseDetailsLoadingStart = () => ({
  type: NutritionActionTypes.UPDATE_NUTRITION_COURSE_DETAILS_LOADING_START,
});

export const updateNutritionCourseDetailsLoadingStop = () => ({
  type: NutritionActionTypes.UPDATE_NUTRITION_COURSE_DETAILS_LOADING_STOP,
});

// Update or Delete Nutrition COurse details
export const updateOrDeleteNutritionCourseDetailsRequest = (payload) => ({
  type: NutritionActionTypes.UPDATE_OR_DELETE_NUTRITION_COURSE_DETAILS_REQUEST,
  payload,
});

export const updateOrDeleteNutritionCourseDetailsSuccess = (payload) => ({
  type: NutritionActionTypes.UPDATE_OR_DELETE_NUTRITION_COURSE_DETAILS_SUCCESS,
  payload,
});

export const updateOrDeleteNutritionCourseDetailsFailure = (error) => ({
  type: NutritionActionTypes.UPDATE_OR_DELETE_NUTRITION_COURSE_DETAILS_FAILURE,
  payload: error,
});

export const updateOrDeleteNutritionCourseDetailsLoadingStart = () => ({
  type: NutritionActionTypes.UPDATE_OR_DELETE_NUTRITION_COURSE_DETAILS_LOADING_START,
});

export const updateOrDeleteNutritionCourseDetailsLoadingStop = () => ({
  type: NutritionActionTypes.UPDATE_OR_DELETE_NUTRITION_COURSE_DETAILS_LOADING_STOP,
});

// Check user id email
export const checkUserIdEmailRequest = (payload) => ({
  type: NutritionActionTypes.CHECK_USERID_EMAIL_REQUEST,
  payload,
});

export const checkUserIdEmailSuccess = (payload) => ({
  type: NutritionActionTypes.CHECK_USERID_EMAIL_SUCCESS,
  payload,
});

export const checkUserIdEmailFailure = (error) => ({
  type: NutritionActionTypes.CHECK_USERID_EMAIL_FAILURE,
  payload: error,
});

export const checkUserIdEmailLoadingStart = () => ({
  type: NutritionActionTypes.CHECK_USERID_EMAIL_LOADING_START,
});

export const checkUserIdEmailLoadingStop = () => ({
  type: NutritionActionTypes.CHECK_USERID_EMAIL_LOADING_STOP,
});

// Marketing Portal Assets
export const getMarketingPortalAssetsRequest = (payload) => ({
  type: NutritionActionTypes.MARKETING_PORTAL_ASSETS_REQUEST,
  payload,
});

export const getMarketingPortalAssetsSuccess = (payload) => ({
  type: NutritionActionTypes.MARKETING_PORTAL_ASSETS_SUCCESS,
  payload,
});

export const getMarketingPortalAssetsFailure = (error) => ({
  type: NutritionActionTypes.MARKETING_PORTAL_ASSETS_FAILURE,
  payload: error,
});

export const getMarketingPortalAssetsLoadingStart = () => ({
  type: NutritionActionTypes.MARKETING_PORTAL_ASSETS_LOADING_START,
});

export const getMarketingPortalAssetsLoadingStop = () => ({
  type: NutritionActionTypes.MARKETING_PORTAL_ASSETS_LOADING_STOP,
});

// Marketing Portal Prints
export const getMarketingPortalPrintsRequest = (payload) => ({
  type: NutritionActionTypes.MARKETING_PORTAL_PRINTS_REQUEST,
  payload,
});

export const getMarketingPortalPrintsSuccess = (payload) => ({
  type: NutritionActionTypes.MARKETING_PORTAL_PRINTS_SUCCESS,
  payload,
});

export const getMarketingPortalPrintsFailure = (error) => ({
  type: NutritionActionTypes.MARKETING_PORTAL_PRINTS_FAILURE,
  payload: error,
});

export const getMarketingPortalPrintsLoadingStart = () => ({
  type: NutritionActionTypes.MARKETING_PORTAL_PRINTS_LOADING_START,
});

export const getMarketingPortalPrintsLoadingStop = () => ({
  type: NutritionActionTypes.MARKETING_PORTAL_PRINTS_LOADING_STOP,
});

// Marketing Portal Prints Details
export const getMarketingPortalPrintsDetailsRequest = (payload) => ({
  type: NutritionActionTypes.MARKETING_PORTAL_PRINTS_DETAILS_REQUEST,
  payload,
});

export const getMarketingPortalPrintsDetailsSuccess = (payload) => ({
  type: NutritionActionTypes.MARKETING_PORTAL_PRINTS_DETAILS_SUCCESS,
  payload,
});

export const getMarketingPortalPrintsDetailsFailure = (error) => ({
  type: NutritionActionTypes.MARKETING_PORTAL_PRINTS_DETAILS_FAILURE,
  payload: error,
});

export const getMarketingPortalPrintsDetailsLoadingStart = () => ({
  type: NutritionActionTypes.MARKETING_PORTAL_PRINTS_DETAILS_LOADING_START,
});

export const getMarketingPortalPrintsDetailsLoadingStop = () => ({
  type: NutritionActionTypes.MARKETING_PORTAL_PRINTS_DETAILS_LOADING_STOP,
});

// Marketing Portal Partners

export const getMarketingPortalPartnersRequest = (payload) => ({
  type: NutritionActionTypes.MARKETING_PORTAL_PARTNERS_REQUEST,
  payload,
});

export const getMarketingPortalPartnersSuccess = (payload) => ({
  type: NutritionActionTypes.MARKETING_PORTAL_PARTNERS_SUCCESS,
  payload,
});

export const getMarketingPortalPartnersFailure = (error) => ({
  type: NutritionActionTypes.MARKETING_PORTAL_PARTNERS_FAILURE,
  payload: error,
});

export const getMarketingPortalPartnersLoadingStart = () => ({
  type: NutritionActionTypes.MARKETING_PORTAL_PARTNERS_LOADING_START,
});

export const getMarketingPortalPartnersLoadingStop = () => ({
  type: NutritionActionTypes.MARKETING_PORTAL_PARTNERS_LOADING_STOP,
});

// Marketing Portal Partner Details

export const getMarketingPortalPartnerDetailsRequest = (payload) => ({
  type: NutritionActionTypes.MARKETING_PORTAL_PARTNER_DETAILS_REQUEST,
  payload,
});

export const getMarketingPortalPartnerDetailsSuccess = (payload) => ({
  type: NutritionActionTypes.MARKETING_PORTAL_PARTNER_DETAILS_SUCCESS,
  payload,
});

export const getMarketingPortalPartnerDetailsFailure = (error) => ({
  type: NutritionActionTypes.MARKETING_PORTAL_PARTNER_DETAILS_FAILURE,
  payload: error,
});

export const getMarketingPortalPartnerDetailsLoadingStart = () => ({
  type: NutritionActionTypes.MARKETING_PORTAL_PARTNER_DETAILS_LOADING_START,
});

export const getMarketingPortalPartnerDetailsLoadingStop = () => ({
  type: NutritionActionTypes.MARKETING_PORTAL_PARTNER_DETAILS_LOADING_STOP,
});

// Marketing Portal Partner and print Details

export const getMarketingPortalPartnerAndPrintDetailsRequest = (payload) => ({
  type: NutritionActionTypes.MARKETING_PORTAL_PARTNER_AND_PRINT_DETAILS_REQUEST,
  payload,
});

export const getMarketingPortalPartnerAndPrintDetailsSuccess = (payload) => ({
  type: NutritionActionTypes.MARKETING_PORTAL_PARTNER_AND_PRINT_DETAILS_SUCCESS,
  payload,
});

export const getMarketingPortalPartnerAndPrintDetailsFailure = (error) => ({
  type: NutritionActionTypes.MARKETING_PORTAL_PARTNER_AND_PRINT_DETAILS_FAILURE,
  payload: error,
});

export const getMarketingPortalPartnerAndPrintDetailsLoadingStart = () => ({
  type: NutritionActionTypes.MARKETING_PORTAL_PARTNER_AND_PRINT_DETAILS_LOADING_START,
});

export const getMarketingPortalPartnerAndPrintDetailsLoadingStop = () => ({
  type: NutritionActionTypes.MARKETING_PORTAL_PARTNER_AND_PRINT_DETAILS_LOADING_STOP,
});

// Marketing Portal Create Affiliate

export const marketingPortalCreateAffiliateRequest = (payload) => ({
  type: NutritionActionTypes.MARKETING_PORTAL_CREATE_AFFILIATE_REQUEST,
  payload,
});

export const marketingPortalCreateAffiliateSuccess = (payload) => ({
  type: NutritionActionTypes.MARKETING_PORTAL_CREATE_AFFILIATE_SUCCESS,
  payload,
});

export const marketingPortalCreateAffiliateFailure = (error) => ({
  type: NutritionActionTypes.MARKETING_PORTAL_CREATE_AFFILIATE_FAILURE,
  payload: error,
});

export const marketingPortalCreateAffiliateLoadingStart = () => ({
  type: NutritionActionTypes.MARKETING_PORTAL_CREATE_AFFILIATE_LOADING_START,
});

export const marketingPortalCreateAffiliateLoadingStop = () => ({
  type: NutritionActionTypes.MARKETING_PORTAL_CREATE_AFFILIATE_LOADING_STOP,
});

// Marketing Portal Add Affiliate

export const marketingPortalAddAffiliateRequest = (payload) => ({
  type: NutritionActionTypes.MARKETING_PORTAL_ADD_AFFILIATE_PROGRAM_REQUEST,
  payload,
});

export const marketingPortalAddAffiliateSuccess = (payload) => ({
  type: NutritionActionTypes.MARKETING_PORTAL_ADD_AFFILIATE_PROGRAM_SUCCESS,
  payload,
});

export const marketingPortalAddAffiliateFailure = (error) => ({
  type: NutritionActionTypes.MARKETING_PORTAL_ADD_AFFILIATE_PROGRAM_FAILURE,
  payload: error,
});

export const marketingPortalAddAffiliateLoadingStart = () => ({
  type: NutritionActionTypes.MARKETING_PORTAL_ADD_AFFILIATE_PROGRAM_LOADING_START,
});

export const marketingPortalAddAffiliateLoadingStop = () => ({
  type: NutritionActionTypes.MARKETING_PORTAL_ADD_AFFILIATE_PROGRAM_LOADING_STOP,
});

// Marketing Portal Create QR Code

export const createQrCodeRequest = (payload) => ({
  type: NutritionActionTypes.CREATE_QR_CODE_REQUEST,
  payload,
});

export const createQrCodeSuccess = (payload) => ({
  type: NutritionActionTypes.CREATE_QR_CODE_SUCCESS,
  payload,
});

export const createQrCodeFailure = (error) => ({
  type: NutritionActionTypes.CREATE_QR_CODE_FAILURE,
  payload: error,
});

export const createQrCodeLoadingStart = () => ({
  type: NutritionActionTypes.CREATE_QR_CODE_LOADING_START,
});

export const createQrCodeLoadingStop = () => ({
  type: NutritionActionTypes.CREATE_QR_CODE_LOADING_STOP,
});

// Marketing Portal Create Partner

export const marketingPortalCreatePartnerRequest = (payload) => ({
  type: NutritionActionTypes.MARKETING_PORTAL_CREATE_PARTNER_REQUEST,
  payload,
});

export const marketingPortalCreatePartnerSuccess = (payload) => ({
  type: NutritionActionTypes.MARKETING_PORTAL_CREATE_PARTNER_SUCCESS,
  payload,
});

export const marketingPortalCreatePartnerFailure = (error) => ({
  type: NutritionActionTypes.MARKETING_PORTAL_CREATE_PARTNER_FAILURE,
  payload: error,
});

export const marketingPortalCreatePartnerLoadingStart = () => ({
  type: NutritionActionTypes.MARKETING_PORTAL_CREATE_PARTNER_LOADING_START,
});

export const marketingPortalCreatePartnerLoadingStop = () => ({
  type: NutritionActionTypes.MARKETING_PORTAL_CREATE_PARTNER_LOADING_STOP,
});

// Marketing Portal Coach Details

export const marketingPortalCoachDetailsRequest = (payload) => ({
  type: NutritionActionTypes.MARKETING_PORTAL_COACH_DETAILS_REQUEST,
  payload,
});

export const marketingPortalCoachDetailsSuccess = (payload) => ({
  type: NutritionActionTypes.MARKETING_PORTAL_COACH_DETAILS_SUCCESS,
  payload,
});

export const marketingPortalCoachDetailsFailure = (error) => ({
  type: NutritionActionTypes.MARKETING_PORTAL_COACH_DETAILS_FAILURE,
  payload: error,
});

export const marketingPortalCoachDetailsLoadingStart = () => ({
  type: NutritionActionTypes.MARKETING_PORTAL_COACH_DETAILS_LOADING_START,
});

export const marketingPortalCoachDetailsLoadingStop = () => ({
  type: NutritionActionTypes.MARKETING_PORTAL_COACH_DETAILS_LOADING_STOP,
});

// Marketing Portal Print Pic

export const marketingPortalPrintPicRequest = (payload) => ({
  type: NutritionActionTypes.MARKETING_PORTAL_PRINT_PIC_REQUEST,
  payload,
});

export const marketingPortalPrintPicSuccess = (payload) => ({
  type: NutritionActionTypes.MARKETING_PORTAL_PRINT_PIC_SUCCESS,
  payload,
});

export const marketingPortalPrintPicFailure = (error) => ({
  type: NutritionActionTypes.MARKETING_PORTAL_PRINT_PIC_FAILURE,
  payload: error,
});

export const marketingPortalPrintPicLoadingStart = () => ({
  type: NutritionActionTypes.MARKETING_PORTAL_PRINT_PIC_LOADING_START,
});

export const marketingPortalPrintPicLoadingStop = () => ({
  type: NutritionActionTypes.MARKETING_PORTAL_PRINT_PIC_LOADING_STOP,
});

// Marketing Portal Raw Url by print id

export const marketingPortalRawUrlByPrintIdRequest = (payload) => ({
  type: NutritionActionTypes.MARKETING_PORTAL_RAW_URL_BY_PRINT_ID_REQUEST,
  payload,
});

export const marketingPortalRawUrlByPrintIdSuccess = (payload) => ({
  type: NutritionActionTypes.MARKETING_PORTAL_RAW_URL_BY_PRINT_ID_SUCCESS,
  payload,
});

export const marketingPortalRawUrlByPrintIdFailure = (error) => ({
  type: NutritionActionTypes.MARKETING_PORTAL_RAW_URL_BY_PRINT_ID_FAILURE,
  payload: error,
});

export const marketingPortalRawUrlByPrintIdLoadingStart = () => ({
  type: NutritionActionTypes.MARKETING_PORTAL_RAW_URL_BY_PRINT_ID_LOADING_START,
});

export const marketingPortalRawUrlByPrintIdLoadingStop = () => ({
  type: NutritionActionTypes.MARKETING_PORTAL_RAW_URL_BY_PRINT_ID_LOADING_STOP,
});

// Marketing Portal Print placement details

export const marketingPortalPrintPlacementDetailsRequest = (payload) => ({
  type: NutritionActionTypes.MARKETING_PORTAL_PRINT_PLACEMENT_DETAILS_REQUEST,
  payload,
});

export const marketingPortalPrintPlacementDetailsSuccess = (payload) => ({
  type: NutritionActionTypes.MARKETING_PORTAL_PRINT_PLACEMENT_DETAILS_SUCCESS,
  payload,
});

export const marketingPortalPrintPlacementDetailsFailure = (error) => ({
  type: NutritionActionTypes.MARKETING_PORTAL_PRINT_PLACEMENT_DETAILS_FAILURE,
  payload: error,
});

export const marketingPortalPrintPlacementDetailsLoadingStart = () => ({
  type: NutritionActionTypes.MARKETING_PORTAL_PRINT_PLACEMENT_DETAILS_LOADING_START,
});

export const marketingPortalPrintPlacementDetailsLoadingStop = () => ({
  type: NutritionActionTypes.MARKETING_PORTAL_PRINT_PLACEMENT_DETAILS_LOADING_STOP,
});

// Marketing Portal Print placement details

export const marketingPortalCreatePrintRequest = (payload) => ({
  type: NutritionActionTypes.MARKETING_PORTAL_CREATE_PRINT_REQUEST,
  payload,
});

export const marketingPortalCreatePrintSuccess = (payload) => ({
  type: NutritionActionTypes.MARKETING_PORTAL_CREATE_PRINT_SUCCESS,
  payload,
});

export const marketingPortalCreatePrintFailure = (error) => ({
  type: NutritionActionTypes.MARKETING_PORTAL_CREATE_PRINT_FAILURE,
  payload: error,
});

export const marketingPortalCreatePrintLoadingStart = () => ({
  type: NutritionActionTypes.MARKETING_PORTAL_CREATE_PRINT_LOADING_START,
});

export const marketingPortalCreatePrintLoadingStop = () => ({
  type: NutritionActionTypes.MARKETING_PORTAL_CREATE_PRINT_LOADING_STOP,
});

// Marketing Portal Print placement details

export const marketingPortalCreatePartnerPrintRequest = (payload) => ({
  type: NutritionActionTypes.MARKETING_PORTAL_CREATE_PARTNER_PRINT_REQUEST,
  payload,
});

export const marketingPortalCreatePartnerPrintSuccess = (payload) => ({
  type: NutritionActionTypes.MARKETING_PORTAL_CREATE_PARTNER_PRINT_SUCCESS,
  payload,
});

export const marketingPortalCreatePartnerPrintFailure = (error) => ({
  type: NutritionActionTypes.MARKETING_PORTAL_CREATE_PARTNER_PRINT_FAILURE,
  payload: error,
});

export const marketingPortalCreatePartnerPrintLoadingStart = () => ({
  type: NutritionActionTypes.MARKETING_PORTAL_CREATE_PARTNER_PRINT_LOADING_START,
});

export const marketingPortalCreatePartnerPrintLoadingStop = () => ({
  type: NutritionActionTypes.MARKETING_PORTAL_CREATE_PARTNER_PRINT_LOADING_STOP,
});

// Get All Refunds

export const getAllRefundsRequest = (payload) => ({
  type: NutritionActionTypes.GET_ALL_REFUNDS_REQUEST,
  payload,
});

export const getAllRefundsSuccess = (payload) => ({
  type: NutritionActionTypes.GET_ALL_REFUNDS_SUCCESS,
  payload,
});

export const getAllRefundsFailure = (error) => ({
  type: NutritionActionTypes.GET_ALL_REFUNDS_FAILURE,
  payload: error,
});

export const getAllRefundsLoadingStart = () => ({
  type: NutritionActionTypes.GET_ALL_REFUNDS_LOADING_START,
});

export const getAllRefundsLoadingStop = () => ({
  type: NutritionActionTypes.GET_ALL_REFUNDS_LOADING_STOP,
});
export const updatePartnerClaimRequest = (payload) => ({
  type: NutritionActionTypes.UPDATE_PARTNER_CLAIM_REQUEST,
  payload,
});
export const updatePartnerClaimSuccess = (payload) => ({
  type: NutritionActionTypes.UPDATE_PARTNER_CLAIM_SUCCESS,
  payload,
});
export const updatePartnerClaimFailure = (error) => ({
  type: NutritionActionTypes.UPDATE_PARTNER_CLAIM_FAILURE,
  payload: error,
});
export const updateUserInformationRequestStart = () => ({
  type: NutritionActionTypes.UPDATE_USER_INFORMATION_REQUEST_START,
});

export const updateUserInformationRequest = (payload) => ({
  type: NutritionActionTypes.UPDATE_USER_INFORMATION_REQUEST,
  payload,
});

export const updateUserInformationSuccess = (payload) => ({
  type: NutritionActionTypes.UPDATE_USER_INFORMATION_REQUEST_SUCCESS,
  payload,
});
export const updateUserInformationFailure = (error) => ({
  type: NutritionActionTypes.UPDATE_USER_INFORMATION_REQUEST_FAILURE,
  payload: error,
});
