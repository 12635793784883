import styled from "styled-components";

export const CustomButtonContainer = styled.div`
    width: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: ${props => props.margin};
    position: relative;
`;

export const CustomButton = styled.button`
    background-color: ${props => props.outlined ? "white" : props.color};
    background-image: ${props => {
        if(props.disabled) return 'linear-gradient(60deg, lightgray, lightgray) !important'
        else if (props.gradient) return props.gradient;
        else return 'none';
    }};
    color: ${props => props.outlined ? props.color : "white"};
    border: ${props => props.outlined ? "2px solid " + props.color : "none"};
    padding: 10px 40px;
    border-radius: 8px;
    border: 1px solid #1D9D87;
    cursor: pointer;
    transition-duration: .3s;
    transition-property: transform;
    transition: all .3s;
    -webkit-transition: all .3s;
    -o-transition: all .3s;

    &:not(:disabled):hover {
      transform: scale(0.9);
    }

    &:disabled {
        border-color: lightgray !important;
        background-color: lightgray !important;
        color: black;
        pointer-events: none;
    }
`;
