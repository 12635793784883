import React from "react";
import UnChecked from "assets/images/unchecked-checkbox.svg";
import Checked from "assets/images/checked.svg";
import * as S from "./EvaluationCard.styles";

const EvaluationCard = ({ item, activeItem, onItemSelect }) => {
  const renderItem = activeItem ? activeItem : item;

  return (
    <S.EvaluationCard key={item?.id}>
      <h3 className="title">{renderItem?.title}</h3>
      <p className="desc">{renderItem?.descr}</p>
      <div className="evaluation-items">
        {(renderItem?.isEmoji || renderItem?.isShape) &&
          renderItem?.images.map((i, index) => (
            <div
              key={i.id || index} // <-- Ensure key is unique
              className={
                i.isActive ? "evaluation-item active" : "evaluation-item"
              }
              onClick={() => onItemSelect(renderItem, i)}
            >
              {!i.isActive && <img className="icon" src={i.img} alt="" />}
              {!i.isActive && (
                <img className="unchecked" src={UnChecked} alt="" />
              )}
              {i.isActive && (
                <>
                  <img className="checked" src={Checked} alt="" />
                  <img src={i.activeImg} alt="" />
                </>
              )}
            </div>
          ))}

        {renderItem?.isNumber &&
          renderItem?.numbers.map((i, index) => (
            <div
              key={i.title || index} // <-- Ensure key is unique
              className={
                i.isActive ? "evaluation-item active" : "evaluation-item"
              }
              onClick={() => onItemSelect(renderItem, i)}
            >
              <p className="">{i.title}</p>
              {!i.isActive && (
                <img className="unchecked" src={UnChecked} alt="" />
              )}
              {i.isActive && (
                <>
                  <img className="checked" src={Checked} alt="" />
                </>
              )}
            </div>
          ))}
      </div>
    </S.EvaluationCard>
  );
};

export default EvaluationCard;
