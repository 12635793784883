import React from "react";
import * as S from "./navigationTabs.styles";
import { useHistory } from "react-router-dom";
import { dashboardNavTabs } from "configs/utils";

const NavigationTabs = ({ tabValues }) => {
  const history = useHistory();

  const activeTab = () => {
    const path = window.location.pathname;
    if (
      path === dashboardNavTabs[0].route ||
      path === "/main/dashboard/tokenRequest"
    ) {
      return 0;
    } else if (path === dashboardNavTabs[1].route) {
      return 1;
    } else if (path === dashboardNavTabs[2].route) {
      return 2;
    } else if (path === dashboardNavTabs[3].route) {
      return 3;
    }
  };

  const handleRedirect = (route, redirect) => {
    if (route) history.push(route);
    else if (redirect) window.open(redirect, "_blank");
  };

  return (
    <S.NavigationTabsContainer>
      {tabValues.map((tab, index) => {
        return (
          <S.NavigationTab
            key={tab.id}
            onClick={() => handleRedirect(tab.route, tab.redirect)}
          >
            {tab.value}
            {activeTab() === index && <S.NavigationTabActive />}
          </S.NavigationTab>
        );
      })}
    </S.NavigationTabsContainer>
  );
};

export default NavigationTabs;
