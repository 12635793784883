import styled from "styled-components";
import { RegistrationContainer, RegistrationHeading, RegistrationSubHeading, } from 'pages/registration/registration.styles';


export const OrderDetailsFormContainer = styled(RegistrationContainer)``;

export const OrderDetailsFormHeading = styled(RegistrationHeading)``;

export const OrderDetailsFormSubHeading = styled(RegistrationSubHeading)``;

export const OrderDetailsDataContainer = styled.div`
    background-color: white;
    width: 686px;
    min-height: 459px;
    margin: 0 auto 100px;
    border-radius: 10px;
`;

export const OrderDetailsDataInnerContainer = styled.div`
    margin: 45px 50px;
`;

export const OrderDetailsInputContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 10px 0;
    justify-content: center;
`;

export const OrderDetailsRulesContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 2rem;
    text-align: left;
`;

export const OrderDetailsRulesLabel = styled.label`
    width: 553px;
    margin-bottom: 30px;
    margin-left: 3px;
    color: #44554C;
    font-family: RubikRegular;
    font-size: 16px;
`;