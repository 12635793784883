import styled from "styled-components";

export const EvaluationCard = styled.div`
  background: #ffffff;
  box-shadow: 0px 13px 20px #d6d6d629;
  border-radius: 8px;
  padding: 30px;

  .title {
    color: #142c1f;
    font-size: 18px;
    line-height: 22px;
    margin-block: 0;
  }

  .desc {
    color: #44554c;
    font-size: 16px;
    line-height: 24px;
    margin: 10px 0 20px;
    font-weight: 400;
  }

  .eval-desc {
    margin-top: 25px;
    color: #a5b5af;
    font-size: 14px;
    font-weight: 300;
    line-height: 18px;
    font-weight: 400;
  }

  .evaluation-items {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    gap: 5px;

    .evaluation-item {
      width: 70px;
      height: 70px;
      padding: 5px;
      border: 2px solid transparent;
      border-radius: 15px;
      cursor: pointer;
      background: #F5F5F5;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;

      p {
        margin: 0;
        font-size: 14px;
        color: #a5b5af;
        font-weight: 400;
      }

      &.active {
        border-color: #2ECF96;
        background: #F3FFFD;

        p {
          color: #142c1f;
        }
      }

      img {
        margin-top: 8px;
        width: 26px;
        height: 26px;
      }
    }
  }
`;
