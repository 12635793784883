import { v4 as uuid } from "uuid";

export const MENUS = [
  {
    id: uuid(),
    title: "Experten",
    path: "/dashboard/partnerships/experts",
  },
  {
    id: uuid(),
    title: "Weiterbildung",
    path: "/dashboard/partnerships/education",
  },
  {
    id: uuid(),
    title: "Marketing",
    path: "/dashboard/partnerships/marketing",
  },
];

export const EXPERTS_DATA = [
  {
    id: uuid(),
    title: "Gründer- & Unternehmensberatung",
    description:
      "Von der Idee zum Erfolg: Individuelle Unternehmensberatung für Gründer und Selbstständige in den Bereichen Aufbau, Ausbau und Marketingstrategien.",
    image:
      "https://foodiaryproduction.s3.eu-central-1.amazonaws.com/dashboard-partnerships/Nadinge-Voegt-Gruendercoach.jpg",
    link: "https://nova-con.de/",
  },
];

export const MARKETING_DATA = [
  {
    id: uuid(),
    title: "WirmachenDruck.de",
    description:
      "Drucke deine Flyer, Banner und Roll-Ups bei unserem Partner WirmachenDruck und lass diese direkt an zu deinem Werbepartner liefern.",
    image:
      "https://foodiaryproduction.s3.eu-central-1.amazonaws.com/dashboard-partnerships/Foodiary-Wir-machen-druck.png",
    link: "https://www.wir-machen-druck.de/",
  },
  {
    id: uuid(),
    title: "Frederic Luhede",
    description:
      "Als Spezialist für Performance Ads hat sich Frederic auf Lead Kampagnen spezialisiert, welche dir neue Erstgespräche und Interessenten für dein Online Coaching liefern.",
    image:
      "https://foodiaryproduction.s3.eu-central-1.amazonaws.com/dashboard-partnerships/Foodiary-Frederic+luhede.jpg",
    link: "https://foodiary.app/performance-ads/",
  },
];

export const EDUCATION_DATA = [
  {
    id: uuid(),
    title: "IST - Studieninstitut",
    description:
      "Das IST Studieninstitut bietet berufsbegleitende Weiterbildungen in den Bereichen Sport, Fitness und Life Balance an. Als Foodiary Coach sparst du 10% auf deine Aus- und Weiterbildung.",
    image:
      "https://foodiaryproduction.s3.eu-central-1.amazonaws.com/dashboard-partnerships/Foodiary-IST.jpg",
    link: "https://foodiary.app/ist-studieninstitut/",
  },
];
