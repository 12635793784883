import React from "react";
import TranslationMessage from "configs/translations/TranslationMessage";
import * as S from "./registration.styles";
import Meat from "assets/images/dietCategories/Icon-Meat.svg";
import Vegan from "assets/images/dietCategories/Icon-vegan.svg";
import Vegetarian from "assets/images/dietCategories/Icon-vegetarian.svg";

const DIET_CATEGORIES = [
  {
    id: 1,
    title: "Fleischhaltige Ernährung",
    img: Meat,
  },
  {
    id: 2,
    title: "Vegane Ernährung",
    img: Vegetarian,
  },
  {
    id: 3,
    title: "Vegetarische Ernährung",
    img: Vegan,
  },
];

const DietCategories = ({ onCategorySelect, selectedFoodCategory }) => {
  return (
    <S.RegistrationContainer>
      <S.RegistrationHeading>
        <TranslationMessage id="registration.dietCategories.heading" />
      </S.RegistrationHeading>
      <S.RegistrationSubHeading>
        <TranslationMessage id="registration.dietCategories.subHeading" />
      </S.RegistrationSubHeading>
      <S.CategoryCardsContainer>
        {DIET_CATEGORIES.map((item) => (
          <S.CategoryCard
            key={item?.id}
            onClick={() => {
              onCategorySelect(item);
            }}
            active={item?.id === selectedFoodCategory?.id}
          >
            <img src={item?.img} alt="categoryIcon" />
            <p>{item?.title}</p>
          </S.CategoryCard>
        ))}
      </S.CategoryCardsContainer>
    </S.RegistrationContainer>
  );
};

export default DietCategories;
