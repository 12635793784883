import styled from "styled-components";
import { RegistrationContainer, RegistrationHeading, RegistrationSubHeading, } from 'pages/registration/registration.styles';

export const PremiumCoachingContainer = styled(RegistrationContainer)``;

export const PremiumCoachingHeading = styled(RegistrationHeading)``;

export const PremiumCoachingSubHeading = styled(RegistrationSubHeading)``;

// Premium Coaching Card styling
export const PremiumCoachingCardInnerContainer = styled.div`
    padding: 20px 15px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 220px;
`;

export const Benefits = styled.div`
    line-height: 27px;
    font-size: 18px;
    color: #142C1F;
    font-family: RubikMedium;
    margin: 34px 10px 0;
`;

export const PremiumCoachingCardDescription = styled.div`
    color: #44554C;
    font-size: 16px;
    line-height: 24px;
    font-family: RubikRegular;
    margin-top: 52px;
`;

export const PremiumCoachingCardDescriptionHeading = styled.span`
    font-family: RubikMedium;
`;