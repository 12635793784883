import EmojiOne from "assets/images/Emoji-1.svg";
import EmojiOneActive from "assets/images/Emoji-1-Active.svg";
import EmojiTwo from "assets/images/Emoji-2.svg";
import EmojiTwoActive from "assets/images/Emoji-2-Active.svg";
import EmojiThree from "assets/images/Emoji-3.svg";
import EmojiThreeActive from "assets/images/Emoji-3-Active.svg";
import EmojiFour from "assets/images/Emoji-4.svg";
import EmojiFourActive from "assets/images/Emoji-4-Active.svg";
import EmojiFive from "assets/images/Emoji-5.svg";
import EmojiFiveActive from "assets/images/Emoji-5-Active.svg";

import ShapeOne from "assets/images/Shape-1.svg";
import ShapeOneActive from "assets/images/Shape-1-Active.svg";
import ShapeTwo from "assets/images/Shape-2.svg";
import ShapeTwoActive from "assets/images/Shape-2-Active.svg";
import ShapeThree from "assets/images/Shape-3.svg";
import ShapeThreeActive from "assets/images/Shape-3-Active.svg";
import ShapeFour from "assets/images/Shape-4.svg";
import ShapeFourActive from "assets/images/Shape-4-Active.svg";
import ShapeFive from "assets/images/Shape-5.svg";
import ShapeFiveActive from "assets/images/Shape-5-Active.svg";

export const EVALUATION_ITEMS = [
  {
    id: 1,
    title: "Stresslevel",
    descr: "Wie hoch ist dein aktuelles Stresslevel?",
    descr2:
      "Zwei flinke Boxer jagen die quirlige Eva und ihren Mops durch Sylt. Franz jagt im komplett verwahrlosten Taxi quer durch Bayern.",
    value: "",
    isNumber: false,
    isEmoji: true,
    isShape: false,
    images: [
      {
        state: "Stresslevel-1",
        value: 1,
        isActive: false,
        img: EmojiOne,
        activeImg: EmojiOneActive,
        color: "green-4",
      },
      {
        state: "Stresslevel-2",
        value: 2,
        isActive: false,
        img: EmojiTwo,
        activeImg: EmojiTwoActive,
        color: "light-green",
      },
      {
        state: "Stresslevel-3",
        value: 3,
        isActive: false,
        img: EmojiThree,
        activeImg: EmojiThreeActive,
        color: "light-orange",
      },
      {
        state: "Stresslevel-4",
        value: 4,
        isActive: false,
        img: EmojiFour,
        activeImg: EmojiFourActive,
        color: "orange",
      },
      {
        state: "Stresslevel-5",
        value: 5,
        isActive: false,
        img: EmojiFive,
        activeImg: EmojiFiveActive,
        color: "red-4",
      },
    ],
  },
  {
    id: 2,
    title: "Körperform",
    descr: "Welche Körperform beschreibt dich am besten?",
    descr2:
      "Zwei flinke Boxer jagen die quirlige Eva und ihren Mops durch Sylt. Franz jagt im komplett verwahrlosten Taxi quer durch Bayern.",
    value: "",
    isNumber: false,
    isEmoji: false,
    isShape: true,
    images: [
      {
        state: "Korperform-1",
        value: 1,
        isActive: false,
        img: ShapeOne,
        activeImg: ShapeOneActive,
        color: "green-4",
      },
      {
        state: "Korperform-2",
        value: 2,
        isActive: false,
        img: ShapeTwo,
        activeImg: ShapeTwoActive,
        color: "light-green",
      },
      {
        state: "Korperform-3",
        value: 3,
        isActive: false,
        img: ShapeThree,
        activeImg: ShapeThreeActive,
        color: "light-orange",
      },
      {
        state: "Korperform-4",
        value: 4,
        isActive: false,
        img: ShapeFour,
        activeImg: ShapeFourActive,
        color: "orange",
      },
      {
        state: "Korperform-5",
        value: 5,
        isActive: false,
        img: ShapeFive,
        activeImg: ShapeFiveActive,
        color: "red-4",
      },
    ],
  },
  {
    id: 3,
    title: "Sportliche Aktivität",
    descr: "Wie häufig treibst du Sport in der Woche?",
    descr2:
      "Zwei flinke Boxer jagen die quirlige Eva und ihren Mops durch Sylt. Franz jagt im komplett verwahrlosten Taxi quer durch Bayern.",
    value: "",
    isNumber: true,
    isEmoji: false,
    isShape: false,
    numbers: [
      {
        state: "SportlicheAktivitat-1",
        value: 1,
        isActive: false,
        title: "0",
        color: "red-4",
      },
      {
        state: "SportlicheAktivitat-2",
        value: 2,
        isActive: false,
        title: "1",
        color: "green-1",
      },
      {
        state: "SportlicheAktivitat-3",
        value: 3,
        isActive: false,
        title: "2-3",
        color: "green-2",
      },
      {
        state: "SportlicheAktivitat-4",
        value: 4,
        isActive: false,
        title: "3-5",
        color: "green-3",
      },
      {
        state: "SportlicheAktivitat-5",
        value: 5,
        isActive: false,
        title: "> 5",
        color: "green-4",
      },
    ],
  },
  {
    id: 4,
    title: "Tägliche Aktivität",
    descr: "Wie viel Schritte machst du am Tag?",
    descr2:
      "Zwei flinke Boxer jagen die quirlige Eva und ihren Mops durch Sylt. Franz jagt im komplett verwahrlosten Taxi quer durch Bayern.",
    value: "",
    isNumber: true,
    isEmoji: false,
    isShape: false,
    numbers: [
      {
        state: "TaglicheAktivitat-1",
        value: 1,
        isActive: false,
        title: "< 5.000",
        color: "red-4",
      },
      {
        state: "TaglicheAktivitat-2",
        value: 2,
        isActive: false,
        title: "5.000 - 8.000",
        color: "orange",
      },
      {
        state: "TaglicheAktivitat-3",
        value: 3,
        isActive: false,
        title: "8.001 - 10.000",
        color: "light-orange",
      },
      {
        state: "TaglicheAktivitat-4",
        value: 4,
        isActive: false,
        title: "10.001 - 12.000",
        color: "light-green",
      },
      {
        state: "TaglicheAktivitat-5",
        value: 5,
        isActive: false,
        title: "> 12.000",
        color: "green-4",
      },
    ],
  },
  {
    id: 5,
    title: "Rauchen",
    descr: "Wie viele Zigaretten rauchst du am Tag?",
    descr2:
      "Zwei flinke Boxer jagen die quirlige Eva und ihren Mops durch Sylt. Franz jagt im komplett verwahrlosten Taxi quer durch Bayern.",
    value: "",
    isNumber: true,
    isEmoji: false,
    isShape: false,
    numbers: [
      {
        state: "Rauchen-1",
        value: 1,
        isActive: false,
        title: "0",
        color: "green-4",
      },
      {
        state: "Rauchen-2",
        value: 2,
        isActive: false,
        title: "1-3",
        color: "red-1",
      },
      {
        state: "Rauchen-3",
        value: 3,
        isActive: false,
        title: "4-10",
        color: "red-2",
      },
      {
        state: "Rauchen-4",
        value: 4,
        isActive: false,
        title: "11-20",
        color: "red-3",
      },
      {
        state: "Rauchen-5",
        value: 5,
        isActive: false,
        title: "> 20",
        color: "red-4",
      },
    ],
  },
  {
    id: 6,
    title: "Alkohol",
    descr: "Wie häufig trinkst du Alkohol in der Woche?",
    descr2:
      "Zwei flinke Boxer jagen die quirlige Eva und ihren Mops durch Sylt. Franz jagt im komplett verwahrlosten Taxi quer durch Bayern.",
    value: "",
    isNumber: true,
    isEmoji: false,
    isShape: false,
    numbers: [
      {
        state: "Alkohol-1",
        value: 1,
        isActive: false,
        title: "0",
        color: "green-4",
      },
      {
        state: "Alkohol-2",
        value: 2,
        isActive: false,
        title: "1",
        color: "red-1",
      },
      {
        state: "Alkohol-3",
        value: 3,
        isActive: false,
        title: "2",
        color: "red-2",
      },
      {
        state: "Alkohol-4",
        value: 4,
        isActive: false,
        title: "3",
        color: "red-3",
      },
      {
        state: "Alkohol-5",
        value: 5,
        isActive: false,
        title: "> 3",
        color: "red-4",
      },
    ],
  },
  {
    id: 7,
    title: "Obst & Gemüse",
    descr: "Wie häufig isst du Obst und Gemüse am Tag?",
    descr2:
      "Zwei flinke Boxer jagen die quirlige Eva und ihren Mops durch Sylt. Franz jagt im komplett verwahrlosten Taxi quer durch Bayern.",
    value: "",
    isNumber: true,
    isEmoji: false,
    isShape: false,
    numbers: [
      {
        state: "Obst&Gemuse-1",
        value: 1,
        isActive: false,
        title: "0",
        color: "red-4",
      },
      {
        state: "Obst&Gemuse-2",
        value: 2,
        isActive: false,
        title: "1",
        color: "orange",
      },
      {
        state: "Obst&Gemuse-3",
        value: 3,
        isActive: false,
        title: "2",
        color: "light-orange",
      },
      {
        state: "Obst&Gemuse-4",
        value: 4,
        isActive: false,
        title: "3-5",
        color: "light-green",
      },
      {
        state: "Obst&Gemuse-5",
        value: 5,
        isActive: false,
        title: "> 5",
        color: "green-4",
      },
    ],
  },
  {
    id: 8,
    title: "Mahlzeitenfrequenz",
    descr: "Wie viel Mahlzeiten nimmst du pro Tag zu dir?",
    descr2:
      "Zwei flinke Boxer jagen die quirlige Eva und ihren Mops durch Sylt. Franz jagt im komplett verwahrlosten Taxi quer durch Bayern.",
    value: "",
    isNumber: true,
    isEmoji: false,
    isShape: false,
    numbers: [
      {
        state: "Mahlzeitenfrequenz-1",
        value: 1,
        isActive: false,
        title: "1",
        color: "red-4",
      },
      {
        state: "Mahlzeitenfrequenz-2",
        value: 2,
        isActive: false,
        title: "2",
        color: "red-2",
      },
      {
        state: "Mahlzeitenfrequenz-3",
        value: 3,
        isActive: false,
        title: "3",
        color: "green-4",
      },
      {
        state: "Mahlzeitenfrequenz-4",
        value: 4,
        isActive: false,
        title: "4",
        color: "green-4",
      },
      {
        state: "Mahlzeitenfrequenz-5",
        value: 5,
        isActive: false,
        title: "> 4",
        color: "green-4",
      },
    ],
  },
  {
    id: 9,
    title: "Trinken",
    descr: "Wie viel Liter trinkst du am Tag?",
    descr2:
      "Zwei flinke Boxer jagen die quirlige Eva und ihren Mops durch Sylt. Franz jagt im komplett verwahrlosten Taxi quer durch Bayern.",
    value: "",
    isNumber: true,
    isEmoji: false,
    isShape: false,
    numbers: [
      {
        state: "Trinken-1",
        value: 1,
        isActive: false,
        title: "< 1",
        color: "red-4",
      },
      {
        state: "Trinken-2",
        value: 2,
        isActive: false,
        title: "1",
        color: "orange",
      },
      {
        state: "Trinken-3",
        value: 3,
        isActive: false,
        title: "1-2",
        color: "light-orange",
      },
      {
        state: "Trinken-4",
        value: 4,
        isActive: false,
        title: "2-3",
        color: "light-green",
      },
      {
        state: "Trinken-5",
        value: 5,
        isActive: false,
        title: "> 3",
        color: "green-4",
      },
    ],
  },
  {
    id: 10,
    title: "Heißhunger",
    descr: "Wie häufig hast du Heißhungerattacken am Tag?",
    descr2:
      "Zwei flinke Boxer jagen die quirlige Eva und ihren Mops durch Sylt. Franz jagt im komplett verwahrlosten Taxi quer durch Bayern.",
    value: "",
    isNumber: true,
    isEmoji: false,
    isShape: false,
    numbers: [
      {
        state: "Heibhunger-1",
        value: 1,
        isActive: false,
        title: "0",
        color: "green-4",
      },
      {
        state: "Heibhunger-2",
        value: 2,
        isActive: false,
        title: "1",
        color: "red-1",
      },
      {
        state: "Heibhunger-3",
        value: 3,
        isActive: false,
        title: "2",
        color: "red-2",
      },
      {
        state: "Heibhunger-4",
        value: 4,
        isActive: false,
        title: "3",
        color: "red-3",
      },
      {
        state: "Heibhunger-5",
        value: 5,
        isActive: false,
        title: "> 3",
        color: "red-4",
      },
    ],
  },
  {
    id: 11,
    title: "Stimmungsschwankungen",
    descr: "Wie häufig kommt das in der Woche vor?",
    descr2:
      "Zwei flinke Boxer jagen die quirlige Eva und ihren Mops durch Sylt. Franz jagt im komplett verwahrlosten Taxi quer durch Bayern.",
    value: "",
    isNumber: true,
    isEmoji: false,
    isShape: false,
    numbers: [
      {
        state: "Stimmungsschwankungen-1",
        value: 1,
        isActive: false,
        title: "0",
        color: "green-4",
      },
      {
        state: "Stimmungsschwankungen-2",
        value: 2,
        isActive: false,
        title: "1",
        color: "light-green",
      },
      {
        state: "Stimmungsschwankungen-3",
        value: 3,
        isActive: false,
        title: "2",
        color: "light-orange",
      },
      {
        state: "Stimmungsschwankungen-4",
        value: 4,
        isActive: false,
        title: "3",
        color: "orange",
      },
      {
        state: "Stimmungsschwankungen-5",
        value: 5,
        isActive: false,
        title: "> 3",
        color: "red-4",
      },
    ],
  },
  {
    id: 12,
    title: "Schwächegefühl",
    descr: "Wie oft fühlst du dich schlapp in der Woche?",
    descr2:
      "Zwei flinke Boxer jagen die quirlige Eva und ihren Mops durch Sylt. Franz jagt im komplett verwahrlosten Taxi quer durch Bayern.",
    value: "",
    isNumber: true,
    isEmoji: false,
    isShape: false,
    numbers: [
      {
        state: "Schwachegefuhl-1",
        value: 1,
        isActive: false,
        title: "0",
        color: "green-4",
      },
      {
        state: "Schwachegefuhl-2",
        value: 2,
        isActive: false,
        title: "1",
        color: "light-green",
      },
      {
        state: "Schwachegefuhl-3",
        value: 3,
        isActive: false,
        title: "2",
        color: "light-orange",
      },
      {
        state: "Schwachegefuhl-4",
        value: 4,
        isActive: false,
        title: "3",
        color: "orange",
      },
      {
        state: "Schwachegefuhl-5",
        value: 5,
        isActive: false,
        title: "> 3",
        color: "red-4",
      },
    ],
  },
].map((mainItem) => {
  if ([1, 2].includes(mainItem.id)) {
    return {
      ...mainItem,
      images: mainItem.images.map((item) => {
        return {
          ...item,
          isActive: false,
        };
      }),
    };
  }
  return {
    ...mainItem,
    numbers: mainItem.numbers.map((item) => {
      return {
        ...item,
        isActive: false,
      };
    }),
  };
});
