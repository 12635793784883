import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import { object, string } from "yup";
import { useIntl } from "react-intl";
import moment from "moment";
import { useAlert } from "react-alert";
import { getS3ImageUrl } from "configs/utils";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import { DatePicker as MaterialDatePicker } from "@material-ui/pickers";

// Components
import Input from "components/formComponents/input/Input";
import DatePicker from "components/formComponents/datePicker/DatePicker";
import PasswordResetModal from "components/PasswordResetModal/PasswordResetModal";
// Images
import InnerSideNav from "pages/NewDasboard/components/InnerSideNav/InnerSide";
// Redux
import * as AuthActions from "redux/auth/auth.actions";
import * as S from "./trainerProfileDetails.styles";
import { MENUS } from "./data";
import ErrorMessage from "components/formComponents/errorMessage/ErrorMessage";

const TrainerProfileSection = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const alert = useAlert();
  const profileFormRef = useRef(null);

  const loading = useSelector((state) => state.auth.loading);
  const trainerProfileData = useSelector((state) => state.auth.trainerData);
  const trainerProfileTempDetails = useSelector((state) => state.dashboard.trainerProfileTempDetails)

  const [selectedSex, setSelectedSex] = useState(null);
  const [isProfileUpdating, setIsProfileUpdating] = useState(false);

  const [passwordResetModal, setPasswordResetModal] = useState(false);

  useEffect(() => {
    getTrainerProfileDetails();
  }, []);

  useEffect(() => {
    setSelectedSex(
      sexOptions.find((gender) => gender?.value === trainerProfileData?.sex)
    );
  }, [trainerProfileData]);

  const getTrainerProfileDetails = () => {
    dispatch(
      AuthActions.getTrainerDetailsRequest({
        FranchiseID: trainerProfileData?.FranchiseID,
        trainerID: trainerProfileData?.id,
      })
    );

    dispatch(
      AuthActions.getAddressDetailsRequest({
        payload: {
          trainerID: trainerProfileData?.id,
        },
      })
    );
  };

  const sexOptions = [
    { id: "Mann", label: "Mann", value: "male" },
    { id: "Frau", label: "Frau", value: "female" },
  ];

  const initialValues = {
    prename: trainerProfileData.prename || "",
    surname: trainerProfileData.surname || "",
    email: trainerProfileData.email,
    phoneNumber: trainerProfileData.Handynummer || "",
    sex: trainerProfileData.sex || "",
    dob: trainerProfileData.birthday || "",
    foodiary_email: trainerProfileTempDetails.data.foodiaryEmail || ""
  };

  const validationSchema = object().shape({
    prename: string()
      .typeError("Das Feld muss aus Buchstaben bestehen.")
      .required("Erforderlich"),
    surname: string()
      .typeError("Das Feld muss aus Buchstaben bestehen.")
      .required("Erforderlich"),
    email: string(),
    phoneNumber: string().required("Erforderlich"),
    sex: string(),
    dob: string().required("Erforderlich"),
  });

  const onFormSubmit = (values, { resetForm }) => {
    setIsProfileUpdating(true);
    const formData = new FormData();
    // formData.append("ownerID", trainerProfileData.ownerID);
    formData.append("trainerID", trainerProfileData.trainerID);
    formData.append("FranchiseID", trainerProfileData.FranchiseID);
    formData.append("prename", values.prename);
    formData.append("surname", values.surname);
    formData.append("email", values.email);
    formData.append("Handynummer", values.phoneNumber);
    formData.append("sex", selectedSex?.value);
    formData.append("birthday", values.dob);

    dispatch(
      AuthActions.updateTrainerDetailsRequest({
        formData,
        funcSuccess: (res) => {
          if (res?.success) {
            toast("✅ Deine Änderungen wurden gespeichert");
          }

          if (!res?.success) {
            toast("❌ Bitte überprüfe deine Eingaben");
          }
          getTrainerProfileDetails();
          resetForm();
          setIsProfileUpdating(false);
        },
      })
    );
  };

  const onProfileUpdate = () => {
    if (profileFormRef.current) {
      profileFormRef.current.handleSubmit();
    }
  };

  return (
    <>
      
      <div className="second-sidebar-wrapper">
        <InnerSideNav
          menus={MENUS}
          userProfileInfo={{
            profilePic: getS3ImageUrl(trainerProfileData.profilePic),
            franchiseId: trainerProfileData?.FranchiseID,
            stripeId: trainerProfileData?.["Stripe Connect ID"] || "",
            prename: trainerProfileData?.prename,
            surname: trainerProfileData?.surname,
            connected_user_account_id:
              trainerProfileData?.connected_user_account_id,
          }}
          onPasswordLink={() => setPasswordResetModal(true)}
          idsSection
        ></InnerSideNav>
      </div>
      <div className="second-sidebar-content">
        <S.TrainerProfileDetailsOverallContainer>
          <PasswordResetModal
            show={passwordResetModal}
            setShow={setPasswordResetModal}
          />

          {/* <S.TrainerProfileDetailsHeading>
        <TranslationMessage id="trainerProfile.heading" />
      </S.TrainerProfileDetailsHeading>
      <S.TrainerProfileDetailsSubHeading>
        <TranslationMessage id="trainerProfile.subHeading" />
      </S.TrainerProfileDetailsSubHeading> */}

          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onFormSubmit}
            innerRef={profileFormRef}
            enableReinitialize
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              touched,
              errors,
              isValid,
              dirty,
              setFieldValue
            }) => {
              console.log('values : ', values)
              return (
                <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                  <S.TrainerProfileDetailsInnerContainer>
                    <S.Header className="p-0">
                      <div>
                        <S.TrainerProfileDetailsSectionHeading>
                          Persönliche Daten
                        </S.TrainerProfileDetailsSectionHeading>
                        <S.TrainerProfileDetailsSectionSubHeading>
                          Deine persönlichen Daten.
                        </S.TrainerProfileDetailsSectionSubHeading>
                      </div>
                      <div>
                        <button
                          className="btn-dark"
                          onClick={onProfileUpdate}
                          disabled={loading || isProfileUpdating}
                        >
                          {isProfileUpdating ? "wird gespeichert…" : "Speichern"}
                        </button>
                      </div>
                    </S.Header>
                    <br />
                    <S.Divider />
                    <br />
                    {loading && (
                      <>
                        <br />
                        <br />
                        <br />
                        <br />
                        <div className="text-center">
                          <div className="loader mx-auto"></div>
                        </div>
                        <br />
                        <br />
                        <br />
                        <br />
                      </>
                    )}
  
                    {!loading && (
                      <S.FormGrid>
                        <div>
                          <S.TrainerProfileDetailsSection1InputLabel>
                            {intl.formatMessage({
                              id: "trainerProfile.prename",
                            })}
                          </S.TrainerProfileDetailsSection1InputLabel>
                          <Input
                            style={{ marginBottom: "10px" }}
                            placeholder={intl.formatMessage({
                              id: "trainerProfile.prename",
                            })}
                            name="prename"
                            onChange={handleChange}
                            value={values.prename}
                          />
                          {touched.prename && errors.prename && (
                            <ErrorMessage error={errors.prename} />
                          )}
                        </div>
                        <div>
                          <S.TrainerProfileDetailsSection1InputLabel>
                            {intl.formatMessage({
                              id: "trainerProfile.surname",
                            })}
                          </S.TrainerProfileDetailsSection1InputLabel>
                          <Input
                            style={{ marginBottom: "10px" }}
                            placeholder={intl.formatMessage({
                              id: "trainerProfile.surname",
                            })}
                            name="surname"
                            onChange={handleChange}
                            value={values.surname}
                          />
                          {touched.surname && errors.surname && (
                            <ErrorMessage error={errors.surname} />
                          )}
                        </div>
                        <div>
                          <S.TrainerProfileDetailsSection1InputLabel>
                            {intl.formatMessage({
                              id: "trainerProfile.email",
                            })}
                          </S.TrainerProfileDetailsSection1InputLabel>
                          <Input
                            style={{ marginBottom: "10px" }}
                            placeholder={intl.formatMessage({
                              id: "trainerProfile.email",
                            })}
                            name="email"
                            onChange={handleChange}
                            value={values.email}
                            disabled
                            filled
                          />
                        </div>
                        <div>
                          <S.TrainerProfileDetailsSection1InputLabel>
                            Foodiary E-MAIL
                          </S.TrainerProfileDetailsSection1InputLabel>
                          <Input
                            style={{ marginBottom: "10px" }}
                            placeholder={'Foodiary E-MAIL'}
                            name="foodiary-email"
                            onChange={handleChange}
                            value={values.foodiary_email}
                            disabled
                            filled
                          />
                        </div>
                        <div>
                          <S.TrainerProfileDetailsSection1InputLabel>
                            {intl.formatMessage({
                              id: "trainerProfile.phoneNumber",
                            })}
                          </S.TrainerProfileDetailsSection1InputLabel>
                          <Input
                            style={{ marginBottom: "10px" }}
                            placeholder={intl.formatMessage({
                              id: "trainerProfile.phoneNumber",
                            })}
                            name="phoneNumber"
                            onChange={handleChange}
                            value={values.phoneNumber}
                          />
                          {touched.phoneNumber && errors.phoneNumber && (
                            <ErrorMessage error={errors.phoneNumber} />
                          )}
                        </div>
                        <div>
                          <S.TrainerProfileDetailsSection1InputLabel>
                            {intl.formatMessage({
                              id: "trainerProfile.sex",
                            })}
                          </S.TrainerProfileDetailsSection1InputLabel>
                          <Select
                            options={sexOptions}
                            name="sex"
                            className="faq-select"
                            classNamePrefix="react-select"
                            onChange={(v) => {
                              handleChange({
                                target: {
                                  value: v?.value,
                                },
                              });
                              setSelectedSex(v);
                            }}
                            value={selectedSex}
                            placeholder="Alle Kategorien"
                            isSearchable={false}
                          />
                          {/* {touched.sex && errors.sex && (
                            <ErrorMessage error={errors.sex} />
                          )} */}
                          {/* <Dropdown
                          options={sexOptions}
                          name="sex"
                          onChange={handleChange}
                          value={values.sex}
                          placeholder={intl.formatMessage({
                            id: "trainerProfile.sex",
                          })}
                          disabled={!section1Edit}
                          style={{
                            margin: "0 0 1rem",
                            fontSize: "1rem",
                            border: "#F8F8F8",
                            background: "#F8F8F8",
                          }} 
                        /> */}
                        </div>
                        <div>
                          <S.TrainerProfileDetailsSection1InputLabel>
                            {intl.formatMessage({
                              id: "trainerProfile.dob",
                            })}
                          </S.TrainerProfileDetailsSection1InputLabel>
                          <DatePicker
                            onDateChange={value => setFieldValue('dob', value)}
                            value={values.dob}
                            placeholder={intl.formatMessage({id: "trainerProfile.dob"})}
                            name="dob"
                          />
                          {touched.dob && errors.dob && (
                            <ErrorMessage error={errors.dob} />
                          )}
                        </div>
                      </S.FormGrid>
                    )}
                  </S.TrainerProfileDetailsInnerContainer>
                  <br />
                </form>
              )
            }}
          </Formik>
        </S.TrainerProfileDetailsOverallContainer>
      </div>
    </>
  );
};

export default TrainerProfileSection;
