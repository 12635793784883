import React from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  removeOnboardingKeysForMiddleFlow,
  stopRecording,
} from "configs/utils";

import Button from "components/formComponents/button/Button";
import Ratings from "components/ratings/Ratings";
import TranslationMessage from "configs/translations/TranslationMessage";
import OnboardingSidebar from "pages/OnBoardingPartOne/OnboardingSidebar";

import FoodiaryLogo from "assets/images/Foodiary-Logo.PNG";
import WaterDrop from "assets/images/waterDrop.svg";
import WaterDropBackground from "assets/images/waterDropBackground.svg";
import SleepingBed from "assets/images/sleepingBed.svg";
import SleepingBedBackground from "assets/images/sleepingBedBackground.svg";
import ArrowLeft from "assets/images/arrowLeft.svg";

import { SidebarSteps } from "../data";

// Redux
import * as SupplementsActions from "redux/supplements/supplements.actions";
import * as S from "../OnBoardingPartThree.styles";

const HealthScoreTipsAndTricks = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const CardData = [
    {
      id: "card1",
      heading: "healthScore.tips.card1.heading",
      description: "healthScore.tips.card1.description",
      icon: WaterDrop,
      iconBackground: WaterDropBackground,
      benefits: [
        { benefit: "healthScore.tips.card1.p1", rating: 1 },
        { benefit: "healthScore.tips.card1.p2", rating: 1 },
        { benefit: "healthScore.tips.card1.p3", rating: 1 },
        { benefit: "healthScore.tips.card1.p4", rating: 4 },
      ],
    },
    {
      id: "card2",
      heading: "healthScore.tips.card2.heading",
      description: "healthScore.tips.card2.description",
      icon: SleepingBed,
      iconBackground: SleepingBedBackground,
      benefits: [
        { benefit: "healthScore.tips.card1.p1", rating: 4 },
        { benefit: "healthScore.tips.card1.p2", rating: 0 },
        { benefit: "healthScore.tips.card1.p4", rating: 4 },
      ],
    },
    {
      id: "card3",
      heading: "healthScore.tips.card3.heading",
      description: "healthScore.tips.card3.description",
      icon: SleepingBed,
      iconBackground: SleepingBedBackground,
      benefits: [
        { benefit: "healthScore.tips.card1.p1", rating: 3 },
        { benefit: "healthScore.tips.card1.p2", rating: 4 },
        { benefit: "healthScore.tips.card1.p3", rating: 4 },
        { benefit: "healthScore.tips.card1.p4", rating: 5 },
      ],
    },
    {
      id: "card4",
      heading: "healthScore.tips.card4.heading",
      description: "healthScore.tips.card4.description",
      icon: SleepingBed,
      iconBackground: SleepingBedBackground,
      benefits: [
        { benefit: "healthScore.tips.card1.p1", rating: 1 },
        { benefit: "healthScore.tips.card1.p2", rating: 2 },
        { benefit: "healthScore.tips.card1.p3", rating: 2 },
        { benefit: "healthScore.tips.card1.p4", rating: 4 },
      ],
    },
  ];

  const handleBack = () => {
    history.push("/onboarding/healthscore/anamnese-evaluation");
  };

  const handleNext = () => {
    history.push("/onboarding/healthscore/improvements");
  };

  const handleClose = () => {
    const userData = JSON.parse(localStorage.getItem("RegistrationData"));
    stopRecording();
    dispatch(
      SupplementsActions.deleteMailChimpUserRequest({
        formValues: {
          sex: userData?.sex,
          mobile: userData?.mobile,
          birthday: userData?.birthday,
          email: userData?.email,
          prename: userData?.prename,
          surname: userData?.surname,
          type: "web",
        },
        func: (res) => {
          removeOnboardingKeysForMiddleFlow();
          console.log("Close", res);
        },
      })
    );
    history.push("/dashboard/main");
  };

  return (
    <>
      <S.OnBoardingPage className="starter">
        <S.OnBoardingContainer className="onboarding-container">
          <OnboardingSidebar step={4} />
          <S.OnBoardingContent className="onboarding-content">
            <S.OnBoardingHeader className="onboarding-header">
              <div className="d-flex align-items-top gap-3">
                <img
                  className="cursor-pointer"
                  src={ArrowLeft}
                  alt="back"
                  onClick={handleBack}
                />
                <div className="content">
                  <span className="small-title text-green font-rubik-medium">
                    Tipps & Tricks
                  </span>
                  <h6 className="header-title font-rubik-medium my-0">
                    Grundlagen einer gesunden Ernährung.
                  </h6>
                </div>
              </div>
              <div className="actions d-flex align-items-center gap-2">
                <Button
                  color="rgba(248, 164, 146, 1)"
                  gradient="linear-gradient(60deg, rgba(46, 207, 150, 1), rgba(28, 157, 135, 1)) !important"
                  margin="0"
                  absolute={{ width: "fit-content", height: "2.5rem" }}
                  buttonStyle={{ padding: ".5625rem 2.5rem" }}
                  onClick={handleNext}
                >
                  Weiter
                </Button>

                <button className="btn-close" onClick={handleClose}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="#000"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-x"
                  >
                    <line x1="18" y1="6" x2="6" y2="18"></line>
                    <line x1="6" y1="6" x2="18" y2="18"></line>
                  </svg>
                </button>
              </div>
            </S.OnBoardingHeader>
            <S.OnBoardingBody className="onboarding-body">
              <div className="health-score-tips-tricks-content">
                <h3 className="text-size-20-28 text-dark-1 font-rubik-medium mt-0">
                  Tipps um deine Health Score zu verbessern
                </h3>
                <p className="text-size-16-19 text-light-grey-1 font-rubik-regular">
                  Einfache Tipps für einen große Veränderung.
                </p>
                <br />
                <div className="health-score-tips gap-3">
                  {CardData.map((card) => {
                    return (
                      <div className="tips-card" key={card.id}>
                        <S.HealthScoreTipsHeadingContainer>
                          <div>
                            <img
                              src={card.iconBackground}
                              alt="IconBackground"
                            />
                            <S.HealthScoreTipsHeadingIcon
                              src={card.icon}
                              alt="Icon"
                            />
                          </div>
                          <h4 className="text-size-18-27 text-dark-1 my-0">
                            <TranslationMessage id={card.heading} />
                          </h4>
                        </S.HealthScoreTipsHeadingContainer>
                        <S.HealthScoreTipsBenefitContainer>
                          {card.benefits.map((benefit, i) => {
                            return (
                              <S.HealthScoreTipsBenefitsContainer key={i}>
                                <h6 className="text-size-16-20 text-dark-2 my-0">
                                  <TranslationMessage id={benefit.benefit} />
                                </h6>
                                <Ratings rating={benefit.rating} />
                              </S.HealthScoreTipsBenefitsContainer>
                            );
                          })}
                        </S.HealthScoreTipsBenefitContainer>
                        <p className="text-size-14-20 text-dark-2 font-rubik-regular mb-0">
                          <TranslationMessage id={card.description} />
                        </p>
                      </div>
                    );
                  })}
                </div>
              </div>
            </S.OnBoardingBody>
          </S.OnBoardingContent>
        </S.OnBoardingContainer>
        <br />
        <br />
        <br />
      </S.OnBoardingPage>
    </>
  );
};

export default HealthScoreTipsAndTricks;
