

const DetailsDropDown = [
    {
        label: 'Erhaltene Leads',
        value: 'received'
    },
    {
        label: 'Erster Kontaktversuch',
        value: 'first contact'
    },
    {
        label: 'Zweiter Kontaktversuch',
        value: 'second contact'
    },
    {
        label: 'Erstgespräch vereinbart',
        value: 'appointment'
    },
    {
        label: 'Kein Interesse',
        value: 'notinterested'
    },
    {
        label: 'No Show',
        value: 'no show'
    },
    {
        label: 'In Betreuung',
        value: 'in consultation'
    },
    {
        label: 'Storniert',
        value: 'storno'
    }
]
const DetailsDropDownObj = {
    'received': {
        label: 'Erhaltene Leads',
        className: 'taskColorOne'
    },
    'first contact': {
        label: 'Erster Kontaktversuch',
        className: 'taskColorTwo'
    },
    'second contact': {
        label: 'Zweiter Kontaktversuch',
        className: 'taskColorThree'
    },
    'appointment': { label: 'Erstgespräch vereinbart', className: 'taskColorFour' },
    'notinterested': { label: 'Kein Interesse', className: 'taskColorFive' },
    'no show': { label: 'No Show', className: 'taskColorSix' },
    'in consultation': { label: 'In Betreuung', className: 'taskColorSeven' },
    'storno': { label: 'Storniert', className: 'taskColorEight' }

}
export { DetailsDropDown, DetailsDropDownObj };