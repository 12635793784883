import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  setUserRegistrationDataInLocalStorage,
  getUserRegistrationDataFromLocalStorage,
} from "configs/utils";
import moment from "moment";

// Components
import Button from "components/formComponents/button/Button";
// import DatePicker from "components/formComponents/datePicker/DatePicker";

import FoodiaryLogo from "assets/images/Foodiary-Logo.PNG";
import MinusIcon from "assets/images/Onboarding/Screen6/Icon-minus.svg";
import PlusIcon from "assets/images/Onboarding/Screen6/Icon-plus-2.svg";
import InfoIcon from "assets/images/Onboarding/Screen6/icon-info.svg";
import ArrowLeft from "assets/images/arrowLeft.svg";
import { SidebarSteps } from "../data";
// Redux
import * as S from "../OnBoardingPartOne.styles";
import OnboardingSidebar from "../OnboardingSidebar";

const BodyDetails = (props) => {
  const history = useHistory();

  const [date, setDate] = useState("");
  const [error, setError] = useState("");

  // const [Date, SetDate] = useState("");
  const [Height, SetHeight] = useState(0);
  const [Weight, SetWeight] = useState(0);
  const [ValidationErrors, SetValidationErrors] = useState({
    Date: false,
    Height: false,
    Weight: false,
  });

  useEffect(() => {
    const formatedDate = getUserRegistrationDataFromLocalStorage()
      ?.bodyDetails?.date.split("-")
      .reverse()
      .join(".");
    setDate(formatedDate || "");

    SetHeight(
      getUserRegistrationDataFromLocalStorage()?.bodyDetails?.height || "0"
    );
    SetWeight(
      getUserRegistrationDataFromLocalStorage()?.bodyDetails?.weight || "0"
    );
  }, []);

  const isValidDate = (d, m, y) => {
    const date = new Date(y, m - 1, d);
    return (
      date.getFullYear() === y &&
      date.getMonth() === m - 1 &&
      date.getDate() === d
    );
  };

  const handleDateChange = (date) => {
    console.log("Date", date);
    setDate(date);
    SetValidationErrors({
      ...ValidationErrors,
      Date: false,
    });

    const value = date;
    setDate(value);

    // Regular expression to match the date format dd.mm.yyyy
    const datePattern = /^\d{2}\.\d{2}\.\d{4}$/;

    // Check if the entered date matches the pattern
    if (!datePattern.test(value)) {
      setError("Bitte verwende das Format: DD.MM.YYYY");
      return false;
    } else {
      // Additional validation to check if the date is valid
      const [day, month, year] = value.split(".").map(Number);

      if (!isValidDate(day, month, year)) {
        setError("Bitte verwende das Format: DD.MM.YYYY");
        return false;
      } else {
        setError("");
      }
    }

    return true;
  };

  const calculateBMI = () => {
    const heightInMeter = Height / 100;
    const h2 = heightInMeter * heightInMeter;

    return Math.ceil(Weight / h2);
  };

  const handleNext = () => {
    if (!handleDateChange(date)) return;

    if (Weight < 40 || Height < 120 || !date) {
      SetValidationErrors({
        Date: !date,
        Height: Height < 120,
        Weight: Weight < 40,
      });
      return;
    }

    const birthday = date;

    setUserRegistrationDataInLocalStorage({
      ...getUserRegistrationDataFromLocalStorage(),
      bodyDetails: {
        date: birthday.split(".").reverse().join("-"),
        height: Height,
        weight: Weight,
      },
    });

    history.push("/onboarding/registration/daily-routine");
  };

  const handleBack = () => {
    history.push("/onboarding/registration/anamnese_evaluation");
  };

  return (
    <>
      <S.OnBoardingPage className="starter">
        <S.OnBoardingContainer className="onboarding-container">
          <OnboardingSidebar step={2} />
          <S.OnBoardingContent className="onboarding-content">
            <S.OnBoardingHeader className="onboarding-header">
              <div className="d-flex align-items-top gap-3">
                <img
                  className="cursor-pointer"
                  src={ArrowLeft}
                  alt="back"
                  onClick={handleBack}
                />
                <div className="content">
                  <span className="small-title text-green font-rubik-medium">
                    Anamnese
                  </span>
                  <h6 className="header-title font-rubik-medium my-0">
                    Wir erstellen deinen Plan.
                  </h6>
                </div>
              </div>
              <div className="actions">
                <Button
                  color="rgba(248, 164, 146, 1)"
                  gradient="linear-gradient(60deg, rgba(46, 207, 150, 1), rgba(28, 157, 135, 1)) !important"
                  margin="0"
                  absolute={{ width: "fit-content", height: "2.5rem" }}
                  buttonStyle={{ padding: ".5625rem 2.5rem" }}
                  onClick={handleNext}
                >
                  Weiter
                </Button>
              </div>
            </S.OnBoardingHeader>
            <S.OnBoardingBody className="onboarding-body">
              <div className="body-details-content">
                <h3 className="text-size-20-28 text-dark-1 font-rubik-medium">
                  Deine personenbezogenen Daten.
                </h3>
                <p className="text-size-16-19 text-light-grey-1 font-rubik-regular">
                  Das Verhältnis zwischen Alter, Gewicht und Größe ist <br />
                  wichtig für deinen Kalorienbedarf.
                </p>
                <p className="text-size-14-28 text-dark-1 font-rubik-medium">
                  Nenne uns deine Daten
                </p>
                <div className="body-details-items">
                  <div className="body-details-item">
                    <p className="text-size-18-22 text-dark-1 font-rubik-medium">
                      Dein Geburtsdatum
                    </p>
                    <div className="date-picker">
                      {/* <DatePicker
                        type="date"
                        placeholder={"dd/mm/yyyy"}
                        onChange={handleDateChange}
                        value={Date}
                        max={moment().add(-1, "days").format("YYYY-MM-DD")}
                        style={{
                          margin: "0 auto",
                          padding: "10px",
                        }}
                      /> */}
                      <input
                        className="just-input"
                        type="text"
                        value={date}
                        onChange={(e) => handleDateChange(e.target.value)}
                        placeholder="dd.mm.yyyy"
                        required
                      />
                    </div>
                  </div>
                  {error && (
                    <p className="text-size-14-17 font-rubik-regular my-0">
                      {error}
                    </p>
                  )}
                  {ValidationErrors.Date && (
                    <p className="text-size-14-17 font-rubik-regular my-0">
                      Dein Geburtsdatum
                    </p>
                  )}
                  <div className="body-details-item">
                    <p className="text-size-18-22 text-dark-1 font-rubik-medium">
                      Deine Körpergröße (cm)
                    </p>
                    <div className="input-incr-decr">
                      <button
                        onClick={() => {
                          if (Number(Height) === 0) return;
                          SetHeight(Number(Height) - 1);
                          SetValidationErrors({
                            ...ValidationErrors,
                            Height: Number(Height) < 120,
                          });
                        }}
                      >
                        <img src={MinusIcon} alt="minus" />
                      </button>
                      <input
                        type="text"
                        value={Height ? Height : ""}
                        onChange={(e) => {
                          if (isNaN(+e.target.value)) return;
                          SetHeight(+e.target.value);
                          SetValidationErrors({
                            ...ValidationErrors,
                            Height: +e.target.value < 120,
                          });
                        }}
                      />
                      <button
                        onClick={() => {
                          SetHeight(Number(Height) + 1);
                          SetValidationErrors({
                            ...ValidationErrors,
                            Height: Number(Height) < 120,
                          });
                        }}
                      >
                        <img src={PlusIcon} alt="plus" />
                      </button>
                    </div>
                  </div>
                  {ValidationErrors.Height && (
                    <p className="text-size-14-17 font-rubik-regular my-0">
                      Die Größe muss über 120 cm liegen
                    </p>
                  )}
                  <div className="body-details-item">
                    <p className="text-size-18-22 text-dark-1 font-rubik-medium">
                      Dein Körpergewicht (kg)
                    </p>
                    <div className="input-incr-decr">
                      <button
                        onClick={() => {
                          if (Number(Weight) === 0) return;
                          SetWeight(Number(Weight) - 1);
                          SetValidationErrors({
                            ...ValidationErrors,
                            Weight: Number(Weight) < 40,
                          });
                        }}
                      >
                        <img src={MinusIcon} alt="minus" />
                      </button>
                      <input
                        type="text"
                        value={Weight ? Weight : ""}
                        onChange={(e) => {
                          if (isNaN(+e.target.value)) return;
                          SetWeight(+e.target.value);
                          SetValidationErrors({
                            ...ValidationErrors,
                            Weight: +e.target.value < 40,
                          });
                        }}
                      />
                      <button
                        onClick={() => {
                          SetWeight(Number(Weight) + 1);
                          SetValidationErrors({
                            ...ValidationErrors,
                            Weight: Number(Weight) < 40,
                          });
                        }}
                      >
                        <img src={PlusIcon} alt="plus" />
                      </button>
                    </div>
                  </div>
                  {ValidationErrors.Weight && (
                    <p className="text-size-14-17 font-rubik-regular my-0">
                      Dein Gewicht muss über 40 Kg liegen
                    </p>
                  )}
                  {Height > 0 && Weight > 0 && (
                    <div className="body-details-status-item">
                      <img className="info-img" src={InfoIcon} alt="" />
                      <div className="">
                        <p className="text-size-14-28 text-dark-1 font-rubik-medium my-0">
                          Wir haben deinen BMI berechnet
                        </p>
                        <p className="text-size-14-19 text-light-grey-1 font-rubik-regular my-0">
                          {`Dein aktueller BMI beträgt: ${calculateBMI()}. Beachte, dass der BMI nur eine grobe Einschätzung ist und als Vergleichsindikator dient.`}
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </S.OnBoardingBody>
          </S.OnBoardingContent>
        </S.OnBoardingContainer>
      </S.OnBoardingPage>
    </>
  );
};

export default BodyDetails;
