import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Modal from "components/modal/Modal";
import Topbar from "components/topbar/Topbar";
import Card from "components/card/Card";
import Button from "components/formComponents/button/Button";
import TranslationMessage from "configs/translations/TranslationMessage";
import BackIconButton from "components/backIconButton/BackIconButton";
import {
  InformationHeading,
  InformationDescription,
} from "./information.styles";
import ErrorMessageIcon from "assets/images/error-message.png";

// Redux
import * as AuthActions from "redux/auth/auth.actions";
// import * as DashboardActions from "redux/dashboard/dashboard.actions";

const Information = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();

  // const [
  //   IsStripeConnectedAccountStatusModal,
  //   SetIsStripeConnectedAccountStatusModal,
  // ] = useState(false);

  // const trainerData = useSelector((state) => state.auth.trainerData);
  // const checkStripeConnectedAccountStatus = useSelector(
  //   (state) => state.dashboard.checkStripeConnectedAccountStatus
  // );
  // const isCheckStripeConnectedAccountStatusLoading = useSelector(
  //   (state) => state.dashboard.isCheckStripeConnectedAccountStatusLoading
  // );

  if (!props.location?.state?.from) {
    // history.push("/main/dashboard/home")
  } else dispatch(AuthActions.resetRegistrationData());

  useEffect(() => {
    // dispatch(
    //   DashboardActions.checkStripeConnectedAccountStatusRequest({
    //     payload: {
    //       trainerID: trainerData?.trainerID,
    //     },
    //     callback: (res) => {},
    //   })
    // );
  }, []);

  return (
    <>
      <Topbar />

      {/* {isCheckStripeConnectedAccountStatusLoading && (
        <Card
          width="496px"
          height="123px"
          margin="auto"
          textAlign="center"
          styles={{ marginTop: "200px" }}
        >
          <InformationHeading>Loading...</InformationHeading>
        </Card>
      )} */}

      {/* {!isCheckStripeConnectedAccountStatusLoading && ( */}
      <>
        <BackIconButton
          onClick={() =>
            history.push(props.location?.state?.from || "/main/dashboard/home")
          }
          style={{ position: "absolute", top: "200px", left: "100px" }}
        />
        <Card
          width="496px"
          height="273px"
          margin="auto"
          textAlign="center"
          styles={{ marginTop: "200px" }}
        >
          <InformationHeading>
            <TranslationMessage id="information.heading" />
          </InformationHeading>
          <InformationDescription>
            Schön, dass wir gemeinsam beginnen <br /> und deinen persönlichen
            Ernährungsplan entwickeln.
          </InformationDescription>
          <Button
            color="rgba(248, 164, 146, 1)"
            margin="20px auto 0 auto"
            onClick={() => {
              // if (
              //   checkStripeConnectedAccountStatus?.connected_account_status ===
              //     "completed" &&
              //   checkStripeConnectedAccountStatus?.connected_user_account_status ===
              //     "completed"
              // ) {
              history.push("/new-consultation");
              // } else {
              //   SetIsStripeConnectedAccountStatusModal(true);
              // }
            }}
          >
            <TranslationMessage id="button.letUsBegin" />
          </Button>
        </Card>
      </>
      {/* )} */}

      {/* <Modal
        width="450px"
        height="auto"
        show={IsStripeConnectedAccountStatusModal}
        setShow={() => null}
        heading={
          <div className="d-flex align-items-center justify-content-between">
            <h4 className="my-0 text-left"> </h4>
          </div>
        }
      >
        <div className="congratulations-modal-body-inner">
          <img src={ErrorMessageIcon} alt="" />

          <h6>Achtung!</h6>
          <p>
            Dein Zahlungsanbieter wurde noch nicht aktiviert. <br />
            Bitte aktiviere deinen Anbieter direkt.
          </p>
          <button
            className="btn-dark"
            onClick={() => history.push("/dashboard/settings/payment-provider")}
          >
            Zahlungsanbieter aktivieren
          </button>
        </div>
      </Modal> */}
    </>
  );
};

export default Information;
