import styled from 'styled-components';
import {RegistrationContainer ,RegistrationHeading, 
    RegistrationSubHeading, BodyDetailsCard, BodyDetailsCardHeading} from 'pages/registration/registration.styles';

export const NutritionFormCaloriesContainer = styled(RegistrationContainer)``;

export const NutritionFormCaloriesHeading = styled(RegistrationHeading)``;

export const NutritionFormCaloriesSubHeading = styled(RegistrationSubHeading)``;

export const NutritionFormCaloriesInnerContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 20px 0 30px;
`;

export const NutritionFormCaloriesLineGraphContainer = styled.div`
    width: 447px;
    height: 289px;
    background: white;
    border-radius: 10px;
`;

export const NutritionFormCaloriesDescription = styled.div`
    width: 427px;
    font-family: RubikRegular;
    font-size: 16px;
    color: rgba(68, 68, 68, .6);
    text-align: left;
    line-height: 25px;
`;

export const EditCaloriesWeekHeadingContainer = styled.div`
    font-size: 14px;
    text-align: center;
    margin-bottom: 20px;
    color: #1C9D87;
    display: flex;
    flex-direction: row;
    justify-content: center;
`;

export const EditCaloriesWeekHeading = styled.span`
    line-height: 50px;
`;

export const EditCaloriesNavigationIconContainer = styled.span`
    margin: 10px 30px;
    cursor: pointer;
`;

export const EditCaloriesInputCard = styled(BodyDetailsCard)`
    background-color: #F5FAF9;
`;

export const EditCaloriesInputCardHeading = styled(BodyDetailsCardHeading)``;
