export const renderDescription = (list, index) => {
  const textsDisctionary = Object.assign({}, [...list]);
  return textsDisctionary[index]?.description;
};

export function sumCoursePoints(list) {
  return list.reduce((acc, current) => {
    return acc + current.course_points;
  }, 0);
}

export function calculatePercentage(array) {
  const percentage = (array.length / 8) * 100;
  return percentage;
}

export function isDate7DaysOld(dateString) {
  const givenDate = new Date(dateString);
  const currentDate = new Date();

  // Calculate the difference in milliseconds
  const differenceInMs = currentDate - givenDate;

  // Convert milliseconds to days
  const differenceInDays = differenceInMs / (1000 * 60 * 60 * 24);

  return differenceInDays >= 7;
}

export const formatDate = (date) => {
  const originalDay = ("0" + date.getDate()).slice(-2);
  const originalMonth = ("0" + (date.getMonth() + 1)).slice(-2);
  const originalYear = date.getFullYear();
  return `${originalDay}.${originalMonth}.${originalYear}`;
};

export function getFormatedDates(dateString) {
  const originalDate = new Date(dateString);

  const formattedOriginalDate = formatDate(originalDate);

  // Calculate new date
  const newDate = new Date(originalDate);
  newDate.setDate(newDate.getDate() + 84);

  const formattedNewDate = formatDate(newDate);

  return { formattedOriginalDate, formattedNewDate };
}
