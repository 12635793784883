import AcademyIcon from "assets/images/new-dashboard/AcademyIcon.svg";
import AcademyIconActive from "assets/images/new-dashboard/AcademyIconActive.svg";
import DashboardIcon from "assets/images/new-dashboard/DashboardIcon.svg";
import DashboardIconActive from "assets/images/new-dashboard/DashboardIconActive.svg";
import SettingsIcon from "assets/images/new-dashboard/settings.svg";
import UsersIcon from "assets/images/new-dashboard/KlientenverwaltungIcon.svg";
import UsersIconActive from "assets/images/new-dashboard/KlientenverwaltungIconActive.svg";
import ThumbsUpIcon from "assets/images/new-dashboard/Marketingportal-Icon.svg";
import PartnershipIcon from "assets/images/new-dashboard/IconPartnership.svg";
import PartnershipIconActive from "assets/images/new-dashboard/IconPartnershipActive.svg";
import WebsiteIcon from "assets/images/new-dashboard/WebsiteIcon.svg";
import WebsiteIconActive from "assets/images/new-dashboard/WebsiteIconActive.svg";
import StarIcon from "assets/images/new-dashboard/SocialMediaIcon.svg";
import StarIconActive from "assets/images/new-dashboard/SocialMediaIconActive.svg";
import SupportIcon from "assets/images/new-dashboard/SupportIcon.svg";
import SupportIconActive from "assets/images/new-dashboard/SupportIconActive.svg";

import CalendlyIcon from "assets/images/new-dashboard/CalendlyIcon.svg";
import MailIcon from "assets/images/new-dashboard/Mail-Icon.svg";
import StripeIcon from "assets/images/new-dashboard/Stripe-Icon.svg";
import FoodiaryIcon from "assets/images/new-dashboard/FoodiaryIcon.svg";
import LeadIcon from "assets/images/lead-Sidebar-icon.svg"

// import InboxIcon from "@material-ui/icons/MoveToInbox";
// import MailIcon from "@material-ui/icons/Mail";
// import WebIcon from "@material-ui/icons/Web";
// import HelpOutlineSharpIcon from "@material-ui/icons/HelpOutlineSharp";
// import LanguageSharpIcon from "@material-ui/icons/LanguageSharp";
// import StarBorderIcon from "@material-ui/icons/StarBorder";
// import ThumbUpAltOutlinedIcon from "@material-ui/icons/ThumbUpAltOutlined";
// import CheckCircleOutlineOutlinedIcon from "@material-ui/icons/CheckCircleOutlineOutlined";
// import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";

export const SidebarSocialIcons = [
  {
    id: 1,
    img: CalendlyIcon,
    url: "https://calendly.com/",
  },
  {
    id: 2,
    img: StripeIcon,
    url: "https://dashboard.stripe.com",
  },
  {
    id: 3,
    img: MailIcon,
    url: "http://webmail.foodiary.app",
  },
  {
    id: 4,
    img: FoodiaryIcon,
    url: "https://gofile.me/6GQ02/K7FUsZSLS",
  },
];

export const MenuItems = [
  {
    id: 1,
    img: DashboardIcon,
    activeImg: DashboardIconActive,
    title: "Home",
    url: "/dashboard/main",
  },
  {
    id: 2,
    img: LeadIcon,
    activeImg: LeadIcon,
    title: "Leads",
    url: "/dashboard/leads/all",
  },
  {
    id: 2,
    img: UsersIcon,
    activeImg: UsersIconActive,
    title: "Klienten",
    url: "/dashboard/customers/list",
  },
  {
    id: 3,
    img: ThumbsUpIcon,
    activeImg: ThumbsUpIcon,
    title: "Marketing",
    url: "/dashboard/marketing-portal/werbeassets",
  },
  {
    id: 4,
    img: StarIcon,
    activeImg: StarIconActive,
    title: "Social Media",
    url: "/dashboard/social-media/instagram",
  },
  {
    id: 5,
    img: WebsiteIcon,
    activeImg: WebsiteIconActive,
    title: "Webseite",
    url: "/dashboard/website/overview",
  },
  {
    id: 6,
    img: SupportIcon,
    activeImg: SupportIconActive,
    title: "Support",
    url: "/dashboard/support/open-ticket",
  },
  {
    id: 7,
    img: AcademyIcon,
    activeImg: AcademyIconActive,
    title: "Academy",
    url: "/dashboard/academy/video",
  },
  {
    id: 8,
    img: PartnershipIcon,
    activeImg: PartnershipIconActive,
    title: "Partner",
    url: "/dashboard/partnerships/experts",
  },
];
