import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import FadeLoader from "react-spinners/FadeLoader";
// Images
import BackdropImage from "assets/images/backdropWave.svg";

// Video
import CreationVideo from "assets/videos/creationVideo.mp4";
import TranslationMessage from "configs/translations/TranslationMessage";
import FoodiaryLogo from "assets/images/Foodiary-Logo.PNG";
import { SidebarSteps } from "../data";

import * as S from "../OnBoardingPartTwo.styles";
import OnboardingSidebar from "pages/OnBoardingPartOne/OnboardingSidebar";

const NutritionCreationPreload = (props) => {
  let history = useHistory();

  useEffect(() => {
    document.body.style.overflowY = "hidden";
    const listenerFunction = () => {
      history.push("/onboarding/nutrition/type-update");
      document.body.style.overflowY = "auto";
    };
    const video = document.getElementById("myvid");
    video.addEventListener("ended", listenerFunction);
    return () => video.removeEventListener("ended", listenerFunction);
  }, [history]);

  return (
    <>
      <S.OnBoardingPage className="starter">
        <S.OnBoardingContainer className="onboarding-container">
          <OnboardingSidebar step={3} />
          <S.OnBoardingContent className="onboarding-content creation-video-content d-flex align-items-center relative overflow-hidden">
            <S.NutritionBackdropImage src={BackdropImage} alt="Backdrop" />
            <div className="nutrition-creation-video-box d-flex align-items-center justify-content-between gap-3">
              <S.NutritionCreationTextContainer className="d-flex flex-column align-items-center gap-3">
                <FadeLoader
                  color="#000"
                  loading
                  margin="1px"
                  height="13px"
                  size={100}
                />
                <h3 className="text-size-22-32 text-dark-1 my-0">
                  <TranslationMessage id="nutrition.creation.creating" />
                </h3>
              </S.NutritionCreationTextContainer>

              <S.NutritionCreationVideo>
                <video
                  id="myvid"
                  autoStart
                  autoPlay
                  src={CreationVideo}
                  preload={"auto"}
                  type="video/mp4"
                ></video>
              </S.NutritionCreationVideo>
            </div>
          </S.OnBoardingContent>
        </S.OnBoardingContainer>
      </S.OnBoardingPage>
    </>
  );
};

export default NutritionCreationPreload;
