import styled from "styled-components";
import {
  WhatAwaitsYouCardInnerContainer,
  WhatAwaitsYouTopImage,
  WhatAwaitsYouCardHeading,
  WhatAwaitsYouCardSubHeading,
} from "pages/whatAwaitsYou/whatAwaitsYou.styles";

export const MainDashboardContainer = styled.div`
  /* margin:3.6rem 0 0 0; */
`;



export const MainDashboardTitle = styled.div`
  color: #1c9d87;
  font-size: 14px;
`;

export const MainDashboardSubTitle = styled.div`
  color: #142c1f;
  font-size: 24px;
  margin: 10px 0 30px 0;
`;

export const MainDashboardCardContainer = styled.div`
  margin: 10px 0;
  display: flex;
  justify-content: center;
`;
export const Button = styled.button`
  padding: 12px 9px;
  font-size: 16px;
  line-height: 19px;
  border: none;
  background: #f8a492;
  border-radius: 8px;
  letter-spacing: 0px;
  color: #ffffff;
  font-family: RubikMedium;
  transition: all 0.3s;
  cursor: pointer;
  transition-duration: 0.3s;
  transition-property: transform;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;

  &:not(:disabled):hover {
    transform: scale(0.9);
  }
`;

export const HomeHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  
`;

export const MainDashboardCardInnerContainer = styled(
  WhatAwaitsYouCardInnerContainer
)``;

export const MainDashboardCardImage = styled(WhatAwaitsYouTopImage)``;

export const MainDashboardCardHeading = styled(WhatAwaitsYouCardHeading)``;

export const MainDashboardCardSubHeading = styled(
  WhatAwaitsYouCardSubHeading
)``;
