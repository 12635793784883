import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { Formik } from "formik";
import { number, object, string, boolean } from "yup";

import Input from "components/formComponents/input/Input";
import InnerSideNav from "pages/NewDasboard/components/InnerSideNav/InnerSide";
import CopyIcon from "assets/images/new-copy.png";

import * as DashboardActions from "redux/dashboard/dashboard.actions";
import * as S from "./settings.styles";
import * as GlobalStyles from "components/global/Sections.styles"

const MENUS = [
  {
    id: 1,
    title: "E-Mail Account",
    path: "/dashboard/settings/email-account",
  },
  {
    id: 2,
    title: "Temporäre Zugangsdaten",
    path: "/dashboard/settings/access-data",
  },
  {
    id: 3,
    title: "Zahlungsanbieter aktivieren",
    path: "/dashboard/settings/payment-provider",
  },
];

const EmailAccount = () => {
  const dispatch = useDispatch();

  const trainerProfileTempDetails = useSelector(
    (state) => state.dashboard.trainerProfileTempDetails
  );
  const trainerData = useSelector((state) => state.auth.trainerData);

  useEffect(() => {
    dispatch(
      DashboardActions.getTrainerProfileTempDetailsRequest({
        payload: { trainerID: trainerData?.trainerID },
        callback: () => null,
      })
    );
  }, []);

  const initialValues = {
    prename: "",
    surname: "",
  };

  const validationSchema = object().shape({
    prename: string().typeError("You must enter a String").required("Required"),
    surname: string().typeError("You must enter a String").required("Required"),
    email: string().email().typeError("You must enter a String"),
    phoneNumber: string(),
    sex: string(),
    dob: string(),
    subtitle: string(),
    specilization: string(),
    nutiritionistSince: number().typeError("You must enter a number"),
    customers: number().typeError("You must enter a number"),
    practicalExperience: string(),
  });

  const onFormSubmit = (values, { resetForm }) => {
    const formData = new FormData();

    formData.append("FranchiseID", values.franchiseId);
    formData.append("prename", values.prename);
    formData.append("surname", values.surname);
    formData.append("email", values.email);

    // dispatch(
    //   AuthActions.updateTrainerDetailsRequest({
    //     formData,
    //     funcSuccess: (res) => {
    //       if (res?.success) {
    //         alert.success("Dein Profil wurde erfolgreich geändert", {
    //           timeout: 5000,
    //         });
    //       }

    //       if (!res?.success) {
    //         alert.error("Deine Änderung konnte nicht gespeichert werden", {
    //           timeout: 5000,
    //         });
    //       }
    //       getTrainerProfileDetails();
    //     },
    //   })
    // );
    resetForm();
  };

  const copyToClipBoard = (text) => {
    navigator.clipboard.writeText(text);
  };

  return (
    <>
      <S.Container>
        <div className="second-sidebar-wrapper">
          <InnerSideNav menus={MENUS} hideUserInfo></InnerSideNav>
        </div>
        <S.Wrapper className="second-sidebar-content">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onFormSubmit}
            enableReinitialize
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              touched,
              errors,
              isValid,
              dirty,
            }) => (
              <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                <S.TrainerProfileDetailsInnerContainer>
                  <GlobalStyles.SectionHeadline>
                    Deinen Foodiary Mail Account einrichten
                  </GlobalStyles.SectionHeadline>
                  <GlobalStyles.SubHeadline>
                    Deinen Mail Account kannst du ganz einfach mit Apple, Google und weiteren Clients verbinden oder über unser Mail Portal verwenden.
                  </GlobalStyles.SubHeadline>

                  <br />
                  <S.Divider />
                  <br />

                  <S.SectionTitle>
                    Server für eintreffende E-Mails
                  </S.SectionTitle>

                  <S.InlineControls>
                    <S.FormControl>
                      <S.TrainerProfileDetailsSection1InputLabel className="info-label">
                        HOSTNAME
                      </S.TrainerProfileDetailsSection1InputLabel>

                      <Input
                        className="input-custom copy-text"
                        filled
                        style={{ width: "100%", marginBottom: "10px" }}
                        placeholder=""
                        disabled
                        name="calendalyURLBasic"
                        onChange={handleChange}
                        value={"mail.lead-online.de"}
                      />
                      <img
                        src={CopyIcon}
                        alt="CopyIcon"
                        onClick={() => copyToClipBoard("mail.lead-online.de")}
                      />
                    </S.FormControl>
                    <S.FormControl>
                      <S.TrainerProfileDetailsSection1InputLabel className="info-label">
                        BENUTZERNAME
                      </S.TrainerProfileDetailsSection1InputLabel>

                      <Input
                        className="input-custom copy-text"
                        filled
                        style={{ width: "100%", marginBottom: "10px" }}
                        placeholder=""
                        disabled
                        name="calendalyURLBasic"
                        onChange={handleChange}
                        value={trainerProfileTempDetails?.data?.foodiaryEmail}
                      />
                      <img
                        src={CopyIcon}
                        alt="CopyIcon"
                        onClick={() =>
                          copyToClipBoard(
                            trainerProfileTempDetails?.data?.foodiaryEmail
                          )
                        }
                      />
                    </S.FormControl>
                    <S.FormControl>
                      <S.TrainerProfileDetailsSection1InputLabel className="info-label">
                        PASSWORT
                      </S.TrainerProfileDetailsSection1InputLabel>

                      <Input
                        className="input-custom copy-text"
                        filled
                        style={{ width: "100%", marginBottom: "10px" }}
                        placeholder=""
                        disabled
                        name="calendalyURLBasic"
                        onChange={handleChange}
                        value={trainerProfileTempDetails?.data?.foodiaryEmailTempPwd}
                      />
                      <img
                        src={CopyIcon}
                        alt="CopyIcon"
                        onClick={() =>
                          copyToClipBoard(trainerProfileTempDetails?.data?.foodiaryEmailTempPwd)
                        }
                      />
                    </S.FormControl>
                    <S.FormControl>
                      <S.TrainerProfileDetailsSection1InputLabel className="info-label">
                        PROTOKOLL
                      </S.TrainerProfileDetailsSection1InputLabel>

                      <Input
                        className="input-custom copy-text"
                        filled
                        style={{ width: "100%", marginBottom: "10px" }}
                        placeholder=""
                        disabled
                        name="calendalyURLBasic"
                        onChange={handleChange}
                        value={"IMAP"}
                      />
                      <img
                        src={CopyIcon}
                        alt="CopyIcon"
                        onClick={() => copyToClipBoard("IMAP")}
                      />
                    </S.FormControl>
                    <S.FormControl>
                      <S.TrainerProfileDetailsSection1InputLabel className="info-label">
                        SERVER PORT
                      </S.TrainerProfileDetailsSection1InputLabel>

                      <Input
                        className="input-custom copy-text"
                        filled
                        style={{ width: "100%", marginBottom: "10px" }}
                        placeholder=""
                        disabled
                        name="calendalyURLBasic"
                        onChange={handleChange}
                        value={"993"}
                      />
                      <img
                        src={CopyIcon}
                        alt="CopyIcon"
                        onClick={() => copyToClipBoard("993")}
                      />
                    </S.FormControl>
                  </S.InlineControls>

                  <S.SectionTitle>Server für ausgehende E-Mails</S.SectionTitle>
                  <S.InlineControls>
                    <S.FormControl>
                      <S.TrainerProfileDetailsSection1InputLabel className="info-label">
                        HOSTNAME
                      </S.TrainerProfileDetailsSection1InputLabel>

                      <Input
                        className="input-custom copy-text"
                        filled
                        style={{ width: "100%", marginBottom: "10px" }}
                        placeholder=""
                        disabled
                        name="calendalyURLBasic"
                        onChange={handleChange}
                        value={"mail.lead-online.de"}
                      />
                      <img
                        src={CopyIcon}
                        alt="CopyIcon"
                        onClick={() => copyToClipBoard("mail.lead-online.de")}
                      />
                    </S.FormControl>
                    <S.FormControl>
                      <S.TrainerProfileDetailsSection1InputLabel className="info-label">
                        BENUTZERNAME
                      </S.TrainerProfileDetailsSection1InputLabel>

                      <Input
                        className="input-custom copy-text"
                        filled
                        style={{ width: "100%", marginBottom: "10px" }}
                        placeholder=""
                        disabled
                        name="calendalyURLBasic"
                        onChange={handleChange}
                        value={trainerProfileTempDetails?.data?.foodiaryEmail}
                      />
                      <img
                        src={CopyIcon}
                        alt="CopyIcon"
                        onClick={() =>
                          copyToClipBoard(
                            trainerProfileTempDetails?.data?.foodiaryEmail
                          )
                        }
                      />
                    </S.FormControl>
                    <S.FormControl>
                      <S.TrainerProfileDetailsSection1InputLabel className="info-label">
                        PASSWORT
                      </S.TrainerProfileDetailsSection1InputLabel>

                      <Input
                        className="input-custom copy-text"
                        filled
                        style={{ width: "100%", marginBottom: "10px" }}
                        placeholder=""
                        disabled
                        name="calendalyURLBasic"
                        onChange={handleChange}
                        value={trainerProfileTempDetails?.data?.foodiaryEmailTempPwd}
                      />
                      <img
                        src={CopyIcon}
                        alt="CopyIcon"
                        onClick={() =>
                          copyToClipBoard(trainerProfileTempDetails?.data?.foodiaryEmailTempPwd)
                        }
                      />
                    </S.FormControl>
                    <S.FormControl>
                      <S.TrainerProfileDetailsSection1InputLabel className="info-label">
                        PROTOKOLL
                      </S.TrainerProfileDetailsSection1InputLabel>

                      <Input
                        className="input-custom copy-text"
                        filled
                        style={{ width: "100%", marginBottom: "10px" }}
                        placeholder=""
                        disabled
                        name="calendalyURLBasic"
                        onChange={handleChange}
                        value={"SMPT-Server"}
                      />
                      <img
                        src={CopyIcon}
                        alt="CopyIcon"
                        onClick={() => copyToClipBoard("SMPT-Server")}
                      />
                    </S.FormControl>
                    <S.FormControl>
                      <S.TrainerProfileDetailsSection1InputLabel className="info-label">
                        SERVER PORT
                      </S.TrainerProfileDetailsSection1InputLabel>

                      <Input
                        className="input-custom copy-text"
                        filled
                        style={{ width: "100%", marginBottom: "10px" }}
                        placeholder=""
                        disabled
                        name="calendalyURLBasic"
                        onChange={handleChange}
                        value={"465"}
                      />
                      <img
                        src={CopyIcon}
                        alt="CopyIcon"
                        onClick={() => copyToClipBoard("465")}
                      />
                    </S.FormControl>
                  </S.InlineControls>
                </S.TrainerProfileDetailsInnerContainer>
              </form>
            )}
          </Formik>
        </S.Wrapper>
      </S.Container>
    </>
  );
};

export default EmailAccount;
