import styled from "styled-components";
import TimeInput from "material-ui-time-picker";

export const TimePickerContainer = styled.span`
  position: relative;
`;

export const CustomTimePicker = styled(TimeInput)`
  width: 96px;
  border-bottom: none;

  &.MuiInput-underline::before,
  &.MuiInput-underline::after {
    transition: none;
    border-bottom: none !important;
  }

  & > input {
    padding-left: 10px;
    color: #2C2C2C;
    box-shadow: 0px 1px 2px #e4e5e73d;
    border: 2px solid #e2e4e9;
    border-radius: 8px;
    font-size: 1rem;
    /* height: 20px; */
  }
`;

export const DownArrowIconContainer = styled.img`
  position: absolute;
  right: 15px;
  top: 15px;
`;
