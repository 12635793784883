import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  setUserRegistrationDataInLocalStorage,
  getUserRegistrationDataFromLocalStorage,
} from "configs/utils";
// Components
import Button from "components/formComponents/button/Button";
import Switch from "components/formComponents/switch/Switch";

import MinusIcon from "assets/images/Onboarding/Screen6/Icon-minus.svg";
import PlusIcon from "assets/images/Onboarding/Screen6/Icon-plus-2.svg";
import ArrowLeft from "assets/images/arrowLeft.svg";

import { TrainingDaysData } from "../data";
// Redux
import * as S from "../OnBoardingPartOne.styles";
import OnboardingSidebar from "../OnboardingSidebar";

const TrainingDays = (props) => {
  const history = useHistory();
  const registrationData = useSelector((state) => state.auth.registrationData);

  const [training, setTraining] = useState(false);
  const [TrainingDays, SetTrainingDays] = useState(TrainingDaysData);
  const [WeeklySessionsCount, SetWeeklySessionsCount] = useState("");
  const [IsValidation, SetIsValidation] = useState(false);

  useEffect(() => {
    const t = getUserRegistrationDataFromLocalStorage()?.trainingDays;
    const trainingSessionsPerWeek =
      getUserRegistrationDataFromLocalStorage()?.trainingSessionsPerWeek;

    const isTraining = getUserRegistrationDataFromLocalStorage()?.isTraining;
    setTraining(isTraining);

    if (t) {
      SetTrainingDays(t);
    }

    SetWeeklySessionsCount(trainingSessionsPerWeek?.toString() || "");
  }, []);

  useEffect(() => {}, [TrainingDays]);

  const onSelectTrainingDays = (item) => {
    SetIsValidation(false);
    SetTrainingDays(
      TrainingDays.map((day) => {
        if (day.id === item.id) {
          return {
            ...day,
            isActive: !day.isActive,
          };
        }
        return day;
      })
    );
  };

  const handleSwitch = (checked) => {
    SetIsValidation(false);
    setTraining(checked);
    setUserRegistrationDataInLocalStorage({
      ...getUserRegistrationDataFromLocalStorage(),
      isTraining: checked,
    });

    if (checked) {
      SetTrainingDays(TrainingDaysData);
      setUserRegistrationDataInLocalStorage({
        ...getUserRegistrationDataFromLocalStorage(),
        trainingDays: null,
      });
    }
  };

  const handleNext = () => {
    const isValid = TrainingDays.some((i) => i.isActive);
    console.log("isValid", isValid);
    console.log("training", training);
    console.log("WeeklySessionsCount", WeeklySessionsCount);

    if (
      (!isValid && !training) ||
      (training && !WeeklySessionsCount.toString())
    ) {
      SetIsValidation(true);
      return;
    }

    if (isValid || (training && WeeklySessionsCount.toString())) {
      setUserRegistrationDataInLocalStorage({
        ...getUserRegistrationDataFromLocalStorage(),
        trainingDays: TrainingDays,
        trainingSessionsPerWeek: WeeklySessionsCount || null,
        isTraining: training,
      });

      history.push("/onboarding/registration/diet-categories");
    }
  };

  const handleBack = () => {
    history.push("/onboarding/registration/activity-level");
  };

  return (
    <>
      <S.OnBoardingPage className="starter">
        <S.OnBoardingContainer className="onboarding-container">
          <OnboardingSidebar step={2} />
          <S.OnBoardingContent className="onboarding-content">
            <S.OnBoardingHeader className="onboarding-header">
              <div className="d-flex align-items-top gap-3">
                <img
                  className="cursor-pointer"
                  src={ArrowLeft}
                  alt="back"
                  onClick={handleBack}
                />
                <div className="content">
                  <span className="small-title text-green font-rubik-medium">
                    Anamnese
                  </span>
                  <h6 className="header-title font-rubik-medium my-0">
                    An welchen Tagen bist du aktiv?
                  </h6>
                </div>
              </div>
              <div className="actions">
                <Button
                  color="rgba(248, 164, 146, 1)"
                  gradient="linear-gradient(60deg, rgba(46, 207, 150, 1), rgba(28, 157, 135, 1)) !important"
                  margin="0"
                  absolute={{ width: "fit-content", height: "2.5rem" }}
                  buttonStyle={{ padding: ".5625rem 2.5rem" }}
                  onClick={handleNext}
                >
                  Weiter
                </Button>
              </div>
            </S.OnBoardingHeader>
            <S.OnBoardingBody className="onboarding-body">
              <div className="activity-level-content">
                <h3 className="text-size-20-28 text-dark-1 font-rubik-medium">
                  An welchen Tagen trainierst du?
                </h3>
                <p className="text-size-16-19 text-light-grey-1 font-rubik-regular">
                  Verrate uns, an welchen Tagen du sportlich aktiv bist.
                </p>
                <br />
                {IsValidation && (
                  <p className="text-size-14-18 text-right text-red font-rubik-regular mt-0">
                    Bitte wähle mindestens ein Trainingstag
                  </p>
                )}
                <div className="activity-level-items">
                  {TrainingDays.map((item, i) => (
                    <div
                      key={i}
                      className={
                        training
                          ? "activity-level-item disabled"
                          : "activity-level-item"
                      }
                      onClick={() =>
                        training ? null : onSelectTrainingDays(item)
                      }
                    >
                      <h6 className="text-size-18-22 font-rubik-medium text-dark-2 my-0">
                        {item.title}
                      </h6>
                      <div
                        className={
                          !training
                            ? "form-group-c medium"
                            : "form-group-c medium disabled"
                        }
                      >
                        <input
                          type="checkbox"
                          id={item.title}
                          checked={item.isActive}
                          onChange={() =>
                            training ? null : onSelectTrainingDays(item)
                          }
                        />
                        <label htmlFor={item.title}></label>
                        <div className="overlay cursor-pointer"></div>
                      </div>
                    </div>
                  ))}
                </div>
                <br />
                <div className="divider"></div>
                <br />
                <div className="total-activities-time d-flex align-items-center justify-content-between">
                  <div>
                    <p className="text-size-16-28 font-rubik-medium text-dark-2 my-0">
                      An welchen Tagen trainierst du?
                    </p>
                  </div>
                  <div className="d-flex align-items-center gap-2">
                    <p className="text-size-16-22 font-rubik-regular text-dark-1 my-0">
                      Feste Trainingstage
                    </p>
                    <Switch
                      switchedOn={training}
                      onChange={(e) => handleSwitch(e.target.checked)}
                    />
                  </div>
                </div>
                <br />
                {training && (
                  <div className="weekly-sessions-item d-flex align-items-center justify-content-between">
                    <p className="text-size-18-22 font-rubik-medium text-dark-1 my-0">
                      Trainingseinheiten pro Woche
                    </p>

                    <div className="input-incr-decr">
                      <button
                        onClick={() => {
                          if (!training) return;
                          if (+WeeklySessionsCount > 0) {
                            SetWeeklySessionsCount((+WeeklySessionsCount - 1).toString());
                            SetIsValidation(false);
                          }
                        }}
                      >
                        <img src={MinusIcon} alt="minus" />
                      </button>
                      <input
                        type="text"
                        value={WeeklySessionsCount}
                        onChange={(e) => {
                          if (isNaN(+e.target.value)) return;

                          SetWeeklySessionsCount(e.target.value);
                          SetIsValidation(false);
                        }}
                      />
                      <button
                        onClick={() => {
                          if (!training) return;
                          SetWeeklySessionsCount((+WeeklySessionsCount + 1).toString());
                          SetIsValidation(false);
                        }}
                      >
                        <img src={PlusIcon} alt="plus" />
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </S.OnBoardingBody>
          </S.OnBoardingContent>
        </S.OnBoardingContainer>
      </S.OnBoardingPage>
    </>
  );
};

export default TrainingDays;
