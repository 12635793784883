import React from 'react';
import { useHistory } from "react-router-dom";
import * as S from "./healthScoreTips.styles";
import { getRandomValueInRange } from "configs/utils";

// Components
import TranslationMessage from 'configs/translations/TranslationMessage';
import Topbar from 'components/topbar/Topbar';
import Card from 'components/card/Card';
import Ratings from 'components/ratings/Ratings';
import MultiCarousel from 'components/multiCarousel/MultiCarousel';

// Images
import WaterDrop from "assets/images/waterDrop.svg";
import WaterDropBackground from "assets/images/waterDropBackground.svg";
import SleepingBed from "assets/images/sleepingBed.svg";
import SleepingBedBackground from "assets/images/sleepingBedBackground.svg";

const HealthScoreTips = () => {
    const history = useHistory();

    const CardData = [
        {
            id: "card1",
            heading: "healthScore.tips.card1.heading",
            description: "healthScore.tips.card1.description",
            icon: WaterDrop,
            iconBackground: WaterDropBackground,
            benefits: [
                { benefit: "healthScore.tips.card1.p1", rating: 1 },
                { benefit: "healthScore.tips.card1.p2", rating: 1 },
                { benefit: "healthScore.tips.card1.p3", rating: 1 },
                { benefit: "healthScore.tips.card1.p4", rating: 4 },
            ],
        },
        {
            id: "card2",
            heading: "healthScore.tips.card2.heading",
            description: "healthScore.tips.card2.description",
            icon: SleepingBed,
            iconBackground: SleepingBedBackground,
            benefits: [
                { benefit: "healthScore.tips.card1.p1", rating: 4 },
                { benefit: "healthScore.tips.card1.p2", rating: 0 },
                { benefit: "healthScore.tips.card1.p4", rating: 4 },
            ],
        },
        {
            id: "card3",
            heading: "healthScore.tips.card3.heading",
            description: "healthScore.tips.card3.description",
            icon: SleepingBed,
            iconBackground: SleepingBedBackground,
            benefits: [
                { benefit: "healthScore.tips.card1.p1", rating: 3 },
                { benefit: "healthScore.tips.card1.p2", rating: 4 },
                { benefit: "healthScore.tips.card1.p3", rating: 4 },
                { benefit: "healthScore.tips.card1.p4", rating: 5 },
            ],
        },
        {
            id: "card4",
            heading: "healthScore.tips.card4.heading",
            description: "healthScore.tips.card4.description",
            icon: SleepingBed,
            iconBackground: SleepingBedBackground,
            benefits: [
                { benefit: "healthScore.tips.card1.p1", rating: 1 },
                { benefit: "healthScore.tips.card1.p2", rating: 2 },
                { benefit: "healthScore.tips.card1.p3", rating: 2 },
                { benefit: "healthScore.tips.card1.p4", rating: 4 },
            ],
        },

    ]


    return (
        <>
            {/* healthReport */}
            {/* prev="/healthscore/evaluation" */}
            
            <Topbar prev="/healthscore/anamnese_evaluations" next="/healthscore/improvement" progress={'45%'} percent={getRandomValueInRange(40, 45)} onClose={() => history.push('/login')} close />
            <S.HealthScoreTipsContainer>
                <S.HealthScoreTipsHeading>
                    <TranslationMessage id="healthScore.tips.heading" />
                </S.HealthScoreTipsHeading>
                <S.HealthScoreTipsSubHeading>
                    <TranslationMessage id="healthScore.tips.subHeading" />
                </S.HealthScoreTipsSubHeading>
                <S.HealthScoreTipsCardContainer>
                    <MultiCarousel itemClass="health-score-tips-carousel" totalItems={2} slidesToSlide={1} noAutoPlay>
                        {CardData.map((card) => {
                            return (
                                <Card
                                    width="95%"
                                    height="375px"
                                    margin="auto"
                                    textAlign="left"
                                    key={card.id}
                                >
                                    <S.HealthScoreTipsCardInnerContainer>
                                        <S.HealthScoreTipsHeadingContainer>
                                            <div>
                                                <img src={card.iconBackground} alt="IconBackground" />
                                                <S.HealthScoreTipsHeadingIcon src={card.icon} alt="Icon" />
                                            </div>
                                            <S.HealthScoreTipsCardHeading>
                                                <TranslationMessage id={card.heading} />
                                            </S.HealthScoreTipsCardHeading>
                                        </S.HealthScoreTipsHeadingContainer>
                                        <S.HealthScoreTipsBenefitContainer>
                                            {card.benefits.map(benefit => {
                                                return (
                                                    <S.HealthScoreTipsBenefitsContainer>
                                                        <S.HealthScoreTipsBenefits>
                                                            <TranslationMessage id={benefit.benefit} />
                                                        </S.HealthScoreTipsBenefits>
                                                        <Ratings rating={benefit.rating} />
                                                    </S.HealthScoreTipsBenefitsContainer>
                                                )
                                            })}
                                        </S.HealthScoreTipsBenefitContainer>
                                        <S.HealthScoreTipsDescription>
                                            <TranslationMessage id={card.description} />
                                        </S.HealthScoreTipsDescription>
                                    </S.HealthScoreTipsCardInnerContainer>
                                </Card>
                            )
                        })}
                    </MultiCarousel>
                </S.HealthScoreTipsCardContainer>
            </S.HealthScoreTipsContainer>
        </>
    )
}

export default HealthScoreTips