import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getS3ImageUrl } from "configs/utils";

import * as DashboardActions from "redux/dashboard/dashboard.actions";

import * as S from "./whitePaper.styles";
import PDF from "assets/images/pdf.jpeg";
import WhitePaperImage from "assets/images/white-paper.jpeg";
import InnerSideNav from "pages/NewDasboard/components/InnerSideNav/InnerSide";
import { MENUS } from "../data";
import * as GlobalStyles from "components/global/Sections.styles"

const WhitePaper = () => {
  const dispatch = useDispatch();

  const allWhitePaperData = useSelector(
    (state) => state.dashboard.allWhitePaperData
  );

  const isAllWhitePaperDataLoading = useSelector(
    (state) => state.dashboard.isAllWhitePaperDataLoading
  );

  useEffect(() => {
    dispatch(
      DashboardActions.getAllWhitePaperDataRequest({
        callback: () => null,
      })
    );
  }, []);

  return (
    <>
      <S.Container className="whit-paper-page">
        <div className="second-sidebar-wrapper">
          <InnerSideNav menus={MENUS} hideUserInfo></InnerSideNav>
        </div>
        <S.Wrapper className="second-sidebar-content">
          <S.MCard>
            <GlobalStyles.SectionHeadline>
              Whitepaper
            </GlobalStyles.SectionHeadline>
            <GlobalStyles.SubHeadline>
              Erweitere dein Wissen über Schlüsselaspekte der Selbstständigkeit.
            </GlobalStyles.SubHeadline>

            <br />
            <S.Divider />
            <br />

            <S.WhitePaperWrap>
              <S.WhitePaperTitle>
                {allWhitePaperData?.length} Whitepaper verfügbar
              </S.WhitePaperTitle>

              {isAllWhitePaperDataLoading && (
                <>
                <br />
                <br />
                <br />
                <br />
                <div className="text-center">
                  <div className="loader mx-auto"></div>
                </div>
                <br />
                <br />
                <br />
                <br />
              </>
              )}
              <S.WhitePaperInner>
                {!isAllWhitePaperDataLoading &&
                  allWhitePaperData.map((item) => (
                    <S.WhitePaperInnerCard>
                      <S.WhitePaperCardImage>
                        <img
                          src={
                            item?.Thumbnail
                              ? getS3ImageUrl(item?.Thumbnail)
                              : WhitePaperImage
                          }
                          alt="white-paper"
                        />
                      </S.WhitePaperCardImage>

                      <S.WhitePaperCardText>
                        <h3>{item?.Titel}</h3>
                        <p>
                          In den Whitepapers lernst du mehr über die
                          Selbstständigkeit mit Foodiary und wichtige Tipps und
                          Tricks.
                        </p>
                        <a
                          href={getS3ImageUrl(item?.download_file)}
                          target="_blank"
                          rel="noreferrer"
                          download
                        >
                          <img src={PDF} alt="white-paper" />
                          PDF HERUNTERLADEN
                        </a>
                      </S.WhitePaperCardText>
                    </S.WhitePaperInnerCard>
                  ))}
              </S.WhitePaperInner>
            </S.WhitePaperWrap>
          </S.MCard>
        </S.Wrapper>
      </S.Container>
    </>
  );
};

export default WhitePaper;
