import styled from "styled-components";
import {
  PersonalDataRulesContainer,
  PersonalDataRulesLabel,
  RegistrationContainer,
  RegistrationHeading,
  RegistrationSubHeading,
} from "pages/registration/registration.styles";

export const SubscriptionDetailsContainer = styled(RegistrationContainer)``;

export const SubscriptionDetailsHeading = styled(RegistrationHeading)``;

export const SubscriptionDetailsSubHeading = styled(RegistrationSubHeading)``;

export const SubscriptionDetailsProInnerContainer = styled.div`
  margin: 41px 37px 30px;
  hr {
    background: #dddddd;
    margin-top: 40px;
  }
`;

export const SubscriptionDetailsProInnerHeading = styled.div`
  font-size: 20px;
  color: #142c1f;
  margin-bottom: 30px;
`;

export const SubscriptionDetailsProInnerDetailsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const SubscriptionDetailsProInnerDetailsFlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: ${(props) => (props.width ? props.width : "100%")};
`;

export const SubscriptionDetailsProInnerIconContainer = styled.div`
  width: 90px;
  height: 90px;
  background-color: #142c1f;
  border-radius: 10px;
  color: white;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SubscriptionDetailsStaticContainer = styled.div`
  width: 90px;
  height: 90px;
  background-color: #a5b5af;
  border-radius: 10px;
  color: white;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SubscriptionDetailsProInnerDetailsHeading = styled.div`
  font-size: 18px;
  color: #142c1f;
  margin-top: 10px;
  margin-bottom: 5px;
`;

export const SubscriptionDetailsProInnerDetailsSubHeading = styled.div`
  font-size: 14px;
  color: #a5b5af;
  font-family: RubikRegular;
  margin-bottom: 10px;
`;

export const SubscriptionDetailsProInnerDetailsDescription = styled.div`
  font-size: 14px;
  color: #44554c;
  font-family: RubikRegular;
  margin-bottom: 0px;
`;

export const SubscriptionDetailsProInnerDetailsOneTimePayment = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-family: RubikMedium;
  color: #2ecf96;
  font-size: 16px;
`;

export const SubscriptionDetailsProInnerDetailsCostContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 18px;
  gap: 0.3rem;
`;

export const SubscriptionDetailsProInnerDetailsCostWithBg = styled.div`
  background-color: #f5f5f5;
  color: #44554c;
  font-size: 18px;
  width: max-content;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-radius: 8px;
  margin-bottom: 7px;
`;

export const SubscriptionDetailsRulesContainer = styled(
  PersonalDataRulesContainer
)``;

export const SubscriptionDetailsRulesLabel = styled(PersonalDataRulesLabel)`
  width: 600px;
`;

export const SubscriptionDetailsTotalContainer = styled.div`
  display: flex;
  padding: 15px 15px 0;
  padding-right: 15px;

  justify-content: space-between;
`;
export const SubscriptionDetailsTotalHeading = styled.div`
  font-size: 20px;
  color: #142c1f;
`;
export const SubscriptionDetailsTotalContainerValueColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: end;
  margin-left: 10px;
`;

export const SubscriptionDetailsTotalContainerValue = styled.div`
  color: #1c9d87;
  font-size: 20px;
  font-family: RubikMedium;
  margin-bottom: 7px;
`;
export const SubscriptionDetailsTotalDescription = styled.div`
  font-size: 14px;
  color: #a5b5af;
  font-family: RubikRegular;
`;

export const SubscriptionDetailsPromoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1.2rem;
`;
export const SubscriptionDetailsPromoHeadings = styled.div`
  display: flex;
  flex: 0 0 40%;
  flex-direction: column;
  h5 {
    font-size: 14px;
    color: #1c9d87;
    font-family: RubikRegular;
    margin: 0 0 2px 0;
  }
  h6 {
    font-size: 18px;
    color: #142c1f;
    font-family: RubikRegular;
    margin: 0;
  }
`;
export const SubscriptioninputBtnContainer = styled.div`
  display: flex;
  flex: 0 0 60%;
`;
export const SubscriptionDataInputContainer = styled.div`
  display: flex;

  input {
    background-color: rgba(165, 181, 175, 0.15);
    color: #a5b5af;
    -webkit-text-fill-color: #a5b5af;
    font-size: 14px;
    border: none;
    width: auto;
    padding: 11px 15px;
    border-radius: 5px;
    outline: none;
    margin: 0 auto;
  }
`;
