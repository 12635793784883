import styled from "styled-components";


export const EvaluationCard = styled.div`
  background: #ffffff;
  border: 2px solid #EEEEEE;
  border-radius: 8px;
  padding: 1.25rem;
  .title {
    color: #142c1f;
    font-size: 18px;
    line-height: 22px;
    margin-block: 0;
    color:#142C1F;
  }

  .desc {
    color: #44554c;
    font-size: 16px;
    line-height: 24px;
    margin: .6rem 0 1.25rem;
    font-weight: 400;
    
  }

  .eval-desc {
    color: #818D88;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    margin:0px;
  }

  .evaluation-items {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    gap: 5px;
    .evaluation-item {
      padding: 5px;
      border: 2px solid transparent;
      border-radius: 15px;
      text-align: center;
      cursor: pointer;

      p {
        margin: 12px 0 0;
        font-size: 14px;
        color: #A5B5AF;
        font-weight: 400;
      }
      

      &.active {
        border-color: #142c1f;

        p {
          color: #142C1F;
        }
      }

      

      .status-bar {
        width: 100%;
        height: 15px;
        border-radius: 10px;

        &.green-1 {
          background: rgba(46, 207, 150, 0.25);
        }
        &.green-2 {
          background: rgba(46, 207, 150, 0.5);
        }
        &.green-3 {
          background: rgba(46, 207, 150, 0.75);
        }
        &.green-4 {
          background: rgba(46, 207, 150, 1);
        }

        &.light-green {
          background: #a7e140;
        }

        &.light-orange {
          background: #fed400;
        }

        &.orange {
          background: #f39b29;
        }

        &.red-1 {
          background: rgba(226, 104, 104, 0.25);
        }
        &.red-2 {
          background: rgba(226, 104, 104, 0.5);
        }
        &.red-3 {
          background: rgba(226, 104, 104, 0.75);
        }
        &.red-4 {
          background: rgba(226, 104, 104, 1);
        }
      }

      img {
        margin-top: 5px;
        width: 20px;
        height: 20px;
      }
    }
  }
`;
