import React from "react";
import * as S from "./datePicker.styles";
import { DatePicker as AntdDatePicker } from "antd";
import dayjs from "dayjs";

const DatePicker = (props) => {
  const {
    height,
    onDateChange,
    value,
    placeholder,
    width,
    format,
    margin
  } = props
  return (
    <S.DatePickerContainer>
      <AntdDatePicker
        style={{ width: width || '100%', height: height || '40.5px', borderRadius: '10px', ...(margin && { margin }) }}
        onChange={value => onDateChange(dayjs(value).format('YYYY-MM-DD'))}
        // defaultValue={dayjs(value, 'YYYY-MM-DD')}
        format={format ?? 'DD.MM.YYYY'}
        placeholder={placeholder}
        value={value ? dayjs(value, 'YYYY-MM-DD') : null}
        //hide calender icon
        suffixIcon={null}
        
      />
    </S.DatePickerContainer>
  );
};

export default DatePicker;
