import React from "react";
import * as S from "./evaluationCard.styles";

const EvaluationCard = ({ item }) => {
  return (
    <S.EvaluationCard>
      <h3 className="title text-size-18-22 text-dark-1">{item?.title}</h3>
      <p className="desc text-size-16-24 text-dark-2">{item?.descr}</p>
      <div className="evaluation-items">
        {(item.isEmoji || item.isShape) &&
          item.images.map((i, index) => (
            <div
              key={index} // Added unique key prop
              className={
                i.isActive
                  ? "evaluation-item d-flex flex-column align-items-center justify-content-between active"
                  : "evaluation-item d-flex flex-column align-items-center justify-content-between"
              }
            >
              <img src={i.img} alt="" />
              <div className={"status-bar " + i.color}></div>
              <div className="form-group-c small">
                <input
                  type="checkbox"
                  id={`image-${index}`}
                  checked={i.isActive}
                  readOnly
                />
                <label htmlFor={`image-${index}`}></label>
              </div>
            </div>
          ))}

        {item.isNumber &&
          item.numbers.map((i, index) => (
            <div
              key={index} // Added unique key prop
              className={
                i.isActive
                  ? "evaluation-item d-flex flex-column align-items-center justify-content-between active"
                  : "evaluation-item d-flex flex-column align-items-center justify-content-between"
              }
            >
              <p className="text-size-14-17 text-dark-1">{i.title}</p>
              <div className={"status-bar " + i.color}></div>
              <div className="form-group-c small">
                <input
                  type="checkbox"
                  id={`number-${index}`}
                  checked={i.isActive}
                  readOnly
                />
                <label htmlFor={`number-${index}`}></label>
              </div>
            </div>
          ))}
      </div>
      <p className="eval-desc text-size-14-18 text-light-grey-1">
        {item?.descr2}
      </p>
    </S.EvaluationCard>
  );
};

export default EvaluationCard;
