import OutsideAlerter from "components/OutsideAlerter";
import React from "react";
import * as S from "./modal.styles";

const Modal = (props) => {
  return (
    <S.ModalBackdrop show={props.show} key={props.key}>
      <OutsideAlerter closeModal={props.setShow}>
        <S.ModalContainer width={props.width} height={props.height}>
          <S.ModalInnerContainer>
            {props.heading ? (
              <S.ModalHeader className="modal-header">
              {props.heading}
            </S.ModalHeader>
            ) : ''}
            <S.ModalBody className="modal-body">{props.children}</S.ModalBody>
          </S.ModalInnerContainer>
        </S.ModalContainer>
      </OutsideAlerter>
    </S.ModalBackdrop>
  );
};

export default Modal;
