import SupplementsActionTypes from "./supplements.types";

// Get Supplements Data
export const getSupplementsRequest = () => ({
  type: SupplementsActionTypes.GET_SUPPLEMENTS_REQUEST,
});

export const getSupplementsSuccess = (response) => ({
  type: SupplementsActionTypes.GET_SUPPLEMENTS_SUCCESS,
  payload: response,
});

export const getSupplementsFailure = (error) => ({
  type: SupplementsActionTypes.GET_SUPPLEMENTS_FAILURE,
  payload: error,
});

// Delete Mail Chimp User
export const deleteMailChimpUserRequest = (payload) => ({
  type: SupplementsActionTypes.DELETE_MAIL_CHIMP_USER_REQUEST,
  payload: payload,
});

export const deleteMailChimpUserSuccess = (response) => ({
  type: SupplementsActionTypes.DELETE_MAIL_CHIMP_USER_SUCCESS,
  payload: response,
});

export const deleteMailChimpUserFailure = (error) => ({
  type: SupplementsActionTypes.DELETE_MAIL_CHIMP_USER_FAILURE,
  payload: error,
});

// Add Pro Mail Chimp User Data
export const addProMailChimpUserDataRequest = (payload) => ({
  type: SupplementsActionTypes.ADD_PRO_USER_MAIL_CHIMP_DATA_REQUEST,
  payload: payload,
});

export const addProMailChimpUserDataSuccess = (response) => ({
  type: SupplementsActionTypes.ADD_PRO_USER_MAIL_CHIMP_DATA_SUCCESS,
  payload: response,
});

export const addProMailChimpUserDataFailure = (error) => ({
  type: SupplementsActionTypes.ADD_PRO_USER_MAIL_CHIMP_DATA_FAILURE,
  payload: error,
});

// Add Basic Mail Chimp User Data
export const addBasicMailChimpUserDataRequest = (payload) => ({
  type: SupplementsActionTypes.ADD_BASIC_USER_MAIL_CHIMP_DATA_REQUEST,
  payload: payload,
});

export const addBasicMailChimpUserDataSuccess = (response) => ({
  type: SupplementsActionTypes.ADD_BASIC_USER_MAIL_CHIMP_DATA_SUCCESS,
  payload: response,
});

export const addBasicMailChimpUserDataFailure = (error) => ({
  type: SupplementsActionTypes.ADD_BASIC_USER_MAIL_CHIMP_DATA_FAILURE,
  payload: error,
});

// Update User Subscription
export const updateUserSubscriptionRequest = (payload) => ({
  type: SupplementsActionTypes.UPDATE_USER_SUBSCRIPTION_REQUEST,
  payload: payload,
});

export const updateUserSubscriptionSuccess = (response) => ({
  type: SupplementsActionTypes.UPDATE_USER_SUBSCRIPTION_SUCCESS,
  payload: response,
});

export const updateUserSubscriptionFailure = (error) => ({
  type: SupplementsActionTypes.UPDATE_USER_SUBSCRIPTION_FAILURE,
  payload: error,
});

// Initiate Stripe Payment
export const initiateStripePaymentRequest = (payload) => ({
  type: SupplementsActionTypes.INITIATE_STRIPE_PAYMENT_REQUEST,
  payload: payload,
});

export const initiateStripePaymentSuccess = (response) => ({
  type: SupplementsActionTypes.INITIATE_STRIPE_PAYMENT_SUCCESS,
  payload: response,
});

export const initiateStripePaymentFailure = (error) => ({
  type: SupplementsActionTypes.INITIATE_STRIPE_PAYMENT_FAILURE,
  payload: error,
});

// Trainer Leads
export const getTrainerLeadsRequest = (payload) => ({
  type: SupplementsActionTypes.GET_TRAINER_LEADS_REQUEST,
  payload: payload,
});

export const getTrainerLeadsSuccess = (response) => ({
  type: SupplementsActionTypes.GET_TRAINER_LEADS_SUCCESS,
  payload: response,
});

export const getTrainerLeadsFailure = (error) => ({
  type: SupplementsActionTypes.GET_TRAINER_LEADS_FAILURE,
  payload: error,
});

export const getTrainerLeadsLoadingStart = () => ({
  type: SupplementsActionTypes.GET_TRAINER_LEADS_LOADING_START,
});

export const getTrainerLeadsLoadingStop = () => ({
  type: SupplementsActionTypes.GET_TRAINER_LEADS_LOADING_STOP,
});

// Trigger trainer emails
export const triggerTrainerEmail = (payload) => ({
  type: SupplementsActionTypes.TRIGGER_TRAINER_EMAIL,
  payload: payload,
});

// Trainer Lead Details
export const getTrainerLeadDetailsRequest = (payload) => ({
  type: SupplementsActionTypes.GET_TRAINER_LEAD_DETAILS_REQUEST,
  payload: payload,
});

export const getTrainerLeadDetailsSuccess = (response) => ({
  type: SupplementsActionTypes.GET_TRAINER_LEAD_DETAILS_SUCCESS,
  payload: response,
});

export const getTrainerLeadDetailsFailure = (error) => ({
  type: SupplementsActionTypes.GET_TRAINER_LEAD_DETAILS_FAILURE,
  payload: error,
});

export const getTrainerLeadDetailsLoadingStart = () => ({
  type: SupplementsActionTypes.GET_TRAINER_LEAD_DETAILS_LOADING_START,
});

export const getTrainerLeadDetailsLoadingStop = () => ({
  type: SupplementsActionTypes.GET_TRAINER_LEAD_DETAILS_LOADING_STOP,
});

// Get Number of Leads

export const getNumberofLeadsRequest = (payload) => ({
  type: SupplementsActionTypes.GET_NUMBER_OF_LEADS_REQUEST,
  payload: payload,
});

export const getNumberofLeadsSuccess = (response) => ({
  type: SupplementsActionTypes.GET_NUMBER_OF_LEADS_SUCCESS,
  payload: response,
});

export const getNumberofLeadsFailure = (error) => ({
  type: SupplementsActionTypes.GET_NUMBER_OF_LEADS_FAILURE,
  payload: error,
});

export const getNumberofLeadsLoadingStart = () => ({
  type: SupplementsActionTypes.GET_NUMBER_OF_LEADS_LOADING_START,
});

export const getNumberofLeadsLoadingStop = () => ({
  type: SupplementsActionTypes.GET_NUMBER_OF_LEADS_LOADING_STOP,
});

// Update Trainer Lead
export const updateTrainerLeadRequest = (payload) => ({
  type: SupplementsActionTypes.UPDATE_TRAINER_LEAD_REQUEST,
  payload: payload,
});

export const updateTrainerLeadSuccess = (response) => ({
  type: SupplementsActionTypes.UPDATE_TRAINER_LEAD_SUCCESS,
  payload: response,
});

export const updateTrainerLeadFailure = (error) => ({
  type: SupplementsActionTypes.UPDATE_TRAINER_LEAD_FAILURE,
  payload: error,
});

export const updateTrainerLeadLoadingStart = () => ({
  type: SupplementsActionTypes.UPDATE_TRAINER_LEAD_LOADING_START,
});

export const updateTrainerLeadLoadingStop = () => ({
  type: SupplementsActionTypes.UPDATE_TRAINER_LEAD_LOADING_STOP,
});

// Foodiary Leads
export const getFoodiaryLeadsRequest = (payload) => ({
  type: SupplementsActionTypes.GET_FOODIARY_LEADS_REQUEST,
  payload: payload,
});

export const getFoodiaryLeadsSuccess = (response) => ({
  type: SupplementsActionTypes.GET_FOODIARY_LEADS_SUCCESS,
  payload: response,
});

export const getFoodiaryLeadsFailure = (error) => ({
  type: SupplementsActionTypes.GET_FOODIARY_LEADS_FAILURE,
  payload: error,
});

export const getFoodiaryLeadsLoadingStart = () => ({
  type: SupplementsActionTypes.GET_FOODIARY_LEADS_LOADING_START,
});

export const getFoodiaryLeadsLoadingStop = () => ({
  type: SupplementsActionTypes.GET_FOODIARY_LEADS_LOADING_STOP,
});

// Foodiary Lead Details
export const getFoodiaryLeadDetailsRequest = (payload) => ({
  type: SupplementsActionTypes.GET_FOODIARY_LEAD_DETAILS_REQUEST,
  payload: payload,
});

export const getFoodiaryLeadDetailsSuccess = (response) => ({
  type: SupplementsActionTypes.GET_FOODIARY_LEAD_DETAILS_SUCCESS,
  payload: response,
});

export const getFoodiaryLeadDetailsFailure = (error) => ({
  type: SupplementsActionTypes.GET_FOODIARY_LEAD_DETAILS_FAILURE,
  payload: error,
});

export const getFoodiaryLeadDetailsLoadingStart = () => ({
  type: SupplementsActionTypes.GET_FOODIARY_LEAD_DETAILS_LOADING_START,
});

export const getFoodiaryLeadDetailsLoadingStop = () => ({
  type: SupplementsActionTypes.GET_FOODIARY_LEAD_DETAILS_LOADING_STOP,
});

// Claim Foodiary Lead
export const claimFoodiaryLeadRequest = (payload) => ({
  type: SupplementsActionTypes.CLAIM_FOODIARY_LEAD_REQUEST,
  payload: payload,
});

export const claimFoodiaryLeadSuccess = (response) => ({
  type: SupplementsActionTypes.CLAIM_FOODIARY_LEAD_SUCCESS,
  payload: response,
});

export const claimFoodiaryLeadFailure = (error) => ({
  type: SupplementsActionTypes.CLAIM_FOODIARY_LEAD_FAILURE,
  payload: error,
});

export const claimFoodiaryLeadLoadingStart = () => ({
  type: SupplementsActionTypes.CLAIM_FOODIARY_LEAD_LOADING_START,
});

export const claimFoodiaryLeadLoadingStop = () => ({
  type: SupplementsActionTypes.CLAIM_FOODIARY_LEAD_LOADING_STOP,
});

//  Lead Utilization
export const leadUtilizationRequest = (payload) => ({
  type: SupplementsActionTypes.LEAD_UTILIZATION_REQUEST,
  payload: payload,
});

export const leadUtilizationSuccess = (response) => ({
  type: SupplementsActionTypes.LEAD_UTILIZATION_SUCCESS,
  payload: response,
});

export const leadUtilizationFailure = (error) => ({
  type: SupplementsActionTypes.LEAD_UTILIZATION_FAILURE,
  payload: error,
});

export const leadUtilizationLoadingStart = () => ({
  type: SupplementsActionTypes.LEAD_UTILIZATION_LOADING_START,
});

export const leadUtilizationLoadingStop = () => ({
  type: SupplementsActionTypes.LEAD_UTILIZATION_LOADING_STOP,
});

//  Lead Center Status
export const getLeadCenterStatusRequest = (payload) => ({
  type: SupplementsActionTypes.GET_LEAD_CENTER_STATUS_REQUEST,
  payload: payload,
});

export const getLeadCenterStatusSuccess = (response) => ({
  type: SupplementsActionTypes.GET_LEAD_CENTER_STATUS_SUCCESS,
  payload: response,
});

export const getLeadCenterStatusFailure = (error) => ({
  type: SupplementsActionTypes.GET_LEAD_CENTER_STATUS_FAILURE,
  payload: error,
});

export const getLeadCenterStatusLoadingStart = () => ({
  type: SupplementsActionTypes.GET_LEAD_CENTER_STATUS_LOADING_START,
});

export const getLeadCenterStatusLoadingStop = () => ({
  type: SupplementsActionTypes.GET_LEAD_CENTER_STATUS_LOADING_STOP,
});

//  Lead History
export const getLeadHistoryRequest = (payload) => ({
  type: SupplementsActionTypes.GET_LEAD_HISTORY_REQUEST,
  payload: payload,
});

export const getLeadHistorySuccess = (response) => ({
  type: SupplementsActionTypes.GET_LEAD_HISTORY_SUCCESS,
  payload: response,
});

export const getLeadHistoryFailure = (error) => ({
  type: SupplementsActionTypes.GET_LEAD_HISTORY_FAILURE,
  payload: error,
});

export const getLeadHistoryLoadingStart = () => ({
  type: SupplementsActionTypes.GET_LEAD_HISTORY_LOADING_START,
});

export const getLeadHistoryLoadingStop = () => ({
  type: SupplementsActionTypes.GET_LEAD_HISTORY_LOADING_STOP,
});

//  Lead AUthorization
export const getLeadAuthorizationRequest = (payload) => ({
  type: SupplementsActionTypes.GET_LEAD_AUTHORIZATION_REQUEST,
  payload: payload,
});

export const getLeadAuthorizationSuccess = (response) => ({
  type: SupplementsActionTypes.GET_LEAD_AUTHORIZATION_SUCCESS,
  payload: response,
});

export const getLeadAuthorizationFailure = (error) => ({
  type: SupplementsActionTypes.GET_LEAD_AUTHORIZATION_FAILURE,
  payload: error,
});

export const getLeadAuthorizationLoadingStart = () => ({
  type: SupplementsActionTypes.GET_LEAD_AUTHORIZATION_LOADING_START,
});

export const getLeadAuthorizationLoadingStop = () => ({
  type: SupplementsActionTypes.GET_LEAD_AUTHORIZATION_LOADING_STOP,
});
export const createLeadCenterRequestStart = () => ({
  type: SupplementsActionTypes.CREATE_LEAD_CENTER_REQUEST_START,
});
export const createLeadCenterRequest = (payload) => ({
  type: SupplementsActionTypes.CREATE_LEAD_CENTER_REQUEST,
  payload: payload,
});
export const createLeadCenterSuccess = (response) => ({
  type: SupplementsActionTypes.CREATE_LEAD_CENTER_SUCCESS,
});
export const createLeadCenterFailure = (error) => ({
  type: SupplementsActionTypes.CREATE_LEAD_CENTER_FAILURE,
  payload: error,
});

export const updateLeadCenterUpdateStart = () => ({
  type: SupplementsActionTypes.UPDATE_LEAD_CENTER_REQUEST_START,
});
export const updateLeadCenterUpdateRequest = (payload) => ({
  type: SupplementsActionTypes.UPDATE_LEAD_CENTER_REQUEST,
  payload: payload,
});
export const updateLeadCenterUpdateSuccess = (response) => ({
  type: SupplementsActionTypes.UPDATE_LEAD_CENTER_SUCCESS,
  payload: response,
});
export const updateLeadCenterUpdateFailure = (error) => ({
  type: SupplementsActionTypes.UPDATE_LEAD_CENTER_FAILURE,
  payload: error,
});

