import styled from "styled-components";

export const DropDownWrapper = styled.select`
  height: 2.5rem;
  min-width: 80px;
  width: ${(props) => props.width};
  padding: 0 15px;
  margin: ${(props) => (props.margin ? props.margin : "0 auto 1rem")};
  background-color: white;
  border: 1px solid #dce1e0;
  color: #44554c;
  border-radius: 5px;
  font-weight: 400;
`;

export const DropDownOption = styled.option``;
