export const MENUS = [
  {
    id: 1,
    title: "Klientenübersicht",
    path: "/dashboard/customers/list",
  },
  {
    id: 2,
    title: "Testumgebung",
    path: "",
    url: "http://3.70.7.80/",
  },
  {
    id: 3,
    title: "Erstgespräch starten",
    path: "/start-consultation",
  },
  {
    id: 4,
    title: "Ernährungsplan öffnen",
    path: "/dashboard/customers/token-request",
  },
]
