export const getEmailTriggerData = (templateName, {
  leadName,
  calendlyurl,
  coachNumber,
  coachPrename,
  subject,
  foodiaryEmail,
  leadEmail
}) => ({
  template_name: templateName,
  template_content: [
    {
      name: 'firstname',
      content: leadName
    },
    {
      ...(calendlyurl ? {
        name: 'calendlyurl',
        content: `<a href='${calendlyurl}' target='_blank'>${calendlyurl}</a>`
      } : {
        name: 'coachnumber',
        content: `<p>${coachNumber}</p>`
      })
    },
    {
      name: 'coachname',
      content: coachPrename
    },
  ],
  subject,
  from_email: foodiaryEmail,
  to: [
    { email: leadEmail, type: 'to' },
    { email: foodiaryEmail, type: 'bcc' }
  ]
})
