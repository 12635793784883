import React, { useState, useEffect } from "react";
import * as S from "./IncrementInputModern.styles";
import Input from "../input/Input";
import { Portal } from "@material-ui/core";
import MinusIcon from "assets/images/Onboarding/Screen6/Icon-minus.svg";
import PlusIcon from "assets/images/Onboarding/Screen6/Icon-plus-2.svg";

const IncrementInputModern = (props) => {
  const [value, setValue] = useState(props.value || "");
  const [defaultValue, setDefaultValue] = useState(
    props?.inputMeasure ? value + " " + props?.inputMeasure : value
  );
  const inputWidth = props.width - 80 + "px";

  useEffect(() => {
    changeInputValue();
  }, [defaultValue]);

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  const changeInputValue = () =>
    (document.getElementById(props.id).value = defaultValue);

  const handleIncrement = () => {
    if (!props.disabled) {
      let Value = parseInt(value || 0) + 1;
      if (props.maxLimit) {
        if (value < props.maxLimit) {
          if (props?.inputMeasure) {
            setDefaultValue(Value + " " + props.inputMeasure);
          } else {
            setDefaultValue(Value);
          }
          setValue(Value);
          props.setData(Value);
        }
      } else {
        if (props?.inputMeasure) {
          setDefaultValue(Value + " " + props.inputMeasure);
        } else {
          setDefaultValue(Value);
        }
        setValue(Value);
        props.setData(Value);
      }
    }
  };
  const handleDecrement = () => {
    if (!props.disabled) {
      if (value > 0) {
        let Value = parseInt(value) - 1;
        if (props?.inputMeasure) {
          setDefaultValue(Value + " " + props.inputMeasure);
        } else {
          setDefaultValue(Value);
        }
        setValue(Value);
        props.setData(Value);
      }
    }
  };
  return (
    <>
      <div className="input-incr-decr">
        <button onClick={handleDecrement} disabled={props.disabled}>
          <img src={MinusIcon} alt="minus" />
        </button>
        <input
          type={props.type}
          id={props.id}
          value={value}
          onFocus={(e) => (e.target.value = value)}
          onChange={(e) => {
            setValue(e.target.value);
            props.setData(e.target.value);
          }}
          onBlur={(e) =>
            (e.target.value = props.inputMeasure
              ? value + " " + props.inputMeasure
              : value)
          }
          style={{ width: inputWidth, padding: "9px 0", textAlign: "center" }}
          disabled={props.disabled}
        />
        <button onClick={handleIncrement} disabled={props.disabled}>
          <img src={PlusIcon} alt="plus" />
        </button>
      </div>

      {/* <S.IncrementInputContainer width={props.width}>
        <S.IncrementInputButton
          onClick={handleDecrement}
          disabled={props.disabled}
        >
          -
        </S.IncrementInputButton>
        <Input
          type={props.type}
          id={props.id}
          value={value}
          onFocus={(e) => (e.target.value = value)}
          onChange={(e) => {
            setValue(e.target.value);
            props.setData(e.target.value);
          }}
          onBlur={(e) =>
            (e.target.value = props.inputMeasure
              ? value + " " + props.inputMeasure
              : value)
          }
          style={{ width: inputWidth, padding: "9px 0", textAlign: "center" }}
          disabled={props.disabled}
        />
        <S.IncrementInputButton
          onClick={handleIncrement}
          disabled={props.disabled}
        >
          +
        </S.IncrementInputButton>
      </S.IncrementInputContainer> */}
    </>
  );
};

export default IncrementInputModern;
