import React from 'react';
import * as S from './alert.styles';

const Alert = ({ isSuccess, isError, message, containerMargin }) => {
  return (
    <S.Alert state={{ isSuccess, isError, containerMargin }}>
      <S.Message state={{ isSuccess, isError }}>{message}</S.Message>
    </S.Alert>
  )
}

export default Alert;
