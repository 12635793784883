import styled from 'styled-components';
import {RegistrationContainer ,RegistrationHeading, RegistrationSubHeading} from 'pages/registration/registration.styles';

export const NutritionFormAdvantagesContainer = styled(RegistrationContainer)``;

export const NutritionFormAdvantagesHeading = styled(RegistrationHeading)``;

export const NutritionFormAdvantagesSubHeading = styled(RegistrationSubHeading)``;

export const NutritionFormAdvantagesCardContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const NutritionFormAdvantagesCard = styled.div`
    width: 455px;
    margin: 0px auto;
    background: white;
    border-radius: 10px;
    margin-top: 20px;
`;

export const NutritionFormAdvantagesCardInnerContainer = styled.div`
    margin: 17px 28px;
    display: flex;
    flex-direction: row;
`;

export const NutritionFormAdvantagesPointNumber = styled.div`
    width: 32px;
    height: 32px;
    border-radius: 50%;
    border: 2px solid #2ECF96;
    margin-right: 20px;
    color: #2ECF96;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const NutritionFormAdvantageData = styled.div`
    font-family: RubikRegular;
    font-size: 16px;
    color: #44554C;
    width: 343px;
    text-align: left;
`;