import styled from "styled-components";

export const CardInnerContainer = styled.div`
    display: flex;
    padding: 45px 50px;
`;

export const CardLeftContainer = styled.div`
    width: 270px;
    margin-right: 50px;
`;

export const SupplementImageContainer = styled.div`
    border: 2px solid #EDEDED;
    border-radius: 8px;
`;

export const SupplementImage = styled.img`
    width: 246px;
    margin-left: 10px;
`;

export const AlertMessageContainer = styled.div`
    background-color: #F5F5F5;
    padding: 32px 28px;
    margin-top: 20px;
    border-radius: 8px;
    display: flex;
`;

export const AlertTriangleImage = styled.img``;

export const AlertMessage = styled.div`
    font-size: 14px;
    color: rgba(68, 85, 76, .7);
    line-height: 21px;
    margin-left: 16px; 
`;

export const CardRightContainer = styled.div``;

export const SupplementsCardHeading = styled.div`
    font-size: 22px;
    line-height: 27px;
    color: #142C1F;
`;

export const SupplementsCardsubHeading = styled.div`
    color: #A5B5AF;
    font-size: 14px;
    line-height: 17px;
    font-family: RubikRegular;
    margin-top: 4px;
`;

export const SupplementsContentContainer = styled.div`
    margin-top: 20px;
`;

export const SupplementContentPoints = styled.div`
    color: #44554C;
    font-size: 14px;
    line-height: 21px;
    font-family: RubikRegular
`;

export const SupplementsContentDescription = styled.div`
    color: #A5B5AF;
    font-size: 12px;
    line-height: 21px;
    font-family: RubikRegular;
`;

export const SupplementsAdvantage = styled.div`
    font-size: 16px;
    line-height: 19px;
    color: #142C1F;
    margin-top: 20px;
    margin-bottom: 10px;
`;

export const SupplementsAdvantageContainer = styled.div`
    display: flex;
    margin-bottom: 6px;
`;

export const GreenTickContainer = styled.span`
    margin-right: 10px;
    padding-top: 2px;
`;

export const Advantage = styled.div`
    line-height: 21px;
    font-size: 14px;
    color: #44554C;
    font-family: RubikRegular;
`;

export const SupplementPlanContainer = styled.div`
    display: flex;
    text-align: center;
    margin-top: 24px;
`;

export const SupplementPlan = styled.div`
    background-color: #F5F5F5;
    padding: 10px 14px;
    border-radius: 8px;
    margin-right: 10px;
`;

export const SupplementPlanHeading = styled.div`
    font-size: 12px;
    color: #A5B5AF;
    line-height: 14px;
    font-family: RubikRegular;
`;

export const SupplementPlanPrice = styled.div`
    font-size: 22px;
    line-height: 27px;
    margin-top: 4px;
    color: ${props => props.color};
`;

export const SwitchContainer = styled.div`
    display: flex;
    margin: 30px 0 0 20px;
`;

export const Info = styled.div`
    margin-left: 15px;
    font-size: 14px;
    font-family: RubikRegular;
    line-height: 21px;
    color: #44554C;
`;