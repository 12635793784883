import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useAlert } from "react-alert";
import { useParams, useHistory } from "react-router-dom";
import { ControlPoint } from "@material-ui/icons";
import moment from "moment";

import TranslationMessage from "configs/translations/TranslationMessage";
import Button from "components/formComponents/button/Button";
import Input from "components/formComponents/input/Input";
import Modal from "components/modal/Modal";
import TimePicker from "components/formComponents/timePicker/TimePicker";

import EditIcon from "assets/images/edit-black-icon.svg";
import Arrow from "assets/images/arrow-white.svg";
import ArrowActive from "assets/images/arrow-green.svg";
import DeleteOutline from "assets/images/delete-outline.svg";
import ClientDetailsSidebar from "./ClientDetailsSidebar";


import * as AuthActions from "redux/auth/auth.actions";
import * as S from "./ClientDetails.styles";
import "./clientDetails.scss";


const DailyRoutine = () => {
  const [showLoader, setShowLoader] = useState(false);
  const dispatch = useDispatch();
  const alert = useAlert();
  const { userId } = useParams();
  const history = useHistory();

  const trainerData = useSelector((state) => state.auth.trainerData);
  const clientDetails = useSelector(
    (state) => state.auth.getClientDetails?.data
  );
  const isClientDetailsLoading = useSelector(
    (state) => state.auth.isClientDetailsLoading
  );

  const userDailyRoutineData = useSelector(
    (state) => state.auth.userDailyRoutineData
  );
  const isUserDailyRoutineDataLoading = useSelector(
    (state) => state.auth.isUserDailyRoutineDataLoading
  );
  const isUserDailyRoutineDataUpdating = useSelector(
    (state) => state.auth.isUserDailyRoutineDataUpdating
  );
  const isUserDailyRoutineDataAdding = useSelector(
    (state) => state.auth.isUserDailyRoutineDataAdding
  );

  const [selectedDailyRoutineTab, setSelectedDailyRoutineTab] = useState(
    userDailyRoutineData?.[0]
  );
  const [routineName, setRoutineName] = useState("");
  const [routineGetup, setRoutineGetup] = useState({
    // dayroutineID: 1,
    // time: "07:00",
  });
  const [routineLunchTime, setRoutineLunchTime] = useState({
    // dayroutineID: 2,
    // time: "12:00",
  });
  const [routineWorkoutTime, setRoutineWorkoutTime] = useState({
    // dayroutineID: 3,
    // time: "17:00",
  });
  const [routineBedTime, setRoutineBedTime] = useState({
    // dayroutineID: 4,
    // time: "22:00",
  });
  const [DefaultDailyRoutines, SetDefaultDailyRoutines] = useState([
    { id: "input.label.getup", hrs: "07" },
    { id: "input.label.lunchTime", hrs: "12" },
    { id: "input.label.workoutTime", hrs: "17" },
    { id: "input.label.bedTime", hrs: "22" },
  ]);
  const [SelectedDailyRoutines, SetSelectedDailyRoutines] = useState([]);
  const [addDailyRoutineModal, setAddDailyRoutineModal] = useState(false);
  const [updateDailyRoutineModal, setUpdateDailyRoutineModal] = useState(false);

  useEffect(() => {
    dispatch(
      AuthActions.getClientDetailsRequest({
        payload: {
          trainerID: trainerData?.trainerID,
          userID: +userId,
        },
        callback: (res) => {
          console.log(res);
        },
      })
    );

    getDailyRoutineData();
  }, []);

  const getDailyRoutineData = () => {
    dispatch(
      AuthActions.getDaysRoutineForUserByTrainerRequest({
        payload: {
          nutritionconceptID: clientDetails?.nutritionconceptID,
        },
        callback: (res) => {
          console.log("getDaysRoutineForUserByTrainerRequest", res);
          setSelectedDailyRoutineTab(res?.[0]);
          setRoutineGetup({
            id: "input.label.getup",
            dayroutineID: res?.[0]?.dayroutines?.[0]?.dayroutineID,
            time: res?.[0]?.dayroutines?.[0]?.time,
          });
          setRoutineLunchTime({
            id: "input.label.lunchTime",
            dayroutineID: res?.[0]?.dayroutines?.[1]?.dayroutineID,
            time: res?.[0]?.dayroutines?.[1]?.time,
          });
          setRoutineWorkoutTime({
            id: "input.label.workoutTime",
            dayroutineID: res?.[0]?.dayroutines?.[2]?.dayroutineID,
            time: res?.[0]?.dayroutines?.[2]?.time,
          });
          setRoutineBedTime({
            id: "input.label.bedTime",
            dayroutineID: res?.[0]?.dayroutines?.[3]?.dayroutineID,
            time: res?.[0]?.dayroutines?.[3]?.time,
          });

          SetSelectedDailyRoutines([
            {
              id: "input.label.getup",
              dayroutineID: res?.[0]?.dayroutines?.[0]?.dayroutineID,
              time: res?.[0]?.dayroutines?.[0]?.time,
            },
            {
              id: "input.label.lunchTime",
              dayroutineID: res?.[0]?.dayroutines?.[1]?.dayroutineID,
              time: res?.[0]?.dayroutines?.[1]?.time,
            },
            {
              id: "input.label.workoutTime",
              dayroutineID: res?.[0]?.dayroutines?.[2]?.dayroutineID,
              time: res?.[0]?.dayroutines?.[2]?.time,
            },
            {
              id: "input.label.bedTime",
              dayroutineID: res?.[0]?.dayroutines?.[3]?.dayroutineID,
              time: res?.[0]?.dayroutines?.[3]?.time,
            },
          ]);

          SetDefaultDailyRoutines([
            {
              id: "input.label.getup",
              dayroutineID: res?.[0]?.dayroutines?.[0]?.dayroutineID,
              time: "07",
            },
            {
              id: "input.label.lunchTime",
              dayroutineID: res?.[0]?.dayroutines?.[1]?.dayroutineID,
              time: "12",
            },
            {
              id: "input.label.workoutTime",
              dayroutineID: res?.[0]?.dayroutines?.[2]?.dayroutineID,
              time: "17",
            },
            {
              id: "input.label.bedTime",
              dayroutineID: res?.[0]?.dayroutines?.[3]?.dayroutineID,
              time: "22",
            },
          ]);
        },
      })
    );
  };

  const routineMapping = {
    "input.label.getup": { id: 1, setter: setRoutineGetup },
    "input.label.lunchTime": { id: 2, setter: setRoutineLunchTime },
    "input.label.workoutTime": { id: 3, setter: setRoutineWorkoutTime },
    "input.label.bedTime": { id: 4, setter: setRoutineBedTime },
  };

  const handleTimeChange = (time, TimeId) => {
    const timeValue = moment(time).format("HH:mm");
    console.log("TimeId", TimeId);
    console.log("time", time);
    console.log("timeValue", timeValue);

    const routine = routineMapping[TimeId];
    if (routine) {
      routine.setter({ dayroutineID: routine.id, time: timeValue });
    } else {
      return false;
    }
  };

  const resetAddRoutineData = () => {
    setRoutineName("");
    setRoutineGetup({});
    setRoutineLunchTime({});
    setRoutineWorkoutTime({});
    setRoutineBedTime({});
  };

  const handleAddRoutine = () => {
    console.log("<--------------------Add Routine Start-------------->");
    console.log("routineGetup------", routineGetup);
    console.log("routineLunchTime------", routineLunchTime);
    console.log("routineWorkoutTime------", routineWorkoutTime);
    console.log("routineBedTime------", routineBedTime);
    console.log("<--------------------Add Routine End-------------->");

    if (
      !routineGetup ||
      !routineLunchTime ||
      !routineWorkoutTime ||
      !routineBedTime
    ) {
      return;
    }

    const uniqueId = "id" + new Date().getTime();
    const newRoutine = {
      id: uniqueId,
      heading: routineName,
      data: [
        routineGetup["time"] || "07:00",
        routineLunchTime["time"] || "12:00",
        routineWorkoutTime["time"] || "17:00",
        routineBedTime["time"] || "22:00",
      ],
    };
    const newRoutineData = {
      [newRoutine.heading]: [
        { dayroutineID: 1, time: newRoutine.data[0] },
        { dayroutineID: 2, time: newRoutine.data[1] },
        { dayroutineID: 3, time: newRoutine.data[2] },
        { dayroutineID: 4, time: newRoutine.data[3] },
      ],
    };

    dispatch(
      AuthActions.addDayRoutineForUserByTrainerRequest({
        payload: {
          nutritionconceptID: clientDetails?.nutritionconceptID,
          routines: newRoutineData,
        },
        callback: () => {
          setAddDailyRoutineModal(false);
          getDailyRoutineData();
          resetAddRoutineData();
        },
      })
    );
  };

  const handleEditDailyRoutine = () => {
    console.log("<--------------------Update Routine Start-------------->");
    console.log("routineGetup------", routineGetup);
    console.log("routineLunchTime------", routineLunchTime);
    console.log("routineWorkoutTime------", routineWorkoutTime);
    console.log("routineBedTime------", routineBedTime);
    console.log("<--------------------Update Routine End-------------->");

    if (
      !routineGetup ||
      !routineLunchTime ||
      !routineWorkoutTime ||
      !routineBedTime
    ) {
      return;
    }

    let selectedRoutineIdx = 0;
    const routines = userDailyRoutineData.map((routine, idx) => {
      if (routine?.id === selectedDailyRoutineTab?.id) {
        selectedRoutineIdx = idx;
        return {
          id: routine.id,
          routinename: routineName,
          dayroutines: [
            {
              dayroutineID: routineGetup?.dayroutineID,
              time: routineGetup?.time,
            },
            {
              dayroutineID: routineLunchTime?.dayroutineID,
              time: routineLunchTime?.time,
            },
            {
              dayroutineID: routineWorkoutTime?.dayroutineID,
              time: routineWorkoutTime?.time,
            },
            {
              dayroutineID: routineBedTime?.dayroutineID,
              time: routineBedTime?.time,
            },
          ],
        };
      }
      return {
        id: routine.id,
        routinename: routine.routinename,
        dayroutines: routine.dayroutines.map((routineType) => {
          return {
            dayroutineID: routineType.dayroutineID,
            time: routineType.time,
          };
        }),
      };
    });

    console.log("routines update payload------", routines);

    dispatch(
      AuthActions.updateDaysRoutineForUserByTrainerRequest({
        payload: {
          nutritionconceptID: clientDetails?.nutritionconceptID,
          routines: routines,
        },
        callback: (updatedData) => {
          console.log("updateDaysRoutineForUserByTrainerRequest", updatedData);
          // setSelectedDailyRoutineTab(0);
          // setSelectedDailyRoutineTab(
          //   updatedData[0].routines[selectedRoutineIdx]
          // );
          setUpdateDailyRoutineModal(false);
          getDailyRoutineData();
        },
      })
    );
  };

  const handleDeleteDailyRoutine = () => {
    dispatch(
      AuthActions.deleteDayRoutineForUserByTrainerRequest({
        data: {
          nutritionconceptID: clientDetails?.nutritionconceptID,
          routineId: selectedDailyRoutineTab?.id,
        },
        funcSuccess: () => {
          getDailyRoutineData();
        },
      })
    );
  };

  return (
    <>
      <S.ClientDetailsOverallContainer>
        <S.ClientDetailsContainer>
          <div className="second-sidebar-wrapper">
            <ClientDetailsSidebar />
          </div>
          <div className="second-sidebar-content" style={{ padding: "1.5rem" }}>
            <S.ContainerBox>
              <S.Header>
                <div>
                  <S.Heading>Tagesablauf</S.Heading>
                  <S.SubHeading>
                    Die aktuellen Tagesabläufe deines Klienten.
                  </S.SubHeading>
                </div>
              </S.Header>

              <S.Divider></S.Divider>
              <S.PaddingBox>
                <div className="container-70rem">
                  {isUserDailyRoutineDataLoading && (
                    <>
                      <br />
                      <br />
                      <br />
                      <br />
                      <div className="text-center">
                        <div className="loader mx-auto"></div>
                      </div>
                      <br />
                      <br />
                      <br />
                      <br />
                    </>
                  )}

                  {!isUserDailyRoutineDataLoading && (
                    <S.ClientDetailsDailyRoutineContainer>
                      <S.ClientDetailsDailyRoutineTabContainer>
                        {userDailyRoutineData?.map((tab) => {
                          const isActive =
                            selectedDailyRoutineTab?.id === tab?.id;

                          return (
                            <S.ClientDetailsDailyRoutineTab
                              isActive={isActive}
                              key={tab?.routinename}
                              onClick={() => {
                                console.log("tab", tab);
                                setSelectedDailyRoutineTab(tab);

                                SetSelectedDailyRoutines([
                                  {
                                    id: "input.label.getup",
                                    time: tab?.dayroutines?.[0]?.time,
                                  },
                                  {
                                    id: "input.label.lunchTime",
                                    time: tab?.dayroutines?.[1]?.time,
                                  },
                                  {
                                    id: "input.label.workoutTime",
                                    time: tab?.dayroutines?.[2]?.time,
                                  },
                                  {
                                    id: "input.label.bedTime",
                                    time: tab?.dayroutines?.[3]?.time,
                                  },
                                ]);

                                setRoutineGetup({
                                  dayroutineID: 1,
                                  time: tab?.dayroutines?.[0]?.time,
                                });
                                setRoutineLunchTime({
                                  dayroutineID: 2,
                                  time: tab?.dayroutines?.[1]?.time,
                                });
                                setRoutineWorkoutTime({
                                  dayroutineID: 3,
                                  time: tab?.dayroutines?.[2]?.time,
                                });
                                setRoutineBedTime({
                                  dayroutineID: 4,
                                  time: tab?.dayroutines?.[3]?.time,
                                });
                              }}
                            >
                              <S.ClientDetailsDailyRoutineTabHeading>
                                {tab?.routinename}
                              </S.ClientDetailsDailyRoutineTabHeading>
                              {!isActive && (
                                <S.ClientDetailsDailyRoutineTabRightArrowIcon
                                  alt="right-icon-tab"
                                  src={Arrow}
                                />
                              )}
                              {isActive && (
                                <S.ClientDetailsDailyRoutineTabRightArrowIcon
                                  className="active"
                                  alt="right-icon-tab"
                                  src={ArrowActive}
                                />
                              )}
                            </S.ClientDetailsDailyRoutineTab>
                          );
                        })}
                        <S.NewRoutine
                          onClick={() => {
                            resetAddRoutineData();
                            setAddDailyRoutineModal(true);
                          }}
                        >
                          <ControlPoint /> Neuer Tagesablauf
                        </S.NewRoutine>
                      </S.ClientDetailsDailyRoutineTabContainer>
                      <S.ClientDetailsDailyRoutineTabDetailsContainer>
                        <S.ClientDetailsDailyRoutineTabDetailsInnerContainer>
                          <div className="daily-routine-details-header">
                            <div>
                              <h6>{selectedDailyRoutineTab?.routinename}</h6>
                              <p></p>
                            </div>
                            <div className="actions">
                              <S.ClientDetailsDailyroutineIcon 
                                onClick={() => {
                                  // setIsRoutineEdit(true);
                                  setRoutineName(
                                    selectedDailyRoutineTab?.routinename
                                  );
                                  setUpdateDailyRoutineModal(true);
                                }}
                              >
                                <img className="edit" src={EditIcon} alt="edit" />
                              </S.ClientDetailsDailyroutineIcon>
                              <S.ClientDetailsDailyroutineIcon
                                onClick={handleDeleteDailyRoutine}
                              >
                                <img src={DeleteOutline} alt="delete" />
                              </S.ClientDetailsDailyroutineIcon>
                            </div>
                          </div>
                          <br />
                          <br />
                          {SelectedDailyRoutines.map((dailyRoutine) => {
                            const newDailyRoutineKey =
                              dailyRoutine?.id + dailyRoutine?.time;
                            return (
                              <S.ClientDetailsDailyRoutineTabDetailsHeading
                                key={newDailyRoutineKey}
                              >
                                <TranslationMessage id={dailyRoutine?.id} />
                                <span>{dailyRoutine?.time} Uhr</span>
                              </S.ClientDetailsDailyRoutineTabDetailsHeading>
                            );
                          })}
                        </S.ClientDetailsDailyRoutineTabDetailsInnerContainer>
                      </S.ClientDetailsDailyRoutineTabDetailsContainer>
                    </S.ClientDetailsDailyRoutineContainer>
                  )}
                </div>
              </S.PaddingBox>
            </S.ContainerBox>

            <Modal
              show={addDailyRoutineModal}
              setShow={() => null}
              heading={
                <div className="d-flex align-items-center justify-content-between gap-2">
                  <TranslationMessage id="registration.dailyRoutine.popupHeading" />

                  <button
                    className="btn-close"
                    onClick={() => setAddDailyRoutineModal(false)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="#000"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-x"
                    >
                      <line x1="18" y1="6" x2="6" y2="18"></line>
                      <line x1="6" y1="6" x2="18" y2="18"></line>
                    </svg>
                  </button>
                </div>
              }
            >
              <Input
                type="text"
                id="routineName"
                placeholder="Name des Tagesablaufs"
                value={routineName}
                onChange={(e) => setRoutineName(e.target.value)}
              />
              {DefaultDailyRoutines.map((value) => {
                return (
                  <S.ClientDetailsDailyroutineInputContainer
                    key={value?.id + value?.time}
                  >
                    <S.ClientDetailsDailyroutineInputLabel>
                      <TranslationMessage id={value?.id} />
                    </S.ClientDetailsDailyroutineInputLabel>
                    <TimePicker
                      value={
                        new Date(
                          2007,
                          11,
                          5,
                          value?.time?.substring(0, 2),
                          value?.time?.substring(3, 5),
                          17
                        )
                      }
                      onChange={(time) => {
                        handleTimeChange(time, value?.id);
                        setAddDailyRoutineModal(true);
                      }}
                    />
                  </S.ClientDetailsDailyroutineInputContainer>
                );
              })}
              <Button
                color="rgba(46, 207, 150, .2)"
                gradient="linear-gradient(60deg, rgba(46, 207, 150, 1), rgba(28, 157, 135, 1)) !important"
                margin="30px auto 0px"
                style={{ fontSize: "16px" }}
                onClick={handleAddRoutine}
                disabled={
                  routineName.length === 0 || isUserDailyRoutineDataAdding
                }
              >
                <TranslationMessage id="button.saveChanges" />
              </Button>
            </Modal>
            {/* setShow={() => setUpdateDailyRoutineModal(true)} */}

            <Modal
              show={updateDailyRoutineModal}
              setShow={() => null}
              heading={
                <div className="d-flex align-items-center justify-content-between gap-2">
                  <TranslationMessage id="registration.dailyRoutine.popupHeading" />

                  <button
                    className="btn-close"
                    onClick={() => setUpdateDailyRoutineModal(false)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="#000"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-x"
                    >
                      <line x1="18" y1="6" x2="6" y2="18"></line>
                      <line x1="6" y1="6" x2="18" y2="18"></line>
                    </svg>
                  </button>
                </div>
              }
            >
              <Input
                type="text"
                id="routineName"
                placeholder="Name des Tagesablaufs"
                value={routineName}
                onChange={(e) => setRoutineName(e.target.value)}
              />
              {SelectedDailyRoutines.map((value) => {
                return (
                  <S.ClientDetailsDailyroutineInputContainer
                    key={value?.id + value?.time}
                  >
                    <S.ClientDetailsDailyroutineInputLabel>
                      <TranslationMessage id={value?.id} />
                    </S.ClientDetailsDailyroutineInputLabel>
                    <TimePicker
                      value={
                        new Date(
                          2007,
                          11,
                          5,
                          value?.time?.substring(0, 2),
                          value?.time?.substring(3, 5),
                          17
                        )
                      }
                      onChange={(time) => {
                        handleTimeChange(time, value?.id);
                        setUpdateDailyRoutineModal(true);
                      }}
                    />
                  </S.ClientDetailsDailyroutineInputContainer>
                );
              })}
              <Button
                color="rgba(46, 207, 150, .2)"
                gradient="linear-gradient(60deg, rgba(46, 207, 150, 1), rgba(28, 157, 135, 1)) !important"
                margin="30px auto 0px"
                style={{ fontSize: "16px" }}
                onClick={handleEditDailyRoutine}
                disabled={
                  routineName.length === 0 || isUserDailyRoutineDataUpdating
                }
              >
                <TranslationMessage id="button.saveChanges" />
              </Button>
            </Modal>
          </div>
        </S.ClientDetailsContainer>
      </S.ClientDetailsOverallContainer>
    </>
  );
};

export default DailyRoutine;
