import styled from "styled-components";

export const TextAreaContainer = styled.span``;

export const CustomTextArea = styled.textarea`
  resize: none;
  width: 100%;
  padding: 10px;
  color: #2c2c2c;
  font-size: 1rem;
  font-weight: 400;
  box-shadow: 0px 1px 2px #e4e5e73d;
  border: 2px solid #e2e4e9;
  border-radius: 10px;

  background-color: ${(props) =>
    props.filled ? "#F8F8F8" : "white"} !important;

  ::placeholder {
    opacity: 0.5;
  }

  &:focus {
    border: none;
    overflow: hidden;
    outline: none;
  }

  &:focus-visible {
    border: none;
    overflow: hidden;
    outline: none;
  }
`;
