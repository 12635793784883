import styled from "styled-components";

export const CheckBoxContainer = styled.span`
  position: relative;
  margin: ${(props) => props.margin};

  & > label {
    background-color: #fff;
    border: 1px solid #dce1e0;
    border-radius: 50%;
    cursor: pointer;
    height: 20px;
    width: 20px;
    left: 0;
    position: absolute;
    top: 0;
    margin: auto;

    &:after {
      border: 2px solid #fff;
      border-top: none;
      border-right: none;
      content: "";
      height: 4px;
      width: 8px;
      opacity: 0;
      position: absolute;
      top: 5px;
      left: 4px;
      transform: rotate(-45deg);
    }
  }
`;

export const CustomCheckBox = styled.input.attrs({ type: "checkbox" })`
  visibility: hidden;
  /* height: 20px;
    width: 20px; */
  top: 0;

  &:checked + label {
    background-color: #2ecf96;
    border-color: #2ecf96;
  }
  &:checked + label:after {
    opacity: 1;
  }
`;

// Square Checkbox
export const SquareCheckBoxContainer = styled.span`
  position: relative;
  margin: ${(props) => props.margin};

  & > label {
    background-color: ${(props) => (props.filled ? props.filled : "#fff")};
    box-shadow: 0px 1px 2px #1018280d;
    border: 1px solid #d0d5dd;
    /* border: ${(props) =>
      props.filled ? "1px solid " + props.filled : "1px solid #DCE1E0"}; */
    border-radius: ${(props) => (props.rounded ? props.rounded : "0px")};
    cursor: pointer;
    height: 22px;
    left: 0;
    position: absolute;
    top: 3px;
    width: 22px;
    margin: auto;

    &:after {
      border: 2px solid #fff;
      border-top: none;
      border-right: none;
      content: "";
      height: 3px;
      opacity: 0;
      position: absolute;
      top: 6px;
      left: 5px;
      -webkit-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
      transform: rotate(-45deg);
      width: 9px;
    }
  }
`;

export const CustomSquareCheckBox = styled.input.attrs({ type: "checkbox" })`
  visibility: hidden;
  height: 20px;
  width: 20px;
  top: 0;

  &:checked + label {
    background-color: #2ecf96;
  }
  &:checked + label:after {
    opacity: 1;
  }
`;
