import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { debounce } from "lodash";
import Select from "react-select";

import Input from "components/formComponents/input/Input";
import InnerSideNav from "pages/NewDasboard/components/InnerSideNav/InnerSide";

import CrossIcon from "assets/images/cross-icon-faq.svg";
import SearchIcon from "assets/images/search.svg";

import { MENUS } from "./SideMenu";
import * as S from "./MarketingPortal.styles";
import * as NutritionActions from "redux/nutrition/nutrition.actions";

const PrintsTagOptions = [
  { value: 1, label: "Flyer" },
  { value: 3, label: "Gutschein" },
  { value: 4, label: "Rollup" },
  { value: 5, label: "Poster" },
];

const PrintData = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [SelectedTag, SetSelectedTag] = useState(null);
  const [SearchTerm, SetSearchTerm] = useState("");
  const [AllPrints, SetAllPrints] = useState([]);
  const [AllPrintsClone, SetAllPrintsClone] = useState([]);

  const isMarketingPortalPrintsLoading = useSelector(
    (state) => state.nutrition.isMarketingPortalPrintsLoading
  );

  // const marketingPortalPrints = useSelector(
  //   (state) => state.nutrition.marketingPortalPrints
  // );

  useEffect(() => {
    getMarketingPortalPrints();
  }, [dispatch]);

  const getMarketingPortalPrints = () => {
    dispatch(
      NutritionActions.getMarketingPortalPrintsRequest({
        pyload: {},
        callback: (res) => {
          SetAllPrints(res?.getAllPrints);
          SetAllPrintsClone(res?.getAllPrints);
        },
      })
    );
  };

  const onResetSelectFilter = () => {
    SetSelectedTag(null);
    SetSearchTerm("");
    SetAllPrints(AllPrintsClone);
  };

  const onSelectTagFilter = (b) => {
    console.log(b);
    SetSelectedTag(b);
    SetSearchTerm("");

    const brochures = AllPrintsClone.filter(
      (item) => item?.tag?.toLowerCase() === b?.label?.toLowerCase()
    );

    SetAllPrints(brochures);
  };

  const debouncedFilter = debounce((term) => filterMarketingAssets(term), 300);

  const onSearchInput = (e) => {
    SetSearchTerm(e.target.value);
    debouncedFilter(e.target.value);
  };

  const filterMarketingAssets = (term) => {
    if (!term) {
      SetAllPrints(AllPrintsClone);
      return;
    }

    const filteredPrints = AllPrints.filter((b) =>
      b?.title.toLowerCase().includes(term.toLowerCase())
    );
    console.log("filteredPrints", filteredPrints);
    SetAllPrints(filteredPrints);
  };

  return (
    <>
      <S.Container>
        <div className="second-sidebar-wrapper">
          <InnerSideNav menus={MENUS} hideUserInfo></InnerSideNav>
        </div>
        <S.Wrapper className="second-sidebar-content">
          <S.TrainerProfileDetailsInnerContainer>
            <S.Header className="p-0">
              <div>
                <S.TrainerProfileDetailsSectionHeading>
                  Druckdaten erstellen
                </S.TrainerProfileDetailsSectionHeading>
                <S.TrainerProfileDetailsSectionSubHeading>
                  Erstelle deine Druckdaten für die Printwerbemittel deiner
                  Kooperationspartner
                </S.TrainerProfileDetailsSectionSubHeading>
              </div>
              <div>
                <button
                  className="btn-dark"
                  onClick={() =>
                    history.push("/dashboard/marketing-portal/partner/create")
                  }
                >
                  Werbepartner anlegen
                </button>
              </div>
            </S.Header>

            <S.Divider />
            <S.FiltersBox>
              <S.SelectedFiltersAndDropdown>
                <Select
                  options={PrintsTagOptions}
                  className="faq-select"
                  classNamePrefix="react-select"
                  onChange={onSelectTagFilter}
                  value={SelectedTag}
                  placeholder="Alle Kategorien"
                  isSearchable={false}
                />

                {SelectedTag && (
                  <S.SelectedFilter>
                    {SelectedTag?.label}
                    <img
                      src={CrossIcon}
                      alt="CrossIcon"
                      onClick={() => onResetSelectFilter()}
                    />
                  </S.SelectedFilter>
                )}
              </S.SelectedFiltersAndDropdown>
              <div>
                <S.FormControlSearch>
                  <Input
                    className="input-custom"
                    style={{ width: "100%", marginBottom: "0" }}
                    placeholder="Suche in der Bibliothek"
                    onChange={onSearchInput}
                    value={SearchTerm}
                  />
                  <img src={SearchIcon} alt="SearchIcon" />
                </S.FormControlSearch>
              </div>
            </S.FiltersBox>
            {isMarketingPortalPrintsLoading && (
              <>
                <br />
                <br />
                <br />
                <br />
                <div className="text-center">
                  <div className="loader mx-auto"></div>
                </div>
                <br />
                <br />
                <br />
                <br />
              </>
            )}

            {!isMarketingPortalPrintsLoading && (
              <S.GridFourColumn>
                {AllPrints?.map((p) => (
                  <S.BrochureCard
                    className="cursor-pointer"
                    onClick={() =>
                      history.push(
                        "/dashboard/marketing-portal/print-data/" + p?.id
                      )
                    }
                  >
                    <S.BrochureCardImage>
                      <img
                        className="card-img"
                        src={p?.previewImg1}
                        alt="preview"
                      />
                      <div className="tag-label">{p?.tag}</div>
                    </S.BrochureCardImage>

                    <S.BrochureCardBody>
                      <div>
                        <h6>{p?.title}</h6>
                        <p>Werbeset: {p?.setID}</p>
                      </div>
                    </S.BrochureCardBody>
                  </S.BrochureCard>
                ))}
              </S.GridFourColumn>
            )}
          </S.TrainerProfileDetailsInnerContainer>
          {/* <br />
          <PrintDataDetails /> */}
        </S.Wrapper>
      </S.Container>
    </>
  );
};

export default PrintData;
