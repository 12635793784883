export const MENUS = [
  {
    id: 1,
    title: "Instagram Datenbank",
    path: "/dashboard/social-media/instagram",
  },
  {
    id: 2,
    title: "LinkedIn Datenbank",
    path: "/dashboard/social-media/linkedin",
  },
];
