import styled from "styled-components";

export const LoginContainer = styled.div`
 display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(119deg, #e5f5f2 0%, #edfbf6 100%);
  height: 100vh;

  @media screen and (max-width: 600px) {
    height: 100%;
    padding: 2rem 1rem;
  }

  @media screen and (min-height: 700px) {
    height: 100vh;
  }

  .check-and-link {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 16px;

    a {
      text-decoration: underline;
      color: #142c1f;
      font-weight: 300;
      font-size: 16px;

      &:hover {
        color: #2ecf96;
      }
    }
  }
`;

export const SliderContainer = styled.div`
  max-width: 50vw;
  width: 100%;
  background: ${(props) => `url(${props.imgUrl})`};
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: calc(100vh - 2rem);
  padding: 1rem;
  margin: 1rem;
  border-radius: 1rem;

  @media screen and (max-width: 992px) {
    display: none;
  }

  .slick-arrow {
    display: none !important;
  }

  .slide-item {
    img {
      border-radius: 18px;
      height: calc(100vh - 252px);
      width: 100%;
      border: 6px solid #ffffff47;
      object-fit: cover;
      object-position: left;
    }

    p,
    h4 {
      color: white;
      max-width: 550px;
      margin-inline: auto;
      text-align: center;
    }

    h4 {
      font-size: 1.5rem;
      font-weight: 400;
    }

    p {
      font-size: 1rem;
      font-weight: 300;
    }
  }
`;
export const ChangePasswordInnerContainer = styled.div`
  padding: 3rem 4.5rem;

  @media screen and (max-width: 768px) {
    padding: 1rem;
  }
`;

export const ChangePasswordHeading = styled.h4`
  color: #142c1f;
  font-size: 1.55rem;
  line-height: 30px;
  font-weight: 400;
  margin: 16px 0 0;
`;

export const ChangePasswordSubHeading = styled.h6`
  color: #44554c;
  font-size: 16px;
  line-height: 20px;
  font-weight: 300;
  margin: 0.7rem 0 20px;
`;
