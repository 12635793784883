import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  NavLink,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

import Logo from "assets/images/new-dashboard/FoodiaryIconKopie.svg";
import * as S from "./dashboard.styles";
import SettingsIcon from "@material-ui/icons/Settings";
import Link from "@material-ui/core/Link";

import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import MainCustomers from "components/MainCustomers/MainCustomers";

import ProfileDropIcon from "components/profileDropIcon/ProfileDropIcon";
import { MenuItems, SidebarSocialIcons } from "./menu";

import InnerSideNav from "./components/InnerSideNav/InnerSide";
import { useHistory } from "react-router-dom";
import TrainerProfileSection from "pages/trainerProfileDetails/TrainerProfileSection";
import AcademyWrapper from "pages/AcademyWrapper/AcademyWrapper";
import WhitePaper from "pages/AcademyWrapper/WhitePaper/WhitePaper";
import AcademyDetails from "pages/AcademyWrapper/AcademyDetails/AcademyDetails";
import WebsiteWrapper from "pages/trainerProfileDetails/website/WebsiteWrapper";
import SocialMediaInstagram from "./SocialMedia/SocialMediaInstagram";
import SocialMediaLinkedIn from "./SocialMedia/SocialMediaLinkedIn";

import { getS3ImageUrl } from "configs/utils";
import ClientDetails from "components/clientDetails/ClientDetails";

const drawerWidth = 300;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    boxShadow: "none",
    background: "transparent",
    borderBottom: "1px solid #00000012",
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
    color: "black",
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    // display: "flex",
    // alignItems: "center",
    // padding: theme.spacing(0, 1),
    // // necessary for content to be below app bar
    // ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    marginTop: "4rem",
    background: "#FAFAFA",
  },
}));

export default function NewDasboard() {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);

  const history = useHistory();

  const trainerProfileData = useSelector((state) => state.auth.trainerData);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar className="topbar-toolbar">
          <IconButton
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon style={{ color: "#000000" }} />
          </IconButton>

          <Typography variant="h6" className="appBarTitle">
            Meine Kunden
          </Typography>
          <div className="ml-auto">
            <ProfileDropIcon
              profileIcon={getS3ImageUrl(trainerProfileData.profilePic)}
              isArrowIcon={false}
            />
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(
          classes.drawer,
          {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          },
          "sideNav"
        )}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <S.LeftWrap>
          <S.UpperBox className="upper-box">
            <S.LogoBar>
              <img src={Logo} className="newDBLogo" alt="" />
              <IconButton onClick={handleDrawerClose}>
                <ChevronRightIcon />
              </IconButton>
            </S.LogoBar>

            <S.MenuLinks className="sideNavLink">
              {/* <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className={classes.heading}>
                    <SupervisorAccountIcon />
                    <span>Klientenverwaltung</span>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Link href="#">Link</Link>
                </AccordionDetails>
              </Accordion> */}
              <List className="secondList">
                {MenuItems.map((item, i) => (
                  <ListItem key={i} className="sidebar-list-item" button>
                    <NavLink to={item.url} activeClassName="active-link">
                      <img src={item.img} alt="menu-icon" />
                      <span>{item.title}</span>
                    </NavLink>
                  </ListItem>
                ))}
              </List>
            </S.MenuLinks>
          </S.UpperBox>

          <S.LowerBox>
            <S.SocialWrap className="qlinks">
              <Typography variant="h6">Quick-Links</Typography>

              <S.SocialBox>
                {SidebarSocialIcons.map((item, i) => (
                  <Link href="#" key={i}>
                    <img src={item.img} alt="social-icon" />
                  </Link>
                ))}
              </S.SocialBox>
            </S.SocialWrap>
            <S.LastNavItem>
              <List>
                <ListItem button>
                  <SettingsIcon />
                  <span>Einstellungen</span>
                </ListItem>
              </List>
            </S.LastNavItem>
          </S.LowerBox>
          {/* <List>
          {['Dashboard', 'Starred', 'Send email', 'Drafts'].map((text, index) => (
            <ListItem button key={text}>
              <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
              <ListItemText primary={text} />
            </ListItem>
          ))}
        </List> */}
        </S.LeftWrap>
      </Drawer>
      <main className={classes.content}>
        <Route
          path="/dashboard/customers/list"
          component={MainCustomers}
          exact
        />


        <Route
          path="/dashboard/profile"
          component={TrainerProfileSection}
          exact
        />

        <Route
          path="/dashboard/academy/video"
          component={AcademyWrapper}
          exact
        />
        <Route
          path="/dashboard/academy/video/:id"
          component={AcademyDetails}
        />

        <Route
          path="/dashboard/academy/white-paper"
          component={WhitePaper}
          exact
        />

        <Route path="/dashboard/website" component={WebsiteWrapper} />
        <Route
          path="/dashboard/social-media/instagram"
          component={SocialMediaInstagram}
        />
        <Route
          path="/dashboard/social-media/linkedin"
          component={SocialMediaLinkedIn}
        />
      </main>
    </div>
  );
}
