import React, { useState, useEffect } from "react";
import * as S from "./profileDropIcon.styles";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

// Components
import OutsideAlerter from "components/OutsideAlerter";

// Images
import Avatar from "assets/images/avatar.svg";
import ArrowDown from "assets/images/arrow-down.svg";
import ProfileIcon from "assets/images/profile-icon.svg";
import Logout from "assets/images/logout.svg";
import Feedback from "assets/images/new/Icon-menu-feedback.svg";
import SocialMedia from "assets/images/new/Icon-menu-social-media.svg";
import Partner from "assets/images/new/icon-menu-partner.svg";
import BackOffice from "assets/images/new/icon-menu-backoffice.svg";
import CalendarIcon from "assets/images/Icon-calendar.svg";
import InfluencerIcon from "assets/images/influencer-icon.svg";
import StagingIcon from "assets/images/staging.svg";

// Redux
import * as AuthActions from "redux/auth/auth.actions";

const ProfileDropIcon = ({ profileIcon, profileName, isArrowIcon = true }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [active, setActive] = useState(false);
  const [DropDownOption, setDropDownOption] = useState([
    {
      value: "Profil",
      image: ProfileIcon,
      alt: "arrow-down",
      onClick: () => {
        history.push("/dashboard/profile");
        setActive(false);
      },
    },
    // {
    //   value: "Profil",
    //   image: ProfileIcon,
    //   alt: "arrow-down",
    //   onClick: () => {
    //     history.push("/main/dashboard/trainerProfile");
    //     setActive(false);
    //   },
    // },
    // {
    //   value: "Feedback",
    //   image: Feedback,
    //   alt: "feedback",
    //   onClick: () => {
    //     window.open(
    //       "https://foodiary.sleekplan.app/",
    //       // "https://docs.google.com/forms/d/e/1FAIpQLSdJChtjgGhv5Q8URRXWHLANA8n7XrgvIrY0Qq0mSOZ87c251Q/viewform",
    //       "_blank"
    //     );
    //   },
    // },
    // {
    //   value: "Social Media",
    //   image: SocialMedia,
    //   alt: "Social Media",
    //   onClick: () => {
    //     window.open(
    //       "https://docs.google.com/forms/d/e/1FAIpQLSc_rHiTv5lMH1wiSfyUvk3ypuOJ37MJTYjxMtCidI62AQln8Q/viewform",
    //       "_blank"
    //     );
    //   },
    // },
    // {
    //   value: "Ansprechpartner",
    //   image: Partner,
    //   alt: "Ansprechpartner",
    //   onClick: () => {
    //     history.push("/contact-persons");
    //   },
    // },
    // {
    //   value: "Verwaltung",
    //   image: BackOffice,
    //   alt: "Verwaltung",
    //   onClick: () => {
    //     history.push("/administration");
    //   },
    // },
    // {
    //   value: "Influencer",
    //   image: InfluencerIcon,
    //   alt: "Influencer",
    //   width: 20,
    //   disabled: true,
    //   onClick: () => {
    //     // history.push("/influencers");
    //   },
    // },
    // {
    //   value: "Kalender",
    //   image: CalendarIcon,
    //   alt: "Kalender",
    //   onClick: () => {
    //     window.open("https://www.calendly.com", "_blank");
    //   },
    // },
    // {
    //   value: "Testumgebung",
    //   image: StagingIcon,
    //   alt: "Testumgebung",
    //   onClick: () => {
    //     window.open("http://3.70.7.80/", "_blank");
    //   },
    // },
    {
      value: "Logout",
      image: Logout,
      alt: "logout",
      onClick: () => {
        dispatch(AuthActions.signOutRequest());
        history.push("/login");
      },
    },
  ]);

  // useEffect(() => {
  //   console.log(window.location.pathname);
  //   const LogoutItem = {
  //     value: "Logout",
  //     image: Logout,
  //     alt: "logout",
  //     onClick: () => {
  //       dispatch(AuthActions.signOutRequest());
  //       history.push("/");
  //     },
  //   };

  //   const CalendarItem = {
  //     value: "Kalender",
  //     image: BackOffice,
  //     alt: "Kalender",
  //     onClick: () => {
  //       window.open("https://www.calendly.com", "_blank");
  //     },
  //   };

  //   const WeeklysItem = {
  //     value: "Weeklys",
  //     image: BackOffice,
  //     alt: "Weeklys",
  //     onClick: () => {
  //       history.push("/main/dashboard/weeklyCalls");
  //     },
  //   };

  //   let options = DropDownOption.filter((item) => item.value !== "Logout");
  //   if (window.location.pathname === "/main/dashboard/weeklyCalls") {
  //     options = options.filter(
  //       (item) => item.value !== "Kalender" && item.value !== "Weeklys"
  //     );

  //     setDropDownOption([...options, CalendarItem, LogoutItem]);
  //   } else {
  //     options = options.filter(
  //       (item) => item.value !== "Kalender" && item.value !== "Weeklys"
  //     );

  //     setDropDownOption([...options, WeeklysItem, LogoutItem]);
  //   }
  // }, [window.location.pathname]);

  return (
    <OutsideAlerter closeModal={() => setActive(false)}>
      <S.ProfileDropIconContainer onClick={() => setActive(!active)}>
        <S.ProfileDropIconImageContainer profilePic={profileIcon || Avatar} />
        {profileName && (
          <S.ProfileDropIconNameContainer>
            {profileName}
          </S.ProfileDropIconNameContainer>
        )}
        {isArrowIcon && (
          <S.ProfileDropIconArrowBottomButtonContainer>
            <img src={ArrowDown} alt={"Arrow Down"} />
          </S.ProfileDropIconArrowBottomButtonContainer>
        )}
      </S.ProfileDropIconContainer>
      {active && (
        <S.ProfileDropdownContainer>
          {DropDownOption.map((option) => {
            return (
              <S.ProfileDropdownOptionContainer
                key={option.value}
                onClick={option.onClick}
                className={option.disabled ? "disabled" : ""}
              >
                <S.ProfileDropdownOptionImage>
                  <img
                    width={option.width || "auto"}
                    src={option.image}
                    alt={option.alt}
                  />
                </S.ProfileDropdownOptionImage>
                <S.ProfileDropdownOption>
                  {option.value}
                </S.ProfileDropdownOption>
              </S.ProfileDropdownOptionContainer>
            );
          })}
        </S.ProfileDropdownContainer>
      )}
    </OutsideAlerter>
  );
};

export default ProfileDropIcon;
