import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import * as S from "./ResetPassword.styles";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Slider from "react-slick";
// Components
import Card from "components/card/Card";
import Input from "components/formComponents/input/Input";
import Button from "components/formComponents/button/Button";
import Loader from "components/loader/Loader";
import ErrorMessage from "components/formComponents/errorMessage/ErrorMessage";
import FoodiaryLogo from "assets/images/Foodiary-Logo.PNG";
import FoodiaryBackground from "assets/images/Foodiary-Background.jpg";
import DashboardOne from "assets/images/Foodiary-dashboard-screen.jpg";
import DashboardTwo from "assets/images/Foodiary-Klienten-Screen.jpg";
import DashboardThree from "assets/images/Foodiary-Academy-Screen.jpg";
import ExclamationCircle from "assets/images/exclamation-circle.svg";
// Redux
import * as NutritionActions from "redux/nutrition/nutrition.actions";

const ResetPassword = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [IsFormSubmitSuccessfuly, setIsFormSubmitSuccessfuly] = useState(false);
  const [IsFormSubmitError, setIsFormSubmitError] = useState(false);

  const isCoachPasswordReseting = useSelector(
    (state) => state.nutrition.isCoachPasswordReseting
  );
  const resetCoachPassword = useSelector(
    (state) => state.nutrition.resetCoachPassword
  );

  useEffect(() => {
    if (resetCoachPassword && resetCoachPassword?.success === false) {
      setIsFormSubmitError(true);
      const t = setTimeout(() => {
        setIsFormSubmitError(false);
        dispatch(NutritionActions.resetPasswordNull());
        clearTimeout(t);
      }, 2000);
    }

    if (!!resetCoachPassword?.success) {
      setIsFormSubmitSuccessfuly(true);

      const t = setTimeout(() => {
        setIsFormSubmitSuccessfuly(false);
        dispatch(NutritionActions.resetPasswordNull());
        history.push("/login");
        clearTimeout(t);
      }, 2000);
    }
  }, [resetCoachPassword]);

  const initialValues = {
    franchiseId: "",
    prename: "",
    password: "",
    confirmPassword: "",
  };

  const validationSchema = Yup.object().shape({
    franchiseId: Yup.string().required("Erforderlich"),
    prename: Yup.string().required("Erforderlich"),
    password: Yup.string()
      .required("Erforderlich")
      .min(8, "Passwort muss mindestens 8 Zeichen lang sein"),
    confirmPassword: Yup.string()
      .required("Erforderlich")
      .oneOf([Yup.ref("password"), null], "Passwort muss übereinstimmen"),
  });

  const onFormSubmit = (values, { resetForm }) => {
    console.log("Reset Password ", values);
    dispatch(
      NutritionActions.resetPasswordRequest({
        formValues: {
          FranchiseID: values.franchiseId,
          prename: values.prename,
          password: values.password,
          confirm_password: values.confirmPassword,
        },
        func: () => {
          // setIsFormSubmitSuccessfuly(true);
          // const t = setTimeout(() => {
          //   setIsFormSubmitSuccessfuly(false);
          //   history.push("/login");
          //   clearTimeout(t);
          // }, 2000);
        },
      })
    );
    resetForm();
  };

  let settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };

  return (
    <>
      <S.LoginContainer>
        <Card
          width="100%"
          maxWidth="530px"
          height="auto"
          margin="auto"
          textAlign="center"
        >
          <S.ChangePasswordInnerContainer>
            <img width={153} src={FoodiaryLogo} alt="" />
            <S.ChangePasswordHeading>
              Passwort zurücksetzen.
            </S.ChangePasswordHeading>
            <S.ChangePasswordSubHeading>
              Gebe deine CoachID ein.
            </S.ChangePasswordSubHeading>
            {resetCoachPassword?.success && IsFormSubmitSuccessfuly && (
              <S.AlertSuccess>Dein Passwort wurde geändert</S.AlertSuccess>
            )}
            {resetCoachPassword?.success === false && IsFormSubmitError && (
              <S.AlertDanger>
                Dein Passwort konnte nicht geändert werden
              </S.AlertDanger>
            )}
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onFormSubmit}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                errors,
                isValid,
                dirty,
              }) => (
                <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                  <div className="form-group">
                    <label>CoachID*</label>
                    <Input
                      className="input-v2"
                      type="text"
                      placeholder="Deine CoachID"
                      name="franchiseId"
                      onChange={handleChange}
                      value={values.franchiseId}
                    />
                    <ErrorMessage error={errors.franchiseId} />
                  </div>

                  <div className="form-group">
                    <label>Vorname*</label>
                    <Input
                      className="input-v2"
                      type="text"
                      placeholder="Vorname"
                      name="prename"
                      onChange={handleChange}
                      value={values.prename}
                    />
                    <ErrorMessage error={errors.prename} />
                  </div>

                  <div className="form-group">
                    <label>Neues Passwort eingeben*</label>
                    <Input
                      className="input-v2"
                      type="password"
                      placeholder="neues Passwort"
                      name="password"
                      onChange={handleChange}
                      value={values.password}
                    />
                    <ErrorMessage error={errors.password} />
                  </div>
                  <div className="form-group">
                    <label>Neues Passwort bestätigen*</label>
                    <Input
                      className="input-v2"
                      type="password"
                      placeholder="neues Passwort"
                      name="confirmPassword"
                      onChange={handleChange}
                      value={values.confirmPassword}
                    />
                    <ErrorMessage error={errors.confirmPassword} />
                    <p className="input-note">
                      <img src={ExclamationCircle} alt="" /> Gebe die E-Mail
                      ein, mit der du dich registriert hast.
                    </p>
                  </div>

                  <Button
                    color="rgba(248, 164, 146, 1)"
                    gradient="linear-gradient(60deg, rgba(46, 207, 150, 1), rgba(28, 157, 135, 1)) !important"
                    style={{ fontSize: "16px" }}
                    margin="40px 0 20px"
                    absolute={{ width: "100%", height: "45px" }}
                    buttonStyle={{ width: "100%" }}
                  >
                    Passwort zurücksetzen
                  </Button>
                  <Loader loading={isCoachPasswordReseting} top="-135px" />

                  <div className="route-links">
                    <span>Du hast keinen Zugang mehr?</span>
                    <a href="mailto:support@foodiary.app">
                      Kontaktiere support@foodiary.app
                    </a>
                  </div>
                </form>
              )}
            </Formik>
          </S.ChangePasswordInnerContainer>
        </Card>

        {/* <S.SliderContainer imgUrl={FoodiaryBackground}>
          <Slider {...settings}>
            <div className="slide-item">
              <img src={DashboardOne} alt="" />
              <h4>Verwalte deine Ernährungsberatung</h4>
              <p>
                Mit dem Coach Dashboard verwaltest du deine Klienten, die Coach
                Webseite und hast Zugang zu wichtigen Funktionen.
              </p>
            </div>
            <div className="slide-item">
              <img src={DashboardTwo} alt="" />
              <h4>Betreue deine Klienten und Teilnehmer</h4>
              <p>
                Über die Klientenverwaltung hast du alle Daten deiner Klienten
                im Überblick und kannst hier wichtige Einstellungen vornehmen.
              </p>
            </div>
            <div className="slide-item">
              <img src={DashboardThree} alt="" />
              <h4>Lerne wichtige Grundlagen</h4>
              <p>
                Mit unserer Academy und den Whitepaper lernst du wichtige
                Grundlagen rund um das Thema “selbstständige Ernährungsberatung”
              </p>
            </div>
          </Slider>
        </S.SliderContainer> */}
      </S.LoginContainer>
    </>
  );
};

export default ResetPassword;
