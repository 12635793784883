import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";

import { getS3ImageUrl } from "configs/utils";
import Avatar from "@material-ui/core/Avatar";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import Typography from "@material-ui/core/Typography";

import TranslationMessage from "configs/translations/TranslationMessage";

import * as AuthActions from "redux/auth/auth.actions";
import * as S from "./ClientDetails.styles";
import "./clientDetails.scss";

const MENUS = [
  {
    id: 1,
    title: "Klientenübersicht",
    path: "/dashboard/customers/list",
  },
  {
    id: 2,
    title: "Testumgebung",
    path: "",
    url: "http://3.70.7.80/",
  },
  {
    id: 3,
    title: "Erstgespräch starten",
    path: "/start-consultation",
  },
  {
    id: 4,
    title: "Token abfragen",
    path: "/dashboard/customers/token-request",
  },
];

const clientDetailsTabData = [
  {
    id: "overview",
    value: "clientDetails.tab.overview",
    url: "/dashboard/clients/overview/:userId",
  },
  {
    id: "calories",
    value: "clientDetails.tab.calories",
    url: "/dashboard/clients/calories/:userId",
  },
  // { id: "diet", value: "clientDetails.tab.diet" },
  {
    id: "diet & nutiritionDistribution",
    value: "clientDetails.tab.diet-nutiritionDistribution",
    url: "/dashboard/clients/diet-and-nutrition/:userId",
  },
  // {
  //   id: "nutiritionDistribution",
  //   value: "clientDetails.tab.nutiritionDistribution",
  // },
  {
    id: "dailyRoutine",
    value: "clientDetails.tab.dailyRoutine",
    url: "/dashboard/clients/daily-routine/:userId",
  },
  // { id: "weightHistory", value: "clientDetails.tab.weightHistory" },
  {
    id: "progress",
    value: "clientDetails.tab.progressTabTitle",
    url: "/dashboard/clients/progress-and-measurements/:userId",
  },
  {
    id: "objective",
    value: "clientDetails.tab.objective",
    url: "/dashboard/clients/objective/:userId",
  },
  {
    id: "anamnese",
    value: "clientDetails.tab.anamnese",
    url: "/dashboard/clients/anamnese/:userId",
  },
  {
    id: "TagebuchHabbitTracker",
    value: "clientDetails.tab.TagebuchHabbitTracker",
    url: "/dashboard/clients/journal/:userId",
  },
  {
    id: "EmailManagement",
    value: "clientDetails.tab.EmailManagement",
    url: "/dashboard/clients/email-management/:userId",
  },
  {
    id: "Ernahrungskurs",
    value: "clientDetails.tab.Ernahrungskurs",
    url: "/dashboard/clients/nutrition-course/:userId",
  },
  {
    id: "invoices",
    value: "clientDetails.tab.invoices",
    url: "/dashboard/clients/invoices/:userId",
  },
  {
    id: "invoice-details",
    value: "clientDetails.tab.invoiceDetails",
    url: "/dashboard/clients/overview/:userId",
  },
  // {
  //   id: "credits",
  //   value: "clientDetails.tab.credits",
  // },
];

const ClientDetailsSidebar = ({ props }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const intl = useIntl();
  const { userId } = useParams();

  const userInvoice = useSelector((state) => state.auth.userInvoice);
  const trainerData = useSelector((state) => state.auth.trainerData);
  const clientDetails = useSelector(
    (state) => state.auth.getClientDetails?.data
  );

  useEffect(() => {
    dispatch(
      AuthActions.getInvoicesOfUserByTrainerRequest({
        formData: {
          trainerID: trainerData?.trainerID,
          userID: userId,
        },
      })
    );
  }, [dispatch, trainerData?.trainerID, userId]);

  return (
    <S.ClientDetailsTabContainer>
      <S.InnerMenuTop>
        <S.BackButton onClick={() => history.push("/dashboard/customers/list")}>
          <NavigateBeforeIcon />
          <span>Klientenübersicht</span>
        </S.BackButton>
      </S.InnerMenuTop>

      <S.InnerMenuMid>
        <div className="user-profile">
          <Avatar
            alt={clientDetails?.prename}
            src="/static/images/avatar/1.jpg"
          />
          <S.InnerMenuInfo>
            <Typography variant="h5">
              {`${clientDetails?.prename || "-"} ${
                clientDetails?.surname || "-"
              }`}
            </Typography>
            <Typography paragraph>
              <span>User-ID: </span>
              {`${userId}`}
            </Typography>
          </S.InnerMenuInfo>
        </div>
        <S.PaymentInfo>
          <p>Bezahlstatus:</p>
          <div className="dots">
            {userInvoice &&
              Object.keys(userInvoice).length > 0 &&
              Object.entries(userInvoice).map(([key, value]) => {
                const dots = [];

                if (value?.total_invoice === 3) {
                  const dotCount = Object.entries(userInvoice).length;
                  for (let i = 0; i < 3; i++) {
                    let dotClass = "grey";
                    if (value.payment_status === "Completed")
                      dotClass = "green";
                    if (value.payment_status === "Incomplete")
                      dotClass = "orange";
                    if (
                      value.payment_status === "Canceled" ||
                      value.payment_status === "Refunded"
                    )
                      dotClass = "red";
                    dots.push(
                      <div
                        key={`dot-${key}-${i}`}
                        className={`dot ${dotClass}`}
                      ></div>
                    );
                  }
                  if (dotCount === 2 && key > 0)
                    dots.push(
                      <div key={`extra-dot-${key}`} className="dot grey"></div>
                    );
                } else if (value?.total_invoice === 1) {
                  for (let i = 0; i < 3; i++) {
                    let dotClass = "grey";
                    if (value.payment_status === "Completed")
                      dotClass = "green";
                    if (value.payment_status === "Incomplete")
                      dotClass = "orange";
                    if (
                      value.payment_status === "Canceled" ||
                      value.payment_status === "Refunded"
                    )
                      dotClass = "red";
                    dots.push(
                      <div
                        key={`dot-${key}-${i}`}
                        className={`dot ${dotClass}`}
                      ></div>
                    );
                  }
                }

                return dots;
              })}
            {userInvoice && Object.keys(userInvoice).length === 0 && (
              <>
                <div key="empty-dot-1" className="dot grey"></div>
                <div key="empty-dot-2" className="dot grey"></div>
                <div key="empty-dot-3" className="dot grey"></div>
              </>
            )}
          </div>
        </S.PaymentInfo>
      </S.InnerMenuMid>
      <S.InnerMenuLast>
        {clientDetailsTabData.map((tab) => (
          <S.ClientDetailsTab
            className={tab?.id}
            key={tab?.id}
            active={window.location.pathname === tab.url.split(":")[0] + userId}
            onClick={() => {
              history.push(tab.url.split(":")[0] + userId);
            }}
          >
            <TranslationMessage id={tab?.value} />
          </S.ClientDetailsTab>
        ))}
      </S.InnerMenuLast>
    </S.ClientDetailsTabContainer>
  );
};

export default ClientDetailsSidebar;
