import styled from "styled-components";
import { LoginHeading, LoginSubHeading } from "pages/login/login.styles";
import { DashboardContainer } from "pages/dashboard/dashboard.styles";

export const RegistrationContainer = styled(DashboardContainer)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin-top: 60px;
`;

export const RegistrationHeading = styled(LoginHeading)``;

export const RegistrationSubHeading = styled(LoginSubHeading)`
  font-size: 22px;
`;

// Gender Screen
export const GenderCardContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 100px;
`;

export const GenderCard = styled.div`
  width: 172px;
  height: 138px;
  background-color: white;
  border-radius: 10px;
  margin-left: ${(props) => (props.marginLeft ? "10px" : "0")};
  cursor: pointer;
  border: ${(props) =>
    props.selected ? "2px solid #1C9D87" : "2px solid white"};

  :hover {
    border: 2px solid #1c9d87;
  }
`;

export const GenderCardImage = styled.img`
  height: 33px;
  margin: 33px auto 20px;
`;

export const GenderCardHeading = styled(RegistrationSubHeading)`
  font-size: 16px;
`;

// Goal Screens
export const GoalCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 100px;
`;

export const GoalCard = styled.div`
  width: 355px;
  height: 84px;
  background-color: white;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  margin: 0px auto 10px;
  position: relative;
  border: ${(props) =>
    props.selected ? "2px solid #1C9D87" : "2px solid white"};

  :hover {
    border: 2px solid #1c9d87;
  }
`;

export const GoalCardImage = styled.img`
  height: 30px;
  margin: auto 23px;
`;

export const GoalCardHeading = styled(RegistrationSubHeading)`
  font-size: 16px;
  padding: ${(props) => (props.withSubtext ? "23px 0 0 0" : "32px 0px")};
`;

export const GoalCardSubHeading = styled.div`
  font-family: RubikLight;
  font-size: 12px;
  color: #44554c;
  position: absolute;
  bottom: 23px;
  left: 70px;
`;

// Body Details
export const BodyDetailsCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 100px;
`;

export const BodyDetailsCard = styled.div`
  width: 355px;
  height: 128px;
  background-color: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  margin: 0px auto 10px;
  text-align: center;
`;

export const BodyDetailsCardHeading = styled(RegistrationSubHeading)`
  font-size: 16px;
  padding: 25px 0 0 0;
  margin-bottom: 20px;
`;

// Daily Routine
export const DailyRoutineCardContainer = styled.div`
  margin-top: 20px;
  margin-bottom: 100px;
`;

export const AddNewDailyRoutineButtonContainer = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
`;

// Add Daily Routine Popup
export const DailyRoutineInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  justify-content: space-between;
`;

export const DailyRoutineInputLabel = styled.div`
  line-height: 35px;
`;

export const DailyRoutineAddButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

// Activity Level
export const ActivityLevelContainer = styled.div`
  width: 750px;
  margin: 20px auto 100px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
`;

export const ActivityLevelCardContainer = styled.div`
  background-color: white;
  border-radius: 10px;
  margin-bottom: 10px;
  margin-left: 10px;
  padding: 10px 28px;
  /* height: 36px; */
  width: calc(50% - 10px);
  line-height: 36px;
  flex-direction: row;
  display: flex;
  justify-content: space-between;
`;

export const ActivityLevelTotalContainer = styled.div`
  margin: 40px auto 0;
  padding: 10px 28px;
  /* height: 36px; */
  /* width: 299px; */
  line-height: 36px;
  flex-direction: row;
  display: flex;
  justify-content: center;
`;

export const ActivityLevelTotalHeading = styled.span`
  font-family: RubikRegular;
  font-size: 16px;
  color: #44554c;
`;

export const ActivityLevelTotal = styled.span`
  margin: 0 15px;
`;

export const ActivityLevelTotalDescription = styled.span`
  font-family: RubikRegular;
  font-size: 12px;
  color: #a5b5af;
`;

// Training Fixed
export const TrainingFixedSwitchContainer = styled.span`
  margin: 21px auto 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const TrainingFixedSwitchHeading = styled.span`
  font-family: RegularRegular;
  font-size: 16px;
  color: #44554c;
  margin-right: 35px;
  line-height: 24px;
`;

export const TrainingFixedContainer = styled(ActivityLevelContainer)``;

export const TrainingFixedCardContainer = styled.div`
  background-color: white;
  border-radius: 10px;
  margin-bottom: 10px;
  margin-left: 10px;
  padding: 16px 28px;
  width: calc(50% - 10px);
  display: flex;
  align-items: center;
`;

// Training Flexible
export const TrainingFlexibleCardContainer = styled(BodyDetailsCardContainer)``;

export const TrainingFlexibleCard = styled(BodyDetailsCard)`
  height: 144px;
`;

export const TrainingFlexibleCardHeading = styled(BodyDetailsCardHeading)`
  padding-top: 33px;
`;

// Eating Habit 1 and 2
export const EatingHabitsContainer = styled(BodyDetailsCardContainer)`
  margin: 10px auto 100px;
`;

export const EatingHabitsCardContainer = styled.div`
  width: 355px;
  height: 131px;
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
`;

export const EatingHabitsImageContainer = styled.img`
  height: 131px;
  border-radius: 8px;
`;

export const EatingHabitsCardInnerContainer = styled.div`
  width: 233px;
  height: 111px;
  margin: 10px 0;
  background-color: white;
  color: black;
  border-radius: 0 10px 10px 0;
  display: flex;
  flex-direction: row;
  border-radius: 10px;
  text-align: left;
`;

export const EatingHabitsInnerCardHeading = styled.div`
  margin: auto 20px;
  width: 140px;
`;

export const EatingHabitsRadioContainer = styled.div`
  margin: 27px 25px 43px 0;
`;

// Personal Data
export const PersonalDataContainer = styled.div`
  background-color: white;
  width: 686px;
  min-height: 459px;
  margin: 0 auto 100px;
  border-radius: 10px;
`;

export const PersonalDataInnerContainer = styled.div`
  margin: 45px 50px;
`;

export const PersonalDataInputContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  padding: 10px 0;

  .phone-input {
    input {
      background-color: white;
      color: #a5b5af;
      -webkit-text-fill-color: #a5b5af;
      font-size: 0.875rem;
      border: rgba(165, 181, 175, 0.15) 2px solid;
      width: 100%;
      padding: 9px 15px;
      border-radius: 5px;
      outline: none;
      margin: 0 auto;
    }
  }
`;

export const PersonalDataRulesContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: 140px;
  margin-top: 40px;
  text-align: left;
`;

export const PersonalDataRulesLabel = styled.label`
  width: 553px;
  margin-bottom: 30px;
  margin-left: 3px;
  color: #44554c;
  font-family: RubikRegular;
  font-size: 16px;

  > a {
    text-decoration: none;
    color: #1c9d87;
  }
`;

export const CategoryCardsContainer = styled.div`
  max-width: 355px;
  margin-top: 50px;
  margin-inline: auto;
  width: 100%;
`;

export const CategoryCard = styled.div`
  background: #ffffff;
  box-shadow: 0px 13px 20px #d6d6d629;
  border-radius: 8px;
  padding: 24px 15px;
  display: flex;
  align-items: center;
  cursor: pointer;
  border: 2px solid transparent;

  ${({ active }) =>
    active &&
    `
    border-color: #2ecf96;
    `}

  img {
    max-width: 40px;
    width: 100%;
    margin-right: 28px;
  }

  p {
    color: #142c1f;
    font-size: 16px;
    line-height: 19px;
    margin: 0;
  }

  &:not(:last-child) {
    margin-bottom: 10px;
  }
`;
