import styled from "styled-components";
import Tick from "assets/images/GreenCheck.svg";

export const Card = styled.div`
  width: 100%;
  max-width: 924px;
  margin: 50px auto auto auto;
  background: #ffffff;
  box-shadow: 0px 2px 5px #0000001a;
  border-radius: 10px;
  padding: 30px;
  box-sizing: border-box;

  @media (max-width: 991.99px) {
    padding: 20px;
    margin-inline: 15px;
  }
`;

export const FirstRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: end;
  @media (max-width: 767px) {
    flex-direction: column;
    align-items: start;
  }
`;

export const LeftSide = styled.div`
  display: flex;
`;
export const InfoBox = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 15px;
`;

export const DetailBoxRight = styled.div`
  width: 50%;
  @media (max-width: 767px) {
    width: 100%;
    margin-top: 50px;
  }
`;
export const RightSide = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 767px) {
    margin-top: 30px;
  }
  .custumStyle {
    width: 312px;
    height: 39px;
    .react-select__control {
      border: none;
      &:hover {
        cursor: pointer;
      }
    }

    .react-select__dropdown-indicator {
      color: #a5b5af;
    }
    @media (max-width: 991.99px) {
      width: 300px;
    }
    @media (max-width: 757px) {
      width: 280px;
    }
  }
`;

export const Loader = styled.h2`
  color: #142c1f;
  font-size: 22px;
  line-height: 32px;
  font-weight: 500;
  margin: 200px 0;
  text-align: center;
`;

export const SmallText = styled.span`
  color: #1c9d87;
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
`;

export const Heading = styled.div`
  font-size: 24px;
  line-height: 34px;
  font-weight: 500;
  color: #142c1f;
  margin: 0px;
`;

export const FollowText = styled.span`
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  color: #44554c;
  margin-right: 15px;
`;

export const PageSmallTitle = styled.p`
  font-size: 14px;
  line-height: 32px;
  color: #1c9d87;
  font-weight: 500;
  margin: 60px 0 0;
  text-align: center;
`;

export const PageTitle = styled.h2`
  color: #142c1f;
  font-size: 22px;
  line-height: 32px;
  font-weight: 500;
  margin: 0 0 50px;
  text-align: center;
`;

export const ListBox = styled.div``;

export const Item = styled.div`
  display: flex;
  gap: 30px;
  cursor: pointer;
  &:not(:last-child) {
    border-bottom: 1px solid #eeeeee;
    margin-bottom: 20px;
    padding-bottom: 20px;
  }
`;

export const ItemImage = styled.div`
  .user-img {
    width: 232px;
    object-fit: cover;
    border-radius: 10px;
  }
`;

export const ItemContent = styled.div`
  padding-top: 10px;
`;

export const ItemChip = styled.div`
  border-radius: 5px;
  padding: 5px 10px;
  width: fit-content;
  font-size: 12px;
  line-height: 14px;

  &.red {
    color: #ee2b2b;
    background: #ee2b2b1a;
  }

  &.green {
    color: #2ecf96;
    background: #2ecf961a;
  }

  &.yellow {
    color: #FFBA31;
    background: #FFBA311A;
  }
`;

export const Title = styled.h4`
  color: #142c1f;
  font-size: 18px;
  line-height: 22px;
  margin: 15px 0 0;
`;

export const Description = styled.p`
  color: #44554c;
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  margin: 15px 0 20px;
  font-weight: 400;
`;

export const DateTimeBox = styled.div`
  display: flex;
  align-items: center;
  gap: 40px;
`;

export const TimeAndDateItem = styled.div`
  display: flex;
  align-items: center;
  gap: 8.5px;

  img {
    width: 14px;
  }

  span {
    color: #a5b5af;
    font-size: 14px;
    line-height: 22px;
    font-weight: 400;
  }
`;

// detail box
export const DetailBox = styled.div`
  background: #ffffff;
  box-shadow: 0px 13px 20px #d6d6d629;
  border-radius: 8px;
  padding: 30px;
  margin: 30px 0 100px;
`;

export const DetailBoxInner = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 767px) {
    flex-direction: column;
  }
`;
export const DetailBoxLeft = styled.div`
  width: 50%;
  @media (max-width: 767px) {
    width: 100%;
  }
`;

export const DetailBoxTop = styled.div`
  border-left: 2px solid #2ecf96;
  padding-left: 15px;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
  span {
    letter-spacing: 0.24px;
    color: #44554c;
    text-transform: uppercase;
    font-size: 12px;
    line-height: 24px;
  }
`;
export const ProfilBox = styled.div`
  background: #eeeeee;
  border-radius: 23px;
  padding: 4px 15px 4px 4px;
  display: flex;
  align-items: center;
  margin-top: 10px;

  img {
    width: 29px;
    height: 29px;
    border-radius: 50%;
  }
  p {
    color: #44554c;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    margin: 0px;
    padding-left: 10px;
  }
`;

export const Insights = styled.div`
  margin: 40px 0 30px;

  h3 {
    letter-spacing: 0.24px;
    color: #44554c;
    text-transform: uppercase;
    font-size: 12px;
    line-height: 24px;
    margin: 0 0 15px;
    font-weight: 500 !important;
  }
  ul {
    margin: 0px;
    padding: 0px 0 0 0px;
    list-style: none;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    @media (max-width: 600px) {
      grid-template-columns: repeat(1, 1fr);
    }
    li {
      letter-spacing: 0px;
      color: #44554c;
      font-size: 14px;
      line-height: 21px;
      font-weight: 400;
      background-image: url(${Tick});
      background-repeat: no-repeat;

      padding: 0 10px 0 25px;
      background-position: 0px 4px;
      margin-bottom: 10px;
    }
  }
`;
export const DetailPrice = styled.div`
  h3 {
    letter-spacing: 0.24px;
    color: #44554c;
    text-transform: uppercase;
    font-size: 12px;
    line-height: 24px;
    margin: 0 0 10px;
    font-weight: 500 !important;
  }
  p {
    margin: 0;
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    color: #1c9d87;
  }
`;
export const ButtonBox = styled.div`
  margin-top: 50px;
  a {
    background: #f8a492 0% 0% no-repeat padding-box;
    border-radius: 8px;
    letter-spacing: 0px;
    font-size: 16px;
    line-height: 19px;
    color: #ffffff;
    padding: 12px 20px;
    border: none;
    font-weight: 500;
    cursor: pointer;
    text-decoration: none;
  }
`;

export const BackButton = styled.button`
  width: 30px;
  background: #eaefed;
  border-radius: 4px;
  height: 53px;
  border: none;
  cursor: pointer;
  img {
    transform: rotate(90deg);
  }
`;
export const InputWrap = styled.div`
  display: flex;
  flex-direction: column;

  label {
    letter-spacing: 0.24px;
    color: #44554c;
    font-size: 12px;
    line-height: 24px;
    font-weight: 500;
    text-transform: uppercase;
    margin-bottom: 10px;
  }
  input {
    border: 1px solid #dce1e0;
    letter-spacing: 0px;
    color: #a5b5af;
    font-size: 14px;
    line-height: 17px;
    font-style: italic;
    border-radius: 4px;
    padding: 11px 15px;
    margin-bottom: 20px;
    &::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: #a5b5af;
    }
    &::-moz-placeholder {
      /* Firefox 19+ */
      color: #a5b5af;
    }
    &:-ms-input-placeholder {
      /* IE 10+ */
      color: #a5b5af;
    }
    &:-moz-placeholder {
      /* Firefox 18- */
      color: #a5b5af;
    }
  }
  textarea {
    border: 1px solid #dce1e0;
    letter-spacing: 0px;
    color: #a5b5af;
    font-size: 14px;
    line-height: 17px;
    font-style: italic;
    border-radius: 4px;
    padding: 11px 15px;
    height: 194px;
    resize: none;

    &::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: #a5b5af;
    }
    &::-moz-placeholder {
      /* Firefox 19+ */
      color: #a5b5af;
    }
    &:-ms-input-placeholder {
      /* IE 10+ */
      color: #a5b5af;
    }
    &:-moz-placeholder {
      /* Firefox 18- */
      color: #a5b5af;
    }
  }
`;
