import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as S from "./evaluationOfOverallConditionForCustomers.styles";

import EvaluationCardForCustomer from "components/evaluationCardForCustomer/evaluationCardForCustomer";
import { EVALUATION_ITEMS } from "./EvaluationData";

// Redux
import * as AuthActions from "redux/auth/auth.actions";

const EvaluationOfOverallConditionForCustomers = ({ userID }) => {
  const dispatch = useDispatch();
  const userAnamneses = useSelector((state) => state.auth.userAnamneses);

  const [Evaluations, setEvaluations] = useState([...EVALUATION_ITEMS]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    // setEvaluations(EVALUATION_ITEMS);
    dispatch(
      AuthActions.getUserAnamneseRequest({
        formData: { userID, weekID: 1 },
        callback: (response) => {
          setIsLoading(false);
          displayAnamnese(response?.data[0]);
        },
      })
    );
  }, []);

  const updateFormValues = (list, obj, property) => {
    return list.map((i) => {
      if (i.state === obj[property]) {
        i.isActive = true;
      }

      return i;
    });
  };

  const displayAnamnese = (userAnamneses) => {
    const evaluations = [...EVALUATION_ITEMS].map((obj) => {
      const item = { ...obj };
      if (item.title === "Stresslevel") {
        console.log("userAnamneses", userAnamneses);
        item.images = updateFormValues(
          item.images,
          userAnamneses,
          "stress_level"
        );
      }
      if (item.title === "Körperform") {
        item.images = updateFormValues(item.images, userAnamneses, "body_fat");
      }
      if (item.title === "Sportliche Aktivität") {
        item.numbers = updateFormValues(
          item.numbers,
          userAnamneses,
          "sport_activity"
        );
      }
      if (item.title === "Tägliche Aktivität") {
        item.numbers = updateFormValues(
          item.numbers,
          userAnamneses,
          "daily_activity"
        );
      }
      if (item.title === "Rauchen") {
        item.numbers = updateFormValues(item.numbers, userAnamneses, "smoking");
      }
      if (item.title === "Alkohol") {
        item.numbers = updateFormValues(item.numbers, userAnamneses, "alcohol");
      }

      if (item.title === "Obst & Gemüse") {
        item.numbers = updateFormValues(
          item.numbers,
          userAnamneses,
          "fruits_vegitables"
        );
      }
      if (item.title === "Mahlzeitenfrequenz") {
        item.numbers = updateFormValues(
          item.numbers,
          userAnamneses,
          "meal_frequency"
        );
      }
      if (item.title === "Trinken") {
        item.numbers = updateFormValues(
          item.numbers,
          userAnamneses,
          "drinking"
        );
      }
      if (item.title === "Heißhunger") {
        item.numbers = updateFormValues(
          item.numbers,
          userAnamneses,
          "cravings"
        );
      }
      if (item.title === "Stimmungsschwankungen") {
        item.numbers = updateFormValues(
          item.numbers,
          userAnamneses,
          "mood_swings"
        );
      }
      if (item.title === "Schwächegefühl") {
        item.numbers = updateFormValues(
          item.numbers,
          userAnamneses,
          "weekness_feeling"
        );
      }
      return item;
    });

    setEvaluations(evaluations);
  };

  return (
    <>
      {isLoading && (
        <>
          <br />
          <br />
          <br />
          <br />
          <div className="text-center">
            <div className="loader mx-auto"></div>
          </div>
          <br />
          <br />
          <br />
          <br />
        </>
      )}
      {!isLoading && (
        <S.GridView>
          {Evaluations.map((item) => (
            <EvaluationCardForCustomer item={item} />
          ))}
        </S.GridView>
      )}
    </>
  );
};

export default EvaluationOfOverallConditionForCustomers;
