import styled from "styled-components"
import { DashboardContainer } from "pages/dashboard/dashboard.styles"

export const Container = styled(DashboardContainer)`
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  /* width: 100%;
  max-width: 1200px; */
`

export const Title = styled.div`
  color: #1c9d87;
  font-size: 14px;
`

export const SubTitle = styled.div`
  color: #142c1f;
  font-size: 24px;
  margin: 10px 0 30px 0;
`

export const CardsContainer = styled.div`
  display: flex;
  gap: 11px;
  justify-content: center;
  margin-top: 50px;
`

export const Card = styled.div`
  background: #ffffff;
  box-shadow: 0px 13px 20px #d6d6d629;
  border-radius: 8px;
  width: 172px;
  height: 138px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition-duration: .3s;
    transition-property: transform;
    transition: all .3s;
    -webkit-transition: all .3s;
    -o-transition: all .3s;

    &:not(:disabled):hover {
        transform: scale(0.9);
    }
`

export const CardImage = styled.img`
  width: 34px;
  height: auto;
  display: block;
  margin: 0 auto;
`

export const CardTitle = styled.h3`
  text-align: center;
  font-size: 16px;
  line-height: 19px;
  font-family: RubikMedium, Georgia;
  font-weight: 500;
  color: #142c1f;
  margin: 0;
`
