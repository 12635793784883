export const addUpNutrients = (selectedNutrients, newNutrients) => {
  let nutrients = { ...selectedNutrients };

  newNutrients.forEach((item) => {
    nutrients.carbohydrates += item?.eatingHabitData?.nutrients?.carbohydrates;
    nutrients.protein += item?.eatingHabitData?.nutrients?.protein;
    nutrients.fat += item?.eatingHabitData?.nutrients?.fat;
  });

  return nutrients;
};

export const setDefaultValuesOfCategories = (savedCategoryId, allNutrients) => {
  const {
    eatingHabit1,
    eatingHabit2,
    eatingHabit3,
    eatingHabit4,
    eatingHabit5,
    eatingHabit2Vegan,
    eatingHabit3Vegan,
    eatingHabit4Vegan,
    eatingHabit5Vegan,
    eatingHabit2Vegetarian,
    eatingHabit3Vegetarian,
    eatingHabit4Vegetarian,
    eatingHabit5Vegetarian,
  } = allNutrients;

  switch (savedCategoryId) {
    case 1:
      return [
        eatingHabit1,
        eatingHabit2,
        eatingHabit3,
        eatingHabit4,
        eatingHabit5,
      ];

    case 2:
      return [
        eatingHabit1,
        eatingHabit2Vegan,
        eatingHabit3Vegan,
        eatingHabit4Vegan,
        eatingHabit5Vegan,
      ];

    case 3:
      return [
        eatingHabit1,
        eatingHabit2Vegetarian,
        eatingHabit3Vegetarian,
        eatingHabit4Vegetarian,
        eatingHabit5Vegetarian,
      ];

    default:
      return [
        eatingHabit1,
        eatingHabit2,
        eatingHabit3,
        eatingHabit4,
        eatingHabit5,
      ];
  }
};

export const addUpNutrientsWithDefaultValues = (
  urlId,
  selectedNutrients,
  allNutrients
) => {
  const {
    eatingHabit1,
    eatingHabit2,
    eatingHabit3,
    eatingHabit4,
    eatingHabit5,
    eatingHabit2Vegan,
    eatingHabit3Vegan,
    eatingHabit4Vegan,
    eatingHabit5Vegan,
    eatingHabit2Vegetarian,
    eatingHabit3Vegetarian,
    eatingHabit4Vegetarian,
    eatingHabit5Vegetarian,
  } = allNutrients;

  switch (urlId) {
    case "eatingHabit1":
      return addUpNutrients(selectedNutrients, eatingHabit1.nutrients);

    case "eatingHabit2":
      return addUpNutrients(selectedNutrients, eatingHabit2.nutrients);
    case "eatingHabit3":
      return addUpNutrients(selectedNutrients, eatingHabit3.nutrients);
    case "eatingHabit4":
      return addUpNutrients(selectedNutrients, eatingHabit4.nutrients);
    case "eatingHabit5":
      return addUpNutrients(selectedNutrients, eatingHabit5.nutrients);

    case "eatingHabit2-vegan":
      return addUpNutrients(selectedNutrients, eatingHabit2Vegan.nutrients);
    case "eatingHabit3-vegan":
      return addUpNutrients(selectedNutrients, eatingHabit3Vegan.nutrients);
    case "eatingHabit4-vegan":
      return addUpNutrients(selectedNutrients, eatingHabit4Vegan.nutrients);
    case "eatingHabit5-vegan":
      return addUpNutrients(selectedNutrients, eatingHabit5Vegan.nutrients);

    case "eatingHabit2-vegetarian":
      return addUpNutrients(
        selectedNutrients,
        eatingHabit2Vegetarian.nutrients
      );
    case "eatingHabit3-vegetarian":
      return addUpNutrients(
        selectedNutrients,
        eatingHabit3Vegetarian.nutrients
      );
    case "eatingHabit4-vegetarian":
      return addUpNutrients(
        selectedNutrients,
        eatingHabit4Vegetarian.nutrients
      );
    case "eatingHabit5-vegetarian":
      return addUpNutrients(
        selectedNutrients,
        eatingHabit5Vegetarian.nutrients
      );

    default:
      return addUpNutrients(selectedNutrients, selectedNutrients);
  }
};

export const calculateNutiritionTypeId = (aimID, selectedNutrients) => {
  let nutritionTypeID = 2;
  switch (aimID) {
    case 1:
      // Anabole Diät
      if (selectedNutrients.fat == 4 && selectedNutrients.carbohydrates == 0) {
        nutritionTypeID = 1;
      }
      // Lowcarb Diät
      if (
        (selectedNutrients.fat == 3 && selectedNutrients.carbohydrates == 1) ||
        (selectedNutrients.fat == 2 && selectedNutrients.carbohydrates == 2)
      ) {
        nutritionTypeID = 4;
      }
      // Metabol Diät
      if (selectedNutrients.fat < selectedNutrients.carbohydrates) {
        nutritionTypeID = 2;
      }
      break;
    case 2:
      // High Carb
      if (selectedNutrients.fat > selectedNutrients.carbohydrates) {
        nutritionTypeID = 2;
      }
      // Metabol
      else {
        nutritionTypeID = 3;
      }
      break;
    case 3:
      // High Carb
      if (selectedNutrients.fat > selectedNutrients.carbohydrates) {
        nutritionTypeID = 2;
      }
      // Metabol
      else {
        nutritionTypeID = 3;
      }
      break;
    case 4:
      // Anabole Diät
      if (selectedNutrients.fat == 4 && selectedNutrients.carbohydrates == 0) {
        nutritionTypeID = 1;
      }
      // Lowcarb Diät
      if (
        (selectedNutrients.fat == 3 && selectedNutrients.carbohydrates == 1) ||
        (selectedNutrients.fat == 2 && selectedNutrients.carbohydrates == 2)
      ) {
        nutritionTypeID = 4;
      }
      // Metabol Diät
      if (selectedNutrients.fat < selectedNutrients.carbohydrates) {
        nutritionTypeID = 2;
      }
      break;
    default:
      nutritionTypeID = 2;
      break;
  }

  return nutritionTypeID;
};
