import React from 'react';
import * as S from './backIconButton.styles';

// Images
import LeftIcon from 'assets/images/leftIcon.svg';

const BackIconButton = (props) => {
  return (
    <S.BackIconButtonContainer {...props}>
        <img src={LeftIcon} alt="Left Icon" />
    </S.BackIconButtonContainer>
  )
}

export default BackIconButton