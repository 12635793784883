const AuthTypes = {
  SET_DAILY_ROUTINE_DATA: "SET_DAILY_ROUTINE_DATA",
  SET_REGISTRATION_DATA: "SET_REGISTRATION_DATA",
  RESET_REGISTRATION_DATA: "RESET_REGISTRATION_DATA",
  SET_HEALTH_SCORE_DATA: "SET_HEALTH_SCORE_DATA",

  LOADING_START: "LOADING_START",
  LOADING_STOP: "LOADING_STOP",

  GET_COACH_CARD_WEBSITE_DETAILS_LOADING_START: "GET_COACH_CARD_WEBSITE_DETAILS_LOADING_START",
  GET_COACH_CARD_WEBSITE_DETAILS_LOADING_STOP: "GET_COACH_CARD_WEBSITE_DETAILS_LOADING_STOP",

  UPDATE_COACH_CARD_WEBSITE_DETAILS_LODING_START: "UPDATE_COACH_CARD_WEBSITE_DETAILS_LODING_START",
  UPDATE_COACH_CARD_WEBSITE_DETAILS_LOADING_STOP: "UPDATE_COACH_CARD_WEBSITE_DETAILS_LODING_STOP",

  GET_COACH_CARD_DETAILS_LOADING_START: "GET_COACH_CARD_DETAILS_LOADING_START",
  GET_COACH_CARD_DETAILS_LOADING_STOP: "GET_COACH_CARD_DETAILS_LOADING_STOP",

  UPDATE_COACH_CARD_DETAILS_LOADING_START: "UPDATE_COACH_CARD_DETAILS_LOADING_START",
  UPDATE_COACH_CARD_DETAILS_LOADING_STOP: "UPDATE_COACH_CARD_DETAILS_LOADING_STOP",

  ADD_COACH_CARD_QUALIFICATION_LOADING_START: "ADD_COACH_CARD_QUALIFICATION_LOADING_START",
  ADD_COACH_CARD_QUALIFICATION_LOADING_STOP: "ADD_COACH_CARD_QUALIFICATION_LOADING_STOP",

  UPDATE_COACH_CARD_QUALIFICATION_LOADING_START: "UPDATE_COACH_CARD_QUALIFICATION_LOADING_START",
  UPDATE_COACH_CARD_QUALIFICATION_LOADING_STOP: "UPDATE_COACH_CARD_QUALIFICATION_LOADING_STOP",

  REMOVE_COACH_CARD_QUALIFICATION_LOADING_START: "REMOVE_COACH_CARD_QUALIFICATION_LOADING_START",
  REMOVE_COACH_CARD_QUALIFICATION_LOADING_STOP: "REMOVE_COACH_CARD_QUALIFICATION_LOADING_STOP",

  GET_COACH_CARD_QUALIFICATION_LOADING_START: "GET_COACH_CARD_QUALIFICATION_LOADING_START",
  GET_COACH_CARD_QUALIFICATION_LOADING_STOP: "GET_COACH_CARD_QUALIFICATION_LOADING_STOP",

  CREATE_WEBSITE_LOADING_START: "CREATE_WEBSITE_LOADING_START",
  CREATE_WEBSITE_LOADING_STOP: "CREATE_WEBSITE_LOADING_STOP",

  GET_WEEK_WISE_IMAGES_LOADING_START: "GET_WEEK_WISE_IMAGES_LOADING_START",
  GET_WEEK_WISE_IMAGES_LOADING_STOP: "GET_WEEK_WISE_IMAGES_LOADING_STOP",

  GET_WEEK_WISE_IMAGES_REQUEST: "GET_WEEK_WISE_IMAGES_REQUEST",
  GET_WEEK_WISE_IMAGES_SUCCESS: "GET_WEEK_WISE_IMAGES_SUCCESS",
  GET_WEEK_WISE_IMAGES_FAILURE: "GET_WEEK_WISE_IMAGES_FAILURE",

  GET_BODY_MEASUREMENTS_LOADING_START: "GET_BODY_MEASUREMENTS_LOADING_START",
  GET_BODY_MEASUREMENTS_LOADING_STOP: "GET_BODY_MEASUREMENTS_LOADING_STOP",

  GET_BODY_MEASUREMENTS_REQUEST: "GET_BODY_MEASUREMENTS_REQUEST",
  GET_BODY_MEASUREMENTS_SUCCESS: "GET_BODY_MEASUREMENTS_SUCCESS",
  GET_BODY_MEASUREMENTS_FAILURE: "GET_BODY_MEASUREMENTS_FAILURE",

  GET_USER_WEIGHT_WEEK_WISE_LOADING_START:
    "GET_USER_WEIGHT_WEEK_WISE_LOADING_START",
  GET_USER_WEIGHT_WEEK_WISE_LOADING_STOP:
    "GET_USER_WEIGHT_WEEK_WISE_LOADING_STOP",

  GET_USER_WEIGHT_WEEK_WISE_REQUEST: "GET_USER_WEIGHT_WEEK_WISE_REQUEST",
  GET_USER_WEIGHT_WEEK_WISE_SUCCESS: "GET_USER_WEIGHT_WEEK_WISE_SUCCESS",
  GET_USER_WEIGHT_WEEK_WISE_FAILURE: "GET_USER_WEIGHT_WEEK_WISE_FAILURE",

  GET_USER_WEIGHT_GOAL_DATA_LOADING_START:
    "GET_USER_WEIGHT_GOAL_DATA_LOADING_START",
  GET_USER_WEIGHT_GOAL_DATA_LOADING_STOP:
    "GET_USER_WEIGHT_GOAL_DATA_LOADING_STOP",

  GET_USER_WEIGHT_GOAL_DATA_REQUEST: "GET_USER_WEIGHT_GOAL_DATA_REQUEST",
  GET_USER_WEIGHT_GOAL_DATA_SUCCESS: "GET_USER_WEIGHT_GOAL_DATA_SUCCESS",
  GET_USER_WEIGHT_GOAL_DATA_FAILURE: "GET_USER_WEIGHT_GOAL_DATA_FAILURE",

  SIGN_IN_REQUEST: "SIGN_IN_REQUEST",
  SIGN_IN_SUCCESS: "SIGN_IN_SUCCESS",
  SIGN_IN_FAILURE: "SIGN_IN_FAILURE",
  SIGN_IN_NULL: "SIGN_IN_NULL",

  PASSWORD_RESET_LOADING_START: "PASSWORD_RESET_LOADING_START",
  PASSWORD_RESET_LOADING_STOP: "PASSWORD_RESET_LOADING_STOP",

  PASSWORD_RESET_REQUEST: "PASSWORD_RESET_REQUEST",
  PASSWORD_RESET_SUCCESS: "PASSWORD_RESET_SUCCESS",
  PASSWORD_RESET_FAILURE: "PASSWORD_RESET_FAILURE",

  CREATE_WEBSITE_REQUEST: "CREATE_WEBSITE_REQUEST",
  CREATE_WEBSITE_SUCCESS: "CREATE_WEBSITE_SUCCESS",
  CREATE_WEBSITE_FAILURE: "CREATE_WEBSITE_FAILURE",

  TOKEN_LOGIN_REQUEST: "TOKEN_LOGIN_REQUEST",
  TOKEN_LOGIN_SUCCESS: "TOKEN_LOGIN_SUCCESS",
  TOKEN_LOGIN_FAILURE: "TOKEN_LOGIN_FAILURE",

  SIGN_IN_EXISTING_PLAN_REQUEST: "SIGN_IN_EXISTING_PLAN_REQUEST",
  SIGN_IN_EXISTING_PLAN_SUCCESS: "SIGN_IN_EXISTING_PLAN_SUCCESS",
  SIGN_IN_EXISTING_PLAN_FAILURE: "SIGN_IN_EXISTING_PLAN_FAILURE",

  CHANGE_PASSWORD_REQUEST: "CHANGE_PASSWORD_REQUEST",
  CHANGE_PASSWORD_SUCCESS: "CHANGE_PASSWORD_SUCCESS",
  CHANGE_PASSWORD_FAILURE: "CHANGE_PASSWORD_FAILURE",

  TRAINER_SIGN_UP_REQUEST: "TRAINER_SIGN_UP_REQUEST",
  TRAINER_SIGN_UP_SUCCESS: "TRAINER_SIGN_UP_SUCCESS",
  TRAINER_SIGN_UP_FAILURE: "TRAINER_SIGN_UP_FAILURE",

  TRAINER_DETAILS_EDIT_REQUEST: "TRAINER_DETAILS_EDIT_REQUEST",
  TRAINER_DETAILS_EDIT_SUCCESS: "TRAINER_DETAILS_EDIT_SUCCESS",
  TRAINER_DETAILS_EDIT_FAILURE: "TRAINER_DETAILS_EDIT_FAILURE",

  GET_USERS_BY_TRAINER_REQUEST: "GET_USERS_BY_TRAINER_REQUEST",
  GET_USERS_BY_TRAINER_SUCCESS: "GET_USERS_BY_TRAINER_SUCCESS",
  GET_USERS_BY_TRAINER_FAILURE: "GET_USERS_BY_TRAINER_FAILURE",

  GET_INVOICES_BY_TRAINER_REQUEST: "GET_INVOICES_BY_TRAINER_REQUEST",
  GET_INVOICES_BY_TRAINER_SUCCESS: "GET_INVOICES_BY_TRAINER_SUCCESS",
  GET_PAID_INVOICES_BY_TRAINER_REQUEST: "GET_PAID_INVOICES_BY_TRAINER_REQUEST",
  CLEAR_INVOICES_BY_TRAINER: "CLEAR_INVOICES_BY_TRAINER",
  GET_INVOICES_BY_TRAINER_FAILURE: "GET_INVOICES_BY_TRAINER_FAILURE",

  GET_INVOICES_OF_USER_BY_TRAINER_REQUEST:
    "GET_INVOICES_OF_USER_BY_TRAINER_REQUEST",
  GET_INVOICES_OF_USER_BY_TRAINER_SUCCESS:
    "GET_INVOICES_OF_USER_BY_TRAINER_SUCCESS",
  GET_INVOICES_OF_USER_BY_TRAINER_FALIURE:
    "GET_INVOICES_OF_USER_BY_TRAINER_FALIURE",

  GET_USER_DETAILS_BY_TRAINER_REQUEST: "GET_USER_DETAILS_BY_TRAINER_REQUEST",
  GET_USER_DETAILS_BY_TRAINER_SUCCESS: "GET_USER_DETAILS_BY_TRAINER_SUCCESS",
  GET_USER_DETAILS_BY_TRAINER_FAILURE: "GET_USER_DETAILS_BY_TRAINER_FAILURE",

  GET_CLIENT_DETAILS_REQUEST: "GET_CLIENT_DETAILS_REQUEST",
  GET_CLIENT_DETAILS_SUCCESS: "GET_CLIENT_DETAILS_SUCCESS",
  GET_CLIENT_DETAILS_FAILURE: "GET_CLIENT_DETAILS_FAILURE",
  GET_CLIENT_DETAILS_LOADING_START: "GET_CLIENT_DETAILS_LOADING_START",
  GET_CLIENT_DETAILS_LOADING_STOP: "GET_CLIENT_DETAILS_LOADING_STOP",

  UPDATE_USER_DETAILS_BY_TRAINER_REQUEST:
    "UPDATE_USER_DETAILS_BY_TRAINER_REQUEST",
  UPDATE_USER_DETAILS_BY_TRAINER_SUCCESS:
    "UPDATE_USER_DETAILS_BY_TRAINER_SUCCESS",
  UPDATE_USER_DETAILS_BY_TRAINER_FAILURE:
    "UPDATE_USER_DETAILS_BY_TRAINER_FAILURE",

  GET_TRAINER_DETAILS_REQUEST: "GET_TRAINER_DETAILS_REQUEST",
  GET_TRAINER_DETAILS_SUCCESS: "GET_TRAINER_DETAILS_SUCCESS",
  GET_TRAINER_DETAILS_FAILURE: "GET_TRAINER_DETAILS_FAILURE",

  UPDATE_TRAINER_DETAILS_REQUEST: "UPDATET_TRAINER_DETAILS_REQUEST",
  UPDATE_TRAINER_DETAILS_SUCCESS: "UPDATE_TRAINER_DETAILS_SUCCESS",
  UPDATE_TRAINER_DETAILS_FAILURE: "UPDATE_TRAINER_DETAILS_FAILURE",

  GET_COACH_CARD_WEBSITE_DETAILS_REQUEST: "GET_COACH_CARD_WEBSITE_DETAILS_REQUEST",
  GET_COACH_CARD_WEBSITE_DETAILS_SUCCESS: "GET_COACH_CARD_WEBSITE_DETAILS_SUCCESS",
  GET_COACH_CARD_WEBSITE_DETAILS_FAILURE: "GET_COACH_CARD_WEBSITE_DETAILS_FAILURE",

  UPDATE_COACH_CARD_DETAILS_REQUEST: "UPDATE_COACH_CARD_DETAILS_REQUEST",
  UPDATE_COACH_CARD_DETAILS_SUCCESS: "UPDATE_COACH_CARD_DETAILS_SUCCESS",
  UPDATE_COACH_CARD_DETAILS_FAILURE: "UPDATE_COACH_CARD_DETAILS_FAILURE",

  UPDATE_COACH_CARD_WEBSITE_DETAILS_REQUEST: "UPDATET_COACH_CARD_WEBSITE_DETAILS_REQUEST",
  UPDATE_COACH_CARD_WEBSITE_DETAILS_SUCCESS: "UPDATE_COACH_CARD_WEBSITE_DETAILS_SUCCESS",
  UPDATE_COACH_CARD_WEBSITE_DETAILS_FAILURE: "UPDATE_COACH_CARD_WEBSITE_DETAILS_FAILURE",

  GET_COACH_CARD_DETAILS_REQUEST: "GET_COACH_CARD_DETAILS_REQUEST",
  GET_COACH_CARD_DETAILS_SUCCESS: "GET_COACH_CARD_DETAILS_SUCCESS",
  GET_COACH_CARD_DETAILS_FAILURE: "GET_COACH_CARD_DETAILS_FAILURE",

  ADD_COACH_CARD_QUALIFICATION_REQUEST: "ADD_COACH_CARD_QUALIFICATION_REQUEST",
  ADD_COACH_CARD_QUALIFICATION_SUCCESS: "ADD_COACH_CARD_QUALIFICATION_SUCCESS",
  ADD_COACH_CARD_QUALIFICATION_FAILURE: "ADD_COACH_CARD_QUALIFICATION_FAILURE",
  
  UPDATE_COACH_CARD_QUALIFICATION_REQUEST: "UPDATE_COACH_CARD_QUALIFICATION_REQUEST",
  UPDATE_COACH_CARD_QUALIFICATION_SUCCESS: "UPDATE_COACH_CARD_QUALIFICATION_SUCCESS",
  UPDATE_COACH_CARD_QUALIFICATION_FAILURE: "UPDATE_COACH_CARD_QUALIFICATION_FAILURE",

  REMOVE_COACH_CARD_QUALIFICATION_REQUEST: "REMOVE_COACH_CARD_QUALIFICATION_REQUEST",
  REMOVE_COACH_CARD_QUALIFICATION_SUCCESS: "REMOVE_COACH_CARD_QUALIFICATION_SUCCESS",
  REMOVE_COACH_CARD_QUALIFICATION_FAILURE: "REMOVE_COACH_CARD_QUALIFICATION_FAILURE",

  GET_COACH_CARD_QUALIFICATION_REQUEST: "GET_COACH_CARD_QUALIFICATION_REQUEST",
  GET_COACH_CARD_QUALIFICATION_SUCCESS: "GET_COACH_CARD_QUALIFICATION_SUCCESS",
  GET_COACH_CARD_QUALIFICATION_FAILURE: "GET_COACH_CARD_QUALIFICATION_FAILURE",

  TRAINER_FEEDBACK_REQUEST: "TRAINER_FEEDBACK_REQUEST",
  TRAINER_FEEDBACK_SUCCESS: "TRAINER_FEEDBACK_SUCCESS",
  TRAINER_FEEDBACK_FAILURE: "TRAINER_FEEDBACK_FAILURE",

  GET_DAYS_ROUTINE_FOR_USER_BY_TRAINER_REQUEST:
    "GET_DAYS_ROUTINE_FOR_USER_BY_TRAINER_REQUEST",
  GET_DAYS_ROUTINE_FOR_USER_BY_TRAINER_SUCCESS:
    "GET_DAYS_ROUTINE_FOR_USER_BY_TRAINER_SUCCESS",
  GET_DAYS_ROUTINE_FOR_USER_BY_TRAINER_FAILURE:
    "GET_DAYS_ROUTINE_FOR_USER_BY_TRAINER_FAILURE",
  GET_DAYS_ROUTINE_FOR_USER_BY_TRAINER_LOADING_START:
    "GET_DAYS_ROUTINE_FOR_USER_BY_TRAINER_LOADING_START",
  GET_DAYS_ROUTINE_FOR_USER_BY_TRAINER_LOADING_STOP:
    "GET_DAYS_ROUTINE_FOR_USER_BY_TRAINER_LOADING_STOP",

  UPDATE_DAYS_ROUTINE_FOR_USER_BY_TRAINER_REQUEST:
    "UPDATE_DAYS_ROUTINE_FOR_USER_BY_TRAINER_REQUEST",
  UPDATE_DAYS_ROUTINE_FOR_USER_BY_TRAINER_SUCCESS:
    "UPDATE_DAYS_ROUTINE_FOR_USER_BY_TRAINER_SUCCESS",
  UPDATE_DAYS_ROUTINE_FOR_USER_BY_TRAINER_FAILURE:
    "UPDATE_DAYS_ROUTINE_FOR_USER_BY_TRAINER_FAILURE",
  UPDATE_DAYS_ROUTINE_FOR_USER_BY_TRAINER_LOADING_START:
    "UPDATE_DAYS_ROUTINE_FOR_USER_BY_TRAINER_LOADING_START",
  UPDATE_DAYS_ROUTINE_FOR_USER_BY_TRAINER_LOADING_STOP:
    "UPDATE_DAYS_ROUTINE_FOR_USER_BY_TRAINER_LOADING_STOP",

  ADD_DAY_ROUTINE_FOR_USER_BY_TRAINER_REQUEST:
    "ADD_DAY_ROUTINE_FOR_USER_BY_TRAINER_REQUEST",
  ADD_DAY_ROUTINE_FOR_USER_BY_TRAINER_SUCCESS:
    "ADD_DAY_ROUTINE_FOR_USER_BY_TRAINER_SUCCESS",
  ADD_DAY_ROUTINE_FOR_USER_BY_TRAINER_FAILURE:
    "ADD_DAY_ROUTINE_FOR_USER_BY_TRAINER_FAILURE",
  ADD_DAYS_ROUTINE_FOR_USER_BY_TRAINER_LOADING_START:
    "ADD_DAYS_ROUTINE_FOR_USER_BY_TRAINER_LOADING_START",
  ADD_DAYS_ROUTINE_FOR_USER_BY_TRAINER_LOADING_STOP:
    "ADD_DAYS_ROUTINE_FOR_USER_BY_TRAINER_LOADING_STOP",

  DELETE_DAY_ROUTINE_FOR_USER_BY_TRAINER_REQUEST:
    "DELETE_DAY_ROUTINE_FOR_USER_BY_TRAINER_REQUEST",
  DELETE_DAY_ROUTINE_FOR_USER_BY_TRAINER_SUCCESS:
    "DELETE_DAY_ROUTINE_FOR_USER_BY_TRAINER_SUCCESS",
  DELETE_DAY_ROUTINE_FOR_USER_BY_TRAINER_FAILURE:
    "DELETE_DAY_ROUTINE_FOR_USER_BY_TRAINER_FAILURE",

  GET_WEIGHT_GRAPH_DETAILS_REQUEST: "GET_WEIGHT_GRAPH_DETAILS_REQUEST",
  GET_WEIGHT_GRAPH_DETAILS_SUCCESS: "GET_WEIGHT_GRAPH_DETAILS_SUCCESS",
  GET_WEIGHT_GRAPH_DETAILS_FAILURE: "GET_WEIGHT_GRAPH_DETAILS_FAILURE",

  SIGN_OUT_REQUEST: "SIGN_OUT_REQUEST",
  SIGN_OUT_SUCCESS: "SIGN_OUT_SUCCESS",
  SIGN_OUT_FAILURE: "SIGN_OUT_FAILURE",

  REGISTRATION_PART1_REQUEST: "REGISTRATION_PART1_REQUEST",
  REGISTRATION_PART1_SUCCESS: "REGISTRATION_PART1_SUCCESS",
  REGISTRATION_PART1_FAILURE: "REGISTRATION_PART1_FAILURE",
  REGISTRATION_OVERRIDE_EMAIL: "REGISTRATION_OVERRIDE_EMAIL",

  CREATE_USER_ANAMNESE_REQUEST: "CREATE_USER_ANAMNESE_REQUEST",
  CREATE_USER_ANAMNESE_SUCCESS: "CREATE_USER_ANAMNESE_SUCCESS",
  CREATE_USER_ANAMNESE_FAILURE: "CREATE_USER_ANAMNESE_FAILURE",

  GET_USER_ANAMNESE_REQUEST: "GET_USER_ANAMNESE_REQUEST",
  GET_USER_ANAMNESE_SUCCESS: "GET_USER_ANAMNESE_SUCCESS",
  GET_USER_ANAMNESE_FAILURE: "GET_USER_ANAMNESE_FAILURE",

  GET_TRAINER_PROFILE_REQUEST: "GET_TRAINER_PROFILE_REQUEST",
  GET_TRAINER_PROFILE_SUCCESS: "GET_TRAINER_PROFILE_SUCCESS",
  GET_TRAINER_PROFILE_FAILURE: "GET_TRAINER_PROFILE_FAILURE",

  GET_INFLUENCERS_REQUEST: "GET_INFLUENCERS_REQUEST",
  GET_INFLUENCERS_SUCCESS: "GET_INFLUENCERS_SUCCESS",
  GET_INFLUENCERS_FAILURE: "GET_INFLUENCERS_FAILURE",

  GET_INFLUENCERS_LOADING_START: "GET_INFLUENCERS_LOADING_START",
  GET_INFLUENCERS_LOADING_STOP: "GET_INFLUENCERS_LOADING_STOP",

  GET_INFLUENCER_DETAILS_REQUEST: "GET_INFLUENCER_DETAILS_REQUEST",
  GET_INFLUENCER_DETAILS_SUCCESS: "GET_INFLUENCER_DETAILS_SUCCESS",
  GET_INFLUENCER_DETAILS_FAILURE: "GET_INFLUENCER_DETAILS_FAILURE",

  GET_INFLUENCER_DETAILS_LOADING_START: "GET_INFLUENCER_DETAILS_LOADING_START",
  GET_INFLUENCER_DETAILS_LOADING_STOP: "GET_INFLUENCER_DETAILS_LOADING_STOP",

  GET_FIRST_MEETING_URL_BY_TRAINER_ID_REQUEST:
    "GET_FIRST_MEETING_URL_BY_TRAINER_ID_REQUEST",
  GET_FIRST_MEETING_URL_BY_TRAINER_ID_SUCCESS:
    "GET_FIRST_MEETING_URL_BY_TRAINER_ID_SUCCESS",
  GET_FIRST_MEETING_URL_BY_TRAINER_ID_FAILURE:
    "GET_FIRST_MEETING_URL_BY_TRAINER_ID_FAILURE",

  GET_FIRST_MEETING_URL_BY_TRAINER_ID_LOADING_START:
    "GET_FIRST_MEETING_URL_BY_TRAINER_ID_LOADING_START",
  GET_FIRST_MEETING_URL_BY_TRAINER_ID_LOADING_STOP:
    "GET_FIRST_MEETING_URL_BY_TRAINER_ID_LOADING_STOP",

  GET_WEEKLY_CALLS_FOR_SUGGESTIONS_REQUEST:
    "GET_WEEKLY_CALLS_FOR_SUGGESTIONS_REQUEST",
  GET_WEEKLY_CALLS_FOR_SUGGESTIONS_SUCCESS:
    "GET_WEEKLY_CALLS_FOR_SUGGESTIONS_SUCCESS",
  GET_WEEKLY_CALLS_FOR_SUGGESTIONS_FAILURE:
    "GET_WEEKLY_CALLS_FOR_SUGGESTIONS_FAILURE",

  GET_WEEKLY_CALLS_FOR_SUGGESTIONS_LOADING_START:
    "GET_WEEKLY_CALLS_FOR_SUGGESTIONS_LOADING_START",
  GET_WEEKLY_CALLS_FOR_SUGGESTIONS_LOADING_STOP:
    "GET_WEEKLY_CALLS_FOR_SUGGESTIONS_LOADING_STOP",

  UPDATE_CONNECTED_ACCOUNT: "UPDATE_CONNECTED_ACCOUNT",
  UPDATE_CONNECTED_ACCOUNT_SUCCESS: "UPDATE_CONNECTED_ACCOUNT_SUCCESS",
  UPDATE_CONNECTED_ACCOUNT_CLEAR: "UPDATE_CONNECTED_ACCOUNT_CLEAR",

  UPDATE_USER_CONNECTED_ACCOUNT: "UPDATE_USER_CONNECTED_ACCOUNT",
  UPDATE_USER_CONNECTED_ACCOUNT_SUCCESS:
    "UPDATE_USER_CONNECTED_ACCOUNT_SUCCESS",
  UPDATE_USER_CONNECTED_ACCOUNT_CLEAR: "UPDATE_USER_CONNECTED_ACCOUNT_CLEAR",

  // Academy

  //   Categories
  GET_LESSONS_CATEGORIES_LOADING_START: "GET_LESSONS_CATEGORIES_LOADING_START",
  GET_LESSONS_CATEGORIES_LOADING_STOP: "GET_LESSONS_CATEGORIES_LOADING_STOP",

  GET_LESSONS_CATEGORIES_REQUEST: "GET_LESSONS_CATEGORIES_REQUEST",
  GET_LESSONS_CATEGORIES_SUCCESS: "GET_LESSONS_CATEGORIES_SUCCESS",
  GET_LESSONS_CATEGORIES_FAILURE: "GET_LESSONS_CATEGORIES_FAILURE",

  // Lessons
  GET_LESSONS_BY_CATEGORY_ID_LOADING_START:
    "GET_LESSONS_BY_CATEGORY_ID_LOADING_START",
  GET_LESSONS_BY_CATEGORY_ID_LOADING_STOP:
    "GET_LESSONS_BY_CATEGORY_ID_LOADING_STOP",

  GET_LESSONS_BY_CATEGORY_ID_REQUEST: "GET_LESSONS_BY_CATEGORY_ID_REQUEST",
  GET_LESSONS_BY_CATEGORY_ID_SUCCESS: "GET_LESSONS_BY_CATEGORY_ID_SUCCESS",
  GET_LESSONS_BY_CATEGORY_ID_FAILURE: "GET_LESSONS_BY_CATEGORY_ID_FAILURE",

  // Lesson Details
  GET_LESSON_DETAILS_LOADING_START: "GET_LESSON_DETAILS_LOADING_START",
  GET_LESSON_DETAILS_LOADING_STOP: "GET_LESSON_DETAILS_LOADING_STOP",

  GET_LESSON_DETAILS_REQUEST: "GET_LESSON_DETAILS_REQUEST",
  GET_LESSON_DETAILS_SUCCESS: "GET_LESSON_DETAILS_SUCCESS",
  GET_LESSON_DETAILS_FAILURE: "GET_LESSON_DETAILS_FAILURE",

  // Lesson Downloads
  GET_DOWNLOADABLE_LESSON_LOADING_START:
    "GET_DOWNLOADABLE_LESSON_LOADING_START",
  GET_DOWNLOADABLE_LESSON_LOADING_STOP: "GET_DOWNLOADABLE_LESSON_LOADING_STOP",

  GET_DOWNLOADABLE_LESSON_REQUEST: "GET_DOWNLOADABLE_LESSON_REQUEST",
  GET_DOWNLOADABLE_LESSON_SUCCESS: "GET_DOWNLOADABLE_LESSON_SUCCESS",
  GET_DOWNLOADABLE_LESSON_FAILURE: "GET_DOWNLOADABLE_LESSON_FAILURE",

  // Social Media

  GET_ALL_INSTAGRAM_CATEGORIES_LOADING_START:
    "GET_ALL_INSTAGRAM_CATEGORIES_LOADING_START",
  GET_ALL_INSTAGRAM_CATEGORIES_LOADING_STOP:
    "GET_ALL_INSTAGRAM_CATEGORIES_LOADING_STOP",

  GET_ALL_INSTAGRAM_CATEGORIES_REQUEST: "GET_ALL_INSTAGRAM_CATEGORIES_REQUEST",
  GET_ALL_INSTAGRAM_CATEGORIES_SUCCESS: "GET_ALL_INSTAGRAM_CATEGORIES_SUCCESS",
  GET_ALL_INSTAGRAM_CATEGORIES_FAILURE: "GET_ALL_INSTAGRAM_CATEGORIES_FAILURE",

  GET_ALL_INSTAGRAM_POSTS_LOADING_START:
    "GET_ALL_INSTAGRAM_POSTS_LOADING_START",
  GET_ALL_INSTAGRAM_POSTS_LOADING_STOP: "GET_ALL_INSTAGRAM_POSTS_LOADING_STOP",

  GET_ALL_INSTAGRAM_POSTS_REQUEST: "GET_ALL_INSTAGRAM_POSTS_REQUEST",
  GET_ALL_INSTAGRAM_POSTS_SUCCESS: "GET_ALL_INSTAGRAM_POSTS_SUCCESS",
  GET_ALL_INSTAGRAM_POSTS_FAILURE: "GET_ALL_INSTAGRAM_POSTS_FAILURE",

  // -----------

  GET_ALL_INSTAGRAM_POSTS_BY_CATEGORIES_LOADING_START:
    "GET_ALL_INSTAGRAM_POSTS_BY_CATEGORIES_LOADING_START",
  GET_ALL_INSTAGRAM_POSTS_BY_CATEGORIES_LOADING_STOP:
    "GET_ALL_INSTAGRAM_POSTS_BY_CATEGORIES_LOADING_STOP",

  GET_ALL_INSTAGRAM_POSTS_BY_CATEGORIES_REQUEST:
    "GET_ALL_INSTAGRAM_POSTS_BY_CATEGORIES_REQUEST",
  GET_ALL_INSTAGRAM_POSTS_BY_CATEGORIES_SUCCESS:
    "GET_ALL_INSTAGRAM_POSTS_BY_CATEGORIES_SUCCESS",
  GET_ALL_INSTAGRAM_POSTS_BY_CATEGORIES_FAILURE:
    "GET_ALL_INSTAGRAM_POSTS_BY_CATEGORIES_FAILURE",

  GET_SUB_CATEGORIES_BY_PARENT_CATEGORY_LOADING_START:
    "GET_SUB_CATEGORIES_BY_PARENT_CATEGORY_LOADING_START",
  GET_SUB_CATEGORIES_BY_PARENT_CATEGORY_LOADING_STOP:
    "GET_SUB_CATEGORIES_BY_PARENT_CATEGORY_LOADING_STOP",

  GET_SUB_CATEGORIES_BY_PARENT_CATEGORY_REQUEST:
    "GET_SUB_CATEGORIES_BY_PARENT_CATEGORY_REQUEST",
  GET_SUB_CATEGORIES_BY_PARENT_CATEGORY_SUCCESS:
    "GET_SUB_CATEGORIES_BY_PARENT_CATEGORY_SUCCESS",
  GET_SUB_CATEGORIES_BY_PARENT_CATEGORY_FAILURE:
    "GET_SUB_CATEGORIES_BY_PARENT_CATEGORY_FAILURE",

  GET_ADDRESS_DETAILS_LOADING_START: "GET_ADDRESS_DETAILS_LOADING_START",
  GET_ADDRESS_DETAILS_LOADING_STOP: "GET_ADDRESS_DETAILS_LOADING_STOP",

  GET_ADDRESS_DETAILS_REQUEST: "GET_ADDRESS_DETAILS_REQUEST",
  GET_ADDRESS_DETAILS_SUCCESS: "GET_ADDRESS_DETAILS_SUCCESS",
  GET_ADDRESS_DETAILS_FAILURE: "GET_ADDRESS_DETAILS_FAILURE",

  UPDATE_ADDRESS_DETAILS_LOADING_START: "UPDATE_ADDRESS_DETAILS_LOADING_START",
  UPDATE_ADDRESS_DETAILS_LOADING_STOP: "UPDATE_ADDRESS_DETAILS_LOADING_STOP",

  UPDATE_ADDRESS_DETAILS_REQUEST: "UPDATE_ADDRESS_DETAILS_REQUEST",
  UPDATE_ADDRESS_DETAILS_SUCCESS: "UPDATE_ADDRESS_DETAILS_SUCCESS",
  UPDATE_ADDRESS_DETAILS_FAILURE: "UPDATE_ADDRESS_DETAILS_FAILURE",
};

export default AuthTypes;
