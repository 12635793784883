import React, {useEffect} from 'react';
import * as S from "./nutritionDone.styles";
import TranslationMessage from 'configs/translations/TranslationMessage';

// Components
import Topbar from 'components/topbar/Topbar';

const NutritionDone = () => {
    useEffect(() => {
        document.body.style.overflowY = "hidden";
    }, [])

    const onNext = () => {
        document.body.style.overflowY = "auto";
    }
    return (
        <div>
            <Topbar prev='/nutrition/macroNutrients' next='/healthScore/investigation' onNext={onNext} redirect />
            <S.NutritionFormDoneContainer>
                <S.NutritionFormDoneHeading><TranslationMessage id="nutrition.formDone.heading" /></S.NutritionFormDoneHeading>
                <S.NutritionFormDoneSubHeading><TranslationMessage id="nutrition.formDone.subHeading" /></S.NutritionFormDoneSubHeading>
                <S.NutritionFormDoneDescription>
                    <TranslationMessage id="nutrition.formDone.description.p1" /> <br/>
                    <TranslationMessage id="nutrition.formDone.description.p2" />
                </S.NutritionFormDoneDescription>
                <S.NutritionFormDoneSVG />
            </S.NutritionFormDoneContainer>
        </div>
    )
}

export default NutritionDone
