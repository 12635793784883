import styled from "styled-components";

export const InputContainer = styled.span`
  display: flex;
  flex-direction: row;
`;

export const CustomInput = styled.input`
  background-color: ${(props) => (props.filled ? "#F6F8FA" : "white")};
  color: #2c2c2c;
  /* -webkit-text-fill-color: #a5b5af; */
  font-size: 0.875rem;
  border: ${(props) =>
    props.filled ? "none" : "rgba(165, 181, 175, .15) 2px solid"};
  width: 100%;
  padding: ${(props) => (props.filled ? "11px 15px" : "11px 15px")};
  box-shadow: 0px 1px 2px #e4e5e73d;
  border: 1px solid #e2e4e9;
  border-radius: 10px;
  outline: none;
  margin: 0 auto;

  ::focus {
    outline: none;
  }

  ::placeholder {
    opacity: 0.5;
  }

  &.input-v2 {
    box-shadow: none;
    border: 1px solid #e2e4e9;
    border-radius: 15px;
    color: #142c1f;
    padding: "15px";
    font-size: 1rem;

    &::focus {
      box-shadow: 0px 1px 2px #142c1f;
      border-color: #142c1f !important;
    }

    &::placeholder {
      font-weight: 300;
      color: #868c98 !important;
    }
  }
`;

export const InputWarningImage = styled.img`
  width: 12px;
  margin: 0 12.5px 0 -25px;
`;
