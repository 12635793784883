import styled from "styled-components";
import { RegistrationContainer, RegistrationHeading, RegistrationSubHeading } from 'pages/registration/registration.styles';


export const HealthScoreEvaluationFormContainer = styled(RegistrationContainer)``;

export const HealthScoreEvaluationFormHeading = styled(RegistrationHeading)``;

export const HealthScoreEvaluationFormSubHeading = styled(RegistrationSubHeading)``;

export const HealthScoreEvaluationInnerContainer = styled.div`
    width: 500px;
    display: flex;
    flex-direction: row;
    margin: 25px auto 36px;
`;

export const HealthScoreDonutChartContainer = styled.div`
    width: 208px;
    height: 208px;
    border-radius: 50%;
    background-color: white;
    position: relative;
`;

export const HealthScoreLineChartContainer = styled.div`
    margin: -20px 0 0 46px;
    text-align: left;
`;

export const HealthScoreLineChartLabel = styled.div`
    margin: 28px 0 10px 0;
    font-family: RubikRegular;
    font-size: 16px;
    color: #142C1F;
`;

export const HealthScoreSliderContainer = styled.div`
    background-color: white;
    border-radius: 10px;
    width: 800px;
    height: 123px;
    margin: 0 auto;
`;

export const HealthScoreSliderInnerContainer = styled.div`
    margin: 34px auto 0;
`;