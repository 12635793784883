import { takeLatest, put, all, call } from "redux-saga/effects";

import ApiCollections from "configs/services/apiCollections";

import DashboardActionTypes from "./dashboard.types";
import * as DashboardActions from "./dashboard.actions";

/*************************************************/

/*************************************************/

export function* getAllLinkedinCategories({ payload }) {
  yield put(DashboardActions.getAllLinkedinCategoriesLoadingStart());
  try {
    const response = yield call(ApiCollections.getAllLinkedinCategories);
    yield put(DashboardActions.getAllLinkedinCategoriesSuccess(response));
    payload?.callback(response);
    yield put(DashboardActions.getAllLinkedinCategoriesLoadingStop());
  } catch (error) {
    yield put(DashboardActions.getAllLinkedinCategoriesFailure(error));
    yield put(DashboardActions.getAllLinkedinCategoriesLoadingStop());
  }
}

/*************************************************/

export function* getAllLinkedinPosts({ payload }) {
  yield put(DashboardActions.getAllLinkedinPostsLoadingStart());
  try {
    const response = yield call(ApiCollections.getAllLinkedinPosts, payload);
    yield put(DashboardActions.getAllLinkedinPostsSuccess(response));
    payload?.callback(response);
    yield put(DashboardActions.getAllLinkedinPostsLoadingStop());
  } catch (error) {
    yield put(DashboardActions.getAllLinkedinPostsFailure(error));
    yield put(DashboardActions.getAllLinkedinPostsLoadingStop());
  }
}

/*************************************************/

export function* getAllLinkedinPostsByCategories({ payload }) {
  yield put(DashboardActions.getAllLinkedinPostsByCategoriesLoadingStart());
  try {
    const response = yield call(
      ApiCollections.getAllLinkedinPostsByCategories,
      payload
    );
    yield put(
      DashboardActions.getAllLinkedinPostsByCategoriesSuccess(response)
    );
    payload?.callback(response);
    yield put(DashboardActions.getAllLinkedinPostsByCategoriesLoadingStop());
  } catch (error) {
    yield put(DashboardActions.getAllLinkedinPostsByCategoriesFailure(error));
    yield put(DashboardActions.getAllLinkedinPostsByCategoriesLoadingStop());
  }
}

/*************************************************/

export function* getLinkedinSubCategoriesByCategory({ payload }) {
  yield put(DashboardActions.getLinkedinSubCategoriesByCategoryLoadingStart());
  try {
    const response = yield call(
      ApiCollections.getLinkedinSubCategoriesByCategory,
      payload?.payload
    );
    yield put(
      DashboardActions.getLinkedinSubCategoriesByCategorySuccess(response)
    );
    payload?.callback(response);
    yield put(DashboardActions.getLinkedinSubCategoriesByCategoryLoadingStop());
  } catch (error) {
    yield put(
      DashboardActions.getLinkedinSubCategoriesByCategoryFailure(error)
    );
    yield put(DashboardActions.getLinkedinSubCategoriesByCategoryLoadingStop());
  }
}

/*************************************************/

export function* getAllWhitePaperData({ payload }) {
  yield put(DashboardActions.getAllWhitePaperDataLoadingStart());
  try {
    const response = yield call(
      ApiCollections.getAllWhitePaperData,
      payload?.payload
    );
    yield put(DashboardActions.getAllWhitePaperDataSuccess(response));
    payload?.callback(response);
    yield put(DashboardActions.getAllWhitePaperDataLoadingStop());
  } catch (error) {
    yield put(DashboardActions.getAllWhitePaperDataFailure(error));
    yield put(DashboardActions.getAllWhitePaperDataLoadingStop());
  }
}

/*************************************************/

export function* getTrainerProfileTempDetails({ payload }) {
  yield put(DashboardActions.getTrainerProfileTempDetailsLoadingStart());
  try {
    const response = yield call(
      ApiCollections.getTrainerProfileTempDetails,
      payload?.payload
    );
    yield put(DashboardActions.getTrainerProfileTempDetailsSuccess(response));
    payload?.callback(response);
    yield put(DashboardActions.getTrainerProfileTempDetailsLoadingStop());
  } catch (error) {
    yield put(DashboardActions.getTrainerProfileTempDetailsFailure(error));
    yield put(DashboardActions.getTrainerProfileTempDetailsLoadingStop());
  }
}

/*************************************************/

export function* getTrainerOnBoardingChecks({ payload }) {
  yield put(DashboardActions.getTrainerOnBoardingChecksLoadingStart());
  try {
    const response = yield call(
      ApiCollections.getTrainerOnBoardingChecks,
      payload?.payload
    );
    yield put(DashboardActions.getTrainerOnBoardingChecksSuccess(response));
    payload?.callback(response);
    yield put(DashboardActions.getTrainerOnBoardingChecksLoadingStop());
  } catch (error) {
    yield put(DashboardActions.getTrainerOnBoardingChecksFailure(error));
    yield put(DashboardActions.getTrainerOnBoardingChecksLoadingStop());
  }
}

/*************************************************/

export function* updateTrainerOnBoardingCheck({ payload }) {
  yield put(DashboardActions.updateTrainerOnBoardingCheckLoadingStart());
  try {
    const response = yield call(
      ApiCollections.updateTrainerOnBoardingCheck,
      payload?.payload
    );
    yield put(DashboardActions.updateTrainerOnBoardingCheckSuccess(response));
    payload?.callback(response);
    yield put(DashboardActions.updateTrainerOnBoardingCheckLoadingStop());
  } catch (error) {
    yield put(DashboardActions.updateTrainerOnBoardingCheckFailure(error));
    yield put(DashboardActions.updateTrainerOnBoardingCheckLoadingStop());
  }
}

/*************************************************/

export function* getActiveClientsLastDays({ payload }) {
  yield put(DashboardActions.getActiveClientsLastDaysLoadingStart());
  try {
    const response = yield call(
      ApiCollections.getActiveClientsLastDays,
      payload?.payload
    );
    yield put(DashboardActions.getActiveClientsLastDaysSuccess(response));
    payload?.callback(response);
    yield put(DashboardActions.getActiveClientsLastDaysLoadingStop());
  } catch (error) {
    yield put(DashboardActions.getActiveClientsLastDaysFailure(error));
    yield put(DashboardActions.getActiveClientsLastDaysLoadingStop());
  }
}

/*************************************************/

export function* getInitialDiscussionChartData({ payload }) {
  yield put(DashboardActions.getInitialDiscussionChartDataLoadingStart());
  try {
    const response = yield call(
      ApiCollections.getInitialDiscussionChartData,
      payload?.payload
    );
    yield put(DashboardActions.getInitialDiscussionChartDataSuccess(response));
    payload?.callback(response);
    yield put(DashboardActions.getInitialDiscussionChartDataLoadingStop());
  } catch (error) {
    yield put(DashboardActions.getInitialDiscussionChartDataFailure(error));
    yield put(DashboardActions.getInitialDiscussionChartDataLoadingStop());
  }
}

export function* getInitialDiscussionChartDataPreviousMonth({ payload }) {
  yield put(DashboardActions.getInitialDiscussionChartDataLoadingStart());
  try {
    const response = yield call(
      ApiCollections.getInitialDiscussionChartData,
      payload?.payload
    );
    yield put(
      DashboardActions.getInitialDiscussionChartDataPreviousMonthSuccess(
        response
      )
    );
    payload?.callback(response);
    yield put(DashboardActions.getInitialDiscussionChartDataLoadingStop());
  } catch (error) {
    yield put(
      DashboardActions.getInitialDiscussionChartDataPreviousMonthFailure(error)
    );
    yield put(DashboardActions.getInitialDiscussionChartDataLoadingStop());
  }
}

export function *getClientsCurrentMonthChartData ({ payload }) {
  try {
    const response = yield call( ApiCollections.getActiveClientsChartData, payload?.payload )
    payload?.callback(response)
  } catch (error) {}
}

/*************************************************/

export function* getActiveClientsCurrentMonthChartData({ payload }) {
  yield put(DashboardActions.getActiveClientsChartDataLoadingStart());
  try {
    const response = yield call(
      ApiCollections.getActiveClientsChartData,
      payload?.payload
    );
    yield put(
      DashboardActions.getActiveClientsCurrentMonthChartDataSuccess(response)
    );
    payload?.callback(response);
    yield put(DashboardActions.getActiveClientsChartDataLoadingStop());
  } catch (error) {
    yield put(
      DashboardActions.getActiveClientsCurrentMonthChartDataFailure(error)
    );
    yield put(DashboardActions.getActiveClientsChartDataLoadingStop());
  }
}

export function* getActiveClientsPreviousMonthChartData({ payload }) {
  yield put(DashboardActions.getActiveClientsChartDataLoadingStart());
  try {
    const response = yield call(
      ApiCollections.getActiveClientsChartData,
      payload?.payload
    );
    yield put(
      DashboardActions.getActiveClientsPreviousMonthChartDataSuccess(response)
    );
    payload?.callback(response);
    yield put(DashboardActions.getActiveClientsChartDataLoadingStop());
  } catch (error) {
    yield put(
      DashboardActions.getActiveClientsPreviousMonthChartDataFailure(error)
    );
    yield put(DashboardActions.getActiveClientsChartDataLoadingStop());
  }
}

/*************************************************/

export function* getSalesCurrentMonthChartData({ payload }) {
  yield put(DashboardActions.getSalesChartDataLoadingStart());
  try {
    const response = yield call(
      ApiCollections.getSalesChartData,
      payload?.payload
    );
    yield put(DashboardActions.getSalesCurrentMonthChartDataSuccess(response));
    payload?.callback(response);
    yield put(DashboardActions.getSalesChartDataLoadingStop());
  } catch (error) {
    yield put(DashboardActions.getSalesCurrentMonthChartDataFailure(error));
    yield put(DashboardActions.getSalesChartDataLoadingStop());
  }
}

export function* getSalesPreviousMonthChartData({ payload }) {
  yield put(DashboardActions.getSalesChartDataLoadingStart());
  try {
    const response = yield call(
      ApiCollections.getSalesChartData,
      payload?.payload
    );
    yield put(DashboardActions.getSalesPreviousMonthChartDataSuccess(response));
    payload?.callback(response);
    yield put(DashboardActions.getSalesChartDataLoadingStop());
  } catch (error) {
    yield put(DashboardActions.getSalesPreviousMonthChartDataFailure(error));
    yield put(DashboardActions.getSalesChartDataLoadingStop());
  }
}

/*************************************************/

export function* getUserTransaction({ payload }) {
  yield put(DashboardActions.getUserTransactionLoadingStart());
  try {
    const response = yield call(
      ApiCollections.getUserTransaction,
      payload?.payload
    );
    yield put(DashboardActions.getUserTransactionSuccess(response));
    payload?.callback(response);
    yield put(DashboardActions.getUserTransactionLoadingStop());
  } catch (error) {
    yield put(DashboardActions.getUserTransactionFailure(error));
    yield put(DashboardActions.getUserTransactionLoadingStop());
  }
}

/*************************************************/

export function* getHabbit({ payload }) {
  yield put(DashboardActions.getHabbitLoadingStart());
  try {
    const response = yield call(ApiCollections.getHabbit, payload?.payload);
    yield put(DashboardActions.getHabbitSuccess(response));
    payload?.callback(response);
    yield put(DashboardActions.getHabbitLoadingStop());
  } catch (error) {
    yield put(DashboardActions.getHabbitFailure(error));
    yield put(DashboardActions.getHabbitLoadingStop());
  }
}

/*************************************************/

export function* getJournal({ payload }) {
  yield put(DashboardActions.getJournalLoadingStart());
  try {
    const response = yield call(ApiCollections.getJournal, payload?.payload);
    yield put(DashboardActions.getJournalSuccess(response));
    payload?.callback(response);
    yield put(DashboardActions.getJournalLoadingStop());
  } catch (error) {
    yield put(DashboardActions.getJournalFailure(error));
    yield put(DashboardActions.getJournalLoadingStop());
  }
}

/*************************************************/

export function* getExperts({ payload }) {
  yield put(DashboardActions.getExpertsCallsLoadingStart());
  try {
    const response = yield call(ApiCollections.getExperts);
    yield put(DashboardActions.getExpertsCallsSuccess(response));
    payload?.callback(response);
    yield put(DashboardActions.getExpertsCallsLoadingStop());
  } catch (error) {
    yield put(DashboardActions.getExpertsCallsFailure(error));
    yield put(DashboardActions.getExpertsCallsLoadingStop());
  }
}

/*************************************************/

export function* getUserWeightData({ payload }) {
  yield put(DashboardActions.getUserWeightDataLoadingStart());
  try {
    const response = yield call(
      ApiCollections.getUserWeightData,
      payload?.payload
    );
    yield put(DashboardActions.getUserWeightDataSuccess(response));
    payload?.callback(response);
    yield put(DashboardActions.getUserWeightDataLoadingStop());
  } catch (error) {
    yield put(DashboardActions.getUserWeightDataFailure(error));
    yield put(DashboardActions.getUserWeightDataLoadingStop());
  }
}

/*************************************************/

export function* getTrainerInvoices({ payload }) {
  yield put(DashboardActions.getTrainerInvoicesLoadingStart());
  try {
    const response = yield call(
      ApiCollections.getTrainerInvoices,
      payload?.payload
    );
    yield put(DashboardActions.getTrainerInvoicesSuccess(response));
    payload?.callback(response);
    yield put(DashboardActions.getTrainerInvoicesLoadingStop());
  } catch (error) {
    yield put(DashboardActions.getTrainerInvoicesFailure(error));
    yield put(DashboardActions.getTrainerInvoicesLoadingStop());
  }
}

/*************************************************/

export function* getTrainerCredits({ payload }) {
  yield put(DashboardActions.getTrainerCreditsLoadingStart());
  try {
    const response = yield call(
      ApiCollections.getTrainerCredits,
      payload?.payload
    );
    if (Object.hasOwn(response, "data")) {
      yield put(DashboardActions.getTrainerCreditsSuccess(response));
      payload?.callback(response);
    } else {
      yield put(
        DashboardActions.getTrainerCreditsSuccess({ status: "error", data: [] })
      );
      payload?.callback({ status: "error", data: [] });
    }

    yield put(DashboardActions.getTrainerCreditsLoadingStop());
  } catch (error) {
    payload?.callback({ status: "error", data: [] });
    yield put(
      DashboardActions.getTrainerCreditsFailure({ status: "error", data: [] })
    );
    yield put(DashboardActions.getTrainerCreditsLoadingStop());
  }
}

/*************************************************/

export function* sendEmail({ payload }) {
  yield put(DashboardActions.sendEmailLoadingStart());
  try {
    const response = yield call(ApiCollections.sendEmail, payload?.payload);
    yield put(DashboardActions.sendEmailSuccess(response));
    payload?.callback(response);
    yield put(DashboardActions.sendEmailLoadingStop());
  } catch (error) {
    yield put(DashboardActions.sendEmailFailure(error));
    yield put(DashboardActions.sendEmailLoadingStop());
  }
}

/*************************************************/

export function* getTrainerNews({ payload }) {
  yield put(DashboardActions.getTrainerNewsLoadingStart());
  try {
    const response = yield call(ApiCollections.getTrainerNews);
    yield put(DashboardActions.getTrainerNewsSuccess(response));
    payload?.callback(response);
    yield put(DashboardActions.getTrainerNewsLoadingStop());
  } catch (error) {
    yield put(DashboardActions.getTrainerNewsFailure(error));
    yield put(DashboardActions.getTrainerNewsLoadingStop());
  }
}

/*************************************************/

export function* updateGoalWeight({ payload }) {
  yield put(DashboardActions.updateGoalWeightLoadingStart());
  try {
    const response = yield call(
      ApiCollections.updateGoalWeight,
      payload?.payload
    );
    yield put(DashboardActions.updateGoalWeightSuccess(response));
    payload?.callback(response);
    yield put(DashboardActions.updateGoalWeightLoadingStop());
  } catch (error) {
    yield put(DashboardActions.updateGoalWeightFailure(error));
    yield put(DashboardActions.updateGoalWeightLoadingStop());
  }
}

export function* updateSelectedGoal({ payload }) {
  try {
    const response = yield call(
      ApiCollections.updateSelectedGoal,
      payload?.payload
    )
    payload?.callback(response)
  } catch (error) {
    payload?.callback(null, error)
  }
}

/*************************************************/

export function* checkStripeConnectedAccountStatus({ payload }) {
  yield put(DashboardActions.checkStripeConnectedAccountStatusLoadingStart());
  try {
    const response = yield call(
      ApiCollections.checkStripeConnectedAccountStatus,
      payload?.payload
    );
    yield put(
      DashboardActions.checkStripeConnectedAccountStatusSuccess(response)
    );
    payload?.callback(response);
    yield put(DashboardActions.checkStripeConnectedAccountStatusLoadingStop());
  } catch (error) {
    yield put(DashboardActions.checkStripeConnectedAccountStatusFailure(error));
    yield put(DashboardActions.checkStripeConnectedAccountStatusLoadingStop());
  }
}

/*************************************************/

export function* dashboardSagas() {
  yield all([
    yield takeLatest(
      DashboardActionTypes.GET_LINKEDIN_SUB_CATEGORIES_BY_PARENT_CATEGORY_REQUEST,
      getLinkedinSubCategoriesByCategory
    ),

    yield takeLatest(
      DashboardActionTypes.GET_ALL_LINKEDIN_POSTS_BY_CATEGORIES_REQUEST,
      getAllLinkedinPostsByCategories
    ),

    yield takeLatest(
      DashboardActionTypes.GET_ALL_LINKEDIN_CATEGORIES_REQUEST,
      getAllLinkedinCategories
    ),

    yield takeLatest(
      DashboardActionTypes.GET_ALL_LINKEDIN_POSTS_REQUEST,
      getAllLinkedinPosts
    ),

    yield takeLatest(
      DashboardActionTypes.GET_ALL_WHITE_PAPER_DATA_REQUEST,
      getAllWhitePaperData
    ),

    yield takeLatest(
      DashboardActionTypes.TRAINER_PROFILE_TEMP_DETAILS_REQUEST,
      getTrainerProfileTempDetails
    ),

    yield takeLatest(
      DashboardActionTypes.GET_TRAINER_ONBOARDING_CHECKS_REQUEST,
      getTrainerOnBoardingChecks
    ),

    yield takeLatest(
      DashboardActionTypes.UPDATE_TRAINER_ONBOARDING_CHECK_REQUEST,
      updateTrainerOnBoardingCheck
    ),

    yield takeLatest(
      DashboardActionTypes.GET_ACTIVE_CLIENTS_LAST_DAYS_REQUEST,
      getActiveClientsLastDays
    ),

    yield takeLatest(
      DashboardActionTypes.GET_INITIAL_DISCUSSION_CHART_DATA_REQUEST,
      getInitialDiscussionChartData
    ),

    yield takeLatest(
      DashboardActionTypes.GET_INITIAL_DISCUSSION_CHART_DATA_PREVIOUS_MONTH_REQUEST,
      getInitialDiscussionChartDataPreviousMonth
    ),

    yield takeLatest(
      DashboardActionTypes.GET_ACTIVE_CLIENTS_CURRENT_MONTH_CHART_DATA_REQUEST,
      getActiveClientsCurrentMonthChartData
    ),

    yield takeLatest(
      DashboardActionTypes.GET_CLIENTS_CURRENT_MONTH_CHART_DATA_REQUEST,
      getClientsCurrentMonthChartData
    ),

    yield takeLatest(
      DashboardActionTypes.GET_ACTIVE_CLIENTS_PREVIOUS_MONTH_CHART_DATA_REQUEST,
      getActiveClientsPreviousMonthChartData
    ),

    yield takeLatest(
      DashboardActionTypes.GET_SALES_CURRENT_MONTH_CHART_DATA_REQUEST,
      getSalesCurrentMonthChartData
    ),

    yield takeLatest(
      DashboardActionTypes.GET_SALES_PREVIOUS_MONTH_CHART_DATA_REQUEST,
      getSalesPreviousMonthChartData
    ),

    yield takeLatest(
      DashboardActionTypes.GET_USER_TRANSACTION_REQUEST,
      getUserTransaction
    ),

    yield takeLatest(DashboardActionTypes.GET_HABBIT_REQUEST, getHabbit),

    yield takeLatest(DashboardActionTypes.GET_JOURNAL_REQUEST, getJournal),
    yield takeLatest(
      DashboardActionTypes.GET_EXPERTS_CALLS_REQUEST,
      getExperts
    ),

    yield takeLatest(
      DashboardActionTypes.GET_USER_WEIGHT_DATA_REQUEST,
      getUserWeightData
    ),

    yield takeLatest(
      DashboardActionTypes.GET_TRAINER_INVOICES_REQUEST,
      getTrainerInvoices
    ),

    yield takeLatest(DashboardActionTypes.SEND_EMAIL_REQUEST, sendEmail),
    yield takeLatest(
      DashboardActionTypes.GET_TRAINER_NEWS_REQUEST,
      getTrainerNews
    ),

    yield takeLatest(
      DashboardActionTypes.UPDATE_GOAL_WEIGHT_REQUEST,
      updateGoalWeight
    ),

    yield takeLatest(
      DashboardActionTypes.UPDATE_SELECTED_GOAL_REQUEST,
      updateSelectedGoal
    ),

    yield takeLatest(
      DashboardActionTypes.CHECK_STRIPE_CONNECTED_ACCOUNT_STATUS_REQUEST,
      checkStripeConnectedAccountStatus
    ),

    yield takeLatest(
      DashboardActionTypes.GET_TRAINER_CREDITS_REQUEST,
      getTrainerCredits
    ),
  ]);
}

/*************************************************/
