const DashboardTypes = {
  // Social Media

  GET_ALL_LINKEDIN_CATEGORIES_LOADING_START:
    "GET_ALL_LINKEDIN_CATEGORIES_LOADING_START",
  GET_ALL_LINKEDIN_CATEGORIES_LOADING_STOP:
    "GET_ALL_LINKEDIN_CATEGORIES_LOADING_STOP",

  GET_ALL_LINKEDIN_CATEGORIES_REQUEST: "GET_ALL_LINKEDIN_CATEGORIES_REQUEST",
  GET_ALL_LINKEDIN_CATEGORIES_SUCCESS: "GET_ALL_LINKEDIN_CATEGORIES_SUCCESS",
  GET_ALL_LINKEDIN_CATEGORIES_FAILURE: "GET_ALL_LINKEDIN_CATEGORIES_FAILURE",

  GET_ALL_LINKEDIN_POSTS_LOADING_START: "GET_ALL_LINKEDIN_POSTS_LOADING_START",
  GET_ALL_LINKEDIN_POSTS_LOADING_STOP: "GET_ALL_LINKEDIN_POSTS_LOADING_STOP",

  GET_ALL_LINKEDIN_POSTS_REQUEST: "GET_ALL_LINKEDIN_POSTS_REQUEST",
  GET_ALL_LINKEDIN_POSTS_SUCCESS: "GET_ALL_LINKEDIN_POSTS_SUCCESS",
  GET_ALL_LINKEDIN_POSTS_FAILURE: "GET_ALL_LINKEDIN_POSTS_FAILURE",

  // -----------

  GET_ALL_LINKEDIN_POSTS_BY_CATEGORIES_LOADING_START:
    "GET_ALL_LINKEDIN_POSTS_BY_CATEGORIES_LOADING_START",
  GET_ALL_LINKEDIN_POSTS_BY_CATEGORIES_LOADING_STOP:
    "GET_ALL_LINKEDIN_POSTS_BY_CATEGORIES_LOADING_STOP",

  GET_ALL_LINKEDIN_POSTS_BY_CATEGORIES_REQUEST:
    "GET_ALL_LINKEDIN_POSTS_BY_CATEGORIES_REQUEST",
  GET_ALL_LINKEDIN_POSTS_BY_CATEGORIES_SUCCESS:
    "GET_ALL_LINKEDIN_POSTS_BY_CATEGORIES_SUCCESS",
  GET_ALL_LINKEDIN_POSTS_BY_CATEGORIES_FAILURE:
    "GET_ALL_LINKEDIN_POSTS_BY_CATEGORIES_FAILURE",

  GET_LINKEDIN_SUB_CATEGORIES_BY_PARENT_CATEGORY_LOADING_START:
    "GET_LINKEDIN_SUB_CATEGORIES_BY_PARENT_CATEGORY_LOADING_START",
  GET_LINKEDIN_SUB_CATEGORIES_BY_PARENT_CATEGORY_LOADING_STOP:
    "GET_LINKEDIN_SUB_CATEGORIES_BY_PARENT_CATEGORY_LOADING_STOP",

  GET_LINKEDIN_SUB_CATEGORIES_BY_PARENT_CATEGORY_REQUEST:
    "GET_LINKEDIN_SUB_CATEGORIES_BY_PARENT_CATEGORY_REQUEST",
  GET_LINKEDIN_SUB_CATEGORIES_BY_PARENT_CATEGORY_SUCCESS:
    "GET_LINKEDIN_SUB_CATEGORIES_BY_PARENT_CATEGORY_SUCCESS",
  GET_LINKEDIN_SUB_CATEGORIES_BY_PARENT_CATEGORY_FAILURE:
    "GET_LINKEDIN_SUB_CATEGORIES_BY_PARENT_CATEGORY_FAILURE",

  // White Paper

  GET_ALL_WHITE_PAPER_DATA_LOADING_START:
    "GET_ALL_WHITE_PAPER_DATA_LOADING_START",
  GET_ALL_WHITE_PAPER_DATA_LOADING_STOP:
    "GET_ALL_WHITE_PAPER_DATA_LOADING_STOP",

  GET_ALL_WHITE_PAPER_DATA_REQUEST: "GET_ALL_WHITE_PAPER_DATA_REQUEST",
  GET_ALL_WHITE_PAPER_DATA_SUCCESS: "GET_ALL_WHITE_PAPER_DATA_SUCCESS",
  GET_ALL_WHITE_PAPER_DATA_FAILURE: "GET_ALL_WHITE_PAPER_DATA_FAILURE",

  // Settings
  TRAINER_PROFILE_TEMP_DETAILS_LOADING_START:
    "TRAINER_PROFILE_TEMP_DETAILS_LOADING_START",
  TRAINER_PROFILE_TEMP_DETAILS_LOADING_STOP:
    "TRAINER_PROFILE_TEMP_DETAILS_LOADING_STOP",

  TRAINER_PROFILE_TEMP_DETAILS_REQUEST: "TRAINER_PROFILE_TEMP_DETAILS_REQUEST",
  TRAINER_PROFILE_TEMP_DETAILS_SUCCESS: "TRAINER_PROFILE_TEMP_DETAILS_SUCCESS",
  TRAINER_PROFILE_TEMP_DETAILS_FAILURE: "TRAINER_PROFILE_TEMP_DETAILS_FAILURE",

  // On Boarding Checks

  GET_TRAINER_ONBOARDING_CHECKS_LOADING_START:
    "GET_TRAINER_ONBOARDING_CHECKS_LOADING_START",
  GET_TRAINER_ONBOARDING_CHECKS_LOADING_STOP:
    "GET_TRAINER_ONBOARDING_CHECKS_LOADING_STOP",

  GET_TRAINER_ONBOARDING_CHECKS_REQUEST:
    "GET_TRAINER_ONBOARDING_CHECKS_REQUEST",
  GET_TRAINER_ONBOARDING_CHECKS_SUCCESS:
    "GET_TRAINER_ONBOARDING_CHECKS_SUCCESS",
  GET_TRAINER_ONBOARDING_CHECKS_FAILURE:
    "GET_TRAINER_ONBOARDING_CHECKS_FAILURE",

  UPDATE_TRAINER_ONBOARDING_CHECK_LOADING_START:
    "UPDATE_TRAINER_ONBOARDING_CHECK_LOADING_START",
  UPDATE_TRAINER_ONBOARDING_CHECK_LOADING_STOP:
    "UPDATE_TRAINER_ONBOARDING_CHECK_LOADING_STOP",

  UPDATE_TRAINER_ONBOARDING_CHECK_REQUEST:
    "UPDATE_TRAINER_ONBOARDING_CHECK_REQUEST",
  UPDATE_TRAINER_ONBOARDING_CHECK_SUCCESS:
    "UPDATE_TRAINER_ONBOARDING_CHECK_SUCCESS",
  UPDATE_TRAINER_ONBOARDING_CHECK_FAILURE:
    "UPDATE_TRAINER_ONBOARDING_CHECK_FAILURE",

  GET_ACTIVE_CLIENTS_LAST_DAYS_LOADING_START:
    "GET_ACTIVE_CLIENTS_LAST_DAYS_LOADING_START",
  GET_ACTIVE_CLIENTS_LAST_DAYS_LOADING_STOP:
    "GET_ACTIVE_CLIENTS_LAST_DAYS_LOADING_STOP",

  GET_ACTIVE_CLIENTS_LAST_DAYS_REQUEST: "GET_ACTIVE_CLIENTS_LAST_DAYS_REQUEST",
  GET_ACTIVE_CLIENTS_LAST_DAYS_SUCCESS: "GET_ACTIVE_CLIENTS_LAST_DAYS_SUCCESS",
  GET_ACTIVE_CLIENTS_LAST_DAYS_FAILURE: "GET_ACTIVE_CLIENTS_LAST_DAYS_FAILURE",

  // ----------------------

  GET_INITIAL_DISCUSSION_CHART_DATA_LOADING_START:
    "GET_INITIAL_DISCUSSION_CHART_DATA_LOADING_START",
  GET_INITIAL_DISCUSSION_CHART_DATA_LOADING_STOP:
    "GET_INITIAL_DISCUSSION_CHART_DATA_LOADING_STOP",

  GET_INITIAL_DISCUSSION_CHART_DATA_REQUEST:
    "GET_INITIAL_DISCUSSION_CHART_DATA_REQUEST",
  GET_INITIAL_DISCUSSION_CHART_DATA_SUCCESS:
    "GET_INITIAL_DISCUSSION_CHART_DATA_SUCCESS",
  GET_INITIAL_DISCUSSION_CHART_DATA_FAILURE:
    "GET_INITIAL_DISCUSSION_CHART_DATA_FAILURE",

  GET_INITIAL_DISCUSSION_CHART_DATA_PREVIOUS_MONTH_REQUEST:
    "GET_INITIAL_DISCUSSION_CHART_DATA_PREVIOUS_MONTH_REQUEST",
  GET_INITIAL_DISCUSSION_CHART_DATA_PREVIOUS_MONTH_SUCCESS:
    "GET_INITIAL_DISCUSSION_CHART_DATA_PREVIOUS_MONTH_SUCCESS",
  GET_INITIAL_DISCUSSION_CHART_DATA_PREVIOUS_MONTH_FAILURE:
    "GET_INITIAL_DISCUSSION_CHART_DATA_PREVIOUS_MONTH_FAILURE",

  // ----------------------

  GET_ACTIVE_CLIENTS_MONTH_CHART_DATA_LOADING_START:
    "GET_ACTIVE_CLIENTS_MONTH_CHART_DATA_LOADING_START",
  GET_ACTIVE_CLIENTS_MONTH_CHART_DATA_LOADING_STOP:
    "GET_ACTIVE_CLIENTS_MONTH_CHART_DATA_LOADING_STOP",

  GET_ACTIVE_CLIENTS_CURRENT_MONTH_CHART_DATA_REQUEST:
    "GET_ACTIVE_CLIENTS_CURRENT_MONTH_CHART_DATA_REQUEST",
  GET_CLIENTS_CURRENT_MONTH_CHART_DATA_REQUEST:
    "GET_CLIENTS_CURRENT_MONTH_CHART_DATA_REQUEST",
  GET_ACTIVE_CLIENTS_CURRENT_MONTH_CHART_DATA_SUCCESS:
    "GET_ACTIVE_CLIENTS_CURRENT_MONTH_CHART_DATA_SUCCESS",
  GET_ACTIVE_CLIENTS_CURRENT_MONTH_CHART_DATA_FAILURE:
    "GET_ACTIVE_CLIENTS_CURRENT_MONTH_CHART_DATA_FAILURE",

  GET_ACTIVE_CLIENTS_PREVIOUS_MONTH_CHART_DATA_REQUEST:
    "GET_ACTIVE_CLIENTS_PREVIOUS_MONTH_CHART_DATA_REQUEST",
  GET_ACTIVE_CLIENTS_PREVIOUS_MONTH_CHART_DATA_SUCCESS:
    "GET_ACTIVE_CLIENTS_PREVIOUS_MONTH_CHART_DATA_SUCCESS",
  GET_ACTIVE_CLIENTS_PREVIOUS_MONTH_CHART_DATA_FAILURE:
    "GET_ACTIVE_CLIENTS_PREVIOUS_MONTH_CHART_DATA_FAILURE",

  // ----------------------------------------------

  GET_SALES_MONTH_CHART_DATA_LOADING_START:
    "GET_SALES_MONTH_CHART_DATA_LOADING_START",
  GET_SALES_MONTH_CHART_DATA_LOADING_STOP:
    "GET_SALES_MONTH_CHART_DATA_LOADING_STOP",

  GET_SALES_CURRENT_MONTH_CHART_DATA_REQUEST:
    "GET_SALES_CURRENT_MONTH_CHART_DATA_REQUEST",
  GET_SALES_CURRENT_MONTH_CHART_DATA_SUCCESS:
    "GET_SALES_CURRENT_MONTH_CHART_DATA_SUCCESS",
  GET_SALES_CURRENT_MONTH_CHART_DATA_FAILURE:
    "GET_SALES_CURRENT_MONTH_CHART_DATA_FAILURE",

  GET_SALES_PREVIOUS_MONTH_CHART_DATA_REQUEST:
    "GET_SALES_PREVIOUS_MONTH_CHART_DATA_REQUEST",
  GET_SALES_PREVIOUS_MONTH_CHART_DATA_SUCCESS:
    "GET_SALES_PREVIOUS_MONTH_CHART_DATA_SUCCESS",
  GET_SALES_PREVIOUS_MONTH_CHART_DATA_FAILURE:
    "GET_SALES_PREVIOUS_MONTH_CHART_DATA_FAILURE",

  // ----------------------------------------------

  GET_USER_TRANSACTION_LOADING_START: "GET_USER_TRANSACTION_LOADING_START",
  GET_USER_TRANSACTION_LOADING_STOP: "GET_USER_TRANSACTION_LOADING_STOP",

  GET_USER_TRANSACTION_REQUEST: "GET_USER_TRANSACTION_REQUEST",
  GET_USER_TRANSACTION_SUCCESS: "GET_USER_TRANSACTION_SUCCESS",
  GET_USER_TRANSACTION_FAILURE: "GET_USER_TRANSACTION_FAILURE",

  // ----------------------------------------------

  GET_HABBIT_LOADING_START: "GET_HABBIT_LOADING_START",
  GET_HABBIT_LOADING_STOP: "GET_HABBIT_LOADING_STOP",

  GET_HABBIT_REQUEST: "GET_HABBIT_REQUEST",
  GET_HABBIT_SUCCESS: "GET_HABBIT_SUCCESS",
  GET_HABBIT_FAILURE: "GET_HABBIT_FAILURE",

  // ----------------------------------------------

  GET_JOURNAL_LOADING_START: "GET_JOURNAL_LOADING_START",
  GET_JOURNAL_LOADING_STOP: "GET_JOURNAL_LOADING_STOP",

  GET_JOURNAL_REQUEST: "GET_JOURNAL_REQUEST",
  GET_JOURNAL_SUCCESS: "GET_JOURNAL_SUCCESS",
  GET_JOURNAL_FAILURE: "GET_JOURNAL_FAILURE",

  // -----------------Experts-----------------------------

  GET_EXPERTS_CALLS_LOADING_START: "GET_EXPERTS_CALLS_LOADING_START",
  GET_EXPERTS_CALLS_LOADING_STOP: "GET_EXPERTS_CALLS_LOADING_STOP",

  GET_EXPERTS_CALLS_REQUEST: "GET_EXPERTS_CALLS_REQUEST",
  GET_EXPERTS_CALLS_SUCCESS: "GET_EXPERTS_CALLS_SUCCESS",
  GET_EXPERTS_CALLS_FAILURE: "GET_EXPERTS_CALLS_FAILURE",

  // -----------------User Weight Data-----------------------------

  GET_USER_WEIGHT_DATA_LOADING_START: "GET_USER_WEIGHT_DATA_LOADING_START",
  GET_USER_WEIGHT_DATA_LOADING_STOP: "GET_USER_WEIGHT_DATA_LOADING_STOP",

  GET_USER_WEIGHT_DATA_REQUEST: "GET_USER_WEIGHT_DATA_REQUEST",
  GET_USER_WEIGHT_DATA_SUCCESS: "GET_USER_WEIGHT_DATA_SUCCESS",
  GET_USER_WEIGHT_DATA_FAILURE: "GET_USER_WEIGHT_DATA_FAILURE",

  // -----------------Trainer Invoices-----------------------------

  GET_TRAINER_INVOICES_LOADING_START: "GET_TRAINER_INVOICES_LOADING_START",
  GET_TRAINER_INVOICES_LOADING_STOP: "GET_TRAINER_INVOICES_LOADING_STOP",

  GET_TRAINER_INVOICES_REQUEST: "GET_TRAINER_INVOICES_REQUEST",
  GET_TRAINER_INVOICES_SUCCESS: "GET_TRAINER_INVOICES_SUCCESS",
  GET_TRAINER_INVOICES_FAILURE: "GET_TRAINER_INVOICES_FAILURE",

  // -----------------Trainer Credits-----------------------------

  GET_TRAINER_CREDITS_LOADING_START: "GET_TRAINER_CREDITS_LOADING_START",
  GET_TRAINER_CREDITS_LOADING_STOP: "GET_TRAINER_CREDITS_LOADING_STOP",

  GET_TRAINER_CREDITS_REQUEST: "GET_TRAINER_CREDITS_REQUEST",
  GET_TRAINER_CREDITS_SUCCESS: "GET_TRAINER_CREDITS_SUCCESS",
  GET_TRAINER_CREDITS_FAILURE: "GET_TRAINER_CREDITS_FAILURE",

  // -----------------Email Templates-----------------------------

  SEND_EMAIL_LOADING_START: "SEND_EMAIL_LOADING_START",
  SEND_EMAIL_LOADING_STOP: "SEND_EMAIL_LOADING_STOP",

  SEND_EMAIL_REQUEST: "SEND_EMAIL_REQUEST",
  SEND_EMAIL_SUCCESS: "SEND_EMAIL_SUCCESS",
  SEND_EMAIL_FAILURE: "SEND_EMAIL_FAILURE",
  SEND_EMAIL_NULL: "SEND_EMAIL_NULL",

  GET_TRAINER_NEWS_REQUEST: "GET_TRAINER_NEWS_REQUEST",
  GET_TRAINER_NEWS_SUCCESS: "GET_TRAINER_NEWS_SUCCESS",
  GET_TRAINER_NEWS_FAILURE: "GET_TRAINER_NEWS_FAILURE",
  GET_TRAINER_NEWS_LOADING_START: "GET_TRAINER_NEWS_LOADING_START",
  GET_TRAINER_NEWS_LOADING_STOP: "GET_TRAINER_NEWS_LOADING_STOP",

  UPDATE_GOAL_WEIGHT_REQUEST: "UPDATE_GOAL_WEIGHT_REQUEST",
  UPDATE_GOAL_WEIGHT_SUCCESS: "UPDATE_GOAL_WEIGHT_SUCCESS",
  UPDATE_GOAL_WEIGHT_FAILURE: "UPDATE_GOAL_WEIGHT_FAILURE",
  UPDATE_GOAL_WEIGHT_LOADING_START: "UPDATE_GOAL_WEIGHT_LOADING_START",
  UPDATE_GOAL_WEIGHT_LOADING_STOP: "UPDATE_GOAL_WEIGHT_LOADING_STOP",
  UPDATE_SELECTED_GOAL_REQUEST: "UPDATE_SELECTED_GOAL_REQUEST",

  CHECK_STRIPE_CONNECTED_ACCOUNT_STATUS_REQUEST:
    "CHECK_STRIPE_CONNECTED_ACCOUNT_STATUS_REQUEST",
  CHECK_STRIPE_CONNECTED_ACCOUNT_STATUS_SUCCESS:
    "CHECK_STRIPE_CONNECTED_ACCOUNT_STATUS_SUCCESS",
  CHECK_STRIPE_CONNECTED_ACCOUNT_STATUS_FAILURE:
    "CHECK_STRIPE_CONNECTED_ACCOUNT_STATUS_FAILURE",
  CHECK_STRIPE_CONNECTED_ACCOUNT_STATUS_LOADING_START:
    "CHECK_STRIPE_CONNECTED_ACCOUNT_STATUS_LOADING_START",
  CHECK_STRIPE_CONNECTED_ACCOUNT_STATUS_LOADING_STOP:
    "CHECK_STRIPE_CONNECTED_ACCOUNT_STATUS_LOADING_STOP",
};

export default DashboardTypes;
