import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import * as S from './healthScorePriority.styles';
import { getRandomValueInRange } from 'configs/utils';

// Components
import TranslationMessage from 'configs/translations/TranslationMessage';
import Topbar from 'components/topbar/Topbar';
import Button from 'components/formComponents/button/Button';

// Redux
import * as AuthActions from "redux/auth/auth.actions";

function HealthScorePriority() {
    const dispatch = useDispatch();
    const history = useHistory();
    const healthScoreData = useSelector(state => state.auth.healthScoreData);
    const [button1, setButton1] = useState(healthScoreData.button1);
    const [button2, setButton2] = useState(healthScoreData.button2);
    const [button3, setButton3] = useState(healthScoreData.button3);
    const [button4, setButton4] = useState(healthScoreData.button4);
    const [button5, setButton5] = useState(healthScoreData.button5);
    const [button6, setButton6] = useState(healthScoreData.button6);
    const [button7, setButton7] = useState(healthScoreData.button7);
    const [button8, setButton8] = useState(healthScoreData.button8);
    const [button9, setButton9] = useState(healthScoreData.button9);
    const [button10, setButton10] = useState(healthScoreData.button10);

    const prioritiesButtonData = [
        { id: "button.priority.beautifulFirmerSkin", selected: button1, setSelected: setButton1 },
        { id: "button.priority.moreEnergy.everydayLife", selected: button2, setSelected: setButton2 },
        { id: "button.priority.innerSerenity", selected: button3, setSelected: setButton3 },
        { id: "button.priority.freshAppearance", selected: button4, setSelected: setButton4 },
        { id: "button.priority.healthierHair", selected: button5, setSelected: setButton5 },
        { id: "button.priority.moreRestfulSleep", selected: button6, setSelected: setButton6 },
        { id: "button.priority.moreBeautifulSkin", selected: button7, setSelected: setButton7 },
        { id: "button.priority.noHungerPangs", selected: button8, setSelected: setButton8 },
        { id: "button.priority.noBingeEating", selected: button9, setSelected: setButton9 },
        { id: "button.priority.balancedDiet", selected: button10, setSelected: setButton10 },
    ]

    const prioritiesButtonStatus = { button1, button2, button3, button4, button5, button6, button7, button8, button9, button10, currentHealthScore: getRandomValueInRange(40, 45) };

    const handleNext = () => {
        dispatch(AuthActions.setHealthScoreData(prioritiesButtonStatus))
        history.push('/healthscore/anamnese_evaluations')
        // history.push('/healthscore/evaluation')
    }

    return (
        <>
            {/* percent={0} healthReport */}
            {/* next="/healthscore/evaluation" */}
            <Topbar prev="/healthscore/investigation" next="/healthscore/anamnese_evaluations" onNext={handleNext} progress={'18%'} close onClose={() => history.push('/login')} />
            <S.HealthScorePriorityFormContainer>
                <S.HealthScorePriorityFormHeading><TranslationMessage id="healthScore.priority.heading" /></S.HealthScorePriorityFormHeading>
                <S.HealthScorePriorityFormSubHeading><TranslationMessage id="healthScore.priority.subHeading" /></S.HealthScorePriorityFormSubHeading>
                <S.HealthScorePriorityButtonsContainer>
                    {prioritiesButtonData.map(buttonValues => {
                        return (
                            <Button
                                color={buttonValues.selected ? "#2ECF96" : "#A5B5AF"}
                                outlined={!buttonValues.selected}
                                onClick={() => buttonValues.setSelected(!buttonValues.selected)}
                            >
                                <TranslationMessage id={buttonValues.id} />
                            </Button>
                        )
                    })}
                </S.HealthScorePriorityButtonsContainer>
            </S.HealthScorePriorityFormContainer>
        </>
    )
}

export default HealthScorePriority
