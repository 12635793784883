import styled from 'styled-components';
import {RegistrationContainer ,RegistrationHeading, RegistrationSubHeading} from 'pages/registration/registration.styles';

export const NutritionFormMacroNutrientsContainer = styled(RegistrationContainer)``;

export const NutritionFormMacroNutrientsHeading = styled(RegistrationHeading)``;

export const NutritionFormMacroNutrientsSubHeading = styled(RegistrationSubHeading)``;

export const NutritionFormMacroNutrientsDescription = styled.div`
    margin: 20px auto 40px;
    font-family: RubikRegular;
    font-size: 16px;
    line-height: 25px;
    color: rgba(68, 68, 68, .6);
`;

export const DonutContainer = styled.div`
    width: 225px;
    height: 225px;
    margin: 10px auto 40px;
`;
