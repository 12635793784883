import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { renderValueInGerman } from "configs/utils";
// Components
import Topbar from "components/topbar/Topbar";
import TranslationMessage from "configs/translations/TranslationMessage";
import Card from "components/card/Card";

import * as NutritionActions from "redux/nutrition/nutrition.actions";

import * as S from "./subscriptionPro.styles";

const SubscriptionPro = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const params = new URLSearchParams(window.location.search);
  const param1Value = params.get("user");

  const [DiscountValue, setDiscountValue] = useState(150);

  const allRefunds = useSelector((state) => state.nutrition.getAllRefunds);

  const isAllRefundsLoading = useSelector(
    (state) => state.nutrition.isAllRefundsLoading
  );

  console.log("allRefunds", allRefunds);

  useEffect(() => {
    getAllRefunds();
  }, []);

  const getAllRefunds = () => {
    dispatch(
      NutritionActions.getAllRefundsRequest({
        pyload: null,
        callback: (res) => {},
      })
    );
  };

  const Benefits = [
    "subscription.pro.benefits.1",
    "subscription.pro.benefits.2",
    "subscription.pro.benefits.3",
    "subscription.pro.benefits.4",
    "subscription.pro.benefits.5",
    "subscription.pro.benefits.6",
    "subscription.pro.benefits.7",
    "subscription.pro.benefits.8",
  ];

  const handleSelectCompany = (value) => {
    if (!value) return;
    let v = (+value * 150) / 100;
    setDiscountValue(v);
    localStorage.setItem("discount", v);
  };

  return (
    <>
      {/*  percent={getRandomValueInRange(40, 45)} healthReport onClose={() => history.push('/login')} close  */}
      <Topbar
        prev="/subscriptions"
        next={`/subscription/details/?user=${param1Value}`}
        progress={"73%"}
      />
      <S.SubscriptionContainer>
        <S.SubscriptionHeading>
          <TranslationMessage
            id={
              param1Value == "Pro"
                ? "subscription.pro.page.heading"
                : "subscription.plus.page.heading"
            }
          />
        </S.SubscriptionHeading>
        <S.SubscriptionSubHeading>
          <TranslationMessage
            id={
              param1Value == "Pro"
                ? "subscription.pro.page.subHeading"
                : "subscription.plus.page.subHeading"
            }
          />
        </S.SubscriptionSubHeading>
        <S.SubscriptionCardContainer>
          <Card
            width="724px"
            height="263px"
            margin="20px auto"
            textAlign="left"
            style={{ padding: "24px", position: "relative" }}
          >
            <S.SubscriptionSmallHeading>
              <p className="top_heading">Krankenkasse</p>
            </S.SubscriptionSmallHeading>

            {isAllRefundsLoading && (
              <>
              <br />
              <br />
              <br />
              <br />
              <div className="text-center">
                <div className="loader mx-auto"></div>
              </div>
              <br />
              <br />
              <br />
              <br />
            </>
            )}
            {!isAllRefundsLoading && (
              <>
                <S.SubscriptionProDrop>
                  <select onChange={(e) => handleSelectCompany(e.target.value)}>
                    <option value="">Krankenkasse auswählen</option>
                    {allRefunds?.getAllRefunds.map((company) => (
                      <option value={company?.refund_amount}>
                        {company?.krankenkasse}
                      </option>
                    ))}
                    {/* <option value="50">AOK BaWü</option>
                <option value="75">AOK Bayern</option>
                <option value="80">AOK Bremen</option>
                <option value="100">AOK Hamburg</option>
                <option value="100">AOK Hessen</option>
                <option value="75">AOK Niedersachsen</option>
                <option value="85">AOK Nordost</option>
                <option value="80">AOK Nordwest</option>
                <option value="100">AOK Rheinland</option>
                <option value="50">AOK PLUS</option>
                <option value="80">AOK Sachsen-Anhalt</option>
                <option value="100">actimonda</option>
                <option value="100">AGIDA</option>
                <option value="90">Audi BKK</option>
                <option value="80">Bahn BKK</option>
                <option value="100">Barmer</option>
                <option value="100">Bergische Krankenkasse</option>
                <option value="100">BIG direkt</option>
                <option value="80">Bertelsmann BKK</option>
                <option value="100">Betriebskrankenkasse mobil oil</option>
                <option value="100">BKK 24</option>
                <option value="80">BKK Achenbach</option>
                <option value="90">BKK Akzo Nobel </option>
                <option value="80">BKK Diakonie</option>
                <option value="100">BKK Euregio</option>
                <option value="100">BKK Exklusiv</option>
                <option value="75">BKK Faber</option>
                <option value="90">BKK Firmus</option>
                <option value="100">BKK Freudenberg</option>
                <option value="75">BKK Gildemeister</option>
                <option value="95">BKK Herkules</option>
                <option value="100">BKK Linde</option>
                <option value="100">BKK Melitta hmr</option>
                <option value="80">BKK Pfaff</option>
                <option value="90">BKK Pfalz</option>
                <option value="80">BKK ProVita</option>
                <option value="85">BKK Scheufelen</option>
                <option value="85">BKK Schwarzwald-Baar-Heuberg</option>
                <option value="75">BKK Technoform</option>
                <option value="85">BKK TUI</option>
                <option value="80">BKK VBU</option>
                <option value="75">Bkk VerbundPlus</option>
                <option value="75">BKK Wera-Meissner</option>
                <option value="100">BKK Wirtschaft &amp; Finanzen</option>
                <option value="80">Betriebskrankenkasse WMF</option>
                <option value="80">BKK ZF &amp; Partner</option>
                <option value="80">Bosch BKK</option>
                <option value="100">Continentale Betriebskrankenkasse</option>
                <option value="75">DAK-Gesundheit</option>
                <option value="75">Debeka BKK</option>
                <option value="80">energie-BKK</option>
                <option value="80">Heimat Krankenkasse</option>
                <option value="50">HEK - Hanseatische Krankenkasse</option>
                <option value="80">HKK - Handelskrankenkasse</option>
                <option value="100">IKK</option>
                <option value="75">IKK Brandenburg und Berlin</option>
                <option value="90">IKK classic</option>
                <option value="75">IKK Südwest</option>
                <option value="100">KKH - Kaufmännische Krankenkasse</option>
                <option value="80">Knappschaft</option>
                <option value="70">mhplus Betriebskrankenkasse</option>
                <option value="100">Novitas BKK</option>
                <option value="80">Pronova BKK</option>
                <option value="80">R+V Betriebskrankenkasse</option>
                <option value="80">Salus BKK</option>
                <option value="100">Securvita Krankenkasse</option>
                <option value="90">SKD BKK</option>
                <option value="67">TK - Techniker Krankenkasse</option>
                <option value="100">Viactiv Krankenkasse</option>
                <option value="80">Vivida BKK</option> */}
                  </select>
                </S.SubscriptionProDrop>
                <S.SubscriptionSmallHeading>
                  <p className="price_color">
                    {/* {`${DiscountValue}`.replace(".", ",")},00 &#8364; */}
                    {renderValueInGerman(+DiscountValue)}
                  </p>
                  <p className="note">werden zurückerstattet</p>
                </S.SubscriptionSmallHeading>
              </>
            )}
          </Card>
        </S.SubscriptionCardContainer>
        {/* <S.SubscriptionCardContainer>
                    <Card
                        width="606px"
                        margin="0"
                        textAlign="left"
                        style={{ padding: '24px', position: 'relative' }}
                    >
                        <S.SubscriptionCardBenefitsHeading>
                            <TranslationMessage id={"subscription.pro.left.heading"} />
                        </S.SubscriptionCardBenefitsHeading>
                        <S.SubscriptionCardInnerBenefitsList>
                            {Benefits.map(benefit => {
                                return (
                                    <S.SubscriptionCardInnerBenefitsListItem key={benefit}>
                                        <span><S.SubscriptionCardInnerBenefitsListItemCheck src={GreenCheck} /></span>
                                        <TranslationMessage id={benefit} />
                                    </S.SubscriptionCardInnerBenefitsListItem>
                                )
                            })}
                        </S.SubscriptionCardInnerBenefitsList>
                        <S.SVGIllustration>
                            <img src={NutritionDone} alt="" />
                        </S.SVGIllustration>
                    </Card>
                    <Card
                        width={"288px"}
                        margin="0 10px"
                        textAlign="left"
                    >
                        <S.SubscriptionCardInnerHeadingContainer background={"#142C1F"}>
                            <S.SubscriptionCardInnerHeading>
                                <S.SubscriptionPlanTitle>
                                    <TranslationMessage id={"subscription.pro.planTitle"} />
                                </S.SubscriptionPlanTitle>
                                <S.SubscriptionPlanDuration>
                                    <TranslationMessage id={"subscription.pro.planDuration"} />
                                </S.SubscriptionPlanDuration>
                            </S.SubscriptionCardInnerHeading>
                        </S.SubscriptionCardInnerHeadingContainer>
                        <div style={{ padding: '24px' }}>

                            <S.SubscriptionCardInnerDescription style={{ textAlign: 'left' }}>
                                <TranslationMessage id="subscription.pro.planIncludes" />
                            </S.SubscriptionCardInnerDescription>
                            <S.SubscriptionCardInnerBenefitsList>
                                <S.SubscriptionCardInnerBenefitsListItem style={{ marginLeft: '26px' }}>
                                    <span style={{ marginLeft: '-26px' }}><S.SubscriptionCardInnerBenefitsListItemCheck src={GreenCheck} /></span>
                                    <strong><TranslationMessage id={"subscription.pro.planIncludes.1.heading"} /></strong>
                                    <TranslationMessage id={"subscription.pro.planIncludes.1.value"} />
                                </S.SubscriptionCardInnerBenefitsListItem>
                                <S.SubscriptionCardInnerBenefitsListItem style={{ marginLeft: '26px' }}>
                                    <span style={{ marginLeft: '-26px' }}><S.SubscriptionCardInnerBenefitsListItemCheck src={GreenCheck} /></span>
                                    <strong><TranslationMessage id={"subscription.pro.planIncludes.2.heading"} /></strong>
                                    <TranslationMessage id={"subscription.pro.planIncludes.2.value"} />
                                </S.SubscriptionCardInnerBenefitsListItem>
                            </S.SubscriptionCardInnerBenefitsList>
                            <S.SubscriptionPrice>
                                <strong><TranslationMessage id={"subscription.pro.planPrice"} /></strong>
                                <p><TranslationMessage id={"subscription.pro.planPrice.1"} /></p>
                                <div><TranslationMessage id={"subscription.pro.planPrice.2"} /></div>
                            </S.SubscriptionPrice>
                        </div>
                    </Card>

                </S.SubscriptionCardContainer> */}
      </S.SubscriptionContainer>
      <br />
      <br />
    </>
  );
};

export default SubscriptionPro;
