import styled from "styled-components";
import { DashboardContainer } from "pages/dashboard/dashboard.styles"
import { RegistrationContainer, RegistrationHeading, RegistrationSubHeading, } from 'pages/registration/registration.styles';

export const HealthScoreImprovementContainer = styled(RegistrationContainer)``;

export const HealthScoreImprovementHeading = styled(RegistrationHeading)``;

export const HealthScoreImprovementSubHeading = styled(RegistrationSubHeading)``;

// Health Score Improvement Card styling
export const HealthScoreImprovementCardContainer = styled(DashboardContainer)`
    display: flex;
    flex-direction: row;
    margin-top: 20px;
`;

export const HealthScoreImprovementCardInnerContainer = styled.div`
    padding: 40px 20px 0;
`;

export const HealthScoreCardHeading = styled.div`
    font-family: RubikMedium;
    font-size: 18px;
    margin-bottom: 20px;
    color: #142C1F;
    text-align: center;
`

export const BenefitsContainer = styled.div`
    display: flex;
    margin-bottom: 15px;
`

export const ChecklistElementGreenTickContainer = styled.span`
    margin-right: 15px;
    padding-top: 4px;
`;

export const Benefits = styled.div`
    line-height: 24px;
    font-size: 16px;
    color: rgba(68, 85, 76, .9);
    font-family: RubikRegular;
`;