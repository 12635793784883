import React, {useState} from 'react';
import * as S from "./trainerSignup.styles";
import moment from 'moment';
import { Formik } from "formik";
import { number, object, string } from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getImageUrl } from "configs/utils";

// Components
import TranslationMessage from 'configs/translations/TranslationMessage';
import Topbar from 'components/topbar/Topbar';
import Button from 'components/formComponents/button/Button';
import Input from 'components/formComponents/input/Input';
import Dropdown from 'components/formComponents/dropdown/Dropdown';
import ErrorMessage from 'components/formComponents/errorMessage/ErrorMessage';
import Loader from "components/loader/Loader";
import TextArea from 'components/formComponents/textArea/TextArea';

// Images
import Avatar from "assets/images/avatar.svg";

// Redux
import * as AuthActions from "redux/auth/auth.actions";

const TrainerSignup = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const loading = useSelector(state => state.auth.loading);
    const trainerProfileData = useSelector(state => state.auth.trainerData);
    const [profileImage, setProfileImage] = useState(null);

    const sexOptions = [{id: 'male', value: 'male'}, {id: 'female', value: 'female'}];
    const authenticated = localStorage.getItem('trainer_token');

    if(authenticated) {
        history.push("/")
    }

    const getProfileImage = () => {
        if(trainerProfileData && trainerProfileData.profilePic) return getImageUrl(trainerProfileData.imgpath, trainerProfileData.profilePic, trainerProfileData.imgtype)
        else if(profileImage) return URL.createObjectURL(profileImage)
        else return Avatar
    }

    const initialValues = {
        prename: "",
        surname: "",
        FranchiseID: "",
        email: "",
        sex: "male",
        birthday: "",
        ownerID: "",
        description: '',
        step1: '',
        step2: '',
        step3: '',
        step4: '',
        step5: '',
    };

    const validationSchema = object().shape({
        FranchiseID: string().required("Required"),
        prename: string().typeError('You must enter a String').required("Required"),
        surname: string().typeError('You must enter a String').required("Required"),
        email: string().email().typeError('You must enter a String').required("Required"),
        sex: string().typeError('You must enter a String').required("Required"),
        birthday: string().typeError('You must enter a String').required("Required"),
        ownerID: number().typeError('You must enter a number').required("Required"),
        description: string().required("Required"),
        step1: string().required("Required"),
        step2: string(),
        step3: string(),
        step4: string(),
        step5: string(),
    });

    const onFormSubmit = (values, { resetForm }) => {
        const formData = new FormData();
        formData.append("FranchiseID", values.FranchiseID);
        formData.append("ownerID", values.ownerID);
        formData.append("prename", values.prename);
        formData.append("surname", values.surname);
        formData.append("email", values.email);
        formData.append("sex", values.sex);
        formData.append("description", values.description);
        formData.append("birthday", trainerProfileData.birthday);
        formData.append("step1", values.step1);
        formData.append("step2", values.step2);
        formData.append("step3", values.step3);
        formData.append("step4", values.step4);
        formData.append("step5", values.step5);
        formData.append("profilePic", profileImage);
        formData.append("subtitle", "4343");
        dispatch(AuthActions.trainerSignUpRequest(formData))
        resetForm();
    };

    return (
        <>
            <Topbar />
            <S.TrainerSignupFormContainer>
                <S.TrainerSignupDataContainer>
                    <S.TrainerSignupDataInnerContainer>
                        <S.TrainerSignupFormHeading>
                            <TranslationMessage id="TrainerSignup.heading" />
                        </S.TrainerSignupFormHeading>
                        <S.TrainerSignupFormSubHeading>
                            <TranslationMessage id="TrainerSignup.subHeading" />
                        </S.TrainerSignupFormSubHeading>
                        <S.TrainerSignupProfileImage 
                            bgImage={getProfileImage()} 
                        >
                            <S.TrainerSignupUploadImage onChange={(e) => setProfileImage(e.target.files[0])} />
                        </S.TrainerSignupProfileImage>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={onFormSubmit}
                        >
                            {({handleSubmit, handleChange, handleBlur, values, touched, errors, isValid, dirty,}) => (
                                <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                                    <S.TrainerSignupInputContainer>
                                        <S.TrainerSignupInputWrapper>
                                            <Input style={{ width: '288px', marginBottom: "1em" }} name="prename" placeholder="Prename" onChange={handleChange} value={values.prename} />
                                            <ErrorMessage error={errors.prename} />
                                        </S.TrainerSignupInputWrapper>
                                        <S.TrainerSignupInputWrapper>
                                            <Input style={{ width: '288px', marginBottom: "1em" }} name="surname" placeholder="Surname" onChange={handleChange} value={values.surname} />
                                            <ErrorMessage error={errors.surname} />
                                        </S.TrainerSignupInputWrapper>
                                        <S.TrainerSignupInputWrapper>
                                            <Input style={{ width: '288px', marginBottom: "1em" }} name="FranchiseID" placeholder="Franchise ID" onChange={handleChange} value={values.FranchiseID} />
                                            <ErrorMessage error={errors.FranchiseID} />
                                        </S.TrainerSignupInputWrapper>
                                        <S.TrainerSignupInputWrapper>
                                            <Input style={{ width: '288px', marginBottom: "1em" }} name="ownerID" placeholder="Owner ID" onChange={handleChange} value={values.ownerID} />
                                            <ErrorMessage error={errors.ownerID} />
                                        </S.TrainerSignupInputWrapper>
                                        <S.TrainerSignupInputWrapper>
                                            <Input style={{ width: '288px', marginBottom: "1em" }} name="email" placeholder="Email" onChange={handleChange} value={values.email} />
                                            <ErrorMessage error={errors.email} />
                                        </S.TrainerSignupInputWrapper>
                                        <S.TrainerSignupInputWrapper>
                                            <Input 
                                                type='text'
                                                name="birthday"
                                                placeholder="01.01.2000" 
                                                max={moment().add(-1, 'days').format('YYYY-MM-DD')}
                                                onFocus={(e) => e.target.type = 'date'} 
                                                onBlur={(e) => {
                                                    if(e.target.value === '') e.target.type = 'text'
                                                }} 
                                                onChange={handleChange}
                                                value={values.birthday}
                                                style={{width: "318px", margin: "0 auto 1rem", padding: "10px 0px", textAlign: "center"}} 
                                            />
                                            <ErrorMessage error={errors.birthday} />
                                        </S.TrainerSignupInputWrapper>
                                        <S.TrainerSignupInputWrapper>
                                            <Dropdown width='318px' options={sexOptions} name="sex" onChange={handleChange} value={values.sex} placeholder="sex" style={{margin: "0 0 1rem"}} />
                                            <ErrorMessage error={errors.sex} />
                                        </S.TrainerSignupInputWrapper>
                                        <S.TrainerSignupInputWrapper style={{width: '318px'}}>
                                            <TextArea rows={4} placeholder="Enter Description" name="description" onChange={handleChange} value={values.description}  />
                                            <ErrorMessage error={errors.description} />
                                        </S.TrainerSignupInputWrapper>
                                        <S.TrainerSignupInputWrapper>
                                            <Input placeholder="Enter Step 1" name="step1" onChange={handleChange} value={values.step1} />
                                            <ErrorMessage error={errors.step1} />
                                        </S.TrainerSignupInputWrapper>
                                        <S.TrainerSignupInputWrapper>
                                            <Input placeholder="Enter Step 2" name="step2" onChange={handleChange} value={values.step2} />
                                            <ErrorMessage error={errors.step2} />
                                        </S.TrainerSignupInputWrapper>
                                        <S.TrainerSignupInputWrapper>
                                            <Input placeholder="Enter Step 3" name="step3" onChange={handleChange} value={values.step3} />
                                            <ErrorMessage error={errors.step3} />
                                        </S.TrainerSignupInputWrapper>
                                        <S.TrainerSignupInputWrapper>
                                            <Input placeholder="Enter Step 4" name="step4" onChange={handleChange} value={values.step4} />
                                            <ErrorMessage error={errors.step4} />
                                        </S.TrainerSignupInputWrapper>
                                        <S.TrainerSignupInputWrapper>
                                            <Input placeholder="Enter Step 5" name="step5" onChange={handleChange} value={values.step5} />
                                            <ErrorMessage error={errors.step5} />
                                        </S.TrainerSignupInputWrapper>
                                    </S.TrainerSignupInputContainer>
                                    <Button color="rgba(248, 164, 146, 1)" margin="20px 0 0 0" type="submit"><TranslationMessage id="button.trainerSignup" /></Button>
                                    <Loader loading={loading} top="-200px" />
                                </form>
                            )}
                        </Formik>
                    </S.TrainerSignupDataInnerContainer>
                </S.TrainerSignupDataContainer>
            </S.TrainerSignupFormContainer>
        </>
    )
}

export default TrainerSignup
