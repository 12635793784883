import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import * as Utils from "configs/utils";

import Button from "components/formComponents/button/Button";
import EvaluationCard from "components/evaluationCard/evaluationCard";

import ArrowLeft from "assets/images/arrowLeft.svg";
import { EVALUATION_ITEMS } from "../data";

// Redux
import * as SupplementsActions from "redux/supplements/supplements.actions";
import * as AuthActions from "redux/auth/auth.actions";
import * as S from "../OnBoardingPartThree.styles";
import OnboardingSidebar from "pages/OnBoardingPartOne/OnboardingSidebar";

const AllAnamneseEvaluation = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const userAnamneses = useSelector((state) => state.auth.userAnamneses);

  const [Evaluations, setEvaluations] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  // console.log("userAnamneses", userAnamneses);
  useEffect(() => {
    const Items = [...EVALUATION_ITEMS].map((mainItem) => {
      if ([1, 2].includes(mainItem.id)) {
        return {
          ...mainItem,
          images: mainItem.images.map((item) => {
            return {
              ...item,
              isActive: false,
            };
          }),
        };
      }
      return {
        ...mainItem,
        numbers: mainItem.numbers.map((item) => {
          return {
            ...item,
            isActive: false,
          };
        }),
      };
    });
    console.log("setEvaluations", Items);
    setEvaluations(Items);

    setIsLoading(true);
    // dispatch(AuthActions.getUserAnamneseSuccess(null))
    dispatch(
      AuthActions.getUserAnamneseRequest({
        formData: { userID: localStorage.getItem("AnamneseUserId"), weekID: 1 },
        callback: (response) => {
          console.log("userAnamneses", userAnamneses);
          setIsLoading(false);
          displayAnamnese(response?.data[0]);
        },
      })
    );
  }, []);

  const updateFormValues = (list, obj, property) => {
    return list.map((i) => {
      if (i.value === obj[property]) {
        i.isActive = true;
      }

      return i;
    });
  };

  const displayAnamnese = (userAnamneses) => {
    const Items = [...EVALUATION_ITEMS].map((mainItem) => {
      if ([1, 2].includes(mainItem.id)) {
        return {
          ...mainItem,
          images: mainItem.images.map((item) => {
            return {
              ...item,
              isActive: false,
            };
          }),
        };
      }
      return {
        ...mainItem,
        numbers: mainItem.numbers.map((item) => {
          return {
            ...item,
            isActive: false,
          };
        }),
      };
    });

    const evaluations = [...Items].map((obj) => {
      const item = { ...obj };

      if (item.title === "Stresslevel") {
        item.images = updateFormValues(
          item.images,
          userAnamneses,
          "stress_level"
        );
      }
      if (item.title === "Körperform") {
        item.images = updateFormValues(item.images, userAnamneses, "body_fat");
      }
      if (item.title === "Sportliche Aktivität") {
        item.numbers = updateFormValues(
          item.numbers,
          userAnamneses,
          "sport_activity"
        );
      }
      if (item.title === "Tägliche Aktivität") {
        item.numbers = updateFormValues(
          item.numbers,
          userAnamneses,
          "daily_activity"
        );
      }
      if (item.title === "Rauchen") {
        item.numbers = updateFormValues(item.numbers, userAnamneses, "smoking");
      }
      if (item.title === "Alkohol") {
        item.numbers = updateFormValues(item.numbers, userAnamneses, "alcohol");
      }

      if (item.title === "Obst & Gemüse") {
        item.numbers = updateFormValues(
          item.numbers,
          userAnamneses,
          "fruits_vegitables"
        );
      }
      if (item.title === "Mahlzeitenfrequenz") {
        item.numbers = updateFormValues(
          item.numbers,
          userAnamneses,
          "meal_frequency"
        );
      }
      if (item.title === "Trinken") {
        item.numbers = updateFormValues(
          item.numbers,
          userAnamneses,
          "drinking"
        );
      }
      if (item.title === "Heißhunger") {
        item.numbers = updateFormValues(
          item.numbers,
          userAnamneses,
          "cravings"
        );
      }
      if (item.title === "Stimmungsschwankungen") {
        item.numbers = updateFormValues(
          item.numbers,
          userAnamneses,
          "mood_swings"
        );
      }
      if (item.title === "Schwächegefühl") {
        item.numbers = updateFormValues(
          item.numbers,
          userAnamneses,
          "weekness_feeling"
        );
      }
      return item;
    });

    // console.log("evaluations", evaluations)
    setEvaluations(evaluations);
  };

  const handleBack = () => {
    history.push("/onboarding/healthscore/priorities");
  };

  const handleNext = () => {
    history.push("/onboarding/healthscore/tips-and-tricks");
  };

  const handleClose = () => {
    const userData = JSON.parse(localStorage.getItem("RegistrationData"));
    Utils.stopRecording();
    dispatch(
      SupplementsActions.deleteMailChimpUserRequest({
        formValues: {
          sex: userData?.sex,
          mobile: userData?.mobile,
          birthday: userData?.birthday,
          email: userData?.email,
          prename: userData?.prename,
          surname: userData?.surname,
          type: "web",
        },
        func: (res) => {
          Utils.removeOnboardingKeysForMiddleFlow();
          console.log("Close", res);
        },
      })
    );
    history.push("/dashboard/main");
  };

  return (
    <>
      <S.OnBoardingPage className="starter">
        <S.OnBoardingContainer className="onboarding-container">
          <OnboardingSidebar step={4} />
          <S.OnBoardingContent className="onboarding-content">
            <S.OnBoardingHeader className="onboarding-header">
              <div className="d-flex align-items-top gap-3">
                <img
                  className="cursor-pointer"
                  src={ArrowLeft}
                  alt="back"
                  onClick={handleBack}
                />
                <div className="content">
                  <span className="small-title text-green font-rubik-medium">
                    Tipps & Tricks
                  </span>
                  <h6 className="header-title font-rubik-medium my-0">
                    Grundlagen einer gesunden Ernährung.
                  </h6>
                </div>
              </div>
              <div className="actions d-flex align-items-center gap-2">
                <Button
                  color="rgba(248, 164, 146, 1)"
                  gradient="linear-gradient(60deg, rgba(46, 207, 150, 1), rgba(28, 157, 135, 1)) !important"
                  margin="0"
                  absolute={{ width: "fit-content", height: "2.5rem" }}
                  buttonStyle={{ padding: ".5625rem 2.5rem" }}
                  onClick={handleNext}
                >
                  Weiter
                </Button>

                <button className="btn-close" onClick={handleClose}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="#000"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-x"
                  >
                    <line x1="18" y1="6" x2="6" y2="18"></line>
                    <line x1="6" y1="6" x2="18" y2="18"></line>
                  </svg>
                </button>
              </div>
            </S.OnBoardingHeader>
            <S.OnBoardingBody className="onboarding-body">
              <div className="health-score-all-anamnese-content">
                <h3 className="text-size-20-28 text-dark-1 font-rubik-medium mt-0">
                  Auswertung der Anamnese
                </h3>
                <p className="text-size-16-19 text-light-grey-1 font-rubik-regular">
                  Deine Fragen haben wir ausgewertet.
                </p>
                <div className="health-score-proirities-blocks gap-3">
                  {isLoading && (
                    <>
                      <br />
                      <br />
                      <br />
                      <br />
                      <div className="text-center">
                        <div className="loader mx-auto"></div>
                      </div>
                      <br />
                      <br />
                      <br />
                      <br />
                    </>
                  )}
                  {!isLoading && (
                    <S.GridView>
                      {Evaluations.map((item, i) => (
                        <EvaluationCard item={item} key={i} />
                      ))}
                    </S.GridView>
                  )}
                </div>
              </div>
            </S.OnBoardingBody>
          </S.OnBoardingContent>
        </S.OnBoardingContainer>
        <br />
        <br />
        <br />
      </S.OnBoardingPage>
    </>
  );
};

export default AllAnamneseEvaluation;
