import styled from "styled-components"

export const SectionHeadline = styled.div`
  font-size: 20px;
  color: #142c1f;
  font-weight: 500;
  margin: 0;
  padding-bottom: 15px;
`

export const SubHeadline = styled.div`
  font-size: 16px;
  color: #818d88;
  font-weight: 200;
`
