import React from "react";
import { useDispatch, useSelector } from "react-redux";
import * as S from "./topbar.styles";
import { useHistory } from "react-router-dom";
import {
  getPercent,
  formatToGermanCurrency,
  getTargetValue,
} from "configs/utils";

// Components
import ProgressBar from "components/progressBar/ProgressBar";
import TranslationMessage from "configs/translations/TranslationMessage";

// Images
import topbar from "assets/images/topbar.svg";
import topbarLogo from "assets/images/logo.svg";
import rightArrow from "assets/images/rightArrow.png";
import closeIcon from "assets/images/closeIcon.svg";
import DonutProgress from "components/donutProgress/DonutProgress";

// Redux
import * as SupplementsActions from "redux/supplements/supplements.actions";
import NavigationTabs from "components/navigationTabs/NavigationTabs";
import ProfileDropIcon from "components/profileDropIcon/ProfileDropIcon";

const Topbar = (props) => {
  const {
    prev,
    next,
    close,
    navTabs,
    profileDropdown,
    onNutritionTypeIdCalculation = null,
    urlId = "",
  } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const authenticated =
    localStorage.getItem("trainer_token") || localStorage.getItem("token");
  const registrationData = useSelector((state) => state.auth.registrationData);
  const healthScoreData = useSelector((state) => state.auth.healthScoreData);

  const handleLinkPosition = (upcomingPage) => {
    let newCurrentLinkPosition = props.arrayData.indexOf(upcomingPage);
    let newPrevLink = props.arrayData[newCurrentLinkPosition - 1];
    let newNextLink = props.arrayData[newCurrentLinkPosition + 1];
    // console.log("props.arrayData", props.arrayData)
    // console.log("newPrevLink", newPrevLink)
    props.setPrevLink(newPrevLink);
    props.setNextLink(newNextLink);
  };
  const handlePrev = () => {
    if (props.arrayData) handleLinkPosition(prev);
    if (props.onPrev) {
      props.onPrev();
      if (props.redirect) {
        history.push(prev);
        if (props.arrayData) handleLinkPosition(prev);
      }
    } else history.push(prev);
  };
  const handleNext = () => {
    if (props.arrayData) handleLinkPosition(next);

    if (props.onNext) {
      props.onNext();
      if (props.redirect) {
        console.log("next URL", next);
        history.push(next);
        if (props.arrayData) handleLinkPosition(next);
      }
      // if (urlId === "personalData") {
      //     props.onNutritionTypeIdCalculation()
      // }

      //   if (urlId === "bodyDetails") {
      //     history.push(next);
      //     if (props.arrayData) handleLinkPosition(next);
      //   }
    } else if (props.arrayData) handleLinkPosition(next);
    else history.push(next);
  };

  const handleTargetBudget = () => {
    if (props.targetBudget === undefined)
      return getTargetValue(healthScoreData.sliderPosition);
    else
      return getTargetValue(
        props.targetBudget.toString()
          ? props.targetBudget
          : healthScoreData.sliderPosition
      );
  };

  return (
    <div>
      <S.TopbarContainer>
        {props.progress && <ProgressBar progress={props.progress} />}
        <S.TopbarLogo progress={props.progress} src={topbarLogo} alt="Logo" />
        {props.healthReport && (
          <S.HealthReportContainer>
            <S.HealthReportSeparationLine />
            <S.HealthReportGraphContainer>
              <DonutProgress
                percent={
                  healthScoreData.currentHealthScore
                    ? healthScoreData.currentHealthScore
                    : props.percent
                }
                target={
                  props.targetHealthScore
                    ? props.targetHealthScore
                    : getPercent(healthScoreData.sliderPosition)
                }
              />
            </S.HealthReportGraphContainer>
            <S.HealthReportSeparationLine />
            <S.HealthReportCurrentCoachingContainer>
              <TranslationMessage id="topbar.currentCoachingPosition" />
              <S.HealthReportValue>89 / 95</S.HealthReportValue>
            </S.HealthReportCurrentCoachingContainer>
            <S.HealthReportSeparationLine />
            <S.HealthReportTargetBudgetContainer>
              <TranslationMessage id="topbar.targetBudget" />
              <S.HealthReportValue>
                {formatToGermanCurrency(
                  props.actualCost
                    ? props.actualCost.toFixed(2)
                    : healthScoreData.totalSupplementsCost.toFixed(2)
                )}{" "}
                <S.TargetBudgetValue>
                  von {handleTargetBudget()}
                </S.TargetBudgetValue>
              </S.HealthReportValue>
            </S.HealthReportTargetBudgetContainer>
          </S.HealthReportContainer>
        )}
        {!!navTabs && (
          <S.TopbarNaviagationTabContainer>
            <NavigationTabs tabValues={navTabs} />
          </S.TopbarNaviagationTabContainer>
        )}
        {profileDropdown && (
          <S.TopbarProfileDrowdownContainer>
            <ProfileDropIcon
              profileIcon={profileDropdown.profilePic}
              profileName={profileDropdown.name}
            />
          </S.TopbarProfileDrowdownContainer>
        )}
        <S.TopbarImage src={topbar} alt="Top Bar" progress={props.progress} />
        <S.TopBarButtonContainer progress={props.progress}>
          {authenticated && prev && (
            <S.BackButton
              color="rgba(248, 164, 146, 1)"
              onClick={handlePrev}
              back
            >
              Zurück
            </S.BackButton>
          )}
          {authenticated && next && (
            <S.FurtherButton
              color="rgba(248, 164, 146, 1)"
              nextButtonWidth={props.nextButtonWidth}
              style={props.nextButtonStyle}
              onClick={handleNext}
              noButtonArrow={props.noButtonArrow}
              disabled={props.nextButtonDisabled}
              id={props.nextButtonId ? props.nextButtonId : "next"}
            >
              {props.nextButtonValue ? props.nextButtonValue : "Weiter"}{" "}
              {!props.noButtonArrow && (
                <S.NextButtonForwardArrow src={rightArrow} alt="Right Arrow" />
              )}
            </S.FurtherButton>
          )}
          {authenticated && close && (
            <S.HealthReportCloseButton
              color="#142C1F"
              onClick={() => {
                props.name == "subscription"
                  ? props.setIsClose(!props.isClose)
                  : dispatch(
                      SupplementsActions.deleteMailChimpUserRequest({
                        formValues: {
                          sex: registrationData.sex,
                          mobile: registrationData.mobile,
                          birthday: registrationData.birthday,
                          email: registrationData.email,
                          prename: registrationData.prename,
                          surname: registrationData.surname,
                          type: "web",
                        },
                        func: props.onClose,
                      })
                    );
              }}
            >
              <img
                src={closeIcon}
                style={{ position: "absolute", right: "25px", top: "15px" }}
                alt="Close Icon"
              />
            </S.HealthReportCloseButton>
          )}
        </S.TopBarButtonContainer>
      </S.TopbarContainer>
    </div>
  );
};

export default Topbar;
