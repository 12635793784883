import styled from "styled-components";

export const EvaluationCard = styled.div`
  background: #ffffff;
  box-shadow: 0px 1px 2px #1018280d;
  border: 1px solid #d0d5dd;
  border-radius: 0.625rem;
  padding: 1.5rem;

  .evaluation-item {
    position: relative;

    .form-group-c {
      position: absolute;
      top: 0.15rem;
      right: 0;
    }
  }

  .title {
    margin-block: 0;
  }

  .desc {
    margin: 10px 0 20px;
  }

  .eval-desc {
    margin-top: 25px;
    margin-bottom: 0;
  }

  .evaluation-items {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    gap: 5px;
    .evaluation-item {
      padding: 5px;
      border: 2px solid transparent;
      text-align: center;
      cursor: pointer;
      background: #ffffff;
      box-shadow: 0px 2px 5px #00000014;
      border-radius: 8px;
      gap: 0.5rem;

      p {
        margin: 1rem 0 0 0;
      }

      &.active {
        border-color: #2ECF96;
        background: #F3FFFD;

        p {
          color: #142c1f;
        }
      }

      .status-bar {
        width: 100%;
        height: 12px;
        border-radius: 10px;

        &.green-1 {
          background: rgba(46, 207, 150, 0.25);
        }
        &.green-2 {
          background: rgba(46, 207, 150, 0.5);
        }
        &.green-3 {
          background: rgba(46, 207, 150, 0.75);
        }
        &.green-4 {
          background: rgba(46, 207, 150, 1);
        }

        &.light-green {
          background: #a7e140;
        }

        &.light-orange {
          background: #fed400;
        }

        &.orange {
          background: #f39b29;
        }

        &.red-1 {
          background: rgba(226, 104, 104, 0.25);
        }
        &.red-2 {
          background: rgba(226, 104, 104, 0.5);
        }
        &.red-3 {
          background: rgba(226, 104, 104, 0.75);
        }
        &.red-4 {
          background: rgba(226, 104, 104, 1);
        }
      }

      img {
        margin-top: 1rem;
        width: 1.625rem;
        height: 1.625rem;
      }
    }
  }
`;
