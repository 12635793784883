import React from 'react';
import { useHistory } from "react-router-dom";
import * as S from './premiumCoachingSuccess.styles';
import { getRandomValueInRange } from "configs/utils";

// Components
import Topbar from 'components/topbar/Topbar';
import TranslationMessage from 'configs/translations/TranslationMessage';
import Card from 'components/card/Card';
import MultiCarousel from 'components/multiCarousel/MultiCarousel';

// Images
import userProfile1 from "assets/images/reviews/Marie-Sophie-Silmar-01.png";
import userProfile2 from "assets/images/reviews/Anna-Seiner.png";
import userProfile3 from "assets/images/reviews/Lukas-Tatzer-01.png";
import ratingStars from "assets/images/rating.svg";

function PremiumCoachingSuccess() {
    const history = useHistory();

    const CardData = [
        {
            id: "card1",
            profileImage: userProfile1,
            rating: ratingStars,
            username: "premium.coaching.success.username1",
            comment: "premium.coaching.success.comment1",
        },
        {
            id: "card2",
            profileImage: userProfile2,
            rating: ratingStars,
            username: "premium.coaching.success.username2",
            comment: "premium.coaching.success.comment2",
        },
        {
            id: "card3",
            profileImage: userProfile3,
            rating: ratingStars,
            username: "premium.coaching.success.username3",
            comment: "premium.coaching.success.comment3",
        },
    ]

    return (
        <>
            {/* healthReport */}
            <Topbar prev="/premiumCoaching" next="/subscriptions" progress={'73%'} percent={getRandomValueInRange(40, 45)} onClose={() => history.push('/login')} close />
            <S.PremiumCoachingSuccessContainer>
                <S.PremiumCoachingSuccessHeading>
                    <TranslationMessage id="premium.coaching.success.heading" />
                </S.PremiumCoachingSuccessHeading>
                <S.PremiumCoachingSuccessSubHeading>
                    <TranslationMessage id="premium.coaching.success.subHeading" />
                </S.PremiumCoachingSuccessSubHeading>
                <S.PremiumCoachingSuccessCardContainer>
                    <MultiCarousel itemClass="premium-coaching-success-carousel" totalItems={3} slidesToSlide={2}>
                        {CardData.map((card) => {
                            return (
                                <Card
                                    width="395px"
                                    height="264px"
                                    margin="auto 10px"
                                    textAlign="left"
                                    key={card.id}
                                >
                                    <S.PremiumCoachingSuccessCardInnerContainer>
                                        <S.PremiumCoachingSuccessCommentUserProfile>
                                            <S.ProfileImage src={card.profileImage} alt="profileImage" />
                                            <S.PremiumCoahingSuccessCommentRating>
                                                <S.Rating src={card.rating} alt="ratingStars" />
                                                <S.Username>
                                                    <TranslationMessage id={card.username} />
                                                </S.Username>
                                            </S.PremiumCoahingSuccessCommentRating>
                                        </S.PremiumCoachingSuccessCommentUserProfile>
                                        <S.Comment>
                                            "<TranslationMessage id={card.comment} />"
                                        </S.Comment>
                                    </S.PremiumCoachingSuccessCardInnerContainer>
                                </Card>
                            )
                        })}
                    </MultiCarousel>
                </S.PremiumCoachingSuccessCardContainer>

            </S.PremiumCoachingSuccessContainer>
        </>
    )
}

export default PremiumCoachingSuccess