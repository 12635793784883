import React, { useEffect, useState } from "react";
import { Route, useLocation, useHistory, Redirect } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getS3ImageUrl } from "configs/utils";

import MainCustomers from "components/MainCustomers/MainCustomers";
import ProfileDropIcon from "components/profileDropIcon/ProfileDropIcon";
import Sidebar from "./Sidebar/Sidebar";
import TrainerProfileSection from "pages/trainerProfileDetails/TrainerProfileSection";
import AcademyWrapper from "pages/AcademyWrapper/AcademyWrapper";
import WhitePaper from "pages/AcademyWrapper/WhitePaper/WhitePaper";
import AcademyDetails from "pages/AcademyWrapper/AcademyDetails/AcademyDetails";
import WebsiteWrapper from "pages/trainerProfileDetails/website/WebsiteWrapper";
import SocialMediaInstagram from "./SocialMedia/SocialMediaInstagram";
import SocialMediaLinkedIn from "./SocialMedia/SocialMediaLinkedIn";
import Support from "./Support/Support";
import FAQs from "./Support/FAQs/FAQs";
import NewDashboard from "./NewDashboard/NewDashboard";
import PartnershipsExperts from "./Partnerships/PartnershipsExperts";
import PartnershipsEducation from "./Partnerships/PartnershipsEducation";
import PartnershipsMarketing from "./Partnerships/PartnershipsMarketing";
import EmailAccount from "./Settings/EmailAccount";
import PaymentProvider from "./Settings/PaymentProvider";
import TemporaryAccessData from "./Settings/TemporaryAccessData";
import ClientDetails from "components/clientDetails/ClientDetails";
import TokenRequest from "components/tokenRequest/TokenRequest";

import * as S from "./dashboard.styles";
import ExpertsCalls from "pages/AcademyWrapper/ExpertsCalls/ExpertsCalls";
import TrainerInvoices from "pages/trainerProfileDetails/TrainerInvoices";
import PresentationsAndBrochures from "./MarketingPortal/PresentationsAndBrochures";
import Partnerships from "./MarketingPortal/Partnerships";
import PrintData from "./MarketingPortal/PrintData";
import PrintDataDetails from "./MarketingPortal/PrintDataDetails";
import PartnershipDetails from "./MarketingPortal/PartnershipDetails";
import CreateNewPartner from "./MarketingPortal/CreateNewPartner";
import TerminationForm from "../trainerProfileDetails/TerminationForm/TerminationForm";
import HealthInsuranceReinbursement from "pages/NewDasboard/Support/HealthInsuranceReinbursement/HealthInsuranceReinbursement";
import TrainerProfileBillingSection from "pages/trainerProfileDetails/TrainerProfileBillingSection";
import Credits from "pages/trainerProfileDetails/Credits";
import Transfer from "components/clientDetails/Transfer";
import CreditsDetails from "pages/trainerProfileDetails/CreditsDetails";
import MainLeads from "pages/NewDasboard/Leads/MainLeads";
import MainLeadDetails from "pages/NewDasboard/Leads/MainLeadDetails";
import FoodiaryLeads from "./Leads/FoodiaryLeads";
import FoodiaryLeadDetails from "./Leads/FoodiaryLeadDetails";

import CrossIcon from "assets/images/cross.svg";
import ErrorMessageIcon from "assets/images/error-message.png";
import Modal from "components/modal/Modal";
import Overview from "pages/ClientDetails/Overview";
import Calories from "pages/ClientDetails/Calories";
import DietFormAndNutritionDistribution from "pages/ClientDetails/DietFormAndNutritionDistribution";
import Objective from "pages/ClientDetails/Objective";
import Anamnese from "pages/ClientDetails/Anamnese";
import DiaryAndHabbitTracker from "pages/ClientDetails/DiaryAndHabbitTracker";
import EmailManagement from "pages/ClientDetails/EmailManagement";
import NutritionCourse from "pages/ClientDetails/NutritionCourse/NutritionCourse";
import Invoices from "pages/ClientDetails/Invoices";
import ProgressAndMeasurements from "pages/ClientDetails/ProgressAndMeasurements";
import DailyRoutine from "pages/ClientDetails/DailyRoutine";

import { NavbarTitles } from "./Data";

import * as AuthActions from "redux/auth/auth.actions";
import * as SupplementsActions from "redux/supplements/supplements.actions";
import * as DashboardActions from "redux/dashboard/dashboard.actions";
import ClientDetailsLayout from "pages/ClientDetails/ClientDetailsLayout";

export default function NewDasboardLayout() {
  const [open, setOpen] = React.useState(true);
  const [ShouldRenderFoodiaryLeadsURLs, SetShouldRenderFoodiaryLeadsURLs] =
    React.useState(false);

  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const signInResponse = useSelector((state) => state.auth.signInResponse);
  const trainerProfileData = useSelector((state) => state.auth.trainerData);
  const isCheckStripeConnectedAccountStatusLoading = useSelector(
    (state) => state.dashboard.isCheckStripeConnectedAccountStatusLoading
  );

  const [
    IsStripeConnectedAccountStatusModal,
    SetIsStripeConnectedAccountStatusModal,
  ] = useState(false);

  const authenticated =
    localStorage.getItem("trainer_token") &&
    localStorage.getItem("token") &&
    signInResponse?.data?.token;

  useEffect(() => {
    dispatch(
      AuthActions.getTrainerDetailsRequest({
        FranchiseID: trainerProfileData?.FranchiseID,
        trainerID: trainerProfileData?.trainerID,
      })
    );

    dispatch(
      DashboardActions.getTrainerProfileTempDetailsRequest({
        payload: { trainerID: trainerProfileData?.trainerID },
        callback: () => {},
      })
    );

    dispatch(
      SupplementsActions.getLeadCenterStatusRequest({
        payload: {
          trainer_id: trainerProfileData?.trainerID,
        },
        callback: (res) => {
          SetShouldRenderFoodiaryLeadsURLs(res?.getleadcenterstatus);
        },
      })
    );
  }, []);

  if (!authenticated) return <Redirect to="/login" />;

  const checkStripeConnection = () => {
    dispatch(
      DashboardActions.checkStripeConnectedAccountStatusRequest({
        payload: {
          trainerID: trainerProfileData?.trainerID,
        },
        callback: (res) => {
          console.log("checkStripeConnectedAccountStatusRequest", res);
          if (
            res?.connected_account_status === "completed" &&
            res?.connected_user_account_status === "completed"
          ) {
            history.push("/onboarding/legal-informations");
          } else {
            SetIsStripeConnectedAccountStatusModal(true);
          }
        },
      })
    );
  };

  return (
    <>
      <Modal
        width="450px"
        height="auto"
        show={IsStripeConnectedAccountStatusModal}
        setShow={() => null}
        heading={
          <div className="d-flex align-items-center justify-content-between">
            <h4 className="my-0 text-left"> </h4>
            <img
              className="cursor-pointer"
              src={CrossIcon}
              alt="CrossIcon"
              onClick={() => SetIsStripeConnectedAccountStatusModal(false)}
            />
          </div>
        }
      >
        <div className="congratulations-modal-body-inner">
          <img src={ErrorMessageIcon} alt="" />

          <h6>Achtung!</h6>
          <p>
            Dein Zahlungsanbieter wurde noch nicht aktiviert. <br />
            Bitte aktiviere deinen Anbieter direkt.
          </p>
          <button
            className="btn-dark"
            onClick={() => history.push("/dashboard/settings/payment-provider")}
          >
            Zahlungsanbieter aktivieren
          </button>
        </div>
      </Modal>
      <div className="new-dashboard-layout">
        <div className="new-dashboard-layout-container relative sideNav">
          <Sidebar />
          <main className="main">
            <div className="dashboard-navbar">
              <div className="dashboard-navbar-container">
                <h6 className="navbar-dynamic-page-title">
                  {location?.pathname &&
                    NavbarTitles.filter((item) =>
                      location.pathname.includes(item.path)
                    )[0].title}
                </h6>
                <div className="navbar-actions">
                  <button
                    onClick={checkStripeConnection}
                    className="btn-outline sm"
                  >
                    Erstgespräch starten
                    {isCheckStripeConnectedAccountStatusLoading && (
                      <div className="loader-small"></div>
                    )}
                  </button>
                  <div className="ml-auto">
                    <ProfileDropIcon
                      profileIcon={getS3ImageUrl(
                        trainerProfileData?.profilePic
                      )}
                      isArrowIcon={false}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="page-content-wrapper">
              <Route path="/dashboard/main" component={NewDashboard} exact />
              <Route path="/dashboard/leads/all" component={MainLeads} exact />
              <Route
                path="/dashboard/leads/all/details/:leadId"
                component={MainLeadDetails}
                exact
              />

              <Route
                path="/dashboard/leads/foodiary"
                component={FoodiaryLeads}
                exact
              />
              <Route
                path="/dashboard/leads/foodiary/details/:leadId"
                component={FoodiaryLeadDetails}
                exact
              />

              <Route
                path="/dashboard/partnerships/experts"
                component={PartnershipsExperts}
                exact
              />

              <Route
                path="/dashboard/partnerships/education"
                component={PartnershipsEducation}
                exact
              />

              <Route
                path="/dashboard/partnerships/marketing"
                component={PartnershipsMarketing}
                exact
              />

              <Route
                path="/dashboard/customers/list"
                component={MainCustomers}
                exact
              />

              <Route
                path="/dashboard/customers/token-request"
                component={TokenRequest}
                exact
              />

              {/* <Route
                path="/dashboard/nutrition-form"
                component={NutritionForm}
                exact
              />

              <Route
                path="/dashboard/form-advantages"
                component={FormAdvantages}
                exact
              /> */}

              <Route
                path="/dashboard/customers/detail/:userId"
                component={ClientDetails}
                exact
              />
              <ClientDetailsLayout />

              {/* <Route
                path="/dashboard/clients/overview/:userId"
                component={Overview}
                exact
              />

              <Route
                path="/dashboard/clients/calories/:userId"
                component={Calories}
                exact
              />

              <Route
                path="/dashboard/clients/diet-and-nutrition/:userId"
                component={DietFormAndNutritionDistribution}
                exact
              />

              <Route
                path="/dashboard/clients/objective/:userId"
                component={Objective}
                exact
              />

              <Route
                path="/dashboard/clients/anamnese/:userId"
                component={Anamnese}
                exact
              />

              <Route
                path="/dashboard/clients/journal/:userId"
                component={DiaryAndHabbitTracker}
                exact
              />

              <Route
                path="/dashboard/clients/email-management/:userId"
                component={EmailManagement}
                exact
              />

              <Route
                path="/dashboard/clients/nutrition-course/:userId"
                component={NutritionCourse}
                exact
              />
              <Route
                path="/dashboard/clients/invoices/:userId"
                component={Invoices}
                exact
              />

              <Route
                path="/dashboard/clients/progress-and-measurements/:userId"
                component={ProgressAndMeasurements}
                exact
              />

              <Route
                path="/dashboard/clients/daily-routine/:userId"
                component={DailyRoutine}
                exact
              /> */}

              <Route path="/dashboard/transfer" component={Transfer} exact />
              <Route
                path="/dashboard/marketing-portal/werbeassets"
                component={PresentationsAndBrochures}
                exact
              />
              <Route
                path="/dashboard/marketing-portal/print-data"
                component={PrintData}
                exact
              />

              <Route
                path="/dashboard/marketing-portal/print-data/:id"
                component={PrintDataDetails}
                exact
              />

              <Route
                path="/dashboard/marketing-portal/partners"
                component={Partnerships}
                exact
              />

              <Route
                path="/dashboard/marketing-portal/partners/:id"
                component={PartnershipDetails}
                exact
              />

              <Route
                path="/dashboard/marketing-portal/partner/create"
                component={CreateNewPartner}
                exact
              />

              <Route
                path="/dashboard/profile"
                component={TrainerProfileSection}
                exact
              />
              <Route
                path="/dashboard/billing"
                component={TrainerProfileBillingSection}
                exact
              />

              <Route
                path="/dashboard/trainer-invoices"
                component={TrainerInvoices}
                exact
              />

              <Route
                path="/dashboard/trainer-credits"
                component={Credits}
                exact
              />
              <Route
                path="/dashboard/trainer-credits/details"
                component={CreditsDetails}
                exact
              />

              <Route
                path="/dashboard/termination"
                component={TerminationForm}
                exact
              />

              <Route
                path="/dashboard/academy/video"
                component={AcademyWrapper}
                exact
              />
              <Route
                path="/dashboard/academy/video/:id"
                component={AcademyDetails}
              />

              <Route
                path="/dashboard/academy/white-paper"
                component={WhitePaper}
                exact
              />

              <Route
                path="/dashboard/academy/experts-calls"
                component={ExpertsCalls}
                exact
              />

              <Route
                path="/dashboard/support/health-insurance-reimbursement"
                component={HealthInsuranceReinbursement}
                exact
              />

              <Route path="/dashboard/website" component={WebsiteWrapper} />
              <Route
                path="/dashboard/social-media/instagram"
                component={SocialMediaInstagram}
              />
              <Route
                path="/dashboard/social-media/linkedin"
                component={SocialMediaLinkedIn}
                exact
              />

              <Route
                path="/dashboard/support/open-ticket"
                component={Support}
                exact
              />

              <Route path="/dashboard/support/faq" component={FAQs} exact />

              <Route
                path="/dashboard/settings/email-account"
                component={EmailAccount}
                exact
              />

              <Route
                path="/dashboard/settings/access-data"
                component={TemporaryAccessData}
                exact
              />

              <Route
                path="/dashboard/settings/payment-provider"
                component={PaymentProvider}
                exact
              />

              {/* <Route path="*" render={() => "Page Not Found"} /> */}
            </div>
          </main>
        </div>
      </div>
    </>
  );
}
