import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getS3ImageUrl } from "configs/utils";
import { SidebarSteps } from "./data";
// Components
import Button from "components/formComponents/button/Button";

import FoodiaryLogo from "assets/images/Foodiary-Logo.PNG";
// Redux
import * as AuthActions from "redux/auth/auth.actions";
import * as S from "./OnBoardingPartOne.styles";

const OnboardingSidebar = ({ step }) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const [activeTab, setActiveTab] = useState(1);

  useEffect(() => {}, []);

  return (
    <>
      <S.OnBoardingSidebar className="onboarding-sidebar">
        <img width={130} src={FoodiaryLogo} alt="" />
        <h6 className="sidebar-title">
          Herzlich Willkommen zum Erstgespräch. 👋
        </h6>
        <span className="steps-title">Schritte</span>
        <div className="steps">
          {SidebarSteps?.map((item) => {
            if (item.id <= step) {
              return { ...item, isActive: true };
            }
            return item;
          }).map((step, i) => (
            <div className="step" key={i}>
              <div className="step-bar">
                <div className={step.isActive ? "round active" : "round"}>
                  <div className="small-round"></div>
                </div>
                {i !== SidebarSteps.length - 1 && (
                  <div className={step.isActive ? "wall active" : "wall"}></div>
                )}
              </div>
              <div className="step-content">
                <h6 className="step-title">{step.title}</h6>
                <p className="step-description">{step.description}</p>
              </div>
            </div>
          ))}
        </div>
      </S.OnBoardingSidebar>
    </>
  );
};

export default OnboardingSidebar;
