import React, { useState } from "react";
import * as S from "./checkbox.styles";

const SquareCheckbox = ({
  margin,
  isChecked,
  setIsChecked,
  onClick,
  param,
  ...props
}) => {
  const [checked, setChecked] = useState(isChecked);

  return (
    <S.SquareCheckBoxContainer
      margin={margin}
      rounded={props.rounded}
      filled={props.filled}
    >
      <S.CustomSquareCheckBox
        checked={isChecked}
        onChange={(e) => console.log("")}
        {...props}
      />
      <label
        onClick={() => {
          if (setIsChecked) {
            setIsChecked((prevState) => ({ ...prevState, [param]: !isChecked }))
          }

          if (onClick) onClick()
        }}
      ></label>
    </S.SquareCheckBoxContainer>
  );
};

export default SquareCheckbox;
