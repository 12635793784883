import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getS3ImageUrl } from "configs/utils";

// Components
import Button from "components/formComponents/button/Button";

import FoodiaryLogo from "assets/images/Foodiary-Logo.PNG";
import ArrowLeft from "assets/images/arrowLeft.svg";

import { SidebarSteps, OnboardingInterviewSteps } from "./data";
// Redux
import * as AuthActions from "redux/auth/auth.actions";
import * as S from "./OnBoardingPartOne.styles";
import OnboardingSidebar from "./OnboardingSidebar";

const InitialInterviewSteps = (props) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const [activeTab, setActiveTab] = useState(1);

  useEffect(() => {}, []);

  return (
    <>
      <S.OnBoardingPage className="starter">
        <S.OnBoardingContainer className="onboarding-container">
          <OnboardingSidebar step={1} />
          <S.OnBoardingContent className="onboarding-content">
            <S.OnBoardingHeader className="onboarding-header">
              <div className="d-flex align-items-top gap-3">
                <img
                  className="cursor-pointer"
                  src={ArrowLeft}
                  alt="back"
                  onClick={() =>
                    history.push("/onboarding/trainer-introduction")
                  }
                />
                <div className="content">
                  <span className="text-size-14-28 text-green font-rubik-medium">
                    Vorstellung
                  </span>
                  <h6 className="header-title font-rubik-medium my-0">
                    3 Teile des Erstgespräch
                  </h6>
                </div>
              </div>
              <div className="actions">
                <Button
                  color="rgba(248, 164, 146, 1)"
                  gradient="linear-gradient(60deg, rgba(46, 207, 150, 1), rgba(28, 157, 135, 1)) !important"
                  margin=" 0"
                  absolute={{ width: "fit-content", height: "2.5rem" }}
                  buttonStyle={{ padding: ".5625rem 2rem" }}
                  onClick={() => history.push("/onboarding/foodiary-benefits")}
                >
                  Weiter
                </Button>
              </div>
            </S.OnBoardingHeader>
            <S.OnBoardingBody className="onboarding-body d-flex justify-content-center align-items-center">
              <div className="interview-steps-content text-center ">
                <h3 className="h3 text-dark-1 font-rubik-medium">
                  So unterstütze ich dich.
                </h3>
                <br />
                <p className="p1 text-dark-2 font-rubik-regular">
                  In den kommenden 3 Teilen des Erstgespräches <br />
                  findet die Anamnese, Auswertung und Vorstellung <br />
                  statt.
                </p>

                <div className="steps-cards">
                  {OnboardingInterviewSteps.map((item, i) => (
                    <div className="step-card" key={i}>
                      <img src={item.img} alt="" />
                      <h5 className="font-rubik-medium">{item?.title}</h5>
                      <p className="font-rubik-regular">{item?.description}</p>
                    </div>
                  ))}
                </div>
              </div>
            </S.OnBoardingBody>
          </S.OnBoardingContent>
        </S.OnBoardingContainer>
      </S.OnBoardingPage>
    </>
  );
};

export default InitialInterviewSteps;
