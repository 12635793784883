import * as S from "./OnBoardingPartOne.styles";
import OnboardingSidebar from "./OnboardingSidebar";
import { useHistory } from "react-router-dom";
import CrossIcon from "assets/images/cross.svg";

function CallRecording() {
  const history = useHistory();

  return (
    <>
      <S.OnBoardingPage className="starter">
        <S.OnBoardingContainer className="onboarding-container">
          <OnboardingSidebar step={1} />
          <S.OnBoardingContent className="onboarding-content">
            <S.FakeModalEffect>
              <S.OnBoardingHeader className="onboarding-header">
                <div className="d-flex align-items-top gap-3">
                  <div className="content">
                    <span className="small-title text-green font-rubik-medium">
                      Vorstellung
                    </span>
                    <h6 className="header-title font-rubik-medium my-0">
                      Darf ich das Gespräch aufzeichnen?
                    </h6>
                  </div>
                </div>
              </S.OnBoardingHeader>
              <S.OnBoardingBody className="onboarding-body p-0">
                <S.FakeModalOnboardingBody>
                  <S.FakeModalOnboardingContent>
                    <div className="close">
                      <img
                        onClick={() =>
                          history.push("/onboarding/trainer-introduction")
                        }
                        src={CrossIcon}
                        alt=""
                      />
                    </div>
                    <div className="header-title font-rubik-medium">
                      Darf ich das Gespräch aufzeichnen?
                    </div>
                    <div>
                      <p>
                        Ich würde gerne das Gespräch aufzeichnen, um jederzeit
                        deine Interessen, Angaben und Wünsche nachvollziehen und
                        dich bestmöglich unterstützen zu können. Bist du
                        einverstanden?
                      </p>
                    </div>
                    <div className="ctas">
                      <button
                        className="btn-outline sm"
                        onClick={() =>
                          history.push("/onboarding/trainer-introduction")
                        }
                      >
                        Nein, bitte nicht
                      </button>
                      <button
                        onClick={() =>
                          history.push("/onboarding/trainer-introduction")
                        }
                        className="btn-dark"
                      >
                        Ich bin einverstanden
                      </button>
                    </div>
                  </S.FakeModalOnboardingContent>
                </S.FakeModalOnboardingBody>
              </S.OnBoardingBody>
            </S.FakeModalEffect>
          </S.OnBoardingContent>
        </S.OnBoardingContainer>
      </S.OnBoardingPage>
    </>
  );
}

export default CallRecording;
