import React, { useState, useEffect, useRef } from "react";
import * as S from "./clientDetails.styles";
import "./clientDetails.scss";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import { number, object, string } from "yup";
import moment from "moment";
import { useIntl } from "react-intl";
import Select from "react-select";
import * as Data from "pages/registration/registration.utils";
import { getS3ImageUrl } from "configs/utils";
import Avatar from "@material-ui/core/Avatar";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import Typography from "@material-ui/core/Typography";
import TextArea from "components/formComponents/textArea/TextArea";
import Calendar from "react-calendar";
import { DatePicker as MaterialDatePicker } from "@material-ui/pickers";

// Components
import TranslationMessage from "configs/translations/TranslationMessage";
import DonutChart from "components/donutChart/DonutChart";
import Button from "components/formComponents/button/Button";
import BackIconButton from "components/backIconButton/BackIconButton";
import Input from "components/formComponents/input/Input";
import IncrementInput from "components/formComponents/incrementInput/IncrementInput";
import LineChart from "components/lineChart/LineChart";
import Modal from "components/modal/Modal";
import TimePicker from "components/formComponents/timePicker/TimePicker";
import DatePicker from "components/formComponents/datePicker/DatePicker";
import Loader from "components/loader/Loader";
import ShareSocialMediaModal from "components/ShareSocialMediaModal/ShareSocialMediaModal";

// Images
import editWhiteIcon from "assets/images/edit-white-icon.svg";
import { ReactComponent as EditGrey } from "assets/images/edit.svg";
import { ReactComponent as EditBlackIcon } from "assets/images/edit-black-icon.svg";
import { ReactComponent as DeleteIconGrey } from "assets/images/delete.svg";
import LowCarbImage from "assets/images/lowCarb.svg";
import LowCarbNotSelectedImage from "assets/images/lowCarbNotSelected.svg";
import LeftIcon from "assets/images/leftIcon.svg";
import RightIcon from "assets/images/rightIcon.svg";
import plusIcon from "assets/images/Icon-plus.svg";
import LeafIcon from "assets/images/new/leaf.svg";
import ArrowUpIcon from "assets/images/new/arrow-circle-up.svg";
import ArrowDownIcon from "assets/images/new/arrow-circle-down.svg";
import ArrowEqualIcon from "assets/images/new/arrow-circle-equal.svg";
import ShareIcon from "assets/images/new/Icon-feather-share.svg";
import UserPlaceholder from "assets/images/default-picture.jpg";
// Redux
import * as NutritionActions from "redux/nutrition/nutrition.actions";
import * as AuthActions from "redux/auth/auth.actions";
import * as DashboardActions from "redux/dashboard/dashboard.actions";
import EvaluationOfOverallConditionForCustomers from "pages/EvaluationOfOverallConditionForCustomers/EvaluationOfOverallConditionForCustomers";
import { ControlPoint } from "@material-ui/icons";
import DiaryAndHabbitTracker from "./DiaryAndHabbitTracker";
import EmailManagement from "./EmailManagement";
import NutritionCourse from "./NutritionCourse";
import CopyIcon from "assets/images/new-copy.png";
import EditLightGrey from "assets/images/edit-light-grey.svg";
import Invoices from "./Invoices";
import Credits from "../../pages/trainerProfileDetails/Credits";
import Transfer from "./Transfer";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const ClientDetails = ({ pageNumber = 1 }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const intl = useIntl();
  const { userId } = useParams();
  const query = useQuery();

  const invoiceId = query.get("invoiceId");
  const queryUserId = query.get("userId");

  const formSubmitRef = useRef(null);

  const loading = useSelector((state) => state.auth.loading);
  const caloriesData = useSelector(
    (state) => state.nutrition.caloriesLineChartData
  );

  const userInvoice = useSelector((state) => state.auth.userInvoice);
  const activeNutritionType = useSelector(
    (state) => state.nutrition.activeNutritionType
  );
  const allNutritionTypes = useSelector(
    (state) => state.nutrition.nutritionTypes
  );
  const donutChartData = useSelector(
    (state) => state.nutrition.macroNutrientsDonutChartData
  );

  const nutritionTypePercentageData = useSelector(
    (state) => state.nutrition.nutritionTypePercentageData
  );

  const allCaloriesData = useSelector(
    (state) => state.nutrition.allCaloriesData
  );
  const userWeightData = useSelector((state) => state.auth.userWeightData);
  // console.log("userWeightData", userWeightData);
  const userDailyRoutineData = useSelector(
    (state) => state.auth.userDailyRoutineData
  );

  const userTransaction = useSelector(
    (state) => state.dashboard.userTransaction
  );


  const weekWiseImages = useSelector((state) => state.auth.weekWiseImages);
  const bodyMeasurements = useSelector((state) => state.auth.bodyMeasurements);
  console.log("bodyMeasurements", bodyMeasurements);
  const weekWiseWeight = useSelector((state) => state.auth.weekWiseWeight);

  const userWeightGoalData = useSelector(
    (state) => state.auth.userWeightGoalData
  );

  const userWeightDataDashboard = useSelector(
    (state) => state.dashboard.userWeightData
  );

  console.log("userWeightDataDashboard", userWeightDataDashboard);

  const isGoalWeightUpdating = useSelector(
    (state) => state.dashboard.isGoalWeightUpdating
  );

  // console.log("userWeightDataDashboard", userWeightDataDashboard);

  const trainerData = useSelector((state) => state.auth.trainerData);
  const usersRelatedToTrainer = useSelector(
    (state) => state.auth.usersRelatedToTrainer
  );

  const details = usersRelatedToTrainer?.data?.find(
    (data) => data.userID === +userId
  );

  console.log("details", details);

  const [clientDetails, setClientDetails] = useState(details);
  const [edit, setEdit] = useState(true);
  const [activeTab, setActiveTab] = useState("overview");

  const [isRoutineEdit, setIsRoutineEdit] = useState(false);
  const [selectedDailyRoutineTab, setSelectedDailyRoutineTab] = useState(
    userDailyRoutineData?.[0]
  );
  const [routineName, setRoutineName] = useState("");
  const [routineGetup, setRoutineGetup] = useState({
    dayroutineID: 1,
    time: "07:00",
  });
  const [routineLunchTime, setRoutineLunchTime] = useState({
    dayroutineID: 2,
    time: "12:00",
  });
  const [routineWorkoutTime, setRoutineWorkoutTime] = useState({
    dayroutineID: 3,
    time: "17:00",
  });
  const [routineBedTime, setRoutineBedTime] = useState({
    dayroutineID: 4,
    time: "22:00",
  });
  const [addDailyRoutineModal, setAddDailyRoutineModal] = useState(false);
  const [shareOnSocialMediaModal, setShareOnSocialMediaModal] = useState(false);

  const [selectedNutritionForm, setSelectedNutritionForm] = useState({});
  const [restDayCalories, setRestDayCalories] = useState(
    allCaloriesData?.["week1"]?.[0]?.calories || 0
  );
  const [trainingDayCalories, setTrainingDayCalories] = useState(
    allCaloriesData?.["week1"]?.[1]?.calories || 0
  );
  const [weekNumber, setWeekNumber] = useState(1);
  const [isCaloriesLoading, setIsCaloriesLoading] = useState(false);
  // const [caloriesDataArray, setCaloriesDataArray] = useState([]);
  // const [lineChartStatus, setLineChartStatus] = useState('');

  const [weekOptions, setWeekOptions] = useState([]);
  const [weekZeroImage, setWeekZeroImage] = useState(null);
  const [selectedWeeklyImage, setSelectedWeeklyImage] = useState(null);
  const [IsSubmitting, setIsSubmitting] = useState(false);
  const [TransactionInstallments, setTransactionInstallments] = useState([]);
  const [GoalWeightValue, SetGoalWeightValue] = useState(0);

  // useEffect(() => {
  //   console.log("usersRelatedToTrainer?.data", usersRelatedToTrainer?.data);
  //   console.log("details", details, userId);
  // }, [usersRelatedToTrainer, userId]);

  useEffect(() => {
    const images = weekWiseImages?.data
      ?.filter((item) => item.weekID !== 0)
      .map((item) => ({
        id: item.weekID,
        label: `Week ${item.weekID}`,
        value: `Week ${item.weekID}`,
        ...item,
      }));
    setWeekZeroImage(weekWiseImages?.data?.find((item) => item.weekID === 0));
    setWeekOptions(images);
  }, [weekWiseImages]);

  useEffect(() => {
    history.push(window.location.pathname);

    dispatch(
      AuthActions.getBodyMeasurementsRequest({
        formData: {
          userID: userId,
        },
        callback: () => null,
      })
    );

    dispatch(
      AuthActions.getWeekWiseImagesRequest({
        formData: {
          userID: userId,
        },
        callback: () => null,
      })
    );

    dispatch(
      AuthActions.getUserWeightWeekWiseRequest({
        formData: {
          userID: userId,
        },
        callback: () => null,
      })
    );

    dispatch(
      DashboardActions.getHabbitRequest({
        payload: {
          userID: userId,
        },
        callback: () => null,
      })
    );

    dispatch(
      DashboardActions.getJournalRequest({
        payload: {
          userID: userId,
        },
        callback: () => null,
      })
    );

    dispatch(
      AuthActions.getUserWeightGoalDataRequest({
        formData: {
          userID: userId,
        },
        callback: (res) => {
          SetGoalWeightValue(res?.data?.goal_weight || 0);
        },
      })
    );

    dispatch(
      DashboardActions.getUserWeightDataRequest({
        payload: {
          userID: +userId,
        },
        callback: null,
      })
    );

    dispatch(
      NutritionActions.getNutritionTypePercentageChartDataRequest({
        formData: {
          nutritiontypeID: clientDetails?.nutritiontypeID,
        },
      })
    );

    // dispatch(
    //   DashboardActions.getUserTransactionRequest({
    //     payload: {
    //       userID: +userId,
    //     },
    //     callback: (res) => {
    //       // userTransaction?.getUserOrderTransactionData
    //       console.log("res?.getUserOrderTransactionData", res);

    //       // getTransactionsAttempts(res?.getUserOrderTransactionData);
    //       setTransactionInstallments(
    //         getTransactionsAttempts(res?.getUserOrderTransactionData)
    //       );
    //     },
    //   })
    // );

    dispatch(
      DashboardActions.getTrainerProfileTempDetailsRequest({
        payload: { trainerID: trainerData?.trainerID },
        callback: () => null,
      })
    );
  }, [dispatch]);

  const getTransactionsAttempts = (data) => {
    const installmentsOne = data?.filter((item) => item?.installment_num === 1);
    const installmentsTwo = data?.filter((item) => item?.installment_num === 2);
    const installmentsThree = data?.filter(
      (item) => item?.installment_num === 3
    );

    const items = [
      getLatestTransactionItem(installmentsOne),
      getLatestTransactionItem(installmentsTwo),
      getLatestTransactionItem(installmentsThree),
    ];
    console.log("filtered Installments---------------", items);
    return items;
  };

  const getLatestTransactionItem = (list) => {
    const paidItem = list?.find((item) => item?.status === "paid");
    return paidItem || list?.find((item) => item?.status === "failed");
  };

  useEffect(() => {
    setSelectedNutritionForm(
      allNutritionTypes?.filter(
        (data) => data.de_name === activeNutritionType?.de_name
      )?.[0] || {}
    );
  }, [allNutritionTypes]);

  useEffect(() => {
    dispatch(
      AuthActions.getInvoicesOfUserByTrainerRequest({
        formData: {
          trainerID: trainerData.trainerID,
          userID: userId,
        },
      })
    );
  }, []);

  useEffect(() => {
    setRestDayCalories(
      allCaloriesData?.["week" + weekNumber]?.[0]?.calories || 0
    );
    setTrainingDayCalories(
      allCaloriesData?.["week" + weekNumber]?.[1]?.calories || 0
    );
    // setLineChartStatus(caloriesData
    //   ? (caloriesData["12"] - caloriesData["1"]).toFixed(2) + " kcal"
    //   : "0 kcal")
    // setCaloriesDataArray([
    //   { label: "1", x: 1, y: caloriesData?.["1"] || 0 },
    //   { label: "2", x: 2, y: caloriesData?.["2"] || 0 },
    //   { label: "3", x: 3, y: caloriesData?.["3"] || 0 },
    //   { label: "4", x: 4, y: caloriesData?.["4"] || 0 },
    //   { label: "5", x: 5, y: caloriesData?.["5"] || 0 },
    //   { label: "6", x: 6, y: caloriesData?.["6"] || 0 },
    //   { label: "7", x: 7, y: caloriesData?.["7"] || 0 },
    //   { label: "8", x: 8, y: caloriesData?.["8"] || 0 },
    //   { label: "9", x: 9, y: caloriesData?.["9"] || 0 },
    //   { label: "10", x: 10, y: caloriesData?.["10"] || 0 },
    //   { label: "11", x: 11, y: caloriesData?.["11"] || 0 },
    //   { label: "12", x: 12, y: caloriesData?.["12"] || 0 },
    // ])
  }, [weekNumber, allCaloriesData]);

  useEffect(() => {
    getCurrentNutritionType(clientDetails?.nutritiontypeID);
    dispatch(
      NutritionActions.getMacroNutrientsDonutRequest({
        nutritiontypeID: clientDetails?.nutritiontypeID,
      })
    );

    dispatch(
      NutritionActions.getAllCaloriesRequest({
        data: {
          nutritionconceptID: clientDetails?.nutritionconceptID,
          nutritiontypeID: clientDetails?.nutritiontypeID,
          aimID: clientDetails?.aimID,
          sex: clientDetails?.sex,
        },
        funcSuccess: () => {},
      })
    );
    dispatch(
      NutritionActions.getAllNutritionTypesRequest({
        aimID: clientDetails?.aimID,
        sex: clientDetails?.sex,
      })
    );
    dispatch(
      AuthActions.getWeightGraphDetailsRequest({
        userID: userId,
      })
    );
    getDailyRoutineData();
    hitCaloriesDataAPI();
  }, [dispatch, clientDetails]);

  const getDailyRoutineData = () => {
    dispatch(
      AuthActions.getDaysRoutineForUserByTrainerRequest({
        nutritionconceptID: clientDetails.nutritionconceptID,
      })
    );
  };

  const values = donutChartData && [
    {
      label: "Kohlenhydrate",
      value: donutChartData.carbohydratesData,
    },
    {
      label: "Fett",
      value: donutChartData.fatData,
    },
    {
      label: "Eiweiß",
      value: donutChartData.proteinData,
    },
  ];

  const clientDetailsTabData = [
    { id: "overview", value: "clientDetails.tab.overview" },
    { id: "calories", value: "clientDetails.tab.calories" },
    // { id: "diet", value: "clientDetails.tab.diet" },
    {
      id: "diet & nutiritionDistribution",
      value: "clientDetails.tab.diet-nutiritionDistribution",
    },
    // {
    //   id: "nutiritionDistribution",
    //   value: "clientDetails.tab.nutiritionDistribution",
    // },
    { id: "dailyRoutine", value: "clientDetails.tab.dailyRoutine" },
    // { id: "weightHistory", value: "clientDetails.tab.weightHistory" },
    { id: "progress", value: "clientDetails.tab.progressTabTitle" },
    { id: "objective", value: "clientDetails.tab.objective" },
    { id: "anamnese", value: "clientDetails.tab.anamnese" },
    {
      id: "TagebuchHabbitTracker",
      value: "clientDetails.tab.TagebuchHabbitTracker",
    },
    {
      id: "EmailManagement",
      value: "clientDetails.tab.EmailManagement",
    },
    {
      id: "Ernahrungskurs",
      value: "clientDetails.tab.Ernahrungskurs",
    },
    {
      id: "invoices",
      value: "clientDetails.tab.invoices",
    },
    {
      id: "invoice-details",
      value: "clientDetails.tab.invoiceDetails",
    },
    // {
    //   id: "credits",
    //   value: "clientDetails.tab.credits",
    // },
  ];

  const renderClientDetailsHeading = () => {
    if (activeTab === "overview")
      return <TranslationMessage id="clientDetails.tab.overview" />;
    else if (activeTab === "calories")
      return <TranslationMessage id="clientDetails.tab.calories" />;
    else if (activeTab === "diet")
      return <TranslationMessage id="clientDetails.tab.diet" />;
    else if (activeTab === "nutiritionDistribution")
      return (
        <TranslationMessage id="clientDetails.tab.nutiritionDistribution" />
      );
    else if (activeTab === "diet & nutiritionDistribution")
      return (
        <>
          <div></div>
        </>
      );
    else if (activeTab === "dailyRoutine")
      return <TranslationMessage id="clientDetails.tab.dailyRoutine" />;
    else if (activeTab === "weightHistory")
      return <TranslationMessage id="clientDetails.tab.weightHistory" />;
    else if (activeTab === "progress")
      return <TranslationMessage id="clientDetails.tab.progressTabTitle" />;
    else if (activeTab === "objective")
      return <TranslationMessage id="clientDetails.tab.objective" />;
    else if (activeTab === "anamnese")
      return <TranslationMessage id="clientDetails.tab.anamnese" />;
  };

  const renderClientDetailsSubHeading = () => {
    if (activeTab === "overview")
      return <TranslationMessage id="clientDetails.tab.overview.description" />;
    else if (activeTab === "calories")
      return <TranslationMessage id="clientDetails.tab.calories.description" />;
    else if (activeTab === "diet")
      return <TranslationMessage id="clientDetails.tab.diet.description" />;
    else if (activeTab === "nutiritionDistribution")
      return (
        <TranslationMessage id="clientDetails.tab.nutiritionDistribution.description" />
      );
    else if (activeTab === "dailyRoutine")
      return (
        <TranslationMessage id="clientDetails.tab.dailyRoutine.description" />
      );
    else if (activeTab === "weightHistory")
      return (
        <TranslationMessage id="clientDetails.tab.weightHistory.description" />
      );
    else if (activeTab === "progress")
      return <TranslationMessage id="clientDetails.tab.progress.description" />;
  };

  const hitCaloriesDataAPI = () => {
    setIsCaloriesLoading(true);
    dispatch(
      NutritionActions.getWeekWiseCaloriesRequest({
        data: {
          nutritiontypeID: clientDetails.nutritiontypeID,
          aimID: clientDetails.aimID,
          sex: clientDetails.sex,
          user_id: userId,
        },
        funcSuccess: () => {
          setIsCaloriesLoading(false);
        },
      })
    );
  };

  const caloriesDataArray = [
    { label: "1", x: 1, y: caloriesData?.["1"] || 0 },
    { label: "2", x: 2, y: caloriesData?.["2"] || 0 },
    { label: "3", x: 3, y: caloriesData?.["3"] || 0 },
    { label: "4", x: 4, y: caloriesData?.["4"] || 0 },
    { label: "5", x: 5, y: caloriesData?.["5"] || 0 },
    { label: "6", x: 6, y: caloriesData?.["6"] || 0 },
    { label: "7", x: 7, y: caloriesData?.["7"] || 0 },
    { label: "8", x: 8, y: caloriesData?.["8"] || 0 },
    { label: "9", x: 9, y: caloriesData?.["9"] || 0 },
    { label: "10", x: 10, y: caloriesData?.["10"] || 0 },
    { label: "11", x: 11, y: caloriesData?.["11"] || 0 },
    { label: "12", x: 12, y: caloriesData?.["12"] || 0 },
  ];

  const weightDataArray = [
    { label: "1", x: 1, y: userWeightData?.["1"] || 0 },
    { label: "2", x: 2, y: userWeightData?.["2"] || 0 },
    { label: "3", x: 3, y: userWeightData?.["3"] || 0 },
    { label: "4", x: 4, y: userWeightData?.["4"] || 0 },
    { label: "5", x: 5, y: userWeightData?.["5"] || 0 },
    { label: "6", x: 6, y: userWeightData?.["6"] || 0 },
    { label: "7", x: 7, y: userWeightData?.["7"] || 0 },
    { label: "8", x: 8, y: userWeightData?.["8"] || 0 },
    { label: "9", x: 9, y: userWeightData?.["9"] || 0 },
    { label: "10", x: 10, y: userWeightData?.["10"] || 0 },
    { label: "11", x: 11, y: userWeightData?.["11"] || 0 },
    { label: "12", x: 12, y: userWeightData?.["12"] || 0 },
  ];

  const weekWiseWeightList = [
    { label: "1", x: 1, y: weekWiseWeight?.data?.["1"] || 0 },
    { label: "2", x: 2, y: weekWiseWeight?.data?.["2"] || 0 },
    { label: "3", x: 3, y: weekWiseWeight?.data?.["3"] || 0 },
    { label: "4", x: 4, y: weekWiseWeight?.data?.["4"] || 0 },
    { label: "5", x: 5, y: weekWiseWeight?.data?.["5"] || 0 },
    { label: "6", x: 6, y: weekWiseWeight?.data?.["6"] || 0 },
    { label: "7", x: 7, y: weekWiseWeight?.data?.["7"] || 0 },
    { label: "8", x: 8, y: weekWiseWeight?.data?.["8"] || 0 },
    { label: "9", x: 9, y: weekWiseWeight?.data?.["9"] || 0 },
    { label: "10", x: 10, y: weekWiseWeight?.data?.["10"] || 0 },
    { label: "11", x: 11, y: weekWiseWeight?.data?.["11"] || 0 },
    { label: "12", x: 12, y: weekWiseWeight?.data?.["12"] || 0 },
  ];

  console.log("weekWiseWeight", weekWiseWeight);

  const lineChartStatus = caloriesData
    ? (caloriesData["12"] - caloriesData["1"]).toFixed(2) + " kcal"
    : "0 kcal";

  const initialValues = {
    prename: clientDetails?.prename || "",
    surname: clientDetails?.surname || "",
    email: clientDetails?.email || "",
    phoneNumber: clientDetails?.mobile || "",
    startDate: clientDetails?.startdate || "",
    endDate: clientDetails?.enddate || "",
    token: clientDetails?.nutritionconcept_token || "",
  };

  const months = {
    1: "Jan",
    2: "Feb",
    3: "Mar",
    4: "April",
    5: "May",
    6: "Jun",
    7: "Jul",
    8: "Aug",
    9: "Sep",
    10: "Oct",
    11: "Nov",
    12: "Dec",
  };

  const validationSchema = object().shape({
    prename: string().typeError("You must enter a String").required("Required"),
    surname: string().typeError("You must enter a String").required("Required"),
    email: string()
      .email()
      .typeError("You must enter a String")
      .required("Required"),
    phoneNumber: number()
      .typeError("You must enter a Number")
      .required("Required"),
    // startDate: string().typeError("You must enter a String").required("Required"),
    // endDate: string().typeError("You must enter a String").required("Required"),
    // token: string().typeError("You must enter a String").required("Required"),
  });

  const onFormSubmit = (values, { resetForm }) => {
    const formData = new FormData();
    const getUsersFormData = {
      formData: {
        trainerID: trainerData.trainerID,
      },
      page: (pageNumber = 1),
    };
    formData.append("userID", userId);
    formData.append("prename", values.prename);
    formData.append("surname", values.surname);
    formData.append("email", values.email);
    formData.append("mobile", values.phoneNumber);
    // formData.append("startDate", values.startDate);
    // formData.append("endDate", values.endDate);
    // formData.append("token", values.token);
    setIsSubmitting(true);
    const setClientData = () => {
      setClientDetails({
        ...clientDetails,
        prename: values.prename,
        surname: values.surname,
        email: values.email,
        mobile: values.phoneNumber,
      });
      setIsSubmitting(false);
    };
    dispatch(
      AuthActions.updateUserDetailsByTrainerRequest({
        formData,
        getUsersFormData,
        func: setClientData,
      })
    );
  };

  // Calories

  const handleRightNavigation = () => {
    setEdit(false);
    if (weekNumber !== 12) setWeekNumber(weekNumber + 1);
  };
  const handleLeftNavigation = () => {
    setEdit(false);
    if (weekNumber !== 1) setWeekNumber(weekNumber - 1);
  };

  const handleCaloriesUpdate = () => {
    dispatch(
      NutritionActions.updateNutritionCaloriesRequest({
        payload: {
          nutritiontypeID: clientDetails.nutritiontypeID,
          nutritionconceptID: clientDetails.nutritionconceptID,
          aimID: clientDetails.aimID,
          sex: clientDetails.sex,
          week: weekNumber,
          caloriesData: [
            { daytypeID: 1, calories: restDayCalories },
            { daytypeID: 2, calories: trainingDayCalories },
          ],
        },
        callback: () => {
          hitCaloriesDataAPI();
          dispatch(
            NutritionActions.getAllCaloriesRequest({
              data: {
                nutritionconceptID: clientDetails.nutritionconceptID,
                nutritiontypeID: clientDetails.nutritiontypeID,
                aimID: clientDetails.aimID,
                sex: clientDetails.sex,
              },
              funcSuccess: () => {},
            })
          );
        },
      })
    );
  };

  // Nutirition Type

  const getCurrentNutritionType = (id) =>
    dispatch(
      NutritionActions.getNutritionTypeRequest({
        nutritiontypeID: id,
        aimID: clientDetails.aimID,
        sex: clientDetails.sex,
      })
    );

  const handleNutritionTypeChange = () => {
    dispatch(
      NutritionActions.updateNutritionTypeRequest({
        nutritiontypeID: selectedNutritionForm.nutritiontypeID,
        aimID: clientDetails.aimID,
        sex: clientDetails.sex,
        nutritionconceptID: clientDetails.nutritionconceptID,
      })
    );
    getCurrentNutritionType(selectedNutritionForm.nutritiontypeID);
    dispatch(
      AuthActions.setRegistrationData({
        ...clientDetails,
        nutritiontypeID: selectedNutritionForm.nutritiontypeID,
        userID: userId,
      })
    );
  };

  // Daily Routine

  const buttonNeeded = () => {
    const showButtonsForTabs = [
      "overview",
      "calories",
      "diet",
      "dailyRoutine",
      "objective",
    ];
    // if (activeTab === "nutiritionDistribution" || activeTab === "weightHistory")
    //   return false
    // else return true
    return showButtonsForTabs.includes(activeTab);
  };

  const formatTimeValue = (time) => moment(time).format("hh:mm");

  const defaultAddDailyRoutineData = [...Data.AddDailyRoutineValues];

  const AddDailyRoutineValues = [
    {
      id: "input.label.getup",
      hrs: selectedDailyRoutineTab?.dayroutines?.[0]?.time,
    },
    {
      id: "input.label.lunchTime",
      hrs: selectedDailyRoutineTab?.dayroutines?.[1]?.time,
    },
    {
      id: "input.label.workoutTime",
      hrs: selectedDailyRoutineTab?.dayroutines?.[2]?.time,
    },
    {
      id: "input.label.bedTime",
      hrs: selectedDailyRoutineTab?.dayroutines?.[3]?.time,
    },
  ];

  const handleTimeChange = (time, TimeId) => {
    const timeValue = formatTimeValue(time);
    switch (TimeId) {
      case "input.label.getup":
        setRoutineGetup({ dayroutineID: 1, time: timeValue });
        break;
      case "input.label.lunchTime":
        setRoutineLunchTime({ dayroutineID: 2, time: timeValue });
        break;
      case "input.label.workoutTime":
        setRoutineWorkoutTime({ dayroutineID: 3, time: timeValue });
        break;
      case "input.label.bedTime":
        setRoutineBedTime({ dayroutineID: 4, time: timeValue });
        break;
      default:
        return false;
    }
  };

  const resetAddRoutineData = () => {
    setRoutineName("");
    setRoutineGetup({ dayroutineID: 1, time: "07:00" });
    setRoutineLunchTime({ dayroutineID: 2, time: "12:00" });
    setRoutineWorkoutTime({ dayroutineID: 3, time: "17:00" });
    setRoutineBedTime({ dayroutineID: 4, time: "22:00" });
  };

  const handleAddRoutine = () => {
    const uniqueId = "id" + new Date().getTime();
    const newRoutine = {
      id: uniqueId,
      heading: routineName,
      data: [
        routineGetup["time"],
        routineLunchTime["time"],
        routineWorkoutTime["time"],
        routineBedTime["time"],
      ],
    };
    const newRoutineData = {
      [newRoutine.heading]: [
        { dayroutineID: 1, time: newRoutine.data[0] },
        { dayroutineID: 2, time: newRoutine.data[1] },
        { dayroutineID: 3, time: newRoutine.data[2] },
        { dayroutineID: 4, time: newRoutine.data[3] },
      ],
    };
    resetAddRoutineData();
    setAddDailyRoutineModal(false);

    dispatch(
      AuthActions.addDayRoutineForUserByTrainerRequest({
        data: {
          nutritionconceptID: clientDetails.nutritionconceptID,
          routines: newRoutineData,
        },
        funcSuccess: getDailyRoutineData,
      })
    );
    getDailyRoutineData();
  };

  const handleEditDailyRoutine = () => {
    let selectedRoutineIdx = 0;
    const routines = userDailyRoutineData.map((routine, idx) => {
      if (routine.routinename === selectedDailyRoutineTab.routinename) {
        selectedRoutineIdx = idx;
        return {
          id: routine.id,
          routinename: routineName,
          dayroutines: [
            routineGetup,
            routineLunchTime,
            routineWorkoutTime,
            routineBedTime,
          ],
        };
      }
      return {
        id: routine.id,
        routinename: routine.routinename,
        dayroutines: routine.dayroutines.map((routineType) => {
          return {
            dayroutineID: routineType.dayroutineID,
            time: routineType.time,
          };
        }),
      };
    });

    dispatch(
      AuthActions.updateDaysRoutineForUserByTrainerRequest({
        data: {
          nutritionconceptID: clientDetails.nutritionconceptID,
          routines: routines,
        },
        funcSuccess: (updatedData) => {
          setSelectedDailyRoutineTab(0);
          setSelectedDailyRoutineTab(
            updatedData[0].routines[selectedRoutineIdx]
          );
          setAddDailyRoutineModal(false);
          setIsRoutineEdit(false);
        },
      })
    );
  };

  const handleDeleteDailyRoutine = () => {
    dispatch(
      AuthActions.deleteDayRoutineForUserByTrainerRequest({
        data: {
          nutritionconceptID: clientDetails.nutritionconceptID,
          routineId: selectedDailyRoutineTab.id,
        },
        funcSuccess: getDailyRoutineData,
      })
    );
    getDailyRoutineData();
  };

  const handleSave = () => {
    // setEdit(false);
    if (activeTab === "overview") {
      formSubmitRef.current.click();
    } else if (activeTab === "calories") {
      handleCaloriesUpdate();
    } else if (activeTab === "diet") {
      handleNutritionTypeChange();
    }
  };

  const renderBothHeadings = () => (
    <>
      <S.ClientDetailsContentHeading>
        {renderClientDetailsHeading()}
      </S.ClientDetailsContentHeading>
      <S.ClientDetailsContentSubHeading>
        {renderClientDetailsSubHeading()}
      </S.ClientDetailsContentSubHeading>
    </>
  );

  const copyToClipBoard = (text) => {
    navigator.clipboard.writeText(text);
  };

  const updateGoalWeight = () => {
    console.log("updateGoalWeight", GoalWeightValue);
    dispatch(
      DashboardActions.updateGoalWeightRequest({
        payload: {
          userID: userId,
          goal_weight: GoalWeightValue,
        },
        callback: () => {
          dispatch(
            AuthActions.getUserWeightGoalDataRequest({
              formData: {
                userID: userId,
              },
              callback: () => null,
            })
          );
        },
      })
    );
  };

  return (
    <>
      <S.ClientDetailsOverallContainer>
        <S.ClientDetailsContainer>
          <div className="second-sidebar-wrapper">
            <S.ClientDetailsTabContainer>
              <S.InnerMenuTop>
                <S.BackButton
                  onClick={() => history.push("/dashboard/customers/list")}
                >
                  <NavigateBeforeIcon />
                  <span>Klientenübersicht</span>
                </S.BackButton>
              </S.InnerMenuTop>

              <S.InnerMenuMid>
                <div className="user-profile">
                  <Avatar
                    alt={clientDetails?.prename}
                    src="/static/images/avatar/1.jpg"
                  />
                  <S.InnerMenuInfo>
                    <Typography variant="h5">
                      {`${clientDetails?.prename || "-"} ${
                        clientDetails?.surname || "-"
                      }`}
                    </Typography>
                    <Typography paragraph>
                      <span>User-ID: </span>
                      {`${userId}`}
                    </Typography>
                  </S.InnerMenuInfo>
                </div>
                <S.PaymentInfo>
                  <p>Bezahlstatus:</p>
                  <div className="dots">
                    {console.log(
                      Object.keys(userInvoice).length,
                      "userInvoice"
                    )}
                    <>
                      {userInvoice &&
                        Object.keys(userInvoice).length > 0 &&
                        Object.entries(userInvoice).map(([key, value]) => {
                          console.log(value, "checkingValue");
                          return (
                            <>
                              {value?.total_invoice === 3 && (
                                <>
                                  {Object.entries(userInvoice).length === 1 && (
                                    <>
                                      {value.payment_status === "Completed" && (
                                        <div className="dot green"></div>
                                      )}
                                      {value.payment_status ===
                                        "Incomplete" && (
                                        <div className="dot orange"></div>
                                      )}
                                      {value.payment_status === "Canceled" && (
                                        <div className="dot red"></div>
                                      )}
                                      {value.payment_status === "Refunded" && (
                                        <div className="dot red"></div>
                                      )}
                                      {value.payment_status ===
                                        "Processing" && (
                                        <div className="dot orange"></div>
                                      )}
                                      {value.payment_status === "Pending" && (
                                        <div className="dot grey"></div>
                                      )}
                                      <div className="dot grey"></div>
                                      <div className="dot grey"></div>
                                    </>
                                  )}

                                  {Object.entries(userInvoice).length === 2 && (
                                    <>
                                      {value.payment_status === "Completed" && (
                                        <div className="dot green"></div>
                                      )}
                                      {value.payment_status ===
                                        "Incomplete" && (
                                        <div className="dot orange"></div>
                                      )}
                                      {value.payment_status === "Canceled" && (
                                        <div className="dot red"></div>
                                      )}
                                      {value.payment_status === "Refunded" && (
                                        <div className="dot red"></div>
                                      )}
                                      {value.payment_status ===
                                        "Processing" && (
                                        <div className="dot orange"></div>
                                      )}
                                      {value.payment_status === "Pending" && (
                                        <div className="dot grey"></div>
                                      )}
                                      {key > 0 ? (
                                        <div className="dot grey"></div>
                                      ) : (
                                        <></>
                                      )}
                                    </>
                                  )}

                                  {Object.entries(userInvoice).length === 3 && (
                                    <>
                                      {value.payment_status === "Completed" && (
                                        <div className="dot green"></div>
                                      )}
                                      {value.payment_status ===
                                        "Incomplete" && (
                                        <div className="dot orange"></div>
                                      )}
                                      {value.payment_status === "Canceled" && (
                                        <div className="dot red"></div>
                                      )}
                                      {value.payment_status === "Refunded" && (
                                        <div className="dot red"></div>
                                      )}
                                      {value.payment_status ===
                                        "Processing" && (
                                        <div className="dot orange"></div>
                                      )}
                                      {value.payment_status === "Pending" && (
                                        <div className="dot grey"></div>
                                      )}
                                    </>
                                  )}
                                </>
                              )}
                              {value?.total_invoice === 1 && (
                                <>
                                  {value.payment_status === "Completed" && (
                                    <>
                                      <div className={"dot green"}></div>
                                      <div className={"dot green"}></div>
                                      <div className={"dot green"}></div>
                                    </>
                                  )}

                                  {value.payment_status === "Incomplete" && (
                                    <>
                                      <div className={"dot orange"}></div>
                                      <div className={"dot orange"}></div>
                                      <div className={"dot orange"}></div>
                                    </>
                                  )}

                                  {value.payment_status === "Canceled" && (
                                    <>
                                      <div className={"dot red"}></div>
                                      <div className={"dot red"}></div>
                                      <div className={"dot red"}></div>
                                    </>
                                  )}

                                  {value.payment_status === "Refunded" && (
                                    <>
                                      <div className={"dot red"}></div>
                                      <div className={"dot red"}></div>
                                      <div className={"dot red"}></div>
                                    </>
                                  )}

                                  {value.payment_status === "Processing" && (
                                    <>
                                      <div className={"dot orange"}></div>
                                      <div className={"dot orange"}></div>
                                      <div className={"dot orange"}></div>
                                    </>
                                  )}

                                  {value.payment_status === "Pending" && (
                                    <>
                                      <div className={"dot grey"}></div>
                                      <div className={"dot grey"}></div>
                                      <div className={"dot grey"}></div>
                                    </>
                                  )}
                                </>
                              )}
                            </>
                          );
                        })}
                      {userInvoice && Object.keys(userInvoice).length == 0 && (
                        <>
                          <div className="dot grey"></div>
                          <div className="dot grey"></div>
                          <div className="dot grey"></div>
                        </>
                      )}

                      {/* {TransactionInstallments?.length > 0 &&
                        TransactionInstallments?.map((t, i) => (
                          <>
                            {t?.status === "paid" && (
                              <div
                                className={"dot green"}
                                key={i + t?.created_at}
                              ></div>
                            )}
                            {t?.status === "failed" && (
                              <div
                                className={"dot orange"}
                                key={i + t?.created_at}
                              ></div>
                            )}
                            {!t && (
                              <div className={"dot grey"} key={i + 2 + 7}></div>
                            )}
                          </>
                        ))} */}
                      {/* {userTransaction?.getUserOrderTransactionData?.map(
                        (item, i) => (
                          <>
                            {item?.installment_num === 1 &&
                              item?.status === "paid" && (
                                
                              )}
                            {item?.installment_num === 1 &&
                              ["unpaid", "failed"].includes(item?.status) && (
                                <div className={"dot orange"} key={i}></div>
                              )}

                            {item?.installment_num === 1 &&
                              item?.status === "paid" && (
                                <div className={"dot green"} key={i}></div>
                              )}
                            {item?.installment_num === 1 &&
                              item?.status === "paid" && (
                                <div className={"dot green"} key={i}></div>
                              )}
                          </>
                        )
                      )} */}
                      {/* {userTransaction?.made_payments && (
                        <>
                          {new Array(userTransaction?.made_payments || 0)
                            .fill(0)
                            .map((i) => (
                              <div className="dot green" key={i}></div>
                            ))}
                          {new Array(userTransaction?.open_payments || 0)
                            .fill(0)
                            .map((i) => (
                              <div className="dot orange" key={i}></div>
                            ))}
                        </>
                      )} */}

                      {/* {!userTransaction?.made_payments &&
                        userTransaction?.made_payments !== 0 && (
                          <>
                            <div className="dot grey"></div>
                            <div className="dot grey"></div>
                            <div className="dot grey"></div>
                          </>
                        )} */}
                    </>
                  </div>
                </S.PaymentInfo>
              </S.InnerMenuMid>
              <S.InnerMenuLast>
                {clientDetailsTabData.map((tab) => (
                  <S.ClientDetailsTab
                    className={tab?.id}
                    key={tab?.id}
                    active={activeTab === tab?.id}
                    onClick={() => {
                      setActiveTab(tab?.id);
                      // setEdit(false);
                      history.push(window.location.pathname);
                    }}
                  >
                    <TranslationMessage id={tab?.value} />
                  </S.ClientDetailsTab>
                ))}
              </S.InnerMenuLast>
            </S.ClientDetailsTabContainer>
          </div>
          <div className="second-sidebar-content" style={{ padding: "1.5rem" }}>
            {activeTab === "overview" && (
              <>
                <S.ClientDetailsContentContainer>
                  {renderBothHeadings()}
                  <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onFormSubmit}
                    enableReinitialize
                  >
                    {({
                      handleSubmit,
                      handleChange,
                      handleBlur,
                      values,
                      touched,
                      errors,
                      isValid,
                      dirty,
                      isSubmitting,
                    }) => (
                      <form
                        noValidate
                        autoComplete="off"
                        onSubmit={handleSubmit}
                      >
                        <>
                          {/* <button
                        type="submit"
                        style={{ display: "none" }}
                        ref={formSubmitRef}
                      /> */}
                          <S.ClientDetailsOverviewContainer>
                            <S.ClientDetailsOverviewInnerContainer>
                              <S.ClientDetailsOverviewInputContainer>
                                <div>
                                  <S.ClientDetailsOverviewInputLabel>
                                    {intl.formatMessage({
                                      id: "clientDetails.tab.overview.prename",
                                    })}
                                  </S.ClientDetailsOverviewInputLabel>
                                  <Input
                                    disabled={!edit}
                                    style={{ width: "100%" }}
                                    placeholder="prename"
                                    name="prename"
                                    onChange={handleChange}
                                    value={values.prename}
                                  />
                                </div>
                                <div>
                                  <S.ClientDetailsOverviewInputLabel>
                                    {intl.formatMessage({
                                      id: "clientDetails.tab.overview.surname",
                                    })}
                                  </S.ClientDetailsOverviewInputLabel>
                                  <Input
                                    style={{ width: "100%" }}
                                    placeholder="surname"
                                    name="surname"
                                    onChange={handleChange}
                                    value={values.surname}
                                  />
                                </div>
                                {/* <div>
                                  <S.ClientDetailsOverviewInputLabel>
                                    {intl.formatMessage({
                                      id: "clientDetails.tab.overview.email",
                                    })}
                                  </S.ClientDetailsOverviewInputLabel>
                                  <Input
                                    style={{ width: "100%" }}
                                    placeholder="email"
                                    name="email"
                                    onChange={handleChange}
                                    value={values.email}
                                  />
                                </div> */}
                                <S.FormControl>
                                  <S.ClientDetailsOverviewInputLabel className="info-label">
                                    {intl.formatMessage({
                                      id: "clientDetails.tab.overview.email",
                                    })}
                                  </S.ClientDetailsOverviewInputLabel>
                                  <Input
                                    className="input-custom copy-text"
                                    filled
                                    style={{ width: "100%" }}
                                    disabled
                                    placeholder="email"
                                    name="email"
                                    onChange={handleChange}
                                    value={values.email}
                                  />
                                  <img
                                    src={CopyIcon}
                                    alt="CopyIcon"
                                    onClick={() =>
                                      copyToClipBoard(values.email)
                                    }
                                  />
                                </S.FormControl>
                                <div>
                                  <S.ClientDetailsOverviewInputLabel>
                                    {intl.formatMessage({
                                      id: "clientDetails.tab.overview.phoneNumber",
                                    })}
                                  </S.ClientDetailsOverviewInputLabel>
                                  <Input
                                    style={{ width: "100%" }}
                                    placeholder="phoneNumber"
                                    name="phoneNumber"
                                    onChange={handleChange}
                                    value={values.phoneNumber}
                                  />
                                </div>
                                <div>
                                  <S.ClientDetailsOverviewInputLabel>
                                    {intl.formatMessage({
                                      id: "clientDetails.tab.overview.startDate",
                                    })}
                                  </S.ClientDetailsOverviewInputLabel>
                                  <Input
                                  className="input-custom copy-text"
                                  placeholder="startDate"
                                  name="startDate"
                                  value={values.startDate ? moment(new Date(values.startDate)).format('DD-MM-YYYY') : 'none'}
                                  filled
                                  disabled
                                />
                                </div>
                                <div>
                                  <S.ClientDetailsOverviewInputLabel>
                                    {intl.formatMessage({
                                      id: "clientDetails.tab.overview.endDate",
                                    })}
                                  </S.ClientDetailsOverviewInputLabel>
                                  <Input
                                  className="input-custom copy-text"
                                  placeholder="endDate"
                                  name="endDate"
                                  value={values.endDate ? moment(new Date(values.endDate)).format('DD-MM-YYYY') : 'none'}
                                  filled
                                  disabled
                                />
                                </div>
                              </S.ClientDetailsOverviewInputContainer>
                              {/* <div>
                                <S.ClientDetailsOverviewInputLabel>
                                  {intl.formatMessage({
                                    id: "clientDetails.tab.overview.token",
                                  })}
                                </S.ClientDetailsOverviewInputLabel>
                                <Input
                                  style={{ width: "100%" }}
                                  placeholder="token"
                                  name="token"
                                  onChange={handleChange}
                                  value={values.token}
                                />
                              </div> */}

                              <S.FormControl>
                                <S.ClientDetailsOverviewInputLabel className="info-label">
                                  {intl.formatMessage({
                                    id: "clientDetails.tab.overview.token",
                                  })}
                                </S.ClientDetailsOverviewInputLabel>
                                <Input
                                  className="input-custom copy-text"
                                  style={{ width: "100%" }}
                                  placeholder="token"
                                  name="token"
                                  onChange={handleChange}
                                  value={values.token}
                                  filled
                                  disabled
                                />
                                <img
                                  src={CopyIcon}
                                  alt="CopyIcon"
                                  onClick={() => copyToClipBoard(values.token)}
                                />
                              </S.FormControl>
                            </S.ClientDetailsOverviewInnerContainer>
                          </S.ClientDetailsOverviewContainer>
                          <S.ClientDetailsSaveButton>
                            <Button
                              color="#FFFFFF"
                              gradient="linear-gradient(99deg, #2ECF96 0%, #1C9D87 100%) !important"
                              margin="1rem 0 0 0"
                              absolute={{
                                width: "auto",
                                justifyContent: "start",
                              }}
                              type="submit"
                              disabled={IsSubmitting}
                            >
                              Speichern
                            </Button>
                          </S.ClientDetailsSaveButton>
                        </>
                      </form>
                    )}
                  </Formik>
                </S.ClientDetailsContentContainer>
              </>
            )}
            {activeTab === "calories" && (
              <S.ClientDetailsContentContainer>
                {renderBothHeadings()}
                <S.ClientDetailsCaloriesContainer>
                  <S.ClientDetailsWeekNavigationContainer>
                    <S.ClientDetailsCaloriesNavigationIconContainer
                      onClick={handleLeftNavigation}
                    >
                      <img src={LeftIcon} alt="left Icon" />
                    </S.ClientDetailsCaloriesNavigationIconContainer>
                    <S.ClientDetailsEditCaloriesWeekHeading>
                      <TranslationMessage id="nutrition.formCalories.editCalories.week" />{" "}
                      {weekNumber}
                    </S.ClientDetailsEditCaloriesWeekHeading>
                    <S.ClientDetailsCaloriesNavigationIconContainer
                      onClick={handleRightNavigation}
                    >
                      <img src={RightIcon} alt="Right Icon" />
                    </S.ClientDetailsCaloriesNavigationIconContainer>
                  </S.ClientDetailsWeekNavigationContainer>
                  <S.ClientDetailsIncrementInputContainer>
                    {
                      <>
                        <S.ClientDetailsIncrementInputCard>
                          <S.ClientDetailsIncrementInputCardHeading>
                            {intl.formatMessage({ id: "input.label.restDay" })}
                          </S.ClientDetailsIncrementInputCardHeading>
                          <IncrementInput
                            width="200"
                            placeholder="0 kcal"
                            id="restDaykcal"
                            inputMeasure="kcal"
                            defaultValue={parseInt(restDayCalories)}
                            value={parseInt(restDayCalories)}
                            setData={setRestDayCalories}
                          />
                        </S.ClientDetailsIncrementInputCard>
                        <S.ClientDetailsIncrementInputCard>
                          <S.ClientDetailsIncrementInputCardHeading>
                            {intl.formatMessage({
                              id: "input.label.trainingDay",
                            })}
                          </S.ClientDetailsIncrementInputCardHeading>
                          <IncrementInput
                            width="200"
                            placeholder="0 kcal"
                            id="trainingDaykcal"
                            inputMeasure="kcal"
                            defaultValue={parseInt(trainingDayCalories)}
                            value={parseInt(trainingDayCalories)}
                            setData={setTrainingDayCalories}
                          />
                        </S.ClientDetailsIncrementInputCard>
                      </>
                    }
                  </S.ClientDetailsIncrementInputContainer>
                  <S.ClientDetailsCaloriesLineChartContainer>
                    <Loader loading={isCaloriesLoading} top="110px" />
                    {!isCaloriesLoading && (
                      <LineChart
                        className="custom-chart"
                        heading="Grundumsatz"
                        status={lineChartStatus}
                        description="in 12 Wochen"
                        fontSize={10}
                        precision={0}
                        padding={20}
                        width={420}
                        height={130}
                        data={caloriesDataArray}
                        horizontalGuideLines={3}
                        yAxisBetweenRange
                      />
                    )}
                  </S.ClientDetailsCaloriesLineChartContainer>
                </S.ClientDetailsCaloriesContainer>
                <S.ClientDetailsSaveButton>
                  <Button
                    color="#FFFFFF"
                    gradient="linear-gradient(99deg, #2ECF96 0%, #1C9D87 100%) !important"
                    margin="1rem 0 0 0"
                    absolute={{
                      width: "auto",
                      justifyContent: "start",
                    }}
                    onClick={handleCaloriesUpdate}
                  >
                    Speichern
                  </Button>
                </S.ClientDetailsSaveButton>
              </S.ClientDetailsContentContainer>
            )}
            {/* {activeTab === "diet" && (
             
                
              </S.ClientDetailsContentContainer>
            )} */}
            {activeTab === "diet & nutiritionDistribution" && (
              <>
                <S.ClientDetailsContentContainer>
                  <S.ClientDetailsContentHeading>
                    Ernährungsform
                  </S.ClientDetailsContentHeading>
                  {/* {!loading && ( */}
                  <>
                    <S.ClientDetailsNutritionFormContainer>
                      {allNutritionTypes &&
                        allNutritionTypes.map((value) => {
                          return (
                            <S.ClientDetailsNutritionFormCard
                              key={value.nutritiontypeID}
                              onClick={() =>
                                edit && setSelectedNutritionForm(value)
                              }
                              selected={
                                value.nutritiontypeID ===
                                selectedNutritionForm.nutritiontypeID
                              }
                            >
                              <S.ClientDetailsNutritionCardImage
                                src={
                                  value.nutritiontypeID ===
                                  selectedNutritionForm.nutritiontypeID
                                    ? LowCarbImage
                                    : LowCarbNotSelectedImage
                                }
                                alt={value.nutritiontypeID}
                              />
                              <span>{value["de_name"]}</span>
                            </S.ClientDetailsNutritionFormCard>
                          );
                        })}
                    </S.ClientDetailsNutritionFormContainer>
                  </>
                  {/* )} */}
                  <S.ClientDetailsSaveButton>
                    <Button
                      color="#FFFFFF"
                      gradient="linear-gradient(99deg, #2ECF96 0%, #1C9D87 100%) !important"
                      margin="1rem 0 0 0"
                      absolute={{
                        width: "auto",
                        justifyContent: "start",
                      }}
                      onClick={handleNutritionTypeChange}
                      disabled={loading}
                    >
                      Speichern
                    </Button>
                  </S.ClientDetailsSaveButton>
                </S.ClientDetailsContentContainer>
                <br />
                <S.ClientDetailsContentContainer>
                  <S.ClientDetailsContentHeading>
                    Nährwertverteilung
                  </S.ClientDetailsContentHeading>
                  <S.ClientDetailsDonutContainer className="client-details-donut-container">
                    {!loading && (
                      <DonutChart
                        series={[
                          {
                            label: "Kohlenhydrate",
                            value:
                              nutritionTypePercentageData?.[0]
                                ?.percentage_carbs,
                          },
                          {
                            label: "Fett",
                            value:
                              nutritionTypePercentageData?.[0]?.percentage_fat,
                          },
                          {
                            label: "Eiweiß",
                            value:
                              nutritionTypePercentageData?.[0]
                                ?.percentage_protein,
                          },
                        ]}
                        title="TRAININGSFREIER TAG"
                      />
                    )}
                    {/* <S.DonutChartLegend>
                      <span>Eiweiß</span>
                      <span>Kohlenhydrate</span>
                      <span>Fett</span>
                    </S.DonutChartLegend> */}
                    {!loading && (
                      <DonutChart
                        series={[
                          {
                            label: "Kohlenhydrate",
                            value:
                              nutritionTypePercentageData?.[1]
                                ?.percentage_carbs,
                          },
                          {
                            label: "Fett",
                            value:
                              nutritionTypePercentageData?.[1]?.percentage_fat,
                          },
                          {
                            label: "Eiweiß",
                            value:
                              nutritionTypePercentageData?.[1]
                                ?.percentage_protein,
                          },
                        ]}
                        title="TRAININGSTAG"
                      />
                    )}
                  </S.ClientDetailsDonutContainer>
                </S.ClientDetailsContentContainer>
              </>
            )}

            {activeTab === "dailyRoutine" && (
              <S.ClientDetailsContentContainer>
                {renderBothHeadings()}
                <S.ClientDetailsDailyRoutineContainer>
                  <S.ClientDetailsDailyRoutineTabContainer>
                    {userDailyRoutineData?.map((tab) => {
                      const isActive =
                        selectedDailyRoutineTab?.routinename ===
                        tab?.routinename;

                      return (
                        <S.ClientDetailsDailyRoutineTab
                          isActive={isActive}
                          key={tab?.routinename}
                          onClick={() => setSelectedDailyRoutineTab(tab)}
                        >
                          <S.ClientDetailsDailyRoutineTabHeading>
                            {tab?.routinename}
                          </S.ClientDetailsDailyRoutineTabHeading>
                          {isActive && (
                            <S.ClientDetailsDailyRoutineTabRightArrowIcon
                              alt="right-icon-tab"
                              src={RightIcon}
                            />
                          )}
                        </S.ClientDetailsDailyRoutineTab>
                      );
                    })}
                    <br />
                    <S.NewRoutine
                      onClick={() => {
                        if (activeTab === "dailyRoutine") {
                          resetAddRoutineData();
                          setIsRoutineEdit(false);
                          setAddDailyRoutineModal(true);
                        } else {
                          setEdit(true);
                        }
                      }}
                    >
                      <ControlPoint /> Neuer Tagesablauf
                    </S.NewRoutine>
                  </S.ClientDetailsDailyRoutineTabContainer>
                  <S.ClientDetailsDailyRoutineTabDetailsContainer>
                    <S.ClientDetailsDailyRoutineTabDetailsInnerContainer>
                      {AddDailyRoutineValues.map((dailyRoutine) => {
                        const newDailyRoutineKey =
                          dailyRoutine?.id + dailyRoutine?.hrs;
                        return (
                          <S.ClientDetailsDailyRoutineTabDetailsHeading
                            key={newDailyRoutineKey}
                          >
                            <TranslationMessage id={dailyRoutine?.id} />
                            <span>{dailyRoutine?.hrs} Uhr</span>
                          </S.ClientDetailsDailyRoutineTabDetailsHeading>
                        );
                      })}
                    </S.ClientDetailsDailyRoutineTabDetailsInnerContainer>
                    <S.ClientDetailsDailyroutineTabDetailsActionButtonsContainer>
                      <S.ClientDetailsDailyroutineEditButton
                        onClick={() => {
                          setIsRoutineEdit(true);
                          setRoutineName(selectedDailyRoutineTab?.routinename);
                          setAddDailyRoutineModal(true);
                        }}
                      >
                        <EditGrey />
                      </S.ClientDetailsDailyroutineEditButton>
                      <S.ClientDetailsDailyroutineDeleteButton
                        onClick={handleDeleteDailyRoutine}
                      >
                        <DeleteIconGrey />
                      </S.ClientDetailsDailyroutineDeleteButton>
                    </S.ClientDetailsDailyroutineTabDetailsActionButtonsContainer>
                  </S.ClientDetailsDailyRoutineTabDetailsContainer>
                </S.ClientDetailsDailyRoutineContainer>
              </S.ClientDetailsContentContainer>
            )}

            {activeTab === "progress" && (
              <>
                <S.ClientDetailsContentContainer>
                  {renderBothHeadings()}
                  <S.TargetDateRange>
                    {months[+clientDetails?.startdate?.substring(5, 7)]}{" "}
                    {clientDetails?.startdate?.substring(0, 4)} -{" "}
                    {months[+clientDetails?.enddate?.substring(5, 7)]}{" "}
                    {clientDetails?.enddate?.substring(0, 4)}
                  </S.TargetDateRange>
                  <LineChart
                    heading="Grundumsatz"
                    status={lineChartStatus}
                    description="in 12 Wochen"
                    fontSize={13}
                    precision={0}
                    padding={50}
                    width={863}
                    height={200}
                    data={weekWiseWeightList}
                    horizontalGuideLines={3}
                    yAxisBetweenRange
                    doNotShowReport
                  />
                  <S.TargetInfo>
                    <S.OrangeLine></S.OrangeLine>
                    <S.TargetInfoTitle>Ziel:</S.TargetInfoTitle>
                    <S.TargetInfoValue>{`${userWeightGoalData?.data?.goal_weight} Kg`}</S.TargetInfoValue>
                  </S.TargetInfo>
                </S.ClientDetailsContentContainer>
                <br />
                <div className="full-width">
                  <S.ClientDetailsContentContainer
                    style={{ minHeight: "auto", width: "100%" }}
                  >
                    <S.ProgressHeadingWrap>
                      <S.ProgressHeading>Körpermaße</S.ProgressHeading>
                      {/* <S.ChipBox>
                    <S.Chip isActive>Aktuell</S.Chip>
                    <S.Chip>Entwicklung</S.Chip>
                  </S.ChipBox> */}
                    </S.ProgressHeadingWrap>
                    <br />
                    <br />
                    <S.ProgressBody>
                      <S.StatsRow style={{ marginBottom: 0 }}>
                        <div></div>
                        <S.StatDate>{bodyMeasurements?.[0]?.date}</S.StatDate>
                        <S.StatProgress>Fortschritt</S.StatProgress>
                      </S.StatsRow>
                      <S.StatsRow style={{ marginBottom: 0 }}>
                        <div></div>
                        <S.StatDate>{bodyMeasurements?.[0]?.date}</S.StatDate>
                        <S.StatProgress>Fortschritt</S.StatProgress>
                      </S.StatsRow>
                    </S.ProgressBody>
                    <S.ProgressBody>
                      {/* <S.StatsCol> */}

                      {bodyMeasurements?.map((stat, i) => (
                        <S.StatsRow key={i}>
                          <S.StatTitleBox>{stat?.name}</S.StatTitleBox>
                          {!stat?.isEmpty && (
                            <S.StatValueBox>{`${stat?.value} cm`}</S.StatValueBox>
                          )}
                          {stat?.isEmpty && <S.StatValueBox>0</S.StatValueBox>}

                          <S.StatCalculationBox>
                            <>
                              {!stat?.isEmpty && (
                                <>
                                  <img
                                    src={
                                      stat?.isEqual
                                        ? ArrowEqualIcon
                                        : stat?.isIncreased
                                        ? ArrowDownIcon
                                        : ArrowUpIcon
                                    }
                                    alt="arrow"
                                  />
                                  <span>
                                    {stat?.valueDifference < 0 &&
                                      `${Math.abs(stat?.valueDifference)}`}
                                    {stat?.valueDifference >= 0 &&
                                      `${stat?.valueDifference}`}
                                  </span>
                                </>
                              )}
                            </>
                          </S.StatCalculationBox>
                        </S.StatsRow>
                      ))}

                      {/* <S.StatsRow>
                    <S.StatTitleBox>Oberarm</S.StatTitleBox>
                    <S.StatValueBox>96 cm</S.StatValueBox>
                    <S.StatCalculationBox>
                      <img src={ArrowDownIcon} alt="arrow" />
                      <span>-2</span>
                    </S.StatCalculationBox>
                  </S.StatsRow> */}
                      {/* </S.StatsCol>
                  <S.StatsCol> */}
                      {/* <S.StatsRow style={{ marginBottom: 0 }}>
                      <div></div>
                      <S.StatDate>19.08.2022</S.StatDate>
                      <S.StatProgress>Fortschritt</S.StatProgress>
                    </S.StatsRow> */}

                      {/* </S.StatsCol> */}
                    </S.ProgressBody>
                  </S.ClientDetailsContentContainer>
                  <S.ClientDetailsContentContainer
                    style={{
                      minHeight: "auto",
                      marginTop: "10px",
                      width: "100%",
                    }}
                  >
                    <S.VisualProgressHeadingWrap>
                      <S.VisualProgressHeading>
                        Vorher / Nachher{" "}
                        <Select
                          classNamePrefix="react-select"
                          options={weekOptions}
                          value={selectedWeeklyImage}
                          onChange={(e) => {
                            setSelectedWeeklyImage(e);
                          }}
                        />
                      </S.VisualProgressHeading>
                      <button
                        className="btn-share"
                        onClick={() => setShareOnSocialMediaModal(true)}
                      >
                        <img src={ShareIcon} alt="share" /> Teilen
                      </button>
                    </S.VisualProgressHeadingWrap>
                    <S.ProgressImages>
                      {weekZeroImage && (
                        <S.ProgressImageItem>
                          <S.StartDay>{weekZeroImage?.weekID} Week</S.StartDay>
                          <img
                            src={getS3ImageUrl(weekZeroImage?.image)}
                            alt="week-zero"
                          />
                        </S.ProgressImageItem>
                      )}

                      {!weekZeroImage && (
                        <S.ProgressImageItem>
                          <S.StartDay>Start</S.StartDay>
                          <img src={UserPlaceholder} alt="week-zero" />
                        </S.ProgressImageItem>
                      )}

                      {selectedWeeklyImage && (
                        <S.ProgressImageItem>
                          <S.Week>{selectedWeeklyImage?.weekID} Week</S.Week>
                          <img
                            src={getS3ImageUrl(selectedWeeklyImage?.image)}
                            alt="selected-week"
                          />
                        </S.ProgressImageItem>
                      )}
                    </S.ProgressImages>
                  </S.ClientDetailsContentContainer>
                </div>
              </>
            )}

            {activeTab === "objective" && (
              <S.ClientDetailsContentContainer>
                <S.DisableSectionOverlay>
                  <S.OverlayContent>
                    {/* <img src={LeftIcon} alt="rocket" /> */}
                    <span>BALD VERFÜGBAR</span>
                  </S.OverlayContent>
                </S.DisableSectionOverlay>
                <S.ClientDetailsContentHeading className="mt-0">
                  {renderClientDetailsHeading()}
                </S.ClientDetailsContentHeading>
                <S.GoalsHeading>FESTGELEGTES ZIEL</S.GoalsHeading>
                <S.Goals>
                  <S.Goal
                    isActive={[2, 3].includes(
                      userWeightDataDashboard?.data?.aimID
                    )}
                  >
                    <img src={LeafIcon} alt="left Icon" />
                    <p>Muskelaufbau</p>
                  </S.Goal>
                  <S.Goal
                    isActive={[1].includes(
                      userWeightDataDashboard?.data?.aimID
                    )}
                  >
                    <img src={LeafIcon} alt="left Icon" />
                    <p>Abnehmen</p>
                  </S.Goal>
                  <S.Goal
                    isActive={[4].includes(
                      userWeightDataDashboard?.data?.aimID
                    )}
                  >
                    <img src={LeafIcon} alt="left Icon" />
                    <p>Gesunde Ernährung</p>
                  </S.Goal>
                </S.Goals>
                <S.FormWrap>
                  <S.FormControl>
                    <label>
                      STARTGEWICHT <small>(Kg)</small>
                    </label>
                    <Input
                      filled
                      type="text"
                      placeholder={`${userWeightDataDashboard?.data?.weight}`}
                      disabled
                    />
                  </S.FormControl>
                  <S.FormControl>
                    <label>
                      ZIELGEWICHT <small>(Kg)</small>
                    </label>
                    <Input
                      filled
                      type="text"
                      placeholder={`50`}
                      value={`${GoalWeightValue}`}
                      onChange={(e) => SetGoalWeightValue(e.target.value)}
                    />
                    <img src={EditLightGrey} alt="" />
                  </S.FormControl>
                </S.FormWrap>
                <S.ClientDetailsSaveButton>
                  <Button
                    color="#FFFFFF"
                    gradient="linear-gradient(99deg, #2ECF96 0%, #1C9D87 100%) !important"
                    margin="1rem 0 0 0"
                    absolute={{
                      width: "auto",
                      justifyContent: "start",
                    }}
                    onClick={() => updateGoalWeight()}
                    disabled={isGoalWeightUpdating}
                  >
                    Speichern
                  </Button>
                </S.ClientDetailsSaveButton>
              </S.ClientDetailsContentContainer>
            )}

            {activeTab === "anamnese" && (
              <S.ClientDetailsContentContainer>
                <S.AnamneseHeading>{renderBothHeadings()}</S.AnamneseHeading>
                <EvaluationOfOverallConditionForCustomers userID={userId} />
              </S.ClientDetailsContentContainer>
            )}

            {activeTab === "TagebuchHabbitTracker" && <DiaryAndHabbitTracker />}
            {activeTab === "EmailManagement" && (
              <EmailManagement clientDetails={clientDetails} />
            )}

            {activeTab === "Ernahrungskurs" && (
              <NutritionCourse clientDetails={clientDetails} />
            )}

            {activeTab === "invoices" && (
              <Invoices
                clientDetails={clientDetails}
                onDetails={() => setActiveTab("invoice-details")}
              />
            )}
            {activeTab === "invoice-details" && (
              <Transfer
                clientDetails={clientDetails}
                onBackClicked={() => setActiveTab("invoices")}
              />
            )}
            {/* {activeTab === "credits" && (
              <Credits clientDetails={clientDetails} />
            )} */}
          </div>
        </S.ClientDetailsContainer>
      </S.ClientDetailsOverallContainer>
      <ShareSocialMediaModal
        show={shareOnSocialMediaModal}
        onClose={() => setShareOnSocialMediaModal(false)}
      />
      <Modal
        show={addDailyRoutineModal}
        setShow={setAddDailyRoutineModal}
        heading={
          <TranslationMessage id="registration.dailyRoutine.popupHeading" />
        }
      >
        <Input
          type="text"
          id="routineName"
          placeholder="Name des Tagesablaufs"
          value={routineName}
          onChange={(e) => setRoutineName(e.target.value)}
        />
        {(isRoutineEdit
          ? AddDailyRoutineValues
          : defaultAddDailyRoutineData
        ).map((value) => {
          return (
            <S.ClientDetailsDailyroutineInputContainer
              key={value?.id + value?.hrs}
            >
              <S.ClientDetailsDailyroutineInputLabel>
                <TranslationMessage id={value?.id} />
              </S.ClientDetailsDailyroutineInputLabel>
              <TimePicker
                value={
                  new Date(
                    2007,
                    11,
                    5,
                    value?.hrs?.substring(0, 2),
                    value?.hrs?.substring(3, 5),
                    17
                  )
                }
                onChange={(time) => {
                  handleTimeChange(time, value?.id);
                  setAddDailyRoutineModal(true);
                }}
              />
            </S.ClientDetailsDailyroutineInputContainer>
          );
        })}
        <Button
          color="rgba(46, 207, 150, .2)"
          gradient="linear-gradient(60deg, rgba(46, 207, 150, 1), rgba(28, 157, 135, 1)) !important"
          margin="30px auto 0px"
          style={{ fontSize: "16px" }}
          onClick={isRoutineEdit ? handleEditDailyRoutine : handleAddRoutine}
          disabled={routineName.length === 0}
        >
          <TranslationMessage id="button.saveChanges" />
        </Button>
      </Modal>
    </>
  );
};

export default ClientDetails;
