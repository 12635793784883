import styled from "styled-components";
import { RegistrationContainer, RegistrationSubHeading, } from 'pages/registration/registration.styles';

export const OverviewBasicContainer = styled(RegistrationContainer)`
    margin-top: 50px;
`;

export const OverviewBasicHeading = styled(RegistrationSubHeading)``;

export const OverviewBasicInnerContainer = styled.span`
    margin: 20px auto 30px;
    display: flex;
    flex-direction: row;
    justify-content: center;
`;

export const OverviewBasicSubHeadingContainer = styled.span`
    display: flex;
    justify-content: space-between;
    width: 290px;
`;

export const OverviewBasicSwitchContainer = styled.span``;

export const OverviewBasicSwitchHeading = styled.span`
    font-family: 'RubikMedium';
    font-size: 16px;
    color: #2ECF96;
    margin-right: 20px;
    line-height: 24px;
`;

export const OverviewBasicFoodiaryPROContainer = styled(OverviewBasicSwitchContainer)``;

export const OverviewBasicFoodiaryText = styled.span`
    color: #A5B5AF;
    line-height: 24px;
`;

export const OverviewBasicPROImage = styled.img`
    margin: 0 0 4px 5px;
`

export const OverBasicDescriptionContainer = styled.div`
    display: flex;
    justify-content: center;
`;

export const OverBasicDescriptionInnerContainer = styled.span`
    width: 574px;
    color: #444444;
    font-weight: 400;
    line-height: 24px;
    font-size: 16px;
`;
