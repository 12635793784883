import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector } from "react-redux";
import {useHistory} from 'react-router-dom';
import * as S from "./overview.styles";
import {getRandomValueInRange} from "configs/utils";

// Components
import Topbar from 'components/topbar/Topbar';
import Switch from 'components/formComponents/switch/Switch';
import Card from 'components/card/Card';
import TranslationMessage from 'configs/translations/TranslationMessage';

// Images
import pro from "assets/images/pro.svg";
import inclusive from "assets/images/inclusive.svg";
import GreenCheck from "assets/images/GreenCheck.svg";

// Redux
import * as SupplementsActions from 'redux/supplements/supplements.actions';
import * as AuthActions from "redux/auth/auth.actions";

const Overview = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const supplementsData = useSelector(state => state.supplements.supplements);
    const healthScoreData = useSelector(state => state.auth.healthScoreData);

    const [foodiaryPro, setFoodiaryPro] = useState(true);
    const [card2Benefits, setCard2benefits] = useState([]);
    const [totalPayment, setTotalPayment] = useState(0);
    const [permanentPayment, setPermanentPayment] = useState(true);
    const [oneTimePayment, setOneTimePayment] = useState(true);

    if(!foodiaryPro) history.push('/overview/basic')
    
    useEffect(() => {
        if(supplementsData.length === 0) dispatch(SupplementsActions.getSupplementsRequest())
        let cardBenefits = [];
        let allPayment = 0;
        supplementsData.map((supplement, index) => {
            if(healthScoreData.supplementsStatus[index]) {
                allPayment = allPayment + supplement.monthly_price;
                cardBenefits.push({ benefit: supplement.name, price: supplement.monthly_price})
            }
            return true;
        })
        setCard2benefits(cardBenefits)
        setTotalPayment(allPayment)
    }, [supplementsData])

    const card1Benefits = [
        "overview.card1.benefits1",
        "overview.card1.benefits2",
        "overview.card1.benefits3",
        "overview.card1.benefits4",
        "overview.card1.benefits5",
    ]

    const paymentTypeOptions = [
        { option: "overview.card2.permanentDelivery", id: "permanent", payment: permanentPayment, setPayment: setPermanentPayment },
        { option: "overview.card2.oneTimePayment", id: 'oneTime', payment: oneTimePayment, setPayment: setOneTimePayment },
    ]

    const getPaymentTypeData = () => {
        if(permanentPayment && oneTimePayment) return 'Monthly payment, One-time payment';
        else if(permanentPayment) return 'Monthly payment';
        else if(oneTimePayment) return 'One-time payment';
    }

    const onNext = () => {
        dispatch(AuthActions.setHealthScoreData({payment_type: getPaymentTypeData()}))
    }

    const onFoodiaryProChange = () => {
        setFoodiaryPro(!foodiaryPro); 
    }

    return (
        <>
            <Topbar prev="/supplements" next="/orderDetails" onNext={onNext} redirect progress={'92%'} percent={getRandomValueInRange(40, 45)} actualCost={healthScoreData.totalSupplementsCost} healthReport onClose={() => history.push('/login')} close />
            <S.OverviewContainer>
                <S.OverviewHeading>
                    <TranslationMessage id="overview.heading" />
                </S.OverviewHeading>
                <S.OverviewInnerContainer>
                    <S.OverviewSubHeadingContainer>
                        <S.OverviewSwitchContainer>
                            <S.OverviewSwitchHeading>Foodiary BASIC</S.OverviewSwitchHeading>
                        </S.OverviewSwitchContainer>
                        <Switch switchedOn={foodiaryPro} onChange={onFoodiaryProChange} />
                        <S.OverviewFoodiaryPROContainer>
                            <S.OverviewFoodiaryText>Foodiary</S.OverviewFoodiaryText>
                            <S.OverviewPROImage src={pro} alt="pro" />
                        </S.OverviewFoodiaryPROContainer>
                    </S.OverviewSubHeadingContainer>
                </S.OverviewInnerContainer>
            </S.OverviewContainer>
            <S.OverviewCardContainer>
                <Card
                    width="387px"
                    height="380px"
                    margin="auto"
                    textAlign="left"
                >
                    <S.OverviewCardInnerContainer>
                        <S.OverviewCardHeading>
                            <TranslationMessage id="overview.card1.heading" />
                            <S.OverviewInclusiveImage src={inclusive} alt="inclusive" />
                        </S.OverviewCardHeading>
                        <S.OverviewCardSubHeading>
                            <TranslationMessage id="overview.card1.subHeading" />
                        </S.OverviewCardSubHeading>
                        {card1Benefits.map((benefit, index) => {
                            return (
                                <S.FoodiaryPROBenefitsContainer key={index}>
                                    <S.GreenTickElementContainer>
                                        <img src={GreenCheck} alt="GreenCheck" />
                                    </S.GreenTickElementContainer>
                                    <S.FoodiaryPROBenefits >
                                        <TranslationMessage id={benefit} />
                                    </S.FoodiaryPROBenefits>
                                </S.FoodiaryPROBenefitsContainer>
                            )
                        })}
                    </S.OverviewCardInnerContainer>
                </Card>
                <Card
                    width="387px"
                    height="380px"
                    margin="auto"
                    textAlign="left"
                >
                    <S.OverviewCardInnerContainer>
                        <S.OverviewCardHeading>
                            <TranslationMessage id="overview.card2.heading" />
                        </S.OverviewCardHeading>
                        <S.OverviewCardSubHeading>
                            <TranslationMessage id="overview.card2.subHeading" />
                        </S.OverviewCardSubHeading>
                        {card2Benefits.map((data, index) => {
                            return (
                                <S.PartnerAdvantageBenefitsContainer key={index}>
                                    <S.PartnerAdvantageBenefits>
                                        <TranslationMessage id={data.benefit} />
                                    </S.PartnerAdvantageBenefits>
                                    <S.PartnerAdvantageBenefitsPrice>
                                        {data.price} €
                                    </S.PartnerAdvantageBenefitsPrice>
                                </S.PartnerAdvantageBenefitsContainer>
                            )
                        })}

                        <S.PartnerAdvantageBenefitsContainer>
                            <S.PartnerAdvantageBenefits >
                                <TranslationMessage id="overview.card2.totalPrice" />
                            </S.PartnerAdvantageBenefits>
                            <S.PartnerAdvantageBenefitsPriceContainer>
                                <S.PartnerAdvantageBenefitsTotalPrice >210,00€</S.PartnerAdvantageBenefitsTotalPrice>
                                <S.PartnerAdvantageBenefitsPrice>{totalPayment} €</S.PartnerAdvantageBenefitsPrice>
                            </S.PartnerAdvantageBenefitsPriceContainer>
                        </S.PartnerAdvantageBenefitsContainer>

                        <S.PaymentTypeContainer>
                            {paymentTypeOptions.map((item, index) => {
                                return (
                                    <S.PaymentTypeOptions key={index}>
                                        <S.PaymentTypeSwitchContainer>
                                            <Switch switchedOn={item.payment} small onChange={() => item.setPayment(!item.payment)} />
                                        </S.PaymentTypeSwitchContainer>
                                        <S.PaymentTypeText >
                                            <TranslationMessage id={item.option} />
                                        </S.PaymentTypeText>
                                    </S.PaymentTypeOptions>
                                )
                            })}
                        </S.PaymentTypeContainer>
                    </S.OverviewCardInnerContainer>
                </Card>
            </S.OverviewCardContainer>
        </>
    )
}

export default Overview