import React, { useState } from "react";
import * as S from "./evaluationOfOverallConditionForm.styles";

import EvaluationFormCard from "components/evaluationFormCard/evaluationFormCard";
import { EVALUATION_ITEMS } from "./EvaluationData";

const EvaluationOfOverallConditionForm = ({ onSelect }) => {
  const [StressLevel, setStressLevel] = useState(null);
  const [BodyShape, setBodyShape] = useState(null);
  const [SportActivity, setSportActivity] = useState(null);
  const [DailyActivity, setDailyActivity] = useState(null);
  const [Smoking, setSmoking] = useState(null);
  const [Alcohol, setAlcohol] = useState(null);
  const [FruitVegetable, setFruitVegetable] = useState(null);
  const [MealFrequency, setMealFrequency] = useState(null);
  const [Drink, setDrink] = useState(null);
  const [Cravings, setCravings] = useState(null);
  const [MoodSwings, setMoodSwings] = useState(null);
  const [FeelingWeak, setFeelingWeak] = useState(null);

  const onItemSelect = (item, formControl) => {
   
    let obj = { ...item };

    if (item?.isNumber) {
      obj.numbers = obj?.numbers.map((i) => {
        i.isActive = false;
        if (i.state === formControl?.state) {
          i.isActive = true;
        }
        return i;
      });
    }

    if (item?.isShape || item?.isEmoji) {
      obj.images = obj?.images.map((i) => {
        i.isActive = false;
        if (i.state === formControl?.state) {
          i.isActive = true;
        }
        return i;
      });
    }

    const allItems = {
      StressLevel,
      BodyShape,
      SportActivity,
      DailyActivity,
      Smoking,
      Alcohol,
      FruitVegetable,
      MealFrequency,
      Drink,
      Cravings,
      MoodSwings,
      FeelingWeak,
    };

    switch (item?.title) {
      case "Stresslevel":
        setStressLevel(obj);
        onSelect({
          ...allItems,
          StressLevel: obj,
        });
        break;
      case "Körperform":
        setBodyShape(obj);
        onSelect({
          ...allItems,
          BodyShape: obj,
        });
        break;
      case "Sportliche Aktivität":
        setSportActivity(obj);
        onSelect({
          ...allItems,
          SportActivity: obj,
        });
        break;
      case "Tägliche Aktivität":
        setDailyActivity(obj);
        onSelect({
          ...allItems,
          DailyActivity: obj,
        });
        break;
      case "Rauchen":
        setSmoking(obj);
        onSelect({
          ...allItems,
          Smoking: obj,
        });
        break;
      case "Alkohol":
        setAlcohol(obj);
        onSelect({
          ...allItems,
          Alcohol: obj,
        });
        break;
      case "Obst & Gemüse":
        setFruitVegetable(obj);
        onSelect({
          ...allItems,
          FruitVegetable: obj,
        });
        break;
      case "Mahlzeitenfrequenz":
        setMealFrequency(obj);
        onSelect({
          ...allItems,
          MealFrequency: obj,
        });
        break;
      case "Trinken":
        setDrink(obj);
        onSelect({
          ...allItems,
          Drink: obj,
        });
        break;
      case "Heißhunger":
        setCravings(obj);
        onSelect({
          ...allItems,
          Cravings: obj,
        });
        break;
      case "Stimmungsschwankungen":
        setMoodSwings(obj);
        onSelect({
          ...allItems,
          MoodSwings: obj,
        });
        break;
      case "Schwächegefühl":
        setFeelingWeak(obj);
        onSelect({
          ...allItems,
          FeelingWeak: obj,
        });
        break;

      default:
        break;
    }
  };

  const getActiveItem = (item) => {
    const Obj = {
      "Stresslevel": StressLevel,
      "Körperform": BodyShape,
      "Sportliche Aktivität": SportActivity,
      "Tägliche Aktivität": DailyActivity,
      "Rauchen": Smoking,
      "Alkohol": Alcohol,
      "Obst & Gemüse": FruitVegetable,
      "Mahlzeitenfrequenz": MealFrequency,
      "Trinken": Drink,
      "Heißhunger": Cravings,
      "Stimmungsschwankungen": MoodSwings,
      "Schwächegefühl": FeelingWeak,
    };
    return Obj[item?.title];
  };

  return (
    <>
      <S.EvaluationContainer>
        <S.EvaluationInnerContainer>
          <S.EvaluationHeading>Anamnese</S.EvaluationHeading>
          <S.EvaluationSubHeading>Dein aktueller Stand</S.EvaluationSubHeading>
          <S.GridView>
            {EVALUATION_ITEMS.map((item) => (
              <EvaluationFormCard
                item={item}
                key={item.id}
                activeItem={getActiveItem(item)}
                onItemSelect={(item, formControl) =>
                  onItemSelect(item, formControl)
                }
              />
            ))}
          </S.GridView>
        </S.EvaluationInnerContainer>
      </S.EvaluationContainer>
    </>
  );
};

export default EvaluationOfOverallConditionForm;
