import axios from 'axios';

const endpoint = 'https://api.tapfiliate.com/1.6/customers/';

async function TapFiliate(payload) {
  axios.post(
    endpoint,
    payload,
    {
      headers: {
        'Api-Key': '3acc178a1eff97b14cc1b12c00222e070a3a1b91',
        'Content-Type': 'application/json',
    },
  })
}

export default TapFiliate;
