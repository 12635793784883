import styled from 'styled-components';

export const BudgetGraphPricingLabelContainer = styled.div`
    font-family: RubikRegular;
    color: rgba(68, 85, 76, .9);
    font-size: 12px;
    position: relative;
`;

export const BudgetGraphLabel1 = styled.span`
    ${props => props.active 
        ? ({
            position: 'absolute',
            left: '0px',
            fontFamily: 'RubikMedium',
            fontSize: '16px',
            bottom: '-20px',
        })
        :  ({
            position: 'absolute',
            left: '20px',
            bottom: '40px',
        })
    } 
`;

export const BudgetGraphLabel2 = styled.span`
    ${props => props.active 
        ? ({
            position: 'absolute',
            left: '270px',
            fontFamily: 'RubikMedium',
            fontSize: '16px',
            bottom: '-20px',
        })
        :  ({
            position: 'absolute',
            left: '275px',
            bottom: '40px',
        })
    }
`;

export const BudgetGraphLabel3 = styled.span`
    ${props => props.active 
        ? ({
            position: 'absolute',
            right: '-10px',
            fontFamily: 'RubikMedium',
            fontSize: '16px',
            bottom: '-20px',
        })
        :  ({
            position: 'absolute',
            right: '10px',
            bottom: '40px',
        })
    }
`;