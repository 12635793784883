import React, {useEffect} from 'react';
import { useHistory } from "react-router-dom";
import * as S from './nutritionCreation.styles';

// Images
import BackdropImage from 'assets/images/backdropWave.svg';

// Video
import CreationVideo from 'assets/videos/creationVideo.mp4';
import Loader from 'components/loader/Loader';
import TranslationMessage from 'configs/translations/TranslationMessage';

const NutritionCreation = () => {
    let history = useHistory();

    useEffect(() => {
        document.body.style.overflowY = "hidden";
        const listenerFunction = () => {
            history.push('/nutrition/nutritionForm')
            document.body.style.overflowY = "auto";
        }
        const video = document.getElementById('myvid');
        video.addEventListener('ended', listenerFunction)
        return () => video.removeEventListener("ended", listenerFunction);
    }, [history])

    return (
        <>
            <Loader top="350px" left="30%"/>
            <S.NutritionCreationTextContainer>
                <TranslationMessage id="nutrition.creation.creating" />
            </S.NutritionCreationTextContainer>
            <S.NutritionBackdropImage src={BackdropImage} alt="Backdrop" />
            <S.NutritionCreationVideo id='myvid' autostart autoPlay src={CreationVideo} preload={'auto'} type="video/mp4" />
        </>
    )
}

export default NutritionCreation;
