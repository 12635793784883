import React from 'react';
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import * as S from './healthScoreDailyBudget.styles';
import {getRandomValueInRange} from "configs/utils";

// Components
import TranslationMessage from 'configs/translations/TranslationMessage';
import Topbar from 'components/topbar/Topbar';
import BudgetGraph from 'components/budgetGraph/BudgetGraph';


function HealthScoreDailyBudget() {
    const history = useHistory();
    const healthScoreData = useSelector(state => state.auth.healthScoreData);

    return (
        <>
            <Topbar prev="/healthscore/evaluation" next="/healthscore/tips" progress={'36%'} percent={getRandomValueInRange(40, 45)} healthReport close onClose={() => history.push('/login')} />
            <S.HealthScoreDailyBudgetContainer>
                <S.HealthScoreDailyBudgetHeading><TranslationMessage id="healthScore.dailyBudget.heading" /></S.HealthScoreDailyBudgetHeading>
                <S.HealthScoreDailyBudgetSubHeading><TranslationMessage id="healthScore.dailyBudget.subHeading" /></S.HealthScoreDailyBudgetSubHeading>
                <S.HealthScoreDailyBudgetGraphContainer>
                    <S.HealthScoreDailyBudgetGraphInnerContainer>
                        <S.HealthScoreDailyBudgetLabelContainer>
                            <span>sehr gesunde Ernährung</span>
                            <span>gesunde Ernährung</span>
                            <span>ausgewogene Ernährung</span>
                        </S.HealthScoreDailyBudgetLabelContainer>
                        <BudgetGraph id={healthScoreData.sliderPosition} />
                    </S.HealthScoreDailyBudgetGraphInnerContainer>
                </S.HealthScoreDailyBudgetGraphContainer>
            </S.HealthScoreDailyBudgetContainer>
        </>
    )
}

export default HealthScoreDailyBudget