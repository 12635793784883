import styled from "styled-components";
import {
  RegistrationContainer,
  RegistrationHeading,
  RegistrationSubHeading,
} from "pages/registration/registration.styles";

export const SubscriptionContainer = styled(RegistrationContainer)``;

export const SubscriptionHeading = styled(RegistrationHeading)``;

export const SubscriptionSubHeading = styled(RegistrationSubHeading)``;

export const SubscriptionCardContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 20px;
  gap: 1.2rem;
`;

export const SubscriptionCardInnerHeadingContainer = styled.div`
  width: 100%;
  height: 103px;
  color: white;
  background-color: ${(props) =>
    props.background ? props.background : "none"};
`;

export const SubscriptionCardInnerHeading = styled.div`
  padding-top: 25px;
  text-align: center;
  font-family: RubikMedium;
  font-size: 20px;
`;

export const SubscriptionCardInnerSubHeading = styled.div`
  padding-top: 10px;
  text-align: center;
  font-family: RubikMedium;
  font-size: 16px;
`;

export const SubscriptionCardInnerDescription = styled.div`
  margin: 30px;
  text-align: center;
  font-family: RubikRegular;
  color: #a5b5af;
  font-size: 16px;
  line-height: 20px;
`;

export const SubscriptionCardInnerBenefitsList = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0 30px;
`;

export const SubscriptionCardInnerBenefitsListItem = styled.div`
  display: flex;
  flex-direction: row;
  width: 260px;
  font-family: RubikRegular;
  font-size: 16px;
  color: #44554c;
  margin-bottom: 15px;
`;

export const SubscriptionCardInnerBenefitsListItemCheck = styled.img`
  padding-right: 10px;
  width: 16px;
`;

export const SubscriptionCardInnerButtonContainer = styled.div`
  margin: 30px;
`;

export const SubscriptionCardInnerButtonIcon = styled.img`
  font-size: 20px;
  margin-right: 10px;
`;

export const SubscriptionSimpleCard = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  background: #ffffff;
  box-shadow: 0px 13px 20px #d6d6d629;
  border-radius: 8px;
  padding: 3.5rem 2rem;
  max-width: 300px;
  cursor: pointer;

  * {
    font-family: RubikRegular;
  }

  h6 {
    color: #142c1f;
    font-size: 1.1rem;
    margin-block: 1rem;
  }

  p {
    color: #44554c;
    font-size: 0.9rem;
    opacity: 0.7;
  }
`;

export const SubscriptionCoachingContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 1.2rem;

  h1 {
    font-weight: 500;
    font-size: 20px;
    line-height: 27px;
    margin: 0 0 6px 0;
    color: #fff;
  }
  h2 {
    font-weight: 500;
    font-size: 16px;
    line-height: 27px;

    margin: 0;
  }
  p {
    font-size: 14px;
    letter-spacing: 0px;

    margin: 0;
    font-weight: 400;
  }
  ul {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin: 0;
    padding: 0;
  }
  li {
    padding: 0 12px;
    margin-bottom: 16px;
  }
  ul li p {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: #142c1f;
    text-align: left;
    font-weight: 400;
    margin: 0;
  }
  p span {
    font-weight: 500;
    font-weight: 500;
  }
  .items_data {
    display: flex;
    align-items: flex-start;
  }
  .items_data img {
    margin-right: 10px;
    margin-top: 5px;
    width: 20px;
  }
  .btn_main {
    max-width: 177px;
    margin: 0 auto;
  }
`;

export const SubscriptionCoachingProContainer = styled.div`
  width: 65%;
  background-color: #fff;
  box-shadow: 0px 13px 20px #d6d6d629;
  border: 3px solid #2ecf96;
  border-radius: 8px;
  overflow: hidden;
  li {
    width: 50%;
  }
`;
export const SubscriptionCoachingProHeading = styled.div`
  background-color: #142c1f;
  padding: 16px;
  h2 {
    color: #2ecf96;
  }
  p {
    color: #ffffffcc;
  }
`;
export const SubscriptionCoachingPlusContainer = styled.div`
  width: 35%;
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
`;
export const SubscriptionCoachingPlusHeading = styled.div`
  background-color: #a5b5af;
  padding: 16px;
  h2 {
    color: #142c1f;
  }
  p {
    color: #142c1f;
  }
`;
export const SubscriptionCoachingContent = styled.div`
  padding: 16px 12px;
  margin-bottom: 18px;
`;
export const SubscriptionCoachingbuttons = styled.div`
  max-width: 177px;
  margin: 0 auto;

  .button_plus {
    background-color: #fff;
    border: 2px solid #1c9d87;
    border-radius: 8px;
    padding: 12px 28px;
    font-weight: 500;
    font-size: 16px;
    color: #1c9d87;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
  }
  .button_pro {
    background-color: #2ecf96;
    color: #fff;
    border-radius: 8px;
    padding: 12px 28px;
    font-weight: 500;
    font-size: 16px;
    text-decoration: none;
    display: flex;
    align-items: center;
    margin-top: 40px;
  }
  .button_pro img {
    margin-right: 10px;
  }
`;
