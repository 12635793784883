import styled from "styled-components";
import { LoginSubHeading } from "pages/login/login.styles";

export const InformationHeading = styled(LoginSubHeading)`
    padding-top: 45px;
`;

export const InformationDescription = styled.div`
    font-size: 16px;
    font-family: "Rubik-Regular";
    color: #44554C;
    margin: 0 0 40px 0;
`;