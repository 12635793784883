import SupplementsActionTypes from "./supplements.types";

/*************************************************/

const INITIAL_STATE = {
  supplements: [],
  trainerLeads: null,
  isTrainerLeadsLoading: false,
  trainerLeadDetails: null,
  isTrainerLeadDetailsLoading: false,
  foodiaryLeads: null,
  isFoodiaryLeadsLoading: false,
  foodiaryLeadDetails: null,
  isFoodiaryLeadDetailsLoading: false,
  claimFoodiaryLead: null,
  isFoodiaryLeadClaiming: false,
  leadUtilization: null,
  isLeadUtilizing: false,

  leadCenterStatus: null,
  isLeadCenterStatusLoading: false,

  leadHistory: null,
  isLeadHistoryLoading: false,

  leadAuthorization: null,
  isLeadAuthorizationLoading: false,
  createLeadCenterLoading: false,
};

/*************************************************/

const supplementsReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case SupplementsActionTypes.GET_SUPPLEMENTS_SUCCESS:
      return {
        ...state,
        supplements: action.payload,
      };
    case SupplementsActionTypes.GET_TRAINER_LEADS_SUCCESS:
      return {
        ...state,
        trainerLeads: action.payload,
      };
    case SupplementsActionTypes.GET_TRAINER_LEADS_LOADING_START:
      return {
        ...state,
        isTrainerLeadsLoading: true,
      };
    case SupplementsActionTypes.GET_TRAINER_LEADS_LOADING_STOP:
      return {
        ...state,
        isTrainerLeadsLoading: false,
      };
    case SupplementsActionTypes.GET_TRAINER_LEAD_DETAILS_SUCCESS:
      return {
        ...state,
        trainerLeadDetails: action.payload,
      };
    case SupplementsActionTypes.GET_TRAINER_LEAD_DETAILS_LOADING_START:
      return {
        ...state,
        isTrainerLeadDetailsLoading: true,
      };
    case SupplementsActionTypes.GET_TRAINER_LEAD_DETAILS_LOADING_STOP:
      return {
        ...state,
        isTrainerLeadDetailsLoading: false,
      };

    case SupplementsActionTypes.GET_FOODIARY_LEADS_SUCCESS:
      return {
        ...state,
        foodiaryLeads: action.payload,
      };
    case SupplementsActionTypes.GET_FOODIARY_LEADS_LOADING_START:
      return {
        ...state,
        isFoodiaryLeadsLoading: true,
      };
    case SupplementsActionTypes.GET_FOODIARY_LEADS_LOADING_STOP:
      return {
        ...state,
        isFoodiaryLeadsLoading: false,
      };

    case SupplementsActionTypes.GET_FOODIARY_LEAD_DETAILS_SUCCESS:
      return {
        ...state,
        foodiaryLeadDetails: action.payload,
      };
    case SupplementsActionTypes.GET_FOODIARY_LEAD_DETAILS_LOADING_START:
      return {
        ...state,
        isFoodiaryLeadDetailsLoading: true,
      };
    case SupplementsActionTypes.GET_FOODIARY_LEAD_DETAILS_LOADING_STOP:
      return {
        ...state,
        isFoodiaryLeadDetailsLoading: false,
      };

    case SupplementsActionTypes.CLAIM_FOODIARY_LEAD_SUCCESS:
      return {
        ...state,
        claimFoodiaryLead: action.payload,
      };
    case SupplementsActionTypes.CLAIM_FOODIARY_LEAD_LOADING_START:
      return {
        ...state,
        isFoodiaryLeadClaiming: true,
      };
    case SupplementsActionTypes.CLAIM_FOODIARY_LEAD_LOADING_STOP:
      return {
        ...state,
        isFoodiaryLeadClaiming: false,
      };

    case SupplementsActionTypes.LEAD_UTILIZATION_SUCCESS:
      return {
        ...state,
        leadUtilization: action.payload,
      };
    case SupplementsActionTypes.LEAD_UTILIZATION_LOADING_START:
      return {
        ...state,
        isLeadUtilizing: true,
      };
    case SupplementsActionTypes.LEAD_UTILIZATION_LOADING_STOP:
      return {
        ...state,
        isLeadUtilizing: false,
      };

    case SupplementsActionTypes.GET_LEAD_CENTER_STATUS_SUCCESS:
      return {
        ...state,
        leadCenterStatus: action.payload,
      };
    case SupplementsActionTypes.GET_LEAD_CENTER_STATUS_LOADING_START:
      return {
        ...state,
        isLeadCenterStatusLoading: true,
      };
    case SupplementsActionTypes.GET_LEAD_CENTER_STATUS_LOADING_STOP:
      return {
        ...state,
        isLeadCenterStatusLoading: false,
      };

    case SupplementsActionTypes.GET_LEAD_AUTHORIZATION_SUCCESS:
      return {
        ...state,
        leadAuthorization: action.payload,
      };
    case SupplementsActionTypes.GET_LEAD_AUTHORIZATION_LOADING_START:
      return {
        ...state,
        isLeadAuthorizationLoading: true,
      };
    case SupplementsActionTypes.GET_LEAD_AUTHORIZATION_LOADING_STOP:
      return {
        ...state,
        isLeadAuthorizationLoading: false,
      };

    case SupplementsActionTypes.GET_LEAD_HISTORY_SUCCESS:
      return {
        ...state,
        leadHistory: action.payload,
      };
    case SupplementsActionTypes.GET_LEAD_HISTORY_LOADING_START:
      return {
        ...state,
        isLeadHistoryLoading: true,
      };
    case SupplementsActionTypes.GET_LEAD_HISTORY_LOADING_STOP:
      return {
        ...state,
        isLeadHistoryLoading: false,
      };
    case SupplementsActionTypes.CREATE_LEAD_CENTER_REQUEST_START:
      return {
        ...state,
        createLeadCenterLoading: true,
      };
    case SupplementsActionTypes.CREATE_LEAD_CENTER_SUCCESS:
      return {
        ...state,
        createLeadCenterLoading: false,
      };
    case SupplementsActionTypes.CREATE_LEAD_CENTER_FAILURE:
      return {
        ...state,
        createLeadCenterLoading: false,
      };
    default:
      return state;
  }
};

/*************************************************/

export default supplementsReducer;

/*************************************************/
