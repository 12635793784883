import styled from "styled-components";
import { RegistrationContainer, RegistrationHeading, RegistrationSubHeading } from 'pages/registration/registration.styles';


export const HealthScoreDailyBudgetContainer = styled(RegistrationContainer)``;

export const HealthScoreDailyBudgetHeading = styled(RegistrationHeading)``;

export const HealthScoreDailyBudgetSubHeading = styled(RegistrationSubHeading)``;

export const HealthScoreDailyBudgetGraphContainer = styled.div`
    background-color: white;
    width: 924px;
    height: 392px;
    margin-top: 20px;
`;

export const HealthScoreDailyBudgetGraphInnerContainer = styled.div`
    margin: 60px 50px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

export const HealthScoreDailyBudgetLabelContainer = styled.div`
    width: 200px;
    font-family: RubikRegular;
    color: #44554C;
    font-size: 16px;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    text-align: left;

    & > span {
        margin-bottom: 50px;
    }
`;