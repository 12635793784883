import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useHistory } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Logo from "assets/images/logo.svg";
// import Logo from "assets/images/new-dashboard/FoodiaryIconKopie.svg";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import SettingsIcon from "@material-ui/icons/Settings";
import Link from "@material-ui/core/Link";

import Modal from "components/modal/Modal";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ErrorMessageIcon from "assets/images/error-message.png";
import CrossIcon from "assets/images/cross.svg";
// import MenuIcon from 'assets/images/menu.svg';
import { MenuItems, SidebarSocialIcons } from "../menu";

import * as DashboardActions from "redux/dashboard/dashboard.actions";
import * as S from "../dashboard.styles";
import "./sidebar.scss";

const Sidebar = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [isHovered, setIsHovered] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [
    IsStripeConnectedAccountStatusModal,
    SetIsStripeConnectedAccountStatusModal,
  ] = useState(false);

  const trainerData = useSelector((state) => state.auth.trainerData);
  const checkStripeConnectedAccountStatus = useSelector(
    (state) => state.dashboard.checkStripeConnectedAccountStatus
  );
  const isCheckStripeConnectedAccountStatusLoading = useSelector(
    (state) => state.dashboard.isCheckStripeConnectedAccountStatusLoading
  );

  useEffect(() => {}, []);

  const toggleSidebar = () => {
    setIsActive(!isActive);
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <>
      <aside
        className={`sidebar ${isActive ? "sidebar-open" : "sidebar-close"}`}
      >
        <S.LogoBar className="icon-change">
          <img src={Logo} className="newDBLogo" alt="" />
          {/* <IconButton onClick={toggleSidebar}>
                {isActive ? <ChevronLeftIcon /> : <MenuIcon />}
              </IconButton> */}
        </S.LogoBar>

        <S.LeftWrap className="scroll-box">
          <S.UpperBox className="upper-box">
            <S.MenuLinks className="sideNavLink">
              <ul className="secondList">
                {MenuItems.map((item, i) => (
                  <li key={i} className="sidebar-list-item">
                    <NavLink
                      to={item.url}
                      isActive={(_, location) => {
                        const parentBase = item.url.split('/')[2]
                        if (!parentBase) return false
                        return location.pathname.includes(`/${parentBase}`)
                      }}
                      activeClassName="active-link"
                    >
                      <img className="icon" src={item.img} alt="menu-icon" />
                      <span>{item.title}</span>
                    </NavLink>
                  </li>
                ))}
              </ul>
            </S.MenuLinks>
          </S.UpperBox>

          {/* <List>
          {['Dashboard', 'Starred', 'Send email', 'Drafts'].map((text, index) => (
            <ListItem button key={text}>
              <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
              <ListItemText primary={text} />
            </ListItem>
          ))}
        </List> */}
        </S.LeftWrap>
        <S.LowerBox>
          <S.SocialWrap
            className="qlinks"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <S.SocialBox>
              <Link href={SidebarSocialIcons[0].url} target="_blank">
                <img
                  className="mx-auto"
                  src={SidebarSocialIcons[0].img}
                  alt="social-icon"
                />
              </Link>
            </S.SocialBox>

            <Typography variant="h6">Quicklinks</Typography>

            {/* <S.SocialBox>
               
              </S.SocialBox> */}
            {/* <br />
              <S.OutlineButton
                onClick={() => {
                  dispatch(
                    DashboardActions.checkStripeConnectedAccountStatusRequest({
                      payload: {
                        trainerID: trainerData?.trainerID,
                      },
                      callback: (res) => {
                        console.log(
                          "checkStripeConnectedAccountStatusRequest",
                          res
                        );
                        if (
                          res?.connected_account_status === "completed" &&
                          res?.connected_user_account_status === "completed"
                        ) {
                          history.push("/onboarding/starter");
                          // history.push("/start-consultation");
                        } else {
                          SetIsStripeConnectedAccountStatusModal(true);
                        }
                      },
                    })
                  );
                }}
                disabled={isCheckStripeConnectedAccountStatusLoading}
              >
                {isCheckStripeConnectedAccountStatusLoading
                  ? "Wir prüfen Stripe…"
                  : "Erstgespräch starten"}
              </S.OutlineButton> */}
          </S.SocialWrap>
          <S.LastNavItem>
            <ul>
              <li>
                <NavLink
                  to="/dashboard/settings/email-account"
                  activeClassName="active-link"
                >
                  <SettingsIcon />
                  <span>Einstellungen</span>
                </NavLink>
              </li>
            </ul>
          </S.LastNavItem>
        </S.LowerBox>

        <Modal
          width="450px"
          height="auto"
          show={IsStripeConnectedAccountStatusModal}
          setShow={() => null}
          heading={
            <div className="d-flex align-items-center justify-content-between">
              <h4 className="my-0 text-left"> </h4>

              <img
                className="cursor-pointer"
                src={CrossIcon}
                alt="CrossIcon"
                onClick={() => SetIsStripeConnectedAccountStatusModal(false)}
              />
            </div>
          }
        >
          <div className="congratulations-modal-body-inner">
            <img src={ErrorMessageIcon} alt="" />

            <h6>Achtung!</h6>
            <p>
              Dein Zahlungsanbieter wurde noch nicht aktiviert. <br />
              Bitte aktiviere deinen Anbieter direkt.
            </p>
            <button
              className="btn-dark"
              onClick={() =>
                history.push("/dashboard/settings/payment-provider")
              }
            >
              Zahlungsanbieter aktivieren
            </button>
          </div>
        </Modal>
      </aside>
      <div
        className={
          isHovered ? "social-media-menu show" : "social-media-menu hidden"
        }
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div className="social-media-menu-items">
          {SidebarSocialIcons.map((item, i) => (
            <Link href={item.url} target="_blank" key={i}>
              <img src={item.img} alt="social-icon" />
            </Link>
          ))}
        </div>
      </div>
    </>
  );
};

export default Sidebar;
