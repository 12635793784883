import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";

import TranslationMessage from "configs/translations/TranslationMessage";
import DonutChart from "components/donutChart/DonutChart";
import Button from "components/formComponents/button/Button";
import ClientDetailsSidebar from "./ClientDetailsSidebar";

import LowCarbImage from "assets/images/lowCarb.svg";
import LowCarbNotSelectedImage from "assets/images/lowCarbNotSelected.svg";

import * as NutritionActions from "redux/nutrition/nutrition.actions";
import * as AuthActions from "redux/auth/auth.actions";
import * as DashboardActions from "redux/dashboard/dashboard.actions";

import * as S from "./ClientDetails.styles";
import "./clientDetails.scss";

const DietFormAndNutritionDistribution = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const intl = useIntl();
  const { userId } = useParams();

  const trainerData = useSelector((state) => state.auth.trainerData);
  const clientDetails = useSelector(
    (state) => state.auth.getClientDetails?.data
  );
  const isClientDetailsLoading = useSelector(
    (state) => state.auth.isClientDetailsLoading
  );
  // const trainerData = useSelector((state) => state.auth.trainerData);
  // const usersRelatedToTrainer = useSelector(
  //   (state) => state.auth.usersRelatedToTrainer
  // );

  // const details = usersRelatedToTrainer?.data?.find(
  //   (data) => data.userID === +userId
  // );

  const allNutritionTypes = useSelector(
    (state) => state.nutrition.nutritionTypes
  );

  const nutritionTypePercentageData = useSelector(
    (state) => state.nutrition.nutritionTypePercentageData
  );

  // const activeNutritionType = useSelector(
  //   (state) => state.nutrition.activeNutritionType
  // );

  const [selectedNutritionForm, setSelectedNutritionForm] = useState({});
  const [isNutritionTypeUpdating, setIsNutritionTypeUpdating] = useState(false);
  const [isNutritionTypeDataLoading, setIsNutritionTypeDataLoading] =
    useState(false);

  useEffect(() => {
    // dispatch(
    //   DashboardActions.getTrainerProfileTempDetailsRequest({
    //     payload: { trainerID: trainerData?.trainerID },
    //     callback: () => null,
    //   })
    // );

    dispatch(
      AuthActions.getClientDetailsRequest({
        payload: {
          trainerID: trainerData?.trainerID,
          userID: +userId,
        },
        callback: (res) => {
          console.log(res);
        },
      })
    );

    getNutritionTypePercentage(clientDetails?.nutritiontypeID);

    dispatch(
      NutritionActions.getAllNutritionTypesRequest({
        aimID: clientDetails?.aimID,
        sex: clientDetails?.sex,
      })
    );

    // getCurrentNutritionType(clientDetails?.nutritiontypeID);

    // dispatch(
    //   NutritionActions.getMacroNutrientsDonutRequest({
    //     nutritiontypeID: clientDetails?.nutritiontypeID,
    //   })
    // );
  }, [dispatch]);

  useEffect(() => {
    if (nutritionTypePercentageData && allNutritionTypes) {
      setSelectedNutritionForm(
        allNutritionTypes?.filter(
          (data) =>
            data?.nutritiontypeID ===
            nutritionTypePercentageData?.[0]?.nutritiontypeID
        )?.[0] || {}
      );
    }
  }, [allNutritionTypes, nutritionTypePercentageData]);

  // const getCurrentNutritionType = (id) =>
  //   dispatch(
  //     NutritionActions.getNutritionTypeRequest({
  //       nutritiontypeID: id,
  //       aimID: clientDetails?.aimID,
  //       sex: clientDetails?.sex,
  //     })
  //   );

  const getNutritionTypePercentage = (id) => {
    setIsNutritionTypeDataLoading(true);
    dispatch(
      NutritionActions.getNutritionTypePercentageChartDataRequest({
        formData: {
          nutritiontypeID: id,
        },
        callback: (res) => {
          setIsNutritionTypeDataLoading(false);
        },
      })
    );
  };

  const handleNutritionTypeChange = () => {
    setIsNutritionTypeUpdating(true);
    dispatch(
      NutritionActions.updateNutritionTypeRequest({
        payload: {
          nutritiontypeID: selectedNutritionForm?.nutritiontypeID,
          aimID: clientDetails?.aimID,
          sex: clientDetails?.sex,
          nutritionconceptID: clientDetails?.nutritionconceptID,
        },
        callback: (res) => {
          setIsNutritionTypeUpdating(false);
          // getCurrentNutritionType(selectedNutritionForm?.nutritiontypeID);
          getNutritionTypePercentage(selectedNutritionForm?.nutritiontypeID);
        },
      })
    );
    // dispatch(
    //   AuthActions.setRegistrationData({
    //     ...clientDetails,
    //     nutritiontypeID: selectedNutritionForm?.nutritiontypeID,
    //     userID: userId,
    //   })
    // );
  };

  const computePercentages = (series) => {
    const filtered = (series || []).filter(({ value }) => value > 0);
    const total = filtered.reduce((t, { value = 0 }) => t + value, 0);

    return filtered.map((item) => ({
      ...item,
      percent: item.value / total,
    }));
  };

  return (
    <>
      <S.ClientDetailsOverallContainer>
        <S.ClientDetailsContainer>
          <div className="second-sidebar-wrapper">
            <ClientDetailsSidebar />
          </div>
          <div className="second-sidebar-content" style={{ padding: "1.5rem" }}>
            <S.ContainerBox>
              <S.Header>
                <div>
                  <S.Heading>Ernährungsform</S.Heading>
                  <S.SubHeading>
                    Die Ernährungsform auf welche der Ernährungsplan ausgelegt
                    ist.
                  </S.SubHeading>
                </div>
                <div>
                  <button
                    className="btn-dark"
                    onClick={handleNutritionTypeChange}
                    disabled={isNutritionTypeUpdating}
                  >
                    {isNutritionTypeUpdating ? "Loading..." : "Speichern"}
                  </button>
                </div>
              </S.Header>

              <S.Divider></S.Divider>
              <S.PaddingBox>
                {(isClientDetailsLoading || isNutritionTypeDataLoading) && (
                  <>
                    <br />
                    <br />
                    <br />
                    <br />
                    <div className="text-center">
                      <div className="loader mx-auto"></div>
                    </div>
                    <br />
                    <br />
                    <br />
                    <br />
                  </>
                )}
                {!isClientDetailsLoading && !isNutritionTypeDataLoading && (
                  <div className="container-70rem">
                    <S.ClientDetailsContentHeading>
                      Ernährungsform bearbeiten
                    </S.ClientDetailsContentHeading>
                    <S.ClientDetailsNutritionFormContainer>
                      {allNutritionTypes &&
                        allNutritionTypes.map((value) => {
                          return (
                            <S.ClientDetailsNutritionFormCard
                              key={value.nutritiontypeID}
                              onClick={() => setSelectedNutritionForm(value)}
                              selected={
                                value?.nutritiontypeID ===
                                selectedNutritionForm?.nutritiontypeID
                              }
                            >
                              <S.ClientDetailsNutritionCardImage
                                src={
                                  value?.nutritiontypeID ===
                                  selectedNutritionForm?.nutritiontypeID
                                    ? LowCarbImage
                                    : LowCarbImage
                                }
                                alt={value.nutritiontypeID}
                              />
                              <span>{value["de_name"]}</span>
                            </S.ClientDetailsNutritionFormCard>
                          );
                        })}
                    </S.ClientDetailsNutritionFormContainer>

                    <S.ClientDetailsContentHeading>
                      Nährwertverteilung
                    </S.ClientDetailsContentHeading>

                    <S.ClientDetailsDonutContainer className="client-details-donut-container">
                      <div className="item legend-item">
                        <h6>Legende:</h6>
                        <div className="legends">
                          {computePercentages([
                            {
                              label: "Kohlenhydrate",
                              value:
                                nutritionTypePercentageData?.[0]
                                  ?.percentage_carbs,
                            },
                            {
                              label: "Fett",
                              value:
                                nutritionTypePercentageData?.[0]
                                  ?.percentage_fat,
                            },
                            {
                              label: "Eiweiß",
                              value:
                                nutritionTypePercentageData?.[0]
                                  ?.percentage_protein,
                            },
                          ]).map(({ value, percent, label }) => (
                            <div key={`${value}-${label}`}>
                              <span>{label}</span>
                              <span>in</span>
                              <span>%</span>
                              {/* <span>{Math.round(percent * 100)}%</span> */}
                            </div>
                          ))}
                        </div>
                      </div>

                      <div className="item chart-item">
                        <DonutChart
                          series={[
                            {
                              label: "Kohlenhydrate",
                              value:
                                nutritionTypePercentageData?.[0]
                                  ?.percentage_carbs,
                            },
                            {
                              label: "Fett",
                              value:
                                nutritionTypePercentageData?.[0]
                                  ?.percentage_fat,
                            },
                            {
                              label: "Eiweiß",
                              value:
                                nutritionTypePercentageData?.[0]
                                  ?.percentage_protein,
                            },
                          ]}
                          title="TRAININGSFREIER TAG"
                        />
                      </div>

                      <div className="item chart-item">
                        <DonutChart
                          series={[
                            {
                              label: "Kohlenhydrate",
                              value:
                                nutritionTypePercentageData?.[1]
                                  ?.percentage_carbs,
                            },
                            {
                              label: "Fett",
                              value:
                                nutritionTypePercentageData?.[1]
                                  ?.percentage_fat,
                            },
                            {
                              label: "Eiweiß",
                              value:
                                nutritionTypePercentageData?.[1]
                                  ?.percentage_protein,
                            },
                          ]}
                          title="TRAININGSTAG"
                        />
                      </div>
                    </S.ClientDetailsDonutContainer>
                  </div>
                )}
              </S.PaddingBox>
            </S.ContainerBox>
          </div>
        </S.ClientDetailsContainer>
      </S.ClientDetailsOverallContainer>
    </>
  );
};

export default DietFormAndNutritionDistribution;
