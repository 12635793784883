import React, { useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ReactPlayer from "react-player";
import * as S from "./academyDetails.styles";
import BackIconButton from "components/backIconButton/BackIconButton";
import ToolTip from 'components/ToolTip/ToolTip'

// Images
import Clock from "assets/images/clock-green.svg";
import pdfIcon from "assets/images/pdf.svg";

// Redux
import * as AuthActions from "redux/auth/auth.actions";
import { getS3ImageUrl } from "configs/utils";
import AcademyWrapper from "../AcademyWrapper";

function Details () {
  const { id } = useParams()
  const dispatch = useDispatch()
  const history = useHistory()

  const authSlice = useSelector((state) => state.auth)
  const title =
    authSlice?.lessonDetails?.getLessonVideoByLessonID?.lesson_title
  const description =
    authSlice?.lessonDetails?.getLessonVideoByLessonID?.text_desc
  const thumbnail =
    authSlice?.lessonDetails?.getLessonVideoByLessonID?.thumbnail;
  const video_file =
    authSlice?.lessonDetails?.getLessonVideoByLessonID?.video_file;
  const video_length =
    authSlice?.lessonDetails?.getLessonVideoByLessonID?.video_length;
  const category_name =
    authSlice?.lessonDetails?.getLessonVideoByLessonID?.category_name;
  
  const filesForDownload =
    authSlice?.downloadableLessons?.getDownloadedFileByLessonID

  useEffect(() => {
    dispatch(
      AuthActions.getLessonDetailsRequest({
        payload: { lessonID: id },
      })
    );

    dispatch(
      AuthActions.getDownloadableLessonsRequest({
        payload: { lessonID: id },
      })
    );
  }, []);

  const AvailableResources = filesForDownload && filesForDownload.length
    ? filesForDownload.map((item, index) => {
      const displayName = item
        .download_file
        .split("/")
        .at(-1)
        .substring(0, 25)
        + '..'
      return (
        <a
          href={getS3ImageUrl(item?.download_file)}
          target="_blank"
          download={"file.pdf"}
          rel="noopener noreferrer"
          key={index}
          className="resources-card-item">
          <span><img src={pdfIcon} /></span>
          <span>{displayName}</span>
        </a>
      )
    })
    : ''

  return (
    <S.MainDashboardContainer>
      <S.HomeHeaderContainer>
        <div style={{ display: "flex" }}>
          <BackIconButton
            onClick={() => {
              history.push("/dashboard/academy/video");
            }}
          />
          <div style={{ marginLeft: "1rem" }}>
            <S.MainDashboardTitle>{category_name}</S.MainDashboardTitle>
            <S.MainDashboardSubTitle>{title}</S.MainDashboardSubTitle>
          </div>
        </div>
      </S.HomeHeaderContainer>
      <S.Wrapper>
        <S.VideoWithDescription>
          <div>
            <ReactPlayer
              className="player"
              url={getS3ImageUrl(video_file)}
              controls
              playing
            />
          </div>
          <div>
            <h4>{title}</h4>
            <p>{description}</p>
          </div>
        </S.VideoWithDescription>

        <S.InfoResources>
          {filesForDownload && filesForDownload.length
          ? (
            <S.ResourcesCard>
              <div className="card-header">
                <span>Ressourcen</span>
                <span>{filesForDownload.length} Dateien</span>
              </div>
              <div className="card-items">
                {AvailableResources}
              </div>
            </S.ResourcesCard>
          ) : ''}

          <S.ResourcesCard>
            <div className="card-header">
              <span>Aktuelles Video</span>
            </div>
            <div className="card-items">
              <div className="modules-card-item">
                <span className="category-tag">
                  <ToolTip value={category_name || 'loading...'} maxCharacter={25} />
                </span>
                <span className="duration-tag">
                  <span><img src={Clock} alt="clock" /></span>
                  <span>{video_length}</span>
                </span>
              </div>
            </div>
          </S.ResourcesCard>
        </S.InfoResources>
      </S.Wrapper>
    </S.MainDashboardContainer>
  )
}

function AcademyDetails () {
  return <AcademyWrapper>
    <Details />
  </AcademyWrapper>
}

export default AcademyDetails
