import styled from "styled-components";
import { RegistrationContainer, RegistrationSubHeading, } from 'pages/registration/registration.styles';
import { DashboardContainer } from "pages/dashboard/dashboard.styles";
import { BenefitsContainer, ChecklistElementGreenTickContainer, Benefits } from "pages/healthScoreImprovement/healthScoreImprovement.styles";


export const OverviewContainer = styled(RegistrationContainer)`
    margin-top: 50px;
`;

export const OverviewHeading = styled(RegistrationSubHeading)``;

export const OverviewInnerContainer = styled.span`
    margin: 20px auto 30px;
    display: flex;
    flex-direction: row;
    justify-content: center;
`;

export const OverviewSubHeadingContainer = styled.span`
    display: flex;
    justify-content: space-between;
    width: 290px;
`;

export const OverviewSwitchContainer = styled.span``;

export const OverviewSwitchHeading = styled.span`
    font-family: 'RegularRegular';
    font-size: 16px;
    color: #A5B5AF;
    margin-right: 20px;
    line-height: 24px;
`;

export const OverviewFoodiaryPROContainer = styled(OverviewSwitchContainer)``;

export const OverviewFoodiaryText = styled.span`
    color: #2ECF96;
    line-height: 24px;
`;

export const OverviewPROImage = styled.img`
    margin: 0 0 4px 5px;
`;

export const OverviewInclusiveImage = styled.img`
    margin: 0 0 10px 10px;
`;

export const OverviewCardContainer = styled(DashboardContainer)`
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    width: 874px;
`;

export const OverviewCardInnerContainer = styled.div`
    padding: 45px 30px 0;
`

export const OverviewCardHeading = styled(OverviewHeading)`
    margin-bottom: 4px;
`;

export const OverviewCardSubHeading = styled(OverviewHeading)`
    font-size: 14px;
    font-family: 'RegularRegular';
    line-height: 21px;
    color: #A5B5AF;
`;


export const FoodiaryPROBenefitsContainer = styled(BenefitsContainer)``;

export const GreenTickElementContainer = styled(ChecklistElementGreenTickContainer)``;

export const FoodiaryPROBenefits = styled(Benefits)``;

export const PartnerAdvantageBenefitsContainer = styled(BenefitsContainer)`
    justify-content: space-between;
`;

export const PartnerAdvantageBenefits = styled(Benefits)``;

export const PartnerAdvantageBenefitsPrice = styled.span`
    line-height: 24px;
    color: #1C9D87;
    font-size: 16px;
    font-family: RubikMedium;
`;

export const PartnerAdvantageBenefitsPriceContainer = styled.span`
    align-self: flex-end;
    min-width: 125px;
    text-align: right;
`;

export const PartnerAdvantageBenefitsTotalPrice = styled.span`
    color: #A5B5AF;
    font-size: 12px;
    text-decoration: line-through;
    margin-right: 10px;
`

export const PaymentTypeContainer = styled(BenefitsContainer)`
    justify-content: space-between;
    margin-top: 2em;
`;

export const PaymentTypeOptions = styled.span`
    display: flex;
    margin-left: 20px;
`;

export const PaymentTypeSwitchContainer = styled.span``;

export const PaymentTypeText = styled.span`
    color: #44554C;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    margin-left: 10px;
`