const NutritionTypes = {
  GET_USER_DATA_REQUEST: "GET_USER_DATA_REQUEST",
  GET_USER_DATA_SUCCESS: "GET_USER_DATA_SUCCESS",
  GET_USER_DATA_FAILURE: "GET_USER_DATA_FAILURE",

  GET_NUTRITION_TYPE_REQUEST: "GET_NUTRITION_TYPE_REQUEST",
  GET_NUTRITION_TYPE_SUCCESS: "GET_NUTRITION_TYPE_SUCCESS",
  GET_NUTRITION_TYPE_FAILURE: "GET_NUTRITION_TYPE_FAILURE",

  GET_ALL_NUTRITION_TYPES_REQUEST: "GET_ALL_NUTRITION_TYPES_REQUEST",
  GET_ALL_NUTRITION_TYPES_SUCCESS: "GET_ALL_NUTRITION_TYPES_SUCCESS",
  GET_ALL_NUTRITION_TYPES_FAILURE: "GET_ALL_NUTRITION_TYPES_FAILURE",

  UPDATE_NUTRITION_TYPE_REQUEST: "UPDATE_NUTRITION_TYPE_REQUEST",
  UPDATE_NUTRITION_TYPE_SUCCESS: "UPDATE_NUTRITION_TYPE_SUCCESS",
  UPDATE_NUTRITION_TYPE_FAILURE: "UPDATE_NUTRITION_TYPE_FAILURE",

  UPDATE_NUTRITION_CALORIES_REQUEST: "UPDATE_NUTRITION_CALORIES_REQUEST",
  UPDATE_NUTRITION_CALORIES_SUCCESS: "UPDATE_NUTRITION_CALORIES_SUCCESS",
  UPDATE_NUTRITION_CALORIES_FAILURE: "UPDATE_NUTRITION_CALORIES_FAILURE",

  GET_NUTRITION_ADVANTAGES_REQUEST: "GET_NUTRITION_ADVANTAGES_REQUEST",
  GET_NUTRITION_ADVANTAGES_SUCCESS: "GET_NUTRITION_ADVANTAGES_SUCCESS",
  GET_NUTRITION_ADVANTAGES_FAILURE: "GET_NUTRITION_ADVANTAGES_FAILURE",

  GET_NUTRITION_RECIPES_REQUEST: "GET_NUTRITION_RECIPES_REQUEST",
  GET_NUTRITION_RECIPES_SUCCESS: "GET_NUTRITION_RECIPES_SUCCESS",
  GET_NUTRITION_RECIPES_FAILURE: "GET_NUTRITION_RECIPES_FAILURE",

  GET_WEEK_WISE_CALORIES_REQUEST: "GET_WEEK_WISE_CALORIES_REQUEST",
  GET_WEEK_WISE_CALORIES_SUCCESS: "GET_WEEK_WISE_CALORIES_SUCCESS",
  GET_WEEK_WISE_CALORIES_FAILURE: "GET_WEEK_WISE_CALORIES_FAILURE",

  GET_ALL_CALORIES_REQUEST: "GET_ALL_CALORIES_REQUEST",
  GET_ALL_CALORIES_SUCCESS: "GET_ALL_CALORIES_SUCCESS",
  GET_ALL_CALORIES_FAILURE: "GET_ALL_CALORIES_FAILURE",

  GET_MACRO_NUTRIENTS_DONUT_REQUEST: "GET_MACRO_NUTRIENTS_DONUT_REQUEST",
  GET_MACRO_NUTRIENTS_DONUT_SUCCESS: "GET_MACRO_NUTRIENTS_DONUT_SUCCESS",
  GET_MACRO_NUTRIENTS_DONUT_FAILURE: "GET_MACRO_NUTRIENTS_DONUT_FAILURE",

  GET_NUTRITION_TYPE_PERCENTAGE_CHART_DATA_REQUEST:
    "GET_NUTRITION_TYPE_PERCENTAGE_CHART_DATA_REQUEST",
  GET_NUTRITION_TYPE_PERCENTAGE_CHART_DATA_SUCCESS:
    "GET_NUTRITION_TYPE_PERCENTAGE_CHART_DATA_SUCCESS",
  GET_NUTRITION_TYPE_PERCENTAGE_CHART_DATA_FAILURE:
    "GET_NUTRITION_TYPE_PERCENTAGE_CHART_DATA_FAILURE",

  RESET_PASSWORD_REQUEST: "RESET_PASSWORD_REQUEST",
  RESET_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS",
  RESET_PASSWORD_FAILURE: "RESET_PASSWORD_FAILURE",
  RESET_PASSWORD_NULL: "RESET_PASSWORD_NULL",

  RESET_PASSWORD_LOADING_START: "RESET_PASSWORD_LOADING_START",
  RESET_PASSWORD_LOADING_STOP: "RESET_PASSWORD_LOADING_STOP",

  SET_PASSWORD_REQUEST: "SET_PASSWORD_REQUEST",
  SET_PASSWORD_SUCCESS: "SET_PASSWORD_SUCCESS",
  SET_PASSWORD_FAILURE: "SET_PASSWORD_FAILURE",
  SET_PASSWORD_NULL: "SET_PASSWORD_NULL",

  SET_PASSWORD_LOADING_START: "SET_PASSWORD_LOADING_START",
  SET_PASSWORD_LOADING_STOP: "SET_PASSWORD_LOADING_STOP",

  COURSE_CHANGE_PASSWORD_REQUEST: "COURSE_CHANGE_PASSWORD_REQUEST",
  COURSE_CHANGE_PASSWORD_SUCCESS: "COURSE_CHANGE_PASSWORD_SUCCESS",
  COURSE_CHANGE_PASSWORD_FAILURE: "COURSE_CHANGE_PASSWORD_FAILURE",
  COURSE_CHANGE_PASSWORD_NULL: "COURSE_CHANGE_PASSWORD_NULL",

  COURSE_CHANGE_PASSWORD_LOADING_START: "COURSE_CHANGE_PASSWORD_LOADING_START",
  COURSE_CHANGE_PASSWORD_LOADING_STOP: "COURSE_CHANGE_PASSWORD_LOADING_STOP",

  GET_FAQ_QUESTIONS_REQUEST: "GET_FAQ_QUESTIONS_REQUEST",
  GET_FAQ_QUESTIONS_SUCCESS: "GET_FAQ_QUESTIONS_SUCCESS",
  GET_FAQ_QUESTIONS_FAILURE: "GET_FAQ_QUESTIONS_FAILURE",

  GET_FAQ_QUESTIONS_LOADING_START: "GET_FAQ_QUESTIONS_LOADING_START",
  GET_FAQ_QUESTIONS_LOADING_STOP: "GET_FAQ_QUESTIONS_LOADING_STOP",

  GET_FAQ_QUESTIONS_BY_ID_REQUEST: "GET_FAQ_QUESTIONS_BY_ID_REQUEST",
  GET_FAQ_QUESTIONS_BY_ID_SUCCESS: "GET_FAQ_QUESTIONS_BY_ID_SUCCESS",
  GET_FAQ_QUESTIONS_BY_ID_FAILURE: "GET_FAQ_QUESTIONS_BY_ID_FAILURE",

  GET_FAQ_QUESTIONS_BY_ID_LOADING_START:
    "GET_FAQ_QUESTIONS_BY_ID_LOADING_START",
  GET_FAQ_QUESTIONS_BY_ID_LOADING_STOP: "GET_FAQ_QUESTIONS_BY_ID_LOADING_STOP",

  GET_NUTRITION_COURSE_DETAILS_BY_ID_REQUEST:
    "GET_NUTRITION_COURSE_DETAILS_BY_ID_REQUEST",
  GET_NUTRITION_COURSE_DETAILS_BY_ID_SUCCESS:
    "GET_NUTRITION_COURSE_DETAILS_BY_ID_SUCCESS",
  GET_NUTRITION_COURSE_DETAILS_BY_ID_FAILURE:
    "GET_NUTRITION_COURSE_DETAILS_BY_ID_FAILURE",

  GET_NUTRITION_COURSE_DETAILS_BY_ID_LOADING_START:
    "GET_NUTRITION_COURSE_DETAILS_BY_ID_LOADING_START",
  GET_NUTRITION_COURSE_DETAILS_BY_ID_LOADING_STOP:
    "GET_NUTRITION_COURSE_DETAILS_BY_ID_LOADING_STOP",

  UPDATE_NUTRITION_COURSE_DETAILS_REQUEST:
    "UPDATE_NUTRITION_COURSE_DETAILS_REQUEST",
  UPDATE_NUTRITION_COURSE_DETAILS_SUCCESS:
    "UPDATE_NUTRITION_COURSE_DETAILS_SUCCESS",
  UPDATE_NUTRITION_COURSE_DETAILS_FAILURE:
    "UPDATE_NUTRITION_COURSE_DETAILS_FAILURE",

  UPDATE_NUTRITION_COURSE_DETAILS_LOADING_START:
    "UPDATE_NUTRITION_COURSE_DETAILS_LOADING_START",
  UPDATE_NUTRITION_COURSE_DETAILS_LOADING_STOP:
    "UPDATE_NUTRITION_COURSE_DETAILS_LOADING_STOP",

  UPDATE_OR_DELETE_NUTRITION_COURSE_DETAILS_REQUEST:
    "UPDATE_OR_DELETE_NUTRITION_COURSE_DETAILS_REQUEST",
  UPDATE_OR_DELETE_NUTRITION_COURSE_DETAILS_SUCCESS:
    "UPDATE_OR_DELETE_NUTRITION_COURSE_DETAILS_SUCCESS",
  UPDATE_OR_DELETE_NUTRITION_COURSE_DETAILS_FAILURE:
    "UPDATE_OR_DELETE_NUTRITION_COURSE_DETAILS_FAILURE",

  UPDATE_OR_DELETE_NUTRITION_COURSE_DETAILS_LOADING_START:
    "UPDATE_OR_DELETE_NUTRITION_COURSE_DETAILS_LOADING_START",
  UPDATE_OR_DELETE_NUTRITION_COURSE_DETAILS_LOADING_STOP:
    "UPDATE_OR_DELETE_NUTRITION_COURSE_DETAILS_LOADING_STOP",

  CHECK_USERID_EMAIL_REQUEST: "CHECK_USERID_EMAIL_REQUEST",
  CHECK_USERID_EMAIL_SUCCESS: "CHECK_USERID_EMAIL_SUCCESS",
  CHECK_USERID_EMAIL_FAILURE: "CHECK_USERID_EMAIL_FAILURE",

  CHECK_USERID_EMAIL_LOADING_START: "CHECK_USERID_EMAIL_LOADING_START",
  CHECK_USERID_EMAIL_LOADING_STOP: "CHECK_USERID_EMAIL_LOADING_STOP",

  MARKETING_PORTAL_ASSETS_REQUEST: "MARKETING_PORTAL_ASSETS_REQUEST",
  MARKETING_PORTAL_ASSETS_SUCCESS: "MARKETING_PORTAL_ASSETS_SUCCESS",
  MARKETING_PORTAL_ASSETS_FAILURE: "MARKETING_PORTAL_ASSETS_FAILURE",

  MARKETING_PORTAL_ASSETS_LOADING_START:
    "MARKETING_PORTAL_ASSETS_LOADING_START",
  MARKETING_PORTAL_ASSETS_LOADING_STOP: "MARKETING_PORTAL_ASSETS_LOADING_STOP",

  MARKETING_PORTAL_PRINTS_REQUEST: "MARKETING_PORTAL_PRINTS_REQUEST",
  MARKETING_PORTAL_PRINTS_SUCCESS: "MARKETING_PORTAL_PRINTS_SUCCESS",
  MARKETING_PORTAL_PRINTS_FAILURE: "MARKETING_PORTAL_PRINTS_FAILURE",

  MARKETING_PORTAL_PRINTS_LOADING_START:
    "MARKETING_PORTAL_PRINTS_LOADING_START",
  MARKETING_PORTAL_PRINTS_LOADING_STOP: "MARKETING_PORTAL_PRINTS_LOADING_STOP",

  MARKETING_PORTAL_PRINTS_DETAILS_REQUEST:
    "MARKETING_PORTAL_PRINTS_DETAILS_REQUEST",
  MARKETING_PORTAL_PRINTS_DETAILS_SUCCESS:
    "MARKETING_PORTAL_PRINTS_DETAILS_SUCCESS",
  MARKETING_PORTAL_PRINTS_DETAILS_FAILURE:
    "MARKETING_PORTAL_PRINTS_DETAILS_FAILURE",

  MARKETING_PORTAL_PRINTS_DETAILS_LOADING_START:
    "MARKETING_PORTAL_PRINTS_DETAILS_LOADING_START",
  MARKETING_PORTAL_PRINTS_DETAILS_LOADING_STOP:
    "MARKETING_PORTAL_PRINTS_DETAILS_LOADING_STOP",

  MARKETING_PORTAL_PARTNERS_REQUEST: "MARKETING_PORTAL_PARTNERS_REQUEST",
  MARKETING_PORTAL_PARTNERS_SUCCESS: "MARKETING_PORTAL_PARTNERS_SUCCESS",
  MARKETING_PORTAL_PARTNERS_FAILURE: "MARKETING_PORTAL_PARTNERS_FAILURE",

  MARKETING_PORTAL_PARTNERS_LOADING_START:
    "MARKETING_PORTAL_PARTNERS_LOADING_START",
  MARKETING_PORTAL_PARTNERS_LOADING_STOP:
    "MARKETING_PORTAL_PARTNERS_LOADING_STOP",

  MARKETING_PORTAL_PARTNER_DETAILS_REQUEST:
    "MARKETING_PORTAL_PARTNER_DETAILS_REQUEST",
  MARKETING_PORTAL_PARTNER_DETAILS_SUCCESS:
    "MARKETING_PORTAL_PARTNER_DETAILS_SUCCESS",
  MARKETING_PORTAL_PARTNER_DETAILS_FAILURE:
    "MARKETING_PORTAL_PARTNER_DETAILS_FAILURE",

  MARKETING_PORTAL_PARTNER_DETAILS_LOADING_START:
    "MARKETING_PORTAL_PARTNER_DETAILS_LOADING_START",
  MARKETING_PORTAL_PARTNER_DETAILS_LOADING_STOP:
    "MARKETING_PORTAL_PARTNER_DETAILS_LOADING_STOP",

  MARKETING_PORTAL_PARTNER_AND_PRINT_DETAILS_REQUEST:
    "MARKETING_PORTAL_PARTNER_AND_PRINT_DETAILS_REQUEST",
  MARKETING_PORTAL_PARTNER_AND_PRINT_DETAILS_SUCCESS:
    "MARKETING_PORTAL_PARTNER_AND_PRINT_DETAILS_SUCCESS",
  MARKETING_PORTAL_PARTNER_AND_PRINT_DETAILS_FAILURE:
    "MARKETING_PORTAL_PARTNER_AND_PRINT_DETAILS_FAILURE",

  MARKETING_PORTAL_PARTNER_AND_PRINT_DETAILS_LOADING_START:
    "MARKETING_PORTAL_PARTNER_AND_PRINT_DETAILS_LOADING_START",
  MARKETING_PORTAL_PARTNER_AND_PRINT_DETAILS_LOADING_STOP:
    "MARKETING_PORTAL_PARTNER_AND_PRINT_DETAILS_LOADING_STOP",

  MARKETING_PORTAL_CREATE_AFFILIATE_REQUEST:
    "MARKETING_PORTAL_CREATE_AFFILIATE_REQUEST",
  MARKETING_PORTAL_CREATE_AFFILIATE_SUCCESS:
    "MARKETING_PORTAL_CREATE_AFFILIATE_SUCCESS",
  MARKETING_PORTAL_CREATE_AFFILIATE_FAILURE:
    "MARKETING_PORTAL_CREATE_AFFILIATE_FAILURE",

  MARKETING_PORTAL_CREATE_AFFILIATE_LOADING_START:
    "MARKETING_PORTAL_CREATE_AFFILIATE_LOADING_START",
  MARKETING_PORTAL_CREATE_AFFILIATE_LOADING_STOP:
    "MARKETING_PORTAL_CREATE_AFFILIATE_LOADING_STOP",

  MARKETING_PORTAL_ADD_AFFILIATE_PROGRAM_REQUEST:
    "MARKETING_PORTAL_ADD_AFFILIATE_PROGRAM_REQUEST",
  MARKETING_PORTAL_ADD_AFFILIATE_PROGRAM_SUCCESS:
    "MARKETING_PORTAL_ADD_AFFILIATE_PROGRAM_SUCCESS",
  MARKETING_PORTAL_ADD_AFFILIATE_PROGRAM_FAILURE:
    "MARKETING_PORTAL_ADD_AFFILIATE_PROGRAM_FAILURE",

  MARKETING_PORTAL_ADD_AFFILIATE_PROGRAM_LOADING_START:
    "MARKETING_PORTAL_ADD_AFFILIATE_PROGRAM_LOADING_START",
  MARKETING_PORTAL_ADD_AFFILIATE_PROGRAM_LOADING_STOP:
    "MARKETING_PORTAL_ADD_AFFILIATE_PROGRAM_LOADING_STOP",

  CREATE_QR_CODE_REQUEST: "CREATE_QR_CODE_REQUEST",
  CREATE_QR_CODE_SUCCESS: "CREATE_QR_CODE_SUCCESS",
  CREATE_QR_CODE_FAILURE: "CREATE_QR_CODE_FAILURE",

  CREATE_QR_CODE_LOADING_START: "CREATE_QR_CODE_LOADING_START",
  CREATE_QR_CODE_LOADING_STOP: "CREATE_QR_CODE_LOADING_STOP",

  MARKETING_PORTAL_CREATE_PARTNER_REQUEST:
    "MARKETING_PORTAL_CREATE_PARTNER_REQUEST",
  MARKETING_PORTAL_CREATE_PARTNER_SUCCESS:
    "MARKETING_PORTAL_CREATE_PARTNER_SUCCESS",
  MARKETING_PORTAL_CREATE_PARTNER_FAILURE:
    "MARKETING_PORTAL_CREATE_PARTNER_FAILURE",

  MARKETING_PORTAL_CREATE_PARTNER_LOADING_START:
    "MARKETING_PORTAL_CREATE_PARTNER_LOADING_START",
  MARKETING_PORTAL_CREATE_PARTNER_LOADING_STOP:
    "MARKETING_PORTAL_CREATE_PARTNER_LOADING_STOP",

  MARKETING_PORTAL_COACH_DETAILS_REQUEST:
    "MARKETING_PORTAL_COACH_DETAILS_REQUEST",
  MARKETING_PORTAL_COACH_DETAILS_SUCCESS:
    "MARKETING_PORTAL_COACH_DETAILS_SUCCESS",
  MARKETING_PORTAL_COACH_DETAILS_FAILURE:
    "MARKETING_PORTAL_COACH_DETAILS_FAILURE",

  MARKETING_PORTAL_COACH_DETAILS_LOADING_START:
    "MARKETING_PORTAL_COACH_DETAILS_LOADING_START",
  MARKETING_PORTAL_COACH_DETAILS_LOADING_STOP:
    "MARKETING_PORTAL_COACH_DETAILS_LOADING_STOP",

  MARKETING_PORTAL_PRINT_PIC_REQUEST: "MARKETING_PORTAL_PRINT_PIC_REQUEST",
  MARKETING_PORTAL_PRINT_PIC_SUCCESS: "MARKETING_PORTAL_PRINT_PIC_SUCCESS",
  MARKETING_PORTAL_PRINT_PIC_FAILURE: "MARKETING_PORTAL_PRINT_PIC_FAILURE",

  MARKETING_PORTAL_PRINT_PIC_LOADING_START:
    "MARKETING_PORTAL_PRINT_PIC_LOADING_START",
  MARKETING_PORTAL_PRINT_PIC_LOADING_STOP:
    "MARKETING_PORTAL_PRINT_PIC_LOADING_STOP",

  MARKETING_PORTAL_RAW_URL_BY_PRINT_ID_REQUEST:
    "MARKETING_PORTAL_RAW_URL_BY_PRINT_ID_REQUEST",
  MARKETING_PORTAL_RAW_URL_BY_PRINT_ID_SUCCESS:
    "MARKETING_PORTAL_RAW_URL_BY_PRINT_ID_SUCCESS",
  MARKETING_PORTAL_RAW_URL_BY_PRINT_ID_FAILURE:
    "MARKETING_PORTAL_RAW_URL_BY_PRINT_ID_FAILURE",

  MARKETING_PORTAL_RAW_URL_BY_PRINT_ID_LOADING_START:
    "MARKETING_PORTAL_RAW_URL_BY_PRINT_ID_LOADING_START",
  MARKETING_PORTAL_RAW_URL_BY_PRINT_ID_LOADING_STOP:
    "MARKETING_PORTAL_RAW_URL_BY_PRINT_ID_LOADING_STOP",

  MARKETING_PORTAL_PRINT_PLACEMENT_DETAILS_REQUEST:
    "MARKETING_PORTAL_PRINT_PLACEMENT_DETAILS_REQUEST",
  MARKETING_PORTAL_PRINT_PLACEMENT_DETAILS_SUCCESS:
    "MARKETING_PORTAL_PRINT_PLACEMENT_DETAILS_SUCCESS",
  MARKETING_PORTAL_PRINT_PLACEMENT_DETAILS_FAILURE:
    "MARKETING_PORTAL_PRINT_PLACEMENT_DETAILS_FAILURE",

  MARKETING_PORTAL_PRINT_PLACEMENT_DETAILS_LOADING_START:
    "MARKETING_PORTAL_PRINT_PLACEMENT_DETAILS_LOADING_START",
  MARKETING_PORTAL_PRINT_PLACEMENT_DETAILS_LOADING_STOP:
    "MARKETING_PORTAL_PRINT_PLACEMENT_DETAILS_LOADING_STOP",

  MARKETING_PORTAL_CREATE_PRINT_REQUEST:
    "MARKETING_PORTAL_CREATE_PRINT_REQUEST",
  MARKETING_PORTAL_CREATE_PRINT_SUCCESS:
    "MARKETING_PORTAL_CREATE_PRINT_SUCCESS",
  MARKETING_PORTAL_CREATE_PRINT_FAILURE:
    "MARKETING_PORTAL_CREATE_PRINT_FAILURE",

  MARKETING_PORTAL_CREATE_PRINT_LOADING_START:
    "MARKETING_PORTAL_CREATE_PRINT_LOADING_START",
  MARKETING_PORTAL_CREATE_PRINT_LOADING_STOP:
    "MARKETING_PORTAL_CREATE_PRINT_LOADING_STOP",

  MARKETING_PORTAL_CREATE_PARTNER_PRINT_REQUEST:
    "MARKETING_PORTAL_CREATE_PARTNER_PRINT_REQUEST",
  MARKETING_PORTAL_CREATE_PARTNER_PRINT_SUCCESS:
    "MARKETING_PORTAL_CREATE_PARTNER_PRINT_SUCCESS",
  MARKETING_PORTAL_CREATE_PARTNER_PRINT_FAILURE:
    "MARKETING_PORTAL_CREATE_PARTNER_PRINT_FAILURE",

  MARKETING_PORTAL_CREATE_PARTNER_PRINT_LOADING_START:
    "MARKETING_PORTAL_CREATE_PARTNER_PRINT_LOADING_START",
  MARKETING_PORTAL_CREATE_PARTNER_PRINT_LOADING_STOP:
    "MARKETING_PORTAL_CREATE_PARTNER_PRINT_LOADING_STOP",

  GET_ALL_REFUNDS_REQUEST: "GET_ALL_REFUNDS_REQUEST",
  GET_ALL_REFUNDS_SUCCESS: "GET_ALL_REFUNDS_SUCCESS",
  GET_ALL_REFUNDS_FAILURE: "GET_ALL_REFUNDS_FAILURE",

  GET_ALL_REFUNDS_LOADING_START: "GET_ALL_REFUNDS_LOADING_START",
  GET_ALL_REFUNDS_LOADING_STOP: "GET_ALL_REFUNDS_LOADING_STOP",

  GET_MACRO_NUTRIENTS_PIE_CHART_DATA_REQUEST: "GET_MACRO_NUTRIENTS_PIE_CHART_DATA_REQUEST",
  GET_MACRO_NUTRIENTS_PIE_CHART_DATA_SUCCESS: "GET_MACRO_NUTRIENTS_PIE_CHART_DATA_SUCCESS",
  GET_MACRO_NUTRIENTS_PIE_CHART_DATA_FAILURE: "GET_MACRO_NUTRIENTS_PIE_CHART_DATA_FAILURE",

  GET_MACRO_NUTRIENTS_PIE_CHART_DATA_LOADING_START: "GET_MACRO_NUTRIENTS_PIE_CHART_DATA_LOADING_START",
  GET_MACRO_NUTRIENTS_PIE_CHART_DATA_LOADING_STOP: "GET_MACRO_NUTRIENTS_PIE_CHART_DATA_LOADING_STOP",
  UPDATE_PARTNER_CLAIM_REQUEST: "UPDATE_PARTNER_CLAIM_REQUEST",
  UPDATE_PARTNER_CLAIM_SUCCESS: "UPDATE_PARTNER_CLAIM_SUCCESS",
  UPDATE_PARTNER_CLAIM_FAILURE: "UPDATE_PARTNER_CLAIM_FAILURE",
  UPDATE_USER_INFORMATION_REQUEST_START: "UPDATE_USER_INFORMATION_REQUEST_START",
  UPDATE_USER_INFORMATION_REQUEST:"UPDATE_USER_INFORMATION_REQUEST",
  UPDATE_USER_INFORMATION_REQUEST_SUCCESS: "UPDATE_USER_INFORMATION_REQUEST_SUCCESS",
  UPDATE_USER_INFORMATION_REQUEST_FAILURE: "UPDATE_USER_INFORMATION_REQUEST_FAILURE",
};

export default NutritionTypes;
