import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as S from "./academy.styles";
import * as GlobalStyles from "components/global/Sections.styles"

import { dashboardNavTabs } from "configs/utils";

// Redux
import * as AuthActions from "redux/auth/auth.actions";
import { getS3ImageUrl } from "configs/utils";

const Academy = (props) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const [activeTab, setActiveTab] = useState(1);

  const trainerProfileData = useSelector((state) => state.auth.trainerData);
  const authSlice = useSelector((state) => state.auth);
  const lessonsCategories = useSelector(
    (state) => state.auth.lessonsCategories
  );
  const lessonsByCategory = useSelector(
    (state) => state.auth.lessonsByCategoryId
  );

  useEffect(() => {
    dispatch(
      AuthActions.getLessonsCategoriesRequest({
        callback: (res) => {
          dispatch(
            AuthActions.getLessonsByCategoryIdRequest({
              payload: {
                category_id: res?.getAllCategories[0]?.id,
              },
            })
          );
        },
      })
    );
  }, []);

  return (
    <>
      <S.MainDashboardContainer>
        <S.ClientDetailsContainer>
          <S.Card>
            <S.HomeHeaderContainer>
              <div>
                <GlobalStyles.SectionHeadline>
                  Video Academy
                </GlobalStyles.SectionHeadline>
                <GlobalStyles.SubHeadline>
                  Entdecke Fodiary, erfahre mehr über unser Angebot und erhalte Tipps für erfolgreiches Partnermanagement.
                </GlobalStyles.SubHeadline>
              </div>
            </S.HomeHeaderContainer>

            <br />
            <S.Divider />
            <br />

            <S.ClientDetailsTabContainer>
              {lessonsCategories?.getAllCategories?.map((tab) => (
                <S.Tab
                  key={tab?.id}
                  active={activeTab === tab?.id}
                  onClick={() => {
                    setActiveTab(tab?.id);
                    dispatch(
                      AuthActions.getLessonsByCategoryIdRequest({
                        payload: { category_id: tab?.id },
                      })
                    );
                  }}
                >
                  {tab?.category_name}
                </S.Tab>
              ))}
            </S.ClientDetailsTabContainer>

            {activeTab && !authSlice?.isLessonsByCategoryIdLoading && (
              <>
                <S.SmallTitle>
                  {lessonsByCategory?.getAllLessonsByCategoryID?.length} Videos
                  verfügbar
                </S.SmallTitle>
                {lessonsByCategory?.getAllLessonsByCategoryID?.map((lesson) => (
                  <S.Item key={lesson?.id}>
                    <div
                      className="video-box"
                      onClick={() =>
                        history.push("/dashboard/academy/video/" + lesson?.id)
                      }
                    >
                      {!lesson?.thumbnail && (
                        <img
                          src="https://via.placeholder.com/350x200"
                          alt="placeholder"
                        />
                      )}
                      {lesson?.thumbnail && (
                        <img
                          src={getS3ImageUrl(lesson?.thumbnail)}
                          alt="thumbnail"
                        />
                      )}
                    </div>
                    <div className="content">
                      <h6
                        onClick={() =>
                          history.push("/dashboard/academy/video/" + lesson?.id)
                        }
                      >
                        {lesson?.lesson_title}
                      </h6>
                      <p>{lesson?.text_desc}</p>
                      <div className="chip">{lesson?.category_name}</div>
                    </div>
                  </S.Item>
                ))}
              </>
            )}

            {authSlice?.isLessonsByCategoryIdLoading && (
               <>
               <br />
               <br />
               <br />
               <br />
               <div className="text-center">
                 <div className="loader mx-auto"></div>
               </div>
               <br />
               <br />
               <br />
               <br />
             </>
            )}
          </S.Card>
        </S.ClientDetailsContainer>
      </S.MainDashboardContainer>
    </>
  );
};

export default Academy;
