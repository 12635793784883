import styled from 'styled-components';
import {RegistrationContainer ,RegistrationHeading, RegistrationSubHeading} from 'pages/registration/registration.styles';
import {NutritionFormMacroNutrientsDescription} from 'pages/macroNutritients/macroNutrients.styles';

// Images
import NutritionDone from 'assets/images/nutritionDone.svg';

export const NutritionFormDoneContainer = styled(RegistrationContainer)``;

export const NutritionFormDoneHeading = styled(RegistrationHeading)``;

export const NutritionFormDoneSubHeading = styled(RegistrationSubHeading)``;

export const NutritionFormDoneDescription = styled(NutritionFormMacroNutrientsDescription)``;

export const NutritionFormDoneSVG = styled.div`
    width: 553px;
    height: 422px;
    margin: 56px auto 20px;
    background-image: url(${NutritionDone});
`;