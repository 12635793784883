import styled from "styled-components";
import Tick from "assets/images/tick.png";

export const ContentContainer = styled.div`
  background: linear-gradient(119deg, #e5f5f2 0%, #edfbf6 100%);
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 1000px) {
    height: auto;
    padding: 10px;
  }
`

export const LoginContainer = styled.div`
  width: 90%;
  margin: auto;
  height: 95vh;
  display: flex;

  .checkbox-group {
    display: flex;
    text-align: left;
    margin: 10px 0px;

    > * {
      margin-right: 10px;
    }
  }

  @media screen and (max-width: 600px) {
    height: auto;
    padding: 2rem 1rem;
  }

  @media screen and (max-width: 1000px) {
    flex-direction: column-reverse;
    height: auto;
    width: 100%;
  }
`;

export const SliderContainer = styled.div`
  background: white;
  border-radius: 1rem;
  padding: 40px 20px;
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 20px;

  .reset-badges {
    text-align: center;
    img {
      filter: grayscale(100%);
    }
  }

  .slick-arrow {
    display: none !important;
  }

  @media screen and (max-width: 1000px) {
    width: 100%;
    margin-left: 0px;
    margin-bottom: 20px;

    .reset-badges {
      display: none;
    }
  }

  .slide-item {
    p, h4 {
      color: #44554C;
      max-width: 550px;
      margin-inline: auto;
      text-align: center;
      line-height: 25px;
    }

    h4 {
      font-size: 24px;
      font-weight: 400;
    }

    p {
      font-size: 16px;
      font-weight: 300;
    }
  }
`;

export const SlideBg = styled.div`
  width: 100%;
  height: 50vh;
  background: url('${({ image }) => image}') no-repeat;
  background-size: contain;
  background-position: center;

  @media screen and (max-width: 1000px) {
    height: 40vh;
  }
`

export const FormContainer = styled.div`
  background: white;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .checkbox-text {
    font-size: .9rem;

    a {
      color: #2BCA95;
    }
  }

  .input-note {
    margin: 10px 0px;
  }

  .form-group {
    margin-bottom: 10px;
  }
`

export const ChangePasswordInnerContainer = styled.div`
  padding: 2rem;
  text-align: center;

  @media screen and (max-width: 768px) {
    padding: 1rem;
  }
`;

export const ChangePasswordHeading = styled.h4`
  color: #142c1f;
  font-size: 20px;
  font-weight: 400;
  margin: 16px 0 0;
`;

export const ChangePasswordSubHeading = styled.h6`
  color: #525866;
  font-size: 1rem;
  font-weight: 300;
  margin: 0.5rem 0 20px;
`;

export const AlertSuccess = styled.h6`
  color: #0f5132;
  background-color: #d1e7dd;
  border-color: #badbcc;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  font-size: 16px;
  line-height: 24px;
  font-weight: 300;
  margin: 0 0 30px;
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 0.5rem 1rem;
`;

export const AlertDanger = styled.div`
  color: #842029;
  background-color: #f8d7da;
  border-color: #f5c2c7;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  font-size: 16px;
  line-height: 24px;
  font-weight: 300;
  margin: 0 0 30px;
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 0.5rem 1rem;
`;
