import DashboardActionTypes from "./dashboard.types";

// Social Media

// --------------

export const getAllLinkedinPostsRequest = (payload) => ({
  type: DashboardActionTypes.GET_ALL_LINKEDIN_POSTS_REQUEST,
  payload,
});

export const getAllLinkedinPostsSuccess = (payload) => ({
  type: DashboardActionTypes.GET_ALL_LINKEDIN_POSTS_SUCCESS,
  payload,
});

export const getAllLinkedinPostsFailure = (error) => ({
  type: DashboardActionTypes.GET_ALL_LINKEDIN_POSTS_FAILURE,
  payload: error,
});

export const getAllLinkedinPostsLoadingStart = () => ({
  type: DashboardActionTypes.GET_ALL_LINKEDIN_POSTS_LOADING_START,
});

export const getAllLinkedinPostsLoadingStop = () => ({
  type: DashboardActionTypes.GET_ALL_LINKEDIN_POSTS_LOADING_STOP,
});

// --------------

export const getAllLinkedinCategoriesRequest = (payload) => ({
  type: DashboardActionTypes.GET_ALL_LINKEDIN_CATEGORIES_REQUEST,
  payload,
});

export const getAllLinkedinCategoriesSuccess = (payload) => ({
  type: DashboardActionTypes.GET_ALL_LINKEDIN_CATEGORIES_SUCCESS,
  payload,
});

export const getAllLinkedinCategoriesFailure = (error) => ({
  type: DashboardActionTypes.GET_ALL_LINKEDIN_CATEGORIES_FAILURE,
  payload: error,
});

export const getAllLinkedinCategoriesLoadingStart = () => ({
  type: DashboardActionTypes.GET_ALL_LINKEDIN_CATEGORIES_LOADING_START,
});

export const getAllLinkedinCategoriesLoadingStop = () => ({
  type: DashboardActionTypes.GET_ALL_LINKEDIN_CATEGORIES_LOADING_STOP,
});

// --------------

export const getAllLinkedinPostsByCategoriesRequest = (payload) => ({
  type: DashboardActionTypes.GET_ALL_LINKEDIN_POSTS_BY_CATEGORIES_REQUEST,
  payload,
});

export const getAllLinkedinPostsByCategoriesSuccess = (response) => ({
  type: DashboardActionTypes.GET_ALL_LINKEDIN_POSTS_BY_CATEGORIES_SUCCESS,
  payload: response,
});

export const getAllLinkedinPostsByCategoriesFailure = (error) => ({
  type: DashboardActionTypes.GET_ALL_LINKEDIN_POSTS_BY_CATEGORIES_FAILURE,
  payload: error,
});

export const getAllLinkedinPostsByCategoriesLoadingStart = () => ({
  type: DashboardActionTypes.GET_ALL_LINKEDIN_POSTS_BY_CATEGORIES_LOADING_START,
});

export const getAllLinkedinPostsByCategoriesLoadingStop = () => ({
  type: DashboardActionTypes.GET_ALL_LINKEDIN_POSTS_BY_CATEGORIES_LOADING_STOP,
});

// --------------

export const getLinkedinSubCategoriesByCategoryRequest = (payload) => ({
  type: DashboardActionTypes.GET_LINKEDIN_SUB_CATEGORIES_BY_PARENT_CATEGORY_REQUEST,
  payload,
});

export const getLinkedinSubCategoriesByCategorySuccess = (response) => ({
  type: DashboardActionTypes.GET_LINKEDIN_SUB_CATEGORIES_BY_PARENT_CATEGORY_SUCCESS,
  payload: response,
});

export const getLinkedinSubCategoriesByCategoryFailure = (error) => ({
  type: DashboardActionTypes.GET_LINKEDIN_SUB_CATEGORIES_BY_PARENT_CATEGORY_FAILURE,
  payload: error,
});

export const getLinkedinSubCategoriesByCategoryLoadingStart = () => ({
  type: DashboardActionTypes.GET_LINKEDIN_SUB_CATEGORIES_BY_PARENT_CATEGORY_LOADING_START,
});

export const getLinkedinSubCategoriesByCategoryLoadingStop = () => ({
  type: DashboardActionTypes.GET_LINKEDIN_SUB_CATEGORIES_BY_PARENT_CATEGORY_LOADING_STOP,
});

// ------------------- White Paper -------------------

export const getAllWhitePaperDataRequest = (payload) => ({
  type: DashboardActionTypes.GET_ALL_WHITE_PAPER_DATA_REQUEST,
  payload,
});

export const getAllWhitePaperDataSuccess = (response) => ({
  type: DashboardActionTypes.GET_ALL_WHITE_PAPER_DATA_SUCCESS,
  payload: response,
});

export const getAllWhitePaperDataFailure = (error) => ({
  type: DashboardActionTypes.GET_ALL_WHITE_PAPER_DATA_FAILURE,
  payload: error,
});

export const getAllWhitePaperDataLoadingStart = () => ({
  type: DashboardActionTypes.GET_ALL_WHITE_PAPER_DATA_LOADING_START,
});

export const getAllWhitePaperDataLoadingStop = () => ({
  type: DashboardActionTypes.GET_ALL_WHITE_PAPER_DATA_LOADING_STOP,
});

// ------------------- Settings - Pages -------------------

export const getTrainerProfileTempDetailsRequest = (payload) => ({
  type: DashboardActionTypes.TRAINER_PROFILE_TEMP_DETAILS_REQUEST,
  payload,
});

export const getTrainerProfileTempDetailsSuccess = (response) => ({
  type: DashboardActionTypes.TRAINER_PROFILE_TEMP_DETAILS_SUCCESS,
  payload: response,
});

export const getTrainerProfileTempDetailsFailure = (error) => ({
  type: DashboardActionTypes.TRAINER_PROFILE_TEMP_DETAILS_FAILURE,
  payload: error,
});

export const getTrainerProfileTempDetailsLoadingStart = () => ({
  type: DashboardActionTypes.TRAINER_PROFILE_TEMP_DETAILS_LOADING_START,
});

export const getTrainerProfileTempDetailsLoadingStop = () => ({
  type: DashboardActionTypes.TRAINER_PROFILE_TEMP_DETAILS_LOADING_STOP,
});

// ------------------- On Boarding Checks [Dashboard] -------------------

export const getTrainerOnBoardingChecksRequest = (payload) => ({
  type: DashboardActionTypes.GET_TRAINER_ONBOARDING_CHECKS_REQUEST,
  payload,
});

export const getTrainerOnBoardingChecksSuccess = (response) => ({
  type: DashboardActionTypes.GET_TRAINER_ONBOARDING_CHECKS_SUCCESS,
  payload: response,
});

export const getTrainerOnBoardingChecksFailure = (error) => ({
  type: DashboardActionTypes.GET_TRAINER_ONBOARDING_CHECKS_FAILURE,
  payload: error,
});

export const getTrainerOnBoardingChecksLoadingStart = () => ({
  type: DashboardActionTypes.GET_TRAINER_ONBOARDING_CHECKS_LOADING_START,
});

export const getTrainerOnBoardingChecksLoadingStop = () => ({
  type: DashboardActionTypes.GET_TRAINER_ONBOARDING_CHECKS_LOADING_STOP,
});
// -----------
export const updateTrainerOnBoardingCheckRequest = (payload) => ({
  type: DashboardActionTypes.UPDATE_TRAINER_ONBOARDING_CHECK_REQUEST,
  payload,
});

export const updateTrainerOnBoardingCheckSuccess = (response) => ({
  type: DashboardActionTypes.UPDATE_TRAINER_ONBOARDING_CHECK_SUCCESS,
  payload: response,
});

export const updateTrainerOnBoardingCheckFailure = (error) => ({
  type: DashboardActionTypes.UPDATE_TRAINER_ONBOARDING_CHECK_FAILURE,
  payload: error,
});

export const updateTrainerOnBoardingCheckLoadingStart = () => ({
  type: DashboardActionTypes.UPDATE_TRAINER_ONBOARDING_CHECK_LOADING_START,
});

export const updateTrainerOnBoardingCheckLoadingStop = () => ({
  type: DashboardActionTypes.UPDATE_TRAINER_ONBOARDING_CHECK_LOADING_STOP,
});

// -----------
export const getActiveClientsLastDaysRequest = (payload) => ({
  type: DashboardActionTypes.GET_ACTIVE_CLIENTS_LAST_DAYS_REQUEST,
  payload,
});

export const getActiveClientsLastDaysSuccess = (payload) => ({
  type: DashboardActionTypes.GET_ACTIVE_CLIENTS_LAST_DAYS_SUCCESS,
  payload,
});

export const getActiveClientsLastDaysFailure = (error) => ({
  type: DashboardActionTypes.GET_ACTIVE_CLIENTS_LAST_DAYS_FAILURE,
  payload: error,
});

export const getActiveClientsLastDaysLoadingStart = () => ({
  type: DashboardActionTypes.GET_ACTIVE_CLIENTS_LAST_DAYS_LOADING_START,
});

export const getActiveClientsLastDaysLoadingStop = () => ({
  type: DashboardActionTypes.GET_ACTIVE_CLIENTS_LAST_DAYS_LOADING_STOP,
});

// --------------------------------------------

export const getInitialDiscussionChartDataRequest = (payload) => ({
  type: DashboardActionTypes.GET_INITIAL_DISCUSSION_CHART_DATA_REQUEST,
  payload,
});

export const getInitialDiscussionChartDataSuccess = (payload) => ({
  type: DashboardActionTypes.GET_INITIAL_DISCUSSION_CHART_DATA_SUCCESS,
  payload,
});

export const getInitialDiscussionChartDataFailure = (error) => ({
  type: DashboardActionTypes.GET_INITIAL_DISCUSSION_CHART_DATA_FAILURE,
  payload: error,
});

export const getInitialDiscussionChartDataLoadingStart = () => ({
  type: DashboardActionTypes.GET_INITIAL_DISCUSSION_CHART_DATA_LOADING_START,
});

export const getInitialDiscussionChartDataLoadingStop = () => ({
  type: DashboardActionTypes.GET_INITIAL_DISCUSSION_CHART_DATA_LOADING_STOP,
});

export const getInitialDiscussionChartDataPreviousMonthRequest = (payload) => ({
  type: DashboardActionTypes.GET_INITIAL_DISCUSSION_CHART_DATA_PREVIOUS_MONTH_REQUEST,
  payload,
});

export const getInitialDiscussionChartDataPreviousMonthSuccess = (payload) => ({
  type: DashboardActionTypes.GET_INITIAL_DISCUSSION_CHART_DATA_PREVIOUS_MONTH_SUCCESS,
  payload,
});

export const getInitialDiscussionChartDataPreviousMonthFailure = (error) => ({
  type: DashboardActionTypes.GET_INITIAL_DISCUSSION_CHART_DATA_PREVIOUS_MONTH_FAILURE,
  payload: error,
});

// --------------------------------------------

export const getActiveClientsCurrentMonthChartDataRequest = (payload) => ({
  type: DashboardActionTypes.GET_ACTIVE_CLIENTS_CURRENT_MONTH_CHART_DATA_REQUEST,
  payload,
});

export const getClientsCurrentMonthChartDataRequest = (payload) => ({
  type: DashboardActionTypes.GET_CLIENTS_CURRENT_MONTH_CHART_DATA_REQUEST,
  payload,
});

export const getActiveClientsCurrentMonthChartDataSuccess = (payload) => ({
  type: DashboardActionTypes.GET_ACTIVE_CLIENTS_CURRENT_MONTH_CHART_DATA_SUCCESS,
  payload,
});

export const getActiveClientsCurrentMonthChartDataFailure = (error) => ({
  type: DashboardActionTypes.GET_ACTIVE_CLIENTS_CURRENT_MONTH_CHART_DATA_FAILURE,
  payload: error,
});

export const getActiveClientsChartDataLoadingStart = () => ({
  type: DashboardActionTypes.GET_ACTIVE_CLIENTS_MONTH_CHART_DATA_LOADING_START,
});

export const getActiveClientsChartDataLoadingStop = () => ({
  type: DashboardActionTypes.GET_ACTIVE_CLIENTS_MONTH_CHART_DATA_LOADING_STOP,
});

export const getActiveClientsPreviousMonthChartDataRequest = (payload) => ({
  type: DashboardActionTypes.GET_ACTIVE_CLIENTS_PREVIOUS_MONTH_CHART_DATA_REQUEST,
  payload,
});

export const getActiveClientsPreviousMonthChartDataSuccess = (payload) => ({
  type: DashboardActionTypes.GET_ACTIVE_CLIENTS_PREVIOUS_MONTH_CHART_DATA_SUCCESS,
  payload,
});

export const getActiveClientsPreviousMonthChartDataFailure = (error) => ({
  type: DashboardActionTypes.GET_ACTIVE_CLIENTS_PREVIOUS_MONTH_CHART_DATA_FAILURE,
  payload: error,
});

// --------------------------------------------

export const getSalesCurrentMonthChartDataRequest = (payload) => ({
  type: DashboardActionTypes.GET_SALES_CURRENT_MONTH_CHART_DATA_REQUEST,
  payload,
});

export const getSalesCurrentMonthChartDataSuccess = (payload) => ({
  type: DashboardActionTypes.GET_SALES_CURRENT_MONTH_CHART_DATA_SUCCESS,
  payload,
});

export const getSalesCurrentMonthChartDataFailure = (error) => ({
  type: DashboardActionTypes.GET_SALES_CURRENT_MONTH_CHART_DATA_FAILURE,
  payload: error,
});

export const getSalesChartDataLoadingStart = () => ({
  type: DashboardActionTypes.GET_SALES_MONTH_CHART_DATA_LOADING_START,
});

export const getSalesChartDataLoadingStop = () => ({
  type: DashboardActionTypes.GET_SALES_MONTH_CHART_DATA_LOADING_STOP,
});

export const getSalesPreviousMonthChartDataRequest = (payload) => ({
  type: DashboardActionTypes.GET_SALES_PREVIOUS_MONTH_CHART_DATA_REQUEST,
  payload,
});

export const getSalesPreviousMonthChartDataSuccess = (payload) => ({
  type: DashboardActionTypes.GET_SALES_PREVIOUS_MONTH_CHART_DATA_SUCCESS,
  payload,
});

export const getSalesPreviousMonthChartDataFailure = (error) => ({
  type: DashboardActionTypes.GET_SALES_PREVIOUS_MONTH_CHART_DATA_FAILURE,
  payload: error,
});

// --------------------------------------------

export const getUserTransactionRequest = (payload) => ({
  type: DashboardActionTypes.GET_USER_TRANSACTION_REQUEST,
  payload,
});

export const getUserTransactionSuccess = (payload) => ({
  type: DashboardActionTypes.GET_USER_TRANSACTION_SUCCESS,
  payload,
});

export const getUserTransactionFailure = (error) => ({
  type: DashboardActionTypes.GET_USER_TRANSACTION_FAILURE,
  payload: error,
});

export const getUserTransactionLoadingStart = () => ({
  type: DashboardActionTypes.GET_USER_TRANSACTION_LOADING_START,
});

export const getUserTransactionLoadingStop = () => ({
  type: DashboardActionTypes.GET_USER_TRANSACTION_LOADING_STOP,
});

// // --------------------------------------------

// export const createHabbitRequest = (payload) => ({
//   type: DashboardActionTypes.CREATE_HABBIT_REQUEST,
//   payload,
// });

// export const createHabbitSuccess = (payload) => ({
//   type: DashboardActionTypes.CREATE_HABBIT_SUCCESS,
//   payload,
// });

// export const createHabbitFailure = (error) => ({
//   type: DashboardActionTypes.CREATE_HABBIT_FAILURE,
//   payload: error,
// });

// export const createHabbitLoadingStart = () => ({
//   type: DashboardActionTypes.CREATE_HABBIT_LOADING_START,
// });

// export const createHabbitLoadingStop = () => ({
//   type: DashboardActionTypes.CREATE_HABBIT_LOADING_STOP,
// });

// // --------------------------------------------

// export const createJournalRequest = (payload) => ({
//   type: DashboardActionTypes.CREATE_JOURNAL_REQUEST,
//   payload,
// });

// export const createJournalSuccess = (payload) => ({
//   type: DashboardActionTypes.CREATE_JOURNAL_SUCCESS,
//   payload,
// });

// export const createJournalFailure = (error) => ({
//   type: DashboardActionTypes.CREATE_JOURNAL_FAILURE,
//   payload: error,
// });

// export const createJournalLoadingStart = () => ({
//   type: DashboardActionTypes.CREATE_JOURNAL_LOADING_START,
// });

// export const createJournalLoadingStop = () => ({
//   type: DashboardActionTypes.CREATE_JOURNAL_LOADING_STOP,
// });

// --------------------------------------------

export const getHabbitRequest = (payload) => ({
  type: DashboardActionTypes.GET_HABBIT_REQUEST,
  payload,
});

export const getHabbitSuccess = (payload) => ({
  type: DashboardActionTypes.GET_HABBIT_SUCCESS,
  payload,
});

export const getHabbitFailure = (error) => ({
  type: DashboardActionTypes.GET_HABBIT_FAILURE,
  payload: error,
});

export const getHabbitLoadingStart = () => ({
  type: DashboardActionTypes.GET_HABBIT_LOADING_START,
});

export const getHabbitLoadingStop = () => ({
  type: DashboardActionTypes.GET_HABBIT_LOADING_STOP,
});

// --------------------------------------------

export const getJournalRequest = (payload) => ({
  type: DashboardActionTypes.GET_JOURNAL_REQUEST,
  payload,
});

export const getJournalSuccess = (payload) => ({
  type: DashboardActionTypes.GET_JOURNAL_SUCCESS,
  payload,
});

export const getJournalFailure = (error) => ({
  type: DashboardActionTypes.GET_JOURNAL_FAILURE,
  payload: error,
});

export const getJournalLoadingStart = () => ({
  type: DashboardActionTypes.GET_JOURNAL_LOADING_START,
});

export const getJournalLoadingStop = () => ({
  type: DashboardActionTypes.GET_JOURNAL_LOADING_STOP,
});

// ------------------Experts--------------------------

export const getExpertsCallsRequest = (payload) => ({
  type: DashboardActionTypes.GET_EXPERTS_CALLS_REQUEST,
  payload,
});

export const getExpertsCallsSuccess = (payload) => ({
  type: DashboardActionTypes.GET_EXPERTS_CALLS_SUCCESS,
  payload,
});

export const getExpertsCallsFailure = (error) => ({
  type: DashboardActionTypes.GET_EXPERTS_CALLS_FAILURE,
  payload: error,
});

export const getExpertsCallsLoadingStart = () => ({
  type: DashboardActionTypes.GET_EXPERTS_CALLS_LOADING_START,
});

export const getExpertsCallsLoadingStop = () => ({
  type: DashboardActionTypes.GET_EXPERTS_CALLS_LOADING_STOP,
});

// ------------------User Weight Data--------------------------

export const getUserWeightDataRequest = (payload) => ({
  type: DashboardActionTypes.GET_USER_WEIGHT_DATA_REQUEST,
  payload,
});

export const getUserWeightDataSuccess = (payload) => ({
  type: DashboardActionTypes.GET_USER_WEIGHT_DATA_SUCCESS,
  payload,
});

export const getUserWeightDataFailure = (error) => ({
  type: DashboardActionTypes.GET_USER_WEIGHT_DATA_FAILURE,
  payload: error,
});

export const getUserWeightDataLoadingStart = () => ({
  type: DashboardActionTypes.GET_USER_WEIGHT_DATA_LOADING_START,
});

export const getUserWeightDataLoadingStop = () => ({
  type: DashboardActionTypes.GET_USER_WEIGHT_DATA_LOADING_STOP,
});

// ------------------Trainer invoices--------------------------

export const getTrainerInvoicesRequest = (payload) => ({
  type: DashboardActionTypes.GET_TRAINER_INVOICES_REQUEST,
  payload,
});

export const getTrainerInvoicesSuccess = (payload) => ({
  type: DashboardActionTypes.GET_TRAINER_INVOICES_SUCCESS,
  payload,
});

export const getTrainerInvoicesFailure = (error) => ({
  type: DashboardActionTypes.GET_TRAINER_INVOICES_FAILURE,
  payload: error,
});

export const getTrainerInvoicesLoadingStart = () => ({
  type: DashboardActionTypes.GET_TRAINER_INVOICES_LOADING_START,
});

export const getTrainerInvoicesLoadingStop = () => ({
  type: DashboardActionTypes.GET_TRAINER_INVOICES_LOADING_STOP,
});

// ------------------Trainer Credits--------------------------

export const getTrainerCreditsRequest = (payload) => ({
  type: DashboardActionTypes.GET_TRAINER_CREDITS_REQUEST,
  payload,
});

export const getTrainerCreditsSuccess = (payload) => ({
  type: DashboardActionTypes.GET_TRAINER_CREDITS_SUCCESS,
  payload,
});

export const getTrainerCreditsFailure = (error) => ({
  type: DashboardActionTypes.GET_TRAINER_CREDITS_FAILURE,
  payload: error,
});

export const getTrainerCreditsLoadingStart = () => ({
  type: DashboardActionTypes.GET_TRAINER_CREDITS_LOADING_START,
});

export const getTrainerCreditsLoadingStop = () => ({
  type: DashboardActionTypes.GET_TRAINER_CREDITS_LOADING_STOP,
});

// ------------------Send Email--------------------------

export const sendEmailRequest = (payload) => ({
  type: DashboardActionTypes.SEND_EMAIL_REQUEST,
  payload,
});

export const sendEmailSuccess = (payload) => ({
  type: DashboardActionTypes.SEND_EMAIL_SUCCESS,
  payload,
});

export const sendEmailFailure = (error) => ({
  type: DashboardActionTypes.SEND_EMAIL_FAILURE,
  payload: error,
});

export const sendEmailNull = () => ({
  type: DashboardActionTypes.SEND_EMAIL_NULL,
});

export const sendEmailLoadingStart = () => ({
  type: DashboardActionTypes.SEND_EMAIL_LOADING_START,
});

export const sendEmailLoadingStop = () => ({
  type: DashboardActionTypes.SEND_EMAIL_LOADING_STOP,
});

// ------------------ Trainer News --------------------------

export const getTrainerNewsRequest = (payload) => ({
  type: DashboardActionTypes.GET_TRAINER_NEWS_REQUEST,
  payload,
});

export const getTrainerNewsSuccess = (payload) => ({
  type: DashboardActionTypes.GET_TRAINER_NEWS_SUCCESS,
  payload,
});

export const getTrainerNewsFailure = (error) => ({
  type: DashboardActionTypes.GET_TRAINER_NEWS_FAILURE,
  payload: error,
});

export const getTrainerNewsLoadingStart = () => ({
  type: DashboardActionTypes.GET_TRAINER_NEWS_LOADING_START,
});

export const getTrainerNewsLoadingStop = () => ({
  type: DashboardActionTypes.GET_TRAINER_NEWS_LOADING_STOP,
});

// ------------------ Update Goal Weight --------------------------

export const updateGoalWeightRequest = (payload) => ({
  type: DashboardActionTypes.UPDATE_GOAL_WEIGHT_REQUEST,
  payload,
});

export const updateGoalWeightSuccess = (payload) => ({
  type: DashboardActionTypes.UPDATE_GOAL_WEIGHT_SUCCESS,
  payload,
});

export const updateGoalWeightFailure = (error) => ({
  type: DashboardActionTypes.UPDATE_GOAL_WEIGHT_FAILURE,
  payload: error,
});

export const updateGoalWeightLoadingStart = () => ({
  type: DashboardActionTypes.UPDATE_GOAL_WEIGHT_LOADING_START,
});

export const updateGoalWeightLoadingStop = () => ({
  type: DashboardActionTypes.UPDATE_GOAL_WEIGHT_LOADING_STOP,
});

export const updateSelectedGoalRequest = (payload) => ({
  type: DashboardActionTypes.UPDATE_SELECTED_GOAL_REQUEST,
  payload,
});


// ------------------ Stripe Connected Account Status --------------------------

export const checkStripeConnectedAccountStatusRequest = (payload) => ({
  type: DashboardActionTypes.CHECK_STRIPE_CONNECTED_ACCOUNT_STATUS_REQUEST,
  payload,
});

export const checkStripeConnectedAccountStatusSuccess = (payload) => ({
  type: DashboardActionTypes.CHECK_STRIPE_CONNECTED_ACCOUNT_STATUS_SUCCESS,
  payload,
});

export const checkStripeConnectedAccountStatusFailure = (error) => ({
  type: DashboardActionTypes.CHECK_STRIPE_CONNECTED_ACCOUNT_STATUS_FAILURE,
  payload: error,
});

export const checkStripeConnectedAccountStatusLoadingStart = () => ({
  type: DashboardActionTypes.CHECK_STRIPE_CONNECTED_ACCOUNT_STATUS_LOADING_START,
});

export const checkStripeConnectedAccountStatusLoadingStop = () => ({
  type: DashboardActionTypes.CHECK_STRIPE_CONNECTED_ACCOUNT_STATUS_LOADING_STOP,
});
