import React, { useState, useEffect, useRef } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import * as S from "./SetPassword.styles";
import { useDispatch, useSelector } from "react-redux";
import Slider from "react-slick";
// Components
import Card from "components/card/Card";
import Input from "components/formComponents/input/Input";
import Button from "components/formComponents/button/Button";
import Loader from "components/loader/Loader";
import ErrorMessage from "components/formComponents/errorMessage/ErrorMessage";
import FoodiaryLogo from "assets/images/Foodiary-Logo.PNG";
import DashboardOne from "assets/images/Foodiary-set-password-slide1.svg";
import DashboardTwo from "assets/images/Foodiary-set-password-slide2.svg";
import DashboardThree from "assets/images/Foodiary-set-password-slide3.svg";
import ResetBadges from "assets/images/reset-password-badges.svg";
import ExclamationCircle from "assets/images/exclamation-circle.svg";
// Redux
import * as NutritionActions from "redux/nutrition/nutrition.actions";
import SquareCheckbox from "components/formComponents/checkbox/SquareCheckbox";
import { sleep } from "helper";

const SetPassword = () => {
  const dispatch = useDispatch();
  const [IsFormSubmitSuccessfuly, setIsFormSubmitSuccessfuly] = useState(false);
  const [IsFormSubmitError, setIsFormSubmitError] = useState(false);
  const [isChecked, setIsChecked] = useState({ firstCheck: false, secondCheck: false })
  const [isCheckedErrors, setIsCheckedErrors] = useState({ firstCheck: false, secondCheck: false })
  const [location, setLocation] = useState({
    latitude: null,
    longitude: null,
  })
  const [loading, setLoading] = useState(false)
  const formikRef = useRef();
  const setUserPassword = useSelector(
    (state) => state.nutrition.setUserPassword
  );
  const auth = useSelector((state) => state.auth);
  console.log(auth);
  





  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        setLocation({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        })
      });
    }
  }, [])
  function failedResponse() {
    setLoading(false);
    setIsFormSubmitError(true);
  }
  function succesResponse() {
      setLoading(false);
      setIsFormSubmitSuccessfuly(true);
      dispatch(NutritionActions.setPasswordNull());
      sleep(2000).then(() => {
        setLoading(false);
        window.location.assign("https://my.foodiary.app/");
          })
}
  const UpdateUserDataApiCall = (booleanValue) => {
    if (!booleanValue) return failedResponse()
    const payload = {
      email: formikRef.current.values.email,
      revocation_excluded: isChecked.firstCheck,
      terms_accepted: isChecked.secondCheck,
      latitude: String(location.latitude),
      longitude: String(location.longitude),
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      network_type: navigator.connection?.effectiveType || 'unknown',
      screen_resolution: `${window.screen.width} x ${window.screen.height} pixels`,
      language_settings: navigator.language || 'unknown'
    }
    dispatch(NutritionActions.updateUserInformationRequest({
      payload, callback: () => { }
        
    }))
  }



  const initialValues = {
    email: "",
    prename: "",
    password: "",
    confirmPassword: "",
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("E-Mail muss bei uns registriert sein")
      .required("Erforderlich"),
    prename: Yup.string().required("Erforderlich"),
    password: Yup.string()
      .required("Erforderlich")
      .min(8, "Passwort muss mindestens 8 Zeichen lang sein"),
    confirmPassword: Yup.string()
      .required("Erforderlich")
      .oneOf([Yup.ref("password"), null], "Passwort muss übereinstimmen")
  });

  const submitClicked = () => (
    setIsCheckedErrors({
      firstCheck: !isChecked.firstCheck,
      secondCheck: !isChecked.secondCheck
    })
  )

  const onFormSubmit = (values, { resetForm }) => {
    if (!isChecked.firstCheck || !isChecked.secondCheck) return
    if (IsFormSubmitError) setIsFormSubmitError(false)
    setLoading(true);

    dispatch(
      NutritionActions.setPasswordRequest({
        formValues: {
          email: values.email,
          prename: values.prename,
          password: values.password,
          confirm_password: values.confirmPassword,
        },
        func: (res) => {
          if (res?.success) {
            succesResponse()
            UpdateUserDataApiCall(true)
          }
          else {
            UpdateUserDataApiCall(false)
          }
        },
      })
    );
    // resetForm();
  };

  let settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };

  return (
    <>
      <S.ContentContainer>
        <S.LoginContainer>
          <S.FormContainer>
            <S.ChangePasswordInnerContainer className="relative">
              <img width={90} src={FoodiaryLogo} alt="" />
              <S.ChangePasswordHeading>
                Dein Profil aktivieren
              </S.ChangePasswordHeading>
              <S.ChangePasswordSubHeading>
                Setzte dein Passwort und schalte Foodiary frei.
              </S.ChangePasswordSubHeading>
              {IsFormSubmitSuccessfuly && (
                <S.AlertSuccess>Foodiary wurde aktiviert, du wirst direkt zum Login geleitet.</S.AlertSuccess>
              )}
              {IsFormSubmitError && (
                <S.AlertDanger>
                  Dein Passwort konnte nicht geändert werden
                </S.AlertDanger>
              )}
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onFormSubmit}
                innerRef={formikRef}
              >
                {({
                  handleSubmit,
                  handleChange,
                  handleBlur,
                  values,
                  touched,
                  errors,
                  isValid,
                  dirty,
                }) => (
                  <form
                    className="relative z-1"
                    noValidate
                    autoComplete="off"
                    onSubmit={handleSubmit}
                  >
                    <div className="form-group">
                      <label>E-Mail Adresse:*</label>
                      <Input
                        className="input-v2"
                        type="text"
                        placeholder="hello@foodiary.app"
                        name="email"
                        onChange={handleChange}
                        value={values.email}
                      />
                      <ErrorMessage error={errors.email} />
                      <p className="input-note">
                        <img src={ExclamationCircle} alt="" />
                        <span>Gebe die E-Mail ein, mit der du dich registriert hast.</span>
                      </p>
                    </div>

                    <div className="form-group">
                      <label>Vorname*</label>
                      <Input
                        className="input-v2"
                        type="text"
                        placeholder="Vorname"
                        name="prename"
                        onChange={handleChange}
                        value={values.prename}
                      />
                      <ErrorMessage error={errors.prename} />
                    </div>

                    <div className="form-group">
                      <label>Passwort festlegen:*</label>
                      <Input
                        className="input-v2"
                        type="password"
                        placeholder="neues Passwort"
                        name="password"
                        onChange={handleChange}
                        value={values.password}
                      />
                      <ErrorMessage error={errors.password} />
                    </div>
                    <div className="form-group">
                      <label>Passwort bestätigen:*</label>
                      <Input
                        className="input-v2"
                        type="password"
                        placeholder="neues Passwort"
                        name="confirmPassword"
                        onChange={handleChange}
                        value={values.confirmPassword}
                      />
                      <ErrorMessage error={errors.confirmPassword} />
                    </div>

                    <div className="form-group">
                      <div className="checkbox-group">
                        <SquareCheckbox
                          rounded="5px"
                          filled="#FFF"
                          isChecked={isChecked.firstCheck}
                          name="firstCheck"
                          param="firstCheck"
                          onClick={() => {
                            setIsChecked({ ...isChecked, firstCheck: !isChecked.firstCheck })
                          }}
                        />
                        <p className="checkbox-text text-dark-1 font-rubik-regular my-0">
                          Ich möchte mein Coaching direkt starten und die Leistung in Anspruch nehmen.
                        </p>
                      </div>
                    </div>
                    {isCheckedErrors.firstCheck && (
                      <div className="text-left">
                        <span className="text-size-14-17 mb-0 text-red mt-1">Erforderlich</span>
                      </div>
                    )}

                    <div className="checkbox-group">
                      <SquareCheckbox
                        rounded="5px"
                        filled="#FFF"
                        isChecked={isChecked.secondCheck}
                        param="secondCheck"
                        name="secondCheck"
                        onClick={() => {
                          setIsChecked({ ...isChecked, secondCheck: !isChecked.secondCheck })
                        }}
                      />
                      <p className="checkbox-text text-dark-1 font-rubik-regular my-0">
                        Ich stimme den <a target="_blank" href="https://foodiary.app/terms/agb-coaching/" rel="noreferrer">AGB Foodiary Coaching</a> und dem <a target="_blank" href="https://foodiary.app/terms/datenschutz/" rel="noreferrer">Datenschutz</a> von Foodiary zu.
                      </p>
                    </div>
                    {isCheckedErrors.secondCheck && (
                      <div className="text-left">
                        <span className="text-size-14-17 mb-0 text-red mt-1">Erforderlich</span>
                      </div>
                    )}

                    <Button
                      color="rgba(248, 164, 146, 1)"
                      gradient="linear-gradient(60deg, rgba(46, 207, 150, 1), rgba(28, 157, 135, 1)) !important"
                      style={{ fontSize: "16px" }}
                      margin="30px 0 20px"
                      absolute={{ width: "100%", height: "45px" }}
                      buttonStyle={{ width: "100%" }}
                      onClick={submitClicked}
                    >
                      Foodiary freischalten
                    </Button>
                    <Loader loading={loading} top="-135px" />
                  </form>
                )}
              </Formik>
            </S.ChangePasswordInnerContainer>
          </S.FormContainer>

          <S.SliderContainer>
            <Slider {...settings}>
              <div className="slide-item">
                <S.SlideBg image={DashboardOne} />
                <h4>Lern-Academy</h4>
                <p>Wir helfen dir, dein Wunschgewicht zu erreichen und ein gesundes Leben voller Energie und Selbstvertrauen zu führen.</p>
              </div>
              <div className="slide-item">
                <S.SlideBg image={DashboardTwo} />
                <h4>Dashboard</h4>
                <p>
                  Das Dashboard zeigt dir eine strucktuierte Übersicht deines
                  Lernerfolges an. Zudem ist es mit der Ernährungsplaner-App
                  verknüpft.
                </p>
              </div>
              <div className="slide-item">
                <S.SlideBg image={DashboardThree} />
                <h4>Dein Foodiary Coach</h4>
                <p>Wir helfen dir, dein Wunschgewicht zu erreichen und ein gesundes Leben voller Energie und Selbstvertrauen zu führen.</p>
              </div>
            </Slider>
            <div className="reset-badges">
              <img src={ResetBadges} alt="" />
            </div>
          </S.SliderContainer>
        </S.LoginContainer>
      </S.ContentContainer>
    </>
  );
};

export default SetPassword;
