import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getS3ImageUrl } from "configs/utils";
import { ToastContainer, toast } from "react-toastify";
// Images
import DownloadIcon from "assets/images/download.svg";
import PDFIcon from "assets/images/pdf.svg";
import InnerSideNav from "pages/NewDasboard/components/InnerSideNav/InnerSide";
import PasswordResetModal from "components/PasswordResetModal/PasswordResetModal";
// Redux
import * as DashboardActions from "redux/dashboard/dashboard.actions";
import * as S from "./trainerProfileDetails.styles";
import { MENUS } from "./data";

const TrainerInvoices = () => {
  const dispatch = useDispatch();

  const [passwordResetModal, setPasswordResetModal] = useState(false);

  const trainerProfileData = useSelector((state) => state.auth.trainerData);
  const trainerInvoices = useSelector(
    (state) => state.dashboard.trainerInvoices
  );
  const isTrainerInvoicesLoading = useSelector(
    (state) => state.dashboard.isTrainerInvoicesLoading
  );

  useEffect(() => {
    getTrainerInvoices();
  }, []);

  const getTrainerInvoices = () => {
    dispatch(
      DashboardActions.getTrainerInvoicesRequest({
        payload: {
          trainerId: trainerProfileData?.trainerID,
        },
        callback: () => null,
      })
    );
  };

  const onInvoice = (invoice) => {
    window.open(invoice?.file_url, "_blank");
  };

  const renderMonthYear = (invoice) => {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const monthIndex = parseInt(invoice?.month, 10) - 1; // Adjust month to be zero-indexed
    const formattedDate = `${monthNames[monthIndex]} ${invoice?.year}`;

    return formattedDate;
  };

  return (
    <>
      
      <div className="second-sidebar-wrapper">
        <InnerSideNav
          menus={MENUS}
          userProfileInfo={{
            profilePic: getS3ImageUrl(trainerProfileData.profilePic),
            franchiseId: trainerProfileData?.FranchiseID,
            stripeId: trainerProfileData?.["Stripe Connect ID"] || "",
            prename: trainerProfileData.prename,
            surname: trainerProfileData.surname,
            connected_user_account_id:
              trainerProfileData?.connected_user_account_id,
          }}
          onPasswordLink={() => setPasswordResetModal(true)}
          idsSection
        ></InnerSideNav>
      </div>
      <PasswordResetModal
        show={passwordResetModal}
        setShow={setPasswordResetModal}
      />
      <div className="second-sidebar-content">
        <S.TrainerProfileDetailsOverallContainer>
          <S.InvoicesCard>
            <S.Header className="p-0">
              <div>
                <S.TrainerProfileDetailsSectionHeading>
                  Gutschriften
                </S.TrainerProfileDetailsSectionHeading>
                <S.TrainerProfileDetailsSectionSubHeading>
                  Hier findest du die monatlichen Gutschriften für deine
                  Steuerunterlagen.
                </S.TrainerProfileDetailsSectionSubHeading>
              </div>
            </S.Header>
            <br />
            <S.Divider />
            <br />
            {isTrainerInvoicesLoading && (
              <>
                <br />
                <br />
                <br />
                <br />
                <div className="text-center">
                  <div className="loader mx-auto"></div>
                </div>
                <br />
                <br />
                <br />
                <br />
              </>
            )}

            {!isTrainerInvoicesLoading && (
              <>
                <S.InvoiceItem className="header">
                  <span className="date">Abrechnungsmonat</span>
                  <span className="pdf">Dokumententyp</span>
                  <span className="download">Download</span>
                </S.InvoiceItem>
                {trainerInvoices?.data?.map((invoice) => (
                  <>
                    <S.InvoiceItem>
                      <span className="date">{renderMonthYear(invoice)}</span>
                      <span className="pdf" onClick={() => onInvoice(invoice)}>
                        <img src={PDFIcon} alt="PDF" /> PDF
                      </span>
                      <span
                        className="download"
                        onClick={() => onInvoice(invoice)}
                      >
                        <img src={DownloadIcon} alt="download" /> Herunterladen
                      </span>
                    </S.InvoiceItem>
                  </>
                ))}
              </>
            )}
          </S.InvoicesCard>
        </S.TrainerProfileDetailsOverallContainer>
      </div>
    </>
  );
};

export default TrainerInvoices;
