import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  areAllObjectsValuesNull,
  getUserRegistrationDataFromLocalStorage,
  setUserRegistrationDataInLocalStorage,
} from "configs/utils";

// Components
import Button from "components/formComponents/button/Button";
import TranslationMessage from "configs/translations/TranslationMessage";

import FoodiaryLogo from "assets/images/Foodiary-Logo.PNG";
import ArrowLeft from "assets/images/arrowLeft.svg";
import { SidebarSteps, DietItemsLists } from "../../data";
// Redux
import * as AuthActions from "redux/auth/auth.actions";
import * as S from "../../OnBoardingPartOne.styles";
import OnboardingSidebar from "pages/OnBoardingPartOne/OnboardingSidebar";

const SelectedDietItems = {
  "vegan-1": DietItemsLists["vegan-1"][0],
  "vegan-2": DietItemsLists["vegan-2"][0],
  "vegan-3": DietItemsLists["vegan-3"][0],
  "vegan-4": DietItemsLists["vegan-4"][0],
  "vegan-5": DietItemsLists["vegan-5"][0],
  "vegetarisch-1": DietItemsLists["vegetarisch-1"][0],
  "vegetarisch-2": DietItemsLists["vegetarisch-2"][0],
  "vegetarisch-3": DietItemsLists["vegetarisch-3"][0],
  "vegetarisch-4": DietItemsLists["vegetarisch-4"][0],
  "vegetarisch-5": DietItemsLists["vegetarisch-5"][0],
  "fleischhaltig-1": DietItemsLists["fleischhaltig-1"][0],
  "fleischhaltig-2": DietItemsLists["fleischhaltig-2"][0],
  "fleischhaltig-3": DietItemsLists["fleischhaltig-3"][0],
  "fleischhaltig-4": DietItemsLists["fleischhaltig-4"][0],
  "fleischhaltig-5": DietItemsLists["fleischhaltig-5"][0],
};

const EatingHabitsOne = (props) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  // const registrationData = useSelector((state) => state.auth.registrationData);

  const [EatingHabitVeganOne, SetEatingHabitVeganOne] = useState(
    // DietItemsLists["vegan-1"][0]
    null
  );
  const [EatingHabitVeganTwo, SetEatingHabitVeganTwo] = useState(
    // DietItemsLists["vegan-2"][0]
    null
  );
  const [EatingHabitVeganThree, SetEatingHabitVeganThree] = useState(
    // DietItemsLists["vegan-3"][0]
    null
  );
  const [EatingHabitVeganFour, SetEatingHabitVeganFour] = useState(
    // DietItemsLists["vegan-4"][0]
    null
  );
  const [EatingHabitVeganFive, SetEatingHabitVeganFive] = useState(
    // DietItemsLists["vegan-5"][0]
    null
  );

  const [EatingHabitVegaterishOne, SetEatingHabitVegaterishOne] = useState(
    // DietItemsLists["vegetarisch-1"][0]
    null
  );
  const [EatingHabitVegaterishTwo, SetEatingHabitVegaterishTwo] = useState(
    // DietItemsLists["vegetarisch-2"][0]
    null
  );
  const [EatingHabitVegaterishThree, SetEatingHabitVegaterishThree] = useState(
    // DietItemsLists["vegetarisch-3"][0]
    null
  );
  const [EatingHabitVegaterishFour, SetEatingHabitVegaterishFour] = useState(
    // DietItemsLists["vegetarisch-4"][0]
    null
  );
  const [EatingHabitVegaterishFive, SetEatingHabitVegaterishFive] = useState(
    // DietItemsLists["vegetarisch-5"][0]
    null
  );

  const [EatingHabitFleischhaltigOne, SetEatingHabitFleischhaltigOne] =
    useState(
      // DietItemsLists["fleischhaltig-1"][0]
      null
    );
  const [EatingHabitFleischhaltigTwo, SetEatingHabitFleischhaltigTwo] =
    useState(
      // DietItemsLists["fleischhaltig-2"][0]
      null
    );
  const [EatingHabitFleischhaltigThree, SetEatingHabitFleischhaltigThree] =
    useState(
      // DietItemsLists["fleischhaltig-3"][0]
      null
    );
  const [EatingHabitFleischhaltigFour, SetEatingHabitFleischhaltigFour] =
    useState(
      // DietItemsLists["fleischhaltig-4"][0]
      null
    );
  const [EatingHabitFleischhaltigFive, SetEatingHabitFleischhaltigFive] =
    useState(
      // DietItemsLists["fleischhaltig-5"][0]
      null
    );

  const [IsValidation, SetIsValidation] = useState(false);

  const SetEatingHabitFunctions = {
    "vegan-1": SetEatingHabitVeganOne,
    "vegan-2": SetEatingHabitVeganTwo,
    "vegan-3": SetEatingHabitVeganThree,
    "vegan-4": SetEatingHabitVeganFour,
    "vegan-5": SetEatingHabitVeganFive,
    "vegetarisch-1": SetEatingHabitVegaterishOne,
    "vegetarisch-2": SetEatingHabitVegaterishTwo,
    "vegetarisch-3": SetEatingHabitVegaterishThree,
    "vegetarisch-4": SetEatingHabitVegaterishFour,
    "vegetarisch-5": SetEatingHabitVegaterishFive,
    "fleischhaltig-1": SetEatingHabitFleischhaltigOne,
    "fleischhaltig-2": SetEatingHabitFleischhaltigTwo,
    "fleischhaltig-3": SetEatingHabitFleischhaltigThree,
    "fleischhaltig-4": SetEatingHabitFleischhaltigFour,
    "fleischhaltig-5": SetEatingHabitFleischhaltigFive,
  };

  useEffect(() => {
    console.log("Selected Eating Habit : ", getSelectedHabitItem(id));
    // console.log("DietItemsLists: ", DietItemsLists[id]);

    const selectedDietItemsFromLocalStorage = JSON.parse(
      localStorage.getItem("SelectedDietItems")
    );

    if (areAllObjectsValuesNull(selectedDietItemsFromLocalStorage || {})) {
      // localStorage.setItem(
      //   "SelectedDietItems",
      //   JSON.stringify({
      //     ...SelectedDietItems,
      //   })
      // );
    } else {
      setEatingHabitsFromLocalStorage(selectedDietItemsFromLocalStorage);
    }
  }, []);

  const setEatingHabitsFromLocalStorage = (selectedDietItems) => {
    Object.keys(SetEatingHabitFunctions).forEach((key) => {
      if (selectedDietItems[key] !== undefined) {
        SetEatingHabitFunctions[key](selectedDietItems[key]);
      }
    });
  };

  const getSelectedHabitItem = (eatingHabitId) => {
    const selectedDietItems = {
      vegan: [
        EatingHabitVeganOne,
        EatingHabitVeganTwo,
        EatingHabitVeganThree,
        EatingHabitVeganFour,
        EatingHabitVeganFive,
      ],
      vegetarisch: [
        EatingHabitVegaterishOne,
        EatingHabitVegaterishTwo,
        EatingHabitVegaterishThree,
        EatingHabitVegaterishFour,
        EatingHabitVegaterishFive,
      ],
      fleischhaltig: [
        EatingHabitFleischhaltigOne,
        EatingHabitFleischhaltigTwo,
        EatingHabitFleischhaltigThree,
        EatingHabitFleischhaltigFour,
        EatingHabitFleischhaltigFive,
      ],
    };

    const [prefix, index] = eatingHabitId.split("-");
    const selectedIndex = parseInt(index, 10) - 1;

    return selectedDietItems[prefix][selectedIndex];
  };

  const onSelectHabit = (item) => {
    SetIsValidation(false);
    const setEatingHabitFunction = SetEatingHabitFunctions[id];

    if (setEatingHabitFunction) {
      setEatingHabitFunction(item);
      saveSelectedDietItemsInLocalStorage(id, item);
    }
  };

  const saveSelectedDietItemsInLocalStorage = (key, selectedItem) => {
    const existingData = JSON.parse(localStorage.getItem("SelectedDietItems"));
    localStorage.setItem(
      "SelectedDietItems",
      JSON.stringify({
        ...existingData,
        [key]: selectedItem,
      })
    );
  };

  const getDietCategoryData = (data, dietCategory) => {
    const dietCategoriesValues = [];

    for (const [key, value] of Object.entries(data)) {
      console.log(`${key}: ${value}`);

      if (key.toLowerCase().includes(dietCategory.title.toLowerCase())) {
        dietCategoriesValues.push(value);
        console.log(`${key}: ${value}`);
      }
    }

    return dietCategoriesValues;
  };

  const navigateToPath = (prefix, index) => {
    const specificPaths = {
      0: "/onboarding/registration/diet-categories",
      6: "/onboarding/registration/personal-data",
    };

    if (specificPaths[index]) {
      const dietCategory =
        getUserRegistrationDataFromLocalStorage()?.dietCategory;
      const existingData = JSON.parse(
        localStorage.getItem("SelectedDietItems")
      );

      const result = getDietCategoryData(existingData, dietCategory);

      setUserRegistrationDataInLocalStorage({
        ...getUserRegistrationDataFromLocalStorage(),
        eatingHabits: result,
      });

      history.push(specificPaths[index]);
      return;
    }

    const path = `/onboarding/registration/eating-habits/${prefix}-${index}`;

    history.push(path);
  };

  const handleBack = () => {
    history.push("/onboarding/registration/diet-categories");
    // const [prefix, indexStr] = id.split("-");
    // const index = parseInt(indexStr, 10);

    // // if (index - 1 === 0) {
    // //   history.push("/onboarding/registration/diet-categories");
    // //   return;
    // // }

    // navigateToPath(prefix, index - 1);
  };

  const handleNext = () => {
    const EatingHabits = {
      "vegan-1": EatingHabitVeganOne,
      "vegan-2": EatingHabitVeganTwo,
      "vegan-3": EatingHabitVeganThree,
      "vegan-4": EatingHabitVeganFour,
      "vegan-5": EatingHabitVeganFive,
      "vegetarisch-1": EatingHabitVegaterishOne,
      "vegetarisch-2": EatingHabitVegaterishTwo,
      "vegetarisch-3": EatingHabitVegaterishThree,
      "vegetarisch-4": EatingHabitVegaterishFour,
      "vegetarisch-5": EatingHabitVegaterishFive,
      "fleischhaltig-1": EatingHabitFleischhaltigOne,
      "fleischhaltig-2": EatingHabitFleischhaltigTwo,
      "fleischhaltig-3": EatingHabitFleischhaltigThree,
      "fleischhaltig-4": EatingHabitFleischhaltigFour,
      "fleischhaltig-5": EatingHabitFleischhaltigFive,
    };

    if (!EatingHabits[id]) {
      SetIsValidation(true);
      return;
    }

    const [prefix, indexStr] = id.split("-");
    const index = parseInt(indexStr, 10);

    navigateToPath(prefix, index + 1);
  };

  return (
    <>
      <S.OnBoardingPage className="starter">
        <S.OnBoardingContainer className="onboarding-container">
          <OnboardingSidebar step={2} />
          <S.OnBoardingContent className="onboarding-content">
            <S.OnBoardingHeader className="onboarding-header">
              <div className="d-flex align-items-top gap-3">
                <img
                  className="cursor-pointer"
                  src={ArrowLeft}
                  alt="back"
                  onClick={handleBack}
                />
                <div className="content">
                  <span className="small-title text-green font-rubik-medium">
                    Anamnese
                  </span>
                  <h6 className="header-title font-rubik-medium my-0">
                    Deine Präferenzen der Rezepte
                  </h6>
                </div>
              </div>
              <div className="actions">
                <Button
                  color="rgba(248, 164, 146, 1)"
                  gradient="linear-gradient(60deg, rgba(46, 207, 150, 1), rgba(28, 157, 135, 1)) !important"
                  margin="0"
                  absolute={{ width: "fit-content", height: "2.5rem" }}
                  buttonStyle={{ padding: ".5625rem 2.5rem" }}
                  onClick={handleNext}
                >
                  Weiter
                </Button>
              </div>
            </S.OnBoardingHeader>
            <S.OnBoardingBody className="onboarding-body">
              <div className="eating-habits-content ">
                <h3 className="text-size-20-28 text-dark-1 font-rubik-medium">
                  Um deinen Plan weiter zu personalisieren passen wir deine
                  Ernährungsform an.
                </h3>
                <p className="text-size-16-19 text-light-grey-1 font-rubik-regular">
                  Wähle das Rezept, welches dir am meisten zusagt. In der App
                  erhältst du später 1700 Rezepte.
                </p>
                <p className="text-size-14-28 text-dark-1 font-rubik-medium">
                  Was isst du lieber?
                </p>
                {IsValidation && (
                  <p className="text-size-14-18 text-right text-red font-rubik-regular mt-0">
                    Bitte wähle mindestens ein Gericht
                  </p>
                )}
                <div className="eating-habits-items">
                  {DietItemsLists &&
                    DietItemsLists[id].map((item, i) => (
                      <div
                        key={i}
                        className="eating-habits-item cursor-pointer"
                        onClick={() => onSelectHabit(item)}
                      >
                        <img
                          className="img"
                          src={item?.eatingHabitData?.image}
                          alt=""
                        />
                        <div className="headings">
                          <p className="text-size-18-22 text-dark-2 font-rubik-medium my-0">
                            <TranslationMessage
                              id={item?.eatingHabitData?.heading}
                            />
                          </p>
                          {/* <p className="text-size-16-19 text-dark-1 font-rubik-regular mt-1 mb-0">
                            Leckere Bowl mit Joghurt und saisonalen Früchten.
                          </p> */}
                        </div>
                        <div className="form-group-c medium">
                          <input
                            type="checkbox"
                            id={i}
                            checked={
                              item.eatingHabitData?.id ===
                              getSelectedHabitItem(id)?.eatingHabitData?.id
                            }
                            readOnly
                          />
                          <label htmlFor={i}></label>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </S.OnBoardingBody>
          </S.OnBoardingContent>
        </S.OnBoardingContainer>
      </S.OnBoardingPage>
    </>
  );
};

export default EatingHabitsOne;
