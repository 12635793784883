import styled from "styled-components";

export const Container = styled.div`
  /* display: grid;
  grid-template-columns: 300px 1fr; */
  display: flex;
`;

export const Wrapper = styled.div`
  padding: 1.5rem;
`;

export const GridFourColumn = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 1rem;
`;

export const HL = styled.h3`
  padding: 4rem 0;
  text-align: center;
`;

export const BrochureCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const BrochureCardImage = styled.div`
  position: relative;

  .tag-label {
    position: absolute;
    left: 0.8125rem;
    top: 0.8125rem;
  }
  .card-img {
    background: #ffffff;
    box-shadow: 0px 1px 2px #1018280d;
    border: 1px solid #d0d5dd;
    border-radius: 1rem;
    width: 100%;
    height: 200px;
    object-fit: cover;
  }

  .download-icon {
    width: 1.75rem;
    height: 1.75rem;
    background: #ffffff;
    box-shadow: 0px 1px 2px #5258660f;
    border: 1px solid #e2e4e9;
    border-radius: 0.375rem;
    padding: 0.1875rem;
    cursor: pointer;

    position: absolute;
    right: 0.8125rem;
    bottom: 0.8125rem;
  }
`;

export const BrochureCardBody = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.625rem;

  > div {
    display: flex;
    flex-direction: column;
    gap: 0.425rem;

    h6 {
      color: #142c1f;
      font-size: 1.125rem;
      font-weight: 400;
      margin: 0;
    }

    p {
      color: #818d88;
      font-size: 1rem;
      font-weight: 300;
      margin: 0;
    }
  }
`;

export const InlineThreeControls = styled.div`
  display: grid;
  grid-template-columns: 7.5rem 1fr 1fr;
  gap: 1rem;
`;

export const InlineTwoControls = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
`;

export const FormControl = styled.div`
  position: relative;
  width: 100%;

  input {
    color: #44554c;
  }

  img {
    cursor: pointer;
    position: absolute;
    top: 12px;
    left: 10px;
    width: 18px;
  }

  .upload-icon {
    right: 1rem;
    left: auto;
    top: 2.4rem;
  }
`;

export const FormControlSearch = styled.div`
  position: relative;
  width: 280px;

  input {
    color: #44554c;
  }

  img {
    cursor: pointer;
    position: absolute;
    top: 12px;
    left: 10px;
    width: 18px;
  }

  input {
    padding: 11px 15px 11px 32px;
  }
`;

export const TrainerProfileDetailsSectionHeading = styled.h6`
  font-size: 20px;
  color: #142c1f;
  margin: 0;
  margin-bottom: 5px;
  line-height: 24px;
`;

export const TrainerProfileDetailsSectionSubHeading = styled.div`
  font-size: 16px;
  line-height: 19px;
  color: #818d88;
  font-weight: 300;
  margin: 1rem 0 1.2rem;

  .links {
    display: flex;
    align-items: center;
    gap: 1rem;

    strong {
      color: #142c1f;
      font-size: large.9rem;
      font-weight: 400;
    }

    .url-copy {
      display: flex;
      align-items: center;
      gap: 0.7rem;

      img {
        box-shadow: 0px 1px 2px #5258660f;
        border: 1px solid #e2e4e9;
        border-radius: 0.25rem;
        padding: 0.1875rem;
        background-color: #fff;
        cursor: pointer;
      }

      p {
        color: #818d88;
        font-size: 0.85rem;
        font-weight: 300;
        margin: 0;
      }
    }
  }
`;

export const Divider = styled.div`
  height: 1px;
  background-color: #e2e4e9;
`;

export const FiltersBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  margin: 1.5rem 0 1.5rem 0;
`;

export const SelectedFiltersAndDropdown = styled.div`
  display: flex;
  gap: 0.5rem;

  .faq-select {
    min-width: 200px;
  }
`;

export const SelectedFilter = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 9px 1rem;
  background: #ffffff;
  box-shadow: 0px 1px 2px #1018280d;
  border: 1px solid #d0d5dd;
  border-radius: 5px;
  color: #44554c;
  font-weight: 300;

  img {
    cursor: pointer;
  }
`;

export const FilterSearchTitle = styled.p`
  font-size: 14px;
  line-height: 17px;
  color: #44554c;
  font-weight: 400;
  margin: 0;
`;

export const SuccessAlert = styled.div`
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
  position: relative;
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  font-weight: 400;
`;

export const ErrorAlert = styled.div`
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
  position: relative;
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  font-weight: 400;
`;

export const TrainerProfileDetailsInnerContainer = styled.div`
  padding: 1.5rem;
  border-radius: 10px;
  height: 100%;
  background-color: #fff;

  .emails-trigger-container {
    display: flex;
    gap: 10px;
  }
  
  .send-emails-headline {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px 0px;
    color: #808D87;

    img {
      width: 30px;
    }
  }

  .lead-status {
      background: #2ECF96;
      color: white;
      padding: 7px 10px;
      border-radius: 17px;
      font-size: .7rem;
      text-transform: uppercase;
    }
`;

export const TrainerProfileDetailsInnerChildContainer = styled.div`
  display:flex;
  align-items:flex-start;
  justify-content:space-between;
`
export const Header = styled.div`
  padding: 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  .header-actions {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  .heading-with-back-icon {
    display: flex;
    > span {
      margin-right: 15px;
      cursor: pointer;
    }
  }
`;

export const BackButton = styled.div`
  text-decoration: none !important;
  color: #44554c;
  font-size: 16px;
  line-height: 19px;
  display: inline-flex;
  font-weight: 400;
  align-items: center;
  background-color: transparent;
  cursor: pointer;

  &:hover {
    color: #1c9d87;
  }
`;

export const TrainerProfileDetailsSection1InputLabel = styled.div`
  font-size: 0.875rem;
  color: #44554c;
  line-height: 1.5rem;
  margin: 0 0 10px;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;

  textarea {
    border: 1px solid #dddddd;
    border-radius: 4px;
    width: 100% !important;
    background-color: transparent;
  }

  img {
    position: relative;
  }
`;

export const PrintDataDetails = styled.div`
  border-radius: 10px;
`;

export const DetailsInfo = styled.div`
  padding: 1.5rem;
`;

export const DetailsGrid = styled.div`
  display: flex;
  /* grid-template-columns: 1fr minmax(12.5rem, 20.4375rem);
 */
  gap: 2rem;

  .slider-container {
    width: calc(100% - 300px);

    .slick-slide img {
      width: 100%;
      border-radius: 1rem;
    }

    .slick-dots {
      bottom: -88px;
      img {
        width: 5.5625rem;
        height: 4.5625rem;
        background: #fff;
        box-shadow: 0px 1px 2px #1018280d;
        border: 1px solid #d0d5dd;
        border-radius: 0.875rem;
        padding: 0.3125rem;
        object-fit: cover;
      }
    }
  }

  .info-items {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 300px;
  }
`;

export const InfoItem = styled.div`
  background: #ffffff;
  box-shadow: 0px 1px 2px #1018280d;
  border: 1px solid #d0d5dd;
  border-radius: 12px;
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  padding: 1rem;

  .title,
  .value {
    font-size: 1rem;
    line-height: 1.1875rem;
  }

  .title {
    color: #142c1f;
    font-weight: 400;
  }

  .value {
    color: #818d88;
    font-weight: 300;
  }

  &.column {
    flex-direction: column;

    .second-line {
      display: flex;
      justify-content: space-between;
      gap: 1rem;
    }
  }
`;

export const TableWrapper = styled.div`
  overflow-x: auto;

  table {
    width: 100%;
    border-collapse: collapse;
    margin: 0 auto;
  }

  &.min-w-70 {
    table {
      min-width: 70rem;
    }
  }
  thead {
    background: #f6f8fa;

    th {
      color: #535865;
      font-size: 1rem;
      padding: 0.875rem 2rem;
      text-align: left;

      &:first-child {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
      }

      &:last-child {
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
      }
    }
  }

  tbody {
    tr {
      td {
        padding: 1rem 2rem;
        border-bottom: 1px solid #e2e4e9;
      }
    }
  }

  .partner {
    display: flex;
    align-items: center;
    gap: 0.625rem;

    img {
      background: #ffffff;
      box-shadow: 0px 1px 2px #e4e5e73d;
      border: 2px solid #e2e4e9;
      border-radius: 50%;
      width: 3.2rem;
      min-width: 3.2rem;
      height: 3.2rem;
    }

    h6 {
      margin: 0;
      color: #44554c;
      font-size: 0.9rem;
      line-height: 1.3rem;
      font-weight: 400;
    }

    p {
      margin: 0;
      color: #a5b5af;
      font-size: 0.9rem;
      font-weight: 300;
    }
  }

  .contact-person {
    h6 {
      margin: 0;
      color: #44554c;
      font-size: 0.9rem;
      line-height: 1.3rem;
      font-weight: 400;
    }

    p {
      margin: 0;
      color: #a5b5af;
      font-size: 0.8rem;
      font-weight: 300;
    }
  }
`;

export const PartnerDetails = styled.div`
  display: grid;
  grid-template-columns: 22.5rem 1fr;

  .partner-sidebar,
  .partner-content {
    padding: 1.5rem;
  }

  .partner-sidebar {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.7rem;
    text-align: center;

    .partner-logo {
      width: 4.875rem;
      height: 4.875rem;
      background: #ffffff;
      box-shadow: 0px 1px 2px #e4e5e73d;
      border: 2px solid #e2e4e9;
      border-radius: 50%;
    }

    h6 {
      margin: 0;
      color: #44554c;
      font-size: 1.125rem;
      line-height: 1.3rem;
      font-weight: 400;
    }

    p {
      margin: 0;
      color: #a5b5af;
      font-size: 1rem;
      font-weight: 300;
    }

    .qr-code-item {
      width: fit-content;
      border-radius: 8px;
      color: #141414;
      font-size: 0.9rem;
      padding: 0.375rem 1rem;
      display: flex;
      align-items: center;
      gap: 0.3rem;
      font-weight: 400;

      &:hover {
        box-shadow: 0px 1px 5px #5258662e;
      }
    }

    .partner-url-item {
      font-size: 0.9rem;
      text-decoration: underline;
      font-weight: 400;
      display: flex;
      align-items: top;
      gap: 0.3rem;

      div {
        text-align: left;
      }

      a {
        color: #44554c;
      }

      img {
        background: #ffffff;
        box-shadow: 0px 1px 2px #5258660f;
        border: 1px solid #e2e4e9;
        border-radius: 5px;
        padding: 6px 4px;
      }
    }
  }

  .partner-content {
    display: flex;
    flex-direction: column;
    gap: 1.4rem;

    .title {
      color: #142c1f;
      font-weight: 500;
      font-size: 1.125rem;
      margin: 0;
    }

    .info-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 1rem;

      span {
        color: #44554c;
        font-weight: 400;
        font-size: 0.9rem;

        a {
          color: #1c9d87;
        }
      }
    }
  }
`;

export const Footer = styled.div`
  padding: 1.5rem;
  border-top: 1px solid #e2e4e9;
`;

export const CardsWrap = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;

  h6,
  h4,
  p {
    margin: 0;
  }

  .credit-card {
    box-shadow: 0px 1px 2px #1018280d;
    border: 1px solid #d0d5dd;
    border-radius: 0.8125rem;

    .header,
    .body {
      padding: 1rem;
    }

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #d0d5dd;

      h6 {
        color: #142c1f;
        font-size: 1rem;
        /* font-weight: 400; */
      }

      .date-range {
        background: #f3f4f6;
        box-shadow: 0px 1px 2px #1018280d;
        border-radius: 0.5rem;
        color: #44554c;
        font-size: 0.875rem;
        padding: 0.3rem 0.4rem;
      }
    }

    .body {
      .income-title {
        color: #686e7c;
        font-size: 0.875rem;
      }

      .income {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 0.8rem;

        h4 {
          color: #191d1b;
          font-size: 2rem;
        }

        .income-status {
          display: flex;
          align-items: center;
          gap: 0.5rem;

          .arrow-up {
            background: #e6faf3;
            border-radius: 0.8125rem;
            padding: 0.4375rem 0.75rem;
          }

          span {
            color: #44554c;
            font-size: 0.75rem;
          }
        }
      }

      .download-row {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &:not(:last-child) {
          border-bottom: 1px solid #d0d5dd;
          margin-bottom: 1rem;
          padding-bottom: 1rem;
        }

        .left {
          display: flex;
          align-items: center;
          gap: 0.5rem;

          .pdf-icon {
            width: 1.375rem;
            height: 1.375rem;
          }

          span {
            color: #44554c;
            font-size: 1rem;
          }
        }

        .download-icon {
          width: 1rem;
          height: 1rem;
        }
      }
    }
  }
`;

export const GoPremiumNoticeContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const GoPremiumNoticeContent = styled.div`
  display: flex;
  align-items: center;
  width: 90%;
  border: 1px solid #D0D5DD;
  padding: 30px;
  border-radius: 30px;

  .content {
    width: 80%;
    display: flex;
    flex-direction: column;

    > * {
      margin-bottom: 30px;
    }

    .heading {
      color: #142C1F;
      font-size: 30px;
    }

    .subheading, p {
      color: #818D88;
      margin: 0;
      line-height: 24px;
    }
    
    .list-item {
      display: flex;
      align-items: flex-start;
      margin-bottom: 10px;

      span {
        margin-right: 10px;
      }
    }

    .call_to_action {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      a, h3 {
        font-size: 20px;
      }
      
      a {
        margin-top: 10px;
      }

      > * {
        margin-bottom: 10px;
      }
    }
  }
  
  .benefits_banner img {
    width: 500px;
  }
`
