import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import * as S from "./nutritionForm.styles";
import TranslationMessage from "configs/translations/TranslationMessage";

// Images
import LowCarbImage from "assets/images/lowCarb.svg";
import LowCarbNotSelectedImage from "assets/images/lowCarbNotSelected.svg";

// Components
import Topbar from "components/topbar/Topbar";
import Button from "components/formComponents/button/Button";
import Modal from "components/modal/Modal";
import Loader from "components/loader/Loader";
import BackIconButton from "components/backIconButton/BackIconButton";

// Redux
import * as NutritionActions from "redux/nutrition/nutrition.actions";
import * as AuthActions from "redux/auth/auth.actions";

const NutritionForm = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const loading = useSelector((state) => state.auth.loading);
  const userData = useSelector((state) => state.nutrition.userData);
  const registrationData = useSelector((state) => state.auth.registrationData);
  const activeNutritionType = useSelector(
    (state) => state.nutrition.activeNutritionType
  );
  const allNutritionTypes = useSelector(
    (state) => state.nutrition.nutritionTypes
  );
  const [selectedNutritionForm, setSelectedNutritionForm] = useState({});
  const [editNutritionFormModal, setEditNutritionFormModal] = useState(false);

  const nextLink = "/nutrition/formAdvantages";

  useEffect(() => {
    const anamnese = JSON.parse(localStorage.getItem("Anamnese"));

    const anamnesePayload = {
      stress_level: anamnese?.StressLevel.images.find((item) => item.isActive)
        ?.value,
      body_fat: anamnese?.BodyShape.images.find((item) => item.isActive)?.value,
      sport_activity: anamnese?.SportActivity.numbers.find(
        (item) => item.isActive
      )?.value,
      daily_activity: anamnese?.DailyActivity.numbers.find(
        (item) => item.isActive
      )?.value,
      smoking: anamnese?.Smoking.numbers.find((item) => item.isActive)?.value,
      alcohol: anamnese?.Alcohol.numbers.find((item) => item.isActive)?.value,
      fruits_vegitables: anamnese?.FruitVegetable.numbers.find(
        (item) => item.isActive
      )?.value,
      meal_frequency: anamnese?.MealFrequency.numbers.find(
        (item) => item.isActive
      )?.value,
      drinking: anamnese?.Drink.numbers.find((item) => item.isActive)?.value,
      cravings: anamnese?.Cravings.numbers.find((item) => item.isActive)?.value,
      mood_swings: anamnese?.MoodSwings.numbers.find((item) => item.isActive)
        ?.value,
      weekness_feeling: anamnese?.FeelingWeak.numbers.find(
        (item) => item.isActive
      )?.value,
    };

    dispatch(
      NutritionActions.getUserDataRequest({
        callback: (response) => {
          localStorage.setItem("AnamneseUserId", response.data[0].userID);
          dispatch(
            AuthActions.createUserAnamneseRequest({
              formData: {
                userID: response.data[0].userID,
                weekID: 1,
                ...anamnesePayload,
              },
              callback: () => {
                localStorage.removeItem("Anamnese");
                localStorage.removeItem("foodCategory");
              },
            })
          );
        },
      })
    );
  }, [dispatch]);

  useEffect(() => {
    const IDs = JSON.parse(localStorage.getItem("NutrtionIds"));
    getCurrentNutritionType(
      IDs?.nutritionTypeID || registrationData?.nutritiontypeID
    );
    // getCurrentNutritionType(registrationData.nutritiontypeID);
    getAllNutritionTypes();
  }, []);

  const getCurrentNutritionType = (id) =>
    dispatch(
      NutritionActions.getNutritionTypeRequest({
        nutritiontypeID: id,
        aimID: registrationData?.aimID,
        sex: registrationData?.sex,
      })
    );
  const getAllNutritionTypes = () =>
    dispatch(
      NutritionActions.getAllNutritionTypesRequest({
        aimID: registrationData?.aimID,
        sex: registrationData?.sex,
      })
    );

  const handleChangeNutritionTypePopup = () => {
    const newNutritionType = allNutritionTypes.filter(
      (data) => data.de_name === activeNutritionType.de_name
    )[0];
    setSelectedNutritionForm(newNutritionType);
    setEditNutritionFormModal(true);
  };

  const handleNutritionTypeChange = () => {
    dispatch(
      NutritionActions.updateNutritionTypeRequest({
        nutritiontypeID: selectedNutritionForm?.nutritiontypeID,
        aimID: registrationData?.aimID,
        sex: registrationData?.sex,
        nutritionconceptID: userData?.fitnessdata?.nutritionconceptID,
      })
    );
    getCurrentNutritionType(selectedNutritionForm.nutritiontypeID);
    dispatch(
      AuthActions.setRegistrationData({
        ...registrationData,
        nutritiontypeID: selectedNutritionForm.nutritiontypeID,
        userID: userData.userID,
      })
    );
    setEditNutritionFormModal(false);
  };

  const onNext = () => {
    dispatch(
      AuthActions.setRegistrationData({
        ...registrationData,
        userID: userData.userID,
      })
    );
    history.push(nextLink);
  };

  return (
    <div>
      <Topbar next={nextLink} progress="17%" onNext={onNext} />
      {<Loader loading={loading} top="35vh" />}
      <BackIconButton
        onClick={() =>
          history.push(props.location?.state?.from || "/main/dashboard/home")
        }
        style={{ position: "absolute", top: "200px", left: "100px" }}
      />
      <>
        {!userData ? (
          <div style={{ textAlign: "center", marginTop: "50px" }}>
            <TranslationMessage id="trainingCompleted" />
          </div>
        ) : (
          <>
            {!loading && (
              <S.NutritionFormContainer>
                <S.NutritionFormHeading>
                  <TranslationMessage id="nutrition.form.heading" />
                </S.NutritionFormHeading>
                <S.NutritionFormSubHeading>
                  <TranslationMessage id="nutrition.form.subHeading" />
                </S.NutritionFormSubHeading>
                <S.NutritionFormCardContainer justify="center">
                  <S.NutritionFormCard backgroundColor="white">
                    <S.NutritionCardImage
                      src={LowCarbImage}
                      alt={activeNutritionType && activeNutritionType.de_name}
                    />
                    {activeNutritionType && activeNutritionType.de_name}
                  </S.NutritionFormCard>
                </S.NutritionFormCardContainer>
                <S.NutritionFormDescription
                  dangerouslySetInnerHTML={{
                    __html: activeNutritionType?.["01_text"].replace(
                      /\n/g,
                      "<br />"
                    ),
                  }}
                />
                <div>
                  <Button
                    color="#1C9D87"
                    outlined
                    onClick={handleChangeNutritionTypePopup}
                    absolute={{ margin: "auto" }}
                  >
                    <TranslationMessage id="button.editNutritionForm" />
                  </Button>
                </div>
              </S.NutritionFormContainer>
            )}
            <Modal
              show={editNutritionFormModal}
              setShow={setEditNutritionFormModal}
              heading={
                <TranslationMessage id="nutrition.form.editNutritionFormHeading" />
              }
              width="848px"
              height="auto"
            >
              {<Loader loading={loading} top="200px" />}
              {!loading && (
                <>
                  <S.NutritionFormCardContainer>
                    {allNutritionTypes &&
                      allNutritionTypes.map((value) => {
                        return (
                          <S.NutritionFormCard
                            key={value.nutritiontypeID}
                            onClick={() => setSelectedNutritionForm(value)}
                            selected={
                              value.nutritiontypeID ===
                              selectedNutritionForm.nutritiontypeID
                            }
                          >
                            <S.NutritionCardImage
                              src={
                                value.nutritiontypeID ===
                                selectedNutritionForm.nutritiontypeID
                                  ? LowCarbImage
                                  : LowCarbNotSelectedImage
                              }
                              alt={value.nutritiontypeID}
                            />
                            {value["de_name"]}
                          </S.NutritionFormCard>
                        );
                      })}
                  </S.NutritionFormCardContainer>
                  <S.NutritionFormDescription
                    dangerouslySetInnerHTML={{
                      __html: selectedNutritionForm["01_text"]
                        ? selectedNutritionForm["01_text"].replace(
                            /\n/g,
                            "<br />"
                          )
                        : "",
                    }}
                  />
                  <Button
                    color="rgba(46, 207, 150, .2)"
                    gradient="linear-gradient(60deg, rgba(46, 207, 150, 1), rgba(28, 157, 135, 1)) !important"
                    margin="30px auto 0px"
                    style={{
                      fontSize: "16px",
                      height: "43px",
                      marginTop: "15px",
                    }}
                    onClick={handleNutritionTypeChange}
                  >
                    <TranslationMessage id="button.saveChanges" />
                  </Button>
                </>
              )}
            </Modal>
          </>
        )}
      </>
    </div>
  );
};

export default NutritionForm;
