import React, { Component } from "react";

import Canva from "assets/images/canva.jpeg";
import Drive from "assets/images/google-drive.jpeg";
import Upload from "assets/images/upload-icon-social.svg";

class ButtonHover extends Component {
  state = {
    isHovered: false,
  };

  handleMouseEnter = () => {
    this.setState({ isHovered: true });
  };

  handleMouseLeave = () => {
    this.setState({ isHovered: false });
  };

  render() {
    const { canva_link, direct_download_link, google_link } = this.props?.links;
    const { isHovered } = this.state;

    return (
      <div
        className={`button-hover-box ${isHovered ? "hovered" : ""}`}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
      >
        <button className="plus-btn">
          <span>+</span>
        </button>

        <div className={`button-hover-inner ${isHovered ? "show" : ""}`}>
          {google_link && (
            <a href={google_link} target="_blank" rel="noreferrer" download>
              <img src={Drive} alt="google" />
            </a>
          )}
          {canva_link && (
            <a href={canva_link} target="_blank" rel="noreferrer" download>
              <img src={Canva} alt="canva" />
            </a>
          )}
          {direct_download_link && (
            <a
              href={direct_download_link}
              className="greenColor"
              target="_blank"
              rel="noreferrer"
              download
            >
              <img src={Upload} alt="direct" />
            </a>
          )}
        </div>
      </div>
    );
  }
}

export default ButtonHover;
