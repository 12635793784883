import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import * as S from "./foodiaryFamily.styles";

// Components
import TranslationMessage from "configs/translations/TranslationMessage";
import Topbar from "components/topbar/Topbar";

const FoodiaryFamily = () => {
  const history = useHistory();

  useEffect(() => {
    document.body.style.overflowY = "hidden";
  }, []);

  const onNext = () => {
    document.body.style.overflowY = "auto";
    localStorage.removeItem("NutrtionIds");
    localStorage.removeItem("AnamneseUserId");
    history.push("/main/dashboard/home");
  };
  return (
    <div>
      <Topbar
        next="/foodiary/family"
        onNext={onNext}
        nextButtonValue={<TranslationMessage id="button.ready" />}
        noButtonArrow
      />
      <S.FoodiaryFamilyContainer>
        <S.FoodiaryFamilyHeading>
          <TranslationMessage id="foodiary.family.heading" />
        </S.FoodiaryFamilyHeading>
        <S.FoodiaryFamilySubHeading>
          <TranslationMessage id="foodiary.family.subHeading" />
        </S.FoodiaryFamilySubHeading>
        <S.FoodiaryFamilyDescription>
          <TranslationMessage id="foodiary.family.description.p1" /> <br />
          <TranslationMessage id="foodiary.family.description.p2" /> <br />
          <TranslationMessage id="foodiary.family.description.p3" />
        </S.FoodiaryFamilyDescription>
        <S.FoodiaryFamilySVG />
      </S.FoodiaryFamilyContainer>
    </div>
  );
};

export default FoodiaryFamily;
