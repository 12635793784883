import styled from "styled-components";
import { DashboardContainer } from "pages/dashboard/dashboard.styles"
import { RegistrationContainer, RegistrationHeading, RegistrationSubHeading, } from 'pages/registration/registration.styles';

export const HealthScoreTipsContainer = styled(RegistrationContainer)``;

export const HealthScoreTipsHeading = styled(RegistrationHeading)``;

export const HealthScoreTipsSubHeading = styled(RegistrationSubHeading)``;

// Health Score Tips Card styling
export const HealthScoreTipsCardContainer = styled(DashboardContainer)`
    display: block;
    margin-top: 20px;
`;

export const HealthScoreTipsCardInnerContainer = styled.div`
    padding: 2rem 50px 0;
`;

export const HealthScoreTipsHeadingContainer = styled.div`
    display: flex;
    align-items: center;
`

export const HealthScoreTipsHeadingIcon = styled.img`
    position: relative;
    bottom: 16px;
    right: 32px;
`

export const HealthScoreTipsCardHeading = styled.div`
    font-family: RubikMedium;
    font-size: 18px;
    color: #142C1F;
`

export const HealthScoreTipsBenefitContainer = styled.div`
    margin-top: 30px;
    height: 144px;
`

export const HealthScoreTipsBenefitsContainer = styled.div`
    display: flex;
    margin-bottom: 14px;
    justify-content: space-between
`

export const HealthScoreTipsBenefits = styled.div`
    line-height: 21px;
    font-size: 16px;
    color: #44554C;
    font-family: RubikMedium;
    width: 263px;
`;

export const HealthScoreTipsDescription = styled.div`
    line-height: 21px;
    font-family: RubikRegular;
    font-size: 14px;
    color: rgba(68, 85, 76, .7);
`