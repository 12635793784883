import styled from "styled-components";
import { RegistrationContainer, RegistrationHeading, RegistrationSubHeading, PersonalDataInputContainer } from 'pages/registration/registration.styles';
import { TrainerProfileImage, TrainerUploadImage } from "pages/dashboard/dashboard.styles";

export const TrainerSignupFormContainer = styled(RegistrationContainer)``;

export const TrainerSignupFormHeading = styled(RegistrationHeading)``;

export const TrainerSignupFormSubHeading = styled(RegistrationSubHeading)``;

export const TrainerSignupDataContainer = styled.div`
    background-color: white;
    width: 750px;
    height: auto;
    margin: 0 auto 100px;
    border-radius: 10px;
`;

export const TrainerSignupDataInnerContainer = styled.div`
    margin: 45px 50px;
`;

export const TrainerSignupInputContainer = styled(PersonalDataInputContainer)``;

export const TrainerSignupInputWrapper = styled.span`
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
`;

export const TrainerSignupRulesContainer = styled.div`
    display: flex;
    flex-direction: row;
    height: 140px;
    margin-top: 3rem;
    text-align: left;
`;

export const TrainerSignupRulesLabel = styled.label`
    width: 553px;
    margin-left: 3px;
    color: #44554C;
    font-family: RubikRegular;
    font-size: 16px;
`;

export const TrainerSignupProfileImage = styled(TrainerProfileImage)`
    margin: 0 auto;
    background-color: #F5FAF9;
`;

export const TrainerSignupUploadImage = styled(TrainerUploadImage)``;

export const ChecklistInputContainer = styled.div`
    margin: 20px 0 0 0;
`;

export const NameInputContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 20px;
`;