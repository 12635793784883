import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import * as S from "./nutritionCalories.styles";
import TranslationMessage from 'configs/translations/TranslationMessage';

// Components
import Topbar from 'components/topbar/Topbar';
import Button from 'components/formComponents/button/Button';
import LineChart from 'components/lineChart/LineChart';
import Loader from "components/loader/Loader";
import Modal from 'components/modal/Modal';
import IncrementInput from 'components/formComponents/incrementInput/IncrementInput';

// Images
import LeftIcon from 'assets/images/leftIcon.svg';
import RightIcon from 'assets/images/rightIcon.svg';

// Redux
import * as NutritionActions from 'redux/nutrition/nutrition.actions';

const NutritionCalories = () => {
    const dispatch = useDispatch();
    const [editCaloriesModal, setEditCaloriesModal] = useState(false);
    const [restDayCalories, setRestDayCalories] = useState(0);
    const [trainingDayCalories, setTrainingDayCalories] = useState(0);
    const [weekNumber, setWeekNumber] = useState(1);

    const loading = useSelector(state => state.auth.loading);
    const registrationData = useSelector(state => state.auth.registrationData);
    const caloriesData = useSelector(state => state.nutrition.caloriesLineChartData);

    useEffect(() => {
        hitCaloriesDataAPI()
    }, [dispatch, registrationData])

    const hitCaloriesDataAPI = () => {
        dispatch(NutritionActions.getWeekWiseCaloriesRequest({
            data: {
                nutritiontypeID: registrationData.nutritiontypeID,
                aimID: registrationData.aimID,
                sex: registrationData.sex,
                user_id: registrationData.userID
            },
            funcSuccess: () => {}
        }))
    }

    const handleCaloriesUpdate = () => {
        setEditCaloriesModal(false)
        dispatch(NutritionActions.updateNutritionCaloriesRequest({
            nutritiontypeID: registrationData.nutritiontypeID,
            nutritionconceptID: registrationData.nutritionconceptID,
            aimID: registrationData.aimID,
            sex: registrationData.sex,
            week: weekNumber,
            caloriesData: [
                { daytypeID: 1, calories: restDayCalories },
                { daytypeID: 2, calories: trainingDayCalories }
            ]
        }))
        setTimeout(() => {
            hitCaloriesDataAPI()
        }, 1000)
    }

    const data = [
        { label: '1', x: 1, y: caloriesData && caloriesData['1'] },
        { label: '2', x: 2, y: caloriesData && caloriesData['2'] },
        { label: '3', x: 3, y: caloriesData && caloriesData['3'] },
        { label: '4', x: 4, y: caloriesData && caloriesData['4'] },
        { label: '5', x: 5, y: caloriesData && caloriesData['5'] },
        { label: '6', x: 6, y: caloriesData && caloriesData['6'] },
        { label: '7', x: 7, y: caloriesData && caloriesData['7'] },
        { label: '8', x: 8, y: caloriesData && caloriesData['8'] },
        { label: '9', x: 9, y: caloriesData && caloriesData['9'] },
        { label: '10', x: 10, y: caloriesData && caloriesData['10'] },
        { label: '11', x: 11, y: caloriesData && caloriesData['11'] },
        { label: '12', x: 12, y: caloriesData && caloriesData['12'] },
    ]

    const lineChartStatus = caloriesData ? (caloriesData['12'] - caloriesData['1']).toFixed(2) + ' kcal' : '0 kcal';

    const handleRightNavigation = () => {
        if (weekNumber !== 12) setWeekNumber(weekNumber + 1)
    }
    const handleLeftNavigation = () => {
        if (weekNumber !== 1) setWeekNumber(weekNumber - 1)
    }

    return (
        <div>
            <Topbar prev='/nutrition/recipes' next='/nutrition/macroNutrients' progress='67%' />
            <S.NutritionFormCaloriesContainer>
                <S.NutritionFormCaloriesHeading><TranslationMessage id="nutrition.formCalories.heading" /></S.NutritionFormCaloriesHeading>
                <S.NutritionFormCaloriesSubHeading><TranslationMessage id="nutrition.formCalories.subHeading" /></S.NutritionFormCaloriesSubHeading>
                {!loading && caloriesData && <>
                    <S.NutritionFormCaloriesInnerContainer>
                        <S.NutritionFormCaloriesLineGraphContainer>
                            <LineChart
                                heading="Gesamtbedarf"
                                status={lineChartStatus}
                                description='in 12 Wochen'
                                fontSize={13}
                                precision={0}
                                padding={50}
                                width={447}
                                height={200}
                                data={data}
                                horizontalGuideLines={3}
                                yAxisBetweenRange
                            />
                        </S.NutritionFormCaloriesLineGraphContainer>
                        <S.NutritionFormCaloriesDescription>
                            <TranslationMessage id="nutrition.formCalories.description.p1" /> <br /><br />
                            {caloriesData.description}
                        </S.NutritionFormCaloriesDescription>
                    </S.NutritionFormCaloriesInnerContainer>
                    <Button outlined absolute={{ margin: "auto" }} color="#1C9D87" onClick={() => setEditCaloriesModal(true)} ><TranslationMessage id="button.changeCalories" /></Button>
                </>}
                {<Loader loading={loading} top="130px" />}
            </S.NutritionFormCaloriesContainer>
            <Modal
                show={editCaloriesModal}
                setShow={setEditCaloriesModal}
                heading={<TranslationMessage id="nutrition.formCalories.editCalories.heading" />}
                width='500px'
                height='auto'
            >
                <S.EditCaloriesWeekHeadingContainer>
                    <S.EditCaloriesNavigationIconContainer onClick={handleLeftNavigation}><img src={LeftIcon} alt="left Icon" /></S.EditCaloriesNavigationIconContainer>
                    <S.EditCaloriesWeekHeading><TranslationMessage id="nutrition.formCalories.editCalories.week" /> {weekNumber}</S.EditCaloriesWeekHeading>
                    <S.EditCaloriesNavigationIconContainer onClick={handleRightNavigation} ><img src={RightIcon} alt="Right Icon" /></S.EditCaloriesNavigationIconContainer>
                </S.EditCaloriesWeekHeadingContainer>
                <S.EditCaloriesInputCard>
                    <S.EditCaloriesInputCardHeading><TranslationMessage id="input.label.restDay" /></S.EditCaloriesInputCardHeading>
                    <IncrementInput width="200" placeholder="0 cm" id='cm' inputMeasure='' value={restDayCalories} setData={setRestDayCalories} />
                </S.EditCaloriesInputCard>
                <S.EditCaloriesInputCard>
                    <S.EditCaloriesInputCardHeading><TranslationMessage id="input.label.trainingDay" /></S.EditCaloriesInputCardHeading>
                    <IncrementInput width="200" placeholder="0 kg" id='kg' inputMeasure='' value={trainingDayCalories} setData={setTrainingDayCalories} />
                </S.EditCaloriesInputCard>
                <Button
                    color='rgba(46, 207, 150, .2)'
                    onClick={handleCaloriesUpdate}
                    gradient="linear-gradient(60deg, rgba(46, 207, 150, 1), rgba(28, 157, 135, 1)) !important"
                    disabled={parseInt(restDayCalories) === 0 || parseInt(trainingDayCalories) === 0}
                >
                    <TranslationMessage id="button.changeCalories" />
                </Button>
            </Modal>
        </div>
    )
}

export default NutritionCalories
