import React, {useState, useEffect, useCallback} from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import * as S from './supplements.styles';
import {getRandomValueInRange, getImageUrl} from "configs/utils";

// Components
import TranslationMessage from 'configs/translations/TranslationMessage';
import Topbar from 'components/topbar/Topbar';
import Card from 'components/card/Card';
import Switch from 'components/formComponents/switch/Switch';
import MultiCarousel from 'components/multiCarousel/MultiCarousel';
import Loader from "components/loader/Loader";

//Images
import alertTriangle from 'assets/images/alertTriangle.svg';
import GreenCheck from 'assets/images/GreenCheck.svg';

// Redux
import * as SupplementsActions from 'redux/supplements/supplements.actions';
import * as AuthActions from "redux/auth/auth.actions";

const Supplements = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const loading = useSelector(state => state.auth.loading);
    const supplementsData = useSelector(state => state.supplements.supplements);

    const [checkedStatus, setCheckedStatus] = useState([true, true, true]);

    const getActualCost = useCallback(
        () => {
            let totalSupplementsCost = 0;
            supplementsData.map((supplement, index) => {
                if(checkedStatus[index]) totalSupplementsCost = totalSupplementsCost + supplement.daily_price;
                return true;
            })
            return totalSupplementsCost;
        },
        [supplementsData, checkedStatus],
      );

    useEffect(() => {
        dispatch(SupplementsActions.getSupplementsRequest())
    }, [dispatch])

    const getContentPoints = (str) => {
        const contentPoints = str.split('\r\n');

        return contentPoints.map(point => {
            return (
                <S.SupplementContentPoints>
                    {point}
                </S.SupplementContentPoints>
            )
        })
    }

    const getAdvantagePoints = str => {
        const sliceValue = str.slice(4, -7);
        const words = sliceValue.split('</li>');
        words.pop();
        const allAdvantages = [];
        words.map(data => allAdvantages.push(data.slice(8)))

        return allAdvantages.map(advantage => {
            return (
                <S.SupplementsAdvantageContainer>
                    <S.GreenTickContainer>
                        <img src={GreenCheck} alt="GreenCheck" />
                    </S.GreenTickContainer>
                    <S.Advantage >
                        {advantage}
                    </S.Advantage>
                </S.SupplementsAdvantageContainer>
            )
        })
    }

    const handleSwitchChange = index => {
        const currentSwitchStatus = checkedStatus.slice();
        currentSwitchStatus[index] = !currentSwitchStatus[index]
        setCheckedStatus(currentSwitchStatus)
    }

    const handleNext = () => {
        if(checkedStatus.includes(true)) return "/overview"
        else return "/overview/basic"
    }

    const supplementsStatus = {supplementsStatus: checkedStatus, foodiaryPro: checkedStatus.includes(true), totalSupplementsCost: getActualCost()};

    const onNext = () => {
        dispatch(AuthActions.setHealthScoreData(supplementsStatus))
    }

    return (
        <>
            <Topbar prev="/premiumCoaching/success" next={handleNext()} redirect onNext={onNext} progress={'81%'} percent={getRandomValueInRange(40, 45)} actualCost={getActualCost()} healthReport onClose={() => history.push('/login')} close />
            <div style={{ padding: "50px" }} >
                {<Loader loading={loading} top="220px" />}
                <MultiCarousel itemClass="" totalItems={1} noAutoPlay slidesToSlide={1} >
                {supplementsData.map((supplement, index) => {
                    return (
                        <Card
                        width="764px"
                        textAlign="left"
                        margin= "auto"
                        style={{minHeight: "527px"}}
                    >
                        <S.CardInnerContainer>
                            <S.CardLeftContainer >
                                <S.SupplementImageContainer>
                                    <S.SupplementImage src={getImageUrl(supplement.imgpath, supplement.image, supplement.imgtype)} alt="supplements" />
                                </S.SupplementImageContainer>
                                <S.AlertMessageContainer >
                                    <S.AlertTriangleImage src={alertTriangle} alt="alert-triangle" />
                                    <S.AlertMessage >
                                        <TranslationMessage id="supplements.alert.message" />
                                    </S.AlertMessage>
                                </S.AlertMessageContainer>
                            </S.CardLeftContainer>
                            <S.CardRightContainer>
                                <S.SupplementsCardHeading>
                                    {supplement.name}
                                </S.SupplementsCardHeading>
                                <S.SupplementsCardsubHeading  >
                                    {supplement.subtitle}
                                </S.SupplementsCardsubHeading>
                                <S.SupplementsContentContainer>
                                    <TranslationMessage id="supplements.card.contents" />
                                    {getContentPoints(supplement.short_text)}
                                    <S.SupplementsContentDescription>
                                        <TranslationMessage id="supplements.card.contents.desc" />
                                    </S.SupplementsContentDescription>
                                </S.SupplementsContentContainer>
                                <S.SupplementsAdvantage>
                                    <TranslationMessage id="supplements.card.advantage" />
                                </S.SupplementsAdvantage>
                                {getAdvantagePoints(supplement.description)}
                                <S.SupplementPlanContainer>
                                    <S.SupplementPlan>
                                        <S.SupplementPlanHeading >
                                            <TranslationMessage id="supplements.card.monthly" />
                                        </S.SupplementPlanHeading>
                                        <S.SupplementPlanPrice color="#1C9D87" >
                                            {supplement.monthly_price}€
                                        </S.SupplementPlanPrice>
                                    </S.SupplementPlan>
                                    <S.SupplementPlan>
                                        <S.SupplementPlanHeading >
                                            <TranslationMessage id="supplements.card.daily" />
                                        </S.SupplementPlanHeading>
                                        <S.SupplementPlanPrice color="#2ECF96" >
                                            {supplement.daily_price}€
                                        </S.SupplementPlanPrice>
                                    </S.SupplementPlan>
                                </S.SupplementPlanContainer>
                                <S.SwitchContainer>
                                    <Switch switchedOn={checkedStatus[index]} small onChange={() => handleSwitchChange(index)} />
                                    <S.Info>
                                        <TranslationMessage id="supplements.card.info" />
                                    </S.Info>
                                </S.SwitchContainer>
                            </S.CardRightContainer>
                        </S.CardInnerContainer>
                    </Card>
                    )
                })
                    
                }
                </MultiCarousel>
            </div>
        </>
    )
}

export default Supplements