import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";

import Arrow from "assets/images/arrow-white.svg";
import ArrowActive from "assets/images/arrow-green.svg";
import EditLightGrey from "assets/images/edit-light-grey.svg";

import Input from "components/formComponents/input/Input";
import ClientDetailsSidebar from "./ClientDetailsSidebar";

import * as AuthActions from "redux/auth/auth.actions";
import * as DashboardActions from "redux/dashboard/dashboard.actions";
import * as S from "./ClientDetails.styles";
import "./clientDetails.scss";
import { toast } from "react-toastify"

const Goals = [
  {
    id: 1,
    title: 'Abnehmen',
    subtitle: 'Die Kalorien im Ernährungsplan werden gesenkt.',
    description: 'Die Kalorien im Ernährungsplan sind täglich um 200 Kalorien unter dem Gesamtbedarf angesetzt, was zu einem wöchentlichen Defizit von 1.400 Kalorien führt. Zusätzlich erfolgt eine dynamische, wöchentliche Anpassung, sodass in der ersten Woche ein Gesamtdefizit von 3.500 Kalorien erreicht wird.'
  },
  {
    id: 2,
    title: 'Muskelaufbau Lean',
    subtitle: 'Die Kalorien im Ernährungsplan werden erhöht.',
    description: 'Der Ernährungsplan enthält täglich 250 Kalorien mehr als den Gesamtbedarf, was zu einem wöchentlichen Überschuss von 1.750 Kalorien führt. Durch eine dynamische, wöchentliche Anpassung entsteht in der ersten Woche ein Gesamtüberschuss von 2.800 Kalorien.'
  },
  {
    id: 3,
    title: 'Muskelaufbau Bulk',
    subtitle: 'Die Kalorien im Ernährungsplan werden stark erhöht.',
    description: 'Der Ernährungsplan enthält täglich 400 Kalorien mehr als den Gesamtbedarf, was zu einem wöchentlichen Überschuss von 2.800 Kalorien führt. Durch eine dynamische, wöchentliche Anpassung entsteht in der ersten Woche ein Gesamtüberschuss von 4550 Kalorien.'
  },
  {
    id: 4,
    title: 'Gewicht halten',
    subtitle: 'Die Kalorien entsprechen dem Gesamtumsatz. ',
    description: 'Der Ernährungsplan ist so gestaltet, dass die Kalorienzufuhr dem täglichen Gesamtbedarf entspricht. Durch das „Kalorien-Cycling” variiert die Kalorienmenge jedoch zwischen Trainings- und trainingsfreien Tagen, sodass der wöchentliche Gesamtbedarf dennoch ausgeglichen bleibt.'
  }
]

const Objective = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { userId } = useParams();
  const [ selectedGoal, setSelectedGoal ] = useState({})

  const trainerData = useSelector((state) => state.auth.trainerData);

  const userWeightGoalData = useSelector(
    (state) => state.auth.userWeightGoalData
  );

  const isUserWeightGoalDataLoading = useSelector(
    (state) => state.auth.isUserWeightGoalDataLoading
  );

  const isGoalWeightUpdating = useSelector(
    (state) => state.dashboard.isGoalWeightUpdating
  );

  const [GoalWeightValue, SetGoalWeightValue] = useState(0);

  useEffect(() => {
    dispatch(
      DashboardActions.getTrainerProfileTempDetailsRequest({
        payload: { trainerID: trainerData?.trainerID },
        callback: () => null,
      })
    );

    dispatch(
      AuthActions.getUserWeightGoalDataRequest({
        formData: {
          userID: userId,
        },
        callback: (res) => {
          SetGoalWeightValue(res?.data?.goal_weight || 0);
        },
      })
    );
  }, [dispatch]);

  useEffect(() => {
    if (!userWeightGoalData?.data) return

    setSelectedGoal(
      Goals.find(
        goal => goal.id === userWeightGoalData?.data?.aimID
      ) || {}
    )
  }, [ userWeightGoalData ])

  const updateGoalWeight = () => {
    console.log("updating with", {
      userId,
      GoalWeightValue,
      aimId: selectedGoal.id
    });

    dispatch(
      DashboardActions.updateSelectedGoalRequest({
        payload: { user_id: userId, aim_id: selectedGoal.id },
        callback: (response, error) => {
          if (error) return console.log('got error : ', error)

          console.log('updated aimID : ', response)

          dispatch(
            DashboardActions.updateGoalWeightRequest({
              payload: {
                userID: userId,
                goal_weight: GoalWeightValue,
              },
              callback: () => {

                console.log('message : ', response.message)
                toast(
                  response.message || "Das Ziel und die Kalorien deines Klienten wurden geändert.",
                  { type: "success" }
                )

                dispatch(
                  AuthActions.getUserWeightGoalDataRequest({
                    formData: {
                      userID: userId,
                    },
                    callback: () => null,
                  })
                );
              },
            })
          )

        }
      })
    );

    console.log('updating with new selected goal : ', selectedGoal)
  };

  const goalsList = Goals.map((item) => {
    const isActive = selectedGoal.id && item.id === selectedGoal.id

    return (
      <S.ClientDetailsDailyRoutineTab
        isActive={isActive}
        key={item.id}
        onClick={() => setSelectedGoal(item)}
      >
        <div>
          <S.ClientDetailsDailyRoutineTabHeading>
            {item.title}
          </S.ClientDetailsDailyRoutineTabHeading>
          <S.ClientDetailsDailyRoutineTabDescription>
            {item.subtitle}
          </S.ClientDetailsDailyRoutineTabDescription>
        </div>

        <S.ClientDetailsDailyRoutineTabRightArrowIcon
          alt="right-icon-tab"
          src={isActive ? ArrowActive : Arrow}
        />
    </S.ClientDetailsDailyRoutineTab>
    )
  })

  return (
    <>
      <S.ClientDetailsOverallContainer>
        <S.ClientDetailsContainer>
          <div className="second-sidebar-wrapper">
            <ClientDetailsSidebar />
          </div>
          <div className="second-sidebar-content" style={{ padding: "1.5rem" }}>
            <S.ContainerBox>
              <S.Header>
                <div>
                  <S.Heading>Zielsetzung</S.Heading>
                  <S.SubHeading>
                    Für dieses Ziel hat sich dein Klient während der Betreuung
                    entschieden.
                  </S.SubHeading>
                </div>
                <div>
                  <button
                    className="btn-dark"
                    onClick={() => updateGoalWeight()}
                    disabled={isGoalWeightUpdating}
                  >
                    {isGoalWeightUpdating ? "Loading..." : "Speichern"}
                  </button>
                </div>
              </S.Header>

              <S.Divider></S.Divider>
              {isUserWeightGoalDataLoading && (
                <>
                  <br />
                  <br />
                  <br />
                  <br />
                  <div className="text-center">
                    <div className="loader mx-auto"></div>
                  </div>
                  <br />
                  <br />
                  <br />
                  <br />
                </>
              )}
              {!isUserWeightGoalDataLoading && (
                <>
                  <S.PaddingBox>
                    <div className="container-70rem">
                      <S.GoalsHeading>
                        Das festgelegte Ziel deines Klienten
                      </S.GoalsHeading>
                      <br />
                      <S.ClientDetailsDailyRoutineContainer>
                        <S.ClientDetailsDailyRoutineTabContainer>
                          {goalsList}
                        </S.ClientDetailsDailyRoutineTabContainer>
                        <S.ClientDetailsDailyRoutineTabDetailsContainer className="h-auto">
                          <S.ClientDetailsDailyRoutineTabDetailsInnerContainer>
                            <div className="daily-routine-details-header">
                              <div>
                                <h6 className="mb-1">{ selectedGoal.title }</h6>
                                <p>{ selectedGoal.description }</p>
                              </div>
                            </div>
                          </S.ClientDetailsDailyRoutineTabDetailsInnerContainer>
                        </S.ClientDetailsDailyRoutineTabDetailsContainer>
                      </S.ClientDetailsDailyRoutineContainer>
                    </div>
                  </S.PaddingBox>
                  <S.Divider></S.Divider>
                  <S.PaddingBox>
                    <div className="container-70rem">
                      <S.GoalsHeading>
                        Bearbeite das Zielgewicht deines Klienten
                      </S.GoalsHeading>
                      <br />
                      <S.FormWrap>
                        <S.FormControl>
                          <S.ClientDetailsOverviewInputLabel>
                            Startgewicht <small>(Kg)</small>
                          </S.ClientDetailsOverviewInputLabel>
                          <Input
                            style={{ width: "100%" }}
                            type="text"
                            placeholder={`${userWeightGoalData?.data?.weight}`}
                            filled
                            disabled
                          />
                        </S.FormControl>
                        <S.FormControl>
                          <S.ClientDetailsOverviewInputLabel>
                            Zielgewicht <small>(Kg)</small>
                          </S.ClientDetailsOverviewInputLabel>
                          <Input
                            type="text"
                            placeholder={`50`}
                            value={`${GoalWeightValue}`}
                            onChange={(e) => SetGoalWeightValue(e.target.value)}
                          />
                          {/* <img
                            src={EditLightGrey}
                            alt="edit"
                            onClick={() => setIsEdit(false)}
                          /> */}
                        </S.FormControl>
                      </S.FormWrap>
                    </div>
                  </S.PaddingBox>
                </>
              )}
            </S.ContainerBox>
          </div>
        </S.ClientDetailsContainer>
      </S.ClientDetailsOverallContainer>
    </>
  );
};

export default Objective;
