import React, { useState, useEffect } from "react";

import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import ReactApexChart from "react-apexcharts";

import TranslationMessage from "configs/translations/TranslationMessage";
import Button from "components/formComponents/button/Button";
import IncrementInput from "components/formComponents/incrementInput/IncrementInput";
import Loader from "components/loader/Loader";
import LineChart from "components/lineChart/LineChart";
import ClientDetailsSidebar from "./ClientDetailsSidebar";

import LeftIcon from "assets/images/leftIcon.svg";
import RightIcon from "assets/images/rightIcon.svg";

import * as AuthActions from "redux/auth/auth.actions";
import * as NutritionActions from "redux/nutrition/nutrition.actions";
import * as S from "./ClientDetails.styles";
import "./clientDetails.scss";

const Calories = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const intl = useIntl();
  const { userId } = useParams();

  const clientDetails = useSelector(
    (state) => state.auth.getClientDetails?.data
  );
  const isClientDetailsLoading = useSelector(
    (state) => state.auth.isClientDetailsLoading
  );

  const caloriesData = useSelector(
    (state) => state.nutrition.caloriesLineChartData
  );

  const allCaloriesData = useSelector(
    (state) => state.nutrition.allCaloriesData
  );

  const trainerData = useSelector((state) => state.auth.trainerData);
  const [isUpdating, setIsUpdating] = useState(false);

  const [restDayCalories, setRestDayCalories] = useState(
    allCaloriesData?.["week1"]?.[0]?.calories || 0
  );
  const [trainingDayCalories, setTrainingDayCalories] = useState(
    allCaloriesData?.["week1"]?.[1]?.calories || 0
  );
  const [weekNumber, setWeekNumber] = useState(1);
  const [isCaloriesLoading, setIsCaloriesLoading] = useState(false);
  const [lineChartStatus, setLineChartStatus] = useState(null);

  const caloriesDataArray = [
    { label: "1", x: 1, y: caloriesData?.["1"] || 0 },
    { label: "2", x: 2, y: caloriesData?.["2"] || 0 },
    { label: "3", x: 3, y: caloriesData?.["3"] || 0 },
    { label: "4", x: 4, y: caloriesData?.["4"] || 0 },
    { label: "5", x: 5, y: caloriesData?.["5"] || 0 },
    { label: "6", x: 6, y: caloriesData?.["6"] || 0 },
    { label: "7", x: 7, y: caloriesData?.["7"] || 0 },
    { label: "8", x: 8, y: caloriesData?.["8"] || 0 },
    { label: "9", x: 9, y: caloriesData?.["9"] || 0 },
    { label: "10", x: 10, y: caloriesData?.["10"] || 0 },
    { label: "11", x: 11, y: caloriesData?.["11"] || 0 },
    { label: "12", x: 12, y: caloriesData?.["12"] || 0 },
  ];

  const [options, setOptions] = useState({
    series: [
      {
        name: "Trainingsfreier Tag",
        data: [],
      },
      {
        name: "Trainingstag",
        data: [],
      },
    ],
    chart: {
      height: 350,
      type: "area",
    },
    colors: ["#2ECF96", "#44554C"],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      categories: [], // Will be filled with the time categories
    },
    tooltip: {},
  });

  useEffect(() => {
  
    dispatch(
      AuthActions.getClientDetailsRequest({
        payload: {
          trainerID: trainerData?.trainerID,
          userID: +userId,
        },
        callback: (res) => {
          console.log(res);
        },
      })
    );

    getAllCalories();
  }, [dispatch]);

  const setCaloriesValues = (weeks, weekNumber) => {
    setRestDayCalories(weeks?.["week" + weekNumber]?.[0]?.calories || 0);
    setTrainingDayCalories(weeks?.["week" + weekNumber]?.[1]?.calories || 0);
  };

  const getAllCalories = () => {
    setIsCaloriesLoading(true);
    dispatch(
      NutritionActions.getAllCaloriesRequest({
        data: {
          nutritionconceptID: clientDetails?.nutritionconceptID,
          nutritiontypeID: clientDetails?.nutritiontypeID,
          aimID: clientDetails?.aimID,
          sex: clientDetails?.sex,
        },
        funcSuccess: (res) => {
          console.log("getAllCaloriesRequest---res", res);
          setCaloriesValues(res?.data, weekNumber);
          setIsCaloriesLoading(false);

          const erholungstagData = Object.values(res?.graphData[0]).filter(
            (val) => !isNaN(val)
          ); // filtering only numeric values
          const trainingstagData = Object.values(res?.graphData[1]).filter(
            (val) => !isNaN(val)
          );

          // Categories will be the same for both, you can adjust this if needed
          const categoriesData = Object.keys(res?.graphData[0]).filter(
            (key) => !isNaN(key)
          ).map(i => +i + 1);


          setLineChartStatus(
            (
              res?.graphData[0]["11"] -
              res?.graphData[0]["0"]
            ).toFixed(2) + " kcal"
          );

          // Find the maximum values for both datasets
          const maxErholungstag = Math.max(...erholungstagData);
          const maxTrainingstag = Math.max(...trainingstagData);

          // Get the overall max value and round it
          const maxValue = Math.max(maxErholungstag, maxTrainingstag);
          const roundedMaxValue = Math.ceil((maxValue + 100) / 500) * 500;
          const tickAmount = Math.ceil(roundedMaxValue / 500);

          setOptions((prevOptions) => ({
            ...prevOptions,
            series: [
              {
                ...prevOptions.series[0],
                data: erholungstagData,
              },
              {
                ...prevOptions.series[1],
                data: trainingstagData,
              },
            ],
            xaxis: {
              ...prevOptions.xaxis,
              categories: categoriesData,
            },
            yaxis: {
              min: 0,
              max: roundedMaxValue,
              tickAmount: tickAmount,
              labels: {
                formatter: (value) => Math.round(value),
              },
            },
          }));
        },
      })
    );
  };

  const handleRightNavigation = () => {
    if (weekNumber !== 12) {
      setWeekNumber(weekNumber + 1);
      setCaloriesValues(allCaloriesData, weekNumber + 1);
    }
  };

  const handleLeftNavigation = () => {
    if (weekNumber !== 1) {
      setWeekNumber(weekNumber - 1);
      setCaloriesValues(allCaloriesData, weekNumber - 1);
    }
  };

  const handleCaloriesUpdate = () => {
    setIsUpdating(true);
    dispatch(
      NutritionActions.updateNutritionCaloriesRequest({
        payload: {
          nutritiontypeID: clientDetails?.nutritiontypeID,
          nutritionconceptID: clientDetails?.nutritionconceptID,
          aimID: clientDetails?.aimID,
          sex: clientDetails?.sex,
          week: weekNumber,
          caloriesData: [
            { daytypeID: 1, calories: restDayCalories },
            { daytypeID: 2, calories: trainingDayCalories },
          ],
        },
        callback: () => {
          setIsUpdating(false);
          // hitCaloriesDataAPI();
          getAllCalories();
        },
      })
    );
  };

  return (
    <>
      <S.ClientDetailsOverallContainer>
        <S.ClientDetailsContainer>
          <div className="second-sidebar-wrapper">
            <ClientDetailsSidebar />
          </div>
          <div className="second-sidebar-content" style={{ padding: "1.5rem" }}>
            <S.ContainerBox>
              <S.Header>
                <div>
                  <S.Heading>Kalorien</S.Heading>
                  <S.SubHeading>
                    So verändert sich die Kalorien deines Klienten.
                  </S.SubHeading>
                </div>
                <div>
                  <button
                    className="btn-dark"
                    onClick={() => handleCaloriesUpdate()}
                    disabled={isUpdating}
                  >
                    {isUpdating ? "Loading..." : "Speichern"}
                  </button>
                </div>
              </S.Header>

              <S.Divider></S.Divider>
              <S.PaddingBox>
                <br />
                <br />
                <br />

                {isClientDetailsLoading && (
                  <>
                    <br />
                    <br />
                    <br />
                    <br />
                    <div className="text-center">
                      <div className="loader mx-auto"></div>
                    </div>
                    <br />
                    <br />
                    <br />
                    <br />
                  </>
                )}

                {!isClientDetailsLoading && (
                  <>
                    <S.ClientDetailsCaloriesContainer>
                      <S.ClientDetailsWeekNavigationContainer>
                        <S.ClientDetailsCaloriesNavigationIconContainer
                          onClick={handleLeftNavigation}
                        >
                          <img src={LeftIcon} alt="left Icon" />
                        </S.ClientDetailsCaloriesNavigationIconContainer>
                        <S.ClientDetailsEditCaloriesWeekHeading>
                          <TranslationMessage id="nutrition.formCalories.editCalories.week" />{" "}
                          {weekNumber}
                        </S.ClientDetailsEditCaloriesWeekHeading>
                        <S.ClientDetailsCaloriesNavigationIconContainer
                          onClick={handleRightNavigation}
                        >
                          <img src={RightIcon} alt="Right Icon" />
                        </S.ClientDetailsCaloriesNavigationIconContainer>
                      </S.ClientDetailsWeekNavigationContainer>

                      <S.ClientDetailsCaloriesLineChartContainer>
                        {/* <Loader loading={isCaloriesLoading} top="110px" /> */}
                        {!isCaloriesLoading && (
                          <>
                            <div
                              className="apex-clients-calories-chart"
                              id="apex-clients-calories-chart"
                            >
                              <S.LineChartContainer>
                                <S.LineChartHeading>
                                  Kalorien
                                </S.LineChartHeading>
                                <span style={{ flexGrow: 1 }}> </span>
                                <S.LineChartStatus>
                                  {lineChartStatus} <br />
                                  <S.LineChartDescription>
                                    in 12 Wochen
                                  </S.LineChartDescription>
                                </S.LineChartStatus>
                              </S.LineChartContainer>
                              <br />
                              <ReactApexChart
                                options={options}
                                series={options.series}
                                type="area"
                                height={350}
                              />
                            </div>
                            {/* <LineChart
                              className="custom-chart"
                              heading="Grundumsatz"
                              status={lineChartStatus}
                              description="in 12 Wochen"
                              fontSize={8}
                              precision={0}
                              padding={30}
                              width={420}
                              height={120}
                              data={caloriesDataArray}
                              horizontalGuideLines={3}
                              yAxisBetweenRange
                            /> */}
                          </>
                        )}
                      </S.ClientDetailsCaloriesLineChartContainer>
                      <br />
                      <S.ClientDetailsIncrementInputContainer>
                        {
                          <>
                            <S.ClientDetailsIncrementInputCard>
                              <S.ClientDetailsIncrementInputCardHeading>
                                Trainingsfreier Tag
                              </S.ClientDetailsIncrementInputCardHeading>
                              <IncrementInput
                                width="200"
                                placeholder="0 kcal"
                                id="restDaykcal"
                                inputMeasure="kcal"
                                defaultValue={parseInt(restDayCalories)}
                                value={parseInt(restDayCalories)}
                                setData={setRestDayCalories}
                              />
                            </S.ClientDetailsIncrementInputCard>
                            <S.ClientDetailsIncrementInputCard>
                              <S.ClientDetailsIncrementInputCardHeading>
                                {intl.formatMessage({
                                  id: "input.label.trainingDay",
                                })}
                              </S.ClientDetailsIncrementInputCardHeading>
                              <IncrementInput
                                width="200"
                                placeholder="0 kcal"
                                id="trainingDaykcal"
                                inputMeasure="kcal"
                                defaultValue={parseInt(trainingDayCalories)}
                                value={parseInt(trainingDayCalories)}
                                setData={setTrainingDayCalories}
                              />
                            </S.ClientDetailsIncrementInputCard>
                          </>
                        }
                      </S.ClientDetailsIncrementInputContainer>
                    </S.ClientDetailsCaloriesContainer>
                  </>
                )}
              </S.PaddingBox>
            </S.ContainerBox>
          </div>
        </S.ClientDetailsContainer>
      </S.ClientDetailsOverallContainer>
    </>
  );
};

export default Calories;
