import React, { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getS3ImageUrl } from "configs/utils";
import { Switch } from 'antd';
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";

import InnerSideNav from "pages/NewDasboard/components/InnerSideNav/InnerSide";
import DownloadIcon from "assets/images/downloadBlack.svg";
import PDFIcon from "assets/images/pdf.svg";
import LinkIcon from "assets/images/link.svg";
import QrCodeIcon from "assets/images/qr-code.svg";

import { MENUS } from "./SideMenu";
import * as NutritionActions from "redux/nutrition/nutrition.actions";
import * as S from "./MarketingPortal.styles";


const PartnershipDetails = () => {
  const [switchState, setSwitchState] = useState(false);
  const switchFlagCheck = useRef();
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();

  const isMarketingPortalPartnerAndPrintDetailsLoading = useSelector(
    (state) => state.nutrition.isMarketingPortalPartnerAndPrintDetailsLoading
  );
  const trainerData = useSelector((state) => state.auth.trainerData);
  const marketingPortalPartnerAndPrintDetails = useSelector(
    (state) => state.nutrition.marketingPortalPartnerAndPrintDetails
  );

  const partner =
    marketingPortalPartnerAndPrintDetails?.getPartnerAndPrintDetails
      ?.partner_data || null;

  useEffect(() => {
    if (partner?.partner_claimed == 1) {
      setSwitchState(true)
    }
    else {
      setSwitchState(false)
    }

  }, [partner])

  useEffect(() => {


    if (!partner?.partnerUrl||!switchFlagCheck.current ) return;
    
    const affiliate_code = partner?.partnerUrl.split('=')[1];
    const payload = {
      label: switchState ? "check" : "uncheck",
      trainerID: trainerData?.id,
      partnerID: id,
      first_meeting_url_affiliate_tag: switchState ? partner?.partnerUrl : null,
      affiliate_code: switchState ? affiliate_code : null,
    }
    dispatch(
      NutritionActions.updatePartnerClaimRequest({
        payload: payload,
        callback: (res) => { },
      })
    );
  }, [switchState])


  useEffect(() => {
    getMarketingPortalPartnerAndPrintDetails();
  }, [dispatch]);

  const getMarketingPortalPartnerAndPrintDetails = () => {
    dispatch(
      NutritionActions.getMarketingPortalPartnerAndPrintDetailsRequest({
        payload: {
          partnerId: +id,
        },
        callback: (res) => { },
      })
    );
  };

  return (
    <>
      <S.Container>
        <div className="second-sidebar-wrapper">
          <InnerSideNav menus={MENUS} hideUserInfo></InnerSideNav>
        </div>
        <S.Wrapper className="second-sidebar-content">
          <S.PrintDataDetails className="bg-white">
            <S.TrainerProfileDetailsInnerContainer className="p-0">
              <S.Header>
                <div>
                  <div className="back-arrow-icon-div">
                    <S.BackButton
                      onClick={() =>
                        history.push("/dashboard/marketing-portal/partners")
                      }
                    >
                      <NavigateBeforeIcon />
                    </S.BackButton>
                    <S.TrainerProfileDetailsSectionHeading>
                      Zurück zur Übersicht
                    </S.TrainerProfileDetailsSectionHeading>
                  </div>

                  <S.TrainerProfileDetailsSectionSubHeading style={{ marginLeft: '1.5rem', marginTop: 0 }}>
                    Wichtige Infos über deinen Interessenten
                  </S.TrainerProfileDetailsSectionSubHeading>
                </div>

                {!isMarketingPortalPartnerAndPrintDetailsLoading && (
                  <>
                    {partner?.status === "Partnerschaft beendet" && (
                      <div className="status-badge orange">
                        {partner?.status}
                      </div>
                    )}
                    {partner?.status === "Partnerschaft aktiv" && (
                      <div className="status-badge green">
                        {partner?.status}
                      </div>
                    )}
                  </>
                )}
              </S.Header>
              <S.Divider />
            </S.TrainerProfileDetailsInnerContainer>

            {isMarketingPortalPartnerAndPrintDetailsLoading && (
              <>
                <br />
                <br />
                <br />
                <br />
                <div className="text-center">
                  <div className="loader mx-auto"></div>
                </div>
                <br />
                <br />
                <br />
                <br />
              </>
            )}
            {!isMarketingPortalPartnerAndPrintDetailsLoading && (
              <S.PartnerDetails>
                <div className="partner-sidebar">
                  <div className="d-flex align-items-center gap-3 ">
                    <img
                      className="partner-logo "
                      src={
                        getS3ImageUrl(partner?.companylogo)
                        || "https://foodiaryproduction.s3.eu-central-1.amazonaws.com/Marketingportal/Company Logo/Placeholder-Profilbild_1714193073TP5TDPmEgj.jpg"
                      }
                    />
                    <S.CompanyDetails>
                      <h6>{partner?.companyName}</h6>
                      <p>{`${partner?.street} ${partner?.houseNumber}, ${partner?.zipcode} ${partner?.city}`}</p>
                    </S.CompanyDetails>
                  </div>
                  <S.CompanyDetails>
                    <div className="partner-url-item">
                      <div>
                        <img src={LinkIcon} alt="" />
                      </div>
                      <div>
                        <a
                          href={partner?.partnerUrl}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {partner?.partnerUrl}
                        </a>
                      </div>
                    </div>
                    <S.QRCode>
                      <div
                        className="cursor-pointer"
                        onClick={() => window.open(partner?.qrCodeUrl, "_blank")}
                      >
                        <img src={QrCodeIcon} alt="" className="img" />
                      </div>
                      <span>QR - Code herunterladen</span>
                    </S.QRCode>

                  </S.CompanyDetails>
                </div>
                <div className="partner-sidebar mb-1-rem" >
                  <S.PartnerUrl>
                    <div className="url-title" >
                      <h6>Werbepartner festlegen:</h6>
                      <p>Nutze diese Partnerschaft als deine eigene, sodass Leads über deine Webseite und dein Lead Form zugeordnet werden.</p>
                    </div>
                    <Switch value={switchState} defaultChecked onChange={(e) => {   
                      setSwitchState(e);
                      switchFlagCheck.current = true;
                    }} />
                  </S.PartnerUrl>
                </div>

                <div className="partner-content">
                  <h6 className="title">Kontaktdetails:</h6>
                  <div className="info-item">
                    <span>Ansprechpartner: {partner?.contact}</span>
                    <span>Telefon: {partner?.phone}</span>
                  </div>
                  <div className="info-item">
                    <span>
                      Email: <a href="mailto:">{partner?.email}</a>
                    </span>
                    <span>
                      Website:{" "}
                      <a
                        href={partner?.website}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {partner?.website}
                      </a>
                    </span>
                  </div>
                  <S.Divider style={{ marginLeft: '-1.5rem', marginRight: '-1.5rem' }} />
                  <h6 className="title">Verlinkte Dokumente:</h6>
                  <div className="document-item">
                    <div className="file">
                      <img src={PDFIcon} alt="" />
                      <div>
                        <p>Vertragsdatei</p>
                        <small>2.2 mb</small>
                      </div>
                    </div>
                    <button
                      className="btn-outline sm"
                      onClick={() =>
                        window.open(
                          getS3ImageUrl(partner?.contractUrl),
                          "_blank"
                        )
                      }
                    >
                      Vertrag herunterladen
                    </button>
                  </div>
                  <h6 className="title">Verbundene Werbemittel:</h6>

                  <div className="grid-3-columns">
                    {marketingPortalPartnerAndPrintDetails?.getPartnerAndPrintDetails?.print_data.map(
                      (p) => (
                        <S.BrochureCard>
                          <S.BrochureCardImage>
                            <img
                              className="card-img"
                              src={p?.previewImg1}
                              alt="preview"
                            />
                            <img
                              className="download-icon"
                              src={DownloadIcon}
                              alt="DownloadIcon"
                              onClick={() => window.open(p?.file_url, "_blank")}
                            />
                            <div className="tag-label">{p?.tag}</div>
                          </S.BrochureCardImage>

                          <S.BrochureCardBody>
                            <div>
                              <h6>{p?.title}</h6>
                              <p>Werbeset: {p?.setID}</p>
                            </div>
                          </S.BrochureCardBody>
                        </S.BrochureCard>
                      )
                    )}
                  </div>
                </div>
              </S.PartnerDetails>
            )}
          </S.PrintDataDetails>
        </S.Wrapper>
      </S.Container>
    </>
  );
};

export default PartnershipDetails;
