import React, { useEffect } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import * as S from "./SendChangePasswordEmail.styles";
import { useDispatch, useSelector } from "react-redux";
import { useAlert } from "react-alert";
import Slider from "react-slick";
// Components
import Topbar from "components/topbar/Topbar";
import Card from "components/card/Card";
import Input from "components/formComponents/input/Input";
import Button from "components/formComponents/button/Button";
import Loader from "components/loader/Loader";
import ErrorMessage from "components/formComponents/errorMessage/ErrorMessage";
import FoodiaryLogo from "assets/images/Foodiary-Logo.PNG";
import TranslationMessage from "configs/translations/TranslationMessage";
import FoodiaryBackground from "assets/images/Foodiary-Background.jpg";
import DashboardOne from "assets/images/Foodiary-dashboard-screen.jpg";
import DashboardTwo from "assets/images/Foodiary-Klienten-Screen.jpg";
import DashboardThree from "assets/images/Foodiary-Academy-Screen.jpg";
import ExclamationCircle from "assets/images/exclamation-circle.svg";
import Hand from "assets/images/YoHand.svg";
// Redux
import * as AuthActions from "redux/auth/auth.actions";
import * as DashboardActions from "redux/dashboard/dashboard.actions";

const SendChangePasswordEmail = () => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const isEmailSending = useSelector((state) => state.dashboard.isEmailSending);
  const sendEmail = useSelector((state) => state.dashboard.sendEmail);

  useEffect(() => {
    dispatch(DashboardActions.sendEmailNull());
  }, []);

  const initialValues = {
    coachId: "",
    email: "",
  };

  const validationSchema = Yup.object().shape({
    coachId: Yup.string().required("Erforderlich"),
    email: Yup.string()
      .email("E-Mail muss bei uns registriert sein")
      .required("Erforderlich"),
  });

  const onFormSubmit = (values, { resetForm }) => {
    dispatch(
      DashboardActions.sendEmailRequest({
        payload: {
          templateName: "passwort-reset-coach",
          fromEmail: "support@foodiary.app",
          fromName: "Support | Foodiary GmbH",
          toEmail: values.email,
          bccAddress: "spam@foodiary.app",
        },
        callback: (res) => {
          dispatch(DashboardActions.sendEmailNull());
          alert.success("Die Email wurde verschickt", {
            timeout: 5000,
          });
        },
      })
    );
    resetForm();
  };

  let settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };

  return (
    <>
      <S.LoginContainer>
        <Card
          width="100%"
          maxWidth="530px"
          height="auto"
          margin="auto"
          textAlign="center"
        >
          <S.ChangePasswordInnerContainer className="relative">
            <img width={153} src={FoodiaryLogo} alt="" />
            <S.ChangePasswordHeading>
              Passwort zurücksetzen.
            </S.ChangePasswordHeading>
            <S.ChangePasswordSubHeading>
              Gebe deine CoachID zum zurücksetzen ein.
            </S.ChangePasswordSubHeading>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onFormSubmit}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                errors,
                isValid,
                dirty,
              }) => (
                <form
                  className="relative z-1"
                  noValidate
                  autoComplete="off"
                  onSubmit={handleSubmit}
                >
                  <div className="form-group">
                    <label>CoachID*</label>
                    <Input
                      className="input-v2"
                      type="text"
                      placeholder="FCI123"
                      name="coachId"
                      onChange={handleChange}
                      value={values.coachId}
                    />
                    <ErrorMessage error={errors.coachId} />
                  </div>

                  <div className="form-group">
                    <label>E-Mail Adresse*</label>
                    <Input
                      className="input-v2"
                      type="text"
                      placeholder="Ihre E-Mail Adresse"
                      name="email"
                      onChange={handleChange}
                      value={values.email}
                    />
                    <ErrorMessage error={errors.email} />
                    <p className="input-note">
                      <img src={ExclamationCircle} alt="" /> Gebe die E-Mail
                      ein, mit der du dich registriert hast.
                    </p>
                  </div>

                  <Button
                    color="rgba(248, 164, 146, 1)"
                    gradient="linear-gradient(60deg, rgba(46, 207, 150, 1), rgba(28, 157, 135, 1)) !important"
                    style={{ fontSize: "16px" }}
                    margin="25px 0 20px"
                    absolute={{ width: "100%", height: "45px" }}
                    buttonStyle={{ width: "100%" }}
                    type="submit"
                    disabled={isEmailSending}
                  >
                    Passwort zurücksetzen
                  </Button>

                  <Loader loading={isEmailSending} top="-105px" />
                </form>
              )}
            </Formik>
            <img className="hand" src={Hand} alt="" />
          </S.ChangePasswordInnerContainer>
        </Card>
        {/* <S.SliderContainer imgUrl={FoodiaryBackground}>
          <Slider {...settings}>
            <div className="slide-item">
              <img src={DashboardOne} alt="" />
              <h4>Verwalte deine Ernährungsberatung</h4>
              <p>
                Mit dem Coach Dashboard verwaltest du deine Klienten, die Coach
                Webseite und hast Zugang zu wichtigen Funktionen.
              </p>
            </div>
            <div className="slide-item">
              <img src={DashboardTwo} alt="" />
              <h4>Betreue deine Klienten und Teilnehmer</h4>
              <p>
                Über die Klientenverwaltung hast du alle Daten deiner Klienten
                im Überblick und kannst hier wichtige Einstellungen vornehmen.
              </p>
            </div>
            <div className="slide-item">
              <img src={DashboardThree} alt="" />
              <h4>Lerne wichtige Grundlagen</h4>
              <p>
                Mit unserer Academy und den Whitepaper lernst du wichtige
                Grundlagen rund um das Thema “selbstständige Ernährungsberatung”
              </p>
            </div>
          </Slider>
        </S.SliderContainer> */}
      </S.LoginContainer>
    </>
  );
};

export default SendChangePasswordEmail;
