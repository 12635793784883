import React from "react";
import * as S from "./evaluationFormCard.styles";

const EvaluationCard = ({ item, activeItem, onItemSelect }) => {
  const renderItem = activeItem ? activeItem : item;

  return (
    <S.EvaluationCard key={item?.id}>
      <h3 className="title">{renderItem?.title}</h3>
      <p className="desc">{renderItem?.descr}</p>
      <div className="evaluation-items">
        {(renderItem?.isEmoji || renderItem?.isShape) &&
          renderItem?.images.map((i) => (
            <div
              className={
                i.isActive ? "evaluation-item active" : "evaluation-item"
              }
              onClick={() => onItemSelect(renderItem, i)}
            >
              {!i.isActive && <img src={i.img} alt="" />}
              {i.isActive && <img src={i.activeImg} alt="" />}
            </div>
          ))}

        {renderItem?.isNumber &&
          renderItem?.numbers.map((i) => (
            <div
              className={
                i.isActive ? "evaluation-item active" : "evaluation-item"
              }
              onClick={() => onItemSelect(renderItem, i)}
            >
              <p className="">{i.title}</p>
            </div>
          ))}
      </div>
    </S.EvaluationCard>
  );
};

export default EvaluationCard;
