import React from "react";
import * as S from "./evaluationCardForCustomer.styles";

const EvaluationCardForCustomer = ({ item }) => {
  return (
    <S.EvaluationCard>
      <h3 className="title">{item?.title}</h3>
      <p className="desc">{item?.descr}</p>
      <div className="evaluation-items">
        {(item.isEmoji || item.isShape) &&
          item.images.map((i, index) => (
            <div
              className={`evaluation-item ${i.isActive ? "active" : ""}`}
              key={index}
            >
              <div className={"status-bar " + i.color}></div>
              <img src={i.img} alt="" />
            </div>
          ))}

        {item.isNumber &&
          item.numbers.map((i, index) => (
            <div
              key={index}
              className={
                i.isActive ? "evaluation-item active" : "evaluation-item"
              }
            >
              <div className={"status-bar " + i.color}></div>
              <p className="">{i.title}</p>
            </div>
          ))}
      </div>
      <p className="eval-desc">{item?.descr2}</p>
    </S.EvaluationCard>
  );
};

export default EvaluationCardForCustomer;
