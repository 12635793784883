import styled from "styled-components";
import {
  WhatAwaitsYouCardInnerContainer,
  WhatAwaitsYouTopImage,
  WhatAwaitsYouCardHeading,
  WhatAwaitsYouCardSubHeading,
} from "pages/whatAwaitsYou/whatAwaitsYou.styles";

export const MainDashboardContainer = styled.div`
  padding: 1.5rem;
  width: 100%;
`;

export const MainDashboardTitle = styled.h6`
  color: #142c1f;
  font-size: 1.25rem;
  line-height: 1.5rem;
  margin: 0;
`;

export const MainDashboardSubTitle = styled.div`
  color: #818d88;
  font-size: 1rem;
  margin: 1.25rem 0 2rem 0;
  font-weight: 400;
`;

export const MainDashboardCardContainer = styled.div`
  margin: 10px 0;
  display: flex;
  justify-content: center;
`;
export const Button = styled.button`
  padding: 12px 9px;
  font-size: 16px;
  line-height: 19px;
  border: none;
  background: #f8a492;
  border-radius: 8px;
  letter-spacing: 0px;
  color: #ffffff;
  font-family: RubikMedium;
  transition: all 0.3s;
  cursor: pointer;
  transition-duration: 0.3s;
  transition-property: transform;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;

  &:not(:disabled):hover {
    transform: scale(0.9);
  }
`;

export const HomeHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const MainDashboardCardInnerContainer = styled(
  WhatAwaitsYouCardInnerContainer
)``;

export const MainDashboardCardImage = styled(WhatAwaitsYouTopImage)``;

export const MainDashboardCardHeading = styled(WhatAwaitsYouCardHeading)``;

export const MainDashboardCardSubHeading = styled(
  WhatAwaitsYouCardSubHeading
)``;

export const Tab = styled.div`
  border-radius: 1.6rem;
  background-color: ${(props) => (props.active ? "#2ECF96" : "#EEEEEE")};
  color: ${(props) => (props.active ? "white" : "#A5B5AF")};
  padding: 0.75rem 1.25rem;
  font-size: 1rem;
  width: fit-content;
  cursor: pointer;
  font-weight: 400;
`;

export const ClientDetailsContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
`;

export const ClientDetailsTabContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
`;

export const Card = styled.div`
  background: #ffffff;
  box-shadow: 0px 2px 5px #0000001a;
  border-radius: 10px;
  width: 100%;
  padding: 1.875rem 1.56rem;
  min-height: 50vh;

  .loading {
    margin: 5rem auto;
    text-align: center;
  }
`;

export const SmallTitle = styled.p`
  color: #44554c;
  font-size: 1rem;
  font-family: RubikLight;
  margin-block: 1rem;
`;

export const Item = styled.div`
  display: flex;
  gap: 1rem;

  &:not(:last-child) {
    border-bottom: 1px solid #eeeeee;
    padding-bottom: 14px;
    margin-bottom: 14px;
  }

  .video-box {
    cursor: pointer;
    max-width: 250px;
    width: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 0.5rem;
    }
  }

  .content {
    h6 {
      cursor: pointer;
      color: #142c1f;
      font-size: 14px;
      line-height: 17px;
      margin: 0;
    }
    p {
      color: #44554c;
      font-size: 16px;
      line-height: 23px;
      margin: 14px 0 26px;
      font-family: RubikLight;
    }
    .chip {
      background: #f5f5f5;
      border-radius: 5px;
      width: fit-content;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0.2px;
      color: #a5b5af;
      text-transform: uppercase;
      font-family: RubikLight;
      padding: 4px 8px;
    }
  }
`;

export const Divider = styled.div`
  height: 1px;
  background-color: #e2e4e9;
`;
