import { all, call } from "redux-saga/effects";

import { authSagas } from "redux/auth/auth.sagas";
import { nutritionSagas } from "redux/nutrition/nutrition.sagas";
import { supplementsSagas } from "redux/supplements/supplements.sagas";
import { dashboardSagas } from "redux/dashboard/dashboard.sagas";

export default function* rootSaga() {
  yield all([
    call(authSagas),
    call(nutritionSagas),
    call(supplementsSagas),
    call(dashboardSagas),
  ]);
}
