import React from "react";
import * as S from "./table.styles";

// Components
import TranslationMessage from "configs/translations/TranslationMessage";

const Table = ({
  tableHeading,
  tableData,
  pagination,
  onPageChange,
  onRow,
  SlicedPagination,
  CurrentPage,
}) => {
  // const [currentPage, setCurrentPage] = useState(1);

  return (
    <>
      <S.TableOverallContainer className="table-styled">
        <S.TableContainer>
          <S.TableHeadingContainer>
            <tr>
              {tableHeading.map((heading) => {
                return (
                  <th key={heading.value}>
                    {heading.value && <TranslationMessage id={heading.value} />}
                  </th>
                );
              })}
            </tr>
          </S.TableHeadingContainer>
          <tbody>
            {tableData.map((rowData) => {
              return (
                <S.TableRowContainer onClick={() => onRow(rowData)}>
                  {rowData.values.map((data) => {
                    if (data?.isPlan) {
                      if (data?.value === "PRO-USER") {
                        return (
                          <td>
                            <S.ChipLightGreen>PRO</S.ChipLightGreen>
                          </td>
                        );
                      }

                      if (data?.value === "PLUS-USER") {
                        return (
                          <td>
                            <S.ChipLightGreen>PLUS</S.ChipLightGreen>
                          </td>
                        );
                      }

                      return (
                        <td>
                          <S.ChipLightGreen>BASIC</S.ChipLightGreen>
                        </td>
                      );
                    }
                    return <td>{data.value}</td>;
                  })}
                </S.TableRowContainer>
              );
            })}
          </tbody>
        </S.TableContainer>
      </S.TableOverallContainer>
      {/* {!!pagination && (
        <S.TablePaginationDetailsContainer>
          <S.TablePaginationNumbersContainer>
            {renderPageNumbers()}
          </S.TablePaginationNumbersContainer>
        </S.TablePaginationDetailsContainer>
      )} */}
    </>
  );
};

export default Table;
