import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import * as S from "./influencer.styles";

// Components
import Topbar from "components/topbar/Topbar";

// Images
import ProfileImage from "assets/images/reviews/Anna-Seiner.png";
import ArrowLeft from "assets/images/downArrow.svg";

// Redux
import * as AuthActions from "redux/auth/auth.actions";
import { FILE_BASE_URL_S3 } from "configs/services/apiCollections";
import { dashboardNavTabs } from "configs/utils";
import { getS3ImageUrl } from "configs/utils";

const InfluencersDetails = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();

  const [emailBody, setEmailBody] = useState("");
  const isInfluencerDetailsLoading = useSelector(
    (state) => state.auth.isInfluencerDetailsLoading
  );
  const influencerDetails = useSelector(
    (state) => state.auth.influencerDetails
  );
  const trainerProfileData = useSelector((state) => state.auth.trainerData);
  const firstMeetingURLByTrainerID = useSelector(
    (state) => state.auth.trainerData
  );

  console.log(trainerProfileData);

  useEffect(() => {
    dispatch(
      AuthActions.getInfluencerDetailsRequest({
        formData: {
          influencerID: id,
        },
        callback: () => {},
      })
    );

    dispatch(
      AuthActions.getFirstMeetingURLByTrainerIDRequest({
        formData: {
          coachID: trainerProfileData?.trainerID,
        },
        callback: () => {},
      })
    );
  }, []);

  return (
    <>
      <Topbar
        navTabs={dashboardNavTabs}
        redirectionLink
        profileDropdown={{
          name: trainerProfileData.prename + " " + trainerProfileData.surname,
          profilePic: getS3ImageUrl(trainerProfileData.profilePic),
        }}
      />
      <S.DatabaseContainer>
        <S.FirstRow>
          <S.LeftSide>
            <S.BackButton onClick={() => history.push("/influencers")}>
              <img src={ArrowLeft} />
            </S.BackButton>
            <S.InfoBox>
              <S.SmallText>Influencer</S.SmallText>
              <S.Heading>Datenbank</S.Heading>
            </S.InfoBox>
          </S.LeftSide>
          <S.RightSide></S.RightSide>
        </S.FirstRow>

        <S.DetailBox>
          <S.DetailBoxInner>
            <S.DetailBoxLeft>
              <S.DetailBoxTop>
                <span>An</span>
                <S.ProfilBox>
                  <img src={getS3ImageUrl(influencerDetails?.influencersData?.profile_picture)} />
                  <p>{`${influencerDetails?.influencersData?.Vorname} ${influencerDetails?.influencersData?.Nachname}`}</p>
                </S.ProfilBox>
              </S.DetailBoxTop>

              <S.Insights>
                <h3>INSIGHTS:</h3>
                <ul>
                  <li>{influencerDetails?.influencersData?.insights_1}</li>
                  <li>{influencerDetails?.influencersData?.insights_2}</li>
                  <li>{influencerDetails?.influencersData?.insights_3}</li>
                  <li>{influencerDetails?.influencersData?.insights_4}</li>
                  <li>{influencerDetails?.influencersData?.insights_5}</li>
                  <li>{influencerDetails?.influencersData?.insights_6}</li>
                </ul>
              </S.Insights>

              <S.DetailPrice>
                <h3>PREIS FÜR STORY</h3>
                <p>{`${influencerDetails?.influencersData?.price} €`}</p>
              </S.DetailPrice>
            </S.DetailBoxLeft>

            <S.DetailBoxRight>
              <S.InputWrap>
                <label>ERSTGESPRÄCH URL</label>
                <input
                  type="text"
                  value={firstMeetingURLByTrainerID?.first_meeting_url}
                  placeholder="www.coach.goodiary.app/coach-name"
                />
              </S.InputWrap>
              <S.InputWrap>
                <label>INFORMATIONEN</label>
                <textarea
                  type="text"
                  placeholder="Wichtige Informationen, die du mit deinem Influencer teilen möchtest"
                  value={emailBody}
                  onChange={(e) => setEmailBody(e.target.value)}
                ></textarea>
              </S.InputWrap>
            </S.DetailBoxRight>
          </S.DetailBoxInner>

          <S.ButtonBox>
            <a
              href={`mailto:${influencerDetails?.influencersData?.influencer_email}?body=${emailBody}`}
            >
              Jetzt buchen
            </a>
          </S.ButtonBox>
        </S.DetailBox>
      </S.DatabaseContainer>
    </>
  );
};

export default InfluencersDetails;
