import styled from "styled-components";

export const DashboardContainer = styled.div`
    width: 950px;
    margin: 100px auto auto auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media (max-width: 991.99px) {
        width: 100%;
    }

    @media (max-width: 600px) {
        margin-top: 40px;
    }
`;

export const TrainerProfileSidebarContainer = styled.div`
    width: 200px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

export const TrainerProfileImage = styled.div`
    width: 200px;
    height: 250px;
    background-color: white;
    background-image: ${props => props.bgImage ? 'url("' + props.bgImage + '")' : "none"};
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: 10px;
    position: relative;
`;

export const TrainerUploadImage = styled.input.attrs({ type: 'file'})`
    position: absolute;
    bottom: 15px;
    left: 20px;
    width: 162px;
`;

export const FromGymContainer = styled.div`
    width: 200px;
`;

export const FromGymHeading = styled.div`
    font-size: 16px;
    font-family: RubikRegular;
    margin: 35px 0 15px 0;
`;

export const FromGymLogoContainer = styled.div`
    width: 200;
    height: 175px;
    background-color: white;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const FromGymLogo = styled.img``;