import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { Formik } from "formik";
import { object, string } from "yup";

import Input from "components/formComponents/input/Input";
import InnerSideNav from "pages/NewDasboard/components/InnerSideNav/InnerSide";

import CopyIcon from "assets/images/new-copy.png";
import * as DashboardActions from "redux/dashboard/dashboard.actions";
import * as S from "./settings.styles";
import * as GlobalStyles from "components/global/Sections.styles"

const MENUS = [
  {
    id: 1,
    title: "E-Mail Account",
    path: "/dashboard/settings/email-account",
  },
  {
    id: 2,
    title: "Temporäre Zugangsdaten",
    path: "/dashboard/settings/access-data",
  },
  {
    id: 3,
    title: "Zahlungsanbieter aktivieren",
    path: "/dashboard/settings/payment-provider",
  },
];

const TemporaryAccessData = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const trainerProfileTempDetails = useSelector(
    (state) => state.dashboard.trainerProfileTempDetails
  );

  const trainerData = useSelector((state) => state.auth.trainerData);

  useEffect(() => {
    dispatch(
      DashboardActions.getTrainerProfileTempDetailsRequest({
        payload: { trainerID: trainerData?.trainerID },
        callback: () => null,
      })
    );
  }, []);

  const initialValues = {
    foodiaryCalendly: trainerProfileTempDetails?.data?.foodiaryCalendly,
    foodiaryCalendlyTempPwd:
      trainerProfileTempDetails?.data?.foodiaryCalendlyTempPwd,
    foodiaryEmail: trainerProfileTempDetails?.data?.foodiaryEmail,
    foodiaryEmailTempPwd: trainerProfileTempDetails?.data?.foodiaryEmailTempPwd,
  };

  const validationSchema = object().shape({
    foodiaryCalendly: string(),
    foodiaryCalendlyTempPwd: string(),
    foodiaryEmail: string(),
    foodiaryEmailTempPwd: string(),
  });

  const onFormSubmit = (values, { resetForm }) => {};

  const copyToClipBoard = (text) => {
    navigator.clipboard.writeText(text);
  };

  return (
    <>
      <S.Container>
        <div className="second-sidebar-wrapper">
          <InnerSideNav menus={MENUS} hideUserInfo></InnerSideNav>
        </div>
        <S.Wrapper className="second-sidebar-content">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onFormSubmit}
            enableReinitialize
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              touched,
              errors,
              isValid,
              dirty,
            }) => (
              <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                <S.TrainerProfileDetailsInnerContainer>
                  <GlobalStyles.SectionHeadline>
                    Temporäre Zugangsdaten
                  </GlobalStyles.SectionHeadline>
                  <GlobalStyles.SubHeadline>
                    Mit diesen Temporären Zugangsdaten kannst du dein Terminkalender und deine Foodiary E-Mail direkt aktivieren.
                  </GlobalStyles.SubHeadline>

                  <br />
                  <S.Divider />
                  <br />

                  <S.InlineControls>
                    <S.FormControl>
                      <S.TrainerProfileDetailsSection1InputLabel className="info-label">
                        CALENDLY E-MAIL
                      </S.TrainerProfileDetailsSection1InputLabel>

                      <Input
                        className="input-custom copy-text"
                        filled
                        style={{ width: "100%", marginBottom: "10px" }}
                        placeholder=""
                        disabled
                        name="foodiaryCalendly"
                        onChange={handleChange}
                        value={values.foodiaryCalendly}
                      />
                      <img
                        src={CopyIcon}
                        alt="CopyIcon"
                        onClick={() => copyToClipBoard(values.foodiaryCalendly)}
                      />
                    </S.FormControl>
                    <S.FormControl>
                      <S.TrainerProfileDetailsSection1InputLabel className="info-label">
                        TEMPORÄRES PASSWORT CALENDLY
                      </S.TrainerProfileDetailsSection1InputLabel>

                      <Input
                        className="input-custom copy-text"
                        filled
                        style={{ width: "100%", marginBottom: "10px" }}
                        placeholder=""
                        disabled
                        name="foodiaryCalendlyTempPwd"
                        onChange={handleChange}
                        value={values.foodiaryCalendlyTempPwd}
                      />
                      <img
                        src={CopyIcon}
                        alt="CopyIcon"
                        onClick={() =>
                          copyToClipBoard(values.foodiaryCalendlyTempPwd)
                        }
                      />
                    </S.FormControl>
                    <S.FormControl>
                      <S.TrainerProfileDetailsSection1InputLabel className="info-label">
                        DEINE FOODIARY E-MAIL
                      </S.TrainerProfileDetailsSection1InputLabel>

                      <Input
                        className="input-custom copy-text"
                        filled
                        style={{ width: "100%", marginBottom: "10px" }}
                        placeholder=""
                        disabled
                        name="foodiaryEmail"
                        onChange={handleChange}
                        value={values.foodiaryEmail}
                      />
                      <img
                        src={CopyIcon}
                        alt="CopyIcon"
                        onClick={() => copyToClipBoard(values.foodiaryEmail)}
                      />
                    </S.FormControl>
                    <S.FormControl>
                      <S.TrainerProfileDetailsSection1InputLabel className="info-label">
                        TEMPORÄRES PASSWORT E-MAIL
                      </S.TrainerProfileDetailsSection1InputLabel>

                      <Input
                        className="input-custom copy-text"
                        filled
                        style={{ width: "100%", marginBottom: "10px" }}
                        placeholder=""
                        disabled
                        name="foodiaryEmailTempPwd"
                        onChange={handleChange}
                        value={values.foodiaryEmailTempPwd}
                      />
                      <img
                        src={CopyIcon}
                        alt="CopyIcon"
                        onClick={() =>
                          copyToClipBoard(values.foodiaryEmailTempPwd)
                        }
                      />
                    </S.FormControl>
                  </S.InlineControls>
                  {/* 
                  <div>
                    <Button
                      color="#FFFFFF"
                      gradient="linear-gradient(99deg, #2ECF96 0%, #1C9D87 100%) !important"
                      margin="1rem 0 0 0"
                      absolute={{
                        width: "auto",
                        justifyContent: "start",
                      }}
                      type="submit"
                    >
                      Änderungen speichern
                    </Button>
                  </div> */}
                </S.TrainerProfileDetailsInnerContainer>
              </form>
            )}
          </Formik>
        </S.Wrapper>
      </S.Container>
    </>
  );
};

export default TemporaryAccessData;
