import React, {useState} from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import * as S from './healthScoreEvaluation.styles';
import {getPercent, getRandomValueInRange} from "configs/utils";

// Components
import TranslationMessage from 'configs/translations/TranslationMessage';
import Topbar from 'components/topbar/Topbar';
import DonutProgress from 'components/donutProgress/DonutProgress';
import LineProgress from 'components/lineProgress/LineProgress';
import LinearSlider from 'components/linearSlider/LinearSlider';

// Redux
import * as AuthActions from "redux/auth/auth.actions";

function HealthScoreEvaluation() {
    const dispatch = useDispatch();
    const history = useHistory();
    const healthScoreData = useSelector(state => state.auth.healthScoreData);
    const [currentPosition, setCurrentPosition] = useState(healthScoreData.sliderPosition);
    const sliderPosition = (currentPosition === null || currentPosition === undefined) ? 0 : currentPosition;
    
    const evaluationData = {sliderPosition: sliderPosition, healthScoreTarget: 60}

    const handleNext = () => {
        dispatch(AuthActions.setHealthScoreData(evaluationData))
        history.push('/healthscore/tips')
    }
    const randomValue = getRandomValueInRange(40, 45);

    return (
        <>
        {/* healthReport */}
            <Topbar prev="/healthscore/priority" next="/healthscore/tips" targetHealthScore={getPercent(sliderPosition)} onNext={handleNext} progress={'27%'} onClose={() => history.push('/login')} targetBudget={sliderPosition} percent={healthScoreData.currentHealthScore} close />
            <S.HealthScoreEvaluationFormContainer>
                <S.HealthScoreEvaluationFormHeading><TranslationMessage id="healthScore.evaluation.heading" /></S.HealthScoreEvaluationFormHeading>
                <S.HealthScoreEvaluationFormSubHeading><TranslationMessage id="healthScore.evaluation.subHeading" /></S.HealthScoreEvaluationFormSubHeading>
                <S.HealthScoreEvaluationInnerContainer>
                    <S.HealthScoreDonutChartContainer>
                        <DonutProgress percent={currentPosition !== null ? getPercent(currentPosition) : randomValue} width="183px" gradient style={{marginTop: "12px"}}/>
                    </S.HealthScoreDonutChartContainer>
                    <S.HealthScoreLineChartContainer>
                        <S.HealthScoreLineChartLabel><TranslationMessage id="label.minerals" /></S.HealthScoreLineChartLabel>
                        <LineProgress percent={getPercent(currentPosition)*2} />
                        <S.HealthScoreLineChartLabel><TranslationMessage id="label.vitamins" /></S.HealthScoreLineChartLabel>
                        <LineProgress percent={getPercent(currentPosition)*2} />
                        <S.HealthScoreLineChartLabel><TranslationMessage id="label.fats" /></S.HealthScoreLineChartLabel>
                        <LineProgress percent={getPercent(currentPosition)*2} />
                    </S.HealthScoreLineChartContainer>
                </S.HealthScoreEvaluationInnerContainer>
                <S.HealthScoreSliderContainer>
                    <S.HealthScoreSliderInnerContainer>
                        <LinearSlider currentPosition={sliderPosition} setCurrentPosition={setCurrentPosition} />
                    </S.HealthScoreSliderInnerContainer>
                </S.HealthScoreSliderContainer>
            </S.HealthScoreEvaluationFormContainer>
        </>
    )
}

export default HealthScoreEvaluation
