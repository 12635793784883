import styled from "styled-components";

export const NavigationTabsContainer = styled.span`
  background: transparent;
  color: white;
  display: flex;
  font-family: RubikRegular;
  font-size: 18px;
`;

export const NavigationTab = styled.span`
  margin-right: 30px;
  position: relative;
  cursor: pointer;
`;

export const NavigationTabActive = styled.span`
    background-color: #142C1F30;
    width: 100%;
    height: 4px;
    border-radius: 3px;
    position: absolute;
    bottom: -13px;
    left: 0;
`;
