import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  setUserRegistrationDataInLocalStorage,
  getUserRegistrationDataFromLocalStorage,
} from "configs/utils";

import Button from "components/formComponents/button/Button";

import FoodiaryLogo from "assets/images/Foodiary-Logo.PNG";
import ArrowLeft from "assets/images/arrowLeft.svg";
import { SidebarSteps, Goals } from "../data";

import * as S from "../OnBoardingPartOne.styles";
import OnboardingSidebar from "../OnboardingSidebar";

const Goal = (props) => {
  const history = useHistory();
  const registrationData = useSelector((state) => state.auth.registrationData);

  const [goal, setGoal] = useState(0);
  const [IsValidation, SetIsValidation] = useState(false);

  useEffect(() => {
    // onSelectGoal(registrationData.aimID);

    const goalId = getUserRegistrationDataFromLocalStorage()?.goal;

    if (goalId) {
      setGoal(goalId);
    } else {
      setGoal(0);
    }
  }, []);

  const onSelectGoal = (goalId) => {
    setGoal(goalId);
    SetIsValidation(false);
    setUserRegistrationDataInLocalStorage({
      ...getUserRegistrationDataFromLocalStorage(),
      goal: goalId,
    });
  };

  const handleNext = () => {
    if (goal <= 0) {
      SetIsValidation(true);
      return;
    }

    history.push("/onboarding/registration/anamnese_evaluation");
  };

  const handleBack = () => {
    history.push("/onboarding/registration/gender");
  };

  return (
    <>
      <S.OnBoardingPage className="starter">
        <S.OnBoardingContainer className="onboarding-container">
          <OnboardingSidebar step={2} />
          <S.OnBoardingContent className="onboarding-content">
            <S.OnBoardingHeader className="onboarding-header">
              <div className="d-flex align-items-top gap-3">
                <img
                  className="cursor-pointer"
                  src={ArrowLeft}
                  alt="back"
                  onClick={handleBack}
                />
                <div className="content">
                  <span className="small-title text-green font-rubik-medium">
                    Anamnese
                  </span>
                  <h6 className="header-title font-rubik-medium my-0">
                    Wir erstellen deinen Plan.
                  </h6>
                </div>
              </div>
              <div className="actions">
                <Button
                  color="rgba(248, 164, 146, 1)"
                  gradient="linear-gradient(60deg, rgba(46, 207, 150, 1), rgba(28, 157, 135, 1)) !important"
                  margin="0"
                  absolute={{ width: "fit-content", height: "2.5rem" }}
                  buttonStyle={{ padding: ".5625rem 2.5rem" }}
                  onClick={handleNext}
                >
                  Weiter
                </Button>
              </div>
            </S.OnBoardingHeader>
            <S.OnBoardingBody className="onboarding-body">
              <div className="gender-content ">
                <h3 className="text-size-20-28 text-dark-1 font-rubik-medium">
                  Welches Ziel möchtest du in den <br /> kommenden 12 Wochen
                  verfolgen ?
                </h3>
                <p className="text-size-16-19 text-light-grey-1 font-rubik-regular">
                  Das Ziel hat einen Einfluss auf deinen Kalorienbedarf.
                </p>
                <p className="text-size-14-28 text-dark-1 font-rubik-medium">
                  Wähle dein Ziel
                </p>
                {IsValidation && (
                  <p className="text-size-14-18 text-right text-red font-rubik-regular mt-0">
                    Bitte wähle das Ziel
                  </p>
                )}
                <div className="gender-items">
                  {Goals.map((item, i) => (
                    <div
                      className="gender-item cursor-pointer"
                      onClick={() => onSelectGoal(item?.id)}
                      key={i}
                    >
                      <img className="goal-img" src={item.img} alt="" />
                      <div className="headings">
                        <p className="text-size-18-22 text-dark-1 font-rubik-medium my-0">
                          {item.title}
                        </p>
                        <p className="text-size-14-20 text-dark-2 font-rubik-regular mt-1 mb-0">
                          {item.description}
                        </p>
                      </div>
                      <div className="form-group-c">
                        <input
                          type="checkbox"
                          id={item?.id}
                          checked={goal === item?.id}
                          onChange={() => null}
                        />
                        <label htmlFor={item?.id}></label>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </S.OnBoardingBody>
          </S.OnBoardingContent>
        </S.OnBoardingContainer>
      </S.OnBoardingPage>
    </>
  );
};

export default Goal;
