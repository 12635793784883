import React, { useState } from "react";
import { useSelector } from "react-redux";
import TextArea from "components/formComponents/textArea/TextArea";
import Calendar from "react-calendar";

import * as S from "./clientDetails.styles";
import "./clientDetails.scss";

const DiaryAndHabbitTracker = () => {
  const [SelectedDate, SetSelectedDate] = useState(formatDate(new Date()));

  const isHabbitLoading = useSelector(
    (state) => state.dashboard.isHabbitLoading
  );
  const isJournalLoading = useSelector(
    (state) => state.dashboard.isJournalLoading
  );
  const habbitTracker = useSelector((state) => state.dashboard.habbit);
  const journalTracker = useSelector((state) => state.dashboard.journal);

  const mark = [
    ...new Set([
      ...Object.keys(habbitTracker?.data),
      ...Object.keys(journalTracker?.data),
    ]),
  ];

  const onDateChange = (date) => {
    SetSelectedDate(formatDate(date));
  };

  function formatDate(date) {
    var d = date,
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  return (
    <>
      <S.ClientDetailsContentContainer>
        <S.AnamneseHeading>Tagebuch & Habbit Tracker</S.AnamneseHeading>

        <S.DiaryWrap>
          <S.CalanderWrap>
            <Calendar
              onChange={onDateChange}
              value={SelectedDate}
              tileClassName={({ date, view }) => {
                if (mark.find((x) => x === formatDate(date))) {
                  return "highlight-c";
                }
              }}
            />
          </S.CalanderWrap>

          <S.FeedbackWrap>
            <S.FeedbackWrapInner>
              <h5>DETAILS</h5>

              <S.FeedbackSmily>
                <S.SmilyRow>
                  <p>Wie fühlst du dich?</p>
                  <S.SmileFace>
                    <svg
                      fill={
                        journalTracker?.data[SelectedDate]
                          ?.how_feel_yourself === "happy"
                          ? "#2ecf96"
                          : "#000000"
                      }
                      width="64px"
                      height="64px"
                      viewBox="0 0 256 256"
                      id="Flat"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                      <g
                        id="SVGRepo_tracerCarrier"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></g>
                      <g id="SVGRepo_iconCarrier">
                        {" "}
                        <path d="M128,28A100,100,0,1,0,228,128,100.11332,100.11332,0,0,0,128,28Zm0,192a92,92,0,1,1,92-92A92.10416,92.10416,0,0,1,128,220ZM84,108a8,8,0,1,1,8,8A7.99977,7.99977,0,0,1,84,108Zm72,0a8,8,0,1,1,8,8A7.99977,7.99977,0,0,1,156,108Zm17.04248,46a52.01569,52.01569,0,0,1-90.08447.00049,3.99987,3.99987,0,1,1,6.9248-4.00537,44.01578,44.01578,0,0,0,76.23438-.00049A4.00008,4.00008,0,1,1,173.04248,154Z"></path>{" "}
                      </g>
                    </svg>
                    <svg
                      fill={
                        journalTracker?.data[SelectedDate]
                          ?.how_feel_yourself === "neutral"
                          ? "#2ecf96"
                          : "#000000"
                      }
                      width="64px"
                      height="64px"
                      viewBox="0 0 256 256"
                      id="Flat"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                      <g
                        id="SVGRepo_tracerCarrier"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></g>
                      <g id="SVGRepo_iconCarrier">
                        {" "}
                        <path d="M128,28A100,100,0,1,0,228,128,100.11332,100.11332,0,0,0,128,28Zm0,192a92,92,0,1,1,92-92A92.10416,92.10416,0,0,1,128,220Zm44-60a4.0002,4.0002,0,0,1-4,4H88a4,4,0,0,1,0-8h80A4.0002,4.0002,0,0,1,172,160ZM84,108a8,8,0,1,1,8,8A8.00917,8.00917,0,0,1,84,108Zm88,0a8,8,0,1,1-8-8A8.00917,8.00917,0,0,1,172,108Z"></path>{" "}
                      </g>
                    </svg>
                    <svg
                      fill={
                        journalTracker?.data[SelectedDate]
                          ?.how_feel_yourself === "sad"
                          ? "#2ecf96"
                          : "#000000"
                      }
                      width="64px"
                      height="64px"
                      viewBox="0 0 256 256"
                      id="Flat"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                      <g
                        id="SVGRepo_tracerCarrier"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></g>
                      <g id="SVGRepo_iconCarrier">
                        {" "}
                        <path d="M128,28A100,100,0,1,0,228,128,100.11332,100.11332,0,0,0,128,28Zm0,192a92,92,0,1,1,92-92A92.10416,92.10416,0,0,1,128,220ZM84,108a8,8,0,1,1,8,8A7.99977,7.99977,0,0,1,84,108Zm72,0a8,8,0,1,1,8,8A7.99977,7.99977,0,0,1,156,108Zm17.04248,65.99707a4.0002,4.0002,0,1,1-6.92529,4.00586,44.01554,44.01554,0,0,0-76.23438-.001,3.99975,3.99975,0,1,1-6.9248-4.00488,52.01544,52.01544,0,0,1,90.08447,0Z"></path>{" "}
                      </g>
                    </svg>
                  </S.SmileFace>
                </S.SmilyRow>

                <S.SmilyRow>
                  <p>Wie war dein Schlaf?</p>
                  <S.SmileFace>
                    <svg
                      fill={
                        journalTracker?.data[SelectedDate]
                          ?.how_was_your_sleep === "happy"
                          ? "#2ecf96"
                          : "#000000"
                      }
                      width="64px"
                      height="64px"
                      viewBox="0 0 256 256"
                      id="Flat"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                      <g
                        id="SVGRepo_tracerCarrier"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></g>
                      <g id="SVGRepo_iconCarrier">
                        {" "}
                        <path d="M128,28A100,100,0,1,0,228,128,100.11332,100.11332,0,0,0,128,28Zm0,192a92,92,0,1,1,92-92A92.10416,92.10416,0,0,1,128,220ZM84,108a8,8,0,1,1,8,8A7.99977,7.99977,0,0,1,84,108Zm72,0a8,8,0,1,1,8,8A7.99977,7.99977,0,0,1,156,108Zm17.04248,46a52.01569,52.01569,0,0,1-90.08447.00049,3.99987,3.99987,0,1,1,6.9248-4.00537,44.01578,44.01578,0,0,0,76.23438-.00049A4.00008,4.00008,0,1,1,173.04248,154Z"></path>{" "}
                      </g>
                    </svg>
                    <svg
                      fill={
                        journalTracker?.data[SelectedDate]
                          ?.how_was_your_sleep === "neutral"
                          ? "#2ecf96"
                          : "#000000"
                      }
                      width="64px"
                      height="64px"
                      viewBox="0 0 256 256"
                      id="Flat"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                      <g
                        id="SVGRepo_tracerCarrier"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></g>
                      <g id="SVGRepo_iconCarrier">
                        {" "}
                        <path d="M128,28A100,100,0,1,0,228,128,100.11332,100.11332,0,0,0,128,28Zm0,192a92,92,0,1,1,92-92A92.10416,92.10416,0,0,1,128,220Zm44-60a4.0002,4.0002,0,0,1-4,4H88a4,4,0,0,1,0-8h80A4.0002,4.0002,0,0,1,172,160ZM84,108a8,8,0,1,1,8,8A8.00917,8.00917,0,0,1,84,108Zm88,0a8,8,0,1,1-8-8A8.00917,8.00917,0,0,1,172,108Z"></path>{" "}
                      </g>
                    </svg>
                    <svg
                      fill={
                        journalTracker?.data[SelectedDate]
                          ?.how_was_your_sleep === "sad"
                          ? "#2ecf96"
                          : "#000000"
                      }
                      width="64px"
                      height="64px"
                      viewBox="0 0 256 256"
                      id="Flat"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                      <g
                        id="SVGRepo_tracerCarrier"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></g>
                      <g id="SVGRepo_iconCarrier">
                        {" "}
                        <path d="M128,28A100,100,0,1,0,228,128,100.11332,100.11332,0,0,0,128,28Zm0,192a92,92,0,1,1,92-92A92.10416,92.10416,0,0,1,128,220ZM84,108a8,8,0,1,1,8,8A7.99977,7.99977,0,0,1,84,108Zm72,0a8,8,0,1,1,8,8A7.99977,7.99977,0,0,1,156,108Zm17.04248,65.99707a4.0002,4.0002,0,1,1-6.92529,4.00586,44.01554,44.01554,0,0,0-76.23438-.001,3.99975,3.99975,0,1,1-6.9248-4.00488,52.01544,52.01544,0,0,1,90.08447,0Z"></path>{" "}
                      </g>
                    </svg>
                  </S.SmileFace>
                </S.SmilyRow>

                <S.SmilyRow>
                  <p>Was könnte besser sein?</p>
                  <TextArea
                    filled
                    disabled
                    value={
                      journalTracker?.data[SelectedDate]?.what_could_better ||
                      ""
                    }
                    name="whatCouldBetter"
                    placeholder="Beschreibung"
                    style={{
                      margin: "0 0 0",
                      border: "#F8F8F8",
                      background: "#F8F8F8",
                      height: "78px",
                    }}
                  />
                </S.SmilyRow>

                <S.SmilyRow>
                  <p>Notiz an deinen Coach</p>
                  <TextArea
                    filled
                    disabled
                    value={
                      journalTracker?.data[SelectedDate]?.note_your_coach || ""
                    }
                    name="noteYourCoach"
                    placeholder="Beschreibung"
                    style={{
                      margin: "0 0 0",
                      border: "#F8F8F8",
                      background: "#F8F8F8",
                      height: "78px",
                    }}
                  />
                </S.SmilyRow>

                <S.RadioRow>
                  <S.SmilyRow>
                    <S.SelfMadeCheckBoxRow>
                      <label className="form-control">
                        <input
                          type="checkbox"
                          name="checkbox"
                          checked={
                            habbitTracker?.data[SelectedDate]?.meals_planned
                          }
                        />
                      </label>
                      <span>Mahlzeiten vorgeplant</span>
                    </S.SelfMadeCheckBoxRow>
                  </S.SmilyRow>
                  <S.SmilyRow>
                    <S.SelfMadeCheckBoxRow>
                      <label className="form-control">
                        <input
                          type="checkbox"
                          name="checkbox"
                          checked={
                            habbitTracker?.data[SelectedDate]
                              ?.calories_recommendation_follwed
                          }
                        />
                      </label>
                      <span>Tagesempfehlung eingehalten</span>
                    </S.SelfMadeCheckBoxRow>
                  </S.SmilyRow>
                  <S.SmilyRow>
                    <S.SelfMadeCheckBoxRow>
                      <label className="form-control">
                        <input
                          type="checkbox"
                          name="checkbox"
                          checked={
                            habbitTracker?.data[SelectedDate]
                              ?.water_recommendation
                          }
                        />
                      </label>
                      <span>2-3 Liter Wasser getrunken</span>
                    </S.SelfMadeCheckBoxRow>
                  </S.SmilyRow>
                </S.RadioRow>
              </S.FeedbackSmily>
            </S.FeedbackWrapInner>
          </S.FeedbackWrap>
        </S.DiaryWrap>
      </S.ClientDetailsContentContainer>
    </>
  );
};

export default DiaryAndHabbitTracker;
