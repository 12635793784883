import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import * as S from "./CourseChangePassword.styles";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Slider from "react-slick";
// Components
import Card from "components/card/Card";
import Input from "components/formComponents/input/Input";
import Button from "components/formComponents/button/Button";
import Loader from "components/loader/Loader";
import ErrorMessage from "components/formComponents/errorMessage/ErrorMessage";
import FoodiaryLogo from "assets/images/Foodiary-Logo.PNG";
import FoodiaryBackground from "assets/images/Background-foodiary-password-user.jpg";
import DashboardOne from "assets/images/Foodiary-set-password-slide1.svg";
import DashboardTwo from "assets/images/Foodiary-set-password-slide2.svg";
import DashboardThree from "assets/images/Foodiary-set-password-slide3.svg";
import ExclamationCircle from "assets/images/exclamation-circle.svg";
import Hand from "assets/images/YoHand.svg";
// Redux
import * as NutritionActions from "redux/nutrition/nutrition.actions";

const CourseChangePassword = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [IsFormSubmitSuccessfuly, setIsFormSubmitSuccessfuly] = useState(false);
  const [IsFormSubmitError, setIsFormSubmitError] = useState(false);

  const isCourseChangePassword = useSelector(
    (state) => state.nutrition.isCourseChangePassword
  );
  const courseChangePassword = useSelector(
    (state) => state.nutrition.courseChangePassword
  );

  useEffect(() => {
    document.title = "Passwort setzen";

    if (courseChangePassword && courseChangePassword?.success === false) {
      setIsFormSubmitError(true);
      const t = setTimeout(() => {
        setIsFormSubmitError(false);
        dispatch(NutritionActions.courseChangePasswordNull());
        clearTimeout(t);
      }, 2000);
    }

    if (!!courseChangePassword?.success) {
      setIsFormSubmitSuccessfuly(true);

      const t = setTimeout(() => {
        setIsFormSubmitSuccessfuly(false);
        dispatch(NutritionActions.courseChangePasswordNull());
        window.location.assign("https://my.foodiary.app/");
        clearTimeout(t);
      }, 2000);
    }
  }, [courseChangePassword]);

  const initialValues = {
    email: "",
    password: "",
    confirmPassword: "",
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("E-Mail muss bei uns registriert sein")
      .required("Erforderlich"),
    password: Yup.string()
      .required("Erforderlich")
      .min(8, "Passwort muss mindestens 8 Zeichen lang sein"),
    confirmPassword: Yup.string()
      .required("Erforderlich")
      .oneOf([Yup.ref("password"), null], "Passwort muss übereinstimmen"),
  });

  const onFormSubmit = (values, { resetForm }) => {
    console.log("CourseChangePassword ", values);
    dispatch(
      NutritionActions.courseChangePasswordRequest({
        formValues: {
          email: values.email,
          password: values.password,
          confirm_password: values.confirmPassword,
        },
        func: (res) => {},
      })
    );
    resetForm();
  };

  let settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };

  return (
    <>
      <S.LoginContainer>
        <Card
          width="100%"
          maxWidth="530px"
          height="auto"
          margin="auto"
          textAlign="center"
        >
          <S.ChangePasswordInnerContainer className="relative">
            <img width={153} src={FoodiaryLogo} alt="" />
            <S.ChangePasswordHeading>
              Passwort zurücksetzen.
            </S.ChangePasswordHeading>
            <S.ChangePasswordSubHeading>
              Gebe deine E-Mail Adresse ein.
            </S.ChangePasswordSubHeading>
            {courseChangePassword?.success && IsFormSubmitSuccessfuly && (
              <S.AlertSuccess>Dein Passwort wurde geändert</S.AlertSuccess>
            )}
            {courseChangePassword?.success === false && IsFormSubmitError && (
              <S.AlertDanger>
                Dein Passwort konnte nicht geändert werden
              </S.AlertDanger>
            )}
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onFormSubmit}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                errors,
                isValid,
                dirty,
              }) => (
                <form className="relative z-1" noValidate autoComplete="off" onSubmit={handleSubmit}>
                  <div className="form-group">
                    <label>E-Mail Adresse:*</label>
                    <Input
                      className="input-v2"
                      type="text"
                      placeholder="E-Mail Adresse"
                      name="email"
                      onChange={handleChange}
                      value={values.email}
                    />
                    <ErrorMessage error={errors.email} />
                  </div>

                  <div className="form-group">
                    <label>Neues Passwort eingeben*</label>
                    <Input
                      className="input-v2"
                      type="password"
                      placeholder="neues Passwort"
                      name="password"
                      onChange={handleChange}
                      value={values.password}
                    />
                    <ErrorMessage error={errors.password} />
                  </div>
                  <div className="form-group">
                    <label>Neues Passwort bestätigen*</label>
                    <Input
                      className="input-v2"
                      type="password"
                      placeholder="neues Passwort"
                      name="confirmPassword"
                      onChange={handleChange}
                      value={values.confirmPassword}
                    />
                    <ErrorMessage error={errors.confirmPassword} />
                    <p className="input-note">
                      <img src={ExclamationCircle} alt="" /> Gebe die E-Mail
                      ein, mit der du dich registriert hast.
                    </p>
                  </div>

                  <Button
                    color="rgba(248, 164, 146, 1)"
                    gradient="linear-gradient(60deg, rgba(46, 207, 150, 1), rgba(28, 157, 135, 1)) !important"
                    style={{ fontSize: "16px" }}
                    margin="40px 0 20px"
                    absolute={{ width: "100%", height: "45px" }}
                    buttonStyle={{ width: "100%" }}
                  >
                    Passwort zurücksetzen
                  </Button>
                  <Loader loading={isCourseChangePassword} top="-135px" />

                  <div className="route-links">
                    <span>Du hast keinen Zugang mehr?</span>
                    <a href="mailto:support@foodiary.app">
                      Kontaktiere support@foodiary.app
                    </a>
                  </div>
                </form>
              )}
            </Formik>
            <img className="hand" src={Hand} alt="" />
          </S.ChangePasswordInnerContainer>
        </Card>

        {/* <S.SliderContainer imgUrl={FoodiaryBackground}>
          <Slider {...settings}>
            <div className="slide-item">
              <img src={DashboardOne} alt="" />
              <h4>Lernacademy</h4>
              <p>
                In unserer Lernacademy findest du unseren 8-Wochen
                Ernährungskurs und weiter hilfreiche Artikel
              </p>
            </div>
            <div className="slide-item">
              <img src={DashboardTwo} alt="" />
              <h4>Dashboard</h4>
              <p>
                Das Dashboard zeigt dir eine strucktuierte Übersicht deines
                Lernerfolges an. Zudem ist es mit der Ernährungsplaner-App
                verknüpft.
              </p>
            </div>
            <div className="slide-item">
              <img src={DashboardThree} alt="" />
              <h4>E-Books</h4>
              <p>
                In unseren E-Books gehen wir im Detail auf die unterschiedlichen
                Bereiche der gesunden Ernährung ein und du eignest dir ein
                tiefes Wissen an.
              </p>
            </div>
          </Slider>
        </S.SliderContainer> */}
      </S.LoginContainer>
    </>
  );
};

export default CourseChangePassword;
