import NutritionActionTypes from "./nutrition.types";

/*************************************************/

const INITIAL_STATE = {
  userData: null,
  nutritionTypes: null,
  activeNutritionType: null,
  nutritionAdvantages: null,
  nutritionRecipes: null,
  caloriesLineChartData: null,
  allCaloriesData: null,
  macroNutrientsDonutChartData: null,
  nutritionTypePercentageData: null,
  isCoachPasswordReseting: false,
  resetCoachPassword: null,
  isUserPasswordSeting: false,
  setUserPassword: null,
  isCourseChangePassword: false,
  courseChangePassword: null,
  faqQuestions: null,
  faqQuestionsById: null,
  isFaqQuestionsLoading: false,
  nutritionCourseDetails: null,
  isNutritionCourseDetailsLoading: false,
  checkUserIdEmail: null,
  isCheckUserIdEmailLoading: false,
  updateNutritionCourseDetails: null,
  isNutritionCourseDetailsUpdating: false,

  updateOrDeleteNutritionCourseDetails: null,
  isNutritionCourseDetailsUpdatingOrDeleting: false,

  marketingPortalAssets: null,
  isMarketingPortalAssetsLoading: false,
  marketingPortalPrints: null,
  isMarketingPortalPrintsLoading: false,
  marketingPortalPartners: null,
  isMarketingPortalPartnersLoading: false,
  marketingPortalPartnerDetails: null,
  isMarketingPortalPartnerDetailsLoading: false,
  marketingPortalPartnerAndPrintDetails: null,
  isMarketingPortalPartnerAndPrintDetailsLoading: false,
  marketingPortalCreateAffiliate: null,
  isMarketingPortalCreateAffiliateLoading: false,
  marketingPortalAddAffiliateProgram: null,
  isMarketingPortalAddAffiliateProgramLoading: false,
  createQrCode: null,
  isQrCodeLoading: false,
  createPartner: null,
  isCreatePartnerLoading: false,
  marketingPortalCoachDetails: null,
  isMarketingPortalCoachDetailsLoading: false,
  marketingPortalPrintPic: null,
  isMarketingPortalPrintPicLoading: false,
  marketingPortalRawUrlByPrintId: null,
  isMarketingPortalRawUrlByPrintIdLoading: false,
  marketingPortalPrintPlacementDetails: null,
  isMarketingPortalPrintPlacementDetailsLoading: false,
  marketingPortalCreatePrint: null,
  isMarketingPortalPrintCreating: false,
  marketingPortalCreatePartnerPrint: null,
  isMarketingPortalCreatePartnerPrintLoading: false,
  getAllRefunds: null,
  isAllRefundsLoading: false,

  macroNutrientsPieChartData: null,
  isMacroNutrientsPieChartDataLoading: false,
};

/*************************************************/

const nutritionReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case NutritionActionTypes.GET_USER_DATA_SUCCESS:
      return {
        ...state,
        userData: action.payload[0],
      };
    case NutritionActionTypes.GET_ALL_NUTRITION_TYPES_SUCCESS:
      return {
        ...state,
        nutritionTypes: action.payload,
      };
    case NutritionActionTypes.GET_NUTRITION_TYPE_SUCCESS:
      return {
        ...state,
        activeNutritionType: action.payload,
      };
    case NutritionActionTypes.GET_NUTRITION_ADVANTAGES_SUCCESS:
      return {
        ...state,
        nutritionAdvantages: action.payload,
      };
    case NutritionActionTypes.GET_NUTRITION_RECIPES_SUCCESS:
      return {
        ...state,
        nutritionRecipes: action.payload,
      };
    case NutritionActionTypes.GET_WEEK_WISE_CALORIES_SUCCESS:
      return {
        ...state,
        caloriesLineChartData: action.payload,
      };
    case NutritionActionTypes.GET_ALL_CALORIES_SUCCESS:
    case NutritionActionTypes.UPDATE_NUTRITION_CALORIES_SUCCESS:
      return {
        ...state,
        allCaloriesData: action.payload,
      };
    case NutritionActionTypes.GET_MACRO_NUTRIENTS_DONUT_SUCCESS:
      return {
        ...state,
        macroNutrientsDonutChartData: action.payload,
      };
    case NutritionActionTypes.GET_NUTRITION_TYPE_PERCENTAGE_CHART_DATA_SUCCESS:
      return {
        ...state,
        nutritionTypePercentageData:
          action.payload?.nutritionTypePercentageData,
      };
    case NutritionActionTypes.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        resetCoachPassword: action.payload,
      };
    case NutritionActionTypes.RESET_PASSWORD_FAILURE:
      return {
        ...state,
        resetCoachPassword: { success: false, message: "" },
      };
    case NutritionActionTypes.RESET_PASSWORD_NULL:
      return {
        ...state,
        resetCoachPassword: null,
      };
    case NutritionActionTypes.RESET_PASSWORD_LOADING_START:
      return {
        ...state,
        isCoachPasswordReseting: true,
      };
    case NutritionActionTypes.RESET_PASSWORD_LOADING_STOP:
      return {
        ...state,
        isCoachPasswordReseting: false,
      };
    case NutritionActionTypes.SET_PASSWORD_SUCCESS:
      return {
        ...state,
        setUserPassword: action.payload,
      };
    case NutritionActionTypes.SET_PASSWORD_FAILURE:
      return {
        ...state,
        setUserPassword: { success: false, message: "" },
      };
    case NutritionActionTypes.SET_PASSWORD_NULL:
      return {
        ...state,
        setUserPassword: null,
      };
    case NutritionActionTypes.SET_PASSWORD_LOADING_START:
      return {
        ...state,
        isUserPasswordSeting: true,
      };
    case NutritionActionTypes.SET_PASSWORD_LOADING_STOP:
      return {
        ...state,
        isUserPasswordSeting: false,
      };
    case NutritionActionTypes.COURSE_CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        courseChangePassword: action.payload,
      };
    case NutritionActionTypes.COURSE_CHANGE_PASSWORD_FAILURE:
      return {
        ...state,
        courseChangePassword: { success: false, message: "" },
      };
    case NutritionActionTypes.COURSE_CHANGE_PASSWORD_NULL:
      return {
        ...state,
        courseChangePassword: null,
      };
    case NutritionActionTypes.COURSE_CHANGE_PASSWORD_LOADING_START:
      return {
        ...state,
        isCourseChangePassword: true,
      };
    case NutritionActionTypes.COURSE_CHANGE_PASSWORD_LOADING_STOP:
      return {
        ...state,
        isCourseChangePassword: false,
      };

    case NutritionActionTypes.GET_FAQ_QUESTIONS_SUCCESS:
      return {
        ...state,
        faqQuestions: action.payload,
      };
    case NutritionActionTypes.GET_FAQ_QUESTIONS_LOADING_START:
      return {
        ...state,
        isFaqQuestionsLoading: true,
      };
    case NutritionActionTypes.GET_FAQ_QUESTIONS_LOADING_STOP:
      return {
        ...state,
        isFaqQuestionsLoading: false,
      };

    case NutritionActionTypes.GET_FAQ_QUESTIONS_BY_ID_SUCCESS:
      return {
        ...state,
        faqQuestionsById: action.payload,
      };
    case NutritionActionTypes.GET_FAQ_QUESTIONS_BY_ID_LOADING_START:
      return {
        ...state,
        isFaqQuestionsLoading: true,
      };
    case NutritionActionTypes.GET_FAQ_QUESTIONS_BY_ID_LOADING_STOP:
      return {
        ...state,
        isFaqQuestionsLoading: false,
      };

    case NutritionActionTypes.GET_NUTRITION_COURSE_DETAILS_BY_ID_SUCCESS:
      return {
        ...state,
        nutritionCourseDetails: action.payload,
      };
    case NutritionActionTypes.GET_NUTRITION_COURSE_DETAILS_BY_ID_LOADING_START:
      return {
        ...state,
        isNutritionCourseDetailsLoading: true,
      };
    case NutritionActionTypes.GET_NUTRITION_COURSE_DETAILS_BY_ID_LOADING_STOP:
      return {
        ...state,
        isNutritionCourseDetailsLoading: false,
      };

    case NutritionActionTypes.CHECK_USERID_EMAIL_SUCCESS:
      return {
        ...state,
        checkUserIdEmail: action.payload,
      };
    case NutritionActionTypes.CHECK_USERID_EMAIL_LOADING_START:
      return {
        ...state,
        isCheckUserIdEmailLoading: true,
      };
    case NutritionActionTypes.CHECK_USERID_EMAIL_LOADING_STOP:
      return {
        ...state,
        isCheckUserIdEmailLoading: false,
      };

    case NutritionActionTypes.UPDATE_NUTRITION_COURSE_DETAILS_SUCCESS:
      return {
        ...state,
        updateNutritionCourseDetails: action.payload,
      };
    case NutritionActionTypes.UPDATE_NUTRITION_COURSE_DETAILS_LOADING_START:
      return {
        ...state,
        isNutritionCourseDetailsUpdating: true,
      };
    case NutritionActionTypes.UPDATE_NUTRITION_COURSE_DETAILS_LOADING_STOP:
      return {
        ...state,
        isNutritionCourseDetailsUpdating: false,
      };

    case NutritionActionTypes.UPDATE_OR_DELETE_NUTRITION_COURSE_DETAILS_SUCCESS:
      return {
        ...state,
        updateOrDeleteNutritionCourseDetails: action.payload,
      };
    case NutritionActionTypes.UPDATE_OR_DELETE_NUTRITION_COURSE_DETAILS_LOADING_START:
      return {
        ...state,
        isNutritionCourseDetailsUpdatingOrDeleting: true,
      };
    case NutritionActionTypes.UPDATE_OR_DELETE_NUTRITION_COURSE_DETAILS_LOADING_STOP:
      return {
        ...state,
        isNutritionCourseDetailsUpdatingOrDeleting: false,
      };

    case NutritionActionTypes.MARKETING_PORTAL_ASSETS_SUCCESS:
      return {
        ...state,
        marketingPortalAssets: action.payload,
      };
    case NutritionActionTypes.MARKETING_PORTAL_ASSETS_LOADING_START:
      return {
        ...state,
        isMarketingPortalAssetsLoading: true,
      };
    case NutritionActionTypes.MARKETING_PORTAL_ASSETS_LOADING_STOP:
      return {
        ...state,
        isMarketingPortalAssetsLoading: false,
      };

    case NutritionActionTypes.MARKETING_PORTAL_PRINTS_SUCCESS:
      return {
        ...state,
        marketingPortalPrints: action.payload,
      };
    case NutritionActionTypes.MARKETING_PORTAL_PRINTS_LOADING_START:
      return {
        ...state,
        isMarketingPortalPrintsLoading: true,
      };
    case NutritionActionTypes.MARKETING_PORTAL_PRINTS_LOADING_STOP:
      return {
        ...state,
        isMarketingPortalPrintsLoading: false,
      };

    case NutritionActionTypes.MARKETING_PORTAL_PRINTS_DETAILS_SUCCESS:
      return {
        ...state,
        marketingPortalPrintsDetails: action.payload,
      };
    case NutritionActionTypes.MARKETING_PORTAL_PRINTS_DETAILS_LOADING_START:
      return {
        ...state,
        isMarketingPortalPrintsDetailsLoading: true,
      };
    case NutritionActionTypes.MARKETING_PORTAL_PRINTS_DETAILS_LOADING_STOP:
      return {
        ...state,
        isMarketingPortalPrintsDetailsLoading: false,
      };

    case NutritionActionTypes.MARKETING_PORTAL_PARTNERS_SUCCESS:
      return {
        ...state,
        marketingPortalPartners: action.payload,
      };
    case NutritionActionTypes.MARKETING_PORTAL_PARTNERS_LOADING_START:
      return {
        ...state,
        isMarketingPortalPartnersLoading: true,
      };
    case NutritionActionTypes.MARKETING_PORTAL_PARTNERS_LOADING_STOP:
      return {
        ...state,
        isMarketingPortalPartnersLoading: false,
      };

    case NutritionActionTypes.MARKETING_PORTAL_PARTNER_AND_PRINT_DETAILS_SUCCESS:
      return {
        ...state,
        marketingPortalPartnerAndPrintDetails: action.payload,
      };
    case NutritionActionTypes.MARKETING_PORTAL_PARTNER_AND_PRINT_DETAILS_LOADING_START:
      return {
        ...state,
        isMarketingPortalPartnerAndPrintDetailsLoading: true,
      };
    case NutritionActionTypes.MARKETING_PORTAL_PARTNER_AND_PRINT_DETAILS_LOADING_STOP:
      return {
        ...state,
        isMarketingPortalPartnerAndPrintDetailsLoading: false,
      };

    case NutritionActionTypes.MARKETING_PORTAL_CREATE_AFFILIATE_SUCCESS:
      return {
        ...state,
        marketingPortalCreateAffiliate: action.payload,
      };
    case NutritionActionTypes.MARKETING_PORTAL_CREATE_AFFILIATE_LOADING_START:
      return {
        ...state,
        isMarketingPortalCreateAffiliateLoading: true,
      };
    case NutritionActionTypes.MARKETING_PORTAL_CREATE_AFFILIATE_LOADING_STOP:
      return {
        ...state,
        isMarketingPortalCreateAffiliateLoading: false,
      };

    case NutritionActionTypes.MARKETING_PORTAL_ADD_AFFILIATE_PROGRAM_SUCCESS:
      return {
        ...state,
        marketingPortalAddAffiliateProgram: action.payload,
      };
    case NutritionActionTypes.MARKETING_PORTAL_ADD_AFFILIATE_PROGRAM_LOADING_START:
      return {
        ...state,
        isMarketingPortalAddAffiliateProgramLoading: true,
      };
    case NutritionActionTypes.MARKETING_PORTAL_ADD_AFFILIATE_PROGRAM_LOADING_STOP:
      return {
        ...state,
        isMarketingPortalAddAffiliateProgramLoading: false,
      };

    case NutritionActionTypes.CREATE_QR_CODE_SUCCESS:
      return {
        ...state,
        createQrCode: action.payload,
      };
    case NutritionActionTypes.CREATE_QR_CODE_LOADING_START:
      return {
        ...state,
        isQrCodeLoading: true,
      };
    case NutritionActionTypes.CREATE_QR_CODE_LOADING_STOP:
      return {
        ...state,
        isQrCodeLoading: false,
      };

    case NutritionActionTypes.MARKETING_PORTAL_CREATE_PARTNER_SUCCESS:
      return {
        ...state,
        createPartner: action.payload,
      };
    case NutritionActionTypes.MARKETING_PORTAL_CREATE_PARTNER_LOADING_START:
      return {
        ...state,
        isCreatePartnerLoading: true,
      };
    case NutritionActionTypes.MARKETING_PORTAL_CREATE_PARTNER_LOADING_STOP:
      return {
        ...state,
        isCreatePartnerLoading: false,
      };

    case NutritionActionTypes.MARKETING_PORTAL_COACH_DETAILS_SUCCESS:
      return {
        ...state,
        marketingPortalCoachDetails: action.payload,
      };
    case NutritionActionTypes.MARKETING_PORTAL_COACH_DETAILS_LOADING_START:
      return {
        ...state,
        isMarketingPortalCoachDetailsLoading: true,
      };
    case NutritionActionTypes.MARKETING_PORTAL_COACH_DETAILS_LOADING_STOP:
      return {
        ...state,
        isMarketingPortalCoachDetailsLoading: false,
      };

    case NutritionActionTypes.MARKETING_PORTAL_PRINT_PIC_SUCCESS:
      return {
        ...state,
        marketingPortalPrintPic: action.payload,
      };
    case NutritionActionTypes.MARKETING_PORTAL_PRINT_PIC_LOADING_START:
      return {
        ...state,
        isMarketingPortalPrintPicLoading: true,
      };
    case NutritionActionTypes.MARKETING_PORTAL_PRINT_PIC_LOADING_STOP:
      return {
        ...state,
        isMarketingPortalPrintPicLoading: false,
      };

    case NutritionActionTypes.MARKETING_PORTAL_RAW_URL_BY_PRINT_ID_SUCCESS:
      return {
        ...state,
        marketingPortalRawUrlByPrintId: action.payload,
      };
    case NutritionActionTypes.MARKETING_PORTAL_RAW_URL_BY_PRINT_ID_LOADING_START:
      return {
        ...state,
        isMarketingPortalRawUrlByPrintIdLoading: true,
      };
    case NutritionActionTypes.MARKETING_PORTAL_RAW_URL_BY_PRINT_ID_LOADING_STOP:
      return {
        ...state,
        isMarketingPortalRawUrlByPrintIdLoading: false,
      };

    case NutritionActionTypes.MARKETING_PORTAL_PRINT_PLACEMENT_DETAILS_SUCCESS:
      return {
        ...state,
        marketingPortalPrintPlacementDetails: action.payload,
      };
    case NutritionActionTypes.MARKETING_PORTAL_PRINT_PLACEMENT_DETAILS_LOADING_START:
      return {
        ...state,
        isMarketingPortalPrintPlacementDetailsLoading: true,
      };
    case NutritionActionTypes.MARKETING_PORTAL_PRINT_PLACEMENT_DETAILS_LOADING_STOP:
      return {
        ...state,
        isMarketingPortalPrintPlacementDetailsLoading: false,
      };

    case NutritionActionTypes.MARKETING_PORTAL_CREATE_PRINT_SUCCESS:
      return {
        ...state,
        marketingPortalCreatePrint: action.payload,
      };
    case NutritionActionTypes.MARKETING_PORTAL_CREATE_PRINT_LOADING_START:
      return {
        ...state,
        isMarketingPortalPrintCreating: true,
      };
    case NutritionActionTypes.MARKETING_PORTAL_CREATE_PRINT_LOADING_STOP:
      return {
        ...state,
        isMarketingPortalPrintCreating: false,
      };

    case NutritionActionTypes.MARKETING_PORTAL_CREATE_PARTNER_PRINT_SUCCESS:
      return {
        ...state,
        marketingPortalCreatePartnerPrint: action.payload,
      };
    case NutritionActionTypes.MARKETING_PORTAL_CREATE_PARTNER_PRINT_LOADING_START:
      return {
        ...state,
        isMarketingPortalCreatePartnerPrintLoading: true,
      };
    case NutritionActionTypes.MARKETING_PORTAL_CREATE_PARTNER_PRINT_LOADING_STOP:
      return {
        ...state,
        isMarketingPortalCreatePartnerPrintLoading: false,
      };

    case NutritionActionTypes.GET_ALL_REFUNDS_SUCCESS:
      return {
        ...state,
        getAllRefunds: action.payload,
      };
    case NutritionActionTypes.GET_ALL_REFUNDS_LOADING_START:
      return {
        ...state,
        isAllRefundsLoading: true,
      };
    case NutritionActionTypes.GET_ALL_REFUNDS_LOADING_STOP:
      return {
        ...state,
        isAllRefundsLoading: false,
      };

    case NutritionActionTypes.GET_MACRO_NUTRIENTS_PIE_CHART_DATA_SUCCESS:
      return {
        ...state,
        macroNutrientsPieChartData: action.payload,
      };
    case NutritionActionTypes.GET_MACRO_NUTRIENTS_PIE_CHART_DATA_LOADING_START:
      return {
        ...state,
        isMacroNutrientsPieChartDataLoading: true,
      };
    case NutritionActionTypes.GET_MACRO_NUTRIENTS_PIE_CHART_DATA_LOADING_STOP:
      return {
        ...state,
        isMacroNutrientsPieChartDataLoading: false,
      };
    default:
      return state;
  }
};

/*************************************************/

export default nutritionReducer;

/*************************************************/
