import styled from "styled-components";
import { RegistrationContainer, RegistrationHeading, RegistrationSubHeading } from 'pages/registration/registration.styles';


export const HealthScorePriorityFormContainer = styled(RegistrationContainer)``;

export const HealthScorePriorityFormHeading = styled(RegistrationHeading)``;

export const HealthScorePriorityFormSubHeading = styled(RegistrationSubHeading)``;

export const HealthScorePriorityButtonsContainer = styled.div`
    margin: 50px auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    width: 800px;

    & > div {
        margin: 0 20px 20px 0;
        width: auto;
    }
`;